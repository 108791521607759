import { Flex } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'

import * as S from './styles'

interface Props {
  isPenaltyShootout?: boolean
  isRegularTimeButtonDisabled?: boolean
  onRegularTimeButtonClick?: () => void
  onPenaltyShootoutButtonClick?: () => void
}

export const GameTimeToggle = ({
  isPenaltyShootout,
  onRegularTimeButtonClick,
  onPenaltyShootoutButtonClick,
}: Props) => {
  return (
    <Flex my="lg" br="sm" textAlign="center">
      <S.GameTimeToggleButton
        forwardedAs="button"
        $isActive={!isPenaltyShootout}
        onClick={onRegularTimeButtonClick}
        w="50%"
        p="lg"
        br="sm 0 0 sm"
      >
        <FormattedMessage id="ft_period" />
      </S.GameTimeToggleButton>
      <S.GameTimeToggleButton
        forwardedAs="button"
        $isActive={isPenaltyShootout}
        onClick={onPenaltyShootoutButtonClick}
        w="50%"
        p="lg"
        br="0 sm sm 0"
      >
        <FormattedMessage id="Penalties" />
      </S.GameTimeToggleButton>
    </Flex>
  )
}
