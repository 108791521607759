import * as React from 'react'
import MuiDialogActions from '@material-ui/core/DialogActions'
import { Stylable } from 'shared/model'

import { useStyles } from './styles'

interface DialogActionsProps extends Stylable {
  children: React.ReactNode
}

/**
 * DialogActions part of Dialog compoennt
 */
const DialogActions = (props: DialogActionsProps) => {
  const { children, style } = props
  const classes = useStyles()
  return (
    <MuiDialogActions style={style} className={classes.dialogActions}>
      {children}
    </MuiDialogActions>
  )
}

export default DialogActions
