import { UntranslatedOption } from 'shared/model'
import { IconConversion, IconPenaltyTry, IconRugbyPenalty, IconTry } from 'shared/ui'

import { IncidentGoalType, IncidentSubstitutionType, PointTypeOption } from '../../model'

export const rugbyPointTypeValues: { [key in IncidentGoalType]?: number } = {
  [IncidentGoalType.Try]: 5,
  [IncidentGoalType.Conversion]: 2,
  [IncidentGoalType.GoalFromPenalty]: 3,
  [IncidentGoalType.DropGoal]: 3,
  [IncidentGoalType.PenaltyTry]: 7,
}

export const rugbySubstitutionReasonOptions: UntranslatedOption[] = [
  {
    translationKey: 'substitute_reason_tactical',
    value: IncidentSubstitutionType.Regular,
  },
  {
    translationKey: 'substitute_reason_injury',
    value: IncidentSubstitutionType.Injury,
  },
  {
    translationKey: 'Temporary',
    value: IncidentSubstitutionType.Temporary,
  },
]

export const pointTypeOptions: PointTypeOption[] = [
  {
    translationKey: 'rugby_try',
    goalType: IncidentGoalType.Try,
    points: 5,
    icon: <IconTry />,
  },
  {
    translationKey: 'rugby_conversion',
    goalType: IncidentGoalType.Conversion,
    points: 2,
    icon: <IconConversion />,
  },
  {
    translationKey: 'penalty',
    goalType: IncidentGoalType.GoalFromPenalty,
    points: 3,
    icon: <IconRugbyPenalty />,
  },
  {
    translationKey: 'rugby_drop_goal',
    goalType: IncidentGoalType.DropGoal,
    points: 3,
    icon: <IconRugbyPenalty />,
  },
  {
    translationKey: 'rugby_penalty_try',
    goalType: IncidentGoalType.PenaltyTry,
    points: 7,
    icon: <IconPenaltyTry />,
  },
]
