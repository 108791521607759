import { Flex } from '@sofascore/ui'
import styled from 'styled-components'

export const ListContainer = styled(Flex)`
  margin-top: ${p => p.theme.spacing.sm};

  overflow-x: auto;

  > div + div {
    margin-left: ${p => p.theme.spacing.sm};
  }
`
