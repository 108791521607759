import { AnyAction } from 'redux'
import { BasicTeam } from 'entities/Team'

import { DynamicLineupsPlayer } from '../model'
import {
  ADD_TO_LINEUP,
  REMOVE_FROM_LINEUP,
  ADD_TO_SUBSTITUTIONS_SINGLE,
  REMOVE_FROM_SUBSTITUTIONS,
  ADD_TO_MISSING_PLAYERS,
  REMOVE_FROM_MISSING_PLAYERS,
  SET_CAPTAIN,
  RESET,
  SET_FORMATION,
  SET_KIT,
  SET_GOALKEEPER_KIT,
  CLEAR_ALL,
  CLEAR_CAPTAIN,
  ADD_TO_SUBSTITUTIONS_MULTIPLE,
} from './actions'

export interface LineupsState {
  homeTeam: BasicTeam
  homeLineupPlayers: DynamicLineupsPlayer[]
  homeKit: string
  homeFormation: string
  homeGoalkeeperKit: string
  awayTeam: BasicTeam
  awayLineupPlayers: DynamicLineupsPlayer[]
  awayKit: string
  awayFormation: string
  awayGoalkeeperKit: string
  hasChanges: boolean
}

const getTeamKey = (lineupPlayer: DynamicLineupsPlayer) => {
  return lineupPlayer.playerTeam === 1 ? 'homeLineupPlayers' : 'awayLineupPlayers'
}

const findAvailablePosition = (starters: DynamicLineupsPlayer[]) => {
  for (let index = 1; index <= 11; index++) {
    if (!starters.find(s => s.position === index)) {
      return index
    }
  }

  return null
}

export const lineupsReducer = (state: LineupsState, action: AnyAction) => {
  switch (action.type) {
    case ADD_TO_LINEUP: {
      const teamKey = getTeamKey(action.payload.lineupPlayer)
      const lineupPlayers = state[teamKey]
      const starters = lineupPlayers.filter(p => p.position)

      const position = action.payload.position || findAvailablePosition(starters)

      return {
        ...state,
        hasChanges: true,
        [teamKey]: lineupPlayers.map(p => {
          if (action.payload.lineupPlayer.player.id === p.player.id) {
            return {
              ...p,
              position: position,
              substitute: false,
              missingReason: null,
            }
          }

          return p
        }),
      }
    }

    case REMOVE_FROM_LINEUP: {
      const lineupPlayer = action.payload.lineupPlayer
      const teamKey = getTeamKey(lineupPlayer)
      const lineupPlayers = state[teamKey]

      return {
        ...state,
        hasChanges: true,
        [teamKey]: lineupPlayers.map(p => {
          if (p.player.id === lineupPlayer.player.id) {
            return { ...p, position: null, substitute: false, captain: false }
          }

          return p
        }),
      }
    }

    case ADD_TO_SUBSTITUTIONS_SINGLE: {
      const teamKey = getTeamKey(action.payload.lineupPlayer)
      const position = action.payload.position
      const lineupPlayers = state[teamKey]

      return {
        ...state,
        hasChanges: true,
        [teamKey]: lineupPlayers.map(p => {
          if (p.player.id === action.payload.lineupPlayer.player.id) {
            return {
              ...p,
              position: position,
              substitute: true,
              missingReason: null,
            }
          }

          return p
        }),
      }
    }

    case ADD_TO_SUBSTITUTIONS_MULTIPLE: {
      const substitutions: DynamicLineupsPlayer[] = action.payload.lineupPlayers
      const teamKey = getTeamKey(substitutions[0])
      const lineupPlayers = state[teamKey]

      return {
        ...state,
        hasChanges: true,
        [teamKey]: lineupPlayers.map(p => {
          if (substitutions.find(lp => lp.player.id === p.player.id)) {
            return {
              ...p,
              position: null,
              substitute: true,
              missingReason: null,
            }
          }

          return p
        }),
      }
    }

    case REMOVE_FROM_SUBSTITUTIONS: {
      const teamKey = getTeamKey(action.payload.lineupPlayer)
      const lineupPlayers = state[teamKey]

      return {
        ...state,
        hasChanges: true,
        [teamKey]: lineupPlayers.map(p => {
          if (action.payload.lineupPlayer.player.id === p.player.id) {
            return {
              ...p,
              position: null,
              substitute: false,
            }
          }

          return p
        }),
      }
    }

    case ADD_TO_MISSING_PLAYERS: {
      const missingPlayers: DynamicLineupsPlayer[] = action.payload

      if (!missingPlayers.length) return state

      const teamKey = getTeamKey(action.payload[0])
      const lineupPlayers = state[teamKey]

      return {
        ...state,
        hasChanges: true,
        [teamKey]: lineupPlayers.map(p => {
          const mp = missingPlayers.find(mp => mp.player.id === p.player.id)

          if (mp) {
            return {
              ...p,
              missingReason: mp.missingReason,
            }
          }

          return p
        }),
      }
    }

    case REMOVE_FROM_MISSING_PLAYERS: {
      const teamKey = getTeamKey(action.payload)
      const lineupPlayers = state[teamKey]

      return {
        ...state,
        hasChanges: true,
        [teamKey]: lineupPlayers.map(p => {
          if (action.payload.player.id === p.player.id) {
            return {
              ...p,
              missingReason: null,
            }
          }

          return p
        }),
      }
    }

    case SET_CAPTAIN: {
      const { lineupPlayer } = action.payload
      const teamKey = lineupPlayer.playerTeam == 1 ? 'homeLineupPlayers' : 'awayLineupPlayers'
      const lineupPlayers = state[teamKey]

      return {
        ...state,
        hasChanges: true,
        [teamKey]: lineupPlayers.map(p => {
          if (p.player.id === lineupPlayer.player.id) {
            return { ...p, captain: true }
          }
          if (p.captain && p.player.id !== lineupPlayer.player.id) {
            return { ...p, captain: false }
          }

          return p
        }),
      }
    }

    case CLEAR_CAPTAIN: {
      const { previousCaptain } = action.payload
      const teamKey = previousCaptain.playerTeam == 1 ? 'homeLineupPlayers' : 'awayLineupPlayers'
      const lineupPlayers = state[teamKey]

      return {
        ...state,
        hasChanges: true,
        [teamKey]: lineupPlayers.map(p => {
          if (p.player.id === previousCaptain.player.id) {
            return { ...p, captain: false }
          }

          return p
        }),
      }
    }

    case SET_FORMATION: {
      const teamKey = action.payload.isHome ? 'homeFormation' : 'awayFormation'

      return {
        ...state,
        hasChanges: true,
        [teamKey]: action.payload.formation,
      }
    }

    case SET_KIT: {
      const teamKey = action.payload.isHome ? 'homeKit' : 'awayKit'

      return {
        ...state,
        hasChanges: true,
        [teamKey]: action.payload.kit,
      }
    }

    case SET_GOALKEEPER_KIT: {
      const teamKey = action.payload.isHome ? 'homeGoalkeeperKit' : 'awayGoalkeeperKit'

      return {
        ...state,
        hasChanges: true,
        [teamKey]: action.payload.goalkeeperKit,
      }
    }

    case RESET: {
      return {
        ...state,
        hasChanges: false,
      }
    }

    case CLEAR_ALL: {
      const teamKey = action.payload.isHome ? 'homeLineupPlayers' : 'awayLineupPlayers'
      const lineupPlayers = state[teamKey]

      return {
        ...state,
        hasChanges: true,
        [teamKey]: lineupPlayers.map(p => {
          return {
            ...p,
            position: null,
            substitute: false,
            missingReason: null,
            captain: false,
          }
        }),
      }
    }

    default:
      return state
  }
}
