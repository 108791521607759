import { useIntl } from 'react-intl'
import { Box, Flex, Text, GraphicEmptyStateMatchShotmap } from '@sofascore/ui'

const EmptyState = ({ title, message }: { title: string; message: string }) => {
  return (
    <Flex direction="column" align="center" mt={64}>
      <GraphicEmptyStateMatchShotmap fill="primary.default" />

      <Box textAlign="center" mt="xl">
        <Text fontWeight="bold" fontSize="medium">
          {title}
        </Text>

        <Box mt="md">
          <Text color="onSurface.nLv2">{message}</Text>
        </Box>
      </Box>
    </Flex>
  )
}

export const NoCreatedEvents = () => {
  const intl = useIntl()

  return (
    <EmptyState title={intl.formatMessage({ id: 'no_matches' })} message={intl.formatMessage({ id: 'emptyMatches' })} />
  )
}

export const NoFilteredMatches = () => {
  const intl = useIntl()

  return (
    <EmptyState
      title={intl.formatMessage({ id: 'no_filter_results' })}
      message={intl.formatMessage({ id: 'no_filter_results_text' })}
    />
  )
}
