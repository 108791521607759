import styled from 'styled-components'

export const SubstitutionSlotEmptyAvatar = styled.div`
  position: relative;

  width: 42px;
  height: 42px;

  border-radius: 50%;
  border: 1px dashed ${p => p.theme.colors.onSurface.nLv3};
`

export const SubstitutionSlotEmptyPosition = styled.p`
  position: absolute;
  top: 8px;
  right: -8px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  font-weight: 600;

  width: 24px;
  height: 24px;

  background-color: #fff;
  border-radius: 50%;
  border: 1px solid ${p => p.theme.colors.onSurface.nLv4};
`
