import { Box, Flex, Text } from '@sofascore/ui'
import { FormattedMessage, useIntl } from 'react-intl'
import { useState } from 'react'
import { useEventState } from 'entities/Event'
import { useToast } from 'shared/lib'
import { useErrorHandler } from 'shared/hooks'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'
import { FirebaseEventName } from 'shared/lib/firebase/model'

import { Button } from 'components/Button'
import { updateMatchInfo } from 'api'
import { TimePicker } from 'components/TimePicker'

interface Props {
  handleClose: () => void
  revalidateEvent: () => void
}

const UpdateStartTimeForm = ({ handleClose, revalidateEvent }: Props) => {
  const { enqueueToast } = useToast()
  const intl = useIntl()
  const handleError = useErrorHandler()

  const { initialEvent } = useEventState()
  const { id, startTimestamp } = initialEvent

  const [isSaving, setIsSaving] = useState(false)

  const [selectedTime, setSelectedTime] = useState(new Date(startTimestamp * 1000))

  const handleChangeToCurrentTime = () => {
    const newSelectedTime = new Date(selectedTime)
    newSelectedTime.setHours(new Date().getHours())
    newSelectedTime.setMinutes(new Date().getMinutes())

    setSelectedTime(newSelectedTime)
  }

  const handleConfirm = async () => {
    setIsSaving(true)

    try {
      const startDateTimestamp = Math.floor(selectedTime.getTime() / 1000)

      await updateMatchInfo(id.toString(), {
        startDateTimestamp,
      })

      revalidateEvent()
      logFirebaseEvent(FirebaseEventName.ChangeDateTime, { id: initialEvent.id })
      handleClose()
      enqueueToast(intl.formatMessage({ id: 'toastSuccess' }), { variant: 'success' })
    } catch (err) {
      handleError(err)
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <Flex direction="column">
      <Box bg="surface.s2" mx="sm" br={8}>
        <TimePicker date={selectedTime} onChange={setSelectedTime} />
      </Box>

      <Flex mt="md" justify="center">
        <Text color="primary.default" fontWeight="medium" cursor="pointer" onClick={handleChangeToCurrentTime}>
          <FormattedMessage id="currentTime" />
        </Text>
      </Flex>

      <Flex justify="center" gapX={16} px={40} py="xl" pt={['xl', 50]}>
        <Button variant="outlined" fullWidth onClick={handleClose}>
          <FormattedMessage id="cancel" />
        </Button>

        <Button variant="contained" fullWidth onClick={handleConfirm} isLoading={isSaving}>
          <FormattedMessage id="confirm" />
        </Button>
      </Flex>
    </Flex>
  )
}

export default UpdateStartTimeForm
