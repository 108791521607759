import { Box, Button, Flex } from '@sofascore/ui'
import styled from 'styled-components'

export const StandingsControlsContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
`

export const StandingsControl = styled(Button)<{ disabled?: boolean; isSaving?: boolean }>`
  padding: 0;

  opacity: ${p => (p.disabled ? 0.3 : 1)};
  cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};
  border: none;

  ${p =>
    p.isSaving &&
    `
  & svg {
    animation: rotate-icon 1s infinite;
  }
  `}

  @keyframes rotate-icon {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(360deg);
    }
  }
`

export const StandingsControlsOptions = styled(Flex)`
  gap: ${p => p.theme.spacing.sm};
`
