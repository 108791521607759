import { PropsWithChildren, useEffect } from 'react'
import { IconError, IconInfo, IconSuccess } from 'shared/ui/icons'
import { Text } from '@sofascore/ui'

import { ToastProps, ToastVariant } from '../model'
import * as S from './styles'

const mapVariantToIcon: Record<ToastVariant, JSX.Element> = {
  info: <IconInfo />,
  success: <IconSuccess />,
  error: <IconError />,
}

const Toast = ({ variant, action, onLoad, children }: PropsWithChildren<ToastProps>) => {
  const icon = mapVariantToIcon[variant]

  useEffect(() => {
    if (onLoad) {
      onLoad()
    }
  }, [onLoad])

  return (
    <S.Toast>
      <S.IconSlot>{icon}</S.IconSlot>

      <Text font="assistive.default">{children}</Text>

      {action && (
        <S.Button onClick={action.func}>
          <Text font="assistive.default" color="#fff">
            {action.content}
          </Text>
        </S.Button>
      )}
    </S.Toast>
  )
}

export default Toast
