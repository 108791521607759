import { IncidentGoalType } from 'entities/Incident'
import { ReactNode } from 'react'
import IconFootballBall from '@sofascore/ui/dist/modules/Icons/_IconFootballBall'
import IconFootballPenalty from '@sofascore/ui/dist/modules/Icons/_IconFootballPenalty'
import IconFootballOwnGoal from '@sofascore/ui/dist/modules/Icons/_IconFootballOwnGoal'

import { GREEN, RED } from 'styles/color'

export interface GoalTypeOption {
  translationKey: string
  goalType: IncidentGoalType
  icon: ReactNode
}

export const goalTypeOptions: GoalTypeOption[] = [
  {
    translationKey: 'goal',
    goalType: IncidentGoalType.GoalRegular,
    icon: <IconFootballBall fill={GREEN.incident} />,
  },
  {
    translationKey: 'penalty',
    goalType: IncidentGoalType.GoalFromPenalty,
    icon: <IconFootballPenalty fill={GREEN.incident} />,
  },
  {
    translationKey: 'autogoal',
    goalType: IncidentGoalType.OwnGoal,
    icon: <IconFootballOwnGoal fill={RED.incident} />,
  },
]
