import { Box, Text, useOutsideClick } from '@sofascore/ui'
import { Ref, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { AddExistingTeam, AddNewTeam } from 'features/AddTeam'

import { IconButton } from 'components/IconButton'
import { IconPlusCircle } from 'components/Icons/IconPlusCircle'

import * as S from './styles'
import { useGroupedCompetitionTeams } from '../hooks'

interface AddTeamProps {
  tournamentId: number
}

export const MyCompetitionAddTeam = ({ tournamentId }: AddTeamProps) => {
  const { revalidate } = useGroupedCompetitionTeams()
  const [isExpanded, setIsExpanded] = useState(false)

  const handleClickOutside = () => setIsExpanded(false)

  const ref: Ref<HTMLButtonElement> = useOutsideClick(handleClickOutside)

  return (
    <Box position="relative" ref={ref} role="listbox" id="add-team-drawer" aria-haspopup="true">
      <IconButton onClick={() => setIsExpanded(v => !v)}>
        <IconPlusCircle />
      </IconButton>

      <S.AddTeamMenu role="menu" aria-expanded={isExpanded}>
        <AddNewTeam tournamentId={tournamentId} onSave={() => revalidate()}>
          {(_, setIsOpen) => (
            <S.AddTeamMenuItem
              aria-controls="add-team-menu"
              role="menuitem"
              onClick={() => {
                setIsExpanded(false)
                setIsOpen(true)
              }}
            >
              <Text>
                <FormattedMessage id="add_new_team" />
              </Text>
            </S.AddTeamMenuItem>
          )}
        </AddNewTeam>

        <AddExistingTeam tournamentId={tournamentId} onSave={() => revalidate()}>
          {(_, setIsOpen) => (
            <S.AddTeamMenuItem
              aria-controls="add-team-menu"
              role="menuitem"
              onClick={() => {
                setIsExpanded(false)
                setIsOpen(true)
              }}
            >
              <Text>
                <FormattedMessage id="add_existing_team" />
              </Text>
            </S.AddTeamMenuItem>
          )}
        </AddExistingTeam>
      </S.AddTeamMenu>
    </Box>
  )
}
