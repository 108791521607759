import { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { Box, Flex, BoxProps } from '@sofascore/ui'
import IconClose from '@sofascore/ui/dist/modules/Icons/_IconClose'
import { CSSProperties } from 'styled-components'

import { IconSearch } from 'components/Icons/IconSearch'

import * as S from './styles'

export type SearchProps<T = string> = {
  label: string
  name?: string
  style?: CSSProperties
  value?: T
  defaultValue?: T
  onChange?: (value: T) => void
} & BoxProps

export const Search = ({ label, value = '', defaultValue, name, onChange, ...boxProps }: SearchProps) => {
  const [fieldValue, setFieldValue] = useState(defaultValue || value)

  const handleOnChange = (e: ChangeEvent<HTMLInputElement> & FormEvent<'input'>) => {
    setFieldValue(e.target.value)
  }

  useEffect(() => {
    setFieldValue(value)
  }, [value])

  useEffect(() => {
    onChange?.(fieldValue)
  }, [fieldValue, onChange])

  return (
    <Box br="sm" {...boxProps} title={typeof label === 'string' ? label : undefined}>
      <S.FormFieldWrapper bg="surface.s2" color="surface.nLv1" minH="48">
        <Flex align="center" minW={0} grow={1} wrap="wrap" mr="sm">
          <IconSearch fill="#222226" />

          <Box
            grow={1}
            as="input"
            type="text"
            name={name}
            placeholder={label}
            aria-label={name}
            id={name}
            onChange={handleOnChange}
            value={fieldValue}
            data-testid="input-field"
            ml="sm"
          />

          {!!fieldValue && (
            <S.MotionButton
              variant="unshielded"
              p={0}
              border="none"
              tabIndex={-1}
              initial={{ transform: 'scale(0.5)' }}
              animate={{ transform: 'scale(1)' }}
              transition={{ duration: 0.1 }}
            >
              <IconClose onClick={() => setFieldValue('')} data-testid="clear-button" cursor="pointer" />
            </S.MotionButton>
          )}
        </Flex>
      </S.FormFieldWrapper>
    </Box>
  )
}
