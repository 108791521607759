import {
  CreateIncidentData,
  IncidentActionType,
  IncidentPenaltyShootoutType,
  IncidentType,
  useEventIncidentsDispatch,
} from 'entities/Incident'
import { useCallback } from 'react'
import { EventActionType, useEventDispatch } from 'entities/Event'

export const usePostMatchCreateIncidentHandler = () => {
  const incidentsDispatch = useEventIncidentsDispatch()
  const eventDispatch = useEventDispatch()

  const handleCreate = useCallback(
    async (data: CreateIncidentData) => {
      const { homeScore, awayScore, incidentType, incidentClass, isHome } = data.incidentData

      incidentsDispatch({ type: IncidentActionType.ADD_INCIDENT, payload: data })

      if (incidentType === IncidentType.Goal) {
        eventDispatch({
          type: EventActionType.SET_SCORES,
          payload: { homeScore: homeScore, awayScore: awayScore },
        })
      }

      if (incidentType === IncidentType.PenaltyShootout && incidentClass === IncidentPenaltyShootoutType.Scored) {
        eventDispatch({
          type: EventActionType.INCREMENT_PENALTY_SCORE,
          payload: { isHome: isHome },
        })
      }
    },
    [incidentsDispatch, eventDispatch],
  )

  return { handleCreate }
}
