import * as React from 'react'

import { secondaryColor } from 'styles/color'

import { IconBase, IconProps } from './Base'

export const IconIndicatorPlay = (props: IconProps) => (
  <IconBase width={16} height={16} viewBox="0 0 16 16" {...props}>
    <g fill={props.fill || secondaryColor} fillRule="evenodd">
      <path
        fill="inherit"
        d="M10.667 7.333a1.992 1.992 0 0 0 1.993-2 1.997 1.997 0 1 0-3.993 0c0 1.107.893 2 2 2zm-5.334 0a1.992 1.992 0 0 0 1.994-2 1.997 1.997 0 1 0-3.993 0c0 1.107.893 2 2 2zm0 1.334C3.78 8.667.667 9.447.667 11v1.667H10V11c0-1.553-3.113-2.333-4.667-2.333zm5.334 0c-.194 0-.414.013-.647.033.773.56 1.313 1.313 1.313 2.3v1.667h4V11c0-1.553-3.113-2.333-4.666-2.333z"
      />
    </g>
  </IconBase>
)
