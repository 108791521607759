import { Box, IconButton, Typography, useTheme } from '@material-ui/core'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Stylable } from 'shared/model'

import { IconMinus } from 'components/Icons/IconMinus'
import { IconPlus } from 'components/Icons/IconPlus'
import { neutral, secondaryColor, selectColor, textSecondary } from 'styles/color'

import { useStyles } from './styles'

const MIN_COUNTER_VALUE = 0

interface SetCounterProps extends Stylable {
  caption?: string
  homeScoreValue?: number
  awayScoreValue?: number
  onHomeValueChange: (value: number | undefined) => void
  onAwayValueChange: (value: number | undefined) => void
  disabled?: boolean
}

/**
 * Component used for set period scores.
 */
export const SetCounter = ({
  caption,
  homeScoreValue,
  awayScoreValue,
  onHomeValueChange,
  onAwayValueChange,
  style,
  disabled,
}: SetCounterProps) => {
  const theme = useTheme()
  const classes = useStyles()

  const [isControlModalOpen, setControlModalOpen] = useState(false)
  const [isHomeScoreSetting, setHomeScoreSetting] = useState(true)

  const [canDecrement, setCanDecrement] = useState(false)

  const setterRef = useRef<HTMLDivElement>(null)

  const isHomeScoreDefined = homeScoreValue !== undefined && homeScoreValue !== null
  const isAwayScoreDefined = awayScoreValue !== undefined && awayScoreValue !== null
  const isSetDefined = isHomeScoreDefined && isAwayScoreDefined

  useEffect(() => {
    if (isHomeScoreSetting) {
      setCanDecrement(homeScoreValue !== undefined && homeScoreValue !== null)
    } else {
      setCanDecrement(awayScoreValue !== undefined && awayScoreValue !== null)
    }
  }, [isHomeScoreSetting, homeScoreValue, awayScoreValue])

  const toggleSetter = (side: 'home' | 'away') => {
    if (!disabled) {
      setControlModalOpen(!isControlModalOpen)
      setHomeScoreSetting(side === 'home')
    }
  }

  const handleIncrement = () => {
    if (isHomeScoreSetting) {
      if (homeScoreValue !== undefined && homeScoreValue !== null) onHomeValueChange(homeScoreValue + 1)
      else {
        onHomeValueChange(0)
        onAwayValueChange(0)
      }
    } else {
      if (awayScoreValue !== undefined && awayScoreValue !== null) onAwayValueChange(awayScoreValue + 1)
      else {
        onHomeValueChange(0)
        onAwayValueChange(0)
      }
    }
  }

  const handleDecrement = () => {
    if (isHomeScoreSetting) {
      if (homeScoreValue !== undefined && homeScoreValue > MIN_COUNTER_VALUE) {
        onHomeValueChange(homeScoreValue - 1)
      } else {
        onHomeValueChange(undefined)
        onAwayValueChange(undefined)
      }
    } else {
      if (awayScoreValue !== undefined && awayScoreValue > MIN_COUNTER_VALUE) {
        onAwayValueChange(awayScoreValue - 1)
      } else {
        onHomeValueChange(undefined)
        onAwayValueChange(undefined)
      }
    }
  }

  const handleHomeValueInput = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value.replace('-', ''))
    if (!isNaN(newValue)) {
      if (awayScoreValue === undefined || awayScoreValue === null) {
        onHomeValueChange(newValue)
        onAwayValueChange(0)
      } else {
        onHomeValueChange(newValue)
      }
    }
  }

  const handleAwayValueInput = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value.replace('-', ''))
    if (!isNaN(newValue)) {
      if (homeScoreValue === undefined || homeScoreValue === null) {
        onAwayValueChange(newValue)
        onHomeValueChange(0)
      } else {
        onAwayValueChange(newValue)
      }
    }
  }

  // For setter outside click
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function handleClickOutside(event: any) {
      if (setterRef.current && !setterRef.current.contains(event.target)) {
        setControlModalOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [setterRef])

  return (
    <Box style={{ ...style }}>
      <Typography className={classes.caption}>{caption}</Typography>

      <div ref={setterRef} style={{ position: 'relative' }}>
        <Box
          className={classes.setterContainer}
          style={{ display: isControlModalOpen ? 'flex' : 'none' }}
          data-testid="counter-modal"
        >
          <Box className={classes.operationItem}>
            <IconButton className={classes.iconButton} onClick={handleIncrement} data-testid="incrementer">
              <IconPlus />
            </IconButton>
          </Box>

          <Box className={classes.operationItem} mt={`${theme.spacing(1)}px`}>
            <IconButton
              className={classes.iconButton}
              disabled={!canDecrement}
              onClick={handleDecrement}
              style={canDecrement ? {} : { opacity: 0.5 }}
              data-testid="decrementer"
            >
              <IconMinus />
            </IconButton>
          </Box>
        </Box>
      </div>

      <Box className={classes.setContainer}>
        <Box
          className={classes.counterContainer}
          onClick={() => toggleSetter('home')}
          style={{
            borderColor: isControlModalOpen && isHomeScoreSetting ? secondaryColor : neutral,
            backgroundColor: isSetDefined ? textSecondary : selectColor,
          }}
          data-testid="homeScoreContainer"
        >
          <Typography className={classes.value}>
            <input
              value={isHomeScoreDefined ? homeScoreValue : '-'}
              onChange={handleHomeValueInput}
              className={classes.input}
              data-testid="homeScoreValue"
              disabled={disabled}
            />
          </Typography>
        </Box>

        <Box
          className={classes.counterContainer}
          onClick={() => toggleSetter('away')}
          style={{
            marginTop: theme.spacing(1),
            borderColor: isControlModalOpen && !isHomeScoreSetting ? secondaryColor : neutral,
            backgroundColor: isSetDefined ? textSecondary : selectColor,
          }}
        >
          <Typography className={classes.value}>
            <input
              value={isAwayScoreDefined ? awayScoreValue : '-'}
              onChange={handleAwayValueInput}
              className={classes.input}
              data-testid="awayScoreValue"
              disabled={disabled}
            />
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
