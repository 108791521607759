import { Helmet } from 'react-helmet-async'
import { tabTitleWrapper } from 'shared/utils'

import { useTeam } from '../TeamContext'

const TeamTabTitle = ({ title }: { title: string }) => {
  const team = useTeam()

  const { name = '' } = team || {}

  const tabTitle = `${title} - ${name}`

  return (
    <Helmet>
      <title>{tabTitleWrapper(tabTitle)}</title>
    </Helmet>
  )
}

export default TeamTabTitle
