import { useState } from 'react'
import { Box, Flex } from '@sofascore/ui'
import {
  LineupsCaptainSelector,
  LineupsTeamSelector,
  LineupsKitSelector,
  Substitutions,
  MissingPlayers,
  PlayerCounter,
  ClearAll,
  lineupPlayerKitColors,
  DynamicLineupsPlayer,
  PitchLineupsProps,
  ADD_TO_LINEUP,
  REMOVE_FROM_LINEUP,
  useLineupsDispatch,
  useLineupsState,
  mapRugbyPositionToGroupKey,
} from 'features/AddLineups'

import { TERRAIN_HEIGHT } from '../config'
import { PitchBackground, PitchLines } from './PitchGraphics'
import { useRugbyPositions } from '../hooks'
import { MobileClickSlot } from './MobileClickSlot'
import { MobileAddPlayerDialog } from '../../MobileAddPlayerDialog'
import { renderRugbyOption, renderRugbySelectedOption, sortRugbyPlayerOptions } from '../utils'
import * as S from './styles'

export const MobilePitchLineups = ({
  lineupPlayers,
  otherTeamKit,
  isHomeTeam,
  activeTeamId,
  onChangeTeam,
  homeTeam,
  awayTeam,
}: PitchLineupsProps) => {
  const { homeKit, awayKit } = useLineupsState()
  const { starterPositions, substitutionPositions } = useRugbyPositions()

  const dispatch = useLineupsDispatch()

  const [selectedPlayerSlot, setSelectedPlayerSlot] = useState<number | null>(null)

  const starters = lineupPlayers.filter(lp => lp.position && !lp.substitute)
  const substitutions = lineupPlayers.filter(lp => lp.substitute)
  const missingPlayers = lineupPlayers.filter(lp => lp.missingReason !== null)

  const availablePlayers = lineupPlayers.filter(
    lp => lp.position === null && !lp.substitute && lp.missingReason === null,
  )

  // Kit color must be different from the one that other team uses and also different from goalkeeper ones
  const availableKitColors = lineupPlayerKitColors.filter(o => o.value !== otherTeamKit)

  const handleAddToLineup = (lineupPlayer: DynamicLineupsPlayer, position: number) => {
    dispatch({ type: ADD_TO_LINEUP, payload: { lineupPlayer: lineupPlayer, position: position } })
  }

  const handleRemoveFromLineup = (lineupPlayer: DynamicLineupsPlayer) => {
    dispatch({ type: REMOVE_FROM_LINEUP, payload: { lineupPlayer: lineupPlayer } })
  }

  return (
    <Box bg="surface.s1">
      <Flex direction="column" gapY="lg" p="lg">
        <LineupsTeamSelector homeTeam={homeTeam} awayTeam={awayTeam} teamId={activeTeamId} setTeamId={onChangeTeam} />

        <LineupsKitSelector options={availableKitColors} isHomeTeam={isHomeTeam} />

        <PlayerCounter starters={starters} limit={starterPositions.length}>
          <ClearAll isHomeTeam={isHomeTeam} />
        </PlayerCounter>
      </Flex>

      <S.MobilePitchContainer style={{ backgroundImage: 'url(/images/graphics/rugby-terrain.svg)' }}>
        <Flex direction="column" justify="space-between" position="relative" w={360} m="0 auto">
          <PitchBackground />
          <PitchLines />

          <Flex direction="column" justify="space-between" position="relative" h={TERRAIN_HEIGHT}>
            {starterPositions.map(coord => {
              return (
                <MobileClickSlot
                  key={coord.position}
                  lineupPlayers={lineupPlayers}
                  kitColor={isHomeTeam ? homeKit : awayKit}
                  position={coord.position}
                  onClick={() => setSelectedPlayerSlot(coord.position)}
                  onRemove={p => handleRemoveFromLineup(p)}
                  style={{ position: 'absolute', top: coord.y, left: coord.x }}
                />
              )
            })}
          </Flex>
        </Flex>
      </S.MobilePitchContainer>

      <Box p="lg">
        <LineupsCaptainSelector
          starters={starters}
          renderOption={renderRugbyOption}
          renderSelectedOption={renderRugbySelectedOption}
          sortOptions={sortRugbyPlayerOptions}
        />
      </Box>

      <MobileAddPlayerDialog
        open={!!selectedPlayerSlot}
        lineupPlayers={lineupPlayers}
        selectedPosition={selectedPlayerSlot!}
        onClose={() => setSelectedPlayerSlot(null)}
        onAddPlayer={lineupPlayer => handleAddToLineup(lineupPlayer, selectedPlayerSlot!)}
      />

      <Substitutions
        substitutions={substitutions}
        availablePlayers={availablePlayers}
        substitutionPositions={substitutionPositions}
        positionGroupMapping={mapRugbyPositionToGroupKey}
      />

      <Box pb="lg" pl="lg" pr={28}>
        <MissingPlayers
          missingPlayers={missingPlayers}
          availablePlayers={availablePlayers}
          positionGroupMapping={mapRugbyPositionToGroupKey}
        />
      </Box>
    </Box>
  )
}
