import { FormattedMessage, useIntl } from 'react-intl'
import { Box } from '@sofascore/ui'
import { Helmet } from 'react-helmet-async'
import { tabTitleWrapper } from 'shared/utils'

import { PageHeader } from 'components/Layout/PageHeader'
import { PageContent } from 'components/Layout/PageContent'

import {
  extractFeatureImageUrl,
  formatPublishDate,
  getIdFromGuid,
  getSummary,
  stripImageTags,
  useRssFeedItems,
  markWhatsNewMenuAsRead,
  isItemRead,
} from './utils/rss'
import { FeedItem } from './FeedItem'

export const WhatsNewContainer = () => {
  const intl = useIntl()
  const feedItems = useRssFeedItems()

  const handleArticleRead = () => {
    const isWhatsNewRead = feedItems.length !== 0 && feedItems.every(item => isItemRead(getIdFromGuid(item.guid)))

    if (isWhatsNewRead) {
      markWhatsNewMenuAsRead()
    }
  }

  return (
    <>
      <Helmet>
        <title>{tabTitleWrapper(intl.formatMessage({ id: 'whats_new' }))}</title>
      </Helmet>
      <PageHeader pageName={<FormattedMessage id="whats_new" />} />

      <PageContent>
        <Box maxW={700} mx="auto" pt="lg" px="sm" pb="sm">
          {feedItems
            // Sort by date
            .sort((item1, item2) => (new Date(item1.pubDate) <= new Date(item2.pubDate) ? 1 : -1))
            .map(item => {
              const id = getIdFromGuid(item.guid)

              return (
                <FeedItem
                  key={id}
                  id={id}
                  title={item.title}
                  imgUrl={extractFeatureImageUrl(item['content:encoded'])}
                  summary={getSummary(item['content:encoded'])}
                  richTextContent={stripImageTags(item['content:encoded'])}
                  pubDate={formatPublishDate(item.pubDate)}
                  handleArticleRead={handleArticleRead}
                />
              )
            })}
        </Box>
      </PageContent>
    </>
  )
}
