import { AnyAction } from 'redux'
import { createContext, useContext, useEffect, useMemo, useReducer } from 'react'
import isEqual from 'lodash/isEqual'

import { sortIncidents } from 'modules/Football/helper'

import { Incident } from '../model'
import { EventIncidentsState, eventIncidentsReducer } from './reducer'
import { IncidentActionType } from './actions'

const EventIncidentsStateContext = createContext<EventIncidentsState>({} as EventIncidentsState)

const EventIncidentsDispatchContext = createContext<React.Dispatch<AnyAction> | null>(null)

export const EventIncidentsProvider = ({
  initialState,
  children,
}: {
  initialState?: Incident[]
  children: React.ReactNode
}) => {
  const [state, dispatch] = useReducer(eventIncidentsReducer, {
    incidents: initialState ? sortIncidents(initialState) : [],
    initialIncidents: initialState ? sortIncidents(initialState) : [],
  })

  // When incidents change, check if they
  // are the same as initial incidents
  const hasChanges = useMemo(() => {
    return !isEqual(state.incidents, state.initialIncidents)
  }, [state.incidents, state.initialIncidents])

  // When incidents change, sort them
  const sortedIncidents = useMemo(() => {
    if (state?.incidents) {
      return sortIncidents([...state.incidents])
    }

    return []
  }, [state.incidents])

  // Since initial state of a reducer can't be changed after
  // initial render, we need to dispatch action to update state
  useEffect(() => {
    if (initialState) {
      dispatch({ type: IncidentActionType.SET_INCIDENTS, payload: sortIncidents(initialState) })
      dispatch({ type: IncidentActionType.SET_INITIAL_INCIDENTS, payload: sortIncidents(initialState) })
    }
  }, [initialState])

  return (
    <EventIncidentsStateContext.Provider
      value={{ initialIncidents: state.initialIncidents, incidents: sortedIncidents, hasChanges: hasChanges }}
    >
      <EventIncidentsDispatchContext.Provider value={dispatch}>{children}</EventIncidentsDispatchContext.Provider>
    </EventIncidentsStateContext.Provider>
  )
}

export const useEventIncidentsState = (): EventIncidentsState => {
  const context = useContext(EventIncidentsStateContext)

  if (!context) {
    throw new Error('useEventIncidentsState must be used within a EventIncidentsProvider')
  }

  return context
}

export const useEventIncidentsDispatch = () => {
  const context = useContext(EventIncidentsDispatchContext)

  if (!context) {
    throw new Error('useEventIncidentsDispatch must be used within a EventIncidentsProvider')
  }

  return context
}
