/* eslint-disable @typescript-eslint/ban-types */

/**
 * Service that allows registering of onLogin callbacks.
 * When callback is registered it will be called when login was successful.
 * Service keeps internal FIFO queue for storing callbacks.
 */
export class OnLoginService {
  static queue: Function[] = []

  /**
   * Add callback to a queue.
   */
  static callOnLogin = (callback: Function) => {
    OnLoginService.queue.push(callback)
  }

  /**
   * This method is called to mark successful login.
   * If you want to register a callback, use `callOnLogin` instead!
   */
  static loginSuccessful = () => {
    OnLoginService.queue.forEach(func => {
      if (typeof func === 'function') {
        func()
      }
    })

    OnLoginService.clear()
  }

  /**
   * Clears callback queue.
   */
  static clear = () => {
    OnLoginService.queue = []
  }
}
