import { BasicEvent, hasExtraTimeData, hasGoneToExtraTime } from 'entities/Event'
import {
  AFTER_EXTRA_TIME_CODE,
  AFTER_PENALTIES_CODE,
  ENDED_CODE,
  FIRST_HALF_CODE,
  INTERRUPTED_CODE,
  NOT_STARTED_CODE,
  PENALTIES_CODE,
} from 'entities/Status'
import get from 'lodash/get'
import { SelectablePeriod, SportPeriodConfig } from 'features/EditPeriod'

import { isExtra } from 'components/ProgressBar/helper'

/**
 * Returns array of possible football periods with their respective start timestamps
 * depending of competition type and match status. Start timestamps for each period
 * are read from `event.time` object (e.g. 1st half -> period1StartTimestamp).
 */
export const createSelectablePeriods = (
  event: BasicEvent,
  regularPeriods: SportPeriodConfig[],
  extraPeriods: SportPeriodConfig[],
  penaltyPeriod: SportPeriodConfig,
): SelectablePeriod[] => {
  const isInPenalties = event.status.code === PENALTIES_CODE
  const periods = Array.from(regularPeriods)

  // If match is currently in extra time or if extra time data exists, add
  // extra periods
  if (isExtra(event) || hasExtraTimeData(event)) {
    periods.push(...extraPeriods)
  }

  // If match is currently in penalties, add penalty period. Or, if match
  // is currenlty not in penalties, but it previously went to extra time
  if (isInPenalties || (!isInPenalties && hasGoneToExtraTime(event))) {
    periods.push(penaltyPeriod)
  }

  return periods.map(({ startTimeStampPath, ...otherProps }) => {
    return {
      ...otherProps,
      startTimestamp: (() => {
        // If there is no path defined in config,
        // we don't display start time of period (half-time)
        if (!startTimeStampPath) return null

        // If the match has not started, display the
        // initial match start time for the 1st half period box
        if (
          event.status.code === NOT_STARTED_CODE &&
          otherProps.code === FIRST_HALF_CODE &&
          !event.time?.period1StartTimestamp
        ) {
          return event.startTimestamp
        }

        // Otherwise, find respective period start time in event.time object
        return startTimeStampPath ? get(event, startTimeStampPath) : null
      })(),
    }
  })
}

export const findActivePeriodIndex = (activePeriods: SelectablePeriod[], event: BasicEvent) => {
  if (event.status.code === INTERRUPTED_CODE) return hasGoneToExtraTime(event) ? 9 : 3
  if (event.status.code === ENDED_CODE) return 3
  if (event.status.code === AFTER_EXTRA_TIME_CODE || event.status.code === AFTER_PENALTIES_CODE) return 9

  return activePeriods.findIndex(p => p.code === event.status.code)
}
