import styled from 'styled-components'
import { Flex } from '@sofascore/ui'

import { Button } from 'components/Button'

export const MatchesNavigation = styled(Flex)`
  box-shadow: 0 1px 2px 0 ${p => p.theme.colors.effect.elevation};
`

export const NavigationButton = styled(Button)`
  width: 22px;
  height: 22px;

  border: none;
  border-radius: 50%;
  padding: 0;
`
