import React from 'react'
import { isToday } from 'date-fns'
import { useSelector } from 'react-redux'
import { Box, Typography, Button } from '@material-ui/core'
import { FormattedRelativeTime, FormattedDate, FormattedTime, FormattedMessage } from 'react-intl'
import { FormattedStatusDescription, StatusCode, StatusType } from 'entities/Status'
import { BasicEvent } from 'entities/Event'
import { SportName } from 'entities/Sport'

import { getDate } from 'utils/time'
import { RootState } from 'store/store'
import useBoolean from 'utils/useBoolean'
import ProgressBar from 'components/ProgressBar'
import SetStatusDialog from 'modules/Matches/components/Dialogs/SetStatusDialog'

import { useStyles } from './styles'

/**
 * Used to display the start date, time and status of selected match. Contains:
 *  1. Depending on editing option and event status:
 *      - Date, time and status of selected match - selected editing option is RESULT ONLY
 *      - Event Details Timeline - event in progress and selected editing option is LIVE
 *  2. Set Match Button (opens a dialog component to change match status)
 */
const MatchStatus = () => {
  const event: BasicEvent = useSelector((state: RootState) => state.event)
  const { status, startTimestamp, tournament } = event
  const sport = tournament.category.sport.slug

  // Toggle set status dialog
  const [isOpenDialog, { setTrue: openDialog, setFalse: closeDialog }] = useBoolean(false)

  const classes = useStyles()

  const date = event && getDate(startTimestamp)
  const hasProgressBar = event.status.type === StatusType.IN_PROGRESS && sport !== SportName.Volleyball

  return (
    <>
      <Box className={hasProgressBar ? classes.liveStatusContainer : classes.statusContainer} mb={2}>
        {hasProgressBar ? (
          <Box className={classes.progressBar}>
            <ProgressBar event={event} />
          </Box>
        ) : (
          <Box display="flex" justifyContent="space-between">
            <Box display="flex">
              <Typography className={classes.timeInfo}>
                {isToday(date) ? (
                  <FormattedRelativeTime unit="day" numeric="auto" />
                ) : (
                  <FormattedDate year="numeric" month="short" day="2-digit" value={date} />
                )}
              </Typography>
              &nbsp;
              <Typography className={classes.timeInfo}>
                <FormattedTime value={date} />
              </Typography>
            </Box>
            <Box>
              {status.description && status.code !== StatusCode['Not started'] ? (
                <Typography className={classes.statusDescription}>
                  <FormattedStatusDescription id={status.description} />
                </Typography>
              ) : (
                '-'
              )}
            </Box>
          </Box>
        )}
        <Box pt={2} display="flex" justifyContent="flex-end">
          <Button variant="contained" onClick={() => openDialog()}>
            <FormattedMessage id="matchStatus" />
          </Button>
        </Box>
      </Box>

      <SetStatusDialog isOpen={isOpenDialog} handleClose={closeDialog} />
    </>
  )
}

export default MatchStatus
