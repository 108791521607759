import { Text, Image } from '@sofascore/ui'
import styled from 'styled-components'

export const PlayerCardBadge = styled.p`
  position: absolute;
  top: 8px;
  right: -8px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  font-weight: 600;

  width: 24px;
  height: 24px;

  background-color: #fff;
  border-radius: 50%;
  border: 1px solid ${p => p.theme.colors.onSurface.nLv4};
`

export const PlayerName = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  height: 100%;
`

export const PlayerImage = styled(Image)`
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: ${p => p.theme.setAlpha(p.theme.colors.onSurface.nLv1, 0.15)};
`
