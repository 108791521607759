import { Box, Flex, Text } from '@sofascore/ui'
import { useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { CreateIncidentData, IncidentSuspensionType, IncidentType } from 'entities/Incident'
import { useEventState } from 'entities/Event'
import { TeamPicker } from 'entities/Team'
import { useTeamPlayerOptions, PlayerOption, renderPlayerOption, renderSelectedPlayerOption } from 'entities/Player'

import { Button } from 'components/Button'
import WheelPicker from 'components/WheelPicker/WheelPicker'
import {
  formatMatchTimeToOptionValue,
  formatTimeOptionValueToMatchTime,
  generateCurrentFootballMatchTimeOptions,
  getCurrentMatchTime,
} from 'utils/matchTime'
import { Select } from 'components/Select'

interface Props {
  onSubmit: (arg0: CreateIncidentData) => Promise<void>
  onClose: () => void
}

export const AddTwoMinuteSuspensionForm = ({ onSubmit, onClose }: Props) => {
  const { event } = useEventState()
  const { id: eventId, homeTeam, awayTeam } = event
  const currentMatchTime = getCurrentMatchTime(event, 0)
  const intl = useIntl()

  const [isSaving, setIsSaving] = useState(false)
  const [time, setTime] = useState({ time: formatMatchTimeToOptionValue(currentMatchTime) })
  const [player, setPlayer] = useState<PlayerOption | undefined>()
  const [teamId, setTeamId] = useState<number>(homeTeam.id)

  const handleConfirm = () => {
    const incidentTime = formatTimeOptionValueToMatchTime(time.time)

    setIsSaving(true)

    onSubmit({
      eventId,
      incidentData: {
        incidentType: IncidentType.Suspension,
        incidentClass: IncidentSuspensionType.TwoMinutes,
        isHome: homeTeam.id === teamId,
        time: incidentTime.time,
        addedTime: incidentTime.addedTime,
        player: player ? player.value : undefined,
        duration: 120,
      },
    })
      .then(() => onClose())
      .finally(() => setIsSaving(false))
  }

  const [teamPlayersOptions] = useTeamPlayerOptions(teamId)
  const timeOptions = useMemo(() => generateCurrentFootballMatchTimeOptions(event, 0), [event])

  return (
    <Box px="sm" pb="xxl">
      <Box px="sm" pt="lg" pb="sm" mb="lg" br="sm" bg="surface.s2">
        <Flex justify="center" mb="lg">
          <Text font="display.small">
            <FormattedMessage id="confirm_time" />
          </Text>
        </Flex>
        <Box>
          <WheelPicker
            columnsOptions={{ time: timeOptions }}
            columnsValues={time}
            onChange={(_name, option) => {
              setTime({ time: option as string })
            }}
            height={100}
          />
        </Box>
      </Box>

      <Box px="sm" pt="lg" pb="xs" br="sm" bg="surface.s2">
        <Flex mb="xl" justify="center">
          <Text font="display.small">
            <FormattedMessage id="select_player" />
          </Text>
        </Flex>

        <TeamPicker
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          teamId={teamId}
          onChangeTeam={newTeamId => {
            setTeamId(newTeamId)
            setPlayer(undefined)
          }}
        />

        <Select
          label={intl.formatMessage({ id: 'player' })}
          options={teamPlayersOptions}
          helperText="Optional"
          value={player}
          onChange={setPlayer}
          maxVisibleOptions={6}
          w="100%"
          selectBackgroundColor="surface.s1"
          renderOption={renderPlayerOption}
          renderSelectedOption={renderSelectedPlayerOption}
        />
      </Box>

      <Flex gapX="lg" px="lg" mt="xxl">
        <Button variant="outlined" fullWidth onClick={onClose}>
          <FormattedMessage id="cancel" />
        </Button>
        <Button variant="contained" fullWidth onClick={handleConfirm} isLoading={isSaving}>
          <FormattedMessage id="confirm" />
        </Button>
      </Flex>
    </Box>
  )
}
