import { Flex } from '@sofascore/ui'
import { PlayerCard } from 'entities/Player'

import { IconButton } from 'components/IconButton'
import { IconRemoveFilled } from 'components/Icons/IconRemoveFilled'

import { DynamicLineupsPlayer } from '../../model'

interface SubstitutionSlotFilledProps {
  lineupPlayer: DynamicLineupsPlayer
  onRemoveSubstitution: (lineupPlayer: DynamicLineupsPlayer) => void
}

export const SubstitutionSlotFilled = ({ lineupPlayer, onRemoveSubstitution }: SubstitutionSlotFilledProps) => {
  return (
    <Flex justify="space-between" align="center" px={8} py={7}>
      <PlayerCard player={lineupPlayer.player} badgeContent={lineupPlayer.position} />
      <IconButton onClick={() => onRemoveSubstitution(lineupPlayer)} h={30}>
        <IconRemoveFilled />
      </IconButton>
    </Flex>
  )
}
