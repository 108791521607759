import { useEffect, useState, useRef, PropsWithChildren } from 'react'
import { Flex, Text, useMediaContext } from '@sofascore/ui'
import useSWR from 'swr'
import { FormattedMessage } from 'react-intl'
import IconMatches from '@sofascore/ui/dist/modules/Icons/IconMatches'
import IconTrophy16 from '@sofascore/ui/dist/modules/Icons/IconTrophy16'
import IconMenu from '@sofascore/ui/dist/modules/Icons/_IconMenu'
import IconClose from '@sofascore/ui/dist/modules/Icons/_IconClose'
import debounce from 'lodash/debounce'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'
import { FirebaseEventName } from 'shared/lib/firebase/model'
import { LanguagePicker } from 'shared/ui'

import { uniqueTournaments } from 'api/routes'
import { isWhatsNewMenuRead } from 'modules/WhatsNew/utils/rss'
import { isProd } from 'api/constants'
import { IconLogo } from 'components/Icons/IconLogo'
import { IconEditorLogo } from 'components/Icons/IconEditorLogo'
import { IconMegaphone } from 'components/Icons'
import { IconSignOut } from 'components/Icons/IconSignOut'
import { IconButton } from 'components/IconButton'
import { PAGE_HEADER_DESKTOP_HEIGHT, PAGE_HEADER_MOBILE_HEIGHT } from 'components/Layout/PageHeader/config'

import SignOutDialog from './components/SignOutDialog'
import { SettingsMenu } from './components/SettingsMenu'
import * as S from './styles'

interface Props {
  pageName?: string | JSX.Element
  hideMobile?: boolean
}

const PageHeader = ({ pageName, hideMobile, children }: PropsWithChildren<Props>) => {
  const { mutate } = useSWR(uniqueTournaments())
  const { isMobile } = useMediaContext()
  const [isSignOutModalOpen, setIsSignOutModalOpen] = useState(false)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const pageHeaderRef = useRef<HTMLDivElement>(null)
  const logoRef = useRef<HTMLDivElement>(null)
  const mainNavRef = useRef<HTMLDivElement>(null)
  const pageHeaderActionsRef = useRef<HTMLDivElement>(null)

  const [isLogoSmaller, setIsLogoSmaller] = useState(false)

  const handleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen)
  }

  // Dynamically calculate if Editor logo should be in full length or icon only
  useEffect(() => {
    const logoWidth = logoRef?.current?.clientWidth || 0

    const handleWindowResize = debounce(() => {
      const pageHeaderWidth = pageHeaderRef?.current?.clientWidth || 0
      const mainNavWidth = mainNavRef?.current?.clientWidth || 0
      const pageHeaderActionsWidth = pageHeaderActionsRef?.current?.clientWidth || 0

      if (pageHeaderWidth - logoWidth - mainNavWidth - pageHeaderActionsWidth <= 0) {
        setIsLogoSmaller(true)
      } else {
        setIsLogoSmaller(false)
      }
    }, 100)

    // Check on mount / load
    handleWindowResize()

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  if (isMobile && hideMobile) {
    return null
  }

  return (
    <>
      <S.PageHeader forwardedAs="header" h={[PAGE_HEADER_MOBILE_HEIGHT, PAGE_HEADER_DESKTOP_HEIGHT]}>
        {((isMobile && isMobileMenuOpen) || !isMobile) && (
          <S.Links ref={pageHeaderRef}>
            <S.Logo ref={logoRef}>
              <S.LogoNavLink
                to="/home"
                onClick={() => logFirebaseEvent(FirebaseEventName.NavigationAction, { name: 'Home' })}
              >
                {isMobile || !isLogoSmaller ? <IconLogo /> : <IconEditorLogo width={24} height={24} fill="black" />}
              </S.LogoNavLink>
              {isMobile && (
                <IconButton onClick={handleMobileMenu} p="0" right="-16px" position="relative">
                  <IconClose />
                </IconButton>
              )}
            </S.Logo>

            <S.MainNav ref={mainNavRef}>
              <S.Link to="/home" onClick={() => logFirebaseEvent(FirebaseEventName.NavigationAction, { name: 'Home' })}>
                <IconMatches w={20} h={20} mr="sm" />
                <Text font="display.micro" color="inherit">
                  <FormattedMessage id="matches" />
                </Text>
              </S.Link>

              <S.Link
                to="/my-competitions"
                onClick={() => logFirebaseEvent(FirebaseEventName.NavigationAction, { name: 'MyCompetitions' })}
              >
                <IconTrophy16 w={20} h={20} mr="sm" />
                <Text font="display.micro" color="inherit">
                  <FormattedMessage id="my_competitions" />
                </Text>
              </S.Link>

              <S.Link
                to="/whats-new"
                onClick={() => logFirebaseEvent(FirebaseEventName.NavigationAction, { name: 'WhatsNew' })}
              >
                <IconMegaphone width={16} height={16} style={{ marginRight: 8 }} />
                {!isWhatsNewMenuRead() && <S.Dot>{`\u2022`}</S.Dot>}
                <Text font="display.micro" color="inherit">
                  <FormattedMessage id="whats_new" />
                </Text>
              </S.Link>
            </S.MainNav>

            <S.Actions ref={pageHeaderActionsRef}>
              {!isProd && <SettingsMenu onSave={mutate} />}
              <LanguagePicker />
              <S.SignOut onClick={() => setIsSignOutModalOpen(true)}>
                <IconSignOut width={16} height={16} style={{ marginRight: 8 }} />
                <Text font="display.micro" color="inherit">
                  <FormattedMessage id="signOut" />
                </Text>
              </S.SignOut>
            </S.Actions>
          </S.Links>
        )}

        {isMobile && (
          <>
            <Flex w="100%">
              <Text font="display.large" color="inherit" textTransform="uppercase">
                {pageName || ''}
              </Text>
            </Flex>
            {children && (
              <Flex px="lg" justify="flex-end" align="center">
                {children}
              </Flex>
            )}
            <IconButton onClick={handleMobileMenu} p="0">
              <IconMenu />
            </IconButton>
          </>
        )}
      </S.PageHeader>

      <SignOutDialog isOpen={isSignOutModalOpen} handleClose={() => setIsSignOutModalOpen(false)} />
    </>
  )
}

export default PageHeader
