import { Box, Flex, Text } from '@sofascore/ui'
import { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { IncidentGoalType, PointTypeOption, pointTypeOptions, useEventIncidentsState } from 'entities/Incident'
import {
  useTeamPlayerOptions,
  PlayerOption,
  getDefaultOption,
  renderPlayerOption,
  renderSelectedPlayerOption,
} from 'entities/Player'
import { useEventState } from 'entities/Event'

import { Option } from 'components/DropdownMenu/interface'
import IncidentBox from 'components/IncidentBox'
import WheelPicker from 'components/WheelPicker/WheelPicker'
import { Button } from 'components/Button'
import {
  formatMatchTimeToOptionValue,
  formatTimeOptionValueToMatchTime,
  generateFootballGoalMatchTimeOptions,
} from 'utils/matchTime'
import { getLastGoalsFromIncidents } from 'modules/Football/helper'
import { Select } from 'components/Select'

import { EditIncidentFormProps } from '../../model'

export const EditLastPointForm = ({ onSubmit, onClose, incident }: EditIncidentFormProps) => {
  const [isSaving, setIsSaving] = useState(false)

  const { event } = useEventState()
  const { incidents } = useEventIncidentsState()
  const intl = useIntl()

  const { id: eventId, homeTeam, awayTeam } = event
  const {
    id,
    isHome,
    incidentType,
    incidentClass: initialIncidentClass,
    homeScore,
    awayScore,
    player: initialPlayer,
    time: initialTime,
    addedTime: initialAddedTime,
  } = incident

  const [pointTypeOption, setPointTypeOption] = useState<PointTypeOption>(
    pointTypeOptions.find(o => o.goalType === initialIncidentClass)!,
  )

  const [scorer, setScorer] = useState<PlayerOption | undefined>(getDefaultOption(initialPlayer))
  const [matchTime, setMatchTime] = useState(
    initialTime
      ? {
          time: formatMatchTimeToOptionValue({ time: initialTime, addedTime: initialAddedTime }),
        }
      : { time: '1' },
  )

  const teamId = isHome ? homeTeam.id : awayTeam.id
  const isPenaltyTry = pointTypeOption.goalType === IncidentGoalType.PenaltyTry

  const [scorerOptions] = useTeamPlayerOptions(teamId, scorer)

  const timeOptions = useMemo(() => {
    const [lastGoal, secondToLastGoal] = incidents ? getLastGoalsFromIncidents(incidents) : []
    return generateFootballGoalMatchTimeOptions(event, lastGoal!, secondToLastGoal)
  }, [event, incidents])

  const handleConfirm = () => {
    const incidentTime = formatTimeOptionValueToMatchTime(matchTime.time)
    const initialPointType = pointTypeOptions.find(o => o.goalType === initialIncidentClass)!
    const newHomeScore = isHome ? homeScore! - initialPointType.points + pointTypeOption.points : homeScore
    const newAwayScore = isHome ? awayScore : awayScore! - initialPointType.points + pointTypeOption.points
    const updateScore = homeScore !== newHomeScore || awayScore !== newAwayScore

    setIsSaving(true)

    onSubmit({
      eventId: eventId,
      updateScore: updateScore,
      incidentId: id as number,
      incidentData: {
        incidentType,
        incidentClass: pointTypeOption.goalType,
        isHome: isHome as boolean,
        time: incidentTime.time,
        addedTime: incidentTime.addedTime,
        homeScore: newHomeScore,
        awayScore: newAwayScore,
        player: scorer ? scorer.value : undefined,
      },
    })
      .then(() => onClose())
      .finally(() => setIsSaving(false))
  }

  useEffect(() => {
    setScorer(scorerOptions.find(s => s.value.id === initialPlayer?.id))
  }, [initialPlayer, scorerOptions])

  return (
    <Box px="sm" pb="xxl">
      <Box px="sm" pt="lg" pb="sm" mb="lg" br="sm" bg="surface.s2">
        <Flex justify="center" mb="lg">
          <Text font="display.small">
            <FormattedMessage id="confirm_time" />
          </Text>
        </Flex>
        <Box>
          <WheelPicker
            columnsOptions={{ time: timeOptions as Option[] }}
            columnsValues={matchTime}
            onChange={(_name, option) => {
              setMatchTime({ time: option as string })
            }}
            height={100}
          />
        </Box>
      </Box>

      <Box px="lg" pt="lg" pb="sm" br="sm" bg="surface.s2">
        <Flex mb="lg" justify="center">
          <Text font="display.small">
            <FormattedMessage id="points_type_and_scorer" />
          </Text>
        </Flex>

        <Flex mb="lg" justify="flex-start" wrap="wrap">
          {pointTypeOptions.map((option, index) => (
            <IncidentBox
              key={index}
              label={
                <>
                  <Box>
                    <FormattedMessage id={option.translationKey} />
                  </Box>
                  <Box>{`(${option.points} pts)`}</Box>
                </>
              }
              isActive={pointTypeOption.goalType === option.goalType}
              onClick={() => {
                setPointTypeOption(option)
                setScorer(undefined)
              }}
              icon={option.icon}
              mr="sm"
              mt="sm"
            />
          ))}
        </Flex>

        {!isPenaltyTry && (
          <Select
            label={intl.formatMessage({ id: 'player' })}
            options={scorerOptions}
            helperText="Optional"
            value={scorer}
            onChange={option => setScorer(option)}
            maxVisibleOptions={6}
            w="100%"
            selectBackgroundColor="surface.s1"
            renderOption={renderPlayerOption}
            renderSelectedOption={renderSelectedPlayerOption}
          />
        )}
      </Box>

      <Flex gapX="lg" px="lg" mt="xxl">
        <Button variant="outlined" fullWidth onClick={onClose}>
          <FormattedMessage id="cancel" />
        </Button>
        <Button variant="contained" fullWidth onClick={handleConfirm} isLoading={isSaving}>
          <FormattedMessage id="confirm" />
        </Button>
      </Flex>
    </Box>
  )
}
