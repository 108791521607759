import { Box } from '@sofascore/ui'
import { useIntl } from 'react-intl'
import { Search } from 'shared/ui'

export interface Props {
  query?: string
  onChange: (query: string) => void
}

export const CompetitionListSearch = ({ query, onChange }: Props) => {
  const intl = useIntl()

  return (
    <Box bg="surface.s1" br="sm">
      <Search
        label={intl.formatMessage({ id: 'search_competitions' })}
        value={query}
        onChange={onChange}
        bg="surface.s2"
      />
    </Box>
  )
}
