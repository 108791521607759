import styled from 'styled-components'
import { Box } from '@sofascore/ui'

import { backgrounds } from 'styles/color'

import { IconButtonProps } from './interface'

export const IconButton = styled(Box)<IconButtonProps>`
  appearance: none;
  outline: none;
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${p => p.p || '3px'};
  border-radius: ${p => p.br || '50%'};
  background-color: ${p => p.bg || 'transparent'};
  transition: background-color 0.2s ease;

  cursor: pointer;

  &:hover {
    background-color: ${p => p.hoverBg || backgrounds.chip};
  }
`
