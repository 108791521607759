import { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ImageUploadResponse } from 'shared/api'
import { useSelector } from 'react-redux'
import { Flex, Image, Text, Box, Autocomplete, Input } from '@sofascore/ui'
import { CountryListResponseData } from 'entities/Country'
import useSWR from 'swr'
import { Option } from 'shared/model'
import { useToast } from 'shared/lib'
import { EntityType } from 'entities/EntityType'
import { useParams } from 'react-router-dom'
import { TEAM_IMAGE_SIZE } from 'entities/Team'
import { EditorSuggestionAction, SuggestionData } from 'entities/Suggestion'

import { ImageUploader } from 'components/ImageUploader'
import { resizeImage } from 'utils/resize'
import { toBase64 } from 'utils/encode'
import { createSuggestion, deleteImage, uploadImage } from 'api'
import { getAuthFromState } from 'store/selectors'
import { countryListRoute, imageByHash } from 'api/routes'
import { transformCountriesToOptionList } from 'utils/countries'
import { Button } from 'components/Button'
import { useMyCompetitionsState } from 'modules/MyCompetitions/providers/MyCompetitionsProvider'

import { AddTeamFormProps } from '../model'

export const AddNewTeamForm = ({ tournamentId, onSave, onClose }: AddTeamFormProps) => {
  const intl = useIntl()
  const { userAccount } = useSelector(getAuthFromState)
  const { data: countryListResponse } = useSWR<CountryListResponseData>(countryListRoute())
  const { enqueueToast } = useToast()
  const { uniqueTournament } = useMyCompetitionsState()
  const { seasonId, competitionId } = useParams()

  const [imageHash, setImageHash] = useState<string>()
  const [imageUrl, setImageUrl] = useState<string>()
  const [isUploadingImage, setIsUploadingImage] = useState(false)

  const [newTeamName, setNewTeamName] = useState('')
  const [newTeamCountry, setNewTeamCountry] = useState<Option | undefined>()
  const [isSaving, setIsSaving] = useState(false)

  const countryOptions = useMemo(() => {
    return countryListResponse ? transformCountriesToOptionList(countryListResponse.countries) : []
  }, [countryListResponse])

  const postImage = async (file: File) => {
    try {
      const encodedData = await toBase64(file)
      const resizedImageData = await resizeImage(encodedData as string, TEAM_IMAGE_SIZE)
      const resp = (await uploadImage(resizedImageData as string)) as ImageUploadResponse

      setImageHash(resp.md5)
      setIsUploadingImage(false)
    } catch (e) {
      console.error(e)
      enqueueToast(intl.formatMessage({ id: 'toastError' }), { variant: 'error' })
    }
  }

  // Input file upload handler
  const handleInputImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Check file availability
    if (!e.target.files?.[0]) {
      return
    }

    setIsUploadingImage(true)
    const file: File = e.target.files[0]

    // If profile image already exists delete it
    if (imageHash) deleteImage(imageHash)

    // Post new image
    postImage(file)
  }

  const handleCreateTeam = async () => {
    if (userAccount && uniqueTournament) {
      try {
        setIsSaving(true)

        const suggestionData: SuggestionData = {
          entity: EntityType.TEAM,
          name: newTeamName,
          sport: uniqueTournament.category.sport.slug,
          action: EditorSuggestionAction.CREATE,
          uniqueTournamentId: Number(competitionId),
          tournamentId: tournamentId,
          valueMap: {
            seasonId: Number(seasonId),
            country: {
              alpha2: String(newTeamCountry!.value),
            },
          },
        }

        if (imageHash) {
          suggestionData.valueMap.imageHash = imageHash
        }

        await createSuggestion(suggestionData)

        enqueueToast(intl.formatMessage({ id: 'add_or_delete_confirmation_text' }), { variant: 'success' })

        onSave()
        onClose()
      } catch (e) {
        console.error(e)
        enqueueToast(intl.formatMessage({ id: 'toastError' }), { variant: 'error' })
      } finally {
        setIsSaving(false)
      }
    }
  }

  const isFormValid = newTeamName && newTeamCountry && competitionId && seasonId && tournamentId

  useEffect(() => {
    if (imageHash) setImageUrl(imageByHash(imageHash))
  }, [imageHash])

  useEffect(() => {
    if (countryOptions.length && uniqueTournament) {
      setNewTeamCountry(countryOptions.find(o => o.value === uniqueTournament.category.country?.alpha2))
    }
  }, [countryOptions, uniqueTournament])

  return (
    <Box p="lg">
      <Flex direction="column" align="center" gapY="lg" minH={382}>
        <ImageUploader imageUrl={imageUrl} onImageUpload={handleInputImageUpload} isUploading={isUploadingImage} />

        <Box w="100%">
          <Input
            label={intl.formatMessage({ id: 'team_name' })}
            value={newTeamName}
            onChange={value => setNewTeamName(value)}
          />
        </Box>

        <Box w="100%">
          <Autocomplete
            zIndex={10001 /** TODO - Temporary solution, remove once we ditch MUI */}
            label={intl.formatMessage({ id: 'country' })}
            options={countryOptions}
            value={newTeamCountry}
            onChange={setNewTeamCountry}
            maxVisibleOptions={6}
            w="100%"
            renderOption={option => (
              <Flex align="center" px="lg" py="sm">
                <Image
                  width={20}
                  height={20}
                  mr="sm"
                  src={`/images/flags/${option.value.toString().toLowerCase()}.png`}
                />
                <Text color="onSurface.nLv1" font="body.large">
                  {option.label}
                </Text>
              </Flex>
            )}
            startAdornment={
              newTeamCountry ? (
                <Image
                  width={20}
                  height={20}
                  mr="sm"
                  src={`/images/flags/${newTeamCountry.value.toString().toLowerCase()}.png`}
                />
              ) : null
            }
          />
        </Box>
      </Flex>

      <Flex gapX="lg" py="sm" px="xl">
        <Button variant="outlined" fullWidth onClick={onClose}>
          <FormattedMessage id="cancel" />
        </Button>
        <Button variant="contained" fullWidth onClick={handleCreateTeam} isLoading={isSaving} disabled={!isFormValid}>
          <FormattedMessage id="confirm" />
        </Button>
      </Flex>
    </Box>
  )
}
