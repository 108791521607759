import { SportName } from 'entities/Sport'

export const newFormSports = [SportName.Tennis, SportName.Volleyball]

export const froSports = [SportName.Tennis]

export const liveSports = [SportName.Volleyball]

// For new redesigned forms, Football and soon Mini Football
export const formSports = [
  SportName.Football,
  SportName.MiniFootball,
  SportName.Rugby,
  SportName.Futsal,
  SportName.ESports,
]
