/* eslint-disable no-case-declarations */
import { AnyAction } from 'redux'
import { SECOND_HALF_CODE, NOT_STARTED_CODE, ENDED_CODE } from 'entities/Status'
import { BasicEvent } from 'entities/Event'
import { SportName, sportsWithTwoHalves } from 'entities/Sport'

import { statusCodesPeriodMap, CURRENT, PERIOD2, displayableScores, PERIOD1 } from './const'

export const SET_EVENT = 'SET_EVENT'
export const REMOVE_EVENT = 'REMOVE_EVENT'
export const SET_MATCH_STATUS = 'SET_MATCH_STATUS'
export const SET_SCORE = 'SET_SCORE'
export const REMOVE_SCORE_SECTION = 'REMOVE_SCORE_SECTION'
export const SET_CURRENT_PERIOD_START_TIMESTAMP = 'SET_CURRENT_PERIOD_START_TIMESTAMP'
export const SET_AGGREGATED_WINNER_CODE = 'SET_AGGREGATED_WINNER_CODE'
export const SET_ROUND_INFO = 'SET_ROUND_INFO'
export const SET_START_TIMESTAMP = 'SET_START_TIMESTAMP'

export const matchesReducer = (state: BasicEvent | null = null, action: AnyAction) => {
  switch (action.type) {
    case SET_EVENT:
      return { ...action.payload.event }
    case SET_MATCH_STATUS: {
      if (state) {
        const statusCode = action.payload.status.code
        const homeScoreStatusPeriod = state.homeScore[statusCodesPeriodMap[statusCode]]
        const awayScoreStatusPeriod = state.awayScore[statusCodesPeriodMap[statusCode]]
        if (statusCode === NOT_STARTED_CODE) {
          const homeScoreObj = { ...state.homeScore }
          const awayScoreObj = { ...state.awayScore }
          Object.keys(homeScoreObj).forEach(v => (homeScoreObj[v] = null))
          Object.keys(awayScoreObj).forEach(v => (awayScoreObj[v] = null))
          return {
            ...state,
            ...action.payload,
            homeScore: { ...homeScoreObj, period2: null },
            awayScore: { ...awayScoreObj, period2: null },
          }
        } else if (
          (typeof homeScoreStatusPeriod === 'number' && homeScoreStatusPeriod >= 0) ||
          (typeof awayScoreStatusPeriod === 'number' && awayScoreStatusPeriod >= 0) ||
          !(statusCode in statusCodesPeriodMap)
        ) {
          return { ...state, ...action.payload }
        } else {
          const homeCurrent = state.homeScore.current
          const awayCurrent = state.awayScore.current
          const currentExists =
            (typeof homeCurrent === 'number' && homeCurrent >= 0) ||
            (typeof awayCurrent === 'number' && awayCurrent >= 0)
          return {
            ...state,
            ...action.payload,
            homeScore: currentExists
              ? {
                  ...state.homeScore,
                  [statusCodesPeriodMap[statusCode]]: 0,
                }
              : {
                  ...state.homeScore,
                  [statusCodesPeriodMap[statusCode]]: 0,
                  [CURRENT]: 0,
                },
            awayScore: currentExists
              ? {
                  ...state.awayScore,
                  [statusCodesPeriodMap[statusCode]]: 0,
                }
              : {
                  ...state.awayScore,
                  [statusCodesPeriodMap[statusCode]]: 0,
                  [CURRENT]: 0,
                },
          }
        }
      }
      return null
    }
    case REMOVE_EVENT:
      return null
    case SET_SCORE:
      let { homeScore, awayScore } = action.payload
      const { periodKey, matchStatusCode } = action.payload
      let matchStatusPeriod = statusCodesPeriodMap[matchStatusCode]
      if (!matchStatusPeriod) {
        matchStatusPeriod = CURRENT
      }

      if (state && state.homeScore && state.awayScore) {
        // Incrementing current result section
        if (periodKey === CURRENT) {
          const invalidScore = homeScore < 0 || awayScore < 0
          const homeScoreObj = { ...state.homeScore }
          const awayScoreObj = { ...state.awayScore }
          // If current score has value smaller than 0 set everything to -1
          if (invalidScore) {
            Object.keys(homeScoreObj).forEach(v => (homeScoreObj[v] = -1))
            Object.keys(awayScoreObj).forEach(v => (awayScoreObj[v] = -1))
            return {
              ...state,
              homeScore: { ...homeScoreObj },
              awayScore: { ...awayScoreObj },
            }
          }
          // Home score for currently selected period
          let matchPeriodHomeScore = homeScore
          const scores = Object.keys(state.homeScore)
          for (let i = 0; i < scores.length; i++) {
            if (!displayableScores.includes(scores[i])) {
              continue
            }
            if (scores[i] !== matchStatusPeriod && scores[i] !== CURRENT) {
              if (typeof state.homeScore[scores[i]] !== 'number' || state.homeScore[scores[i]] < 0) {
                continue
              }
              matchPeriodHomeScore -= state.homeScore[scores[i]]
            }
          }
          // Away score for currently selected period
          let matchPeriodAwayScore = awayScore
          for (let i = 0; i < scores.length; i++) {
            if (!displayableScores.includes(scores[i])) {
              continue
            }
            if (scores[i] !== matchStatusPeriod && scores[i] !== CURRENT) {
              if (typeof state.awayScore[scores[i]] !== 'number' || state.awayScore[scores[i]] < 0) {
                continue
              }
              matchPeriodAwayScore -= state.awayScore[scores[i]]
            }
          }
          // If current match status is on some of possible periods we need to increment
          // that period score also
          return matchStatusPeriod
            ? {
                ...state,
                homeScore: {
                  ...state.homeScore,
                  [matchStatusPeriod]: matchPeriodHomeScore,
                  [CURRENT]: homeScore,
                },
                awayScore: {
                  ...state.awayScore,
                  [matchStatusPeriod]: matchPeriodAwayScore,
                  [CURRENT]: awayScore,
                },
              }
            : state
        } else {
          const sportSlug = state.tournament.category.sport.slug
          const hasTwoPeriods = sportsWithTwoHalves.includes(sportSlug as SportName)

          if (periodKey === PERIOD2 && matchStatusCode !== SECOND_HALF_CODE && hasTwoPeriods) {
            if (typeof state.homeScore.period1 === 'number' && state.homeScore.period1 > 0) {
              homeScore -= state.homeScore.period1
            }
            if (typeof state.awayScore.period1 === 'number' && state.awayScore.period1 > 0) {
              awayScore -= state.awayScore.period1
            }
          }

          let scoreHomeDiff =
            typeof state.homeScore[periodKey] !== 'number' || state.homeScore[periodKey] < 0
              ? homeScore
              : homeScore - state.homeScore[periodKey]
          let scoreAwayDiff =
            typeof state.awayScore[periodKey] !== 'number' || state.awayScore[periodKey] < 0
              ? awayScore
              : awayScore - state.awayScore[periodKey]

          if ((scoreHomeDiff <= 0 && homeScore < 0) || (scoreHomeDiff > 0 && homeScore === 0)) {
            scoreHomeDiff = 0
          }

          if ((scoreAwayDiff <= 0 && awayScore < 0) || (scoreAwayDiff > 0 && awayScore === 0)) {
            scoreAwayDiff = 0
          }

          // Dont accumulate period points in tennis
          let newCurrentHomeScore

          if (typeof state.homeScore.current === 'number') {
            if (state.homeScore.current === -1 && scoreHomeDiff === 0) {
              newCurrentHomeScore = 0
            } else if (state.homeScore.current === 0 && (homeScore < 0 || awayScore < 0)) {
              newCurrentHomeScore = -1
            } else {
              if (sportSlug === SportName.Tennis || sportSlug === SportName.Volleyball) {
                newCurrentHomeScore = state.homeScore.current < 0 ? scoreHomeDiff : state.homeScore.current
              } else {
                newCurrentHomeScore =
                  state.homeScore.current < 0 ? scoreHomeDiff : state.homeScore.current + scoreHomeDiff
              }
            }
          } else {
            newCurrentHomeScore =
              periodKey !== PERIOD2 && hasTwoPeriods && (homeScore < 0 || awayScore < 0) ? -1 : homeScore
          }

          let newCurrentAwayScore
          if (typeof state.awayScore.current === 'number') {
            if (state.awayScore.current === -1 && scoreAwayDiff === 0) {
              newCurrentAwayScore = 0
            } else if (state.awayScore.current === 0 && (homeScore < 0 || awayScore < 0)) {
              newCurrentAwayScore = -1
            } else if (sportSlug === SportName.Tennis || sportSlug === SportName.Volleyball) {
              newCurrentAwayScore = state.awayScore.current < 0 ? scoreAwayDiff : state.awayScore.current
            } else {
              newCurrentAwayScore =
                state.awayScore.current < 0 ? scoreAwayDiff : state.awayScore.current + scoreAwayDiff
            }
          } else {
            newCurrentAwayScore =
              periodKey !== PERIOD2 && hasTwoPeriods && (homeScore < 0 || awayScore < 0) ? -1 : awayScore
          }

          if (periodKey !== PERIOD2 && hasTwoPeriods) {
            if (homeScore < 0 && awayScore > 0 && newCurrentAwayScore > 0) {
              newCurrentAwayScore -= awayScore
              if (newCurrentHomeScore < 0) {
                newCurrentAwayScore = -1
              }
            }
            if (awayScore < 0 && homeScore > 0 && newCurrentHomeScore > 0) {
              newCurrentHomeScore -= homeScore
              if (newCurrentAwayScore < 0) {
                newCurrentHomeScore = -1
              }
            }
          }

          if (matchStatusCode === ENDED_CODE && hasTwoPeriods && periodKey === PERIOD1) {
            if (typeof state.homeScore.current === 'number' && typeof state.awayScore.current === 'number') {
              return {
                ...state,
                homeScore: {
                  ...state.homeScore,
                  [periodKey]: homeScore < 0 || awayScore < 0 ? -1 : homeScore,
                  [CURRENT]: homeScore <= state.homeScore.current ? state.homeScore.current : homeScore,
                },
                awayScore: {
                  ...state.awayScore,
                  [periodKey]: homeScore < 0 || awayScore < 0 ? -1 : awayScore,
                  [CURRENT]: awayScore <= state.awayScore.current ? state.awayScore.current : awayScore,
                },
              }
            } else {
              return {
                ...state,
                homeScore: {
                  ...state.homeScore,
                  [periodKey]: homeScore,
                  [CURRENT]: homeScore,
                },
                awayScore: {
                  ...state.awayScore,
                  [periodKey]: awayScore,
                  [CURRENT]: awayScore,
                },
              }
            }
          }
          return {
            ...state,
            homeScore: {
              ...state.homeScore,
              [periodKey]: periodKey !== PERIOD2 && hasTwoPeriods && (homeScore < 0 || awayScore < 0) ? -1 : homeScore,
              [CURRENT]: newCurrentHomeScore,
            },
            awayScore: {
              ...state.awayScore,
              [periodKey]: periodKey !== PERIOD2 && hasTwoPeriods && (homeScore < 0 || awayScore < 0) ? -1 : awayScore,
              [CURRENT]: newCurrentAwayScore,
            },
          }
        }
      }
      return state

    case REMOVE_SCORE_SECTION: {
      const { periodToRemove } = action.payload
      if (state) {
        const homeScoreToBeRemoved = state.homeScore[periodToRemove]
        const awayScoreToBeRemoved = state.awayScore[periodToRemove]

        if (typeof state.homeScore.current === 'number' && typeof state.awayScore.current === 'number') {
          let newCurrentHomeScore = state.homeScore.current
          if (homeScoreToBeRemoved > 0) {
            newCurrentHomeScore -= homeScoreToBeRemoved
          }
          let newCurrentAwayScore = state.awayScore.current
          if (awayScoreToBeRemoved > 0) {
            newCurrentAwayScore -= awayScoreToBeRemoved
          }

          const newHomeScore = { ...state.homeScore }
          const newAwayScore = { ...state.awayScore }

          // Removed scores (extra1, extra2, penalties and overtime) must be marked
          // specifically so backend knows to not send it next time
          newHomeScore[periodToRemove] = null
          newAwayScore[periodToRemove] = null
          newHomeScore.current = newCurrentHomeScore
          newAwayScore.current = newCurrentAwayScore
          return {
            ...state,
            homeScore: {
              ...newHomeScore,
            },
            awayScore: {
              ...newAwayScore,
            },
          }
        }
      }

      return null
    }

    case SET_CURRENT_PERIOD_START_TIMESTAMP: {
      if (state) {
        const { periodStartTime } = action.payload
        return {
          ...state,
          currentPeriodStartTimestamp: periodStartTime ? Math.round(periodStartTime.getTime() / 1000) : null,
        }
      }
      return null
    }

    case SET_AGGREGATED_WINNER_CODE:
    case SET_ROUND_INFO:
    case SET_START_TIMESTAMP:
      return { ...state, ...action.payload }

    default:
      return state
  }
}
