import { Outlet, useParams } from 'react-router-dom'
import useSWR from 'swr'
import { Box } from '@sofascore/ui'
import { useEffect } from 'react'

import { PageContent } from 'components/Layout/PageContent'
import { PageHeader } from 'components/Layout/PageHeader'
import { PAGE_HEADER_DESKTOP_HEIGHT } from 'components/Layout/PageHeader/config'
import { teamDetails } from 'api/routes'
import { TeamDetailsResponse } from 'modules/Teams/interface'
import { CompetitionList } from 'modules/MyCompetitions/components/CompetitionList'
import { Loading } from 'components/Loading'

import { TEAM_DETAILS_HEADER_HEIGHT } from './TeamDetailsHeader/config'
import TeamDetailsHeader from './TeamDetailsHeader'
import { TeamProvider, useTeamDispatch } from './TeamContext'
import { setTeam } from './TeamContext/actions'
import * as S from './styles'

const TeamDetails = () => {
  const { teamId } = useParams()

  const dispatch = useTeamDispatch()

  const { data: teamResponse, error } = useSWR<TeamDetailsResponse>(teamId ? teamDetails(+teamId) : null)

  useEffect(() => {
    const team = teamResponse?.team

    if (team) {
      dispatch(setTeam({ team }))
    }
  }, [teamResponse, dispatch])

  if (error?.error?.code) {
    throw Error(error?.error?.message)
  }

  return (
    <>
      <PageHeader hideMobile={true} />

      <PageContent pt={[TEAM_DETAILS_HEADER_HEIGHT, PAGE_HEADER_DESKTOP_HEIGHT]}>
        <S.TeamDetailsContainer>
          <CompetitionList hideMobile={true} />

          <Box maxW={['100%', 700]} w="100%" mx={[0, 'lg']}>
            {!error && !teamResponse && <Loading />}

            {!error && teamResponse && (
              <>
                <TeamDetailsHeader />
                <Outlet />
              </>
            )}
          </Box>
        </S.TeamDetailsContainer>
      </PageContent>
    </>
  )
}

const TeamDetailsContainer = () => {
  return (
    <TeamProvider>
      <TeamDetails />
    </TeamProvider>
  )
}

export default TeamDetailsContainer
