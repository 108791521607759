import { PropsWithChildren } from 'react'
import { Flex } from '@sofascore/ui'

import * as S from './styles'

interface Props {
  primary?: boolean
  disabled?: boolean
  isSaving?: boolean
  onClick?: () => void
}

export const ControlButton = ({ primary, disabled, isSaving, onClick, children }: PropsWithChildren<Props>) => {
  return (
    <S.StandingsControl
      size="xs"
      variant="unshielded"
      colorScheme={primary ? 'primary' : 'neutral'}
      h={32}
      br="sm"
      disabled={disabled}
      isSaving={isSaving}
      style={{ textTransform: 'none' }}
      onClick={onClick}
    >
      <Flex h="100%" align="center" bg={primary ? 'primary.default' : 'surface.s2'} px="md" py="xs" br="sm">
        {children}
      </Flex>
    </S.StandingsControl>
  )
}
