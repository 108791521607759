import { ScoreWithPeriods } from 'entities/Score'

import { textPrimary } from 'styles/color'

/**
 * Utility function for determining colors of next result. Used in Add Goal form in edit match.
 *
 * @param isHomeSide flag that incidates if we are calculating home team result
 * @param isHomeIncrement flag that indicates if we are adding goal (incrementing) home team
 */
export const getNextResultColoring = (isHomeSide: boolean, isHomeIncrement: boolean) => {
  if (isHomeIncrement) {
    return isHomeSide ? textPrimary : 'onSurface.nLv3'
  }

  return isHomeSide ? 'onSurface.nLv3' : textPrimary
}

/**
 * Utility function for determining next result. Used in Add Goal form in edit match.
 *
 * @param score Current score of team
 * @param isHomeSide flag that incidates if we are coloring home team result
 * @param isHomeIncrement flag that indicates if we are adding goal (incrementing) home team
 */
export const getNextResult = (
  score: ScoreWithPeriods,
  isHomeSide: boolean,
  isHomeIncrement: boolean,
  increment = 1,
) => {
  let _score = score.display

  if (_score === undefined) _score = 0

  if (isHomeIncrement) {
    return isHomeSide ? _score + increment : _score
  }

  return isHomeSide ? _score : _score + increment
}
