import { PropsWithChildren } from 'react'
import { Flex } from '@sofascore/ui'

export const LineupsActionsContainer = ({ children }: PropsWithChildren) => {
  return (
    <Flex
      direction={['column', 'row']}
      justify="center"
      position="fixed"
      p="lg"
      left={0}
      bottom={0}
      w="100%"
      bg="surface.s1"
      elevation={1}
      zIndex={3}
      style={{ gap: 16 }}
    >
      {children}
    </Flex>
  )
}
