import styled from 'styled-components'
import { Box } from '@sofascore/ui'

export const GameTimeToggleButton = styled(Box)<{ $isActive?: boolean; $isDisabled?: boolean }>`
  position: relative;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  font: ${p => p.theme.typography.font.display.small};
  color: ${p => (p.$isActive ? p.theme.colors.onSurface.nLv1 : p.theme.colors.onSurface.nLv3)};
  border-width: 1px;
  border-style: solid;
  border-color: ${p => (p.$isActive ? p.theme.colors.onSurface.nLv1 : p.theme.colors.onSurface.nLv4)};
  cursor: ${p => (p.$isDisabled ? 'default' : p.$isActive ? 'auto' : 'pointer')};
  transition: all 0.2s;
  background-color: #ffffff;

  &:hover {
    border-color: ${p => (p.$isDisabled ? p.theme.colors.onSurface.nLv4 : p.theme.colors.onSurface.nLv1)};
    color: ${p => (p.$isDisabled ? p.theme.colors.onSurface.nLv3 : p.theme.colors.onSurface.nLv1)};
  }
`
