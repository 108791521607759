import { Flex, Image, Text } from '@sofascore/ui'
import { getTeamCountryFlag } from 'entities/Team'
import { useIntl } from 'react-intl'
import slugify from 'slugify'

import { Country } from '../model'
import { getAlpha3 } from '../utils'

export const CountryInfo = ({ country }: { country: Country }) => {
  const intl = useIntl()

  return (
    <Flex align="center" gapX={4}>
      <Image
        src={getTeamCountryFlag(country)}
        w="16"
        h="16"
        alt={country?.name ? intl.formatMessage({ id: slugify(country.name.toLowerCase()) }) : ''}
      />
      {country?.alpha2 && (
        <Text font="assistive.default" color="onSurface.nLv3">
          {getAlpha3(country.alpha2)}
        </Text>
      )}
    </Flex>
  )
}
