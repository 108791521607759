import { Outlet } from 'react-router-dom'

import { MyCompetitionsProvider } from 'modules/MyCompetitions/providers/MyCompetitionsProvider'

/**
 * This component is used to wrap multiple routes with the contexts in the app router
 */
const MyCompetitionsContextLayout = () => {
  return (
    <MyCompetitionsProvider>
      <Outlet />
    </MyCompetitionsProvider>
  )
}

export default MyCompetitionsContextLayout
