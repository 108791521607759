import { Player } from 'entities/Player'

import { DynamicLineupsPlayer } from '../model'

export type GroupedPlayers = Record<string, DynamicLineupsPlayer[]>

export const sortLineupPlayers = (players: DynamicLineupsPlayer[]) => {
  return players.sort((a, b) => {
    if (a.player.jerseyNumber && b.player.jerseyNumber) {
      return +a.player.jerseyNumber - +b.player.jerseyNumber
    }

    if (a.player.jerseyNumber && !b.player.jerseyNumber) {
      return -1
    }

    if (!a.player.jerseyNumber && b.player.jerseyNumber) {
      return 1
    }

    return a.player.name.localeCompare(b.player.name)
  })
}

export const groupPlayersByPosition = (players: DynamicLineupsPlayer[]) => {
  return players.reduce((groupedPlayers: GroupedPlayers, currentPlayer: DynamicLineupsPlayer) => {
    const group = currentPlayer.player.position || '-'

    if (groupedPlayers[group]) {
      groupedPlayers[group].push(currentPlayer)
    } else {
      groupedPlayers[group] = [currentPlayer]
    }

    return groupedPlayers
  }, {})
}

export const toDynamicLineupPlayers = (players: Player[], isHomePlayer: boolean) => {
  return players.map<DynamicLineupsPlayer>(p => {
    return {
      player: p,
      playerTeam: isHomePlayer ? 1 : 2,
      position: null,
      substitute: false,
      captain: false,
      jerseyNumber: p.jerseyNumber,
      missingReason: null,
    }
  })
}

/**
 * Used for merging lineup players received from lineups route with all team players.
 * @param lineupPlayers Lineup players
 * @param allPlayers All available players
 */
export const mergePlayers = (lineupPlayers: DynamicLineupsPlayer[], allPlayers: DynamicLineupsPlayer[]) => {
  return allPlayers.map(ap => {
    const existingPlayer = lineupPlayers.find(lp => ap.player.id === lp.player.id)

    if (existingPlayer) {
      return existingPlayer
    }

    return ap
  })
}

// If we created lineup with player list type, the response will return all
// kit colors as black, meaning if user switches to pitch lineups then default
// values of player and goalkeepers kit will be black which is not wanted
export const getKit = (initialKit: string | undefined, fallbackKit: string) => {
  return initialKit && initialKit !== '#000000' ? initialKit : fallbackKit
}
