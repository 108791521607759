import useSWR from 'swr'
import { BasicTeam } from 'entities/Team'
import { useParams } from 'react-router-dom'

import { tournamentTeams } from 'api/routes'
import { Option } from 'components/DropdownMenu/interface'

import { useCreateMatchState } from '../context/createMatchContext'
import { TeamsResponse } from '../../interface'

const mapTeamsToOptions = (teams: BasicTeam[]) => {
  return teams
    .map<Option>(team => ({ label: team.name, value: team.id }))
    .sort((team1, team2) => team1.label.localeCompare(team2.label))
}

export default function useTeamOptions() {
  const { competitionId, seasonId } = useParams()
  const { selectedTournament } = useCreateMatchState()

  const { data: teamsData, isLoading } = useSWR<TeamsResponse>(
    competitionId && seasonId && selectedTournament
      ? tournamentTeams(+competitionId, selectedTournament.id, +seasonId)
      : null,
  )

  const teamOptions = teamsData ? mapTeamsToOptions(teamsData.teams) : []

  return {
    teamOptions,
    isLoading,
  }
}
