import { scaleTime } from 'd3-scale'
import { Box, Flex, Text } from '@sofascore/ui'
import { FormattedDate, FormattedMessage } from 'react-intl'
import { BasicSeason } from 'entities/Season'

interface Props {
  startTimestamp?: number
  endTimestamp?: number
  seasonFinished?: boolean
  season?: BasicSeason
}

const SeasonDuration = ({ startTimestamp, endTimestamp, seasonFinished, season }: Props) => {
  const scale = scaleTime().range([0, 100])

  const now = Date.now() / 1000

  if (startTimestamp && endTimestamp) {
    scale.domain([startTimestamp, endTimestamp])
  }

  let scaledNow = 0
  if (startTimestamp && startTimestamp < now) {
    scaledNow = Math.min(scale(now), 100)
  }

  return (
    <Flex direction="column" w="100%" p="lg" br="sm" bg="surface.s1">
      <Text font="display.medium" mb="lg">
        <FormattedMessage id="duration" />
      </Text>
      <Box h="4" w="100%" br="2px" bg="onSurface.nLv4" position="relative" mb="sm">
        {startTimestamp && endTimestamp && <Box h="4" w={`${scaledNow}%`} br="2px" bg="primary.default" />}
      </Box>

      <Flex justify="space-between">
        <Text font="body.small" color="onSurface.nLv3" fontWeight={500}>
          {startTimestamp ? <FormattedDate value={startTimestamp * 1000} day="numeric" month="short" /> : '-'}
        </Text>
        <Box>
          <Text font="body.small" color="onSurface.nLv3" fontWeight={500}>
            {endTimestamp ? <FormattedDate value={endTimestamp * 1000} day="numeric" month="short" /> : '-'}
            {seasonFinished && ' - '}
          </Text>
          {seasonFinished && (
            <Text font="body.small" color="onSurface.nLv1" fontWeight={500}>
              <FormattedMessage id="seasonFinished" values={{ seasonName: season?.name }} />
            </Text>
          )}
        </Box>
      </Flex>
    </Flex>
  )
}

export default SeasonDuration
