import { IconProps, IconBase } from './Base'

const IconSubstitutionTemporary = ({ fill = '#384DF5' }: IconProps) => {
  return (
    <IconBase width={24} height={24} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path d="m17 2 4 4-4 4h-1V7H6v5H4V7l2-2h10V2z" fill={fill} />
        <path d="M6 10h14v14H6z" />
        <path
          d="M12.994 11.167A5.837 5.837 0 0 1 18.834 17a5.837 5.837 0 0 1-5.84 5.833A5.83 5.83 0 0 1 7.167 17a5.83 5.83 0 0 1 5.827-5.833zM13 12.333A4.665 4.665 0 0 0 8.333 17 4.665 4.665 0 0 0 13 21.667 4.665 4.665 0 0 0 17.667 17 4.665 4.665 0 0 0 13 12.333zm.292 1.75v3.063l2.625 1.557-.438.718-3.062-1.838v-3.5h.875z"
          fill={fill}
        />
      </g>
    </IconBase>
  )
}

export default IconSubstitutionTemporary
