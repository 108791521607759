import { Text } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'
import { PropsWithChildren } from 'react'
import IconCardDoubleYellow from '@sofascore/ui/dist/modules/Icons/_IconCardDoubleYellow'

import { Incident } from '../../model'
import { IncidentBody, IncidentFrame, IncidentHead, IncidentTail, IncidentTime } from '../'

interface Props {
  incident: Incident
}

export const IncidentYellowRedCard = ({ children, incident }: PropsWithChildren<Props>) => {
  const { isHome, time, addedTime, player } = incident

  return (
    <IncidentFrame>
      <IncidentHead isHome={isHome}>
        <IconCardDoubleYellow width="24" height="24" />
        <IncidentTime addedTime={addedTime} time={time} />
      </IncidentHead>

      <IncidentBody isHome={isHome}>
        <Text align={isHome ? 'left' : 'right'} font="body.medium">
          {player?.name}
          {!player?.name && <FormattedMessage id="2nd_yellow_card" />}
        </Text>
      </IncidentBody>

      <IncidentTail>{children}</IncidentTail>
    </IncidentFrame>
  )
}
