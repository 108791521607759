import { BasicEvent } from 'entities/Event'
import { SportName } from 'entities/Sport'
import { IntlShape } from 'react-intl'
import { ValuesType, getId, getValues } from 'shared/utils'

import {
  BETWEEN_PERIODS,
  ENDED,
  ENDED_CODE,
  FIRST_HALF,
  FIRST_HALF_CODE,
  HALF_TIME_CODE,
  SECOND_HALF,
  SECOND_HALF_CODE,
  SPECIAL_STATUSES,
  STATUS_CODES_BY_SPORT,
} from '../config'
import { StatusCode } from '../model'
import statusDescriptions from '../mappings/statusDescription.json'

export const isSpecialStatus = (statusCode: number) => SPECIAL_STATUSES.includes(statusCode)

/**
 * Method used to specify the label and value that is displayed within the status selector.
 */
export const getStatusOption = (statusRadioValue: string, sport: string) => {
  switch (statusRadioValue) {
    case FIRST_HALF:
      return {
        label: STATUS_CODES_BY_SPORT[sport][FIRST_HALF_CODE].description,
        value: Number(FIRST_HALF_CODE),
      }
    case SECOND_HALF:
      return {
        label: STATUS_CODES_BY_SPORT[sport][SECOND_HALF_CODE].description,
        value: Number(SECOND_HALF_CODE),
      }
    case BETWEEN_PERIODS:
      return {
        label: STATUS_CODES_BY_SPORT[sport][HALF_TIME_CODE].description,
        value: Number(HALF_TIME_CODE),
      }
    case ENDED:
      return {
        label: STATUS_CODES_BY_SPORT[sport][ENDED_CODE].description,
        value: Number(ENDED_CODE),
      }
    default:
      return null
  }
}

export const getStatusDescription = (event: BasicEvent) => {
  switch (event.status.code) {
    case StatusCode['Not started']:
      return '-'
    case StatusCode.Halftime:
      return 'HT'
    case StatusCode.Ended:
      return 'FT'
    case StatusCode.Overtime:
      return 'OT'
    case StatusCode.Removed:
      return 'FT'
    case StatusCode['Awaiting extra time']:
      return 'Aw. ET'
    case StatusCode['Extra time halftime']:
      return 'ET HT'
    case StatusCode['Awaiting penalties']:
      return 'Aw. Pen.'
    case StatusCode['Start delayed']:
      return 'Delayed'
    case StatusCode['First break']:
      return '1. break'
    case StatusCode['Second break']:
      return '2. break'
    case StatusCode['Third break']:
      return '3. break'
    case StatusCode['1st quarter']:
      return 'Q1'
    case StatusCode['2nd quarter']:
      return 'Q2'
    case StatusCode['3rd quarter']:
      return 'Q3'
    case StatusCode['4th quarter']:
      return 'Q4'
    default:
      return event.status.description ?? '-'
  }
}

// NOTE: this formats classic status description such as "Finished", "Canceled" as well as period description such as
// "1st Quarter". The reason is that period description is exactly status description when the match is in progress and
// we want to have a single source of translation mapping for it.

export const getStatusCodeTranslation = (sport: SportName, matchStatusCode: number, intl: IntlShape) => {
  let trans = ''

  try {
    if (matchStatusCode >= 8 && matchStatusCode <= 12) {
      const description = STATUS_CODES_BY_SPORT[sport][matchStatusCode].description
      const [firstWord, secondWord] = description.split(' ')
      const number = firstWord.charAt(0)
      const set = secondWord.toLowerCase()
      trans = `${intl.formatMessage({ id: `period${number}` })} ${intl.formatMessage({ id: set })}`
    } else {
      trans = intl.formatMessage({ id: STATUS_CODES_BY_SPORT[sport][matchStatusCode].description })
    }
  } catch (e) {
    console.error(e)
  }

  return trans
}

export const getStatusDescriptionId = (name: string, short?: boolean): string => {
  const id = getId(statusDescriptions, name)

  if (short) {
    switch (id) {
      case 'ordinalPeriod':
      case 'ordinalHalf':
        return 'ordinalPeriod.short'
      case 'ordinalQuarter':
        return 'qN'
      case 'overtime':
        return 'overtime.short'
      case 'ended':
        return 'fulltime.short'
      default:
        return id
    }
  }
  return id
}

export const getStatusDescriptionValues = (name: string): ValuesType => getValues(statusDescriptions, name)
