import { MatchesTabTitle, useEventState } from 'entities/Event'
import { SportName } from 'entities/Sport'
import { useIntl } from 'react-intl'
import { EventPlayersProvider, useTeamPlayers } from 'entities/Player'
import { Flex, Spinner } from '@sofascore/ui'

import { FootballLineups } from './Football'
import { MiniFootballLineups } from './MiniFootball'
import { RugbyLineups } from './Rugby'

const mapSportToForm: Record<string, () => JSX.Element> = {
  [SportName.Football]: FootballLineups,
  [SportName.ESports]: FootballLineups,
  [SportName.MiniFootball]: MiniFootballLineups,
  [SportName.Futsal]: MiniFootballLineups,
  [SportName.Rugby]: RugbyLineups,
}

export const Lineups = () => {
  const { event } = useEventState()
  const { homeTeam, awayTeam, tournament } = event

  const intl = useIntl()

  const { data: homeTeamData, isLoading: isHomeTeamPlayersLoading } = useTeamPlayers(homeTeam.id)
  const { data: awayTeamData, isLoading: isAwayTeamPlayersLoading } = useTeamPlayers(awayTeam.id)

  if (isHomeTeamPlayersLoading || isAwayTeamPlayersLoading) {
    return (
      <Flex w="100%" h="100%" display="flex" align="center" justify="center" direction="column">
        <Spinner color="#374df5" width="50px" />
      </Flex>
    )
  }

  const players = {
    [homeTeam.id]: homeTeamData ? homeTeamData : [],
    [awayTeam.id]: awayTeamData ? awayTeamData : [],
  }

  const sport = tournament.category.sport.slug
  const LineupsForm = mapSportToForm[sport]

  return (
    <EventPlayersProvider initialState={players}>
      <MatchesTabTitle title={intl.formatMessage({ id: 'lineups' })} />
      <LineupsForm />
    </EventPlayersProvider>
  )
}
