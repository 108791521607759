import { RoundInfo } from 'entities/Event'
import { EntityType } from 'entities/EntityType'

import { getQueryString } from 'utils/url'
import { TeamValue } from 'modules/Matches/interface'
import { EntityFilter } from 'modules/MyCompetitions/MyCompetitionTeams/model'

// #region User
export const loginUser = () => '/user/login'
export const logoutUser = () => '/user/logout'
// #endregion

// #region Auth & token
export const tokenRefresh = () => '/token/refresh'
export const tokenInit = () => '/token/init'
// #endregion

// #region Unique tournaments
export const searchUniqueTournaments = (q: string, sport: string = '') =>
  `/search/unique-tournaments?q=${q}&sport=${sport}`
export const uniqueTournaments = () => `/editor/user-account/unique-tournaments`
export const tournamentsList = (uniqueTournamentId: number, seasonId: number) =>
  `/unique-tournament/${uniqueTournamentId}/season/${seasonId}/tournaments`
export const uniqueTournamentDetails = (uniqueTournamentId: number) => `/editor/unique-tournament/${uniqueTournamentId}`
export const uniqueTournamentPermissions = (uniqueTournamentId: number) =>
  `/editor/permission/unique-tournament/${uniqueTournamentId}`
export const uniqueTournamentSeasons = (uniqueTournamentId: number) =>
  `/editor/unique-tournament/${uniqueTournamentId}/seasons`
export const uniqueTournamentInfo = (uniqueTournamentId: number, seasonId: number) =>
  `/unique-tournament/${uniqueTournamentId}/season/${seasonId}/info`
export const uniqueTournamentTeams = (uniqueTournamentId: number, seasonId: number) =>
  `/unique-tournament/${uniqueTournamentId}/season/${seasonId}/teams`
export const uniqueTournamentRounds = (uniqueTournamentId: number, seasonId: number) =>
  `/unique-tournament/${uniqueTournamentId}/season/${seasonId}/rounds`
// Returns teams that participated in at least one season of UT.
export const uniqueTournamentAllTeams = (competitionId: number) => `/editor/unique-tournament/${competitionId}/teams`
export const uniqueTournamentGroupedTeams = (competitionId: number, seasonId: number) =>
  `/editor/unique-tournament/${competitionId}/season/${seasonId}/teams`
export const uniqueTournamentGroupedSuggestions = (competitionId: number, seasonId: number, status: EntityFilter) =>
  `/editor/unique-tournament/${competitionId}/season/${seasonId}/suggest/${status}/teams`
export const uniqueTournamentImage = (uniqueTournamentId: number) => `/unique-tournament/${uniqueTournamentId}/image`
// #endregion

// #region Tournaments
export const tournamentTeams = (uniqueTournamentId: number, tournamentId: number, seasonId: number) =>
  `/unique-tournament/${uniqueTournamentId}/tournament/${tournamentId}/season/${seasonId}/teams`
export const tournamentRounds = (tournamentId: number, seasonId: number) =>
  `/tournament/${tournamentId}/season/${seasonId}/rounds`
export const tournamentCupRounds = (tournamentId: number) => `/editor/tournament/${tournamentId}/cup-rounds`
export const tournamentCupTree = (tournamentId: number, seasonId: number) =>
  `/tournament/${tournamentId}/season/${seasonId}/cuptree`
// #endregion

// #region Season events
export const eventListEvents = (tournamentId: number, seasonId: number, page = 0, isPast = false) =>
  `/tournament/${tournamentId}/season/${seasonId}/events/${isPast ? 'last' : 'next'}/${page}`

export const eventListEventsByTeam = (
  competitionId: number,
  seasonId: number,
  teamId: number,
  page = 0,
  isPast = false,
) => `/unique-tournament/${competitionId}/season/${seasonId}/team/${teamId}/events/${isPast ? 'last' : 'next'}/${page}`
// #endregion

// #region Round events
export const eventListEventsByRound = (
  tournamentId: number,
  seasonId: number,
  round: RoundInfo,
  page = 0,
  isPast = false,
) => {
  if (round.round && Object.keys(round).length === 1) {
    return `/tournament/${tournamentId}/season/${seasonId}/events/round/${round.round}/${
      isPast ? 'last' : 'next'
    }/${page}`
  }
  if (round.round && round.slug && !round.prefix) {
    return `/tournament/${tournamentId}/season/${seasonId}/events/round/${round.round}/slug/${round.slug}/${
      isPast ? 'last' : 'next'
    }/${page}`
  }
  if (round.round && round.slug && round.prefix) {
    return `/tournament/${tournamentId}/season/${seasonId}/events/round/${round.round}/slug/${round.slug}/prefix/${
      round.prefix
    }/${isPast ? 'last' : 'next'}/${page}`
  }
  if (round.round && round.prefix && !round.slug) {
    return `/tournament/${tournamentId}/season/${seasonId}/events/round/${round.round}/prefix/${round.prefix}/${
      isPast ? 'last' : 'next'
    }/${page}`
  }
  return ''
}
// #endregion

// #region Event
export const editorEvents = (query: Record<string, string | number> = {}) =>
  `/editor/events/daily${getQueryString(query)}`

export const competitionEvents = (
  uniqueTournamentId: string,
  seasonId: string,
  span: 'last' | 'next',
  page: number,
  query?: URLSearchParams,
) =>
  `/editor/events/unique-tournament/${uniqueTournamentId}/season/${seasonId}/${span}/${page}${
    query ? `?${query.toString()}` : ''
  }`

export const createEvent = () => `/editor/events/create`
export const createDoublesEvent = () => `/editor/events/tennis-doubles/create`
export const eventDetails = (eventId: number) => `/editor/event/${eventId}`
export const deleteEvent = (eventId: number) => `/editor/event/${eventId}`
export const updateEvent = (eventId: number, query: Record<string, boolean>) =>
  `/editor/event/${eventId}${getQueryString(query)}`
export const downloadMatchReport = (eventId: number) => `/editor/event/${eventId}/match-report/pdf`
export const getMatchReport = (eventId: number) => `/editor/match-report/data/event/${eventId}`
export const storeMatchReport = () => `/editor/match-report`
export const sendMatchReportEmail = (eventId: number) => `/editor/match-report/event/${eventId}/send`
export const updateMatchInfoRoute = (eventId: string) => `/editor/event/${eventId}/info`
export const postMatchUpdate = (eventId: number) => `/editor/event/${eventId}/post-match`
export const getEventCrowdsourcingSuggests = (eventId: number) => `/editor/event/${eventId}/suggests`
// #endregion

// #region Accumulated Fouls
export const eventAccumulatedFouls = (eventId: number) => `/editor/event/${eventId}/accumulated-fouls`
export const createOrUpdateAccumulatedFoul = (eventId: number) => `/editor/event/${eventId}/accumulated-fouls`

// #region Lineups
export const getLineups = (eventId: number) => `/event/${eventId}/lineups`

export const getEditorLineups = (eventId: number, teamType: TeamValue) => `/editor/event/${eventId}/lineups/${teamType}`
export const createLineups = (eventId: number) => `/editor/event/${eventId}/dynamic-lineups`
// #endregion

// #region Player
export const playerImage = (playerId: number) =>
  window.location.hostname === 'localhost'
    ? `https://master.dev.sofascore.com/api/v1/player/${playerId}/image`
    : `https://api.sofascore.app/api/v1/player/${playerId}/image`
// #endregion

// #region Incidents
export const createIncident = (eventId: number, updateScore?: boolean) =>
  `/editor/event/${eventId}/incident${updateScore ? '?updateScore=1' : ''}`
export const getIncidents = (eventId: number) => `/event/${eventId}/incidents`
export const deleteIncident = (eventId: number, incidentType: string, incidentId: number, updateScore?: boolean) =>
  `/editor/event/${eventId}/incidents/${incidentType}/${incidentId}${updateScore ? '?updateScore=1' : ''}`
export const updateIncident = (eventId: number, incidentId: number, updateScore?: boolean) =>
  `/editor/event/${eventId}/incident/${incidentId}${updateScore ? '?updateScore=1' : ''}`
// #endregion

// #region Team
export const teamSearch = (q: string, sport: string = '') => `/search/teams?q=${q}&sport=${sport}`
export const teamPlayers = (teamId: number) => `/team/${teamId}/players`
export const teamPlayersEditor = (teamId: number) => `/editor/team/${teamId}/players`
export const teamImage = (id: number) => `https://api.sofascore.app/api/v1/team/${id}/image`
export const teamDetails = (teamId: number) => `/editor/team/${teamId}`
export const teamUniqueTournaments = (teamId: number) => `/editor/team/${teamId}/unique-tournaments`
export const teamUniqueTournamentEvents = (teamId: number, uniqueTournamentId: number, pageId: number) =>
  `/editor/team/${teamId}/unique-tournament/${uniqueTournamentId}/events/page/${pageId}`
export const teamPlayersSuggestions = (teamId: number, status: EntityFilter) =>
  `/editor/team/${teamId}/suggest/${status}/players`
export const teamCreatePlayerPermissionCheck = (teamId: number) =>
  `/editor/permission/team/${teamId}/player-create-check`
// #endregion

// #region Image
export const imageByHash = (imageHash: string) =>
  window.location.hostname === 'localhost'
    ? `http://master.dev.sofascore.dev/i/${imageHash}`
    : `https://www.sofascore.com/i/${imageHash}`
export const image = () => '/editor/image'
export const deleteImage = (hash: string) => `/editor/image/${hash}`
// #endregion

// #region Season
export const seasonInfo = (seasonID: number) => `/editor/season/${seasonID}`
// #endregion

// #region Suggestion
export const createSuggestion = () => `/editor/suggestion`
export const deleteSuggestion = (suggestId: number) => `/editor/suggestion/${suggestId}`
export const createUserSuggestion = (entityType: EntityType, entityId: number) => `/suggest/${entityType}/${entityId}`
export const suggestions = (entityType: EntityType, userId: string) =>
  `/editor/user-account/${userId}/suggestion/${entityType}/all`
export const lastCreatedEvents = (userId: string) => `/editor/user-account/${userId}/events/last-created`
// #endregion

// #region Standings
export const standings = (tournamentId: number, seasonId: number) =>
  `/tournament/${tournamentId}/season/${seasonId}/standings/total`
export const standingsEditor = (uniqueTournamentId: number, seasonId: number) =>
  `/editor/unique-tournament/${uniqueTournamentId}/season/${seasonId}/standings/total`
export const calculateStandings = () => `/editor/standings/calculate`
export const sortStandings = (standingsId: number) => `/editor/standings/${standingsId}/sort`
// #endregion

export const createSeason = (uniqueTournamentId: number) =>
  `/editor/unique-tournament/${uniqueTournamentId}/season/create`

// Whats new feed
export const rssFeed = () => '/editor/info/web'

// Country list
export const countryListRoute = () => '/countries/all'

export const contact = () => '/editor/contact'
export const daysWithEditorEvents = (year: string, month: string) =>
  `/editor/calendar/${year}/${month}/days-with-events`
export const daysWithEditorEventsRange = () => `/editor/calendar/days-with-events`

// #region App
export const getGitBranches = () => '/app/branches'
// #endregion App
