import * as React from 'react'
import { Box, IconButton } from '@material-ui/core'

import { IconMinus } from 'components/Icons/IconMinus'
import { IconPlus } from 'components/Icons/IconPlus'
import { neutral } from 'styles/color'

import { CounterProps, ScoreCounterProps, WritableCounterProps, Direction } from './interface'
import { WritableCounterInput, useStyles } from './styles'

const MIN_COUNTER_VALUE = 0

const Counter = ({ value, canDecrement, canIncrement, style, onChange, children, direction }: CounterProps) => {
  const isDirectionColumn = direction === Direction.COLUMN

  const classes = useStyles({ isDirectionColumn })

  return (
    <Box className={classes.counterContainer} style={style}>
      <Box className={classes.operationItem} borderTop={isDirectionColumn ? `2px solid ${neutral}` : ''}>
        <IconButton
          className={classes.iconButton}
          disabled={!canDecrement}
          style={canDecrement ? {} : { opacity: 0.5 }}
          onClick={() => onChange(value - 1)}
        >
          <IconMinus />
        </IconButton>
      </Box>
      {children}
      <Box className={classes.operationItem} borderBottom={isDirectionColumn ? `2px solid ${neutral}` : ''}>
        <IconButton
          className={classes.iconButton}
          onClick={() => onChange(value + 1)}
          disabled={!canIncrement}
          style={canIncrement ? {} : { opacity: 0.5 }}
        >
          <IconPlus />
        </IconButton>
      </Box>
    </Box>
  )
}

const WritableCounter = (props: WritableCounterProps) => {
  const { value, onChange, canDecrement, canIncrement, isTime, direction, disabled } = props

  const onInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = Number(event.target.value)

      if (!Number.isInteger(newValue)) {
        onChange(Math.floor(newValue))
      } else {
        onChange(newValue)
      }
    },
    [onChange],
  )

  let inputValue = ''
  let inputError = false

  if (isTime) {
    inputValue = String(value)
    inputError = !canDecrement
  } else {
    inputValue = canDecrement ? String(value) : ''
  }

  return (
    <Counter
      {...props}
      direction={direction}
      canDecrement={disabled ? false : canDecrement}
      canIncrement={disabled ? false : canIncrement}
    >
      <WritableCounterInput
        name="timeCounter"
        onChange={onInputChange}
        value={inputValue}
        inputProps={{
          min: MIN_COUNTER_VALUE,
        }}
        placeholder={'-'}
        error={inputError}
        disabled={disabled}
        direction={direction}
      />
    </Counter>
  )
}

export const ScoreCounter = (props: ScoreCounterProps) => {
  const { value, onChange, style, direction, disabled } = props
  const isValid = value > -1

  return (
    <WritableCounter
      value={value}
      onChange={onChange}
      canIncrement={true}
      canDecrement={isValid}
      style={style}
      direction={direction}
      disabled={disabled}
    />
  )
}
