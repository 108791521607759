import styled from 'styled-components'
import { Flex } from '@sofascore/ui'

import { backgrounds } from 'styles/color'

const mobile = '@media (max-width: 959.95px)'

export const HomeTeamBox = styled(Flex)`
  display: grid;
  grid-template-columns: auto 72px;
  grid-template-rows: auto auto;
  width: 38%;

  ${mobile} {
    grid-template-columns: 56px auto;
  }
`

export const HomeTeamBoxName = styled(Flex)`
  ${mobile} {
    grid-column-start: span 2;
  }
`

export const HomeTeamBoxAddScore = styled(Flex)`
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: 3;

  ${mobile} {
    grid-column-start: 2;
    grid-row-start: 2;
  }
`
export const AwayTeamBox = styled(Flex)`
  display: grid;
  grid-template-columns: 72px auto;
  grid-template-rows: auto auto;
  width: 38%;

  ${mobile} {
    grid-template-columns: auto 56px;
  }
`

export const AwayTeamBoxName = styled(Flex)`
  ${mobile} {
    grid-column-start: span 2;
  }
`

export const AwayTeamBoxAddScore = styled(Flex)`
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row-end: 3;

  ${mobile} {
    grid-column-start: 1;
    grid-row-start: 2;
  }
`

export const AddScoreButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${backgrounds.chip};
  border: 1px solid ${backgrounds.chip};
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  transition: all 0.2s;
  width: 72px;
  height: 72px;

  &:hover {
    border-color: #333333;
  }

  ${mobile} {
    width: 32px;
    height: 32px;
  }
`

export const AddScoreButtonFRO = styled(AddScoreButton)`
  width: 32px;
  height: 32px;
`
