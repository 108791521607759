import { Flex, Text } from '@sofascore/ui'
import { useIntl } from 'react-intl'

import { Option } from 'components/DropdownMenu/interface'
import { IconShirt } from 'components/Icons/IconShirt'
import { Select } from 'components/Select'
import { backgrounds } from 'styles/color'

import { SET_KIT, useLineupsDispatch, useLineupsState } from '../../providers'

export const LineupsKitSelector = ({ isHomeTeam, options }: { isHomeTeam: boolean; options: Option[] }) => {
  const { homeKit, awayKit } = useLineupsState()
  const dispatch = useLineupsDispatch()
  const intl = useIntl()

  const kit = isHomeTeam ? homeKit : awayKit
  const selectedKit = options.find(k => k.value === kit)!

  const setHomeKit = (value: string) => {
    dispatch({
      type: SET_KIT,
      payload: { kit: value, isHome: isHomeTeam },
    })
  }

  return (
    <Select
      value={selectedKit}
      onChange={option => setHomeKit(option.value.toString())}
      options={options}
      label={intl.formatMessage({ id: 'editor_kit_colour' })}
      renderOption={option => (
        <Flex px="lg" py="xs" align="center" gapX="sm">
          <IconShirt fill={option.value.toString()} />
          <Text>{option.label}</Text>
        </Flex>
      )}
      renderSelectedOption={option => (
        <Flex align="center" gapX="sm">
          <IconShirt fill={option?.value.toString()} />
          <Text>{option?.label}</Text>
        </Flex>
      )}
      selectBackgroundColor={backgrounds.chip}
      disableClear
    />
  )
}
