import { logFirebaseEvent } from 'shared/lib/firebase/utils'
import { FirebaseEventName, FirebaseEventType, FirebaseEventIncidentAction } from 'shared/lib/firebase/model'
import { useEventState } from 'entities/Event'

export const useFirebaseWithEvent = () => {
  const { event } = useEventState()

  const logFirebaseWithEvent = (
    firebaseEvent: FirebaseEventName,
    firebaseType: FirebaseEventType,
    firebaseIncidentAction?: FirebaseEventIncidentAction,
  ) => {
    const firebasePayload = {
      type: firebaseType,
      action: firebaseIncidentAction,
    }
    if (firebaseEvent !== FirebaseEventName.AddEntity) {
      firebasePayload['id'] = event?.id
      firebasePayload['sport'] = event?.tournament?.category?.sport?.name?.toLowerCase()
      firebasePayload['status'] = event?.status?.type
    }

    logFirebaseEvent(firebaseEvent, firebasePayload)
  }

  return logFirebaseWithEvent
}
