import { UpdateIncidentData, Incident, IncidentInGamePenaltyType, IncidentType } from 'entities/Incident'

export const generateIncident = (data: UpdateIncidentData) => {
  const { incidentData, incidentId } = data
  const { incidentType } = incidentData
  const {
    isHome,
    incidentClass,
    time,
    addedTime,
    homeScore,
    awayScore,
    player,
    assist1,
    playerIn,
    playerOut,
    reasonDescription,
    reason,
    description,
    sequence,
    duration,
  } = incidentData

  const incident: Incident = {
    id: incidentId || new Date().valueOf(),
    incidentType,
    incidentClass,
    isHome,
    time,
    addedTime,
  }

  if (
    incidentType === IncidentType.Goal ||
    incidentType === IncidentType.Card ||
    incidentType === IncidentType.Suspension ||
    incidentType === IncidentType.InGamePenalty ||
    incidentType === IncidentType.PenaltyShootout
  ) {
    if (player) {
      incident.player = player
    }
  }

  if (incidentType === IncidentType.Goal || incidentType === IncidentType.PenaltyShootout) {
    incident.homeScore = homeScore
    incident.awayScore = awayScore
    if (assist1) {
      incident.assist1 = assist1
    }
  }

  if (incidentType === IncidentType.Substitution) {
    if (playerIn) {
      incident.playerIn = playerIn
    }
    if (playerOut) {
      incident.playerOut = playerOut
    }
  }

  if (incidentType === IncidentType.Suspension) {
    incident.duration = duration as number
  }

  if (incidentType === IncidentType.InGamePenalty) {
    incident.incidentClass = IncidentInGamePenaltyType.Missed
    incident.description = reasonDescription
    incident.reason = reason
  }

  if (incidentType === IncidentType.PenaltyShootout) {
    incident.addedTime = undefined
    incident.sequence = sequence
    incident.reason = reason
    incident.description = description
  }

  return incident
}
