import React from 'react'
import { Checkbox, Flex, Text, Image } from '@sofascore/ui'
import { useIntl } from 'react-intl'
import { Team, getTeamCountryFlag } from 'entities/Team'

import { teamImage } from 'api/routes'
import { TeamWithTournamentList } from 'modules/Teams/interface'

interface TeamItemProps {
  team: Team | TeamWithTournamentList
  checked: boolean
  handleChange: (teamId: number) => void
}

export const TeamItem = ({ team, checked, handleChange }: TeamItemProps) => {
  const intl = useIntl()
  const { country } = team

  return (
    <Flex w="100%" py="sm" align="center">
      <Flex w="100%" align="center" onClick={() => handleChange(team.id)}>
        <Image w="40" h="40" mr="lg" src={teamImage(team.id)} alt={team.name} />
        <Flex pr="sm" direction="column" justify="space-between" w="100%">
          <Text font="body.medium">{team.name}</Text>
          <Flex mt="sm" mr="sm" align="center">
            <Image
              src={getTeamCountryFlag(country)}
              w="16"
              h="16"
              mr="xs"
              alt={country?.name ? intl.formatMessage({ id: country.name.toLowerCase() }) : ''}
            />
            {country?.alpha3 && (
              <Text font="assistive.default" color="onSurface.nLv3">
                {country?.alpha3}
              </Text>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Checkbox
        px="0"
        py="0"
        size={24}
        gap={0}
        id={`${team.id}`}
        onChange={() => handleChange(team.id)}
        checked={checked}
      />
    </Flex>
  )
}
