import * as React from 'react'

import { secondaryColor } from 'styles/color'

import { IconBase, IconProps } from './Base'

export const IconPlusCircle = ({ fill = secondaryColor, ...props }: IconProps) => (
  <IconBase width={24} height={24} viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={fill}
        fillRule="evenodd"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
      />
    </g>
  </IconBase>
)
