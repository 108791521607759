import { playerImage } from 'api/routes'

import { Player } from '../model'
import * as S from './styles'

interface PlayerImageProps {
  player: Player
}

export const PlayerImage = ({ player }: PlayerImageProps) => {
  return (
    <S.PlayerImage
      w={42}
      h={42}
      alt={player.name}
      src={playerImage(player.id)}
      fallback={<S.PlayerImage src="/images/placeholders/player.svg" />}
    />
  )
}
