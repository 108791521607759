import type { LoaderFunctionArgs } from 'react-router-dom'

import { fetchUniqueTournamentPermissions } from 'api'

export const loadCompetitionPermissions = async (loaderArguments: LoaderFunctionArgs) => {
  const { competitionId, seasonId } = loaderArguments.params
  if (competitionId && seasonId) {
    const permission = await fetchUniqueTournamentPermissions(Number(competitionId))
    if (Object.keys(permission?.seasonPermissions[seasonId]).length) return permission?.seasonPermissions[seasonId]
  }
  throw Error()
}
