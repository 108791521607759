import styled, { keyframes } from 'styled-components'

import { SpinnerProps } from './interface'

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const load = keyframes`
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
`

export const Spinner = styled.span<SpinnerProps>`
  display: inline-block;
  width: ${p => p.width || '18px'};
  height: ${p => p.width || '18px'};
  border-radius: 50%;
  position: relative;
  animation: ${rotate} ${p => p.animationDuration || '1s'} linear infinite;

  &::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 3px solid ${p => p.color || '#FFF'};
    animation: ${load} 2s linear infinite;
  }
`
