// ISO 639-1 => ISO 31661-1
const languageFlags: Record<string, string> = {
  en: 'gb',
  pt: 'pt',
  hr: 'hr',
  es: 'es',
  'pt-BR': 'br',
  it: 'it',
  fr: 'fr',
}

export const languageTranslations = {
  en: 'en',
  es: 'es-ES',
  hr: 'hr-HR',
  pt: 'pt-PT',
  'pt-BR': 'pt-BR',
  it: 'it-IT',
  fr: 'fr-FR',
}

/**
 * Returns alpha2 code (ISO 31661-1) for given language code (ISO 639-1).
 * @param locale ISO 639-1 code
 */
export const getLanguageFlag = (locale: string): string => {
  return languageFlags[locale]
}

/**
 * Locales with their native names
 */
export const localeNames: Record<string, string> = {
  en: 'English',
  hr: 'Hrvatski',
  pt: 'Português',
  es: 'Español',
  'pt-BR': 'Português (Brasil)',
  it: 'Italiano',
  fr: 'Français',
}

const availableLanguages = Object.keys(languageFlags)

export const getBrowserLocale = () => {
  const locale =
    [
      ...(navigator.languages || []),
      navigator.language,
      // navigator['browserLanguage'],
      // navigator['userLanguage'],
      // navigator['systemLanguage'],
    ].find(language => availableLanguages.includes(language)) || 'en'

  return locale
}
