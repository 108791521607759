import { UniqueTournamentTeamsResponse } from 'entities/UniqueTournament'
import useSWR from 'swr'
import { useParams } from 'react-router-dom'

import { tournamentTeams } from 'api/routes'
import { TeamsResponse } from 'modules/Matches/interface'
import { uniqueTournamentTeamsLastSeason } from 'modules/Teams/api/routes'
import { useMyCompetitionsState } from 'modules/MyCompetitions/providers/MyCompetitionsProvider'
import { uniqueTournamentTeams } from 'modules/NewSeason/api/routes'

import { removeDuplicates } from '../utils'

export const useExistingTeams = (tournamentId: number) => {
  const { uniqueTournament } = useMyCompetitionsState()
  const { seasonId, competitionId } = useParams()

  const hasSingleTournament = !uniqueTournament?.hasGroups

  const {
    data: _tournamentTeams,
    error: tournamentTeamsError,
    mutate: revalidateTournamentTeams,
  } = useSWR<TeamsResponse>(
    competitionId && tournamentId && seasonId ? tournamentTeams(+competitionId, tournamentId, +seasonId) : null,
    {
      revalidateOnFocus: false,
    },
  )

  const {
    data: _uniqueTournamentTeamsLastSeason,
    error: uniqueTournamentTeamsLastSeasonError,
    mutate: revalidateUniqueTournamentTeamsLastSeason,
  } = useSWR<TeamsResponse>(
    competitionId && hasSingleTournament ? uniqueTournamentTeamsLastSeason(+competitionId) : null,
    {
      revalidateOnFocus: false,
    },
  )

  const {
    data: _uniqueTournamentTeams,
    error: uniqueTournamentTeamsError,
    mutate: revalidateUniqueTournamentTeams,
  } = useSWR<UniqueTournamentTeamsResponse>(
    competitionId && !hasSingleTournament ? uniqueTournamentTeams(+competitionId) : null,
    {
      revalidateOnFocus: false,
    },
  )

  const revalidate = () => {
    if (hasSingleTournament) {
      revalidateUniqueTournamentTeamsLastSeason()
    } else {
      revalidateTournamentTeams()
      revalidateUniqueTournamentTeams()
    }
  }

  const teams = hasSingleTournament
    ? _uniqueTournamentTeamsLastSeason?.teams
    : removeDuplicates(_uniqueTournamentTeams?.teams, _tournamentTeams?.teams)

  const error = hasSingleTournament
    ? uniqueTournamentTeamsLastSeasonError
    : uniqueTournamentTeamsError || tournamentTeamsError

  return {
    teams,
    error,
    revalidate,
  }
}
