import { Theme, createStyles, makeStyles } from '@material-ui/core'

import { BLUE, secondaryColor } from 'styles/color'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.default,
      marginTop: theme.spacing(2),
    },

    label: {
      fontSize: '12px',
      fontWeight: 500,
      color: BLUE.incident,
      height: 36,
      display: 'flex',
      alignItems: 'center',
    },

    setsContainer: {
      '& > div + div': {
        marginLeft: theme.spacing(1),
      },
    },

    helperText: {
      fontSize: 12,
      marginRight: 16,
      color: secondaryColor,
      maxHeight: 36,
      overflow: 'hidden',
    },
  }),
)
