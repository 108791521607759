import React from 'react'
import { useDispatch, connect } from 'react-redux'
import { Typography, useTheme, Theme, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import { useMediaContext } from '@sofascore/ui'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { FormattedMessage } from 'react-intl'
import { SportName, sportsWithTwoHalves } from 'entities/Sport'
import { AWAY_TEAM, HOME_TEAM } from 'entities/Status'

import { useStyles } from 'modules/Matches/styles'
import { backgrounds } from 'styles/color'
import {
  periodNamesSportMap,
  PERIOD1,
  PERIOD2,
  EXTRA_TIME_1,
  EXTRA_TIME_2,
  PENALTIES,
  CURRENT,
} from 'modules/Matches/const'
import { SET_SCORE } from 'modules/Matches/matchesReducer'
import { RootState } from 'store/store'
import { ScoreCounter } from 'components/Counter/Counter'
import { SET_PERIOD_SCORE_UPDATED } from 'store/uiControls/reducer'
import { useMyCompetitionsState } from 'modules/MyCompetitions/providers/MyCompetitionsProvider'

import DeleteSectionButton from './DeleteSectionButton'

interface OwnProps {
  periodKey: string
  expanded?: boolean
  closeable?: boolean
  deleteSection?: () => void
}

interface StateProps {
  homeTeamName: string | null
  awayTeamName: string | null
  resultHome: number
  resultAway: number
  matchStatusCode: number | null
}

type Props = OwnProps & StateProps

const ResultSectionItem = (props: Props) => {
  const { periodKey, expanded, closeable, deleteSection } = props
  const { homeTeamName, awayTeamName, resultHome, resultAway, matchStatusCode } = props
  const { uniqueTournament } = useMyCompetitionsState()

  const sport = uniqueTournament?.category.sport.slug

  const dispatch = useDispatch()

  const theme: Theme = useTheme()
  const classes = useStyles()
  const { isMobile } = useMediaContext()

  const onScoreChanged = (newScore: number, team: string) => {
    const homeScoreUpdate = team === HOME_TEAM
    const payload = {
      periodKey,
      homeScore: homeScoreUpdate ? newScore : resultHome < 0 ? 0 : resultHome,
      awayScore: homeScoreUpdate ? (resultAway < 0 ? 0 : resultAway) : newScore,
      matchStatusCode,
    }

    dispatch({
      type: SET_SCORE,
      payload: payload,
    })

    if (periodKey !== CURRENT) {
      dispatch({ type: SET_PERIOD_SCORE_UPDATED, payload: { periodScoreUpdated: true } })
    }
  }

  return homeTeamName && awayTeamName ? (
    <Accordion
      className={classes.expansionPanel}
      defaultExpanded={expanded}
      style={{ backgroundColor: backgrounds.content }}
      onClick={e => e.stopPropagation()}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: theme.palette.secondary.main }} />}
        className={classes.expansionPanelSummary}
      >
        <Typography className={classes.heading}>
          <FormattedMessage id="result" /> - <FormattedMessage id={periodNamesSportMap[periodKey][sport]} />
        </Typography>
        {closeable && <DeleteSectionButton deleteHandler={deleteSection} />}
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.expansionPanelDetailsRoot }}>
        <div className={classes.container}>
          <div className={classes.infoContainer}>
            <Typography variant="caption" color="primary">
              {homeTeamName}
            </Typography>
            <ScoreCounter
              value={resultHome}
              onChange={newScore => {
                onScoreChanged(newScore, HOME_TEAM)
              }}
            />
          </div>
          <div className={classes.infoContainer}>
            <Typography variant="caption" color="primary">
              {awayTeamName}
            </Typography>
            <ScoreCounter
              value={resultAway}
              onChange={newScore => {
                onScoreChanged(newScore, AWAY_TEAM)
              }}
            />
          </div>
          {!isMobile && <div style={{ width: '33.33%' }} />}
        </div>
      </AccordionDetails>
    </Accordion>
  ) : (
    <></>
  )
}

const mapStateToProps = ({ event }: RootState, { periodKey }: OwnProps): StateProps => {
  let resultHome = -1
  let resultAway = -1

  if (event) {
    const sport = event.tournament.category.sport.slug as SportName

    // Full time result (football and futsal)
    if (periodKey === PERIOD2 && sportsWithTwoHalves.indexOf(sport) !== -1) {
      if (typeof event.homeScore.normaltime === 'number' && typeof event.awayScore.normaltime === 'number') {
        resultHome = event.homeScore.normaltime
        resultAway = event.awayScore.normaltime
      }
      if (typeof event.homeScore[PERIOD1] === 'number' && typeof event.awayScore[PERIOD2] === 'number') {
        const currentHomeScorePeriod1 = event.homeScore[PERIOD1] >= 0 ? event.homeScore[PERIOD1] : 0
        const currentHomeScorePeriod2 = event.homeScore[PERIOD2] >= 0 ? event.homeScore[PERIOD2] : 0
        const currentAwayScorePeriod1 = event.awayScore[PERIOD1] >= 0 ? event.awayScore[PERIOD1] : 0
        const currentAwayScorePeriod2 = event.awayScore[PERIOD2] >= 0 ? event.awayScore[PERIOD2] : 0
        resultHome = currentHomeScorePeriod1 + currentHomeScorePeriod2
        resultAway = currentAwayScorePeriod1 + currentAwayScorePeriod2
      } else if (typeof event.homeScore[CURRENT] === 'number') {
        const extra1Home = typeof event.homeScore[EXTRA_TIME_1] === 'number' ? event.homeScore[EXTRA_TIME_1] : 0
        const extra1Away = typeof event.awayScore[EXTRA_TIME_1] === 'number' ? event.awayScore[EXTRA_TIME_1] : 0
        const extra2Home = typeof event.homeScore[EXTRA_TIME_2] === 'number' ? event.homeScore[EXTRA_TIME_2] : 0
        const extra2Away = typeof event.awayScore[EXTRA_TIME_2] === 'number' ? event.awayScore[EXTRA_TIME_2] : 0
        const penaltiesHome = typeof event.homeScore[PENALTIES] === 'number' ? event.homeScore[PENALTIES] : 0
        const penaltiesAway = typeof event.awayScore[PENALTIES] === 'number' ? event.awayScore[PENALTIES] : 0
        resultHome = event.homeScore[CURRENT] - extra1Home - extra2Home - penaltiesHome
        resultAway = event.awayScore[CURRENT] - extra1Away - extra2Away - penaltiesAway
      }
    } else {
      if (typeof event.homeScore[periodKey] === 'number') {
        resultHome = event.homeScore[periodKey]
      }
      if (typeof event.awayScore[periodKey] === 'number') {
        resultAway = event.awayScore[periodKey]
      }
    }
  }

  return {
    homeTeamName: event ? event.homeTeam.name : null,
    awayTeamName: event ? event.awayTeam.name : null,
    resultHome,
    resultAway,
    matchStatusCode: event ? event.status.code : null,
  }
}

export default connect(mapStateToProps)(ResultSectionItem)
