import { SportName } from 'entities/Sport'

import { Incident, IncidentType, PeriodGroup, PeriodIncident } from '../model'
import { mapIncidentGoalTypeToValue } from '../mappings'

/**
 * Returns an array of period groups, where each group contains incidents
 * happened in that particular period. Logic assumes that BE returns list
 * of incidents in their chronological order (as it should always be),
 * otherwise grouping won't be done properly.
 */
export const groupIncidentsByPeriod = (incidents: Incident[]) => {
  const groups: PeriodGroup[] = []

  incidents.forEach(incident => {
    if (incident.incidentType === IncidentType.Period) {
      groups.push({
        incidents: [],
        periodIncident: incident as PeriodIncident,
        periodEndHomeScore: incident.homeScore!,
        periodEndAwayScore: incident.awayScore!,
        isLive: (incident as PeriodIncident).isLive,
      })
    } else {
      groups[groups.length - 1]?.incidents.push(incident)
    }
  })

  return groups
}

export const getGoalValueByType = (sport: SportName, incidentClass: string | undefined) => {
  if (mapIncidentGoalTypeToValue[sport] && incidentClass) {
    return mapIncidentGoalTypeToValue[sport]![incidentClass] ?? 1
  }

  return 1
}

/**
 * Returns tuple with first element being the last goal (or undefined if it does not exist) and second element
 * being the second to last goal (or undefined if it does not exist) from the provided incident list.
 */
export const getLastGoalsFromIncidents = (incidents: Incident[]): [Incident | undefined, Incident | undefined] => {
  const goals = incidents ? incidents.filter(i => i.incidentType === IncidentType.Goal) : []
  const [lastGoal, secondToLastGoal] = goals

  return [lastGoal, secondToLastGoal]
}
