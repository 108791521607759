import { useContext, useEffect, useState } from 'react'
import useSWR from 'swr'

import { RssParserContext } from 'modules/WhatsNew/context/RssParserContext'
import { rssFeed } from 'api/routes'

export interface RssResponseData {
  rss?: {
    url: string
    lastpublished: number // timestamp in seconds
  }
}

interface RssFeed {
  title: string
  link: string
  description: string
  feedUrl: string
  language: string
  items: Record<string, string>[]
}

export const stripHtmlTags = (content: string) => {
  return content.replaceAll(/<\/?[^>]+(>|$)/gi, '')
}

export const stripImageTags = (content: string) => {
  return content.replaceAll(/<img[^>]*>/gi, '')
}

export const extractFeatureImageUrl = (content: string): string | undefined => {
  return content.split('<img')[1]?.split('src="')[1]?.split('"')[0]
}

export const formatPublishDate = (date: string) => {
  const [weekDay, day, month, year] = date.split(' ')
  return [weekDay, day, month, year].join(' ')
}

export const getIdFromGuid = (guid: string) => {
  return guid.split('?p=')[1]
}

export const getSummary = (content: string) => {
  return stripHtmlTags(content.split('<br />')[0])
}

export const useRssFeedItems = () => {
  const rssParser = useContext(RssParserContext)

  const { data: rssData } = useSWR<RssResponseData>(rssFeed())

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [feedItems, setFeedItems] = useState<Record<string, any>[]>([])

  useEffect(() => {
    if (rssData && rssData.rss) {
      fetch(rssData.rss.url)
        .then(res => res.text())
        .then(xmlString => {
          rssParser
            .parseString(xmlString)
            .then((feed: RssFeed) => {
              setFeedItems(feed.items)
            })
            .catch((err: Error) => console.error(err))
        })
        .catch(err => console.error(err))
    }
  }, [rssParser, rssData])

  return feedItems
}

// LOCAL STORAGE OPERATIONS

export const markItemAsRead = (id: string) => {
  const alreadyReadLSString = localStorage.getItem('persist:rss-items-read')
  let alreadyReadList: string[] = []

  if (alreadyReadLSString) {
    try {
      alreadyReadList = JSON.parse(alreadyReadLSString)
    } catch (error) {
      console.error(error)
    }

    // Check if the id already exists
    if (!alreadyReadList.includes(id)) {
      alreadyReadList.push(id)
    }
  } else {
    alreadyReadList = [id]
  }

  localStorage.setItem('persist:rss-items-read', JSON.stringify(alreadyReadList))
}

export const isItemRead = (id: string): boolean => {
  const alreadyReadLSString = localStorage.getItem('persist:rss-items-read')

  if (alreadyReadLSString) {
    let alreadyReadList

    try {
      alreadyReadList = JSON.parse(alreadyReadLSString)
    } catch (error) {
      console.error(error)
    }

    if (alreadyReadList.includes(id)) {
      return true
    }
  }

  return false
}

export const markItemAsHidden = (id: string) => {
  const alreadyHiddenLSString = localStorage.getItem('persist:rss-items-hidden')
  let alreadyHiddenList: string[] = []

  if (alreadyHiddenLSString) {
    try {
      alreadyHiddenList = JSON.parse(alreadyHiddenLSString)
    } catch (error) {
      console.error(error)
    }

    // Check if the id already exists
    if (!alreadyHiddenList.includes(id)) {
      alreadyHiddenList.push(id)
    }
  } else {
    alreadyHiddenList = [id]
  }

  localStorage.setItem('persist:rss-items-hidden', JSON.stringify(alreadyHiddenList))
}

export const isItemHidden = (id: string): boolean => {
  const alreadyHiddenLSString = localStorage.getItem('persist:rss-items-hidden')

  if (alreadyHiddenLSString) {
    let alreadyHiddenList

    try {
      alreadyHiddenList = JSON.parse(alreadyHiddenLSString)
    } catch (error) {
      console.error(error)
    }

    if (alreadyHiddenList.includes(id)) {
      return true
    }
  }

  return false
}

export const markWhatsNewMenuAsRead = () => {
  const isOpened = localStorage.getItem('persist:whats-new-read')

  if (!isOpened) {
    localStorage.setItem('persist:whats-new-read', JSON.stringify(true))
  }
}

export const isWhatsNewMenuRead = (): boolean => {
  const menuReadLSString = localStorage.getItem('persist:whats-new-read')

  if (menuReadLSString) {
    let isOpened

    try {
      isOpened = JSON.parse(menuReadLSString)
    } catch (error) {
      console.error(error)
    }

    return isOpened
  }

  return false
}
