import * as React from 'react'

import { secondaryColor } from 'styles/color'

import { IconBase, IconProps } from './Base'

export const IconPlus = (props: IconProps) => (
  <IconBase width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill={props.fill || secondaryColor}
      fillRule="evenodd"
      d="M14.5 4a.5.5 0 0 1 .5.5V10h5.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-5.501L15 19.5a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5l-.001-5.5H5.5a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5H11V4.5a.5.5 0 0 1 .5-.5h3z"
    />
  </IconBase>
)

export const IconPlusAdd = (props: IconProps) => (
  <IconBase width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path fill={props.fill || '#ffffff'} fillRule="evenodd" d="M18.92 11h-6V5h-2v6h-6v2h6v6h2v-6h6z" />
  </IconBase>
)
