import { LineupsState } from 'features/AddLineups'

export const isListLineupValid = (state: LineupsState) => {
  const homeStarters = state.homeLineupPlayers.filter(lp => lp.position && !lp.substitute)
  const awayStarters = state.awayLineupPlayers.filter(lp => lp.position && !lp.substitute)

  return (
    (state.hasChanges && (homeStarters.length !== 0 || awayStarters.length !== 0)) ||
    (homeStarters.length === 0 && awayStarters.length === 0)
  )
}
