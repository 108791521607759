import {
  IncidentGoalType,
  IncidentType,
  IncidentGoalRegular,
  IncidentOwnGoal,
  IncidentFootballGoalFromPenalty as IncidentGoalFromPenalty,
  IncidentSubstitutionType,
  IncidentSubstitutionRegular,
  IncidentSubstitutionInjury,
  IncidentCardType,
  IncidentYellowCard,
  IncidentRedCard,
  IncidentYellowRedCard,
  IncidentFootballMissedPenalty as IncidentMissedPenalty,
  IncidentPenaltyShootoutType,
  IncidentShootoutPenaltyScored,
  IncidentShootoutPenaltyMissed,
} from 'entities/Incident'
import { DeleteIncident } from 'features/DeleteIncident'
import {
  EditFootballGoalRegular as EditGoalRegular,
  EditFootballLastGoalRegular as EditLastGoalRegular,
  EditFootballLastOwnGoal as EditLastOwnGoal,
  EditFootballLastGoalFromPenalty as EditLastGoalFromPenalty,
  EditFootballSubstitutionRegular as EditSubstitutionRegular,
  EditFootballSubstitutionInjury as EditSubstitutionInjury,
  EditFootballYellowCard as EditYellowCard,
  EditFootballRedCard as EditRedCard,
  EditFootballYellowRedCard as EditYellowRedCard,
  EditFootballMissedPenalty as EditMissedPenalty,
  EditFootballLastShootoutPenalty as EditLastShootoutPenalty,
  EditFootballShootoutPenalty as EditShootoutPenalty,
} from 'features/EditIncident'

import { IncidentRendererProps } from '../../model'

export const FootballIncidentRenderer = ({ incident, isLastGoal = false, onEdit, onDelete }: IncidentRendererProps) => {
  const { incidentType, incidentClass } = incident

  const props = {
    incident,
    isLastGoal,
    onEdit,
    onDelete,
  }

  if (incidentType === IncidentType.Goal && incidentClass == IncidentGoalType.GoalRegular) {
    return (
      <IncidentGoalRegular incident={incident}>
        {isLastGoal ? <DeleteIncident {...props} /> : null}
        {isLastGoal ? <EditLastGoalRegular {...props} /> : <EditGoalRegular {...props} />}
      </IncidentGoalRegular>
    )
  }

  if (incidentType === IncidentType.Goal && incidentClass == IncidentGoalType.GoalFromPenalty) {
    return (
      <IncidentGoalFromPenalty incident={incident}>
        {isLastGoal ? <DeleteIncident {...props} /> : null}
        {isLastGoal ? <EditLastGoalFromPenalty {...props} /> : <EditGoalRegular {...props} />}
      </IncidentGoalFromPenalty>
    )
  }

  if (incidentType === IncidentType.Goal && incidentClass == IncidentGoalType.OwnGoal) {
    return (
      <IncidentOwnGoal incident={incident}>
        {isLastGoal ? <DeleteIncident {...props} /> : null}
        {isLastGoal ? <EditLastOwnGoal {...props} /> : <EditGoalRegular {...props} />}
      </IncidentOwnGoal>
    )
  }

  if (incidentType === IncidentType.PenaltyShootout && incidentClass === IncidentPenaltyShootoutType.Scored) {
    return (
      <IncidentShootoutPenaltyScored incident={incident}>
        {isLastGoal ? <DeleteIncident {...props} /> : null}
        {isLastGoal ? <EditLastShootoutPenalty {...props} /> : <EditShootoutPenalty {...props} />}
      </IncidentShootoutPenaltyScored>
    )
  }

  if (incidentType === IncidentType.PenaltyShootout && incidentClass === IncidentPenaltyShootoutType.Missed) {
    return (
      <IncidentShootoutPenaltyMissed incident={incident}>
        {isLastGoal ? <DeleteIncident {...props} /> : null}
        {isLastGoal ? <EditLastShootoutPenalty {...props} /> : <EditShootoutPenalty {...props} />}
      </IncidentShootoutPenaltyMissed>
    )
  }

  if (incidentType === IncidentType.Substitution && incidentClass == IncidentSubstitutionType.Regular) {
    return (
      <IncidentSubstitutionRegular incident={incident}>
        <DeleteIncident {...props} />
        <EditSubstitutionRegular {...props} />
      </IncidentSubstitutionRegular>
    )
  }

  if (incidentType === IncidentType.Substitution && incidentClass == IncidentSubstitutionType.Injury) {
    return (
      <IncidentSubstitutionInjury incident={incident}>
        <DeleteIncident {...props} />
        <EditSubstitutionInjury {...props} />
      </IncidentSubstitutionInjury>
    )
  }

  if (incidentType === IncidentType.Card && incidentClass == IncidentCardType.Yellow) {
    return (
      <IncidentYellowCard incident={incident}>
        <DeleteIncident {...props} />
        <EditYellowCard {...props} />
      </IncidentYellowCard>
    )
  }

  if (incidentType === IncidentType.Card && incidentClass == IncidentCardType.Red) {
    return (
      <IncidentRedCard incident={incident}>
        <DeleteIncident {...props} />
        <EditRedCard {...props} />
      </IncidentRedCard>
    )
  }

  if (incidentType === IncidentType.Card && incidentClass == IncidentCardType.YellowRed) {
    return (
      <IncidentYellowRedCard incident={incident}>
        <DeleteIncident {...props} />
        <EditYellowRedCard {...props} />
      </IncidentYellowRedCard>
    )
  }

  if (incidentType === IncidentType.InGamePenalty) {
    return (
      <IncidentMissedPenalty incident={incident}>
        <DeleteIncident {...props} />
        <EditMissedPenalty {...props} />
      </IncidentMissedPenalty>
    )
  }

  return null
}
