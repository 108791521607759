import { Country } from 'entities/Country'

/**
 * Returns Team country flag
 */
export const getTeamCountryFlag = (country: Country | undefined) => {
  let countryFlag = '/images/placeholders/teamLogo.png'

  if (country?.alpha2) {
    countryFlag = `/images/flags/${country.alpha2.toLowerCase()}.png`
  }

  return countryFlag
}
