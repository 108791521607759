import {
  IncidentActionType,
  IncidentPenaltyShootoutType,
  IncidentType,
  UpdateIncidentData,
  useEventIncidentsDispatch,
} from 'entities/Incident'
import { useCallback } from 'react'
import { EventActionType, useEventDispatch } from 'entities/Event'

export const usePostMatchEditIncidentHandler = () => {
  const incidentsDispatch = useEventIncidentsDispatch()
  const eventDispatch = useEventDispatch()

  const handleEdit = useCallback(
    async (data: UpdateIncidentData) => {
      const { incidentType, incidentClass, isHome, homeScore, awayScore } = data.incidentData
      const { updateScore } = data

      incidentsDispatch({ type: IncidentActionType.EDIT_INCIDENT, payload: data })

      if (incidentType === IncidentType.Goal && updateScore) {
        eventDispatch({
          type: EventActionType.SET_SCORES,
          payload: { homeScore: homeScore, awayScore: awayScore },
        })
      }

      if (incidentType === IncidentType.PenaltyShootout && updateScore) {
        if (incidentClass === IncidentPenaltyShootoutType.Scored) {
          eventDispatch({
            type: EventActionType.INCREMENT_PENALTY_SCORE,
            payload: { isHome: isHome },
          })
        }

        if (incidentClass === IncidentPenaltyShootoutType.Missed) {
          eventDispatch({
            type: EventActionType.DECREMENT_PENALTY_SCORE,
            payload: { isHome: isHome },
          })
        }
      }
    },
    [incidentsDispatch, eventDispatch],
  )

  return { handleEdit }
}
