import { Flex } from '@sofascore/ui'
import { motion } from 'framer-motion'
import styled from 'styled-components'

export const StyledMotionDiv = styled(motion.div)`
  position: relative;
  z-index: 1;
  background: #fff;

  & svg {
    display: none;
  }

  &:hover svg {
    display: block;
  }
`

export const TooltipContainer = styled(Flex)`
  position: absolute;

  align-items: center;
  margin-right: ${p => p.theme.spacing.sm};
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  &:hover {
    z-index: 3;
  }
`
