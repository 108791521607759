import styled, { FlattenSimpleInterpolation, css } from 'styled-components'
import { Flex, ZIndex } from '@sofascore/ui'

import { notActiveIconColor, secondaryColor, secondaryColorHover, textSecondary } from 'styles/color'

import { ButtonStyleProps, ButtonVariant } from './interface'

const TRANSITION_DURATION_NORMAL = 0.2

// #region Base styles

const baseButtonStyles = css`
  outline: none;
  box-sizing: border-box;
  vertical-align: middle;
  user-select: none;
  transition: background-color ${TRANSITION_DURATION_NORMAL}s, box-shadow ${TRANSITION_DURATION_NORMAL}s,
    border-color ${TRANSITION_DURATION_NORMAL}s, color ${TRANSITION_DURATION_NORMAL}s;
  cursor: pointer;

  position: relative;
  white-space: nowrap;
  z-index: ${ZIndex.Button};
  appearance: button;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

const baseTextStyles = css`
  font-family: 'Sofascore Sans';
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.5px;
`

// #endregion

// #region Variants

const containedVariantStyles = css`
  background-color: ${secondaryColor};
  color: ${textSecondary};
  border-color: ${secondaryColor};

  &:hover {
    background-color: ${secondaryColorHover};
    border-color: ${secondaryColorHover};
  }

  &:disabled {
    border-color: ${notActiveIconColor};
    background-color: ${notActiveIconColor};
    box-shadow: none;
  }
`

const outlinedVariantStyles = css`
  color: ${secondaryColor};
  background-color: ${textSecondary};
  border-color: ${secondaryColor};

  &:hover {
    border-color: ${secondaryColorHover};
    color: ${secondaryColorHover};
    background-color: ${textSecondary};
  }

  &:disabled {
    border-color: ${textSecondary};
    background-color: ${textSecondary};
    color: ${notActiveIconColor};
  }
`

const textVariantStyles = css`
  color: ${secondaryColor};
  background-color: ${textSecondary};

  &:hover {
    color: ${secondaryColorHover};
    background-color: ${textSecondary};
  }

  &:disabled {
    border-color: ${textSecondary};
    background-color: ${textSecondary};
    color: ${notActiveIconColor};
  }
`

// #endregion

// #region Variant helpers

const variantsMap: Record<ButtonVariant, FlattenSimpleInterpolation> = {
  contained: containedVariantStyles,
  outlined: outlinedVariantStyles,
  text: textVariantStyles,
}

const getVariantStyles = (variant: ButtonVariant) => {
  return variantsMap[variant]
}

// #endregion

export const ButtonContent = styled(Flex)`
  align-items: center;
  min-height: 18px;
  gap: 8px;
`

export const Button = styled.button<ButtonStyleProps>`
  ${baseButtonStyles}
  ${baseTextStyles}

  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  padding: 14px;

  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  border-radius: 2px;

  width: ${p => (p.fullWidth ? '100%' : undefined)};

  ${p => getVariantStyles(p.variant)}

  svg {
    fill: currentColor;
    display: inline-block;
    vertical-align: middle;
  }
`
