import { UntranslatedOption } from 'shared/model'

import { IncidentMissedInGamePenaltyType, IncidentSubstitutionType } from '../../model'

export const footballSubstitutionReasonOptions: UntranslatedOption[] = [
  {
    translationKey: 'substitute_reason_tactical',
    value: IncidentSubstitutionType.Regular,
  },
  {
    translationKey: 'substitute_reason_injury',
    value: IncidentSubstitutionType.Injury,
  },
]

export const footballMissedPenaltyReasonOptions: UntranslatedOption[] = [
  {
    translationKey: 'goalkeeper_save',
    value: IncidentMissedInGamePenaltyType.GoalkeeperSave,
  },
  {
    translationKey: 'off_target',
    value: IncidentMissedInGamePenaltyType.OffTarget,
  },
  {
    translationKey: 'woodwork',
    value: IncidentMissedInGamePenaltyType.Woodwork,
  },
]
