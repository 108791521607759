import { Box, Flex, Text } from '@sofascore/ui'
import { useEventState } from 'entities/Event'
import { StatusCode } from 'entities/Status'
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { TimePicker } from 'components/TimePicker'
import ResponsivePopUp from 'components/ResponsivePopUp'
import { Button } from 'components/Button'

import { useUpdatePeriodHandler } from '../hooks'

const StartMatchButton = styled.button`
  text-align: center;
  width: 100%;
  appearance: none;
  border: none;
  border-radius: 8px;
  outline: none;
  background: rgba(229, 233, 239, 0.4);
  padding: 24px 0px;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.25s ease;
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.colors.surface.s0};
    background-color: ${p => p.theme.colors.primary.default};
  }

  &:active:hover {
    transform: scale(0.9);
  }
`

export const MatchStarter = () => {
  const [isOpened, setIsOpened] = useState(false)
  const [selectedTime, setSelectedTime] = useState<Date>(new Date())
  const [isSaving, setIsSaving] = useState(false)

  const { event } = useEventState()
  const { handleUpdate } = useUpdatePeriodHandler()

  const handleSetCurrentTime = () => setSelectedTime(new Date())
  const handleCloseModal = () => setIsOpened(false)

  const handleStartMatch = async (currentPeriodStartTime: Date | null) => {
    setIsSaving(true)

    handleUpdate(
      {
        status: {
          code: StatusCode['1st quarter'], // TODO - make this generic, read from props„
        },
        homeScore: event.homeScore,
        awayScore: event.awayScore,
        aggregatedWinnerCode: event.aggregatedWinnerCode!,
        currentPeriodStartTimestamp: currentPeriodStartTime
          ? Math.round(currentPeriodStartTime.getTime() / 1000)
          : null,
        roundInfo: event.roundInfo,
        // If editor takes over crowdsourced event, delete all incidents
        resetScore: event.crowdsourcingDataDisplayEnabled,
      },
      event.id,
    )
      .then(handleCloseModal)
      .finally(() => setIsSaving(false))
  }

  useEffect(() => {
    setSelectedTime(new Date())
  }, [isOpened])

  return (
    <>
      <Box bg="surface.s1" mt="sm" br="sm" p="lg" textAlign="center" elevation={1}>
        <StartMatchButton onClick={() => setIsOpened(true)}>
          <FormattedMessage id="start_match" />
        </StartMatchButton>
      </Box>

      <ResponsivePopUp
        isOpen={isOpened}
        onClose={handleCloseModal}
        heading={
          <Text font="display.medium">
            <FormattedMessage id="match_start_time" />
          </Text>
        }
      >
        <Box>
          <Flex direction="column" mb={200}>
            <Box backgroundColor="surface.s2" textAlign="center" mt="lg" br="sm" p="lg" mx="md">
              <Text font="display.small">
                <FormattedMessage id="select_confirm_time" />
              </Text>
              <TimePicker date={selectedTime} onChange={val => setSelectedTime(val)} />
            </Box>
            <Button
              variant="text"
              style={{ textTransform: 'unset', fontSize: '12px', fontWeight: 500 }}
              onClick={handleSetCurrentTime}
            >
              <FormattedMessage id="current_time" />
            </Button>
          </Flex>

          <Flex gapX="lg" px="lg" mb="xl" mx="lg">
            <Button variant="outlined" fullWidth onClick={handleCloseModal}>
              <FormattedMessage id="cancel" />
            </Button>
            <Button variant="contained" fullWidth onClick={() => handleStartMatch(selectedTime)} isLoading={isSaving}>
              <FormattedMessage id="confirm" />
            </Button>
          </Flex>
        </Box>
      </ResponsivePopUp>
    </>
  )
}
