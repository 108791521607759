import * as React from 'react'
import { TextField } from '@material-ui/core'

import { InputProps } from './interface'
import { useStyles } from './styles'

const Input = React.memo(({ error, inputProps, multiline, ...rest }: InputProps) => {
  const classes = useStyles()
  return (
    <TextField
      variant="outlined"
      margin="dense"
      multiline={!!multiline}
      InputProps={{
        classes: {
          notchedOutline: error ? classes.cssError : classes.notchedOutline,
          input: error ? classes.inputError : classes.input,
          focused: classes.focused,
          multiline: classes.multiline,
        },
        inputProps,
      }}
      className={`${classes.inputField} ${multiline ? classes.multiline : classes.singleLine}`}
      {...rest}
    />
  )
})

export default Input
