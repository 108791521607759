export enum StatusType {
  FINISHED = 'finished',
  IN_PROGRESS = 'inprogress',
  STARTED = 'started',
  NOT_STARTED = 'notstarted',
  INTERRUPTED = 'interrupted',
  POSTPONED = 'postponed',
  SUSPENDED = 'suspended',
  PAUSE = 'pause',
  DELAYED = 'delayed',
  CANCELED = 'canceled',
  WILL_CONTINUE = 'willcontinue',
}

export enum StatusCode {
  /* 0 */ 'Not started',
  /* 1 */ '1st period',
  /* 2 */ '2nd period',
  /* 3 */ '3rd period',
  /* 4 */ '4th period',
  /* 5 */ '5th period',
  /* 6 */ '1st half',
  /* 7 */ '2nd half',
  /* 8 */ '1st set',
  /* 9 */ '2nd set',
  /* 10 */ '3rd set',
  /* 11 */ '4th set',
  /* 12 */ '5th set',
  /* 13 */ '1st quarter' = 13,
  /* 14 */ '2nd quarter',
  /* 15 */ '3rd quarter',
  /* 16 */ '4th quarter',
  /* 20 */ 'Started' = 20,
  /* 22 */ 'About to start' = 22,
  /* 30 */ 'Pause' = 30,
  /* 31 */ 'Halftime',
  /* 32 */ 'Awaiting extra time',
  /* 33 */ 'Extra time halftime', // 1st extra time half
  /* 34 */ 'Awaiting penalties',
  /* 40 */ 'Overtime' = 40,
  /* 41 */ '1st extra',
  /* 42 */ '2nd extra',
  /* 50 */ 'Penalties' = 50,
  /* 51 */ 'Penalties alt.',
  /* 52 */ 'Shootout',
  /* 60 */ 'Postponed' = 60,
  /* 61 */ 'Start delayed',
  /* 70 */ 'Canceled' = 70,
  /* 80 */ 'Interrupted' = 80,
  /* 81 */ 'Suspended',
  /* 90 */ 'Abandoned' = 90,
  /* 91 */ 'Walkover',
  /* 92 */ 'Retired',
  /* 93 */ 'Removed',
  /* 97 */ 'HomeDefaulted' = 97,
  /* 98 */ 'AwayDefaulted',
  /* 100 */ 'Ended' = 100,
  /* 110 */ 'AET' = 110,
  /* 120 */ 'AP' = 120,
  /* 130 */ 'AGS' = 130,
  /* 301 */ 'First break' = 301,
  /* 302 */ 'Second break',
  /* 303 */ 'Third break',
}

export interface StatusCodeMap {
  [code: number]: {
    description: string
    type: StatusType
  }
}
export interface Status {
  type: StatusType
  code: number
  description?: string
}
