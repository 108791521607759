import { Box, Checkbox, Flex, Text } from '@sofascore/ui'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { motion, AnimatePresence } from 'framer-motion'
import { BasicEvent } from 'entities/Event'
import { useToast } from 'shared/lib'

import { Spinner } from 'components/Spinner'
import { FormDatePicker } from 'components/FormDatePicker'
import { Button } from 'components/Button'
import { createDoublesEvent, createEvent } from 'api'
import { secondaryColor } from 'styles/color'
import { TimeInput } from 'components/TimeInput'
import { useMyCompetitionsState } from 'modules/MyCompetitions/providers/MyCompetitionsProvider'
import { Select } from 'components/Select'
import { Option } from 'components/DropdownMenu/interface'

import { Rounds } from './Rounds'
import { Results } from './Results'
import useTeamOptions from './hooks/useTeamOptions'
import {
  CreateMatchProvider,
  RESET_TEAMS,
  SET_DATE,
  SET_MATCH_ENDED,
  SET_TIME,
  SET_TOURNAMENT,
  useCreateMatchDispatch,
  useCreateMatchState,
} from './context/createMatchContext'
import { Teams } from './Teams'
import { createDoublesEventDto, createEventDto } from './helper'
import { MATCH_ENDED_PERIOD_DIFF, TENNIS_DOUBLE } from '../const'

interface Props {
  onClose: () => void
  onCreate?: (event: BasicEvent) => void
}

const CreateMatchForm = ({ onClose, onCreate }: Props) => {
  const [shouldPreserveValues, setShouldPreserveValues] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isPastEvent, setIsPastEvent] = useState(false)

  const { seasonId } = useParams()
  const { tournaments } = useMyCompetitionsState()
  const { enqueueToast } = useToast()

  const { selectedTournament, selectedDate, selectedTime, selectedRound, matchType, isValid, matchEnded, ...other } =
    useCreateMatchState()
  const dispatch = useCreateMatchDispatch()

  useEffect(() => {
    // Ensure both selectedDate and selectedTime are defined
    if (selectedDate && selectedTime) {
      // 1h 45min or 105min as default
      const selectedDateTime = new Date(selectedDate)
      selectedDateTime.setHours(selectedTime.getHours())
      selectedDateTime.setMinutes(selectedTime.getMinutes())
      const currentDateTime = new Date()
      const targetDateTime = new Date(currentDateTime.getTime() - MATCH_ENDED_PERIOD_DIFF * 60 * 1000)
      if (targetDateTime >= selectedDateTime) {
        setIsPastEvent(true)
      } else {
        setIsPastEvent(false)
        dispatch({ type: SET_MATCH_ENDED, payload: false })
      }
    }
  }, [selectedDate, selectedTime, dispatch])

  useEffect(() => {
    if (tournaments && tournaments.length === 1) {
      dispatch({
        type: SET_TOURNAMENT,
        payload: tournaments[0],
      })
    }
  }, [tournaments, dispatch])

  const intl = useIntl()

  const { teamOptions, isLoading } = useTeamOptions()

  const handleTournamentSelect = (newTournamentOption: Option) => {
    let payload
    if (!newTournamentOption) {
      payload = undefined
    } else {
      payload = tournaments.find(tournament => newTournamentOption.value === tournament.id)
    }

    dispatch({
      type: SET_TOURNAMENT,
      payload,
    })

    dispatch({ type: RESET_TEAMS })
  }

  const handleCreateMatch = async () => {
    if (selectedTournament && seasonId) {
      setIsSaving(true)

      const state = { selectedDate, selectedTime, selectedRound, matchType, matchEnded, ...other }
      const isDoubles = matchType === TENNIS_DOUBLE

      const request = isDoubles
        ? createDoublesEvent(createDoublesEventDto(selectedTournament, +seasonId, state))
        : createEvent(createEventDto(selectedTournament, +seasonId, state))

      request
        .then(apiResponse => {
          if (shouldPreserveValues) dispatch({ type: RESET_TEAMS })

          enqueueToast(intl.formatMessage({ id: 'toastSuccess' }), { variant: 'success' })

          if (onCreate && 'event' in apiResponse) {
            onCreate(apiResponse.event as BasicEvent)
          }

          if (!shouldPreserveValues) onClose()
        })
        .catch(err => {
          console.error(err)
          enqueueToast(intl.formatMessage({ id: err.error.key }), { variant: 'error' })
        })
        .finally(() => setIsSaving(false))
    }
  }

  if (isLoading) {
    return (
      <Flex align="center" justify="center" minH={[288, 710]}>
        <Spinner width="48px" color={secondaryColor} />
      </Flex>
    )
  }

  return (
    <Flex direction="column" px="lg" pb="lg" minH={[320, 694]}>
      <Text>
        <FormattedMessage id="add_to" />
      </Text>
      <Box w="100%" mt="sm" bg="surface.s2" br="sm">
        <Select
          label={intl.formatMessage({ id: 'tournament' })}
          value={
            selectedTournament
              ? {
                  value: selectedTournament.id,
                  label: selectedTournament.name,
                }
              : undefined
          }
          options={tournaments.map(tournament => {
            return { value: tournament.id, label: tournament.name }
          })}
          onChange={handleTournamentSelect}
          disabled={tournaments.length === 1}
          disableClear={tournaments.length === 1}
        />
      </Box>

      {selectedTournament && (
        <>
          <Flex wrap="wrap" gapX="lg" mt="lg">
            <Box w="calc(50% - 8px)">
              <FormDatePicker
                label={<FormattedMessage id="date" />}
                date={selectedDate}
                onChange={newDate => dispatch({ type: SET_DATE, payload: newDate })}
              />
            </Box>
            <Box w="calc(50% - 8px)">
              <TimeInput
                label={intl.formatMessage({ id: 'time' })}
                value={selectedTime}
                onChange={newTime => dispatch({ type: SET_TIME, payload: newTime })}
              />
            </Box>
          </Flex>
          <Box mt="lg">
            <Rounds />
          </Box>
          <Box mb="md" mt="xl">
            <Text font="body.medium">
              <FormattedMessage id="match" />
            </Text>
          </Box>
          <Flex direction="column" gapY="lg" mb="lg">
            <Teams teams={teamOptions} />
          </Flex>
          {isPastEvent && (
            <Box border="1px solid onSurface.nLv4" br="sm" mb="lg">
              <Checkbox
                name="matchEnded"
                py={0}
                h={46}
                onChange={val => dispatch({ type: SET_MATCH_ENDED, payload: val.target.checked })}
                checked={!!matchEnded}
              >
                <Text style={{ whiteSpace: 'initial' }}>
                  <FormattedMessage id="match_ended" />
                </Text>
              </Checkbox>
              <AnimatePresence>
                {matchEnded && (
                  <motion.div
                    style={{ overflow: 'hidden' }}
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                  >
                    <Results />
                  </motion.div>
                )}
              </AnimatePresence>
            </Box>
          )}
          <Box border="1px solid onSurface.nLv4" br="sm" mb="xxl">
            <Checkbox
              name="preserve"
              py={0}
              h={46}
              onChange={val => setShouldPreserveValues(val.target.checked)}
              checked={shouldPreserveValues}
            >
              <Text style={{ whiteSpace: 'initial' }}>
                <FormattedMessage id="editor_add_another_match" />
              </Text>
            </Checkbox>
          </Box>
        </>
      )}

      <Flex gapX="lg" mt="auto" px="lg" pb="sm">
        <Button variant="outlined" fullWidth onClick={onClose}>
          <FormattedMessage id="cancel" />
        </Button>
        <Button
          variant="contained"
          fullWidth
          onClick={handleCreateMatch}
          isLoading={isSaving}
          disabled={!isValid || isSaving}
        >
          <FormattedMessage id="confirm" />
        </Button>
      </Flex>
    </Flex>
  )
}

const CreateMatchFormContainer = (props: Props) => {
  return (
    <CreateMatchProvider>
      <CreateMatchForm {...props} />
    </CreateMatchProvider>
  )
}

export default CreateMatchFormContainer
