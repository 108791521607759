import { RugbyPositionCoordinate } from '../model'

export const TERRAIN_WIDTH = 360
export const TERRAIN_HEIGHT = 503

export const rugby15StarterPositions: RugbyPositionCoordinate[] = [
  {
    position: 1,
    x: 50,
    y: 36,
  },
  {
    position: 2,
    x: 162,
    y: 26,
  },
  {
    position: 3,
    x: 274,
    y: 36,
  },
  {
    position: 4,
    x: 106,
    y: 102,
  },
  {
    position: 5,
    x: 218,
    y: 102,
  },
  {
    position: 6,
    x: 50,
    y: 163,
  },
  {
    position: 7,
    x: 274,
    y: 163,
  },
  {
    position: 8,
    x: 162,
    y: 183,
  },
  {
    position: 9,
    x: 50,
    y: 239,
  },
  {
    position: 10,
    x: 124,
    y: 273,
  },
  {
    position: 11,
    x: 50,
    y: 419,
  },
  {
    position: 12,
    x: 199,
    y: 306,
  },
  {
    position: 13,
    x: 274,
    y: 340,
  },
  {
    position: 14,
    x: 274,
    y: 419,
  },
  {
    position: 15,
    x: 162,
    y: 429,
  },
]

export const rugby7StarterPositions: RugbyPositionCoordinate[] = [
  {
    position: 1,
    x: 50,
    y: 90,
  },
  {
    position: 2,
    x: 124,
    y: 109,
  },
  {
    position: 3,
    x: 199,
    y: 90,
  },
  {
    position: 4,
    x: 50,
    y: 239,
  },
  {
    position: 5,
    x: 124,
    y: 273,
  },
  {
    position: 6,
    x: 199,
    y: 306,
  },
  {
    position: 7,
    x: 274,
    y: 340,
  },
]

export const rugby15SubstitutionPositions = [16, 17, 18, 19, 20, 21, 22, 23]

export const rugby7SubstitutionPositions = [8, 9, 10, 11, 12]
