import { BasicEvent } from 'entities/Event'
import { EventsFilterType } from 'features/FilterMatches'

import { EventGroup } from './interface'

enum GroupingCategory {
  Tournament = 'tournament',
  Round = 'round',
}

interface GroupingCriteria {
  group: GroupingCategory
  subgroup: GroupingCategory
}

const defaultGroupingCriteria: GroupingCriteria = {
  group: GroupingCategory.Round,
  subgroup: GroupingCategory.Tournament,
}

const getGroupingTitles = (event: BasicEvent, groupingCriteria: GroupingCriteria) => {
  let groupName: string, subgroupName: string

  const tournamentName = event.tournament.name
  const roundName = event.roundInfo!.name || event.roundInfo!.round.toString()

  let isGroupRound = false
  if (groupingCriteria.group === GroupingCategory.Round) {
    isGroupRound = true
    groupName = roundName
    subgroupName = tournamentName
  } else {
    groupName = tournamentName
    subgroupName = roundName
  }

  return { groupName, subgroupName, isGroupRound }
}

export const getGroupingCriteriaForFilterCombination = (filters: URLSearchParams) => {
  let groupingCriteria = defaultGroupingCriteria

  if (filters) {
    const isOnlyTournamentFilterActive = filters.size === 1 && filters.get(EventsFilterType.TournamentId)

    if (isOnlyTournamentFilterActive) {
      groupingCriteria = {
        group: GroupingCategory.Tournament,
        subgroup: GroupingCategory.Round,
      }
    }
  }

  return groupingCriteria
}

/**
 *
 * @param events
 * @param isTournamentUnique - does the unique tournament only have one tournament
 * @param areEventsFiltered - if some filter is applied, don't display the group's header as it is the only group
 * @returns
 */
export const createEventGroups = (
  events: BasicEvent[],
  groupingCriteria: GroupingCriteria = defaultGroupingCriteria,
  activeFilters: URLSearchParams,
  isOneTournamentCompetition?: boolean,
): EventGroup[] => {
  const eventGroups: EventGroup[] = []

  const isAnyFilterActive = activeFilters.size >= 1
  const areAllFiltersActive =
    (activeFilters.get(EventsFilterType.Round) || activeFilters.get(EventsFilterType.Stage)) &&
    activeFilters.get(EventsFilterType.TournamentId)

  const isHeaderDisplayed = !isAnyFilterActive && !areAllFiltersActive
  const isSubheaderDisplayed = !isOneTournamentCompetition && !areAllFiltersActive

  // Sort events by timestamp, if equal by tournament name
  const sortedEvents = events.sort((event1, event2) => {
    const timestampDiff = event1.startTimestamp - event2.startTimestamp

    if (timestampDiff !== 0) return timestampDiff

    if (event1.tournament.name < event2.tournament.name) return -1
    else if (event1.tournament.name > event2.tournament.name) return 1
    else return 0
  })

  sortedEvents.forEach(event => {
    if (!event.roundInfo) return

    const { groupName, subgroupName, isGroupRound } = getGroupingTitles(event, groupingCriteria)

    // See if the group with the most recent event is the same group, if not create the next group with another event
    const lastGroup = eventGroups[eventGroups.length - 1]
    const isLatestGroupAdequate = lastGroup?.title === groupName
    if (isLatestGroupAdequate) {
      // Insert in target group
      lastGroup.eventCount += 1

      // Check if the last subgroup is adequate
      const lastSubgroup = lastGroup.subgroups[lastGroup.subgroups.length - 1]
      const isLastSubgroupAdequate = lastSubgroup.title === subgroupName
      if (isLastSubgroupAdequate) {
        // Insert in target subgroup
        lastSubgroup.events.push(event)
      } else {
        // Create new subgroup
        lastGroup.subgroups.push({
          title: subgroupName,
          events: [event],
          isSubheaderDisplayed,
          roundInfo: !isGroupRound ? event.roundInfo : undefined,
        })
      }
    } else {
      eventGroups.push({
        title: groupName,
        eventCount: 1,
        subgroups: [
          {
            title: subgroupName,
            events: [event],
            isSubheaderDisplayed,
            roundInfo: !isGroupRound ? event.roundInfo : undefined,
          },
        ],
        isHeaderDisplayed,
        roundInfo: isGroupRound ? event.roundInfo : undefined,
      })
    }
  })

  return eventGroups
}
