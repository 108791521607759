import { FormattedMessage } from 'react-intl'
import { Flex, Text } from '@sofascore/ui'

import { Button } from 'components/Button'
import ResponsivePopUp from 'components/ResponsivePopUp'

interface DialogProps {
  isOpen: boolean
  playerName: string
  isDeleting?: boolean
  onClose: () => void
  handleConfirm?: () => void
}

const RemovePlayerDialog = ({ isOpen, playerName, isDeleting, onClose, handleConfirm }: DialogProps) => {
  return (
    <ResponsivePopUp
      isOpen={isOpen}
      onClose={onClose}
      heading={
        <Text fontSize="large" fontWeight="bold">
          <FormattedMessage id="remove_player" />
        </Text>
      }
    >
      <Flex justify="center" px="lg">
        <Text fontSize="regular">
          <FormattedMessage id="removePlayerFromTeam" values={{ playerName }} />
        </Text>
      </Flex>

      <Flex justify="center" gapX={16} px={40} pt="xxl" pb="xl">
        <Button variant="outlined" fullWidth onClick={onClose}>
          <FormattedMessage id="cancel" />
        </Button>

        <Button variant="contained" fullWidth onClick={handleConfirm} isLoading={isDeleting}>
          <FormattedMessage id="confirm" />
        </Button>
      </Flex>
    </ResponsivePopUp>
  )
}

export default RemovePlayerDialog
