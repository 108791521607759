import { Flex, Text } from '@sofascore/ui'
import IconClose from '@sofascore/ui/dist/modules/Icons/_IconClose'
import { useMatchesFilters } from 'features/FilterMatches'

import * as S from './styles'
import { MATCHES_FILTER_HEIGHT } from './config'

const FilterList = () => {
  const { selectedRoundOption, selectedTournamentOption, applyFilters } = useMatchesFilters()

  if (!selectedRoundOption && !selectedTournamentOption) return null

  return (
    <S.ListContainer>
      {selectedRoundOption && (
        <Flex h={MATCHES_FILTER_HEIGHT} maxH={MATCHES_FILTER_HEIGHT}>
          <Flex bg="surface.s1" border="primary.default" br="sm" justify="space-between" align="center" pl="md" pr="sm">
            <Text fontSize="small" fontWeight="medium" whiteSpace="nowrap">
              {selectedRoundOption.label}
            </Text>

            <Flex
              ml="sm"
              align="center"
              cursor="pointer"
              onClick={() => applyFilters(undefined, selectedTournamentOption)}
            >
              <IconClose fill="onSurface.nLv3" w={24} />
            </Flex>
          </Flex>
        </Flex>
      )}

      {selectedTournamentOption && (
        <Flex h={MATCHES_FILTER_HEIGHT} maxH={MATCHES_FILTER_HEIGHT}>
          <Flex bg="surface.s1" border="primary.default" br="sm" justify="space-between" align="center" pl="md" pr="sm">
            <Text fontSize="small" fontWeight="medium" whiteSpace="nowrap">
              {selectedTournamentOption.label}
            </Text>

            <Flex ml="sm" align="center" cursor="pointer" onClick={() => applyFilters(selectedRoundOption, undefined)}>
              <IconClose fill="onSurface.nLv3" w={24} />
            </Flex>
          </Flex>
        </Flex>
      )}
    </S.ListContainer>
  )
}

export default FilterList
