import { Flex, Text } from '@sofascore/ui'
import { IncidentCardType, CreateIncidentData } from 'entities/Incident'
import { FormattedMessage, useIntl } from 'react-intl'
import { useState } from 'react'
import IconCardDoubleYellow from '@sofascore/ui/dist/modules/Icons/_IconCardDoubleYellow'
import { useFirebaseWithEvent } from 'entities/Event/hooks'
import { FirebaseEventIncidentAction, FirebaseEventName, FirebaseEventType } from 'shared/lib/firebase/model'

import IncidentBox from 'components/IncidentBox'
import ResponsivePopUp from 'components/ResponsivePopUp'

import { AddCardForm } from '../AddCardForm'
import { additionalTimeDisplayLimit } from '../../config'

interface Props {
  onCreate: (arg0: CreateIncidentData) => Promise<void>
}

export const AddRugbyYellowRedCard = ({ onCreate }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const intl = useIntl()
  const logFirebaseWithEvent = useFirebaseWithEvent()

  const responsivePopupOpen = () => {
    setIsOpen(true)
    logFirebaseWithEvent(FirebaseEventName.AddEntity, FirebaseEventType.Incident, FirebaseEventIncidentAction.Card)
  }
  const responsivePopupClose = (discarded?: boolean | undefined) => {
    setIsOpen(false)
    if (discarded)
      logFirebaseWithEvent(
        FirebaseEventName.DiscardEntity,
        FirebaseEventType.Incident,
        FirebaseEventIncidentAction.Card,
      )
    else
      logFirebaseWithEvent(FirebaseEventName.SubmitEntity, FirebaseEventType.Incident, FirebaseEventIncidentAction.Card)
  }

  return (
    <>
      <IncidentBox
        label={intl.formatMessage({ id: '2nd_yellow_card' })}
        onClick={responsivePopupOpen}
        icon={<IconCardDoubleYellow width="24" height="24" />}
      />

      <ResponsivePopUp
        isOpen={isOpen}
        onClose={responsivePopupClose}
        heading={
          <Flex align="center">
            <IconCardDoubleYellow width="24" height="24" />
            <Text font="display.large" ml="sm">
              <FormattedMessage id="add_2nd_yellow_card" />
            </Text>
          </Flex>
        }
      >
        <AddCardForm
          incidentClass={IncidentCardType.YellowRed}
          additionalTimeDisplayLimit={additionalTimeDisplayLimit}
          onSubmit={onCreate}
          onClose={responsivePopupClose}
        />
      </ResponsivePopUp>
    </>
  )
}
