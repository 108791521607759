import { Text, colors } from '@sofascore/ui'
import IconChevronRight16 from '@sofascore/ui/dist/modules/Icons/IconChevronRight16'
import { FormattedMessage, useIntl } from 'react-intl'
import { useEventState } from 'entities/Event'
import { StatusType } from 'entities/Status'

import { secondaryColor } from 'styles/color'
import { getTimeParts, isToday, isTomorrow } from 'utils/time'

import * as S from './styles'

interface Props {
  isLiveEditingEnabled: boolean
  merge?: 'top' | 'bottom'
  disabled?: boolean
  onEditLiveClick: () => void
}

const EditLiveButton = ({ isLiveEditingEnabled, merge, onEditLiveClick }: Props) => {
  const intl = useIntl()

  const { initialEvent } = useEventState()
  const { startTimestamp, status } = initialEvent

  const notStarted = status.type === StatusType.NOT_STARTED
  const hasEnded = status.type === StatusType.FINISHED

  let endingContent: string | JSX.Element
  if (isLiveEditingEnabled) {
    endingContent = <IconChevronRight16 fill={secondaryColor} />
  } else if (isToday(startTimestamp) && notStarted) {
    endingContent = intl.formatMessage({ id: 'editor_calendar_today' })
  } else if (hasEnded) {
    endingContent = intl.formatMessage({ id: 'Ended' })
  } else if (isTomorrow(startTimestamp)) {
    endingContent = intl.formatMessage({ id: 'editor_tomorrow' })
  } else {
    const daysRemaining = getTimeParts(startTimestamp - Date.now() / 1000)[0]
    endingContent = intl.formatRelativeTime(daysRemaining, 'day')
  }

  return (
    <S.StyledButton onClick={onEditLiveClick} disabled={!isLiveEditingEnabled} merge={merge}>
      <Text
        fontWeight="bold"
        color={!isLiveEditingEnabled ? colors.light['--on-surface-nLv3'] : secondaryColor}
        textTransform="capitalize"
      >
        <FormattedMessage id="edit_live" />
      </Text>

      <Text
        fontSize={12}
        fontWeight={500}
        color={!isLiveEditingEnabled ? colors.light['--on-surface-nLv3'] : secondaryColor}
      >
        {endingContent}
      </Text>
    </S.StyledButton>
  )
}

export default EditLiveButton
