import { UniqueTournamentDetailsResponse } from 'entities/UniqueTournament'
import { SeasonDetailsResponse } from 'entities/Season'

import * as routes from 'api/routes'
import { getJson, postJson, putJson } from 'api/utilities'

import { InfoGeneralFormAPIPayload } from '../model'

export const updateUniqueTournamentDetails = (utID: number, values: InfoGeneralFormAPIPayload) =>
  putJson(routes.uniqueTournamentDetails(utID), { ...values }).then(
    response => response as UniqueTournamentDetailsResponse,
  )

export const getSeasonInfo = (seasonID: number) => getJson(routes.seasonInfo(seasonID))

export const updateSeasonInfo = (seasonID: number, values: InfoGeneralFormAPIPayload) =>
  postJson(routes.seasonInfo(seasonID), { ...values }).then(response => response as SeasonDetailsResponse)
