export enum EventActionType {
  SET_EVENT = 'SET_EVENT',
  SET_INITIAL_EVENT = 'SET_INITIAL_EVENT',
  SET_SCORES = 'SET_SCORES',
  RESET_SCORES = 'RESET_SCORES',
  RESET_PERIOD_SCORES = 'RESET_PERIOD_SCORES',
  REVERT_SCORES = 'REVERT_SCORES',
  REINITIALIZE_SCORES = 'REINITIALIZE_SCORES',
  RESET_PENALTIES = 'RESET_PENALTIES',
  SET_PERIOD_SCORE = 'SET_PERIOD_SCORE',
  INCREMENT_SCORE = 'INCREMENT_SCORE',
  INCREMENT_PERIOD_SCORE = 'INCREMENT_PERIOD_SCORE',
  DECREMENT_PERIOD_SCORE = 'DECREMENT_PERIOD_SCORE',
  DECREMENT_SCORE = 'DECREMENT_SCORE',
  INCREMENT_PENALTY_SCORE = 'INCREMENT_PENALTY_SCORE',
  DECREMENT_PENALTY_SCORE = 'DECREMENT_PENALTY_SCORE',
  UPDATE_STATUS_CODE = 'UPDATE_STATUS_CODE',
  UPDATE_WINNER_CODE = 'UPDATE_WINNER_CODE',
  RECALCULATE_CURRENT_SCORES = 'RECALCULATE_CURRENT_SCORES',
}
