export const getMinutesUntilStart = (startTimestamp: number) => {
  const minutesUntilStart = (startTimestamp - Date.now() / 1000) / 60

  return Math.max(minutesUntilStart, 0)
}

export const getMinutesAfterStart = (startTimestamp: number) => {
  const minutesAfterStart = (Date.now() / 1000 - startTimestamp) / 60

  return Math.max(minutesAfterStart, 0)
}

export const is15MinBefore = (startTimestamp: number) => {
  const secondsRemaining = startTimestamp - Date.now() / 1000
  return secondsRemaining <= 15 * 60 && secondsRemaining > 15 * 60 - 1
}
