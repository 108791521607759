import { Flex, Text } from '@sofascore/ui'
import { useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Chip, PermissionGuard } from 'shared/ui'
import { PermissionAction, PermissionScope } from 'entities/Permission/model'
import { usePermissions } from 'shared/hooks'

import { Spinner } from 'components/Spinner'
import { useMyCompetitionsState } from 'modules/MyCompetitions/providers/MyCompetitionsProvider'

import { EntityFilter } from '../model'
import { mapFilterToTranslation } from '../mappings'
import { MyCompetitionApprovedLeagueTeams } from './MyCompetitionApprovedLeagueTeams'
import { MyCompetitionDeclinedLeagueTeams } from './MyCompetitionDeclinedLeagueTeams'
import { MyCompetitionPendingLeagueTeams } from './MyCompetitionPendingLeagueTeams'
import { MyCompetitionLeagueEmptyState } from './MyCompetitionLeagueEmptyState'
import { MyCompetitionAddTeam } from './MyCompetitionAddTeam'
import { useGroupedCompetitionTeams, useGroupedTeamSuggestions } from '../hooks'

export const MyCompetitionLeagueTeams = () => {
  const [filter, setFilter] = useState(EntityFilter.APPROVED)

  const intl = useIntl()
  const { tournaments } = useMyCompetitionsState()

  const { guardScope } = usePermissions()
  const canSuggestTeamPermissions = guardScope([PermissionScope.Team], [PermissionAction.Create])

  // Ideally, we want to send HEAD requests here to check if data exists,
  // but BE is having problems with Authorization header on HEAD requests
  // so this is the temporary solution
  const { data: approvedTeams } = useGroupedCompetitionTeams()
  const { data: pendingTeams } = useGroupedTeamSuggestions(EntityFilter.PENDING)
  const { data: declinedTeams } = useGroupedTeamSuggestions(EntityFilter.DECLINED)

  const isLoading = approvedTeams === undefined && pendingTeams === undefined && declinedTeams === undefined
  const isEmpty = !approvedTeams && !pendingTeams && !declinedTeams

  const [league] = tournaments

  const filterChips = useMemo(
    () =>
      Object.keys(mapFilterToTranslation).filter((key: string) => {
        if (!canSuggestTeamPermissions) return key === EntityFilter.APPROVED
        return true
      }),
    [canSuggestTeamPermissions],
  )

  if (isLoading || !league) {
    return (
      <Flex justify="center" mt={100}>
        <Spinner width="40px" />
      </Flex>
    )
  }

  if (isEmpty) {
    return <MyCompetitionLeagueEmptyState tournamentId={league.id} />
  }

  return (
    <>
      <Flex h={48} br="sm" px="lg" justify="space-between" align="center" bg="surface.s1" elevation={2} mb="sm">
        <Text fontWeight="bold" color="onSurface.nLv1" fontSize="medium">
          <FormattedMessage id="teams" />
        </Text>

        <PermissionGuard entityScopes={[PermissionScope.Team]} checkAllowedActions={[PermissionAction.Create]}>
          <MyCompetitionAddTeam tournamentId={league.id} />
        </PermissionGuard>
      </Flex>

      <Flex align="center" gapX="sm">
        {filterChips.map(f => (
          <Chip
            key={f}
            value={intl.formatMessage({ id: mapFilterToTranslation[f as EntityFilter] })}
            isActive={f === filter}
            onClick={() => setFilter(f as EntityFilter)}
          />
        ))}
      </Flex>

      {filter === EntityFilter.APPROVED && <MyCompetitionApprovedLeagueTeams />}
      <PermissionGuard entityScopes={[PermissionScope.Team]} checkAllowedActions={[PermissionAction.Create]}>
        {filter === EntityFilter.PENDING && <MyCompetitionPendingLeagueTeams />}
        {filter === EntityFilter.DECLINED && <MyCompetitionDeclinedLeagueTeams />}
      </PermissionGuard>
    </>
  )
}
