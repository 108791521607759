import { Box, BottomSheet, Modal, Flex, BoxProps, useMediaContext } from '@sofascore/ui'

import { IconButton } from 'components/IconButton'
import { IconClose } from 'components/Icons/IconClose'

interface Props extends BoxProps {
  isOpen: boolean
  onClose: (closed?: boolean) => void
  heading: React.ReactNode
  portalsContainerId?: string
  children: React.ReactNode
}

export const ResponsivePopUp = ({ isOpen, onClose, heading, portalsContainerId, children, ...boxProps }: Props) => {
  const { isMobile } = useMediaContext()

  //  pass true value so we know the RPU is closed by human interaction or programmatically
  const closeHandler = () => {
    onClose(true)
  }

  if (isMobile) {
    return (
      <BottomSheet isOpen={isOpen} onClose={closeHandler} portalsContainerId={portalsContainerId}>
        <BottomSheet.Header>
          <Flex align="center" justify="space-between">
            {heading}
          </Flex>
        </BottomSheet.Header>
        <BottomSheet.Content>{children}</BottomSheet.Content>
      </BottomSheet>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={closeHandler} w={360} maxH="90vh" portalsContainerId={portalsContainerId}>
      <Flex align="center" justify="space-between" px="lg" pt="xl" pb="lg">
        {heading}

        <IconButton onClick={closeHandler}>
          <IconClose fill="#000000" />
        </IconButton>
      </Flex>
      <Box overflowY="auto" {...boxProps}>
        {children}
      </Box>
    </Modal>
  )
}
