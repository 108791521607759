import { EntityFilter } from '../model'

export const mapFilterToTranslation: Record<EntityFilter, string> = {
  [EntityFilter.APPROVED]: 'suggestionApproved',
  [EntityFilter.PENDING]: 'suggestionPending',
  [EntityFilter.DECLINED]: 'suggestionDeclined',
}

export const mapReasonToKey: Record<string, string> = {
  'Inappropriate Content': 'suggestion_inappropriate_content',
  'Incorrect Information': 'suggestion_incorrect_information',
}
