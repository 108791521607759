/* eslint-disable react-hooks/exhaustive-deps */
import { useIntl } from 'react-intl'
import { Text } from '@sofascore/ui'
import { useEffect, useMemo, useState } from 'react'

import { Option } from 'components/DropdownMenu/interface'
import { Select } from 'components/Select'
import { backgrounds } from 'styles/color'

import { DynamicLineupsPlayer } from '../../model'
import { CLEAR_CAPTAIN, SET_CAPTAIN, useLineupsDispatch } from '../../providers'

const comparePlayerOptions = (a: Option<DynamicLineupsPlayer>, b: Option<DynamicLineupsPlayer>) => {
  if (a.value.player.jerseyNumber && b.value.player.jerseyNumber) {
    return +a.value.player.jerseyNumber - +b.value.player.jerseyNumber
  }

  if (a.value.player.jerseyNumber && !b.value.player.jerseyNumber) {
    return -1
  }

  if (!a.value.player.jerseyNumber && b.value.player.jerseyNumber) {
    return 1
  }

  return a.value.player.name.localeCompare(b.value.player.name)
}

const defaultRenderOption = (option: Option<DynamicLineupsPlayer>) => (
  <Text as="div" font="body.large" px="lg" py="sm" ellipsis>
    {option.value.player.jerseyNumber ? `${option.value.player.jerseyNumber} - ${option.label}` : option.label}
  </Text>
)

const defaultRenderSelectedOption = (option: Option<DynamicLineupsPlayer> | undefined) => (
  <Text as="div" font="body.large" ellipsis>
    {option?.value.player.jerseyNumber ? `${option.value.player.jerseyNumber} - ${option.label}` : option?.label}
  </Text>
)

const LineupsCaptainSelector = ({
  starters,
  renderOption = defaultRenderOption,
  renderSelectedOption = defaultRenderSelectedOption,
  sortOptions = comparePlayerOptions,
}: {
  starters: DynamicLineupsPlayer[]
  renderOption?: (option: Option<DynamicLineupsPlayer>) => JSX.Element
  renderSelectedOption?: (option?: Option<DynamicLineupsPlayer>) => JSX.Element
  sortOptions?: (a: Option<DynamicLineupsPlayer>, b: Option<DynamicLineupsPlayer>) => number
}) => {
  const [captain, setCaptain] = useState<Option<DynamicLineupsPlayer> | undefined>()
  const dispatch = useLineupsDispatch()
  const intl = useIntl()

  const playerOptions: Option<DynamicLineupsPlayer>[] = useMemo(() => {
    return starters
      .map(p => {
        return {
          label: p.player.name,
          value: p,
        }
      })
      .sort(sortOptions)
  }, [starters])

  const handleChange = (option: Option<DynamicLineupsPlayer> | undefined) => {
    handleCaptainChange(option?.value)
    setCaptain(option)
  }

  const handleCaptainChange = (lineupPlayer: DynamicLineupsPlayer | undefined) => {
    if (lineupPlayer) {
      dispatch({ type: SET_CAPTAIN, payload: { lineupPlayer: lineupPlayer } })
    } else {
      dispatch({ type: CLEAR_CAPTAIN, payload: { previousCaptain: captain!.value } })
    }
  }

  useEffect(() => {
    const cap = starters.find(s => s.captain)

    if (cap) {
      if (cap.player.id !== captain?.value.player.id) {
        setCaptain({ label: cap.player.name, value: cap })
      }
    } else {
      setCaptain(undefined)
    }
  }, [starters])

  useEffect(() => {
    if (!starters.length) {
      setCaptain(undefined)
    }
  }, [starters])

  return (
    <Select
      value={captain}
      onChange={handleChange}
      options={playerOptions}
      disabled={playerOptions.length === 0}
      label={intl.formatMessage({ id: 'editor_team_captain' })}
      selectBackgroundColor={backgrounds.chip}
      renderOption={renderOption}
      renderSelectedOption={renderSelectedOption}
    />
  )
}

export default LineupsCaptainSelector
