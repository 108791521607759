import { useMediaContext } from '@sofascore/ui'
import { useIntl } from 'react-intl'

import { CompetitionInfoProvider } from 'modules/Competition/competitionContext'

import { MobileMatches } from './MobileMatches'
import { DesktopMatches } from './DesktopMatches'
import CompetitionMatchesContextProvider from './CompetitionMatchesContext'
import { useMyCompetitionsState } from '../providers/MyCompetitionsProvider'
import MatchesNavigation from './MatchesNavigation'
import FilterList from './FilterList'
import MyCompetitionTabTitle from '../components/MyCompetitionTabTitle'

/**
 * This component is loaded in Outlet (as a page content) in MyCompetition.tsx
 */
const MyCompetitionMatches = () => {
  const intl = useIntl()
  const { isMobile } = useMediaContext()
  const { tournaments } = useMyCompetitionsState()

  if (!tournaments.length) return null

  return (
    <CompetitionMatchesContextProvider>
      <CompetitionInfoProvider>
        <MyCompetitionTabTitle title={intl.formatMessage({ id: 'matches' })} />
        <MatchesNavigation />
        <FilterList />
        {isMobile ? <MobileMatches tournaments={tournaments} /> : <DesktopMatches tournaments={tournaments} />}
      </CompetitionInfoProvider>
    </CompetitionMatchesContextProvider>
  )
}

export default MyCompetitionMatches
