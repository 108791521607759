import styled from 'styled-components'

export const ClearAllButton = styled.button`
  appearance: none;

  padding: 8px 12px;
  background: ${p => p.theme.colors.surface.s2};
  border-radius: 8px;
  border: none;

  transition: all 0.2s ease;
  cursor: pointer;

  :hover {
    background: ${p => p.theme.colors.surface.s0};
  }
`
