import { Flex, Text } from '@sofascore/ui'
import { TeamCard } from 'entities/Team'
import { useRemoveTeamHandler } from 'features/RemoveTeam'
import { WithSwipeToDelete, PermissionGuard } from 'shared/ui'
import IconDelete from '@sofascore/ui/dist/modules/Icons/IconDelete'
import { useNavigate, useParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { PermissionAction } from 'entities/Permission'
import { usePermissions } from 'shared/hooks'

import { IconButton } from 'components/IconButton'
import { Spinner } from 'components/Spinner'

import * as S from './styles'
import { useGroupedCompetitionTeams } from '../hooks'

export const MyCompetitionApprovedLeagueTeams = () => {
  const { competitionId, seasonId } = useParams()
  const { data, error, isLoading } = useGroupedCompetitionTeams()
  const { guardEntity } = usePermissions()

  const navigate = useNavigate()
  const removeTeam = useRemoveTeamHandler()

  const backRef = `/my-competitions/${competitionId}/season/${seasonId}/teams`

  if (isLoading) {
    return (
      <Flex justify="center" mt={100}>
        <Spinner width="40px" />
      </Flex>
    )
  }

  if (error) return null

  const [league] = data!.groups
  const { teams, tournament } = league

  if (!teams.length) {
    return (
      <Flex direction="column" align="center" gapY="lg" pt={128}>
        <Text font="display.medium">
          <FormattedMessage id="no_approved_requests" />
        </Text>
        <Text font="body.medium" color="onSurface.nLv3">
          <FormattedMessage id="approved_requests_team_empty_text" />
        </Text>
      </Flex>
    )
  }

  return (
    <Flex direction="column" bg="surface.s1" elevation={2} br="sm" mt="sm" py="sm">
      <Flex direction="column">
        {teams
          .sort((t1, t2) => t1.name.localeCompare(t2.name))
          .map(team => (
            <WithSwipeToDelete
              key={team.id}
              onSwipe={() => removeTeam(team, tournament.id)}
              disabled={!guardEntity(team.allowedActions, [PermissionAction.Delete])}
            >
              <S.DeleteWrapper
                px="lg"
                py="sm"
                hoverBg="surface.s2"
                cursor="pointer"
                onClick={() => {
                  navigate(`/team/${team.id}?uniqueTournamentId=${competitionId}`, {
                    state: { backRef },
                  })
                }}
              >
                <TeamCard team={team}>
                  <PermissionGuard
                    entityAllowedActions={team.allowedActions}
                    checkAllowedActions={[PermissionAction.Delete]}
                  >
                    <IconButton
                      br="5px"
                      p="5px"
                      hoverBg="onSurface.nLv5"
                      onClick={e => {
                        e?.stopPropagation()
                        removeTeam(team, tournament.id)
                      }}
                    >
                      <IconDelete fill="onSurface.nLv1" />
                    </IconButton>
                  </PermissionGuard>
                </TeamCard>
              </S.DeleteWrapper>
            </WithSwipeToDelete>
          ))}
      </Flex>
    </Flex>
  )
}
