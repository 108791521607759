import { useEffect, useState } from 'react'
import {
  AET,
  AP,
  CANCELED_CODE,
  ENDED_CODE,
  INTERRUPTED_CODE,
  NOT_STARTED_CODE,
  POSTPONED_CODE,
  StatusType,
} from 'entities/Status'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useEventState } from '../providers'

export const useEventStatusListener = () => {
  const [isLive, setIsLive] = useState(false)
  const [isPostMatch, setIsPostMatch] = useState(false)

  const { competitionId, seasonId, eventId } = useParams()
  const { event, initialEvent } = useEventState()
  const { state } = useLocation()
  const navigate = useNavigate()

  const { status, startTimestamp } = event
  const { status: initialStatus } = initialEvent

  useEffect(() => {
    const currentTimestamp = Math.floor(new Date().getTime() / 1000)
    const eventStartTimestamp = startTimestamp || currentTimestamp

    const isEditLiveUnlocked = (eventStartTimestamp - currentTimestamp) / 60 <= 15 // Unlock 15min before the match
    const hasEditLiveExpiredForNotStarted =
      status.code === NOT_STARTED_CODE && (currentTimestamp - eventStartTimestamp) / 60 >= 105 // 1h 45min after start

    if ((isEditLiveUnlocked && !hasEditLiveExpiredForNotStarted) || status.type === StatusType.IN_PROGRESS) {
      setIsLive(true)
      setIsPostMatch(false)
    }

    if (hasEditLiveExpiredForNotStarted || status.code === AP || status.code === AET || status.code === ENDED_CODE) {
      setIsLive(false)
      setIsPostMatch(true)
    }

    if (
      initialStatus?.code === CANCELED_CODE ||
      initialStatus?.code === INTERRUPTED_CODE ||
      initialStatus?.code === POSTPONED_CODE
    ) {
      setIsLive(false)
      setIsPostMatch(false)
      navigate(`/my-competitions/${competitionId}/season/${seasonId}/matches/${eventId}/info`, { state: state })
    }
  }, [startTimestamp, initialStatus, status, navigate, competitionId, seasonId, eventId, state])

  return {
    isLive,
    isPostMatch,
  }
}
