import { Flex } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'
import { AddNewTeam, AddExistingTeam } from 'features/AddTeam'

import { Button } from 'components/Button'

import { MyCompetitionNoTeams } from './MyCompetitionNoTeams'
import { useGroupedCompetitionTeams } from '../hooks'

interface MyCompetitionLeagueEmptyStateProps {
  tournamentId: number
}

export const MyCompetitionLeagueEmptyState = ({ tournamentId }: MyCompetitionLeagueEmptyStateProps) => {
  const { revalidate } = useGroupedCompetitionTeams()

  return (
    <Flex direction="column" gapY="xl" align="center" px="lg">
      <MyCompetitionNoTeams />
      <Flex direction="column" gapY="xl" w={296}>
        <AddNewTeam tournamentId={tournamentId} onSave={() => revalidate()}>
          {(_, setIsOpen) => (
            <Button variant="contained" fullWidth onClick={() => setIsOpen(true)}>
              <FormattedMessage id="add_new_team" />
            </Button>
          )}
        </AddNewTeam>
        <AddExistingTeam tournamentId={tournamentId} onSave={() => revalidate()}>
          {(_, setIsOpen) => (
            <Button variant="contained" fullWidth onClick={() => setIsOpen(true)}>
              <FormattedMessage id="add_existing_team" />
            </Button>
          )}
        </AddExistingTeam>
      </Flex>
    </Flex>
  )
}
