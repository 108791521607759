import { AnyAction } from 'redux'

import { CompetitionMatchesState } from './interface'
import { ActionPayload, ActionType } from './actions'

export const competitionMatchesReducer = (state: CompetitionMatchesState | null = null, action: AnyAction) => {
  if (!state) return state

  const payload = action.payload as ActionPayload

  switch (action.type) {
    case ActionType.REGISTER_REVALIDATED_EVENT: {
      const newRevalidatedEvents = [...state.revalidatedEvents]
      const newRevalidatedEvent = payload.revalidatedEvent!

      // Check if new revalidated event already exists and replace it
      const indexOfNewRevalidatedEvent = newRevalidatedEvents.findIndex(event => event.id === newRevalidatedEvent.id)
      if (indexOfNewRevalidatedEvent !== -1) {
        newRevalidatedEvents[indexOfNewRevalidatedEvent] = newRevalidatedEvent
      }
      // If not add it
      else {
        newRevalidatedEvents.push(newRevalidatedEvent)
      }

      return { ...state, revalidatedEvents: newRevalidatedEvents }
    }

    case ActionType.UNREGISTER_REVALIDATED_EVENT: {
      const eventId = payload.eventId!

      const newRevalidatedEvents = state.revalidatedEvents.filter(event => event.id !== eventId)

      return { ...state, revalidatedEvents: newRevalidatedEvents }
    }

    case ActionType.REMOVE_ALL_EVENTS: {
      return { ...state, revalidatedEvents: [] }
    }

    default:
      return state
  }
}
