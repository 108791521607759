import { Box, Flex, Text } from '@sofascore/ui'
import { useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSubstitutionReasonOptions, Incident, UpdateIncidentData } from 'entities/Incident'
import { useEventState } from 'entities/Event'
import {
  useTeamPlayerOptions,
  PlayerOption,
  getDefaultOption,
  renderPlayerOption,
  renderSelectedPlayerOption,
} from 'entities/Player'
import { TeamPicker } from 'entities/Team'
import { UntranslatedOption } from 'shared/model'

import { Button } from 'components/Button'
import WheelPicker from 'components/WheelPicker/WheelPicker'
import {
  formatMatchTimeToOptionValue,
  formatTimeOptionValueToMatchTime,
  generateCurrentFootballMatchTimeOptions,
} from 'utils/matchTime'
import { Select } from 'components/Select'

interface Props {
  onSubmit: (arg0: UpdateIncidentData) => Promise<void>
  onClose: () => void
  incident: Incident
  substitutionOptions: UntranslatedOption[]
}

export const EditSubstitutionForm = ({ incident, substitutionOptions, onClose, onSubmit }: Props) => {
  const [isSaving, setIsSaving] = useState(false)

  const { event } = useEventState()
  const intl = useIntl()
  const { id: eventId, homeTeam, awayTeam } = event

  const {
    id,
    isHome,
    incidentType,
    incidentClass: initialIncidentClass,
    playerIn: initialPlayerIn,
    playerOut: initialPlayerOut,
    time: initialTime,
    addedTime: initialAddedTime,
  } = incident

  const [matchTime, setMatchTime] = useState(
    initialTime
      ? {
          time: formatMatchTimeToOptionValue({ time: initialTime, addedTime: initialAddedTime }),
        }
      : { time: '1' },
  )

  const [playerIn, setPlayerIn] = useState<PlayerOption | undefined>(getDefaultOption(initialPlayerIn))
  const [playerOut, setPlayerOut] = useState<PlayerOption | undefined>(getDefaultOption(initialPlayerOut))

  const [teamId, setTeamId] = useState<number>(isHome ? homeTeam.id : awayTeam.id)

  const { options, substitutionReason, setSubstitutionReason } = useSubstitutionReasonOptions(
    substitutionOptions,
    initialIncidentClass,
  )

  const handleConfirm = async () => {
    const incidentTime = formatTimeOptionValueToMatchTime(matchTime.time)

    setIsSaving(true)

    onSubmit({
      eventId: eventId,
      incidentId: id as number,
      incidentData: {
        incidentType,
        incidentClass: substitutionReason.value as string,
        isHome: teamId === homeTeam.id,
        time: incidentTime.time,
        addedTime: incidentTime.addedTime,
        playerIn: playerIn?.value,
        playerOut: playerOut?.value,
      },
    })
      .then(() => onClose())
      .finally(() => setIsSaving(false))
  }

  const [playerInOptions, playerOutOptions] = useTeamPlayerOptions(teamId, playerIn, playerOut)

  const timeOptions = useMemo(() => generateCurrentFootballMatchTimeOptions(event), [event])

  return (
    <Box px="sm" pb="xxl">
      <Box px="sm" pt="lg" pb="sm" mb="lg" br="sm" bg="surface.s2">
        <Flex justify="center" mb="lg">
          <Text font="display.small">
            <FormattedMessage id="confirm_time" />
          </Text>
        </Flex>
        <Box>
          <WheelPicker
            columnsOptions={{ time: timeOptions }}
            columnsValues={matchTime}
            onChange={(_name, option) => {
              setMatchTime({ time: option as string })
            }}
            height={100}
          />
        </Box>
      </Box>

      <Box px="sm" pt="lg" pb="xs" br="sm" bg="surface.s2">
        <Flex mb="xl" justify="center">
          <Text font="display.small">
            <FormattedMessage id="substituted_player" />
          </Text>
        </Flex>

        <TeamPicker
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          teamId={teamId}
          onChangeTeam={newTeamId => {
            setTeamId(newTeamId)
            setPlayerIn(undefined)
            setPlayerOut(undefined)
          }}
        />

        <Select
          label={intl.formatMessage({ id: 'in' })}
          options={playerInOptions}
          helperText="Optional"
          value={playerIn}
          onChange={setPlayerIn}
          maxVisibleOptions={6}
          w="100%"
          selectBackgroundColor="surface.s1"
          renderOption={renderPlayerOption}
          renderSelectedOption={renderSelectedPlayerOption}
        />

        <Select
          label={intl.formatMessage({ id: 'out' })}
          options={playerOutOptions}
          helperText="Optional"
          value={playerOut}
          onChange={setPlayerOut}
          maxVisibleOptions={6}
          w="100%"
          selectBackgroundColor="surface.s1"
          renderOption={renderPlayerOption}
          renderSelectedOption={renderSelectedPlayerOption}
        />

        <Select
          label={intl.formatMessage({ id: 'reason' })}
          value={substitutionReason}
          options={options}
          onChange={setSubstitutionReason}
          maxVisibleOptions={6}
          w="100%"
          required
          selectBackgroundColor="surface.s1"
        />
      </Box>

      <Flex gapX="lg" px="lg" mt="xxl">
        <Button variant="outlined" fullWidth onClick={onClose}>
          <FormattedMessage id="cancel" />
        </Button>
        <Button
          variant="contained"
          fullWidth
          onClick={handleConfirm}
          isLoading={isSaving}
          disabled={!substitutionReason?.value}
        >
          <FormattedMessage id="confirm" />
        </Button>
      </Flex>
    </Box>
  )
}
