import { useEventState } from 'entities/Event'
import { IncidentActionType, useEventIncidentsDispatch, useEventIncidentsState } from 'entities/Incident'
import { useEffect } from 'react'
import isEqual from 'lodash/isEqual'
import { useEventPlayers } from 'entities/Player'

export const useIncidentExtender = () => {
  const { event } = useEventState()
  const { incidents } = useEventIncidentsState()
  const eventPlayers = useEventPlayers()
  const incidentsDispatch = useEventIncidentsDispatch()

  const { homeTeam, awayTeam } = event

  // Since BE only sends us half of player data, we need to append all the missing
  // player props to every incident that includes a player. Not the most performant
  // solution, but works for now.
  useEffect(() => {
    const updatedIncidents = incidents
      ? incidents.map(incident => {
          return {
            ...incident,
            player: {
              ...incident.player,
              ...eventPlayers[incident.isHome ? homeTeam.id : awayTeam.id].find(p => p.id === incident.player?.id),
            },
            assist1: {
              ...incident.assist1,
              ...eventPlayers[incident.isHome ? homeTeam.id : awayTeam.id].find(p => p.id === incident.assist1?.id),
            },
            playerIn: {
              ...incident.playerIn,
              ...eventPlayers[incident.isHome ? homeTeam.id : awayTeam.id].find(p => p.id === incident.playerIn?.id),
            },
            playerOut: {
              ...incident.playerOut,
              ...eventPlayers[incident.isHome ? homeTeam.id : awayTeam.id].find(p => p.id === incident.playerOut?.id),
            },
          }
        })
      : []

    if (!isEqual(incidents, updatedIncidents)) {
      incidentsDispatch({ type: IncidentActionType.SET_INCIDENTS, payload: updatedIncidents })
    }
  }, [eventPlayers, incidents, incidentsDispatch, homeTeam, awayTeam])
}
