import * as React from 'react'
import { IconButton, Button, makeStyles, createStyles, CircularProgress } from '@material-ui/core'
import { useMediaContext } from '@sofascore/ui'
import CheckIcon from '@material-ui/icons/Check'
import { useNavigate, useParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import { IconDelete } from 'components/Icons/IconDelete'
import { IconClose } from 'components/Icons/IconClose'
import DiscardDialog from 'modules/Matches/components/Dialogs/DiscardDialog'
import useBoolean from 'utils/useBoolean'
import { useCompetitionState } from 'modules/Competition/competitionContext'

interface DeleteMatchButtonProps {
  deleteHandler: () => void
  disabled?: boolean
}

interface CloseMatchButtonProps {
  close?: () => void
}

interface SaveMatchProps {
  disabled: boolean
  onClick: () => void
  isNewMatch?: boolean
  isSaving?: boolean
}

interface DiscardNewMatchButtonProps {
  hasUnsavedChanges: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    discardButton: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  }),
)

// Button for deleting existing match
export const DeleteMatchButton = ({ deleteHandler, disabled }: DeleteMatchButtonProps) => {
  return (
    <>
      <IconButton
        data-testid="delete-match-button"
        onClick={deleteHandler}
        style={disabled ? { opacity: 0.5, color: 'white' } : { color: 'white' }}
        disabled={disabled}
      >
        <IconDelete />
      </IconButton>
    </>
  )
}

// Button for exiting existing match view and opening save dialog in case of unsaved changes
export const CloseMatchButton = ({ close }: CloseMatchButtonProps) => {
  const navigate = useNavigate()
  const { competitionId, seasonId } = useParams()

  const clickHandler = React.useCallback(() => {
    if (close) {
      close()
    } else {
      navigate(`/my-competitions/${competitionId}/season/${seasonId}/matches`)
    }
  }, [close, competitionId, navigate, seasonId])

  if (!competitionId && !close) return null

  return (
    <IconButton onClick={clickHandler}>
      <IconClose width={24} height={24} />
    </IconButton>
  )
}

// Button for exiting creating new match view and opening discarding dialog if we have unsaved changes
export const DiscardNewMatchButton = ({ hasUnsavedChanges }: DiscardNewMatchButtonProps) => {
  const navigate = useNavigate()
  const [isOpen, { setTrue: openDialog, setFalse: closeDialog }] = useBoolean(false)
  const { isMobile } = useMediaContext()

  const { competitionId, selectedTournament } = useCompetitionState()

  const onClick = React.useCallback(() => {
    if (hasUnsavedChanges) {
      openDialog()
    } else if (competitionId && selectedTournament) {
      navigate(`/my-competitions/${competitionId}/tournament/${selectedTournament.id}/matches`)
    }
  }, [hasUnsavedChanges, selectedTournament, openDialog, navigate, competitionId])

  const classes = useStyles()

  return (
    <>
      {isMobile ? (
        <IconButton onClick={onClick}>
          <IconClose width={24} height={24} />
        </IconButton>
      ) : (
        <Button onClick={onClick} style={{ width: 94, height: 36, color: 'white' }} className={classes.discardButton}>
          <FormattedMessage id="discard" />
        </Button>
      )}
      {/* Dialog for unsaved changes when creating new event */}
      <DiscardDialog isOpen={isOpen} handleClose={closeDialog} />
    </>
  )
}

export const SaveMatch = ({ disabled, onClick, isNewMatch, isSaving }: SaveMatchProps) => {
  const { isMobile } = useMediaContext()

  if (isNewMatch && !isMobile) {
    return (
      <Button variant="contained" onClick={onClick} disabled={disabled}>
        <FormattedMessage id="save" />
      </Button>
    )
  }

  if (isSaving) {
    return (
      <div style={{ padding: 12, display: 'flex' }}>
        <CircularProgress disableShrink style={{ width: 24, height: 24, color: 'white' }} />
      </div>
    )
  }

  return (
    <IconButton
      style={disabled ? { opacity: 0.5, color: 'white' } : { color: 'white' }}
      onClick={onClick}
      disabled={disabled}
    >
      <CheckIcon width={24} height={24} />
    </IconButton>
  )
}
