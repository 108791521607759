import { IconProps, IconBase } from './Base'

const IconPenaltyTry = ({ fill = '#0BB32A' }: IconProps) => {
  return (
    <IconBase width={24} height={24} viewBox="0 0 24 24">
      <g fill={fill} fillRule="evenodd">
        <path d="m16.21 8.93-.97 2.29-4.21 4.21-2.29.97-.52.05c.4-2.02 1.36-3.86 2.85-5.39a10.71 10.71 0 0 1 5.21-2.81l-.07.68zM13.6 21.26l.97-2.29 4.21-4.22 2.29-.97.67-.07c-.42 1.96-1.37 3.74-2.82 5.22a10.678 10.678 0 0 1-5.38 2.84l.05-.52.01.01z" />
        <path d="m8.07 17.46.99-.11 2.61-1.14 4.46-4.51 1.07-2.59.11-1.02c.27-.03.55-.06.83-.07 1.07-.05 2.29 0 3.1.68l.07.07c.55.65.75 1.62.68 3.09-.01.29-.04.57-.07.85l-1.16.13-2.61 1.14-4.46 4.51-1.07 2.59-.09.85c-.22.02-.44.05-.66.06-1.07.05-2.29 0-3.1-.68l-.07-.07c-.48-.57-.7-1.36-.7-2.55 0-.18 0-.35.01-.54.01-.23.04-.45.06-.68v-.01z" />
        <path
          d="M3.5 4c.28 0 .53.08.75.21.51-1.29 1.76-2.2 3.23-2.21H9l1 1h1V2h6l-1 2-5 2-.05.03A3.49 3.49 0 0 1 7.5 9c-1.48 0-2.73-.92-3.25-2.21-.22.13-.48.21-.75.21C2.67 7 2 6.33 2 5.5S2.67 4 3.5 4zm0 2c.28 0 .5-.22.5-.5S3.78 5 3.5 5s-.5.22-.5.5.22.5.5.5z"
          fillRule="nonzero"
        />
      </g>
    </IconBase>
  )
}

export default IconPenaltyTry
