import { Autocomplete, Box, Flex, Button } from '@sofascore/ui'
import { FormattedMessage, useIntl } from 'react-intl'
import { useEffect, useState } from 'react'
import { BasicTournament } from 'entities/Tournament'

import { useMatchesFilters } from '../hooks'

interface MatchFilterFormProps {
  tournaments: BasicTournament[]
  onClose: () => void
}

export const MatchesFiltersForm = ({ tournaments, onClose }: MatchFilterFormProps) => {
  const { selectedRoundOption, roundOptions, selectedTournamentOption, applyFilters } = useMatchesFilters()

  const [tempSelectedRoundOption, setTempSelectedRoundOption] = useState(selectedRoundOption)
  const [tempSelectedTournamentOption, setTempSelectedTournamentOption] = useState(selectedTournamentOption)

  const intl = useIntl()

  const handleConfirm = () => {
    applyFilters(tempSelectedRoundOption, tempSelectedTournamentOption)

    onClose()

    setTempSelectedRoundOption(undefined)
    setTempSelectedTournamentOption(undefined)
  }

  useEffect(() => {
    setTempSelectedRoundOption(selectedRoundOption)
  }, [selectedRoundOption])

  useEffect(() => {
    setTempSelectedTournamentOption(selectedTournamentOption)
  }, [selectedTournamentOption])

  const isOneTournamentCompetition = tournaments.length === 1
  const hasChanges =
    selectedRoundOption?.value !== tempSelectedRoundOption?.value ||
    selectedTournamentOption?.value !== tempSelectedTournamentOption?.value

  return (
    <>
      <Box minH={300} px="lg" pb="lg">
        <Autocomplete
          label={intl.formatMessage({ id: 'round' })}
          value={selectedRoundOption}
          options={roundOptions}
          onChange={option => setTempSelectedRoundOption(option)}
          maxVisibleOptions={4}
        />

        <Autocomplete
          mt="lg"
          label={intl.formatMessage({ id: 'tournament' })}
          value={
            isOneTournamentCompetition
              ? { value: tournaments[0].id.toString(), label: tournaments[0].name }
              : selectedTournamentOption
          }
          options={tournaments.map(tournament => {
            return { value: tournament.id.toString(), label: tournament.name }
          })}
          onChange={option => setTempSelectedTournamentOption(option)}
          disabled={isOneTournamentCompetition}
          clearable={!isOneTournamentCompetition}
          maxVisibleOptions={4}
        />
      </Box>

      <Flex w="100%" justify="center" gapX="lg" px="lg" pb="xl">
        <Button variant="outline" minW={132} h={48} onClick={onClose}>
          <FormattedMessage id="cancel" />
        </Button>

        <Button variant="solid" minW={132} h={48} onClick={handleConfirm} disabled={!hasChanges}>
          <FormattedMessage id="confirm" />
        </Button>
      </Flex>
    </>
  )
}
