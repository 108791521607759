import { StatusType } from 'entities/Status'
import { SportPeriodConfig } from 'features/EditPeriod'

// Supported periods for live rugby matches.
export const rugbyRegularPeriods: SportPeriodConfig[] = [
  {
    code: 6,
    type: StatusType.IN_PROGRESS,
    shortTitleKey: '1st_half',
    longTitleKey: '1st_half_start_time',
    descriptionKey: null,
    startTimeStampPath: 'time.period1StartTimestamp',
  },
  {
    code: 31,
    type: StatusType.IN_PROGRESS,
    shortTitleKey: 'Halftime.short',
    longTitleKey: 'half_time',
    descriptionKey: 'proceed_to_ht',
    startTimeStampPath: undefined,
  },
  {
    code: 7,
    type: StatusType.IN_PROGRESS,
    shortTitleKey: '2nd_half',
    longTitleKey: '2nd_half_start_time',
    descriptionKey: null,
    startTimeStampPath: 'time.period2StartTimestamp',
  },
]

export const rugbyExtraPeriods: SportPeriodConfig[] = [
  {
    code: 32,
    type: StatusType.IN_PROGRESS,
    shortTitleKey: 'awaiting_et',
    longTitleKey: 'awaiting_extra_time',
    descriptionKey: null,
    startTimeStampPath: undefined,
  },
  {
    code: 41,
    type: StatusType.IN_PROGRESS,
    shortTitleKey: '1st_extra_time_short',
    longTitleKey: '1st_extra_start_time',
    descriptionKey: null,
    startTimeStampPath: 'time.extra1StartTimestamp',
  },
  {
    code: 33,
    type: StatusType.IN_PROGRESS,
    shortTitleKey: 'ExtraTimeHalftime.short',
    longTitleKey: 'extra_time_half_time',
    descriptionKey: 'proceed_to_et_ht',
    startTimeStampPath: undefined,
  },
  {
    code: 42,
    type: StatusType.IN_PROGRESS,
    shortTitleKey: '2nd_extra_time_short',
    longTitleKey: '2nd_extra_start_time',
    descriptionKey: null,
    startTimeStampPath: 'time.extra2StartTimestamp',
  },
]

export const rugbyPenaltyPeriod: SportPeriodConfig = {
  code: 50,
  type: StatusType.IN_PROGRESS,
  shortTitleKey: 'Penalties',
  longTitleKey: 'Penalties',
  descriptionKey: 'proceed_to_penalties_question',
  startTimeStampPath: undefined,
}
