import { Flex, Portal, Text } from '@sofascore/ui'

import ResponsivePopUp from 'components/ResponsivePopUp'
import { Button } from 'components/Button'

import useConfirm from './useConfirm'

const ConfirmDialog = () => {
  const { onConfirm, onCancel, state } = useConfirm()

  const component = state.show ? (
    <ResponsivePopUp
      isOpen={state.show}
      onClose={onCancel}
      heading={
        <Flex align="center">
          <Text font="body.large" fontWeight="bold" fontSize={18}>
            {state.titleTextElement}
          </Text>
        </Flex>
      }
    >
      <Flex justify="center" pt="xxl" px="xl">
        <Text lineHeight="20px" align="center">
          {state.messageTextElement}
        </Text>
      </Flex>

      <Flex justify="center" gapX={16} px={40} pt="xxl" pb="xl">
        {state.closeTextElement && (
          <Button variant="outlined" fullWidth onClick={onCancel}>
            {state.closeTextElement}
          </Button>
        )}

        <Button data-testid="delete-dialog-button" variant="contained" fullWidth onClick={onConfirm}>
          {state.confirmTextElement}
        </Button>
      </Flex>
    </ResponsivePopUp>
  ) : null

  return <Portal>{component}</Portal>
}

export default ConfirmDialog
