import { Dispatch, SetStateAction } from 'react'
import { useIntl } from 'react-intl'
import { Flex, Text } from '@sofascore/ui'
import IconDelete from '@sofascore/ui/dist/modules/Icons/IconDelete'
import { BasicEvent } from 'entities/Event'
import { PermissionAction } from 'entities/Permission'
import { usePermissions } from 'shared/hooks'

import { Spinner } from 'components/Spinner'
import { formatNumber } from 'utils/strings'
import { IconUser } from 'components/Icons/IconUser'

import * as S from './styles'

interface CellTailProps {
  event: BasicEvent
  swipeToDelete?: boolean
  isDeleting: boolean
  setDeleteConfirmationOpen: Dispatch<SetStateAction<boolean>>
}

const CellTail = ({ event, swipeToDelete, isDeleting, setDeleteConfirmationOpen }: CellTailProps) => {
  const intl = useIntl()
  const { feedLocked, userCount, allowedActions } = event
  const { guardEntity } = usePermissions()
  const canDelete = guardEntity(allowedActions || [], [PermissionAction.Delete])

  return (
    <Flex style={{ opacity: feedLocked ? '.5' : 1 }}>
      <Flex
        w="55px"
        pl="sm"
        pr={swipeToDelete || !canDelete ? 'sm' : 0}
        direction="column"
        justify="center"
        align="center"
        title={intl.formatMessage({ id: 'editor_event_followers' }, { followerCount: userCount })}
      >
        {userCount !== undefined && (
          <>
            <IconUser width={16} height={16} fill="#384df5" />

            <Text w="100%" fontSize="micro" color="onSurface.nLv3" fontWeight="medium" mt="xxs" align="center" ellipsis>
              {formatNumber(userCount)}
            </Text>
          </>
        )}
      </Flex>

      {!swipeToDelete && canDelete && (
        <Flex minW="50px" justify="center" align="center">
          {!isDeleting ? (
            <S.DeleteButton
              onClick={e => {
                e.preventDefault()
                setDeleteConfirmationOpen(true)
              }}
              onAuxClick={e => {
                e.preventDefault() // prevent middle mouse click from opening the event in new tab
              }}
            >
              <IconDelete w={24} h={24} fill="onSurface.nLv1" />
            </S.DeleteButton>
          ) : (
            <Spinner width="18px" color="#222226" />
          )}
        </Flex>
      )}
    </Flex>
  )
}

export default CellTail
