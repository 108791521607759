import { Box, useMediaContext } from '@sofascore/ui'
import { NOT_STARTED_CODE, OVERTIME as OVERTIME_CODE, PENALTIES_CODE } from 'entities/Status'
import { FormattedMessage, useIntl } from 'react-intl'
import { EventActionType, getUpdateEventBody, useEventDispatch, useEventState } from 'entities/Event'
import { useRef } from 'react'
import { SaveAllChanges } from 'widgets/SaveAllChanges'
import { useErrorHandler } from 'shared/hooks'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'
import { FirebaseEventName } from 'shared/lib/firebase/model'
import { mutate } from 'swr'
import { useToast } from 'shared/lib'
import { PERIOD1, PERIOD2, PERIOD3, PERIOD4, OVERTIME, PENALTIES, periodNamesSportMap } from 'entities/Score'
import { CompetitionType } from 'entities/Tournament'
import { SetAggregateWinner } from 'features/SetAggregateWinner'
import { GeneralEditMatchStatus } from 'widgets/GeneralEditMatchStatus'
import { GeneralEditMatchScoreHeader } from 'widgets/GeneralEditMatchScoreHeader'
import { SetPeriodScoreFRO } from 'features/SetPeriodScore'
import { SportName } from 'entities/Sport'

import { updateEvent } from 'api'
import { eventDetails } from 'api/routes'

import { waterPoloStatusCodes } from '../../config'

export const WaterPoloEditMatch = () => {
  const intl = useIntl()
  const containerRef = useRef<HTMLDivElement>(null)
  const eventDispatch = useEventDispatch()
  const handleError = useErrorHandler()

  const { isMobile } = useMediaContext()
  const { event, hasChanges } = useEventState()
  const { status, tournament, homeScore, awayScore, time } = event
  const { enqueueToast } = useToast()

  const hasPenaltiesData = homeScore.penalties || awayScore.penalties || time?.penalties
  const hasOvertimeData = homeScore.overtime || awayScore.overtime || time?.overtime

  const isNotStarted = status.code === NOT_STARTED_CODE
  const isOvertime = status.code === OVERTIME_CODE
  const isPenalties = status.code === PENALTIES_CODE
  const isCup = tournament.competitionType === CompetitionType.Cup

  const showPenalties = isPenalties || hasPenaltiesData
  const showOvertime = isOvertime || hasOvertimeData

  const onSaveAllChanges = async () => {
    try {
      logFirebaseEvent(FirebaseEventName.EditResultPeriod, { id: event.id })

      const updateEventBody = getUpdateEventBody(event)
      if (updateEventBody) {
        await updateEvent(event.id, updateEventBody)

        mutate(eventDetails(event.id))

        enqueueToast(intl.formatMessage({ id: 'alert_changes_saved' }), { variant: 'success' })
      }
    } catch (err) {
      handleError(err)
    }
  }

  const handleRecalculateCurrentScore = () => {
    eventDispatch({ type: EventActionType.RECALCULATE_CURRENT_SCORES })
  }

  const handleResetPeriodScores = () => {
    eventDispatch({ type: EventActionType.RESET_PERIOD_SCORES })
    eventDispatch({ type: EventActionType.RESET_PENALTIES })
  }

  return (
    <Box ref={containerRef}>
      {/* Status & AGGREGATE Winner */}
      <GeneralEditMatchStatus availableStatusCodes={waterPoloStatusCodes} />

      {/* HEADER */}
      {!isNotStarted && (
        <>
          <GeneralEditMatchScoreHeader onScoreChange={handleResetPeriodScores} />

          {/* SCORE BY SETS */}
          <SetPeriodScoreFRO
            periodKey={PERIOD1}
            periodName={<FormattedMessage id={periodNamesSportMap[PERIOD1][SportName.Waterpolo]} />}
            onScoreChange={handleRecalculateCurrentScore}
          />
          <SetPeriodScoreFRO
            periodKey={PERIOD2}
            periodName={<FormattedMessage id={periodNamesSportMap[PERIOD2][SportName.Waterpolo]} />}
            onScoreChange={handleRecalculateCurrentScore}
          />
          <SetPeriodScoreFRO
            periodKey={PERIOD3}
            periodName={<FormattedMessage id={periodNamesSportMap[PERIOD3][SportName.Waterpolo]} />}
            onScoreChange={handleRecalculateCurrentScore}
          />
          <SetPeriodScoreFRO
            periodKey={PERIOD4}
            periodName={<FormattedMessage id={periodNamesSportMap[PERIOD4][SportName.Waterpolo]} />}
            onScoreChange={handleRecalculateCurrentScore}
          />

          {/** Overtime */}
          {showOvertime && (
            <SetPeriodScoreFRO
              periodKey={OVERTIME}
              periodName={<FormattedMessage id={periodNamesSportMap[OVERTIME][SportName.Waterpolo]} />}
              onScoreChange={handleRecalculateCurrentScore}
            />
          )}

          {/** Penalties */}
          {showPenalties && (
            <SetPeriodScoreFRO
              periodKey={PENALTIES}
              periodName={<FormattedMessage id={periodNamesSportMap[PENALTIES][SportName.Waterpolo]} />}
              onScoreChange={handleRecalculateCurrentScore}
            />
          )}

          {/* AGGREGATE WINNER */}
          {isMobile && isCup && <SetAggregateWinner />}
        </>
      )}

      {hasChanges && (
        <>
          <Box h={100} />
          <SaveAllChanges onSubmit={onSaveAllChanges} parentRef={containerRef} />
        </>
      )}
    </Box>
  )
}
