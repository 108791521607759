import { IntlShape } from 'react-intl'

import { Standings } from './interface'

export const ALL_FILTER_VALUE = 'all'

export const getDefaultFilterOption = (intl: IntlShape) => {
  return {
    value: ALL_FILTER_VALUE,
    label: intl.formatMessage({ id: ALL_FILTER_VALUE }),
  }
}

export const getStandingsOptions = (tournamentStandings: Standings[], intl: IntlShape) => {
  const options = tournamentStandings.map(standings => {
    return { value: standings.name, label: standings.name }
  })
  return [{ value: ALL_FILTER_VALUE, label: intl.formatMessage({ id: ALL_FILTER_VALUE }) }, ...options]
}
