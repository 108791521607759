import styled, { css } from 'styled-components'
import { Box, Flex } from '@sofascore/ui'
import { NavLink } from 'react-router-dom'

export const PageHeader = styled(Flex)`
  position: fixed;
  padding: 24px 48px;
  background-color: ${p => p.theme.colors.surface.s1};
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  z-index: 1200;

  ${p => p.theme.breakpoints.query.mdMax`
    padding: 16px;
    justify-content: space-between;
    `}
`

export const Logo = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const LogoNavLink = styled(NavLink)`
  height: 24px;

  ${p => p.theme.breakpoints.query.mdMax`
    height: 16px;

    & * {
      height: 16px;
      width: auto;
    }
    `}
`

export const Links = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;

  ${p => p.theme.breakpoints.query.mdMax`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    flex-direction: column;
    padding: 16px 32px;
    z-index: 1250;
    background-color: ${p.theme.colors.surface.s1};
    `}
`

export const MainNav = styled(Box)`
  display: flex;
  margin: 0 auto;
  padding: 0 16px;

  ${p => p.theme.breakpoints.query.mdMax`
    flex-direction: column;
    margin: 48px 0 0 0;
    padding: 0;
    `}
`

const baseLink = css`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  color: ${p => p.theme.colors.onSurface.nLv1};
  transition: all 0.2s;

  svg {
    fill: ${p => p.theme.colors.onSurface.nLv1};
    transition: all 0.2s;

    path {
      fill-opacity: 1;
    }
  }

  &:hover,
  &.active {
    color: ${p => p.theme.colors.primary.default};

    svg {
      fill: ${p => p.theme.colors.primary.default};
    }
  }
`

export const Link = styled(NavLink)`
  ${baseLink}
  margin-right: 32px;

  &:last-child {
    margin-right: 0;
  }

  ${p => p.theme.breakpoints.query.mdMax`
    width: 100%;
    height: 56px;
    justify-content: flex-start;
    `}
`

export const Actions = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;

  ${p => p.theme.breakpoints.query.mdMax`
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px 0 0 0;
    `}
`

export const SignOut = styled.button`
  ${baseLink}
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;

  ${p => p.theme.breakpoints.query.mdMax`
    width: 100%;
    height: 56px;
    justify-content: flex-start;
    margin-top: 150px;
    `}
`

export const Dot = styled.div`
  color: ${p => p.theme.colors.primary.default};
  font-size: 24;
  position: absolute;
  left: 16px;
  top: -6px;

  ${p => p.theme.breakpoints.query.mdMax`
    top: 10px;
    `}
`
