import { Box, Flex, Text } from '@sofascore/ui'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useEventState } from 'entities/Event'
import {
  useTeamPlayerOptions,
  PlayerOption,
  getDefaultOption,
  renderPlayerOption,
  renderSelectedPlayerOption,
} from 'entities/Player'

import { Button } from 'components/Button'
import { Select } from 'components/Select'

import { EditIncidentFormProps } from '../../model'

export const EditMiniFootballAccumulatedPenaltyForm = ({ onSubmit, onClose, incident }: EditIncidentFormProps) => {
  const { event } = useEventState()
  const intl = useIntl()
  const { id: eventId, homeTeam, awayTeam } = event
  const {
    id,
    isHome,
    player: initialPlayer,
    incidentType,
    incidentClass,
    homeScore,
    awayScore,
    time,
    addedTime,
  } = incident
  const teamId = isHome ? homeTeam.id : awayTeam.id

  const [isSaving, setIsSaving] = useState(false)
  const [scorer, setScorer] = useState<PlayerOption | undefined>(getDefaultOption(initialPlayer))

  const handleConfirm = async () => {
    onSubmit({
      eventId: eventId,
      incidentId: id as number,
      incidentData: {
        incidentType,
        incidentClass,
        isHome: isHome as boolean,
        time,
        addedTime,
        homeScore,
        awayScore,
        player: scorer?.value,
      },
    })
      .then(() => onClose())
      .finally(() => setIsSaving(false))
  }

  const [scorerOptions] = useTeamPlayerOptions(teamId, scorer)

  return (
    <Box px="sm" pb="xxl">
      <Box px="sm" pt="lg" pb="xs" br="sm" bg="surface.s2">
        <Flex mb="xl" justify="center">
          <Text font="display.small">
            <FormattedMessage id="select_scorer" />
          </Text>
        </Flex>

        <Select
          label={intl.formatMessage({ id: 'player' })}
          options={scorerOptions}
          helperText="Optional"
          onChange={setScorer}
          value={scorer}
          maxVisibleOptions={6}
          w="100%"
          selectBackgroundColor="surface.s1"
          renderOption={renderPlayerOption}
          renderSelectedOption={renderSelectedPlayerOption}
        />
      </Box>

      <Flex gapX="lg" px="lg" mt="xxl">
        <Button variant="outlined" fullWidth onClick={onClose}>
          <FormattedMessage id="cancel" />
        </Button>
        <Button variant="contained" fullWidth onClick={handleConfirm} isLoading={isSaving}>
          <FormattedMessage id="confirm" />
        </Button>
      </Flex>
    </Box>
  )
}
