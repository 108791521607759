interface RenderButtonProps {
  element: HTMLDivElement | null // Element that displays as button or null if there is no button.
  google: typeof window.google // Google object with necessary methods.
  clientId: string // Google app client id.
  renderOptions: google.accounts.id.GsiButtonConfiguration // Button UI customization attributes.
  callback: (res: google.accounts.id.CredentialResponse) => void // Callback function that is being called after auth.
  disableOneTap: boolean // If not set, One Tap dialog is rendered if appropriate (Google internal logic).
}

/**
 *
 * Initializes google authentification process.
 * Renders Sign In With Google button if element prop is passed.
 * Renders Google One Tap dialog if not explicitly disabled via prop.
 */
export const renderButton = ({
  element,
  google,
  clientId,
  renderOptions,
  callback,
  disableOneTap,
}: RenderButtonProps) => {
  if (!element && disableOneTap) {
    return
  }
  try {
    google.accounts.id.initialize({ client_id: clientId, callback })
    if (element) {
      google.accounts.id.renderButton(element, renderOptions)
    }
    if (!disableOneTap) {
      google.accounts.id.prompt()
    }
  } catch (error) {
    console.error('Failed to render Sign In With Google UI.', error)
  }
}

/**
 *
 * @param token - JWT token
 * @returns - parsed JWT token as JavaScript object.
 */
export function parseJwt(token: string) {
  const base64 = token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}
