import { FormattedMessage, IntlShape } from 'react-intl'

interface CategoryTranslate {
  slug: string
  name: string
}

const amateurSubstringStartIndex = (categorySlug: string) => categorySlug.indexOf('-amateur')

/**
 * Renders translated category name. If translation doesn't exist, uses name as default.
 *
 * NOTE: `translateCategory` can be used here instead of `FormattedMessage`,
 *  but it isn't to get `FormattedMessage` behaviour (can return string, be used as render prop, ...)
 */
export const FormattedCategoryName = ({ slug, name }: CategoryTranslate) => {
  const amateurIndex = amateurSubstringStartIndex(slug)

  if (amateurIndex !== -1) {
    const country = slug.substring(0, amateurIndex)

    return (
      <FormattedMessage
        id="amateur_category"
        defaultMessage={name}
        values={{ countryName: <FormattedMessage id={country} /> }}
      />
    )
  }

  return <FormattedMessage id={slug} defaultMessage={name} />
}

/**
 * Returns translated category name. If translation doesn't exist, uses name as default.
 */
export function formatCategoryName({ slug, name, intl }: CategoryTranslate & { intl: IntlShape }) {
  const amateurIndex = amateurSubstringStartIndex(slug)

  if (amateurIndex !== -1) {
    const country = slug.substring(0, amateurIndex)

    return intl.formatMessage(
      { id: 'amateur_category', defaultMessage: name },
      { countryName: intl.formatMessage({ id: country }) },
    )
  }

  return intl.formatMessage({ id: slug, defaultMessage: name })
}
