import { Flex } from '@sofascore/ui'
import { PropsWithChildren } from 'react'
import styled from 'styled-components'

const StyledIncidentTail = styled(Flex)`
  :empty {
    display: none;
  }
`

export const IncidentTail = ({ children }: PropsWithChildren) => {
  return (
    <Flex order="4">
      <StyledIncidentTail direction="row" bg="surface.s2" br="xs" p="sm">
        {children}
      </StyledIncidentTail>
    </Flex>
  )
}
