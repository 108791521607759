import React from 'react'
import { connect } from 'react-redux'
import { Typography, Theme, Accordion, AccordionSummary, AccordionDetails, useTheme } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useMediaContext } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'
import { SportName } from 'entities/Sport'

import { BLUE } from 'styles/color'
import { RootState } from 'store/store'
import { useStyles } from 'modules/Matches/styles'
import { useMyCompetitionsState } from 'modules/MyCompetitions/providers/MyCompetitionsProvider'

interface Props {
  tournamentName: string | null
  homeTeamName: string | null
  awayTeamName: string | null
}
/**
 * Panel for displaying event's tournament, home team, away team and round (optional)
 */
const BasicInfoPanel = (props: Props) => {
  const { tournamentName, homeTeamName, awayTeamName } = props

  const { uniqueTournament } = useMyCompetitionsState()

  const theme: Theme = useTheme()
  const { isMobile } = useMediaContext()
  const classes = useStyles()

  const sport = uniqueTournament?.category.sport.slug
  const isTennis = sport === SportName.Tennis

  return (
    // We are on event details page -> Tournament, Home Team, Away Team and start time isn't editable
    tournamentName && homeTeamName && awayTeamName ? (
      <>
        <Accordion className={classes.expansionPanel}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: theme.palette.secondary.main }} />}
            className={classes.expansionPanelSummary}
          >
            <Typography className={classes.heading}>
              <FormattedMessage id="basicInfo" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.expansionPanelDetailsRoot }}>
            <div className={classes.container} style={{ flexWrap: 'wrap', justifyContent: 'flex-start' }}>
              <div style={{ display: 'flex', paddingRight: isMobile ? 0 : theme.spacing(6) }}>
                <Typography variant="subtitle2" style={{ color: BLUE.incident }}>
                  <FormattedMessage id="tournament" />
                  :&nbsp;&nbsp;
                </Typography>
                <Typography variant="subtitle2">{tournamentName}</Typography>
              </div>
              <div style={{ display: 'flex', paddingRight: isMobile ? 0 : theme.spacing(6) }}>
                <Typography variant="subtitle2" style={{ color: BLUE.incident }}>
                  <FormattedMessage id={isTennis ? 'player_home' : 'homeTeam'} />
                  :&nbsp;&nbsp;
                </Typography>
                <Typography variant="subtitle2">{homeTeamName}</Typography>
              </div>
              <div style={{ display: 'flex', paddingRight: isMobile ? 0 : theme.spacing(6) }}>
                <Typography variant="subtitle2" style={{ color: BLUE.incident }}>
                  <FormattedMessage id={isTennis ? 'player_away' : 'awayTeam'} />
                  :&nbsp;&nbsp;
                </Typography>
                <Typography variant="subtitle2">{awayTeamName}</Typography>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </>
    ) : (
      <></>
    )
  )
}

const areStatesEqual = (nextState: RootState, prevState: RootState) => {
  if (!nextState.event || !prevState.event) {
    return false
  }
  return nextState.event.id === prevState.event.id
}

const mapStateToProps = ({ event }: RootState): Props => ({
  tournamentName: event ? event.tournament.name : null,
  homeTeamName: event ? event.homeTeam.name : null,
  awayTeamName: event ? event.awayTeam.name : null,
})

export default connect<Props, null, unknown, RootState>(mapStateToProps, null, null, { areStatesEqual })(BasicInfoPanel)
