import { Spinner } from 'components/Spinner'

import * as S from './styles'
import { ButtonProps } from './interface'

const Button = (baseProps: ButtonProps) => {
  const { children, style, isLoading, ...props } = baseProps

  return (
    <S.Button {...props} disabled={props.disabled || isLoading} style={style}>
      <S.ButtonContent>{isLoading ? <Spinner width="18px" /> : children}</S.ButtonContent>
    </S.Button>
  )
}

export default Button
