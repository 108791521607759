import { Flex, Text } from '@sofascore/ui'

const Error = ({ title }: { title: string }) => {
  return (
    <Flex justify="center" mt="200">
      <Text font="display.extraLarge">{title}</Text>
    </Flex>
  )
}

export default Error
