import { useState } from 'react'
import { Box, Flex, RadioButton, Text, Button as SofaUiButton } from '@sofascore/ui'
import IconArrowDropdown from '@sofascore/ui/dist/modules/Icons/IconArrowDropdown'
import { FormattedMessage } from 'react-intl'
import IconInfo from '@sofascore/ui/dist/modules/Icons/IconInfo'
import { INTERRUPTED_CODE, usePostMatchStatusOptions } from 'entities/Status'
import { CompetitionType } from 'entities/Tournament'
import { useEventState } from 'entities/Event'
import { AggregateWinnerPicker } from 'features/EditPeriod'

import { secondaryColor, textPrimary } from 'styles/color'
import ResponsivePopUp from 'components/ResponsivePopUp'
import { Tooltip } from 'components/Tooltip'
import { Button } from 'components/Button'
import { Option } from 'components/DropdownMenu/interface'

import * as S from './MatchEndedStatusPicker.styles'

interface MatchStatusPickerProps {
  onSave: (matchStatusCode: number, aggregateWinnerCode: number | null) => void
  disabled?: boolean
}

export const MatchEndedStatusPicker = ({ onSave, disabled }: MatchStatusPickerProps) => {
  const { event } = useEventState()

  const { homeTeam, awayTeam, status, tournament } = event
  const isCup = tournament?.competitionType === CompetitionType.Cup

  const [leaguePostMatchStatusOptions, cupPostMatchStatusOptions] = usePostMatchStatusOptions()
  let postMatchStatusOptions = leaguePostMatchStatusOptions

  if (isCup) {
    postMatchStatusOptions = cupPostMatchStatusOptions
  }

  const previousStatus = postMatchStatusOptions.find(o => o.value === status.code) || postMatchStatusOptions[0]
  const [matchStatusCode, setMatchStatusCode] = useState<Option>(previousStatus)

  const [isPickerOpened, setIsPickerOpened] = useState(false)
  const [tempWinnerCode, setTempWinnerCode] = useState<number | null>(null)

  const toggleOpen = () => setIsPickerOpened(prev => !prev)

  return (
    <>
      <S.Select position="relative" onClick={toggleOpen}>
        <S.SelectFieldWrapper isDisabled={disabled}>
          <Flex align="center" minW={0} grow={1} pt={18} wrap="wrap" mr="md">
            <Box grow={1} minW={0} p={0} overflow="hidden" style={{ whiteSpace: 'nowrap' }}>
              {previousStatus.label}
            </Box>
          </Flex>

          <SofaUiButton variant="unshielded" p={0} border="none" tabIndex={-1}>
            <IconArrowDropdown
              style={{ transform: isPickerOpened ? 'rotate(180deg)' : undefined }}
              fill={secondaryColor}
              onClick={e => {
                e.stopPropagation()
                toggleOpen()
              }}
            />
          </SofaUiButton>

          <S.Label>
            {' '}
            <FormattedMessage id="match_status" />
          </S.Label>
        </S.SelectFieldWrapper>
      </S.Select>

      <ResponsivePopUp
        isOpen={isPickerOpened}
        onClose={toggleOpen}
        heading={
          <Text font="display.large">
            <FormattedMessage id="end_of_the_match_details" />
          </Text>
        }
      >
        <Box minH={388} mb={60}>
          <Box backgroundColor="surface.s2" mt="lg" mx="md" br="sm" p="lg" mb="lg">
            <Text font="display.small" align="center" as="div">
              <FormattedMessage id="select_match_status" />
            </Text>

            <Flex direction="column" align="flex-start" mt="lg">
              {postMatchStatusOptions.map(option => (
                <Flex key={option.value} justify="space-between" w="100%" align="flex-end">
                  <RadioButton
                    id={option.label}
                    name="matchStatusCode"
                    label={option.label}
                    value={option.value.toString()}
                    checked={matchStatusCode.value === option.value}
                    onChange={e =>
                      setMatchStatusCode(postMatchStatusOptions.find(o => o.value.toString() === e.target.value)!)
                    }
                    gap="md"
                    mb="md"
                  />
                  {option.value === INTERRUPTED_CODE && (
                    <Tooltip
                      placement="bottom"
                      alignment="left"
                      w={200}
                      p="md"
                      label={
                        <Text>
                          <FormattedMessage id="match_interrupted_info_text" />
                        </Text>
                      }
                      element={<IconInfo fill={textPrimary} fillOpacity={0.4} pr={6} cursor="pointer" />}
                    />
                  )}
                </Flex>
              ))}
            </Flex>
          </Box>

          {isCup && (
            <Box backgroundColor="surface.s2" mx="md" br="sm" pt="lg" pb="xs" mb={60}>
              <Text font="display.small" align="center" as="div" mb="lg">
                <FormattedMessage id="select_aggregate_winner" />
              </Text>

              <AggregateWinnerPicker
                homeTeam={homeTeam}
                awayTeam={awayTeam}
                winnerCode={tempWinnerCode}
                onChangeWinnerCode={newCode => setTempWinnerCode(newCode)}
              />
            </Box>
          )}
        </Box>

        <Flex gapX="lg" px="lg" mb="xl" mx="lg">
          <Button variant="outlined" fullWidth onClick={() => setIsPickerOpened(false)}>
            <FormattedMessage id="cancel" />
          </Button>
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              onSave(+matchStatusCode.value, tempWinnerCode)
              setIsPickerOpened(false)
            }}
          >
            <FormattedMessage id="confirm" />
          </Button>
        </Flex>
      </ResponsivePopUp>
    </>
  )
}
