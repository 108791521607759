import { Box, Button, Theme, Typography, withStyles } from '@material-ui/core'

export const DialogTitleContainer = withStyles((theme: Theme) => ({
  root: {
    padding: '0 20px',
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
  },
}))(Box)

export const DialogSubtitle = withStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(3),
    padding: `0 ${theme.spacing(1)}px`,
    fontSize: 16,
  },
}))(Typography)

export const DialogConfirmButton = withStyles((theme: Theme) => ({
  root: {
    width: 132,
    height: 48,
    marginRight: theme.spacing(3),
    fontSize: 14,
  },
}))(Button)

export const DialogRejectButton = withStyles(() => ({
  root: {
    width: 132,
    height: 48,
  },
}))(Button)
