import { FormattedMessage } from 'react-intl'
import { Flex, Text } from '@sofascore/ui'

import { Button } from 'components/Button'
import ResponsivePopUp from 'components/ResponsivePopUp'

interface DialogProps {
  isOpen: boolean
  onClose: () => void
  handleConfirm?: () => void
  isDeleting?: boolean
}

const ConfirmDeletionDialog = ({ isOpen, onClose, handleConfirm, isDeleting }: DialogProps) => {
  return (
    <ResponsivePopUp
      isOpen={isOpen}
      onClose={onClose}
      heading={
        <Text fontSize="large" fontWeight="bold">
          <FormattedMessage id="delete" /> <FormattedMessage id="match" />
        </Text>
      }
    >
      <Flex justify="center" pt="xxl">
        <Text fontSize="regular">
          <FormattedMessage id="match_delete_confirmation" />
        </Text>
      </Flex>

      <Flex justify="center" gapX={16} px={40} pt="xxl" pb="xl">
        <Button variant="outlined" fullWidth onClick={onClose}>
          <FormattedMessage id="cancel" />
        </Button>

        <Button variant="contained" fullWidth onClick={handleConfirm} isLoading={isDeleting}>
          <FormattedMessage id="delete" />
        </Button>
      </Flex>
    </ResponsivePopUp>
  )
}

export default ConfirmDeletionDialog
