import * as React from 'react'
import { Avatar, createStyles, makeStyles } from '@material-ui/core'
import { Stylable } from 'shared/model'

const useStyles = makeStyles(() =>
  createStyles({
    placeholderImg: {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
    },
  }),
)

interface ImageProps extends Stylable {
  src: string
  alt?: string
  fallbackImage?: string
}

const DEFAULT_FALLBACK_IMAGE = '/images/placeholders/uniqueTournament.png'

const Image = ({
  src,
  style,
  className,
  alt,
  fallbackImage = DEFAULT_FALLBACK_IMAGE,
  ...props
}: ImageProps & React.HTMLAttributes<HTMLElement>) => {
  const classes = useStyles()

  return (
    <Avatar alt={alt} src={src} style={style} className={className} {...props}>
      {/* If there is an error loading the avatar image, the component falls back to an alternative in the 
      following order: 
        1.the provided children, 
        2.the first letter of the alt text 
        3.generic avatar icon */}
      <img alt={alt} src={fallbackImage} className={classes.placeholderImg} />
    </Avatar>
  )
}

export default Image
