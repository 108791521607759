import { Dropdown, Flex, Text, Image, useMediaContext, ZIndex } from '@sofascore/ui'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import IconArrowBack from '@sofascore/ui/dist/modules/Icons/IconArrowBack'
import { getCompetitionCountryFlag, UniqueTournamentImage } from 'entities/UniqueTournament'
import { CompetitionType } from 'entities/Tournament'
import { PermissionScope } from 'entities/Permission'
import useSWR from 'swr'
import { useState } from 'react'
import { PermissionGuard } from 'shared/ui'
import { FormattedCategoryName, formatCategoryName } from 'entities/Category/utils'

import { getUpdatedURLPath } from 'utils/url'
import { tournamentCupTree } from 'api/routes'
import { useMyCompetitionsState } from 'modules/MyCompetitions/providers/MyCompetitionsProvider'
import { Option } from 'components/DropdownMenu/interface'

import * as S from './styles'

const MyCompetitionHeader = () => {
  const { isMobile } = useMediaContext()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { competitionId, seasonId } = useParams()
  const { competitionsWithSeasons, uniqueTournament, tournaments } = useMyCompetitionsState()
  const intl = useIntl()

  const seasonOptions: Option[] = []

  const [hasCupTree, setHasCupTree] = useState(false)

  const cupTournament = tournaments.find(tournament => tournament.competitionType === CompetitionType.Cup)

  useSWR(cupTournament && seasonId ? tournamentCupTree(cupTournament.id, +seasonId) : null, {
    onSuccess: () => setHasCupTree(true),
    onError: () => setHasCupTree(false),
    shouldRetryOnError: false,
  })

  competitionsWithSeasons?.map(competition => {
    if (competition.uniqueTournament.id === Number(competitionId)) {
      seasonOptions.push({
        label: competition.season?.year,
        value: competition.season?.id,
      })
    }
  })

  const handleSeasonChange = (option: Option) => {
    const newPath = getUpdatedURLPath(pathname, 'season', option.value.toString())
    //  on season change switch to matches tab
    const parts = newPath.split('/')
    parts[parts.length - 1] = 'matches'
    const newUrl = parts.join('/')
    navigate(newUrl)
  }

  const hasStandings = !!tournaments.find(tournament => tournament.competitionType === CompetitionType.League)
  const hasCupTournament = !!cupTournament

  const category = uniqueTournament?.category

  return (
    <S.MyCompetitionHeader
      forwardedAs="header"
      mb={[0, 'sm']}
      br={[0, 'sm']}
      bg="surface.s1"
      zIndex={ZIndex.Header}
      elevation={2}
    >
      <Flex w="100%" h={[48, 'auto']} px="lg" pt={['sm', 'lg']} pb={[0, 'sm']} align="center">
        <Flex display={['flex', 'none']} mr="sm" align="center">
          <NavLink
            to="/my-competitions"
            style={{ width: 30, height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <IconArrowBack />
          </NavLink>
        </Flex>

        {uniqueTournament && (
          <>
            <UniqueTournamentImage
              id={uniqueTournament.id}
              w={[32, 72]}
              h={[32, 72]}
              name={uniqueTournament.name}
              mr="lg"
            />

            <Flex direction="column" justify="space-between">
              <Text font={['assistive.default', 'display.extraLarge']}>{uniqueTournament.name}</Text>

              <Flex mt={[0, 'sm']}>
                <Flex display={['none', 'flex']} mr="sm" align="center">
                  <Image
                    src={getCompetitionCountryFlag(uniqueTournament.category)}
                    w="24"
                    h="24"
                    mr="sm"
                    alt={category ? formatCategoryName({ slug: category.slug, name: category.name, intl }) : ''}
                  />
                  {category && (
                    <Text font="display.small">
                      <FormattedCategoryName slug={category.slug} name={category.name} />
                    </Text>
                  )}
                </Flex>
                <Dropdown
                  items={seasonOptions}
                  renderItem={item => item?.label || ''}
                  selectedItem={seasonOptions.find(option => option.value === Number(seasonId))}
                  onSelectedItemChange={item => handleSeasonChange(item.selectedItem as Option)}
                  buttonStyle={isMobile ? { padding: 0, backgroundColor: 'transparent' } : { fontSize: '12px' }}
                />
              </Flex>
            </Flex>
          </>
        )}
      </Flex>

      <S.ScrollingTabs w="100%" br={[0, 'sm']} overflowY="hidden">
        <PermissionGuard entityScopes={[PermissionScope.Event]}>
          <S.Link to={`/my-competitions/${competitionId}/season/${seasonId}/matches`}>
            <Text font="body.medium" color="inherit" whiteSpace="nowrap">
              <FormattedMessage id="matches" />
            </Text>
          </S.Link>
        </PermissionGuard>

        <PermissionGuard entityScopes={[PermissionScope.Team]}>
          <S.Link to={`/my-competitions/${competitionId}/season/${seasonId}/teams`}>
            <Text font="body.medium" color="inherit" whiteSpace="nowrap">
              <FormattedMessage id="teams" />
            </Text>
          </S.Link>
        </PermissionGuard>
        <PermissionGuard entityScopes={[PermissionScope.Standings]}>
          {hasStandings && (
            <S.Link to={`/my-competitions/${competitionId}/season/${seasonId}/standings`}>
              <Text font="body.medium" color="inherit" whiteSpace="nowrap">
                <FormattedMessage id="standings" />
              </Text>
            </S.Link>
          )}
        </PermissionGuard>
        <PermissionGuard entityScopes={[PermissionScope.Standings]}>
          {hasCupTournament && hasCupTree && (
            <S.Link to={`/my-competitions/${competitionId}/season/${seasonId}/knockout`}>
              <Text font="body.medium" color="inherit" whiteSpace="nowrap">
                <FormattedMessage id="knockout" />
              </Text>
            </S.Link>
          )}
        </PermissionGuard>
        <S.Link end to={`/my-competitions/${competitionId}/season/${seasonId}/info`}>
          <Text font="body.medium" color="inherit" whiteSpace="nowrap">
            <FormattedMessage id="info" />
          </Text>
        </S.Link>
      </S.ScrollingTabs>
    </S.MyCompetitionHeader>
  )
}

export default MyCompetitionHeader
