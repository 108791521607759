import { CreateIncidentData } from 'entities/Incident'
import { FormattedMessage, useIntl } from 'react-intl'
import { useState } from 'react'
import { Flex, Text } from '@sofascore/ui'
import IconPenaltyMissed from '@sofascore/ui/dist/modules/Icons/_IconPenaltyMissed'
import { useFirebaseWithEvent } from 'entities/Event/hooks'
import { FirebaseEventIncidentAction, FirebaseEventName, FirebaseEventType } from 'shared/lib/firebase/model'

import IncidentBox from 'components/IncidentBox'
import ResponsivePopUp from 'components/ResponsivePopUp'
import { RED } from 'styles/color'

import { AddMissedPenaltyForm } from '../AddMissedPenaltyForm'
import { additionalTimeDisplayLimit } from '../../config'

interface Props {
  onCreate: (arg0: CreateIncidentData) => Promise<void>
}

export const AddFootballMissedPenalty = ({ onCreate }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const intl = useIntl()
  const logFirebaseWithEvent = useFirebaseWithEvent()

  const responsivePopupOpen = () => {
    setIsOpen(true)
    logFirebaseWithEvent(
      FirebaseEventName.AddEntity,
      FirebaseEventType.Incident,
      FirebaseEventIncidentAction.InGamePenalty,
    )
  }
  const responsivePopupClose = (discarded?: boolean | undefined) => {
    setIsOpen(false)
    if (discarded)
      logFirebaseWithEvent(
        FirebaseEventName.DiscardEntity,
        FirebaseEventType.Incident,
        FirebaseEventIncidentAction.InGamePenalty,
      )
    else
      logFirebaseWithEvent(
        FirebaseEventName.SubmitEntity,
        FirebaseEventType.Incident,
        FirebaseEventIncidentAction.InGamePenalty,
      )
  }

  return (
    <>
      <IncidentBox
        label={intl.formatMessage({ id: 'missed_penalty' })}
        onClick={responsivePopupOpen}
        icon={<IconPenaltyMissed fill={RED.incident} />}
      />

      <ResponsivePopUp
        isOpen={isOpen}
        onClose={responsivePopupClose}
        heading={
          <Flex align="center">
            <IconPenaltyMissed fill={RED.incident} />
            <Text font="display.large" ml="sm">
              <FormattedMessage id="add_missed_penalty" />
            </Text>
          </Flex>
        }
      >
        <AddMissedPenaltyForm
          onClose={responsivePopupClose}
          onSubmit={onCreate}
          additionalTimeDisplayLimit={additionalTimeDisplayLimit}
        />
      </ResponsivePopUp>
    </>
  )
}
