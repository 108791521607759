import {
  LineupsAdditionalData,
  LineupsResponse,
  LineupsResponseMissingPlayer,
  LineupsResponsePlayer,
} from 'entities/Lineups'
import { MissingPlayerType } from 'entities/Player'
import { BasicTeam } from 'entities/Team'
import {
  DynamicLineupsEvent,
  DynamicLineupsMissingPlayer,
  DynamicLineupsPayload,
  DynamicLineupsPlayer,
  DynamicLineupsTeamColors,
  LineupsInitialState,
  LineupsState,
  MIN_PLAYERS,
} from 'features/AddLineups'

export const createFootballLineupsDto = (
  eventId: number,
  homeLineupPlayers: DynamicLineupsPlayer[],
  awayLineupPlayers: DynamicLineupsPlayer[],
  homeTeam: BasicTeam,
  awayTeam: BasicTeam,
  additionalData: LineupsAdditionalData | null,
): DynamicLineupsPayload => {
  const _event = {
    id: eventId,
    homeFormation: additionalData ? additionalData.homeFormation : null,
    awayFormation: additionalData ? additionalData.awayFormation : null,
    confirmedLineups: true,
    noMissingPlayersConfirmed: false,
  }

  const homeStarters = homeLineupPlayers.filter(lp => lp.position && !lp.substitute)
  const awayStarters = awayLineupPlayers.filter(lp => lp.position && !lp.substitute)

  const homeSubstitutes = homeLineupPlayers.filter(lp => lp.substitute)
  const awaySubstitutes = awayLineupPlayers.filter(lp => lp.substitute)

  const _eventPlayers = [...homeStarters, ...awayStarters, ...homeSubstitutes, ...awaySubstitutes].map(ep => {
    return {
      ...ep,
      position: additionalData ? ep.position : null,
      jerseyNumber: null,
    }
  })

  const _missingPlayers = [...homeLineupPlayers, ...awayLineupPlayers]
    .filter(lp => lp.missingReason !== null)
    .map<DynamicLineupsMissingPlayer>(mlp => {
      return {
        player: mlp.player,
        team: mlp.playerTeam === 1 ? homeTeam : awayTeam,
        reason: mlp.missingReason!,
        type: MissingPlayerType.Missing,
      }
    })

  const _teamColors = additionalData
    ? {
        homeTeamColorMain: additionalData.homeKit,
        awayTeamColorMain: additionalData.awayKit,
        homeTeamGKColorMain: additionalData.homeGoalkeeperKit,
        awayTeamGKColorMain: additionalData.awayGoalkeeperKit,
      }
    : null

  return {
    event: _event as DynamicLineupsEvent,
    eventPlayers: _eventPlayers,
    missingPlayers: _missingPlayers,
    homeTeamColor: 'event' as const,
    awayTeamColor: 'event' as const,
    teamColors: _teamColors as DynamicLineupsTeamColors,
  }
}

export const responseToInitialState = (data: LineupsResponse): Partial<LineupsInitialState> => {
  const allHomePlayers = [...data.home.players, ...(data.home.missingPlayers || [])]
  const allAwayPlayers = [...data.away.players, ...(data.away.missingPlayers || [])]

  const toDynamicLineupsPlayer = (
    p: LineupsResponsePlayer | LineupsResponseMissingPlayer,
    i: number,
    isHomePlayer: boolean,
  ): DynamicLineupsPlayer => {
    return {
      ...p,
      position: ('reason' in p && p.reason !== undefined) || ('substitute' in p && p.substitute) ? null : i + 1,
      substitute: 'substitute' in p ? p.substitute : false,
      captain: 'captain' in p ? p.captain : false,
      playerTeam: isHomePlayer ? 1 : 2,
      missingReason: 'reason' in p && p.reason !== undefined ? p.reason : null,
      jerseyNumber: 'jerseyNumber' in p ? p.jerseyNumber : null,
    }
  }

  return {
    homeLineupPlayers: allHomePlayers.map<DynamicLineupsPlayer>((p, i) => toDynamicLineupsPlayer(p, i, true)),
    awayLineupPlayers: allAwayPlayers.map<DynamicLineupsPlayer>((p, i) => toDynamicLineupsPlayer(p, i, false)),
    homeKit: `#${data.home.playerColor.primary?.toUpperCase()}`,
    awayKit: `#${data.away.playerColor.primary?.toUpperCase()}`,
    homeGoalkeeperKit: `#${data.home.goalkeeperColor.primary?.toUpperCase()}`,
    awayGoalkeeperKit: `#${data.away.goalkeeperColor.primary?.toUpperCase()}`,
    homeFormation: data.home.formation,
    awayFormation: data.away.formation,
  }
}

export const isListLineupValid = (state: LineupsState) => {
  const homeStarters = state.homeLineupPlayers.filter(lp => lp.position && !lp.substitute)
  const awayStarters = state.awayLineupPlayers.filter(lp => lp.position && !lp.substitute)

  return (
    ((homeStarters.length >= MIN_PLAYERS.PLAYER_LIST.TO_CREATE &&
      awayStarters.length >= MIN_PLAYERS.PLAYER_LIST.TO_CREATE) ||
      (homeStarters.length === 0 && awayStarters.length === 0)) &&
    state.hasChanges
  )
}

export const isPitchLineupValid = (state: LineupsState) => {
  const homeStarters = state.homeLineupPlayers.filter(lp => lp.position && !lp.substitute)
  const awayStarters = state.awayLineupPlayers.filter(lp => lp.position && !lp.substitute)

  return (
    ((homeStarters.length >= MIN_PLAYERS.PITCH.TO_CREATE && awayStarters.length >= MIN_PLAYERS.PITCH.TO_CREATE) ||
      (homeStarters.length === 0 && awayStarters.length === 0)) &&
    state.hasChanges
  )
}
