import { Table } from '@sofascore/ui'
import { SportName } from 'entities/Sport'

import { COLUMNS_BY_SPORT } from 'modules/StandingsRedesign/config'

import * as S from './styles'

export default function AdaptiveTableHead({ sport, isEditMode }: { sport: SportName; isEditMode: boolean }) {
  return (
    <Table.Head>
      <Table.Row bg="surface.s1">
        {COLUMNS_BY_SPORT[sport].map(({ shortKey, textAlign }) => {
          return (
            <S.StyledHeadCell key={shortKey} textAlign={textAlign}>
              {shortKey}
            </S.StyledHeadCell>
          )
        })}

        {isEditMode && <Table.Cell style={{ border: 'none' }} />}
      </Table.Row>
    </Table.Head>
  )
}
