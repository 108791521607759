import { PlayersResponseEditor } from 'entities/Player'
import useSWR from 'swr'
import { useEffect, useState } from 'react'

import { teamPlayersEditor, teamPlayersSuggestions } from 'api/routes'
import { EntityFilter } from 'modules/MyCompetitions/MyCompetitionTeams/model'
import { isNotFoundError } from 'utils'

import { AllPlayers, PlayerSuggestionsResponse } from './interface'
import { mapPlayerSuggestions, mapRemovePlayerSuggestions } from './utils'

const initialAllPlayers: AllPlayers = {
  approved: [],
  pending: [],
  declined: [],
}

export const useAllPlayers = (teamId?: string) => {
  const [allPlayers, setAllPlayers] = useState(initialAllPlayers)
  const [isLoading, setIsLoading] = useState(true)

  const {
    data: approvedPlayersResponse,
    isLoading: areApprovedPlayersLoading,
    error: approvedPlayersError,
    mutate: revalidateApprovedPlayers,
  } = useSWR<PlayersResponseEditor>(teamId ? teamPlayersEditor(+teamId) : null)

  const {
    data: pendingPlayersResponse,
    isLoading: arePendingPlayersLoading,
    error: pendingPlayersError,
    mutate: revalidatePendingPlayers,
  } = useSWR<PlayerSuggestionsResponse>(teamId ? teamPlayersSuggestions(+teamId, EntityFilter.PENDING) : null)

  const {
    data: declinedPlayersResponse,
    isLoading: areDeclinedPlayersLoading,
    error: declinedPlayersError,
  } = useSWR<PlayerSuggestionsResponse>(teamId ? teamPlayersSuggestions(+teamId, EntityFilter.DECLINED) : null)

  useEffect(() => {
    if (isNotFoundError(approvedPlayersError)) {
      setAllPlayers(prevState => {
        return {
          ...prevState,
          approved: [],
        }
      })
    } else if (approvedPlayersResponse) {
      setAllPlayers(prevState => {
        return {
          ...prevState,
          approved: approvedPlayersResponse.players,
        }
      })
    }
  }, [approvedPlayersResponse, approvedPlayersError])

  useEffect(() => {
    if (isNotFoundError(pendingPlayersError)) {
      setAllPlayers(prevState => {
        return {
          ...prevState,
          pending: [],
        }
      })
    } else if (pendingPlayersResponse) {
      setAllPlayers(prevState => {
        return {
          ...prevState,
          pending: mapPlayerSuggestions(pendingPlayersResponse.suggests.playerSuggests).concat(
            mapRemovePlayerSuggestions(pendingPlayersResponse.suggests.removeSuggests),
          ),
        }
      })
    }
  }, [pendingPlayersResponse, pendingPlayersError])

  useEffect(() => {
    if (isNotFoundError(declinedPlayersError)) {
      setAllPlayers(prevState => {
        return {
          ...prevState,
          declined: [],
        }
      })
    } else if (declinedPlayersResponse) {
      setAllPlayers(prevState => {
        return {
          ...prevState,
          declined: mapPlayerSuggestions(declinedPlayersResponse.suggests.playerSuggests).concat(
            mapRemovePlayerSuggestions(declinedPlayersResponse.suggests.removeSuggests),
          ),
        }
      })
    }
  }, [declinedPlayersResponse, declinedPlayersError])

  useEffect(() => {
    if (isLoading && !areApprovedPlayersLoading && !arePendingPlayersLoading && !areDeclinedPlayersLoading)
      setIsLoading(false)
  }, [isLoading, areApprovedPlayersLoading, arePendingPlayersLoading, areDeclinedPlayersLoading])

  const isApprovedPlayersError = approvedPlayersError && !isNotFoundError(approvedPlayersError)
  const isPendingPlayersError = pendingPlayersError && !isNotFoundError(pendingPlayersError)
  const isDeclinedPlayersError = declinedPlayersError && !isNotFoundError(declinedPlayersError)

  return {
    allPlayers,
    isLoading,
    isError: isApprovedPlayersError || isPendingPlayersError || isDeclinedPlayersError,
    revalidatePendingPlayers,
    revalidateApprovedPlayers,
  }
}
