import useSWRInfinite, { SWRInfiniteKeyLoader } from 'swr/infinite'

import { competitionEvents } from 'api/routes'
import { getJson } from 'api/utilities'

import { EventListResponse } from '../interface'

interface EventsHookProps {
  competitionId: string | undefined
  seasonId: string | undefined
}

export const usePaginatedEvents = ({
  competitionId,
  seasonId,
  isPast,
  initialSize = 1,
}: EventsHookProps & { isPast: boolean; initialSize?: number }) => {
  const getKey: SWRInfiniteKeyLoader = (index, previousPageData) => {
    if ((previousPageData && !previousPageData.hasNextPage) || !competitionId || !seasonId) return null

    return competitionEvents(competitionId, seasonId, isPast ? 'last' : 'next', index)
  }

  const { data, isLoading, error, mutate, size, setSize } = useSWRInfinite<EventListResponse>(getKey, getJson, {
    revalidateFirstPage: false,
    initialSize: initialSize,
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  })

  const loadMoreEvents = () => setSize(prevSize => prevSize + 1)

  const hasMoreEvents = data ? data[size - 1]?.hasNextPage : false

  return {
    data,
    isLoading,
    error,
    mutate,
    size,
    loadMoreEvents,
    hasMoreEvents: hasMoreEvents,
  }
}
