import { Text } from '@sofascore/ui'

interface IncidentTimeProps {
  addedTime: number | undefined
  time: number | null
}

export const IncidentTime = ({ addedTime, time }: IncidentTimeProps) => {
  return (
    <Text align="center" font="body.small" color="onSurface.nLv3" mt="2">
      {addedTime ? (
        <>
          {`${time}'`}&nbsp;
          <sup>+{addedTime}</sup>
        </>
      ) : (
        `${time}'`
      )}
    </Text>
  )
}
