import type { PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { getAuthFromState } from 'store/selectors'

interface AuthGuardProps {
  redirectPath?: string
}

const AuthGuard = ({ children, redirectPath = '/login' }: PropsWithChildren<AuthGuardProps>) => {
  const location = useLocation()
  const { userAccount } = useSelector(getAuthFromState)

  if (!userAccount) {
    return <Navigate to={redirectPath} state={{ from: location }} />
  }

  return children ? children : <Outlet />
}

export default AuthGuard
