import { Typography, Box, AppBar, Toolbar, Theme, useTheme } from '@material-ui/core'
import { useContext, PropsWithChildren } from 'react'
import { FormattedMessage } from 'react-intl'
import { useMediaContext } from '@sofascore/ui'
import { Stylable } from 'shared/model'

import { primaryDark } from 'styles/color'
import RouteLeavingGuard from 'components/RouteLeavingGuard'
import { CloseMatchButton, DeleteMatchButton, SaveMatch } from 'modules/Matches/components/MatchHeader/HeaderButtons'
import { HeaderActionContext } from 'modules/Matches/MatchDetails/headerActionContext'
import { useStyles } from 'modules/Matches/MatchDetails/styles'

const Header = ({ children, style }: PropsWithChildren<Stylable>) => {
  const classes = useStyles()
  const theme: Theme = useTheme()
  const { isMobile } = useMediaContext()
  const appBarStyles = !isMobile ? { width: '100%', backgroundColor: primaryDark } : { width: '100%' }

  return (
    <AppBar position="relative" color="primary" style={{ ...appBarStyles, ...style }} className={classes.header}>
      <Toolbar
        style={
          !isMobile
            ? { justifyContent: 'space-between', minHeight: theme.spacing(7), paddingLeft: 20, paddingRight: 20 }
            : {
                minHeight: theme.spacing(7),
                paddingRight: 4,
                paddingLeft: 4,
              }
        }
      >
        {children}
      </Toolbar>
    </AppBar>
  )
}

export const NewSportsMatchDetailsHeader = () => {
  const { onSaveAction, onDeleteAction, enableSave, enableDelete, isSaving, hasUnsavedChanges } =
    useContext(HeaderActionContext)

  return (
    <>
      <Header>
        <CloseMatchButton />
        <Typography variant="subtitle1" noWrap style={{ marginLeft: '12px' }}>
          <FormattedMessage id="matchDetails" />
        </Typography>
        <Box display="flex" marginLeft="auto">
          <DeleteMatchButton disabled={!enableDelete} deleteHandler={onDeleteAction!} />
          <SaveMatch disabled={!enableSave} onClick={onSaveAction!} isSaving={isSaving} />
        </Box>
      </Header>

      <RouteLeavingGuard block={hasUnsavedChanges && !!enableSave} onConfirm={onSaveAction!} />
    </>
  )
}
