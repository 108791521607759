import { PropsWithChildren } from 'react'
import { Text } from '@sofascore/ui'
import { PermissionAction, PermissionScope } from 'entities/Permission'
import { PermissionGuard } from 'shared/ui'

import * as S from './styles'

interface Props {
  standingsName: string
}

export const StandingsControls = ({ standingsName, children }: PropsWithChildren<Props>) => {
  return (
    <S.StandingsControlsContainer bg="surface.s1" position="relative" p="sm" pl="lg">
      <Text fontSize="medium" color="onSurface.nLv1" fontWeight="bold">
        {standingsName}
      </Text>
      <PermissionGuard entityScopes={[PermissionScope.Standings]} checkAllowedActions={[PermissionAction.Update]}>
        <S.StandingsControlsOptions>{children}</S.StandingsControlsOptions>
      </PermissionGuard>
    </S.StandingsControlsContainer>
  )
}
