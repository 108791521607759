import { createGlobalStyle } from 'styled-components'

import { RED, GREEN } from './color'

export const GlobalStyle = createGlobalStyle`
  * {
      box-sizing: border-box;
  }

  html {
      height: 100%;
      box-sizing: border-box;
  }

  body {
    background-color: ${p => p.theme.colors.surface.s0};
    font-family: 'Sofascore Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    min-height: 100%;
  }

  #root {
    height: 100%;
    min-height: 100%;
  }

  .Toastify__toast-container {
    @media (max-width: 959px) { 
      width: 100vw;
      bottom: 0;
      right: 0;
    }
  }

  [data-testid="modal"] {
    z-index: 15000 !important;
  }

  [data-testid="sheet"] {
    z-index: 14000 !important;
  }

  #portals {
    & > div,
    & > ul {
      z-index: 10000 !important;
    }

    & > #toasts {
      z-index: 10001 !important;
    }
  }

  .Toastify__toast-theme--colored.Toastify__toast--success {
    background-color: ${GREEN.incident};
  }

  .Toastify__toast-theme--colored.Toastify__toast--error {
    background-color: ${RED.incident};
  }

  .Toastify__toast-container {
    z-index: 10001 !important; // TODO - this is temporary solution until we ditch MUI
  }

  .editor-toast {
    margin-bottom: 0;

    @media (max-width: 959px) {
      width: auto;
      max-width: calc(100vw - 16px);
      margin: 6px auto;
    }
  }

  @font-face {
    font-family: 'Sofascore Sans';
    src: url('/fonts/SofascoreSans/woff2/SofascoreSans-Regular.woff2') format('woff2'),
      url('/fonts/SofascoreSans/woff/SofascoreSans-Regular.woff') format('woff'),
      url('/fonts/SofascoreSans/otf/SofascoreSans-Regular.otf') format('otf'),
      url('/fonts/SofascoreSans/ttf/SofascoreSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    font-display: swap;
    ascent-override: 102%;
  }

  @font-face {
    font-family: 'Sofascore Sans';
    src: url('/fonts/SofascoreSans/woff2/SofascoreSans-Medium.woff2') format('woff2'),
      url('/fonts/SofascoreSans/woff/SofascoreSans-Medium.woff') format('woff'),
      url('/fonts/SofascoreSans/otf/SofascoreSans-Medium.otf') format('otf'),
      url('/fonts/SofascoreSans/ttf/SofascoreSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    ascent-override: 102%;
  }

  @font-face {
    font-family: 'Sofascore Sans';
    src: url('/fonts/SofascoreSans/woff2/SofascoreSans-Bold.woff2') format('woff2'),
      url('/fonts/SofascoreSans/woff/SofascoreSans-Bold.woff') format('woff'),
      url('/fonts/SofascoreSans/otf/SofascoreSans-Bold.otf') format('otf'),
      url('/fonts/SofascoreSans/ttf/SofascoreSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    ascent-override: 102%;
  }

  /** Sofascore Sans Condensed */

  @font-face {
    font-family: 'Sofascore Sans Condensed';
    src: url('/fonts/SofascoreSans/woff2/SofascoreSans-RegularCondensed.woff2') format('woff2'),
      url('/fonts/SofascoreSans/woff/SofascoreSans-RegularCondensed.woff') format('woff'),
      url('/fonts/SofascoreSans/otf/SofascoreSans-RegularCondensed.otf') format('otf'),
      url('/fonts/SofascoreSans/ttf/SofascoreSans-RegularCondensed.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    ascent-override: 102%;
  }

  @font-face {
    font-family: 'Sofascore Sans Condensed';
    src: url('/fonts/SofascoreSans/woff2/SofascoreSans-MediumCondensed.woff2') format('woff2'),
      url('/fonts/SofascoreSans/woff/SofascoreSans-MediumCondensed.woff') format('woff'),
      url('/fonts/SofascoreSans/otf/SofascoreSans-MediumCondensed.otf') format('otf'),
      url('/fonts/SofascoreSans/ttf/SofascoreSans-MediumCondensed.ttf') format('ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    ascent-override: 102%;
  }

  @font-face {
    font-family: 'Sofascore Sans Condensed';
    src: url('/fonts/SofascoreSans/woff2/SofascoreSans-BoldCondensed.woff2') format('woff2'),
      url('/fonts/SofascoreSans/woff/SofascoreSans-BoldCondensed.woff') format('woff'),
      url('/fonts/SofascoreSans/otf/SofascoreSans-BoldCondensed.otf') format('otf'),
      url('/fonts/SofascoreSans/ttf/SofascoreSans-BoldCondensed.ttf') format('ttf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    ascent-override: 102%;
  }
`
