import { createContext, useContext, useReducer } from 'react'
import { AnyAction } from 'redux'

import { competitionMatchesReducer } from './reducer'
import { CompetitionMatchesState } from './interface'

export const CompetitionMatchesContext = createContext<CompetitionMatchesState | null>(null)
const CompetitionMatchesDispatchContext = createContext<React.Dispatch<AnyAction> | null>(null)

const getInitialState = (): CompetitionMatchesState => ({
  revalidatedEvents: [],
})

const CompetitionMatchesContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(competitionMatchesReducer, getInitialState())

  return (
    <CompetitionMatchesContext.Provider value={state}>
      <CompetitionMatchesDispatchContext.Provider value={dispatch}>
        {children}
      </CompetitionMatchesDispatchContext.Provider>
    </CompetitionMatchesContext.Provider>
  )
}

export default CompetitionMatchesContextProvider

export const useCompetitionMatchesState = (): CompetitionMatchesState => {
  const context = useContext(CompetitionMatchesContext)

  if (!context) {
    throw new Error('useCompetitionMatchesState must be used within a CompetitionMatchesContextProvider')
  }

  return context
}

export const useCompetitionMatchesDispatch = () => {
  const context = useContext(CompetitionMatchesDispatchContext)

  if (!context) {
    throw new Error('useCompetitionMatchesDispatch must be used within a CompetitionMatchesContextProvider')
  }

  return context
}
