import * as React from 'react'
import { useState } from 'react'
import { BoxProps } from '@material-ui/core'
import { connect, useSelector } from 'react-redux'
import { mutate } from 'swr'
import { useIntl } from 'react-intl'
import { StatusType } from 'entities/Status'
import { BasicEvent } from 'entities/Event'
import { useToast } from 'shared/lib'
import { useErrorHandler } from 'shared/hooks'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'
import { FirebaseEventName } from 'shared/lib/firebase/model'
import { Box } from '@sofascore/ui'

import { RootState } from 'store/store'
import { updateEvent } from 'api'
import { UpdateEventBody } from 'modules/Matches/interface'
import { lastCreatedEvents } from 'api/routes'
import { getAuthFromState } from 'store/selectors'
import { BasicInfoPanel } from 'modules/Matches/MatchDetails/BasicInfoPanel'
import { StartTimePanel } from 'modules/Matches/MatchDetails/StartTimePanel'
import { CurrentPeriodPanel } from 'modules/Matches/MatchDetails/CurrentPeriodPanel'
import { ResultSection } from 'modules/Matches/MatchDetails/ResultSection'
import { AggregatedScoreSection } from 'modules/Matches/MatchDetails/AggregatedScoreSection'
import { Rounds } from 'modules/Matches/MatchDetails/Rounds'
import { RemoveSuggestionsDialog } from 'modules/Matches/MatchDetails/RemoveSuggestionsDialog'
import { RemoveSuggestionsPanel } from 'modules/Matches/MatchDetails/RemoveSuggestionsPanel'
import { MY_COMPETITION_HEADER_MOBILE_HEIGHT } from 'modules/MyCompetitions/components/MyCompetitionHeader/config'

import { GeneralMatchDetailsHeader } from './GeneralMatchDetailsHeader'

type MatchDetailsMyItemsProps = {
  close?: () => void
}

type StateProps = {
  event: BasicEvent | null
}

type Props = MatchDetailsMyItemsProps & StateProps & BoxProps

const GeneralMatchDetailsForm = ({ close, event }: Props) => {
  const intl = useIntl()
  const { enqueueToast } = useToast()
  const { userAccount } = useSelector(getAuthFromState)

  const handleError = useErrorHandler()

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const hasStarted = event?.status.type !== StatusType.NOT_STARTED
  const isCrowdsourcingEvent = event?.crowdsourcingDataDisplayEnabled && hasStarted

  const removeAllSuggestions = () => {
    if (event && userAccount) {
      setIsLoading(true)

      const updateEventBody: UpdateEventBody = {
        status: {
          code: event.status.code,
        },
        homeScore: event.homeScore,
        awayScore: event.awayScore,
        aggregatedWinnerCode: event.aggregatedWinnerCode!,
        currentPeriodStartTimestamp: event.currentPeriodStartTimestamp!,
        roundInfo: event.roundInfo,
        startDateTimestamp: event.startTimestamp,
        resetScore: true,
        crowdsourcingDataDisplayEnabled: false,
      }

      updateEvent(event.id, updateEventBody, { calculatePeriodScores: true })
        .then(() => {
          enqueueToast(intl.formatMessage({ id: 'toastSuccess' }), { variant: 'success' })
          mutate(lastCreatedEvents(userAccount.id))
          setIsLoading(false)
          setIsDialogOpen(false)
          close?.()
        })
        .catch(handleError)
    }
  }

  if (event) {
    logFirebaseEvent(FirebaseEventName.OpenMatch, { id: event.id, status: event.status.description })
  }

  return (
    <Box mb={[56, 0]} mt={[-MY_COMPETITION_HEADER_MOBILE_HEIGHT, 0]}>
      {/* Header */}
      <GeneralMatchDetailsHeader close={close} />

      {isCrowdsourcingEvent && (
        <>
          <RemoveSuggestionsPanel onClick={() => setIsDialogOpen(true)} ml={4} mr={3} my={1} />

          <RemoveSuggestionsDialog
            open={isDialogOpen}
            onConfirm={removeAllSuggestions}
            onClose={() => setIsDialogOpen(false)}
            isLoading={isLoading}
          />
        </>
      )}

      {/* Basic Info */}
      <BasicInfoPanel />

      {/* Start Time panel */}
      <StartTimePanel />

      <Rounds />

      <CurrentPeriodPanel />

      <ResultSection />

      <AggregatedScoreSection />
    </Box>
  )
}

const areStatesEqual = (nextState: RootState, prevState: RootState) => {
  if (!nextState.event || !prevState.event) {
    return false
  }

  return nextState.event.id === prevState.event.id
}

const mapStateToProps = ({ event }: RootState) => ({
  event,
})

export default connect<StateProps, null, unknown, RootState>(mapStateToProps, null, null, { areStatesEqual })(
  GeneralMatchDetailsForm,
)
