import styled from 'styled-components'

export const ClickableArea = styled.label<{ isClickable: boolean }>`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: ${p => (p.isClickable ? 'initial' : 'none')};

  cursor: ${p => (p.isClickable ? 'pointer' : 'default')};
`

export const EditIconLabelWrapper = styled.label`
  width: 32px;
  height: 32px;

  position: absolute;
  bottom: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 6px 0 rgba(15, 32, 44, 0.15);

  cursor: pointer;
`
