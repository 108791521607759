import { DropTargetMonitor, useDrop } from 'react-dnd'

import { DynamicLineupsPlayer } from '../../model'
import { DND_TYPE } from '../../config'

interface SubstitutionSlotEmptyProps {
  children: (canDrop: boolean, isOver: boolean) => JSX.Element
  onDrop: (player: DynamicLineupsPlayer, position: number) => void
  position: number
}

export const SubstitutionSlotDropLayer = ({ children, onDrop, position }: SubstitutionSlotEmptyProps) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: DND_TYPE,
    drop: (player: DynamicLineupsPlayer) => {
      onDrop(player, position)
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }))

  return <div ref={drop}>{children(canDrop, isOver)}</div>
}
