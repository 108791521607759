import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Flex, Text, ZIndex } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'
import { BasicTournament } from 'entities/Tournament'
import { useMatchesFilters } from 'features/FilterMatches'

import { Spinner } from 'components/Spinner'
import { colorTokens } from 'styles/color'

import { EventGroupList } from '../EventGroupList'
import { useEvents } from '../hooks/useEvents'
import { useOnBottomHandler } from '../hooks/useOnBottomHandler'
import { NoCreatedEvents, NoFilteredMatches } from '../EmptyStates'
import { useCompetitionMatchesDispatch } from '../CompetitionMatchesContext'
import { createEventGroups, getGroupingCriteriaForFilterCombination } from '../utils'
import { EventGroup } from '../interface'
import { removeAllEvents } from '../CompetitionMatchesContext/actions'
import * as S from './styles'

interface Props {
  tournaments: BasicTournament[]
}

const DesktopMatches = ({ tournaments }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const { competitionId, seasonId } = useParams()
  const { searchParams } = useMatchesFilters()

  const dispatch = useCompetitionMatchesDispatch()

  const {
    events,
    hasMoreLastEvents,
    loadMoreLastEvents,
    areLastEventsLoading,
    hasMoreNextEvents,
    loadMoreNextEvents,
    areNextEventsLoading,
  } = useEvents({
    competitionId,
    seasonId,
  })

  useOnBottomHandler({
    containerRef,
    handler: () => {
      if (hasMoreNextEvents && !areNextEventsLoading) {
        loadMoreNextEvents()
      }
    },
  })

  const [eventGroups, setEventGroups] = useState<EventGroup[]>([])

  const isEventListEmtpy = eventGroups.length === 0
  const noEvents = isEventListEmtpy && !hasMoreLastEvents
  const areEventsLoading = areLastEventsLoading || areNextEventsLoading

  useEffect(() => {
    const groupingCriteria = getGroupingCriteriaForFilterCombination(searchParams)
    setEventGroups(createEventGroups(events, groupingCriteria, searchParams, tournaments.length === 1))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, tournaments])

  // On season change, clean up revalidated events
  useEffect(() => {
    dispatch(removeAllEvents())
  }, [dispatch, seasonId])

  return (
    <Box position="relative" ref={containerRef}>
      {areEventsLoading && (
        <Flex justify="center" mt={100}>
          <Spinner width="40px" color={colorTokens.primary.default} />
        </Flex>
      )}

      {!areEventsLoading && noEvents && searchParams.size === 0 && <NoCreatedEvents />}
      {!areEventsLoading && noEvents && searchParams.size !== 0 && <NoFilteredMatches />}

      {!areEventsLoading && !noEvents && (
        <>
          {areLastEventsLoading ? (
            <Box h={32} bg="surface.s1" br="sm" mt="sm">
              <Flex w="100%" h="100%" justify="center" align="center">
                <Spinner width="16px" color={colorTokens.primary.default} />
              </Flex>
            </Box>
          ) : (
            <>
              {hasMoreLastEvents && (
                <Box h={32} bg="surface.s1" br="sm" mt="sm">
                  <Flex
                    w="100%"
                    h="100%"
                    justify="center"
                    align="center"
                    hoverBg="rgba(220, 220, 220, 0.1)"
                    cursor="pointer"
                    onClick={() => loadMoreLastEvents()}
                  >
                    <Text fontSize="small" fontWeight="medium">
                      <FormattedMessage id="matches_show_past_events" />
                    </Text>
                  </Flex>
                </Box>
              )}
            </>
          )}

          <S.EventGroup mt="sm">
            <EventGroupList eventGroups={eventGroups} />
          </S.EventGroup>

          {hasMoreNextEvents && (
            <Box zIndex={ZIndex.Popover} position="absolute" bottom={0} w="100%">
              <Flex h="100%" justify="center" align="center">
                <Flex w={30} h={30} justify="center" align="center" bg="surface.s1" br="50%" elevation={3}>
                  <Spinner width="20px" color={colorTokens.primary.default} />
                </Flex>
              </Flex>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

export default DesktopMatches
