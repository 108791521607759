import { Text } from '@sofascore/ui'
import noop from 'lodash/noop'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { ENDED_CODE, INTERRUPTED_CODE, StatusType } from 'entities/Status'
import { CompetitionType } from 'entities/Tournament'
import { hasGoneToExtraTime } from 'entities/Event'
import { useFirebaseWithEvent } from 'entities/Event/hooks'
import { FirebaseEventName, FirebaseEventType, FirebaseEventIncidentAction } from 'shared/lib/firebase/model'

import { GREEN, RED, textSecondary } from 'styles/color'
import { extraTimeStatusCodes } from 'components/ProgressBar/helper'
import ResponsivePopUp from 'components/ResponsivePopUp'

import * as S from './styles'
import { ConfirmFullTimeForm } from './ConfirmFullTimeForm'
import { EndPeriodBox } from '../model'

export const ConfirmFullTime = ({ event, onClick, onConfirm, ...boxProps }: EndPeriodBox) => {
  const [isOpen, setIsOpen] = useState(false)
  const logFirebaseWithEvent = useFirebaseWithEvent()

  const responsivePopupOpen = () => {
    setIsOpen(true)
    logFirebaseWithEvent(FirebaseEventName.AddEntity, FirebaseEventType.Incident, FirebaseEventIncidentAction.Period)
  }
  const responsivePopupClose = (discarded?: boolean | undefined) => {
    setIsOpen(false)
    if (discarded)
      logFirebaseWithEvent(
        FirebaseEventName.DiscardEntity,
        FirebaseEventType.Incident,
        FirebaseEventIncidentAction.Period,
      )
    else
      logFirebaseWithEvent(
        FirebaseEventName.SubmitEntity,
        FirebaseEventType.Incident,
        FirebaseEventIncidentAction.Period,
      )
  }

  const hasFinished = event.status.type === StatusType.FINISHED
  const hasFinishedRegularly = event.status.code === ENDED_CODE
  const isInterrupted = event.status.code === INTERRUPTED_CODE
  const isCup = event.tournament.competitionType === CompetitionType.Cup
  const isExtraTime = extraTimeStatusCodes.includes(event.status.code)

  // Disable confirming full time if match has ended, has special status
  // (canceled, postponed, interrupted etc.) or if match is in extra time
  const isDisabled = hasFinished || isInterrupted || isExtraTime

  return (
    <>
      <S.PeriodBox
        $isDisabled={isDisabled}
        onClick={isDisabled ? noop : responsivePopupOpen}
        bg={
          hasFinishedRegularly
            ? GREEN.timelineEnd
            : isInterrupted && !hasGoneToExtraTime(event)
            ? RED.incident
            : undefined
        }
        c={
          hasFinishedRegularly
            ? 'onSurface.nLv1'
            : isInterrupted && !hasGoneToExtraTime(event)
            ? textSecondary
            : undefined
        }
        mr={isCup && (isExtraTime || hasGoneToExtraTime(event)) ? 'sm' : undefined}
        {...boxProps}
      >
        <S.PeriodBoxContent>
          <Text font="body.small" color="inherit" align="center">
            <FormattedMessage
              id={
                hasFinishedRegularly
                  ? 'match_ended'
                  : isInterrupted && !hasGoneToExtraTime(event)
                  ? 'match_interrupted'
                  : isExtraTime || hasGoneToExtraTime(event)
                  ? 'Fulltime.short'
                  : 'confirm_full_time'
              }
            />
          </Text>
        </S.PeriodBoxContent>
      </S.PeriodBox>

      <ResponsivePopUp
        isOpen={isOpen}
        onClose={responsivePopupClose}
        heading={
          <Text font="display.medium">
            <FormattedMessage id="full_time_details" />
          </Text>
        }
      >
        <ConfirmFullTimeForm onClose={responsivePopupClose} onSubmit={onConfirm} />
      </ResponsivePopUp>
    </>
  )
}
