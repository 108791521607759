import { BasicSeason } from 'entities/Season'
import { SportName } from 'entities/Sport'
import { BasicTournament, Period } from 'entities/Tournament'
import * as React from 'react'
import { AnyAction } from 'redux'

interface CompetitionState {
  competitionId: number | null
  seasonId: number | null
  selectedTournament: BasicTournament | null
  allSeasons: BasicSeason[] | null
  allTournaments: BasicTournament[] | null
  sport: SportName
  alpha2: string | null
  numberOfRounds: number | null
  periodLength: Period | null
}

export const SET_COMPETITION_INFO = 'SET_COMPETITION_INFO'

export const CLEAR_COMPETITION_INFO = 'CLEAR_COMPETITION_INFO'

export const SET_SEASON = 'SET_SEASON'

export const SET_TOURNAMETS_FOR_UT = 'SET_TOURNAMETS_FOR_UT'

export const SET_TOURNAMENT = 'SET_TOURNAMENT'

export const REMOVE_TOURNAMENT = 'REMOVE_TOURNAMENT'

export const SET_CREATED_SEASON = 'SET_CREATED_SEASON'

export const SET_PERIOD_LENGTH = 'SET_PERIOD_LENGTH'

export const CompetitionStateContext = React.createContext<CompetitionState | null>(null)
const CompetitionDispatchContext = React.createContext<React.Dispatch<AnyAction> | null>(null)

const getInitialState = (): CompetitionState => ({
  competitionId: null,
  seasonId: null,
  selectedTournament: null,
  allSeasons: null,
  allTournaments: null,
  sport: SportName.Football,
  alpha2: null,
  numberOfRounds: null,
  periodLength: null,
})

const competitionReducer = (state: CompetitionState | null = null, action: AnyAction) => {
  switch (action.type) {
    case SET_COMPETITION_INFO:
      return { ...action.payload }
    case CLEAR_COMPETITION_INFO:
      return { ...getInitialState() }
    case SET_SEASON:
    case SET_TOURNAMETS_FOR_UT:
    case SET_TOURNAMENT:
    case SET_PERIOD_LENGTH:
      return { ...state, ...action.payload }
    case REMOVE_TOURNAMENT:
      return { ...state, selectedTournament: null }
    case SET_CREATED_SEASON:
      return { ...state, allSeasons: [action.payload].concat(state!.allSeasons), seasonId: action.payload.id }
    default:
      return state
  }
}

export const CompetitionInfoProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = React.useReducer(competitionReducer, getInitialState())

  return (
    <CompetitionStateContext.Provider value={state}>
      <CompetitionDispatchContext.Provider value={dispatch}>{children}</CompetitionDispatchContext.Provider>
    </CompetitionStateContext.Provider>
  )
}

export const useCompetitionState = (): CompetitionState => {
  const context = React.useContext(CompetitionStateContext)

  if (!context) {
    throw new Error('useCompetitionState must be used within a CompetitionStateProvider')
  }

  return context
}

export const useCompetitionDispatch = () => {
  const context = React.useContext(CompetitionDispatchContext)

  if (!context) {
    throw new Error('useCompetitionDispatch must be used within a CompetitionDispatchProvider')
  }

  return context
}
