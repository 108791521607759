import { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

interface Props {
  to: string
}

export const RedirectWithLocationState = ({ to }: Props) => {
  const location = useLocation()

  /**
   * undefined - state is not initialized
   * null - state is initialized but empty
   */
  const [state, setState] = useState()

  useEffect(() => {
    if (location) {
      setState(location.state)
    }
  }, [location])

  if (state === undefined) return null

  return <Navigate to={to + location.search} replace state={state} />
}
