import { Box, Button, Typography, useTheme } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { StatusCode } from 'entities/Status'
import { BasicEvent } from 'entities/Event'

import { SetCounter } from 'components/SetCounter/SetCounter'
import { PERIOD1, PERIOD2, PERIOD3, PERIOD4, PERIOD5 } from 'modules/Matches/const'
import { RootState } from 'store/store'
import { SET_SCORE } from 'modules/Matches/matchesReducer'

import { useStyles } from './styles'

const periods = [PERIOD1, PERIOD2, PERIOD3, PERIOD4, PERIOD5]

export const TennisSets = () => {
  const theme = useTheme()
  const classes = useStyles()
  const intl = useIntl()

  const event: BasicEvent = useSelector((state: RootState) => state.event)
  const { homeTeam, awayTeam, homeScore, awayScore, status } = event
  const dispatch = useDispatch()

  const [homePeriodScores, setHomePeriodScores] = useState(
    Object.fromEntries(
      periods.map(period => {
        return [[period], homeScore[period]]
      }),
    ),
  )

  const [awayPeriodScores, setAwayPeriodScores] = useState(
    Object.fromEntries(
      periods.map(period => {
        return [[period], awayScore[period]]
      }),
    ),
  )

  // Reassign on state update
  useEffect(() => {
    setHomePeriodScores(
      Object.fromEntries(
        periods.map(period => {
          return [[period], homeScore[period]]
        }),
      ),
    )

    setAwayPeriodScores(
      Object.fromEntries(
        periods.map(period => {
          return [[period], awayScore[period]]
        }),
      ),
    )
  }, [homeScore, awayScore])

  const handleHomePeriodScoreChange = (period: string) => (value: number | undefined) => {
    if (value === undefined) {
      setHomePeriodScores({ ...homePeriodScores, [period]: null })
      setAwayPeriodScores({ ...awayPeriodScores, [period]: null })
    } else {
      setHomePeriodScores({ ...homePeriodScores, [period]: value })
    }
  }

  const handleAwayPeriodScoreChange = (period: string) => (value: number | undefined) => {
    if (value === undefined) {
      setHomePeriodScores({ ...homePeriodScores, [period]: null })
      setAwayPeriodScores({ ...awayPeriodScores, [period]: null })
    } else {
      setAwayPeriodScores({ ...awayPeriodScores, [period]: value })
    }
  }

  const applyChanges = () => {
    Object.keys(homePeriodScores).map(period =>
      dispatch({
        type: SET_SCORE,
        payload: {
          periodKey: period,
          homeScore: homePeriodScores[period] < 0 ? null : homePeriodScores[period],
          awayScore: awayPeriodScores[period] < 0 ? null : awayPeriodScores[period],
        },
      }),
    )
  }

  const hasResultChanges =
    Object.keys(homePeriodScores).some(period => homePeriodScores[period] !== homeScore[period]) ||
    Object.keys(awayPeriodScores).some(period => awayPeriodScores[period] !== awayScore[period])

  const isNotStarted = status.code === StatusCode['Not started']

  const teamAplayer1Name = homeTeam.subTeams?.length && homeTeam.subTeams[0].name
  const teamAplayer2Name = homeTeam.subTeams?.length && homeTeam.subTeams[1].name
  const teamBplayer1Name = awayTeam.subTeams?.length && awayTeam.subTeams[0].name
  const teamBplayer2Name = awayTeam.subTeams?.length && awayTeam.subTeams[1].name
  const isDoubles = teamAplayer1Name && teamAplayer2Name && teamBplayer1Name && teamBplayer2Name

  return (
    <Box className={classes.container}>
      <Box display="flex" justifyContent="space-between" m={`${theme.spacing(1)}px ${theme.spacing(2)}px`}>
        <Box display="flex" flexDirection="column" mt={`${theme.spacing(3.25)}px`}>
          {isDoubles ? (
            <Typography className={classes.label}>
              {teamAplayer1Name}
              <br />
              {teamAplayer2Name}
            </Typography>
          ) : (
            <Typography className={classes.label}>{homeTeam.name}</Typography>
          )}

          {isDoubles ? (
            <Typography className={classes.label} style={{ marginTop: `${theme.spacing(1)}px` }}>
              {teamBplayer1Name}
              <br />
              {teamBplayer2Name}
            </Typography>
          ) : (
            <Typography className={classes.label}>{awayTeam.name}</Typography>
          )}
        </Box>

        <Box display="flex" className={classes.setsContainer}>
          {Object.keys(homePeriodScores).map(period => (
            <SetCounter
              key={period}
              caption={intl.formatMessage({ id: period })}
              homeScoreValue={homePeriodScores[period]}
              awayScoreValue={awayPeriodScores[period]}
              onHomeValueChange={handleHomePeriodScoreChange(period)}
              onAwayValueChange={handleAwayPeriodScoreChange(period)}
            />
          ))}
        </Box>
      </Box>

      <Box p={2} display="flex" alignItems="center" justifyContent="flex-end">
        {isNotStarted && hasResultChanges && (
          <Typography className={classes.helperText}>
            <FormattedMessage id="applyResultHelperText" />
          </Typography>
        )}
        <Box>
          <Button disabled={!hasResultChanges || isNotStarted} variant="contained" onClick={applyChanges}>
            <FormattedMessage id="apply" />
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
