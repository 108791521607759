import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Flex, Input, Text } from '@sofascore/ui'
import IconAddCircle from '@sofascore/ui/dist/modules/Icons/IconAddCircle'
import { AnimatePresence, motion } from 'framer-motion'
import { FormattedMessage, useIntl } from 'react-intl'
import { useEventState } from 'entities/Event'
import { useToast } from 'shared/lib'
import { useSelector } from 'react-redux'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'
import { FirebaseEventName } from 'shared/lib/firebase/model'
import { StatusType } from 'entities/Status'

import { getAuthFromState } from 'store/selectors'
import { sendMatchReportEmail } from 'api'
import { Button } from 'components/Button'

import { MatchReportAction, MatchReportStatus, RepeaterItem } from '../../model'
import { emailRegex, repeaterItemAnimationConfig } from '../../config'
import * as S from '../../styles'

export function SendEmailForm({
  mutate,
  setEmailModal,
}: {
  mutate: () => void
  setEmailModal: Dispatch<SetStateAction<boolean>>
}) {
  const { userAccount } = useSelector(getAuthFromState)
  const intl = useIntl()
  const { event } = useEventState()
  const { id: eventID, editor } = event
  const { enqueueToast } = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [emailAddresses, setEmailAddresses] = useState<RepeaterItem[]>([
    { uid: uuidv4(), value: editor?.editorEmail || userAccount?.email || '' },
  ])
  const hasFinished = event.status.type === StatusType.FINISHED

  const validateForm = useMemo(() => {
    const emailAddressesErrors = emailAddresses
      .filter(item => {
        const spaceCheck = item?.value.replace(/\s+/g, '')
        const emailCheck = item?.value.match(emailRegex)
        return !spaceCheck || !emailCheck
      })
      .map(item => item.uid)
    return {
      emailAddressesErrors,
    }
  }, [emailAddresses])

  const isFormValid = useMemo(() => {
    return Object.values(validateForm).every(fields => Array.isArray(fields) && fields.length === 0)
  }, [validateForm])

  const onSubmit = async () => {
    if (!isFormValid) return

    setIsLoading(true)

    const updatedData = {
      emails: emailAddresses.map(item => item.value),
    }

    try {
      await sendMatchReportEmail(eventID, updatedData)
      enqueueToast(intl.formatMessage({ id: 'toastSuccess' }), { variant: 'success' })
      logFirebaseEvent(FirebaseEventName.MatchReport, {
        id: eventID,
        action: MatchReportAction.SendToMail,
        sport: event?.tournament?.category?.sport?.slug,
        status: hasFinished ? MatchReportStatus.PostMatch : MatchReportStatus.PreMatch,
      })
      setEmailModal(false)
    } catch (e) {
      console.error(e)
      enqueueToast(intl.formatMessage({ id: 'toastError' }), { variant: 'error' })
    } finally {
      setIsLoading(false)
      mutate()
    }
  }

  return (
    <Flex direction="column" p="xl">
      <Flex direction="column" w="100%">
        <AnimatePresence>
          {emailAddresses.map(coach => (
            <motion.div key={coach.uid} {...repeaterItemAnimationConfig}>
              <Flex direction="column" gapY="md" w="100%" mb="md">
                <Input
                  clearable
                  type="text"
                  value={coach.value}
                  required
                  label={<FormattedMessage id="email_address" />}
                  isValid={validateForm.emailAddressesErrors.includes(coach.uid) ? false : true}
                  endAdornment={
                    emailAddresses?.length > 1 && (
                      <S.RepeaterDeleteButton
                        onClick={() => {
                          const updatedEmailAddresses = emailAddresses.filter(item => item.uid !== coach.uid)
                          setEmailAddresses([...updatedEmailAddresses])
                        }}
                      />
                    )
                  }
                  onClear={() => {
                    const updatedCoaches = emailAddresses.map(item =>
                      item.uid === coach.uid ? { uid: item.uid, value: '' } : item,
                    )
                    setEmailAddresses([...updatedCoaches])
                  }}
                  onChange={value => {
                    const updatedEmailAddresses = emailAddresses.map(item =>
                      item.uid === coach.uid ? { uid: item.uid, value } : item,
                    )
                    setEmailAddresses([...updatedEmailAddresses])
                  }}
                  name="homeCoaches"
                />
              </Flex>
            </motion.div>
          ))}
        </AnimatePresence>
      </Flex>
      <S.RepeaterButton onClick={() => setEmailAddresses([...emailAddresses, { uid: uuidv4(), value: '' }])}>
        <IconAddCircle fill="primary.default" mr="sm" />
        <Text>
          <FormattedMessage id="add_another_email" />
        </Text>
      </S.RepeaterButton>
      <Flex gapX="lg" mt="lg">
        <Button variant="outlined" fullWidth onClick={() => setEmailModal(false)}>
          <FormattedMessage id="cancel" />
        </Button>
        <Button variant="contained" fullWidth onClick={onSubmit} disabled={!isFormValid} isLoading={isLoading}>
          <FormattedMessage id="send" />
        </Button>
      </Flex>
    </Flex>
  )
}
