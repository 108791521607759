import * as React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { FormattedMessage } from 'react-intl'
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import { Typography } from '@material-ui/core'

import { IconCalendar } from 'components/Icons/IconCalendar'
import { IconTime } from 'components/Icons/IconTime'

import { PickerProps } from './interface'
import { useStyles } from './styles'

const now = new Date()

export const DatePicker = ({ value, onChange, label, style, defaultDate = now }: PickerProps) => {
  const classes = useStyles()
  return (
    <div style={style}>
      {label && (
        <Typography variant="caption" color="primary">
          {label}
        </Typography>
      )}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          autoOk
          clearable
          value={value}
          initialFocusedDate={defaultDate}
          placeholder="DD/MM/YYYY"
          onChange={onChange}
          format="dd/MM/yyyy"
          InputProps={{
            disableUnderline: true,
          }}
          className={classes.keyboardDatePicker}
          keyboardIcon={<IconCalendar />}
        />
      </MuiPickersUtilsProvider>
    </div>
  )
}

export const TimePicker = ({ value, onChange, label, style, required }: PickerProps) => {
  const classes = useStyles()
  return (
    <div style={style}>
      {label && (
        <Typography variant="caption" color="primary">
          {label}
        </Typography>
      )}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardTimePicker
          autoOk
          ampm={false}
          placeholder="HH:MM"
          mask="__:__"
          value={value}
          onChange={onChange}
          InputProps={{
            disableUnderline: true,
          }}
          className={classes.keyboardDatePicker}
          keyboardIcon={<IconTime />}
        />
      </MuiPickersUtilsProvider>
      {required && (
        <Typography variant="caption" style={{ marginTop: 8 }}>
          <FormattedMessage id={'requiredField'} />
        </Typography>
      )}
    </div>
  )
}
