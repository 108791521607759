import { Text } from '@sofascore/ui'
import { BasicTeam, TeamImage } from 'entities/Team'

import * as S from './styles'

export const ListLineupsHeader = ({ team }: { team: BasicTeam }) => {
  return (
    <S.ListLineupsHeader>
      <TeamImage team={team} size={40} />
      <Text font="body.large" ml="lg">
        {team.name}
      </Text>
    </S.ListLineupsHeader>
  )
}
