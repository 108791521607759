import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Flex, ZIndex } from '@sofascore/ui'
import { BasicTournament } from 'entities/Tournament'
import { useMatchesFilters } from 'features/FilterMatches'

import { Spinner } from 'components/Spinner'
import { colorTokens } from 'styles/color'
import { EVENT_CELL_HEIGHT } from 'modules/EventCellNew/config'

import { EventGroupList } from '../EventGroupList'
import { useEvents } from '../hooks/useEvents'
import { useBidirectionalInfiniteScroll } from '../hooks/useBidirectionalInfiniteScroll'
import { calculateLatestGroupOffset, getGroupsHeight, getScrollerHeight } from './utils'
import { NoCreatedEvents, NoFilteredMatches } from '../EmptyStates'
import { createEventGroups, getGroupingCriteriaForFilterCombination } from '../utils'
import { EventGroup } from '../interface'
import { useCompetitionMatchesDispatch } from '../CompetitionMatchesContext'
import { removeAllEvents } from '../CompetitionMatchesContext/actions'
import * as S from './styles'

interface Props {
  tournaments: BasicTournament[]
}

const MobileMatches = ({ tournaments }: Props) => {
  const { competitionId, seasonId } = useParams()
  const { searchParams } = useMatchesFilters()

  const dispatch = useCompetitionMatchesDispatch()

  const {
    events,
    areInitialEventsLoaded,
    hasMoreLastEvents,
    loadMoreLastEvents,
    areLastEventsLoading,
    hasMoreNextEvents,
    loadMoreNextEvents,
    areNextEventsLoading,
  } = useEvents({
    competitionId,
    seasonId,
  })

  const { scroller, scrollerRef } = useBidirectionalInfiniteScroll({
    hasMoreLastEvents,
    areLastEventsLoading,
    loadMoreLastEvents,
    hasMoreNextEvents,
    areNextEventsLoading,
    loadMoreNextEvents,
  })

  const [scrollerHeight, setScrollerHeight] = useState('auto')
  const groupListRef = useRef<HTMLDivElement>(null)

  const [eventGroups, setEventGroups] = useState<EventGroup[]>([])
  const [oldGroupsHeight, setOldGroupsHeight] = useState(0)

  useEffect(() => {
    const groupingCriteria = getGroupingCriteriaForFilterCombination(searchParams)
    setEventGroups(createEventGroups(events, groupingCriteria, searchParams, tournaments.length === 1))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, tournaments])

  const isEventListEmtpy = eventGroups.length === 0
  const areEventsLoading = areLastEventsLoading || areNextEventsLoading

  // Adjust event container height
  useLayoutEffect(() => {
    setScrollerHeight(getScrollerHeight({ hasFilters: searchParams.size !== 0 }))
  }, [eventGroups, searchParams, scrollerRef, scroller])

  const handleScrollerHeightChange = useCallback(() => {
    if (scroller && oldGroupsHeight && scroller.scrollHeight > oldGroupsHeight) {
      const heightDiff = getGroupsHeight(eventGroups) - oldGroupsHeight
      if (heightDiff > 0) {
        scroller.scrollTop = heightDiff - EVENT_CELL_HEIGHT - 16
      }

      setOldGroupsHeight(getGroupsHeight(eventGroups))
    }
  }, [eventGroups, oldGroupsHeight, scroller])

  useEffect(() => {
    const observer = new MutationObserver(handleScrollerHeightChange)
    const list = groupListRef.current

    if (list) {
      observer.observe(list, { childList: true })
    }

    return () => observer.disconnect()
  }, [groupListRef, oldGroupsHeight, handleScrollerHeightChange])

  // Initially, position the scroll container on the first relevant event
  useLayoutEffect(() => {
    if (scroller) {
      scroller.scrollTop = calculateLatestGroupOffset(eventGroups)
      setOldGroupsHeight(getGroupsHeight(eventGroups))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scroller])

  // On season change, clean up revalidated events
  useEffect(() => {
    dispatch(removeAllEvents())
  }, [dispatch, seasonId])

  return (
    <Box>
      {areEventsLoading && (
        <Flex justify="center" mt={100}>
          <Spinner width="40px" color={colorTokens.primary.default} />
        </Flex>
      )}

      {!areEventsLoading && isEventListEmtpy && searchParams.size === 0 && <NoCreatedEvents />}
      {!areEventsLoading && isEventListEmtpy && searchParams.size !== 0 && <NoFilteredMatches />}

      {!areEventsLoading && !isEventListEmtpy && areInitialEventsLoaded && (
        <Box ref={scrollerRef} position="relative" h={scrollerHeight} overflowY={'auto'} mt="sm">
          {areLastEventsLoading && (
            <Box position="relative" w="100%" h={0}>
              <Flex
                position="absolute"
                bottom={-40}
                left="50%"
                w={32}
                h={32}
                justify="center"
                align="center"
                bg="surface.s1"
                br="50%"
                elevation={3}
                zIndex={ZIndex.Popover}
                style={{ transform: 'translate(-50%, 0)' }}
              >
                <Spinner width="16px" color={colorTokens.primary.default} />
              </Flex>
            </Box>
          )}

          <S.EventGroup ref={groupListRef}>
            <EventGroupList eventGroups={eventGroups} />
          </S.EventGroup>

          {areNextEventsLoading && (
            <Box position="relative" w="100%" h={0}>
              <Flex
                position="absolute"
                top={-40}
                left="50%"
                w={32}
                h={32}
                justify="center"
                align="center"
                bg="surface.s1"
                br="50%"
                elevation={3}
                zIndex={1}
                style={{ transform: 'translate(-50%, 0)' }}
              >
                <Spinner width="16px" color={colorTokens.primary.default} />
              </Flex>
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}

export default MobileMatches
