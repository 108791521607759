import { StatusType } from 'entities/Status'

import { secondaryColor, textLightColor, textPrimary } from 'styles/color'

export const getScoreColor = (eventStatus: StatusType, side: 'home' | 'away', winningSide: '1' | '2' | 'X') => {
  if (eventStatus === StatusType.IN_PROGRESS) {
    return secondaryColor
  } else {
    if ((side === 'home' && winningSide === '1') || (side === 'away' && winningSide === '2')) {
      return textPrimary
    } else {
      return textLightColor
    }
  }
}
