import IconDelete from '@sofascore/ui/dist/modules/Icons/IconDelete'
import { DeleteIncidentData, Incident } from 'entities/Incident'
import { useState } from 'react'
import { Flex, Text } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'

import { IconButton } from 'components/IconButton'
import ResponsivePopUp from 'components/ResponsivePopUp'

import DeleteIncidentForm from './DeleteIncidentForm'

interface DeleteIncidentProps {
  onDelete: (arg0: DeleteIncidentData) => Promise<void>
  incident: Incident
}

export const DeleteIncident = ({ onDelete, incident }: DeleteIncidentProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <IconButton onClick={() => setIsOpen(true)} br="xs" hoverBg="onSurface.nLv5" mr="sm">
        <IconDelete fill="#000000" />
      </IconButton>

      <ResponsivePopUp
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        heading={
          <Flex align="center">
            <Text font="display.large">
              <FormattedMessage id="delete_detail_title" />
            </Text>
          </Flex>
        }
      >
        <DeleteIncidentForm onSubmit={onDelete} onClose={() => setIsOpen(false)} incident={incident} />
      </ResponsivePopUp>
    </>
  )
}
