import { Box, Text } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'
import { useEndMatchHandler, PeriodSwitcherFRO } from 'features/EditPeriod'
import { UpdateEventBody } from 'entities/Event'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ENDED_CODES } from 'entities/Status'

import Collapse from 'components/Collapse/Collapse'

import { useBasketballPeriods } from '../hooks/usePeriods'

export const BasketballEditPeriodSection = () => {
  const { competitionId, seasonId } = useParams()
  const { activePeriodIndex, selectablePeriods } = useBasketballPeriods()

  const { handleEndMatch } = useEndMatchHandler()
  const navigate = useNavigate()
  const { state } = useLocation()

  const onConfirmEndMatch = async (data: UpdateEventBody, eventId: number) => {
    return handleEndMatch(data, eventId).then(() => {
      if (ENDED_CODES.includes(data.status.code)) {
        navigate(`/my-competitions/${competitionId}/season/${seasonId}/matches/${eventId}/info`, { state: state })
      }
    })
  }

  return (
    <Box bg="surface.s1" mt="sm" br="sm" p="lg" textAlign="center" elevation={1}>
      <Collapse
        initiallyOpen
        title={
          <Text font="display.medium">
            <FormattedMessage id="edit_period" />
          </Text>
        }
        alignTitle="center"
        px="lg"
      >
        <Box mx="auto" pt="lg" w="100%">
          <PeriodSwitcherFRO
            currentPeriodIndex={activePeriodIndex}
            selectablePeriods={selectablePeriods}
            onConfirm={onConfirmEndMatch}
          />
        </Box>
      </Collapse>
    </Box>
  )
}
