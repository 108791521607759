import styled from 'styled-components'
import IconDelete from '@sofascore/ui/dist/modules/Icons/IconDelete'
import { Flex } from '@sofascore/ui'

export const MatchReportActionHolder = styled(Flex)`
  background-color: ${p => p.theme.colors.onSurface.nLv5};
  border-radius: 4px;
  padding: 6px;
`

export const MatchReportActionButton = styled.button`
  height: 100%;
  border: 0;
  padding: 4px;
  border-radius: 4px;
  background: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s;
  :hover {
    background-color: ${p => p.theme.colors.onSurface.nLv5};
  }
`

export const RepeaterButton = styled.button`
  height: 100%;
  border: 0;
  padding: 11px;
  border-radius: 8px;
  background: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s;
  border: 1px solid ${p => p.theme.colors.onSurface.nLv4};
  margin-bottom: 12px;

  :hover {
    background-color: ${p => p.theme.colors.surface.s2};
  }
`

export const RepeaterDeleteButton = styled(IconDelete)`
  fill: ${p => p.theme.colors.onSurface.nLv1};
  border-radius: 4px;
  padding: 2px;
  cursor: pointer;
  transition: all 0.2s;

  :hover {
    background-color: ${p => p.theme.colors.onSurface.nLv4};
    fill: ${p => p.theme.colors.onSurface.nLv1};
  }
`
