import { Box, Text } from '@sofascore/ui'
import { useEffect, useState } from 'react'
import IconChevronRight16 from '@sofascore/ui/dist/modules/Icons/IconChevronRight16'
import { FormattedMessage, useIntl } from 'react-intl'
import useSWR from 'swr'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { StatusType, isSpecialStatus } from 'entities/Status'
import { useEventState } from 'entities/Event'
import { PermissionAction } from 'entities/Permission'
import { PermissionGuard } from 'shared/ui'

import { RED } from 'styles/color'
import ResponsivePopUp from 'components/ResponsivePopUp'
import { eventDetails } from 'api/routes'

import { CancelationType, SportSettings } from '../model'
import { getMinutesAfterStart, getMinutesUntilStart, is15MinBefore } from '../utils'
import EditPostMatchButton from './EditPostMatchButton'
import EditLiveButton from './EditLiveButton'
import MatchActionButton from './MatchActionButton'
import InterruptForm from './InterruptForm'
import PostponeCancelForm from './PostponeCancelForm'

export const MatchActionButtons = ({ disableEditLiveInactiveThreshold, enableEditLiveThreshold }: SportSettings) => {
  const navigate = useNavigate()
  const intl = useIntl()
  const { competitionId, seasonId, eventId } = useParams()
  const { initialEvent, event } = useEventState()
  const { id, tournament, status, startTimestamp, season } = initialEvent
  const { mutate: mutateEventDetails } = useSWR(eventDetails(id))
  const { state } = useLocation()

  const [isPopUpOpen, setPopUpOpen] = useState(false)
  const togglePopUp = () => setPopUpOpen(!isPopUpOpen)

  const [cancelationType, setCancelationType] = useState<CancelationType>()

  const notStarted = status.type === StatusType.NOT_STARTED
  const inProgress = status.type === StatusType.IN_PROGRESS

  const [minutesUntilStart, setMinutesUntilStart] = useState(getMinutesUntilStart(startTimestamp))
  const [minutesAfterStart, setMinutesAfterStart] = useState(getMinutesAfterStart(startTimestamp))

  /**
   * Live Editing for a match, that has NOT STARTED, is possible:
   * from 15mins before the start of the match
   * until 1h 45mins after the start.
   *
   * Once the match starts, it stays in Live state until the editor finishes it.
   */
  const isLiveEditingInactivePeriod =
    notStarted && minutesUntilStart <= enableEditLiveThreshold && minutesAfterStart < disableEditLiveInactiveThreshold

  /**
   * Live Edit button is not visible if the match has not been started for 1h 45mins.
   *
   * Then Post-match option becomes available.
   *
   * When the match gets a final result and an ended status,
   * Edit Live button becomes visible again, is disabled, and displays "Ended" to user.
   */
  const isLiveEditingHidden = notStarted && minutesAfterStart > disableEditLiveInactiveThreshold

  const handleMatchCancelation = (type: CancelationType) => {
    setCancelationType(type)
    togglePopUp()
  }

  const handlePopUpClose = () => {
    setPopUpOpen(false)
  }

  const revalidateEvent = () => {
    mutateEventDetails()
  }

  useEffect(() => {
    /**
     * Refresh current time to compare it to the event startTimestamp
     * and determine wether Live or Post-match editing are available.
     */
    const currentTimeRefreshInterval = setInterval(() => {
      // Refresh Tabs 15min before and 1h 45min after the no started event
      if (is15MinBefore(startTimestamp)) navigate(0)

      setMinutesUntilStart(getMinutesUntilStart(startTimestamp))
      setMinutesAfterStart(getMinutesAfterStart(startTimestamp))
    }, 1000)

    return () => clearInterval(currentTimeRefreshInterval)
  }, [navigate, startTimestamp])

  return (
    <PermissionGuard entityAllowedActions={event.allowedActions} checkAllowedActions={[PermissionAction.Update]}>
      <Box mt="md">
        {!isSpecialStatus(status.code) && (
          <>
            {/* Edit Live Button */}
            {!isLiveEditingHidden && (
              <EditLiveButton
                isLiveEditingEnabled={inProgress || isLiveEditingInactivePeriod}
                merge="bottom"
                onEditLiveClick={() =>
                  navigate(`/my-competitions/${competitionId}/season/${seasonId}/matches/${eventId}/live`, {
                    state: state,
                  })
                }
              />
            )}

            {/* Edit Post Match Button */}
            <EditPostMatchButton
              minutesUntilStart={minutesUntilStart}
              minuteAfterStart={minutesAfterStart}
              merge={isLiveEditingHidden ? undefined : 'top'}
              enableEditLiveThreshold={enableEditLiveThreshold}
              disableEditLiveInactiveThreshold={disableEditLiveInactiveThreshold}
              onEditPostMatchClick={() =>
                navigate(`/my-competitions/${competitionId}/season/${seasonId}/matches/${eventId}/post-match`, {
                  state: state,
                })
              }
            />

            {/* Cancelation Actions */}
            <Box mt="sm">
              {notStarted && (
                <MatchActionButton
                  label={intl.formatMessage({ id: 'postpone_cancel_match' })}
                  endingContent={<IconChevronRight16 fill={RED.incident} />}
                  textColor={RED.incident}
                  onClick={() => handleMatchCancelation(CancelationType.POSTPONE_CANCEL)}
                />
              )}

              {inProgress && (
                <MatchActionButton
                  label={intl.formatMessage({ id: 'interrupt_match' })}
                  endingContent={<IconChevronRight16 fill={RED.incident} />}
                  textColor={RED.incident}
                  onClick={() => handleMatchCancelation(CancelationType.INTERRUPT)}
                />
              )}
            </Box>
          </>
        )}

        {isSpecialStatus(status.code) && tournament.uniqueTournament && (
          <MatchActionButton
            label={intl.formatMessage({ id: 'create_new_match' })}
            endingContent={<IconChevronRight16 />}
            onClick={() =>
              navigate(`/my-competitions/${tournament.uniqueTournament!.id}/season/${season!.id}/matches`, {
                state: { shouldOpenForm: true },
              })
            }
          />
        )}

        <ResponsivePopUp
          isOpen={isPopUpOpen}
          onClose={togglePopUp}
          heading={
            <Text fontWeight="bold" fontSize={18}>
              {cancelationType === CancelationType.POSTPONE_CANCEL ? (
                <FormattedMessage id="postpone_cancel_match" />
              ) : (
                <FormattedMessage id="interrupt_match" />
              )}
            </Text>
          }
        >
          {cancelationType === CancelationType.POSTPONE_CANCEL ? (
            <PostponeCancelForm handleClose={handlePopUpClose} revalidateEvent={revalidateEvent} />
          ) : (
            <InterruptForm handleClose={handlePopUpClose} revalidateEvent={revalidateEvent} />
          )}
        </ResponsivePopUp>
      </Box>
    </PermissionGuard>
  )
}
