import {
  FIRST_HALF_CODE,
  FIRST_QUARTER_CODE,
  SECOND_HALF_CODE,
  SECOND_QUARTER_CODE,
  THIRD_QUARTER_CODE,
  FOURTH_QUARTER_CODE,
  FIRST_EXTRA_TIME_CODE,
  SECOND_EXTRA_TIME_CODE,
  PENALTIES_CODE,
  OVERTIME as OVERTIME_CODE,
} from 'entities/Status'

export const NEW_EVENT_ID = 'new'
export const NUMBER_ROUND = 'numberRound'
export const STAGE_ROUND = 'stageRound'
export const MIN_ROUND = 1
export const MAX_ROUND = 200
export const FIRST_HALF = 'firstHalf'
export const SECOND_HALF = 'secondHalf'
export const BETWEEN_PERIODS = 'halftime'
export const ENDED = 'ended'
export const OTHER = 'other'
export const RESET = 'reset'

// Initial values of score counters.
export const INITIAL_INVALID_SCORE = -1
// Initial valid score value
export const INITIAL_VALID_SCORE = 0

export const CURRENT = 'current'
export const HALF_TIME = 'period1'
export const PERIOD1 = 'period1'
export const PERIOD2 = 'period2'
export const PERIOD3 = 'period3'
export const PERIOD4 = 'period4'
export const PERIOD5 = 'period5'
export const EXTRA_TIME_1 = 'extra1'
export const EXTRA_TIME_2 = 'extra2'
export const PENALTIES = 'penalties'
export const OVERTIME = 'overtime'

/*
  the difference between current and entered time in minutes 
  for match ended checkbox to apear in create match form
  Default: 105min or 1h and 45min
*/
export const MATCH_ENDED_PERIOD_DIFF = 105

export const periodNamesSportMap = {
  [PERIOD1]: {
    football: 'Halftime',
    esports: 'Halftime',
    futsal: 'Halftime',
    basketball: '1st quarter',
    waterpolo: '1st quarter',
    tennis: '1st set',
    volleyball: '1st set',
    minifootball: 'Halftime',
    rugby: 'Halftime',
  },
  [PERIOD2]: {
    football: 'Full time',
    esports: 'Full time',
    futsal: 'Full time',
    basketball: '2nd quarter',
    waterpolo: '2nd quarter',
    volleyball: '2nd set',
    tennis: '2nd set',
    minifootball: 'Full time',
    rugby: 'Full time',
  },
  [PERIOD3]: {
    basketball: '3rd quarter',
    waterpolo: '3rd quarter',
    tennis: '3rd set',
    volleyball: '3rd set',
  },
  [PERIOD4]: {
    basketball: '4th quarter',
    waterpolo: '4th quarter',
    tennis: '4th set',
    volleyball: '4th set',
  },
  [PERIOD5]: {
    tennis: '5th set',
    volleyball: '5th set',
  },
  [CURRENT]: {
    football: 'total',
    esports: 'total',
    futsal: 'total',
    basketball: 'total',
    waterpolo: 'total',
    tennis: 'total',
    volleyball: 'total',
    minifootball: 'total',
    rugby: 'total',
  },
  [EXTRA_TIME_1]: {
    football: '1st extra',
    esports: '1st extra',
    futsal: '1st extra',
    minifootball: '1st extra',
    rugby: '1st extra',
  },
  [EXTRA_TIME_2]: {
    football: '2nd extra',
    esports: '2nd extra',
    futsal: '2nd extra',
    minifootball: '2nd extra',
    rugby: '2nd extra',
  },
  [PENALTIES]: {
    football: 'Penalties',
    esports: 'Penalties',
    futsal: 'Penalties',
    waterpolo: 'Penalties',
    minifootball: 'Penalties',
    rugby: 'Penalties',
  },
  [OVERTIME]: {
    basketball: 'Overtime',
    waterpolo: 'Overtime',
    volleyball: 'Overtime',
  },
}

export const displayableScores = [
  PERIOD1,
  PERIOD2,
  CURRENT,
  EXTRA_TIME_1,
  EXTRA_TIME_2,
  PENALTIES,
  PERIOD3,
  PERIOD4,
  OVERTIME,
]

// List of status codes and possible periods for score.
// This map is needed for situations when we increment/decrement Total (current) score
// and current match status is one of possible periods (1st half, 1st quarter etc) so
// we can know which score period we need to increment/decrement also.
export const statusCodesPeriodMap = {
  [FIRST_HALF_CODE]: 'period1', // 1st half
  [FIRST_QUARTER_CODE]: 'period1', // 1st quarter
  [SECOND_HALF_CODE]: 'period2', // 2nd half
  [SECOND_QUARTER_CODE]: 'period2', // 2nd quarter
  [THIRD_QUARTER_CODE]: 'period3', // 3rd quarter
  [FOURTH_QUARTER_CODE]: 'period4', // 4th quarter
  [FIRST_EXTRA_TIME_CODE]: 'extra1', // 1st extra time
  [SECOND_EXTRA_TIME_CODE]: 'extra2', // 2nd extra time
  [PENALTIES_CODE]: 'penalties', // Penalties
  [OVERTIME_CODE]: 'overtime', // Overtime (basketball)
}

export const periodStatuses = [
  FIRST_HALF_CODE,
  FIRST_QUARTER_CODE,
  SECOND_HALF_CODE,
  SECOND_QUARTER_CODE,
  THIRD_QUARTER_CODE,
  FOURTH_QUARTER_CODE,
  FIRST_EXTRA_TIME_CODE,
  SECOND_EXTRA_TIME_CODE,
  OVERTIME_CODE,
]

export const TENNIS_SINGLE = 'tennisSingle'
export const TENNIS_DOUBLE = 'tennisDouble'
