export const ADD_TO_LINEUP = 'ADD_TO_LINEUP'

export const REMOVE_FROM_LINEUP = 'REMOVE_FROM_LINEUP'

export const ADD_TO_SUBSTITUTIONS_SINGLE = 'ADD_TO_SUBSTITUTIONS_SINGLE'

export const ADD_TO_SUBSTITUTIONS_MULTIPLE = 'ADD_TO_SUBSTITUTIONS_MULTIPLE'

export const REMOVE_FROM_SUBSTITUTIONS = 'REMOVE_FROM_SUBSTITUTIONS'

export const ADD_TO_MISSING_PLAYERS = 'ADD_TO_MISSING_PLAYERS'

export const REMOVE_FROM_MISSING_PLAYERS = 'REMOVE_FROM_MISSING_PLAYERS'

export const SET_CAPTAIN = 'SET_CAPTAIN'

export const CLEAR_CAPTAIN = 'CLEAR_CAPTAIN'

export const SET_FORMATION = 'SET_FORMATION'

export const SET_KIT = 'SET_KIT'

export const SET_GOALKEEPER_KIT = 'SET_GOALKEEPER_KIT'

export const RESET = 'RESET'

export const CLEAR_ALL = 'CLEAR_ALL'
