/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { mutate } from 'swr'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'
import { useMediaContext } from '@sofascore/ui'
import { useNavigate } from 'react-router-dom'
import { StatusType } from 'entities/Status'
import { SportName } from 'entities/Sport'
import { useToast } from 'shared/lib'
import { useErrorHandler } from 'shared/hooks'
import { BasicEvent } from 'entities/Event'

import { UpdateEventBody } from 'modules/Matches/interface'
import { getAuthFromState, selectEvent } from 'store/selectors'
import * as routes from 'api/routes'
import { deleteEvent, updateEvent } from 'api'
import MatchStatus from 'modules/Matches/MatchDetails/MatchStatus'
import { RemoveSuggestionsDialog } from 'modules/Matches/MatchDetails/RemoveSuggestionsDialog'
import { RemoveSuggestionsPanel } from 'modules/Matches/MatchDetails/RemoveSuggestionsPanel'
import { ResultContainer } from 'modules/Matches/MatchDetails/ResultSection'
import TeamsInfo from 'modules/Matches/MatchDetails/TeamsInfo'
import { TennisSets } from 'modules/Matches/MatchDetails/TennisSets/TennisSets'
import { areEventsEqual, isCurrentPeriodStartTimestampValid } from 'modules/Matches/helper'
import { HeaderActionContext } from 'modules/Matches/MatchDetails/headerActionContext'
import { getEventBody } from 'modules/Matches/MatchDetails/helper'
import useConfirm from 'modules/ConfirmDialog/useConfirm'
import usePrevious from 'utils/usePrevious'
import { CrowdsourcingInfoNote } from 'modules/Matches/components/CrowdsourcingInfoNote'
import { useMyCompetitionsState } from 'modules/MyCompetitions/providers/MyCompetitionsProvider'

export const NewSportsMatchDetails = ({ eventData }: { eventData: BasicEvent }) => {
  const {
    setOnSaveAction,
    setOnDeleteAction,
    setEnableSave,
    setEnableDelete,
    setIsSaving,
    hasUnsavedChanges,
    setHasUnsavedChanges,
  } = useContext(HeaderActionContext)

  const navigate = useNavigate()
  const handleError = useErrorHandler()
  const intl = useIntl()

  const { enqueueToast } = useToast()
  const { isMobile } = useMediaContext()
  const { uniqueTournament } = useMyCompetitionsState()
  const event = useSelector(selectEvent)
  const { userAccount } = useSelector(getAuthFromState)
  const { confirm } = useConfirm()
  const prevEvent = usePrevious(event)
  const sport = uniqueTournament?.category.sport.slug

  const [isInfoNoteOpened, setIsInfoNoteOpened] = useState(true)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const saveDetails = () => {
    if (event && prevEvent) {
      const updateEventBody = getEventBody(event, prevEvent)

      if (updateEventBody) {
        setIsSaving!(true)

        updateEvent(event.id, updateEventBody, { calculatePeriodScores: true })
          .then(() => {
            enqueueToast(intl.formatMessage({ id: 'toastSuccess' }), { variant: 'success' })
            setIsSaving!(false)
            setHasUnsavedChanges!(false)
            mutate(routes.eventDetails(event.id))
          })
          .catch(handleError)
      }
    }
  }

  const deleteMatch = async () => {
    const ok = await confirm({
      messageTextElement: intl.formatMessage({ id: 'editor_delete_match' }),
      confirmTextElement: intl.formatMessage({ id: 'yes_delete' }),
    })

    if (ok && event) {
      deleteEvent(event.id)
        .then(() => {
          navigate('/home')
        })
        .catch(handleError)
    }
  }

  const removeAllSuggestions = () => {
    if (event && userAccount) {
      setIsLoading(true)

      const updateEventBody: UpdateEventBody = {
        status: {
          code: event.status.code,
        },
        homeScore: event.homeScore,
        awayScore: event.awayScore,
        aggregatedWinnerCode: event.aggregatedWinnerCode!,
        currentPeriodStartTimestamp: event.currentPeriodStartTimestamp!,
        roundInfo: event.roundInfo,
        startDateTimestamp: event.startTimestamp,
        resetScore: true,
        crowdsourcingDataDisplayEnabled: false,
      }

      updateEvent(event.id, updateEventBody, { calculatePeriodScores: true })
        .then(() => {
          enqueueToast(intl.formatMessage({ id: 'toastSuccess' }), { variant: 'success' })
          mutate(routes.lastCreatedEvents(userAccount.id))
          setIsLoading(false)
          setIsDialogOpen(false)
        })
        .catch(handleError)
    }
  }

  /*
    Used to:
    1. Enable/disable save button in drawer header
    2. Display unsaved changes message
   */
  useEffect(() => {
    if (event && eventData) {
      setHasUnsavedChanges!(!areEventsEqual(event, eventData))
    } else {
      setHasUnsavedChanges!(false)
    }
  }, [event, eventData])

  useEffect(() => {
    setOnSaveAction!(() => saveDetails)
    setOnDeleteAction!(() => deleteMatch)
    setEnableSave!(hasUnsavedChanges && isCurrentPeriodStartTimestampValid(event!))
    setEnableDelete!(true)
  }, [hasUnsavedChanges, event, setOnSaveAction, setOnDeleteAction, setEnableSave, setEnableDelete])

  const hasStarted = event?.status.type !== StatusType.NOT_STARTED
  const isCrowdsourcingEvent = event?.crowdsourcingDataDisplayEnabled && hasStarted

  return (
    <>
      {isCrowdsourcingEvent && (
        <CrowdsourcingInfoNote isOpen={isInfoNoteOpened} onClose={() => setIsInfoNoteOpened(false)} />
      )}

      <Box mx={isMobile ? 1 : 4} pb={isMobile ? 7 : 0}>
        <Box pt={isCrowdsourcingEvent ? 2 : 0} mx={isMobile ? 1 : 4}>
          {isCrowdsourcingEvent && (
            <>
              <RemoveSuggestionsPanel onClick={() => setIsDialogOpen(true)} my={1} />

              <RemoveSuggestionsDialog
                open={isDialogOpen}
                onConfirm={removeAllSuggestions}
                onClose={() => setIsDialogOpen(false)}
                isLoading={isLoading}
              />
            </>
          )}
        </Box>

        <TeamsInfo />

        <MatchStatus />

        <ResultContainer />

        {sport === SportName.Tennis && <TennisSets />}
      </Box>
    </>
  )
}
