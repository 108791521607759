import { Flex } from '@sofascore/ui'
import styled from 'styled-components'

export const Arrow = styled(Flex)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 36px;
  height: 36px;
  background-color: ${p => p.theme.colors.surface.s1};
  box-shadow: -4px 4px 8px 0 rgba(15, 32, 44, 0.1);
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
`

export const CalendarButton = styled(Flex)`
  min-width: 64px;
  border-radius: 8px;
  box-shadow: -4px 4px 8px 0 rgba(15, 32, 44, 0.1);

  ${p => p.theme.breakpoints.query.mdMax`
    min-width: 56px;
    box-shadow: -10px 0px 8px -8px rgba(15, 32, 44, 0.1);
  `}
`
