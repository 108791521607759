import styled from 'styled-components'

export const PlayerListGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
`

export const ListLineupsHeader = styled.header`
  display: flex;
  align-items: center;
  padding: 12px 0;
`
