import * as React from 'react'

function usePrevious<T>(value: T) {
  const ref: React.MutableRefObject<T | undefined> = React.useRef()
  React.useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export default usePrevious
