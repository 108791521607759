import styled from 'styled-components'

export const Toast = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: -16px 4px 32px 0 rgba(15, 32, 44, 0.25), -4px 4px 8px 0 rgba(15, 32, 44, 0.1);
  background: #fff;
  width: 296px;
  box-sizing: border-box;
  z-index: 10001; // TODO - this is temporary solution until we ditch MUI
`

export const IconSlot = styled.div`
  width: 24px;
  flex-basis: 24px;
  height: 24px;
  flex-shrink: 0;
`

export const Button = styled.button`
  appearance: none;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 8px;
  padding: 8px 12px;

  background-color: ${p => p.theme.colors.primary.default};
`
