import { SportName } from 'entities/Sport'

import { rugbyPointTypeValues } from '../config'
import { IncidentGoalType } from '../model'

type GoalTypeToValue = { [key in IncidentGoalType]?: number }

export const mapIncidentGoalTypeToValue: { [key in SportName]?: GoalTypeToValue } = {
  [SportName.Rugby]: rugbyPointTypeValues,
}
