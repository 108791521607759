import { memo, useEffect, useState } from 'react'
import { Box, Flex, Text } from '@sofascore/ui'
import useSWR from 'swr'
import { FormattedMessage } from 'react-intl'
import { orderedSports } from 'entities/Sport'

import { uniqueTournaments } from 'api/routes'
import { UniqueTournamentSeason, UniqueTournamentsResponse } from 'modules/MyCompetitions/interface'
import { getCompetitionsWithAllSeasons } from 'modules/MyCompetitions/helper'
import CompetitionRequest from 'modules/MyCompetitions/components/CompetitionRequest'
import {
  MyCompetitionsActionType,
  useMyCompetitionsDispatch,
  useMyCompetitionsState,
} from 'modules/MyCompetitions/providers/MyCompetitionsProvider'

import { CompetitionListSearch } from './components/CompetitionListSearch'
import { SportLeagueList } from './components/SportLeagueList'
import * as S from './styles'

interface Props {
  hideMobile?: boolean
}

/**
 * List of available competitions for users.
 */
const CompetitionList = ({ hideMobile }: Props) => {
  const { competitionsWithSeasons } = useMyCompetitionsState()
  const myCompetitionsDispatch = useMyCompetitionsDispatch()
  const competitions = getCompetitionsWithAllSeasons(competitionsWithSeasons)

  const { data: fetchedCompetitions } = useSWR<UniqueTournamentsResponse>(uniqueTournaments())

  const [query, setQuery] = useState('')

  useEffect(() => {
    if (fetchedCompetitions) {
      myCompetitionsDispatch({
        type: MyCompetitionsActionType.SET_COMPETITIONS,
        payload: fetchedCompetitions.uniqueTournaments as UniqueTournamentSeason[],
      })
    }
  }, [fetchedCompetitions, myCompetitionsDispatch])

  const onSearchChange = (query: string) => {
    setQuery(query)
  }

  const filteredCompetitions = competitions.filter(
    competition => competition.uniqueTournament.name.toLowerCase().indexOf(query.toLowerCase()) > -1,
  )

  const competitionsBySport = orderedSports.map(sport => {
    return {
      sport: sport,
      competitions: filteredCompetitions.filter(
        competition => competition.uniqueTournament.category.sport.slug === sport,
      ),
    }
  })

  return (
    <S.CompetitionList $hideMobile={hideMobile}>
      <Box br="sm" bg="surface.s1" mb="sm" px="lg" pt="md" pb={competitions?.length > 6 ? 'lg' : 'md'}>
        <Flex display={['none', 'flex']} justify="space-between" align="center">
          <Text font="display.medium" textTransform="uppercase" fontSize={18}>
            <FormattedMessage id="my_competitions" />
          </Text>
          <CompetitionRequest />
        </Flex>
        {competitions?.length > 6 && (
          <Box mt={['xs', 'md']}>
            <CompetitionListSearch query={query} onChange={onSearchChange} />
          </Box>
        )}
      </Box>

      <Box>
        {competitionsBySport?.map(sportGroup => {
          return sportGroup.competitions?.length ? (
            <SportLeagueList key={sportGroup.sport} sport={sportGroup.sport} competitions={sportGroup.competitions} />
          ) : null
        })}
      </Box>
    </S.CompetitionList>
  )
}

export default memo(CompetitionList)
