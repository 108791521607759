import { useTheme } from '@material-ui/core'
import { useRef, useLayoutEffect } from 'react'
import { useIntl } from 'react-intl'
import { Box, Text } from '@sofascore/ui'
import * as selection from 'd3-selection'
import * as transition from 'd3-transition'
import * as ease from 'd3-ease'
import * as scale from 'd3-scale'
import { BasicEvent } from 'entities/Event'
import { StatusType } from 'entities/Status'

import { RED, backgrounds, neutral, textLightColor } from 'styles/color'
import { getElapsed, getMaxTime, getPeriodMarks } from 'components/ProgressBar/helper'

import Timer from './Timer'

const d3: typeof selection & typeof transition & typeof ease & typeof scale = Object.assign(
  {},
  selection,
  transition,
  ease,
  scale,
)

export const MatchTimeline = ({ event }: { event: BasicEvent }) => {
  const ref = useRef<SVGSVGElement>(null)
  const timerRef = useRef<HTMLElement>(null)

  const intl = useIntl()
  const theme = useTheme()

  const isInProgress = event.status.type === StatusType.IN_PROGRESS

  function draw() {
    const root = ref.current

    if (!root) return

    const d3Root = d3.select(root)
    const rootRect = root.getBoundingClientRect()
    const elapsed = getElapsed(event)
    const domainMax = getMaxTime(event)

    if (!domainMax) return

    const scaleMinutes = d3.scaleLinear().domain([0, domainMax]).range([0, rootRect.width])

    const rects = d3Root.selectAll<SVGRectElement, number>('rect').data([elapsed])

    rects
      .enter()
      .append('rect')
      .merge(rects)
      .attr('fill', theme.palette.secondary.main)
      .attr('x', 0)
      .attr('y', 0)
      .attr('rx', 3)
      .attr('ry', 3)
      .transition()
      .duration(200)
      .ease(d3.easeCubicInOut)
      .attr('width', d => (elapsed > -1 ? scaleMinutes(d) : 0))
      .attr('height', 4)

    const periodMarks = getPeriodMarks(event, intl)

    const circles = d3Root.selectAll<SVGCircleElement, { x: number; label: string }>('circle').data(periodMarks)
    circles.exit().remove()

    circles
      .enter()
      .append('circle')
      .merge(circles)
      .attr('fill', d => (scaleMinutes(elapsed) >= scaleMinutes(d.x) ? theme.palette.secondary.main : neutral))
      .attr('r', d => (d.hasDivider ? 4 : 0))
      .transition()
      .duration(200)
      .ease(d3.easeCubicInOut)
      .attr('stroke', d => (scaleMinutes(elapsed) >= scaleMinutes(d.x) ? theme.palette.secondary.main : neutral))
      .attr('stroke-width', 4)
      .attr('cx', d => scaleMinutes(d.x))
      .attr('cy', 2)

    const texts = d3Root.selectAll<SVGTextElement, { x: number; label: string }>('text').data(periodMarks)
    texts.exit().remove()

    texts
      .enter()
      .append('text')
      .merge(texts)
      .attr('font-family', 'Sofascore Sans')
      .attr('font-size', 12)
      .attr('text-anchor', d => (d.x === 0 ? 'start' : 'middle'))
      .transition()
      .duration(200)
      .ease(d3.easeCubicInOut)
      .attr('fill', d => (scaleMinutes(elapsed) >= scaleMinutes(d.x) ? theme.palette.secondary.main : textLightColor))
      .attr('x', d => (d.x > 0 ? scaleMinutes(d.x) : -8))
      .attr('y', 26)
      .text(d => d.label)

    if (timerRef.current) {
      const timerRectWidth = timerRef.current.getBoundingClientRect().width
      const timer = d3.select(timerRef.current)
      timer
        .style('display', 'inline-block')
        .style('position', 'relative')
        .style('bottom', '-6px')
        .style('color', RED.incident)
        .transition()
        .duration(200)
        .ease(d3.easeCubicInOut)
        .style('transform', () => {
          let xOffset = scaleMinutes(elapsed)
          if (xOffset < timerRectWidth / 2) {
            // edge case: left
            xOffset = 0
          } else {
            // edge case: right, plus centering
            xOffset = Math.min(xOffset - timerRectWidth / 2, rootRect.width - timerRectWidth)
          }
          return `translateX(${xOffset}px)`
        })
    }
  }

  useLayoutEffect(() => {
    draw()

    let intervalId = 0

    if (isInProgress) {
      intervalId = window.setInterval(() => {
        draw()
      }, 1000)
    }

    return () => {
      if (intervalId) {
        window.clearInterval(intervalId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, event])

  return (
    <Box minH={60} pt="xs">
      <Text fontSize={12} lineHeight="16px" ref={timerRef} minH={16} minW={1}>
        <Timer event={event} />
      </Text>
      <svg
        ref={ref}
        width="100%"
        height="4"
        style={{
          backgroundColor: backgrounds.lightRgba,
          overflow: 'visible',
          borderRadius: '6px',
        }}
      />
    </Box>
  )
}
