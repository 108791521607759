import { Flex, Input } from '@sofascore/ui'
import { FormattedMessage, useIntl } from 'react-intl'
import { useState } from 'react'
import { useEventState } from 'entities/Event'
import { useToast } from 'shared/lib'
import { useErrorHandler } from 'shared/hooks'

import { Button } from 'components/Button'
import { updateEventLocation } from 'api'

interface Props {
  handleClose: () => void
  revalidateEvent: () => void
}

const UpdateCityForm = ({ handleClose, revalidateEvent }: Props) => {
  const { enqueueToast } = useToast()
  const { initialEvent } = useEventState()
  const { id, editorEventCity } = initialEvent
  const [city, setCity] = useState(editorEventCity)
  const intl = useIntl()
  const handleError = useErrorHandler()
  const [isSaving, setIsSaving] = useState(false)

  const handleConfirm = async () => {
    setIsSaving(true)

    // send new city to backend
    try {
      await updateEventLocation(id, {
        city,
      })

      revalidateEvent()
      handleClose()
      enqueueToast(intl.formatMessage({ id: 'toastSuccess' }), { variant: 'success' })
    } catch (err) {
      handleError(err)
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <Flex direction="column">
      <Flex mx="lg">
        <Input label={intl.formatMessage({ id: 'city' })} value={city} onChange={value => setCity(value)} w="100%" />
      </Flex>
      <Flex justify="center" gapX="lg" px={40} py="xl">
        <Button variant="outlined" fullWidth onClick={handleClose}>
          <FormattedMessage id="cancel" />
        </Button>
        <Button variant="contained" fullWidth onClick={handleConfirm} isLoading={isSaving}>
          <FormattedMessage id="confirm" />
        </Button>
      </Flex>
    </Flex>
  )
}

export default UpdateCityForm
