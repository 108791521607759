import styled from 'styled-components'

export const PickerColumn = styled.div`
  height: 100%;

  position: relative;

  overflow: hidden;
  text-align: center;

  display: flex;
  flex-grow: 1;
`

export const PickerScroller = styled.div`
  transition: 300ms ease-out;
  padding: 0 8px; // breathing space for PickerItem scale animation: ;
`

export const PickerItem = styled.div<{ isSelected: boolean }>`
  position: relative;

  font-size: 14px;

  white-space: nowrap;
  color: #999999;
  transform: ${p => (p.isSelected ? 'scaleX(1.8)' : 'scaleX(1)')};
  transition: transform 200ms;
  overflow: hidden;
  text-overflow: ellipsis;

  cursor: pointer;
`
