import {
  EventActionType,
  getAggregateWinnerOption,
  getAggregateWinnerOptions,
  useEventDispatch,
  useEventState,
} from 'entities/Event'
import { useIntl } from 'react-intl'
import { useMediaContext } from '@sofascore/ui'

import { Select } from 'components/Select'

export const SetAggregateWinner = () => {
  const intl = useIntl()

  const { isMobile } = useMediaContext()

  const { event } = useEventState()
  const eventDispatch = useEventDispatch()
  const { homeTeam, awayTeam } = event

  return (
    <Select
      label={intl.formatMessage({ id: 'aggregate_winner' })}
      options={getAggregateWinnerOptions(homeTeam.name, awayTeam.name, intl)}
      value={getAggregateWinnerOption(event, intl)}
      onChange={option => eventDispatch({ type: EventActionType.UPDATE_WINNER_CODE, payload: option.value })}
      disableClear
      bg={isMobile ? 'surface.s1' : 'surface.s2'}
      mt={isMobile ? 'sm' : undefined}
      br="sm"
    />
  )
}
