export const getLandingPageURLPath = (selectedLocale: string) => {
  let path

  switch (selectedLocale) {
    case 'hr':
      path = '/hr/eu-fondovi'
      break
    case 'es':
      path = '/es/fondos-de-la-ue'
      break
    case 'pt':
      path = '/pt/eu-funds'
      break
    default:
      path = '/eu-funds'
  }

  return path
}
