import { Box, Flex, Input } from '@sofascore/ui'
import { FormattedMessage, useIntl } from 'react-intl'
import { useState } from 'react'
import { CompetitionType, CupRound } from 'entities/Tournament'
import { useEventState } from 'entities/Event'
import { useToast } from 'shared/lib'
import { useErrorHandler } from 'shared/hooks'

import { Button } from 'components/Button'
import { updateMatchInfo } from 'api'
import { Select } from 'components/Select'
import { getTournamentCupRounds } from 'modules/Matches/components/RoundsForm/helper'
import { formatTournamentRound } from 'translations/mappings/components/FormattedRound'

interface Props {
  handleClose: () => void
  revalidateEvent: () => void
}

// Qualifications are not supported
// TODO: Get firstCupRound on event route
const stageOptions = getTournamentCupRounds(CupRound.FINAL_256)

const UpdateRoundForm = ({ handleClose, revalidateEvent }: Props) => {
  const { enqueueToast } = useToast()
  const intl = useIntl()
  const handleError = useErrorHandler()
  const { initialEvent } = useEventState()
  const { id, tournament, roundInfo } = initialEvent

  const [isSaving, setIsSaving] = useState(false)

  const [selectedRound, setSelectedRound] = useState(roundInfo?.round.toString())
  const [selectedStage, setSelectedStage] = useState(roundInfo?.name)

  const isLeague = tournament.competitionType === CompetitionType.League

  const handleConfirm = async () => {
    setIsSaving(true)

    try {
      const roundInfoPayload = {
        roundInfo: isLeague
          ? {
              round: +selectedRound!,
            }
          : {
              name: selectedStage,
            },
      }

      await updateMatchInfo(id.toString(), roundInfoPayload)

      revalidateEvent()
      handleClose()
      enqueueToast(intl.formatMessage({ id: 'toastSuccess' }), { variant: 'success' })
    } catch (err) {
      handleError(err)
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <Flex direction="column">
      <Flex m="md">
        {isLeague ? (
          <Box w="100%">
            <Input label="Round" type="number" value={selectedRound} onChange={value => setSelectedRound(value)} />
          </Box>
        ) : (
          <Select
            label={intl.formatMessage({ id: 'stage' })}
            options={stageOptions.map(name => {
              return { label: formatTournamentRound(name, intl), value: name }
            })}
            value={{ label: selectedStage, value: selectedStage }}
            onChange={option => {
              setSelectedStage(option.label)
            }}
            w="100%"
            disableClear
          />
        )}
      </Flex>

      <Flex justify="center" gapX={16} px={40} py="xl">
        <Button variant="outlined" fullWidth onClick={handleClose}>
          <FormattedMessage id="cancel" />
        </Button>

        <Button variant="contained" fullWidth onClick={handleConfirm} isLoading={isSaving}>
          <FormattedMessage id="confirm" />
        </Button>
      </Flex>
    </Flex>
  )
}

export default UpdateRoundForm
