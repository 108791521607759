import { useCallback } from 'react'
import { Text } from '@sofascore/ui'
import { useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { useToast } from 'shared/lib'
import { useErrorHandler } from 'shared/hooks'

import { sendEmail } from 'api'
import { getAuthFromState } from 'store/selectors'
import ResponsivePopUp from 'components/ResponsivePopUp'
import { AddNewCompetitionData } from 'modules/MyCompetitions/interface'

import AddNewCompetitionForm from './AddNewCompetitionForm'

export interface Props {
  handleClose: () => void
  isOpen: boolean
}

const AddNewCompetition = ({ handleClose, isOpen }: Props) => {
  const { userAccount } = useSelector(getAuthFromState)
  const { enqueueToast } = useToast()
  const intl = useIntl()
  const handleError = useErrorHandler()

  const onSubmit = useCallback(
    (requestAccessData: AddNewCompetitionData) => {
      return new Promise<boolean>((resolve, reject) => {
        if (userAccount) {
          sendEmail(requestAccessData)
            .then(() => {
              enqueueToast(
                `${intl.formatMessage({ id: 'requestSent' })} ${intl.formatMessage({ id: 'suggestionSentMessage' })}`,
                { variant: 'success' },
              )
              resolve(true)
            })
            .catch(e => {
              handleError(e)
              reject(e)
            })
        }
      })
    },
    [userAccount, intl, enqueueToast, handleError],
  )

  return (
    <ResponsivePopUp
      isOpen={isOpen}
      onClose={handleClose}
      heading={
        <Text font="display.large">
          <FormattedMessage id="request_access" />
        </Text>
      }
    >
      <AddNewCompetitionForm
        onSubmit={onSubmit}
        onClose={handleClose}
        initialValues={{ email: userAccount?.email || '' }}
      />
    </ResponsivePopUp>
  )
}

export default AddNewCompetition
