import { Text } from '@sofascore/ui'
import { FormattedMessage, useIntl } from 'react-intl'
import { PropsWithChildren } from 'react'
import IconSubstitutionInjured from '@sofascore/ui/dist/modules/Icons/_IconSubstitutionInjured'

import { Incident } from '../../model'
import { IncidentBody, IncidentFrame, IncidentHead, IncidentTail, IncidentTime } from '../'

interface Props {
  incident: Incident
}

export const IncidentSubstitutionInjury = ({ children, incident }: PropsWithChildren<Props>) => {
  const { isHome, time, addedTime, playerIn, playerOut } = incident

  const intl = useIntl()

  return (
    <IncidentFrame>
      <IncidentHead isHome={isHome}>
        <IconSubstitutionInjured fill="secondary.default" width="24" height="24" />
        <IncidentTime addedTime={addedTime} time={time} />
      </IncidentHead>

      <IncidentBody isHome={isHome}>
        <Text align={isHome ? 'left' : 'right'} font="body.medium">
          {playerIn?.name && `${intl.formatMessage({ id: 'in' })}: ${playerIn?.name}`}
          {!playerIn?.name &&
            playerOut?.name &&
            `${intl.formatMessage({ id: 'in' })}: ${intl.formatMessage({ id: 'unknown' })}`}
          {!playerIn?.name && !playerOut?.name && <FormattedMessage id="substitute_reason_injury" />}
        </Text>
        {(playerIn?.name || playerOut?.name) && (
          <Text align={isHome ? 'left' : 'right'} font="body.small" color="onSurface.nLv3">
            <FormattedMessage id="out" />: {playerOut?.name || <FormattedMessage id="unknown" />}
          </Text>
        )}
      </IncidentBody>

      <IncidentTail> {children} </IncidentTail>
    </IncidentFrame>
  )
}
