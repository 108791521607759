import { Autocomplete, Box, Button, Flex, Text } from '@sofascore/ui'
import DateTimePicker from '@sofascore/ui/dist/modules/DateTimePicker'
import { FieldArray, Formik } from 'formik'
import { FormattedDate, useIntl } from 'react-intl'
import { AnimatePresence, motion } from 'framer-motion'
import { repeaterItemAnimationConfig } from 'shared/config'
import IconClose from '@sofascore/ui/dist/modules/Icons/_IconClose'
import { SearchTeamResponse, TeamImage } from 'entities/Team'
import useSWR from 'swr'
import { BasicSeasonsResponse } from 'entities/UniqueTournament'
import { useParams } from 'react-router-dom'
import { useMemo } from 'react'
import { scaleTime } from 'd3-scale'
import { useQuerySearch } from 'entities/Search/hooks'

import { useMyCompetitionsState } from 'modules/MyCompetitions/providers/MyCompetitionsProvider'
import { teamSearch, uniqueTournamentSeasons } from 'api/routes'

import { SeasonGeneralFormSchema } from '../schema'
import * as S from './../../styles'

export const AdminInfoSeason = () => {
  const intl = useIntl()
  const { competitionId, seasonId } = useParams()
  const { competitionsWithSeasons } = useMyCompetitionsState()

  const { data: seasonsResponse } = useSWR<BasicSeasonsResponse>(uniqueTournamentSeasons(Number(competitionId)))

  const {
    results: teamsSearchResults,
    handleQueryChange: handleTeamsQueryChange,
    noOptionsText: teamsNoOptionsText,
  } = useQuerySearch<SearchTeamResponse>(2, teamSearch)

  const selectedSeason = competitionsWithSeasons?.find(
    competition => competition.season.id === Number(seasonId),
  )?.season

  const parseInitialValues = useMemo(() => {
    if (!competitionId) return {}
    return {
      season: selectedSeason ? { value: selectedSeason.id, label: selectedSeason.name } : undefined,
      startDate: selectedSeason?.startDateTimestamp ? new Date(selectedSeason.startDateTimestamp * 1000) : undefined,
      endDate: selectedSeason?.endDateTimestamp ? new Date(selectedSeason.endDateTimestamp * 1000) : undefined,
      lowerDivisionIds: [],
      upperDivisionIds: [],
      otherIds: [],
    }
  }, [competitionId, selectedSeason])

  const seasonPercentage = useMemo(() => {
    const start = selectedSeason?.startDateTimestamp
    const end = selectedSeason?.endDateTimestamp
    if (!selectedSeason || !start || !end) return 0
    const scale = scaleTime().range([0, 100])
    const now = Date.now() / 1000
    scale.domain([start, end])
    const percentage = scale(now)
    return percentage < 0 ? 0 : percentage
  }, [selectedSeason])

  return (
    <Formik
      initialValues={SeasonGeneralFormSchema.cast(parseInitialValues)}
      validationSchema={SeasonGeneralFormSchema}
      onSubmit={values => {
        alert(JSON.stringify(values, null, 2))
      }}
      enableReinitialize
    >
      {formik => (
        <Flex direction="column" bg="surface.s1" elevation={2} br="sm" mt="sm" p="lg">
          <Text fontWeight="bold" fontSize="medium" mb="lg">
            Season
          </Text>
          <Box mb="xl" w={['100%', '50%']} mr={['0', 'lg']}>
            <Autocomplete
              label={intl.formatMessage({ id: 'select_season' })}
              onChange={value => formik.setFieldValue('season', value)}
              value={formik?.values?.season}
              iconColor="onSurface.nLv1"
              clearable={false}
              options={
                seasonsResponse?.seasons?.map(season => ({
                  value: season.id,
                  label: season.name,
                })) || []
              }
            />
          </Box>
          <Flex direction="column" bg="surface.s2" elevation={2} br="sm" p="lg" mb="lg">
            <Text fontWeight="bold" fontSize="medium" mb="lg">
              Duration
            </Text>
            <Box h="4" w="100%" br="2px" bg="onSurface.nLv4" position="relative" mb="sm" overflow="hidden">
              {selectedSeason?.startDateTimestamp && selectedSeason?.endDateTimestamp && (
                <Box h="4" w={`${seasonPercentage}%`} bg="primary.default" />
              )}
            </Box>
            <Flex direction="row" justify="space-between" mb="lg">
              <Text font="body.small" color="onSurface.nLv3">
                {selectedSeason?.startDateTimestamp && (
                  <FormattedDate value={selectedSeason?.startDateTimestamp * 1000} day="numeric" month="short" />
                )}
              </Text>
              <Text font="body.small" color="onSurface.nLv3">
                {selectedSeason?.endDateTimestamp && (
                  <FormattedDate value={selectedSeason?.endDateTimestamp * 1000} day="numeric" month="short" />
                )}
              </Text>
            </Flex>
            <Flex style={{ gap: '16px' }} wrap={['wrap', 'nowrap']}>
              <DateTimePicker
                value={formik?.values?.startDate}
                label={intl.formatMessage({ id: 'startDate' })}
                onChange={value => formik.setFieldValue('start_date', value)}
                w="100%"
                time={false}
                bg="surface.s1"
              />
              <DateTimePicker
                value={formik?.values?.endDate}
                label={intl.formatMessage({ id: 'endDate' })}
                onChange={value => formik.setFieldValue('start_date', value)}
                w="100%"
                time={false}
                bg="surface.s1"
              />
            </Flex>
          </Flex>
          <Flex direction="column" bg="surface.s2" elevation={2} br="sm" p="lg" mb="lg">
            <Text fontWeight="bold" fontSize="medium" mb="lg">
              New Teams
            </Text>
            <Flex style={{ gap: '16px' }} wrap={['wrap', 'nowrap']}>
              <Box w="100%" position="relative">
                <FieldArray
                  name="lowerDivisionIds"
                  render={arrayHelpers => (
                    <Box w="100%" position="relative" mb="lg">
                      <S.SearchAdornment w={24} h={24} fill="onSurface.nLv1" />
                      <Autocomplete
                        label={intl.formatMessage({ id: 'newcomers_from_lower_division' })}
                        onChange={value => arrayHelpers.push(value)}
                        iconColor="onSurface.nLv1"
                        filterOptions={x => !!x}
                        emptyOnChange
                        clearable={false}
                        noOptionsText={teamsNoOptionsText}
                        onQueryChange={handleTeamsQueryChange}
                        options={teamsSearchResults}
                        inputProps={{
                          bg: 'surface.s1',
                        }}
                      />
                      <AnimatePresence initial={false}>
                        {formik.values.lowerDivisionIds.map?.((value, index) => (
                          <motion.div key={index} {...repeaterItemAnimationConfig}>
                            <S.DivisionItem elevation={1}>
                              <S.DivisionItemLabel>
                                {value.value && (
                                  <TeamImage
                                    team={{
                                      id: value.value,
                                      name: value.label || '',
                                    }}
                                    size={16}
                                  />
                                )}
                                <S.DivisionItemLabelText>{value.label}</S.DivisionItemLabelText>
                              </S.DivisionItemLabel>
                              <S.DivisionItemClear type="button" onClick={() => arrayHelpers.remove(index)}>
                                <IconClose fill="onSurface.nLv1" width={16} height={16} />
                              </S.DivisionItemClear>
                            </S.DivisionItem>
                          </motion.div>
                        ))}
                      </AnimatePresence>
                    </Box>
                  )}
                />
                <FieldArray
                  name="otherIds"
                  render={arrayHelpers => (
                    <Box w="100%" position="relative">
                      <S.SearchAdornment w={24} h={24} fill="onSurface.nLv1" />
                      <Autocomplete
                        label={intl.formatMessage({ id: 'other_newcomers' })}
                        onChange={value => arrayHelpers.push(value)}
                        iconColor="onSurface.nLv1"
                        filterOptions={x => !!x}
                        emptyOnChange
                        clearable={false}
                        noOptionsText={teamsNoOptionsText}
                        onQueryChange={handleTeamsQueryChange}
                        options={teamsSearchResults}
                        inputProps={{
                          bg: 'surface.s1',
                        }}
                      />
                      <AnimatePresence initial={false}>
                        {formik.values.otherIds.map?.((value, index) => (
                          <motion.div key={index} {...repeaterItemAnimationConfig}>
                            <S.DivisionItem elevation={1}>
                              <S.DivisionItemLabel>
                                {value.value && (
                                  <TeamImage
                                    team={{
                                      id: value.value,
                                      name: value.label || '',
                                    }}
                                    size={16}
                                  />
                                )}
                                <S.DivisionItemLabelText>{value.label}</S.DivisionItemLabelText>
                              </S.DivisionItemLabel>
                              <S.DivisionItemClear type="button" onClick={() => arrayHelpers.remove(index)}>
                                <IconClose fill="onSurface.nLv1" width={16} height={16} />
                              </S.DivisionItemClear>
                            </S.DivisionItem>
                          </motion.div>
                        ))}
                      </AnimatePresence>
                    </Box>
                  )}
                />
              </Box>
              <FieldArray
                name="upperDivisionIds"
                render={arrayHelpers => (
                  <Box w="100%" position="relative">
                    <S.SearchAdornment w={16} h={16} fill="onSurface.nLv1" />
                    <Autocomplete
                      label={intl.formatMessage({ id: 'newcomers_from_upper_division' })}
                      onChange={value => arrayHelpers.push(value)}
                      emptyOnChange
                      clearable={false}
                      filterOptions={x => !!x}
                      noOptionsText={teamsNoOptionsText}
                      onQueryChange={handleTeamsQueryChange}
                      options={teamsSearchResults}
                      inputProps={{
                        bg: 'surface.s1',
                      }}
                    />
                    <AnimatePresence initial={false}>
                      {formik.values.upperDivisionIds.map?.((value, index) => (
                        <motion.div key={index} {...repeaterItemAnimationConfig}>
                          <S.DivisionItem elevation={1}>
                            <S.DivisionItemLabel>
                              {value.value && (
                                <TeamImage
                                  team={{
                                    id: value.value,
                                    name: value.label || '',
                                  }}
                                  size={16}
                                />
                              )}
                              <S.DivisionItemLabelText>{value.label}</S.DivisionItemLabelText>
                            </S.DivisionItemLabel>
                            <S.DivisionItemClear type="button" onClick={() => arrayHelpers.remove(index)}>
                              <IconClose fill="onSurface.nLv1" width={16} height={16} />
                            </S.DivisionItemClear>
                          </S.DivisionItem>
                        </motion.div>
                      ))}
                    </AnimatePresence>
                  </Box>
                )}
              />
            </Flex>
          </Flex>
          <Flex justify="end">
            <Button>Save</Button>
          </Flex>
        </Flex>
      )}
    </Formik>
  )
}
