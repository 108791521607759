import { FormattedMessage } from 'react-intl'
import { useCallback, useState } from 'react'
import { Flex, Text, Box, Spinner } from '@sofascore/ui'
import { useEventState } from 'entities/Event'
import IconAccessTimeOutline from '@sofascore/ui/dist/modules/Icons/IconAccessTimeOutline'
import IconCreate from '@sofascore/ui/dist/modules/Icons/IconCreate'
import IconDownload from '@sofascore/ui/dist/modules/Icons/IconDownload'
import IconAdd from '@sofascore/ui/dist/modules/Icons/IconAdd'
import IconForwardEmail from '@sofascore/ui/dist/modules/Icons/IconForwardEmail'
import useSWR from 'swr'
import { useParams } from 'react-router-dom'
import { StatusType } from 'entities/Status'
import { usePermissions } from 'shared/hooks'
import { PermissionAction, PermissionScope } from 'entities/Permission'
import { PermissionGuard } from 'shared/ui'
import { FirebaseEventName } from 'shared/lib/firebase/model'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'
import { useSelector } from 'react-redux'

import { selectLocale } from 'store/selectors'
import { getAbsoluteUrl, getAuthToken } from 'api/utilities'
import { downloadMatchReport, getMatchReport } from 'api/routes'
import ResponsivePopUp from 'components/ResponsivePopUp'
import { formatDate, formatTime, getDate } from 'utils/time'

import * as S from '../../styles'
import { UpdateConfigForm } from './UpdateConfigForm'
import { MatchReportAction, MatchReportResponse, MatchReportStatus } from '../../model'
import { SendEmailForm } from './SendEmailForm'

export function MatchReportDownload() {
  const locale = useSelector(selectLocale)
  const { eventId } = useParams()
  const token = getAuthToken()
  const [configModal, setConfigModal] = useState(false)
  const [emailModal, setEmailModal] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const { guardScope, guardEntity } = usePermissions()
  const { event } = useEventState()
  const canCreateMatchReport = guardEntity(event.allowedActions, [PermissionAction.Create], PermissionScope.MatchReport)
  const hasFinished = [StatusType.FINISHED, StatusType.INTERRUPTED].includes(event.status.type)
  const {
    tournament: {
      category: {
        sport: { slug: sportSlug },
      },
    },
  } = event

  const { data, isLoading, mutate } = useSWR<MatchReportResponse>(eventId ? getMatchReport(+eventId) : null)

  const handleDownloadMatchReport = useCallback(async () => {
    if (!eventId || downloading) return

    setDownloading(true)
    try {
      const baseUrl = downloadMatchReport(event.id)
      const absoluteUrl = getAbsoluteUrl(baseUrl)

      // Log the download event
      logFirebaseEvent(FirebaseEventName.MatchReport, {
        id: Number(eventId),
        action: MatchReportAction.Download,
        sport: sportSlug,
        status: hasFinished ? MatchReportStatus.PostMatch : MatchReportStatus.PreMatch,
      })

      // Fetch the match report
      const timeZoneOffset = new Date().getTimezoneOffset() * 60 * -1
      const response = await fetch(`${absoluteUrl}?timezoneOffset=${String(timeZoneOffset)}&locale=${locale}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      // Handle response and trigger download
      if (response.ok) {
        const blob = await response.blob()
        const url = URL.createObjectURL(blob)
        const { tournament, homeTeam, awayTeam, startTimestamp } = event
        const { uniqueTournament } = tournament

        // Create a temporary anchor element and initiate download
        const date = new Date(startTimestamp * 1000).toLocaleDateString()
        const fileName = `match-report-${uniqueTournament?.slug}-${tournament.slug}-${homeTeam.slug}-${awayTeam.slug}-${date}.pdf`
        const link = document.createElement('a')
        link.href = url
        link.download = fileName
        document.body.appendChild(link)
        link.click()

        // Clean up
        URL.revokeObjectURL(url)
        document.body.removeChild(link)
      } else {
        console.error('Failed to download the report')
      }
    } catch (error) {
      console.error('An error occurred during download:', error)
    } finally {
      setDownloading(false)
    }
  }, [event, eventId, hasFinished, setDownloading, sportSlug, downloading, token, locale])

  const matchReportCreated = data?.matchReportData

  if (!guardScope([PermissionScope.MatchReport]) || (!canCreateMatchReport && !matchReportCreated)) return null

  return (
    <Flex
      justify="space-between"
      align="center"
      px="lg"
      py="md"
      role="button"
      gapX="md"
      bg="surface.s1"
      br="sm"
      mb="sm"
      elevation={1}
    >
      <Box>
        <Text font="display.medium">
          <FormattedMessage id={hasFinished ? 'match_report' : 'pre_match_report'} />
        </Text>
        {matchReportCreated?.updatedAtTimestamp && (
          <Flex align="center" mt="xs" display={['flex', 'none']}>
            <IconAccessTimeOutline mr="xs" w={16} h={16} fill="onSurface.nLv3" />
            <Text font="display.micro" color="onSurface.nLv3">
              {formatDate(getDate(matchReportCreated.updatedAtTimestamp), { format: 'dd/mm/yyyy' })}{' '}
              {formatTime(getDate(matchReportCreated.updatedAtTimestamp))}
            </Text>
          </Flex>
        )}
      </Box>

      <Flex gapX="lg">
        {matchReportCreated?.updatedAtTimestamp && (
          <Flex align="center" mt="xs" display={['none', 'flex']}>
            <IconAccessTimeOutline mr="xs" w={16} h={16} fill="onSurface.nLv3" />
            <Text font="display.micro" color="onSurface.nLv3">
              {formatDate(getDate(matchReportCreated.updatedAtTimestamp), { format: 'dd/mm/yyyy' })}{' '}
              {formatTime(getDate(matchReportCreated.updatedAtTimestamp))}
            </Text>
          </Flex>
        )}
        <S.MatchReportActionHolder gapX="sm">
          {isLoading ? (
            <S.MatchReportActionButton>
              <Spinner />
            </S.MatchReportActionButton>
          ) : !matchReportCreated ? (
            <S.MatchReportActionButton onClick={() => setConfigModal(true)}>
              <IconAdd />
            </S.MatchReportActionButton>
          ) : (
            <>
              <S.MatchReportActionButton onClick={() => setEmailModal(true)}>
                <IconForwardEmail />
              </S.MatchReportActionButton>
              <PermissionGuard
                entityAllowedActions={event.allowedActions}
                checkAllowedActions={[PermissionAction.Update]}
                entityAllowedActionsScope={PermissionScope.MatchReport}
              >
                <S.MatchReportActionButton onClick={() => setConfigModal(true)}>
                  <IconCreate />
                </S.MatchReportActionButton>
              </PermissionGuard>
              <S.MatchReportActionButton onClick={handleDownloadMatchReport}>
                {downloading ? <Spinner /> : <IconDownload />}
              </S.MatchReportActionButton>
            </>
          )}
        </S.MatchReportActionHolder>
      </Flex>

      <ResponsivePopUp
        isOpen={configModal}
        onClose={() => setConfigModal(false)}
        heading={
          <Text font="display.large">
            {matchReportCreated ? (
              <FormattedMessage id="edit_match_report" />
            ) : (
              <FormattedMessage id="create_match_report" />
            )}
          </Text>
        }
        portalsContainerId="match-properties-container"
      >
        <UpdateConfigForm
          mutate={mutate}
          initialData={matchReportCreated}
          setConfigModal={setConfigModal}
          matchReportCreated={!!matchReportCreated}
        />
      </ResponsivePopUp>

      <ResponsivePopUp
        isOpen={emailModal}
        onClose={() => setEmailModal(false)}
        heading={
          <Text font="display.large">
            <FormattedMessage id="send_report_to_email" />
          </Text>
        }
        portalsContainerId="match-properties-container"
      >
        <SendEmailForm mutate={mutate} setEmailModal={setEmailModal} />
      </ResponsivePopUp>
    </Flex>
  )
}
