import { Box, Flex, Text } from '@sofascore/ui'
import { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { IncidentType, IncidentGoalType, CreateIncidentData, useEventIncidentsState } from 'entities/Incident'
import { BasicEvent } from 'entities/Event'
import { BasicTeam } from 'entities/Team'
import { getNextResult } from 'features/AddScore'
import { useTeamPlayerOptions, PlayerOption, renderPlayerOption, renderSelectedPlayerOption } from 'entities/Player'

import { Option } from 'components/DropdownMenu/interface'
import IncidentBox from 'components/IncidentBox'
import WheelPicker from 'components/WheelPicker/WheelPicker'
import { Button } from 'components/Button'
import {
  formatMatchTimeToOptionValue,
  formatTimeOptionValueToMatchTime,
  generateFootballGoalMatchTimeOptionsAfter,
  getCurrentMatchTime,
  isAfter,
} from 'utils/matchTime'
import { getLastGoalsFromIncidents, getIncrementedScore } from 'modules/MiniFootball/helper'
import { Select } from 'components/Select'

import { AddScoreModal } from '../../AddScoreModal'
import { goalTypeOptions } from '../config'

interface Props {
  isOpen: boolean
  event: BasicEvent
  team: BasicTeam
  onSubmit: (arg0: CreateIncidentData) => Promise<void>
  onClose: () => void
}

export const AddMiniFootballGoalForm = ({ isOpen, event, team, onSubmit, onClose }: Props) => {
  const { incidents } = useEventIncidentsState()
  const intl = useIntl()

  const currentMatchTime = getCurrentMatchTime(event, 0)

  const [time, setTime] = useState({ time: formatMatchTimeToOptionValue(currentMatchTime) })
  const [isSaving, setIsSaving] = useState(false)
  const [goalType, setGoalType] = useState<IncidentGoalType>(IncidentGoalType.GoalRegular)

  const [scorer, setScorer] = useState<PlayerOption | undefined>()
  const [assist, setAssist] = useState<PlayerOption | undefined>()

  const { id: eventId, homeTeam, awayTeam, homeScore, awayScore } = event
  const isHomeTeam = homeTeam.id === team.id
  const isOwnGoal = goalType === IncidentGoalType.OwnGoal
  const _teamId = isOwnGoal ? (isHomeTeam ? awayTeam.id : homeTeam.id) : isHomeTeam ? homeTeam.id : awayTeam.id

  const [scorerOptions, assistOptions] = useTeamPlayerOptions(_teamId, scorer, assist)

  const timeOptions = useMemo(() => {
    const [lastGoal] = incidents ? getLastGoalsFromIncidents(incidents) : []
    return generateFootballGoalMatchTimeOptionsAfter(event, lastGoal, 0)
  }, [event, incidents])

  const handleConfirm = async () => {
    setIsSaving(true)

    const incidentTime = formatTimeOptionValueToMatchTime(time.time)
    const newScore = getIncrementedScore(homeScore, awayScore, isHomeTeam)

    onSubmit({
      eventId,
      incidentData: {
        incidentType: IncidentType.Goal,
        isHome: isHomeTeam,
        time: incidentTime.time,
        addedTime: incidentTime.addedTime,
        incidentClass: goalType,
        player: scorer ? scorer.value : undefined,
        assist1: assist ? assist.value : undefined,
        homeScore: newScore.homeScore,
        awayScore: newScore.awayScore,
      },
    })
      .then(() => onClose())
      .finally(() => setIsSaving(false))
  }

  useEffect(() => {
    // If an editor adds a goal in the future (which is not possible),
    // we need to set the initial time in WheelPicker to when last goal
    // happened, because current time won't be available in options and
    // the there won't be any preselected value (it will look disabled)
    const [lastGoal] = incidents ? getLastGoalsFromIncidents(incidents) : []

    if (lastGoal) {
      const lastGoalTime = { time: lastGoal.time!, addedTime: lastGoal.addedTime }
      const currentTime = formatTimeOptionValueToMatchTime(time.time)

      if (isAfter(lastGoalTime, currentTime)) {
        setTime({ time: formatMatchTimeToOptionValue(lastGoalTime) })
      }
    }
  }, [incidents, time.time])

  return (
    <AddScoreModal
      isOpen={isOpen}
      onClose={onClose}
      title={<FormattedMessage id="add_goal" />}
      team={team}
      event={event}
      nextHomeScore={getNextResult(homeScore, true, isHomeTeam)}
      nextAwayScore={getNextResult(awayScore, false, isHomeTeam)}
    >
      <Box px="sm" pb="xxl">
        <Box px="sm" pt="lg" pb="sm" mb="lg" br="sm" bg="surface.s2">
          <Flex justify="center" mb="lg">
            <Text font="display.small">
              <FormattedMessage id="confirm_time" />
            </Text>
          </Flex>
          <Box>
            <WheelPicker
              columnsOptions={{ time: timeOptions as Option[] }}
              columnsValues={time}
              onChange={(_name, option) => {
                setTime({ time: option as string })
              }}
              height={100}
            />
          </Box>
        </Box>

        <Box px="sm" pt="lg" pb="xs" br="sm" bg="surface.s2">
          <Flex mb="xl" justify="center">
            <Text font="display.small">
              <FormattedMessage id="goal_type_and_scorer" />
            </Text>
          </Flex>

          <Flex mb="lg" px="xxl" justify="center">
            {goalTypeOptions.map((option, index) => (
              <IncidentBox
                key={index}
                label={intl.formatMessage({ id: option.translationKey })}
                isActive={goalType === option.goalType}
                onClick={() => {
                  setGoalType(option.goalType)
                  setScorer(undefined)
                  setAssist(undefined)
                }}
                icon={option.icon}
                mx="xs"
              />
            ))}
          </Flex>

          <Select
            label={intl.formatMessage({ id: 'player' })}
            options={scorerOptions}
            helperText="Optional"
            value={scorer}
            onChange={option => setScorer(option)}
            maxVisibleOptions={6}
            w="100%"
            selectBackgroundColor="surface.s1"
            renderOption={renderPlayerOption}
            renderSelectedOption={renderSelectedPlayerOption}
          />

          {goalType === IncidentGoalType.GoalRegular && (
            <Select
              label={intl.formatMessage({ id: 'assist' })}
              options={assistOptions}
              helperText="Optional"
              value={assist}
              onChange={option => setAssist(option)}
              maxVisibleOptions={6}
              w="100%"
              selectBackgroundColor="surface.s1"
              renderOption={renderPlayerOption}
              renderSelectedOption={renderSelectedPlayerOption}
            />
          )}
        </Box>

        <Flex gapX="lg" px="lg" mt="xxl">
          <Button variant="outlined" fullWidth onClick={onClose}>
            <FormattedMessage id="cancel" />
          </Button>
          <Button variant="contained" fullWidth onClick={handleConfirm} isLoading={isSaving}>
            <FormattedMessage id="confirm" />
          </Button>
        </Flex>
      </Box>
    </AddScoreModal>
  )
}
