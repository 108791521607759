import { postJson } from 'api/utilities'
import { NewTeamsData } from 'modules/Teams/interface'

import { addExistingTeams } from './routes'

export const addTeams = (
  uniqueTournamentId: number,
  tournamentId: number,
  seasonId: number,
  newTeamsData: NewTeamsData,
) => postJson(addExistingTeams(uniqueTournamentId, tournamentId, seasonId), { ...newTeamsData })
