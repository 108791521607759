import { Box, Flex, Text } from '@sofascore/ui'
import { StatusType, NO_TIMER_CODES, getStatusDescription, StatusCode, SPECIAL_STATUSES } from 'entities/Status'
import { useEventState } from 'entities/Event'

import { ProgressTimer } from 'components/Timekeeping'
import { ADDITIONAL_TIME_DISPLAY_LIMIT } from 'utils/matchTime'
import { RED } from 'styles/color'

const ScoreSection = ({ hasTimer }: { hasTimer?: boolean }) => {
  const { event, initialEvent } = useEventState()
  const { status, time, homeScore, awayScore, winnerCode } = initialEvent

  const isLive = status.type === StatusType.IN_PROGRESS

  const isLeftWin = winnerCode === 1
  const isRightWin = winnerCode === 2
  const isPostponedOrCanceled = [StatusCode.Postponed, StatusCode.Canceled].includes(status.code)
  const isSpecial = SPECIAL_STATUSES.includes(status.code)

  const homeScoreColor = isLive ? 'primary.default' : isLeftWin ? 'onSurface.nLv1' : 'onSurface.nLv3'
  const awayScoreColor = isLive ? 'primary.default' : isRightWin ? 'onSurface.nLv1' : 'onSurface.nLv3'

  return (
    <Flex direction="column" align="center">
      {homeScore.display !== undefined && !isPostponedOrCanceled && (
        <Box>
          <Text fontSize="extraLarge" fontWeight="bold" color={homeScoreColor}>
            {homeScore.display}
          </Text>
          <Text fontSize="extraLarge" fontWeight="bold" color={isLive ? 'primary.default' : 'onSurface.nLv3'}>
            {' - '}
          </Text>
          <Text fontSize="extraLarge" fontWeight="bold" color={awayScoreColor}>
            {awayScore.display}
          </Text>
        </Box>
      )}

      {isLive && hasTimer && time && !NO_TIMER_CODES.includes(status.code) ? (
        <Text align="center" fontSize="small" fontWeight="medium" color={isLive ? 'primary.default' : 'onSurface.nLv1'}>
          <ProgressTimer event={event} additionalTimeDisplayLimit={ADDITIONAL_TIME_DISPLAY_LIMIT} />
        </Text>
      ) : (
        <Text
          align="center"
          fontSize="small"
          fontWeight="medium"
          color={isLive ? 'primary.default' : isSpecial ? RED.incident : 'onSurface.nLv1'}
        >
          {getStatusDescription(initialEvent)}
        </Text>
      )}
    </Flex>
  )
}

export default ScoreSection
