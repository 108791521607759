import { Box } from '@sofascore/ui'
import IconFootballBall from '@sofascore/ui/dist/modules/Icons/_IconFootballBall'
import IconFootballPenalty from '@sofascore/ui/dist/modules/Icons/_IconFootballPenalty'
import IconFootballOwnGoal from '@sofascore/ui/dist/modules/Icons/_IconFootballOwnGoal'
import IconCardYellow from '@sofascore/ui/dist/modules/Icons/_IconCardYellow'
import IconCardDoubleYellow from '@sofascore/ui/dist/modules/Icons/_IconCardDoubleYellow'
import IconCardRed from '@sofascore/ui/dist/modules/Icons/IconCardRed'
import IconPenaltyMissed from '@sofascore/ui/dist/modules/Icons/_IconPenaltyMissed'
import IconSubstitution from '@sofascore/ui/dist/modules/Icons/_IconSubstitution'
import IconSubstitutionInjured from '@sofascore/ui/dist/modules/Icons/_IconSubstitutionInjured'
import IconTwoMinuteSuspension from '@sofascore/ui/dist/modules/Icons/_Icon2MinuteSuspension'
import IconAccumulatedPenalty from '@sofascore/ui/dist/modules/Icons/_IconAccumulatedPenalty'
import {
  IncidentType,
  IncidentGoalType,
  IncidentCardType,
  IncidentInGamePenaltyType,
  IncidentSubstitutionType,
  IncidentPenaltyShootoutType,
  IncidentSuspensionType,
} from 'entities/Incident'
import { IconConversion, IconPenaltyTry, IconRugbyPenalty, IconTry } from 'shared/ui'

import { RED, GREEN } from 'styles/color'

type Props = {
  incidentType: string
  incidentClass?: string
}

export const iconsMap = {
  [IncidentType.Goal]: {
    [IncidentGoalType.GoalRegular]: <IconFootballBall fill={GREEN.incident} />,
    [IncidentGoalType.GoalFromPenalty]: <IconFootballPenalty fill={GREEN.incident} />,
    [IncidentGoalType.AccumulatedPenalty]: <IconAccumulatedPenalty fill={GREEN.incident} />,
    [IncidentGoalType.OwnGoal]: <IconFootballOwnGoal fill={RED.incident} />,
    [IncidentGoalType.Try]: <IconTry fill={GREEN.incident} />,
    [IncidentGoalType.Conversion]: <IconConversion fill={GREEN.incident} />,
    [IncidentGoalType.DropGoal]: <IconRugbyPenalty fill={GREEN.incident} />,
    [IncidentGoalType.PenaltyTry]: <IconPenaltyTry fill={GREEN.incident} />,
  },
  [IncidentType.Card]: {
    [IncidentCardType.Yellow]: <IconCardYellow width="24" height="24" />,
    [IncidentCardType.YellowRed]: <IconCardDoubleYellow width="24" height="24" />,
    [IncidentCardType.Red]: <IconCardRed width="24" height="24" />,
  },
  [IncidentType.InGamePenalty]: {
    [IncidentInGamePenaltyType.Missed]: <IconPenaltyMissed fill={RED.incident} />,
  },
  [IncidentType.Substitution]: {
    [IncidentSubstitutionType.Injury]: <IconSubstitutionInjured fill="secondary.default" width="24" height="24" />,
    [IncidentSubstitutionType.Regular]: <IconSubstitution fill="secondary.default" width="24" height="24" />,
  },
  [IncidentType.PenaltyShootout]: {
    [IncidentPenaltyShootoutType.Missed]: <IconPenaltyMissed fill={RED.incident} />,
    [IncidentPenaltyShootoutType.Scored]: <IconFootballPenalty fill={GREEN.incident} />,
  },
  [IncidentType.Suspension]: {
    [IncidentSuspensionType.TwoMinutes]: <IconTwoMinuteSuspension />,
  },
}

export const IncidentIcon = ({ incidentType, incidentClass }: Props) => {
  const Icon = incidentType && iconsMap[incidentType] ? iconsMap[incidentType][incidentClass] : null

  return Icon ? Icon : <Box w="24" />
}
