import { useRef } from 'react'
import { useDrop, useDrag, DragSourceMonitor } from 'react-dnd'
import type { Identifier, XYCoord } from 'dnd-core'
import { Box, Flex, Image, Table, Text, useMediaContext } from '@sofascore/ui'

import { IconDragAndDrop } from 'components/Icons/IconDragAndDrop'
import { COLUMNS_BY_SPORT } from 'modules/StandingsRedesign/config'
import { teamImage } from 'api/routes'

import { BaseTableRowProps, DragItem, DraggableTableRowProps } from './interface'

const DND_TYPE_ROW = 'DND_TYPE_ROW'

export const TableRowCells = ({ row, sport, isEditMode }: BaseTableRowProps) => {
  const { isMobile } = useMediaContext()

  return (
    <>
      {COLUMNS_BY_SPORT[sport].map(({ shortKey, textAlign, resolveValue }) => {
        const isTeamCol = shortKey === 'Team'

        return (
          <Table.Cell
            key={shortKey}
            style={{
              border: 'none',
              textAlign: textAlign || 'center',
              fontSize: 14,
              padding: '12px 8px',

              display: isTeamCol ? 'flex' : undefined,
              alignItems: isTeamCol ? 'center' : undefined,
            }}
          >
            {isTeamCol && !isMobile && (
              <Box h={24}>
                <Image
                  alt={row.team.name}
                  src={teamImage(row.team.id)}
                  fallback={<Image src="/images/placeholders/teamLogo.png" />}
                  width={24}
                  height={24}
                  mr="lg"
                />
              </Box>
            )}

            <Text>{resolveValue(row)}</Text>
          </Table.Cell>
        )
      })}

      {isEditMode && (
        <Table.Cell align="center" minW={24} border="none" style={{ position: 'relative', cursor: 'pointer' }}>
          <Flex
            position="absolute"
            top="50%"
            left="50%"
            align="center"
            justify="center"
            style={{ transform: 'translate(-50%, -50%)' }}
          >
            <IconDragAndDrop />
          </Flex>
        </Table.Cell>
      )}
    </>
  )
}

const DraggableTableRow = ({ row, sport, index, isEditMode, onMoveRow }: DraggableTableRowProps) => {
  const ref = useRef<HTMLTableRowElement>(null)

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: DND_TYPE_ROW,

    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },

    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      onMoveRow(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: DND_TYPE_ROW,

    item: () => {
      return { row, sport, index }
    },

    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  return (
    <Table.Row
      ref={ref}
      bg="surface.s1"
      cursor="pointer"
      style={{ opacity: isDragging ? 0 : 1 }}
      data-handler-id={handlerId}
    >
      <TableRowCells sport={sport} isDragging={isDragging} row={row} isEditMode={isEditMode} />
    </Table.Row>
  )
}

export default function AdaptiveTableRow({ row, sport, index, isEditMode, onMoveRow }: DraggableTableRowProps) {
  if (isEditMode) {
    return <DraggableTableRow row={row} sport={sport} index={index} isEditMode={isEditMode} onMoveRow={onMoveRow} />
  }

  return (
    <Table.Row bg="surface.s1">
      <TableRowCells sport={sport} row={row} isEditMode={isEditMode} />
    </Table.Row>
  )
}
