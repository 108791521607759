import * as React from 'react'

import { IconBase, IconProps } from './Base'

export const IconShirtEmpty = (props: IconProps) => (
  <IconBase width={28} height={32} viewBox="0 0 28 32" {...props}>
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="jknv23q5pc">
        <stop stopOpacity="0" offset="0%" />
        <stop stopOpacity=".1" offset="100%" />
      </linearGradient>
      <path
        d="M18.465.72 26 3l2 9.37-3 2V31a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-16.63l-3-2L2 3 9.535.721c.823.978 2.514 1.649 4.465 1.649s3.642-.671 4.465-1.65z"
        id="6j9f35x7fa"
      />
      <mask
        id="js6c1zouvb"
        maskContentUnits="userSpaceOnUse"
        maskUnits="objectBoundingBox"
        x="0"
        y="0"
        width="28"
        height="31.279"
        fill="#fff"
      >
        <use xlinkHref="#6j9f35x7fa" />
      </mask>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M0 0h28v32H0z" />
      <path
        d="M18.466.721 26 3l2 9.37-3 2V31a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V14.37l-3-2L2 3 9.534.721c.824.979 2.514 1.65 4.466 1.65 1.952 0 3.642-.671 4.466-1.65z"
        fillOpacity=".202"
        fill="#FFF"
      />
      <g>
        <path fill="none" d="M0 0h28v32H0z" />
        <use stroke="#FFF" mask="url(#js6c1zouvb)" strokeWidth="2" strokeDasharray="2" xlinkHref="#6j9f35x7fa" />
        <path d="M0 0h22v7a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V0z" transform="translate(3 24)" fill="url(#jknv23q5pc)" />
      </g>
    </g>
  </IconBase>
)
