import { useEffect } from 'react'

import useCallbackRef from 'components/ArrowSlider/useCallbackRef'

interface BidirectionalInfScrollProps {
  hasMoreLastEvents: boolean
  areLastEventsLoading: boolean
  loadMoreLastEvents: () => void
  hasMoreNextEvents: boolean
  areNextEventsLoading: boolean
  loadMoreNextEvents: () => void
}

const UPPER_SCROLL_PCT_THRESHOLD = 0
const LOWER_SCROLL_PCT_THRESHOLD = 1

export const useBidirectionalInfiniteScroll = ({
  hasMoreLastEvents,
  areLastEventsLoading,
  loadMoreLastEvents,
  hasMoreNextEvents,
  areNextEventsLoading,
  loadMoreNextEvents,
}: BidirectionalInfScrollProps) => {
  const [scroller, setScroller] = useCallbackRef([])

  useEffect(() => {
    if (scroller) {
      const handleEventListScroll = () => {
        const isTopThreshholdAchieved = scroller.scrollTop <= scroller.scrollHeight * UPPER_SCROLL_PCT_THRESHOLD
        if (hasMoreLastEvents && !areLastEventsLoading && isTopThreshholdAchieved) {
          loadMoreLastEvents?.()
        }
        const isBottomThreshholdAchieved =
          scroller.scrollTop + scroller.clientHeight >= scroller.scrollHeight * LOWER_SCROLL_PCT_THRESHOLD
        if (hasMoreNextEvents && !areNextEventsLoading && isBottomThreshholdAchieved) {
          loadMoreNextEvents()
        }
      }

      scroller.addEventListener('scroll', handleEventListScroll)

      return () => {
        scroller.removeEventListener('scroll', handleEventListScroll)
      }
    } else return
  }, [
    scroller,
    hasMoreLastEvents,
    areLastEventsLoading,
    loadMoreLastEvents,
    hasMoreNextEvents,
    areNextEventsLoading,
    loadMoreNextEvents,
  ])

  return {
    scroller,
    scrollerRef: (newRef: HTMLElement) => {
      if (newRef) setScroller(newRef)
    },
  }
}
