import { TeamImage } from 'entities/Team'
import { Flex, Text } from '@sofascore/ui'
import { Option } from 'shared/model'

export const TeamOption = ({ option }: { option: Option }) => {
  return (
    <Flex align="center" py="md" px="lg" gapX="lg">
      <TeamImage
        team={{
          id: Number(option?.value),
          name: option?.label || '',
        }}
        size={32}
      />
      <Text mr="lg">{option?.label}</Text>
    </Flex>
  )
}
