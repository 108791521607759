import { ApiResponse, CustomResponse, GetJsonOptions, BaseJsonOptions } from 'shared/api'

import store from 'store/store'
import { unauthorizedError } from 'store/auth/actions'

import { NEW_API_BASENAME, TOKEN_REFRESH_HEADER } from './constants'

const overriddenApiBase = () => {
  //  Get apiBaseBranch directly from local storage because persisted
  //  store is not available on react-router-dom Route loaders
  let apiBaseBranch = undefined
  try {
    const lsAPIBaseBranch = JSON.parse(localStorage?.getItem('persist:uicontrols') || '{}')?.apiBaseBranch?.slice(1, -1)
    if (lsAPIBaseBranch) apiBaseBranch = `https://${lsAPIBaseBranch}/api/v1`
  } catch (error) {
    console.error(error)
  }
  return apiBaseBranch
}

/**
 * Tests if provided `url` starts with `http` or `https`. Returns boolean result of a test.
 *
 * @param url URL to be tested
 */
const isAbsoluteUrl = (url: string) => url.match(/^https?:\/\//)

/**
 * Generates absolute URL to be used as link or image src. If provided URL is already absolute, returns provided URL.
 */
export const getAbsoluteUrl = (url: string) =>
  isAbsoluteUrl(url) ? url : `${overriddenApiBase() || NEW_API_BASENAME}${url}`

/**
 * Parses received response as JSON, if parsing fails, rejects promise with parsing error
 * object, if parsing is successful and response code is not in range of 200-399 then
 * reject is called with custom error object and if everything is fine then promise
 * is resolved with API JSON response
 */
export const parseResponse = <T = ApiResponse>(response: Response): Promise<T> => {
  return new Promise((resolve, reject) => {
    try {
      const shouldRefreshToken = response.headers.has(TOKEN_REFRESH_HEADER)

      if (response.status === 204) {
        resolve((shouldRefreshToken ? { shouldRefreshToken } : {}) as T)
      } else {
        response
          .json()
          .then((jsonResponse: CustomResponse) => {
            if (response.status >= 200 && response.status < 400) {
              resolve((shouldRefreshToken ? { ...jsonResponse, shouldRefreshToken } : jsonResponse) as T)
            } else {
              if (response.status === 401) {
                store.dispatch(unauthorizedError(jsonResponse))
              }
              reject(shouldRefreshToken ? { ...jsonResponse, shouldRefreshToken } : jsonResponse)
            }
          })
          .catch(reject)
      }
    } catch (error) {
      reject(error)
    }
  })
}

/**
 * Send GET request to given URL, with provided options, such as `isLegacyRoute` and `useCache`.
 */
export const getJson = async <T = ApiResponse>(url: string, options: GetJsonOptions = {}) => {
  const { useCache = false, ...fetchOptions } = options
  const token = getAuthToken()
  const reqHeaders = {
    ...(token && getAuthHeader(token)),
  }
  const absoluteUrl = getAbsoluteUrl(url)
  const rawResponse = await fetch(absoluteUrl, {
    cache: useCache ? 'default' : 'no-cache',
    ...fetchOptions,
    headers: {
      ...reqHeaders,
    },
  })

  return parseResponse<T>(rawResponse)
}
/**
 * Send POST request to given URL, with provided body and options.
 */
export const postJson = async <T = ApiResponse>(url: string, body = {}, options: BaseJsonOptions = {}): Promise<T> => {
  const { headers, ...fetchOptions } = options
  const absoluteUrl = getAbsoluteUrl(url)
  const token = getAuthToken()
  const reqHeaders = {
    'Content-Type': 'application/json',
    ...(token && getAuthHeader(token)),
  }

  return fetch(absoluteUrl, {
    method: 'POST',
    ...fetchOptions,
    body: JSON.stringify(body),
    headers: { ...headers, ...reqHeaders },
  }).then(rawResponse => parseResponse<T>(rawResponse))
}

/**
 * Send DELETE request to given URL
 */
export const deleteJson = (url: string, options: GetJsonOptions = {}): Promise<ApiResponse> => {
  const { headers, ...fetchOptions } = options
  const absoluteUrl = getAbsoluteUrl(url)
  const token = getAuthToken()
  const reqHeaders = {
    ...(token && getAuthHeader(token)),
  }
  return fetch(absoluteUrl, { method: 'DELETE', headers: { ...headers, ...reqHeaders }, ...fetchOptions }).then(
    parseResponse,
  )
}

/**
 * Send PUT request to given URL, with provided body and options.
 */
export const putJson = (url: string, body = {}, options: BaseJsonOptions = {}): Promise<ApiResponse> => {
  const { headers, ...fetchOptions } = options
  const absoluteUrl = getAbsoluteUrl(url)
  const token = getAuthToken()
  const reqHeaders = {
    'Content-Type': 'application/json',
    ...(token && getAuthHeader(token)),
  }
  return fetch(absoluteUrl, {
    method: 'PUT',
    ...fetchOptions,
    body: JSON.stringify(body),
    headers: { ...headers, ...reqHeaders },
  }).then(parseResponse)
}

/**
 * Sends HEAD request to given URL. Returns a promise witch resolves with `false` if error code, `true` otherwise.
 */
export const hasResponse = (url: string, options: BaseJsonOptions = {}): Promise<boolean> =>
  new Promise(resolve => {
    const { headers, ...fetchOptions } = options

    const absoluteUrl = getAbsoluteUrl(url)

    fetch(absoluteUrl, { method: 'HEAD', headers: { ...headers }, ...fetchOptions })
      .then(response => (response.ok ? resolve(true) : resolve(false)))
      .catch(() => resolve(false))
  })

/**
 * Wrapper for hasResponse. Useful when sending HEAD requests with swr.
 */
export const hasResponseFetcher = ([url]: [string]) => hasResponse(url)

/**
 *  Generates authentication header with provided token
 *
 * @param token user token
 */
export const getAuthHeader = (token: string) => ({
  Authorization: `Bearer ${token}`,
})

/**
 *  Gets current active token from auth persistor
 */
export const getAuthToken = () => {
  try {
    const LS = JSON.parse(localStorage.getItem('persist:auth') || '{}')
    if (LS && LS.token) {
      const token = LS.token.substring(1, LS.token.length - 1)
      return token || null
    }
    return null
  } catch (error) {
    console.error('Error occurred while retrieving auth token:', error)
    return null
  }
}

type ResponseObj<T> = { response?: T; error?: Error }

/**
 * Returns a promise that always resolves with the array of `{ response, error }` objects.
 */
export const promiseWhen = <T = Response>(promises: Promise<T>[]): Promise<ResponseObj<T>[]> =>
  new Promise(resolve => {
    const responses: { [index: number]: ResponseObj<T> } = {}

    const check = () => {
      if (promises.length === Object.keys(responses).length) {
        resolve(
          Object.keys(responses).reduce((arr: ResponseObj<T>[], index) => {
            arr.push(responses[index])
            return arr
          }, []),
        )
      }
    }

    promises.forEach((promise, i) => {
      promise
        .then(response => {
          responses[i] = { response }
          check()
        })
        .catch(error => {
          responses[i] = { error }
          check()
        })
    })
  })
