import { Box, BoxProps } from '@sofascore/ui'

import { Spinner } from 'components/Spinner'
import { colorTokens } from 'styles/color'

const Loading = (props: BoxProps) => {
  return (
    <Box mt={['100', '200']} py="lg" textAlign="center" {...props}>
      <Spinner color={colorTokens.primary.default} width="48px" />
    </Box>
  )
}

export default Loading
