import * as React from 'react'

export type UseBooleanActions = {
  setValue: React.Dispatch<React.SetStateAction<boolean>>
  setTrue: () => void
  setFalse: () => void
  toggle: () => void
}

export type UseBoolean = [boolean, UseBooleanActions]

const useBoolean = (initialValue: boolean): UseBoolean => {
  const [value, setValue] = React.useState<boolean>(initialValue)

  const toggle = React.useCallback(() => setValue(v => !v), [])
  const setTrue = React.useCallback(() => setValue(true), [])
  const setFalse = React.useCallback(() => setValue(false), [])

  const actions = React.useMemo(() => ({ setValue, setTrue, setFalse, toggle }), [setTrue, setFalse, toggle])

  return React.useMemo(() => [value, actions], [value, actions])
}

export default useBoolean
