import { Flex, FlexProps } from '@sofascore/ui'

interface CounterButtonProps {
  width?: number
  height?: number
  onClick?: () => void
  disabled?: boolean
}

export const CounterButton = ({
  width,
  height,
  onClick,
  disabled,
  children,
  ...boxProps
}: React.PropsWithChildren<CounterButtonProps & FlexProps>) => {
  return (
    <Flex
      w={width}
      h={height}
      justify="center"
      align="center"
      bg="surface.s2"
      br="xs"
      hoverBg={disabled ? undefined : 'onSurface.nLv4'}
      style={{ transition: 'all .1s', opacity: disabled ? 0.5 : 1 }}
      {...boxProps}
    >
      <button
        onClick={onClick}
        disabled={disabled}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          padding: 0,
          border: 0,
          backgroundColor: 'transparent',
          cursor: 'pointer',
        }}
      >
        {children}
      </button>
    </Flex>
  )
}
