import styled from 'styled-components'

import { backgrounds } from 'styles/color'

export const ChangeFoulsButton = styled.button<{ disabled?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${backgrounds.chip};
  border: 1px solid ${backgrounds.chip};
  border-radius: 4px;
  padding: 4px;
  cursor: ${p => (p.disabled ? 'auto' : 'pointer')};
  transition: all 0.2s;
  opacity: ${p => (p.disabled ? 0.65 : 1)};

  &:hover {
    border-color: ${p => (p.disabled ? backgrounds.chip : '#333333')};
  }
`
