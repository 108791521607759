import * as React from 'react'

import { IconBase, IconProps } from './Base'

export const IconArrowDown = (props: IconProps) => (
  <IconBase width={24} height={24} viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M1 0h24v24H1z" />
      <path fill={props.fill ? props.fill : '#FF407F'} d="M6 9l7 7 7-7z" />
    </g>
  </IconBase>
)
