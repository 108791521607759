import { StatusType } from 'entities/Status'
import { SportPeriodConfig } from 'features/EditPeriod'

// Supported periods for live basketball matches.
export const basketballRegularPeriods: SportPeriodConfig[] = [
  {
    code: 13,
    type: StatusType.IN_PROGRESS,
    shortTitleKey: 'Q1',
    longTitleKey: '1st Quarter',
    descriptionKey: 'Proceed to Q1?',
    startTimeStampPath: 'period1StartTimestamp',
    scorePath: 'period1',
  },
  {
    code: 30,
    type: StatusType.PAUSE,
    shortTitleKey: 'Pause',
    longTitleKey: 'Pause',
    descriptionKey: 'proceed_to_pause',
    startTimeStampPath: undefined,
  },
  {
    code: 14,
    type: StatusType.IN_PROGRESS,
    shortTitleKey: 'Q2',
    longTitleKey: '2nd Quarter',
    descriptionKey: 'Proceed to Q2?',
    startTimeStampPath: 'period2StartTimestamp',
    scorePath: 'period2',
  },
  {
    code: 31,
    type: StatusType.PAUSE,
    shortTitleKey: 'Halftime.short',
    longTitleKey: 'half_time',
    descriptionKey: 'proceed_to_ht',
    startTimeStampPath: undefined,
  },
  {
    code: 15,
    type: StatusType.IN_PROGRESS,
    shortTitleKey: 'Q3',
    longTitleKey: '3rd Quarter',
    descriptionKey: 'Proceed to Q3?',
    startTimeStampPath: 'period3StartTimestamp',
    scorePath: 'period3',
  },
  {
    code: 30,
    type: StatusType.PAUSE,
    shortTitleKey: 'Pause',
    longTitleKey: 'Pause',
    descriptionKey: 'proceed_to_pause',
    startTimeStampPath: undefined,
  },
  {
    code: 16,
    type: StatusType.IN_PROGRESS,
    shortTitleKey: 'Q4',
    longTitleKey: '4th Quarter',
    descriptionKey: 'Proceed to Q4?',
    startTimeStampPath: 'period4StartTimestamp',
    scorePath: 'period4',
  },
]

export const basketballExtraPeriods: SportPeriodConfig[] = [
  {
    code: 32,
    type: StatusType.PAUSE,
    shortTitleKey: 'Awaiting ET',
    longTitleKey: 'Pause',
    descriptionKey: 'Go to Awaiting Extra Time?',
    startTimeStampPath: undefined,
  },
  {
    code: 40,
    type: StatusType.IN_PROGRESS,
    shortTitleKey: 'OT',
    longTitleKey: 'Overtime',
    descriptionKey: 'proceed_to_overtime_question',
    startTimeStampPath: 'overtime',
    scorePath: 'overtime',
  },
]
