import * as React from 'react'

import { IconBase, IconProps } from './Base'

export const IconTime = (props: IconProps) => (
  <IconBase width={25} height={24} viewBox="0 0 25 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M1 0h24v24H1z" />
      <path
        fill={props.fill || '#FF407F'}
        d="M12.99 2C18.52 2 23 6.48 23 12s-4.48 10-10.01 10C7.47 22 3 17.52 3 12S7.47 2 12.99 2zM13 4c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm.5 3v5.25l4.5 2.67-.75 1.23L12 13V7h1.5z"
      />
    </g>
  </IconBase>
)
