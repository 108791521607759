import { Flex, Text, ZIndex } from '@sofascore/ui'
import IconArrowBack from '@sofascore/ui/dist/modules/Icons/IconArrowBack'
import { FormattedMessage } from 'react-intl'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEventState, useEventStatusListener } from 'entities/Event'
import { PermissionAction } from 'entities/Permission'
import { PermissionGuard } from 'shared/ui'

import * as S from 'modules/MyCompetitions/components/MyCompetitionHeader/styles'
import { IconButton } from 'components/IconButton'

export const MatchDetailsHeader = () => {
  const { event } = useEventState()
  const { competitionId, seasonId, eventId } = useParams()
  const { isLive, isPostMatch } = useEventStatusListener()
  const { state } = useLocation()

  const navigate = useNavigate()

  const handleGoBack = () => {
    if (state && state.previousPageTeamId) {
      navigate(`/team/${state.previousPageTeamId}/matches?uniqueTournamentId=${competitionId}`)
    } else {
      navigate(`/my-competitions/${competitionId}/season/${seasonId}/matches`)
    }
  }

  return (
    <S.MyCompetitionHeader
      forwardedAs="header"
      mb={[0, 'sm']}
      br={[0, 'sm']}
      bg="surface.s1"
      zIndex={ZIndex.Header}
      elevation={2}
    >
      <Flex align="center" px="lg" pt="sm" h={48} bg="surface.s1" br="sm sm 0 0">
        <IconButton onClick={handleGoBack} p={0} border="none" mr="xl">
          <IconArrowBack fill="onSurface.nLv1" />
        </IconButton>
        <Text font="display.large" color="onSurface.nLv1">
          <FormattedMessage id="edit_match" />
        </Text>
      </Flex>

      <S.ScrollingTabs w="100%" br={[0, 'sm']} overflowY="hidden">
        <S.Link to={`/my-competitions/${competitionId}/season/${seasonId}/matches/${eventId}/info`} state={state}>
          <Text font="body.medium" color="inherit" whiteSpace="nowrap">
            <FormattedMessage id="info" />
          </Text>
        </S.Link>

        <PermissionGuard entityAllowedActions={event.allowedActions} checkAllowedActions={[PermissionAction.Update]}>
          <S.Link to={`/my-competitions/${competitionId}/season/${seasonId}/matches/${eventId}/lineups`} state={state}>
            <Text font="body.medium" color="inherit" whiteSpace="nowrap">
              <FormattedMessage id="lineups" />
            </Text>
          </S.Link>

          {isLive && (
            <S.Link to={`/my-competitions/${competitionId}/season/${seasonId}/matches/${eventId}/live`} state={state}>
              <Text font="body.medium" color="inherit" whiteSpace="nowrap">
                <FormattedMessage id="edit_live" />
              </Text>
            </S.Link>
          )}

          {isPostMatch && (
            <S.Link
              to={`/my-competitions/${competitionId}/season/${seasonId}/matches/${eventId}/post-match`}
              state={state}
            >
              <Text font="body.medium" color="inherit" whiteSpace="nowrap">
                <FormattedMessage id="post_match_tab_title" />
              </Text>
            </S.Link>
          )}
        </PermissionGuard>
      </S.ScrollingTabs>
    </S.MyCompetitionHeader>
  )
}
