export const RED = {
  google: '#dc4e41',
  incident: '#E03E3E',
  action: '#c7361f',
  light: '#ffe6e6',
}

export const YELLOW = {
  incident: '#E4BA00',
  warning: '#cf9612',
  light: '#fdf5e0',
}
export const BLUE = {
  facebook: '#1877f2',
  incident: '#1C62A7',
  EU: '#323c8d',
}

export const GREEN = {
  incident: '#0CB66F',
  action: '#15b168',
  timelineEnd: '#75f1c0',
}
export const primaryColor = '#03519e'

export const secondaryColor = '#ff407f'

export const secondaryColorHover = '#ff6d9d'

export const secondaryColorAlpha = 'rgba(255, 64, 127, 0.15)'

export const textPrimary = '#0f202c'

export const textSecondary = '#ffffff'

export const neutral = '#DBDEE0'

export const selectColor = 'rgba(15, 32, 44, 0.05)'

export const textLightColor = 'rgba(15, 32, 44, 0.6)'

export const boxShadowColor = 'rgba(15, 32, 44, 0.3)'

export const iconButonDarkHover = 'rgba(0, 0, 0, 0.04)'

export const backgrounds = {
  dark: '#273641',
  light: '#3f4d56',
  lightRgba: 'rgba(15, 32, 44, 0.15)',
  content: '#f7f8f8',
  chip: 'rgba(229, 233, 239, 0.4)',
  terrain: '#CBEDBF',
}

export const borders = {
  lightRgba: 'rgba(15, 32, 44, 0.03)',
  chip: '#1d7cd8',
}

export const primaryDark = '#054687'

export const textColorRgbaDark = 'rgba(15, 32, 44, 0.4)'

export const notActiveIconColor = '#B7BCBF'

export const lightGray = '#9fa6ab'

export const colorTokens = {
  primary: {
    default: secondaryColor,
    hover: secondaryColorHover,
    highlight: secondaryColorAlpha,
    selected: secondaryColorHover,
  },
  secondary: { default: '#374df5', hover: 'hsl(233, 90.5%, 63.8%)', highlight: 'rgba(55, 77, 245, 0.15)' },
}
