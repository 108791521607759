import { ChangeEvent, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Flex } from '@sofascore/ui'
import { MissingPlayerReason, PlayerCard } from 'entities/Player'
import { Checkbox } from 'shared/ui'

import { backgrounds } from 'styles/color'

import { DynamicLineupsPlayer } from '../../model'
import { mapMissingReasonToTranslationKey } from '../../mappings'
import { MissingReasonSelector } from './MissingReasonSelector'

export const MissingPlayerGroupItem = ({
  lineupPlayer,
  checked,
  onCheck,
  onChangeReason,
}: {
  lineupPlayer: DynamicLineupsPlayer
  checked: boolean
  onCheck: (event: ChangeEvent<HTMLInputElement>, reason: MissingPlayerReason) => void
  onChangeReason: (reason: MissingPlayerReason) => void
}) => {
  const intl = useIntl()
  const [reason, setReason] = useState<MissingPlayerReason>(MissingPlayerReason.Injured)

  const missingPlayersOptions = useMemo(() => {
    return Object.keys(MissingPlayerReason)
      .filter(key => !isNaN(Number(key)))
      .map(key => {
        return {
          label: intl.formatMessage({ id: mapMissingReasonToTranslationKey[key] }),
          value: Number(key),
        }
      })
  }, [intl])

  const handleCheckPlayer = (event: ChangeEvent<HTMLInputElement>) => {
    onCheck(event, reason)
  }

  const handleChangeReason = (value: MissingPlayerReason) => {
    setReason(value)
    onChangeReason(value)
  }

  return (
    <Flex direction="column" bg={checked ? backgrounds.content : undefined} px="lg" py="xs">
      <Flex align="center" justify="space-between">
        <PlayerCard player={lineupPlayer.player} showJerseyNumber />
        <Checkbox name={`missing-player-${lineupPlayer.player.id}`} checked={checked} onChange={handleCheckPlayer} />
      </Flex>
      {checked && (
        <Box py="sm">
          <MissingReasonSelector options={missingPlayersOptions} reason={reason} setReason={handleChangeReason} />
        </Box>
      )}
    </Flex>
  )
}
