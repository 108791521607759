import { Theme, createStyles, makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
        width: 346,
      },
    },
    dialogPaperDiscard: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        width: 328,
      },
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(2),
      },
    },
    dialogPaperDeleteIncident: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        width: 346,
      },
      [theme.breakpoints.down('sm')]: {
        width: '90%',
        margin: 0,
      },
    },
    dialogContent: {
      paddingTop: 0,
      paddingBottom: theme.spacing(3),
    },
  }),
)
