import styled from 'styled-components'
import { Box, Text } from '@sofascore/ui'

import { textPrimary } from 'styles/color'

export const IncidentBox = styled(Box)<{ $isActive?: boolean }>`
  position: relative;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 72px;
  width: 72px;
  height: 72px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: ${p => (p.$isActive ? '#ffffff' : 'rgba(34, 34, 38, 0.06)')};
  color: ${textPrimary};
  padding: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: ${p => (p.$isActive ? '#333333' : 'transparent')};
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    border-color: #333333;
    background-color: #ffffff;
  }
`

export const IncidentBoxLabel = styled(Text)`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  & div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`
