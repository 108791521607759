import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react'
import { Flex } from '@sofascore/ui'
import { useEventPlayers } from 'entities/Player'
import { LineupsResponse } from 'entities/Lineups'
import {
  LineupType,
  LineupsInfoProvider,
  LineupsInitialState,
  LineupsTypeSelector,
  useEventLineups,
  getKit,
  mergePlayers,
  toDynamicLineupPlayers,
  lineupFormations,
  lineupGoalkeeperKitColors,
  lineupPlayerKitColors,
} from 'features/AddLineups'
import { useEventState } from 'entities/Event'

import { Spinner } from 'components/Spinner'

interface FootballLineupsLoaderProps {
  transformer: (data: LineupsResponse) => Partial<LineupsInitialState>
  children: (type: LineupType, setType: Dispatch<SetStateAction<LineupType>>) => ReactNode
}

export const FootballLineupsLoader = ({ children, transformer }: FootballLineupsLoaderProps) => {
  const { event } = useEventState()
  const { homeTeam, awayTeam } = event
  const players = useEventPlayers()

  const homePlayers = players[homeTeam.id] || []
  const awayPlayers = players[awayTeam.id] || []

  const [type, setType] = useState<LineupType>(LineupType.PLAYER_LIST)
  const [initialLineupsTypeSelected, setInitialLineupsTypeSelected] = useState(false)

  const { data: eventLineupsData, error: eventLineupsError } = useEventLineups(event ? event.id : null)

  useEffect(() => {
    if (eventLineupsData) {
      if (eventLineupsData.home.formation && eventLineupsData.away.formation) {
        setType(LineupType.PITCH)
      }

      setInitialLineupsTypeSelected(true)
    }
  }, [eventLineupsData])

  useEffect(() => {
    if (eventLineupsError) {
      setInitialLineupsTypeSelected(false)
    }
  }, [eventLineupsError])

  if (!eventLineupsData && !eventLineupsError) {
    return (
      <Flex w="100%" h={400} display="flex" align="center" justify="center" direction="column">
        <Spinner color="#374df5" width="50px" />
      </Flex>
    )
  }

  if (!initialLineupsTypeSelected) {
    return (
      <LineupsTypeSelector
        type={type}
        setType={setType}
        onSelect={() => setInitialLineupsTypeSelected(true)}
        homeTeam={homeTeam}
        homePlayers={homePlayers}
        awayTeam={awayTeam}
        awayPlayers={awayPlayers}
      />
    )
  }

  const transformedLineupsData = eventLineupsData ? transformer(eventLineupsData) : undefined

  // If lineups already exist, we need to merge those lineup players
  // with all available so user can have all team players at disposal
  const homeLineupPlayers = mergePlayers(
    transformedLineupsData?.homeLineupPlayers || [],
    toDynamicLineupPlayers(homePlayers, true),
  )
  const awayLineupPlayers = mergePlayers(
    transformedLineupsData?.awayLineupPlayers || [],
    toDynamicLineupPlayers(awayPlayers, false),
  )

  return (
    <LineupsInfoProvider
      initialState={{
        homeTeam: homeTeam,
        homeLineupPlayers: homeLineupPlayers,
        homeKit: getKit(transformedLineupsData?.homeKit, lineupPlayerKitColors[0].value),
        homeFormation: transformedLineupsData?.homeFormation || lineupFormations[0].value,
        homeGoalkeeperKit: getKit(transformedLineupsData?.homeGoalkeeperKit, lineupGoalkeeperKitColors[0].value),
        awayTeam: awayTeam,
        awayLineupPlayers: awayLineupPlayers,
        awayKit: getKit(transformedLineupsData?.awayKit, lineupPlayerKitColors[1].value),
        awayFormation: transformedLineupsData?.awayFormation || lineupFormations[1].value,
        awayGoalkeeperKit: getKit(transformedLineupsData?.awayGoalkeeperKit, lineupGoalkeeperKitColors[1].value),
        hasChanges: false,
      }}
    >
      {children(type, setType)}
    </LineupsInfoProvider>
  )
}
