import { useEffect, useState } from 'react'
import { Box, Button, Flex, Text } from '@sofascore/ui'
import IconChevronRight from '@sofascore/ui/dist/modules/Icons/_IconChevronRight'
import IconChevronLeft from '@sofascore/ui/dist/modules/Icons/_IconChevronLeft'
import { FormattedMessage } from 'react-intl'
import { OnArgs } from 'react-calendar'

import { isOnSameDay, isToday } from 'utils/time'

import * as S from './styles'

export interface CalendarProps {
  date: Date | undefined
  onChange: (date: Date) => void
}

export default function DateTimePickerCalendar({ onChange, date }: CalendarProps) {
  const [activeMonth, setActiveMonth] = useState<Date | undefined>()

  useEffect(() => {
    setActiveMonth(date)
  }, [date])

  return (
    <>
      <S.Calendar
        showNeighboringMonth={false}
        activeStartDate={activeMonth}
        formatDay={() => ''}
        navigationLabel={({ label }: { label: string }) => <Text font="display.medium">{label}</Text>}
        locale="en"
        nextLabel={<IconChevronRight fill="primary.default" />}
        next2Label={null}
        prevLabel={<IconChevronLeft fill="primary.default" />}
        prev2Label={null}
        minDetail="decade"
        value={date}
        onChange={val => onChange(val as Date)}
        onActiveStartDateChange={({ activeStartDate }: OnArgs) => {
          if (activeStartDate) {
            setActiveMonth(activeStartDate)
          }
        }}
        tileContent={p => {
          const isSelected = date ? isOnSameDay(date.getTime(), p.date.getTime()) : false

          return (
            <Flex
              data-testid={isSelected ? 'selected-date' : 'unselected-date'}
              className={isSelected ? 'selected' : undefined}
              position="relative"
              justify="center"
              align="center"
              grow={0}
              shrink={0}
              bg={isSelected ? 'primary.default' : isToday(p.date) ? 'primary.highlight' : undefined}
              w={['xxl', 'xl', 'xxl']}
              h={['xxl', 'xl', 'xxl']}
              br="lg"
              cursor="pointer"
            >
              <Text font="display.small" color={isSelected ? 'surface.s1' : 'onSurface.nLv3'} lineHeight={1}>
                {p.date.getDate()}
              </Text>
            </Flex>
          )
        }}
      />

      <Box borderTop="onSurface.nLv4" p="lg" mt="lg">
        <Button
          tertiary
          onClick={() => {
            const today = new Date()
            onChange(today)
            setActiveMonth(today)
          }}
        >
          <FormattedMessage id="editor_calendar_today" />
        </Button>
      </Box>
    </>
  )
}
