import { Flex, Text, ZIndex } from '@sofascore/ui'
import IconArrowBack from '@sofascore/ui/dist/modules/Icons/IconArrowBack'
import { FormattedMessage } from 'react-intl'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEventState } from 'entities/Event'
import { PermissionAction } from 'entities/Permission'
import { PermissionGuard, Tab } from 'shared/ui'

import * as S from 'modules/MyCompetitions/components/MyCompetitionHeader/styles'
import { IconButton } from 'components/IconButton'

export const GeneralMatchDetailsHeader = () => {
  const { event } = useEventState()
  const { competitionId, seasonId, eventId } = useParams()
  const { state } = useLocation()

  const navigate = useNavigate()

  const handleGoBack = () => {
    if (state && state.previousPageTeamId) {
      navigate(`/team/${state.previousPageTeamId}/matches?uniqueTournamentId=${competitionId}`)
    } else {
      navigate(`/my-competitions/${competitionId}/season/${seasonId}/matches`)
    }
  }

  return (
    <S.MyCompetitionHeader
      forwardedAs="header"
      mb={[0, 'sm']}
      br={[0, 'sm']}
      bg="surface.s1"
      zIndex={ZIndex.Header}
      elevation={2}
    >
      <Flex align="center" px="lg" pt="sm" h={48} bg="surface.s1" br="sm sm 0 0">
        <IconButton onClick={handleGoBack} p={0} border="none" mr="xl">
          <IconArrowBack fill="onSurface.nLv1" />
        </IconButton>

        <Text font="display.large" color="onSurface.nLv1">
          <FormattedMessage id="edit_match" />
        </Text>
      </Flex>

      <S.ScrollingTabs w="100%" br={[0, 'sm']} overflowY="hidden">
        <Tab to={`/my-competitions/${competitionId}/season/${seasonId}/matches/${eventId}/info`} msgId="info" />

        <PermissionGuard entityAllowedActions={event.allowedActions} checkAllowedActions={[PermissionAction.Update]}>
          <Tab to={`/my-competitions/${competitionId}/season/${seasonId}/matches/${eventId}/live`} msgId="edit" />
        </PermissionGuard>
      </S.ScrollingTabs>
    </S.MyCompetitionHeader>
  )
}
