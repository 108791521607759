import { Box, Flex, Text } from '@sofascore/ui'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { IncidentPenaltyShootoutType, useMissedPenaltyReasonOptions } from 'entities/Incident'
import { useEventState } from 'entities/Event'
import {
  useTeamPlayerOptions,
  PlayerOption,
  getDefaultOption,
  renderPlayerOption,
  renderSelectedPlayerOption,
} from 'entities/Player'

import { Button } from 'components/Button'
import { Select } from 'components/Select'

import { EditIncidentFormProps } from '../../model'

export const EditFootballShootoutPenaltyForm = ({ onSubmit, onClose, incident }: EditIncidentFormProps) => {
  const [isSaving, setIsSaving] = useState(false)

  const { event } = useEventState()
  const { id: eventId, homeTeam, awayTeam } = event
  const {
    id,
    isHome,
    player: initialPlayer,
    incidentType,
    incidentClass,
    homeScore,
    awayScore,
    time,
    addedTime,
  } = incident
  const intl = useIntl()

  const teamId = isHome ? homeTeam.id : awayTeam.id
  const scored = incidentClass === IncidentPenaltyShootoutType.Scored ? 1 : 0
  const [scorer, setScorer] = useState<PlayerOption | undefined>(getDefaultOption(initialPlayer))

  const { options, missedPenaltyReason, setMissedPenaltyReason } = useMissedPenaltyReasonOptions()

  const handleConfirm = async () => {
    setIsSaving(true)

    onSubmit({
      eventId: eventId,
      incidentId: id as number,
      incidentData: {
        incidentType,
        incidentClass,
        isHome: isHome as boolean,
        time,
        addedTime,
        homeScore,
        awayScore,
        player: scorer ? scorer.value : undefined,
        sequence: incident.sequence,
        score: scored,
        reason: missedPenaltyReason?.value as string,
        description: missedPenaltyReason?.label as string,
      },
    })
      .then(() => onClose())
      .finally(() => setIsSaving(false))
  }

  const [teamPlayersOptions] = useTeamPlayerOptions(teamId)

  return (
    <Box px="sm" pb="xxl">
      <Box px="sm" pt="lg" pb="xs" br="sm" bg="surface.s2">
        <Flex mb="xl" justify="center">
          <Text font="display.small">
            <FormattedMessage id="select_outcome_penalty_taker" />
          </Text>
        </Flex>

        <Select
          label={intl.formatMessage({ id: 'player' })}
          options={teamPlayersOptions}
          helperText="Optional"
          onChange={setScorer}
          value={scorer}
          maxVisibleOptions={6}
          w="100%"
          selectBackgroundColor="surface.s1"
          renderOption={renderPlayerOption}
          renderSelectedOption={renderSelectedPlayerOption}
        />

        {scored === 0 && (
          <Select
            label={intl.formatMessage({ id: 'outcome' })}
            options={options}
            helperText="Optional"
            value={missedPenaltyReason}
            onChange={setMissedPenaltyReason}
            maxVisibleOptions={6}
            w="100%"
            selectBackgroundColor="surface.s1"
          />
        )}
      </Box>

      <Flex gapX="lg" px="lg" mt="xxl">
        <Button variant="outlined" fullWidth onClick={onClose}>
          <FormattedMessage id="cancel" />
        </Button>
        <Button variant="contained" fullWidth onClick={handleConfirm} isLoading={isSaving}>
          <FormattedMessage id="confirm" />
        </Button>
      </Flex>
    </Box>
  )
}
