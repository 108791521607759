import { IconProps, IconBase } from './Base'

const IconInfo = ({ fill = 'rgba(34, 34, 38, 0.45)' }: IconProps) => {
  return (
    <IconBase width={24} height={24} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <circle cx="12" cy="12" r="12" fill={fill} />
        <g fill="#FFF">
          <path d="M11 10h2v8h-2zM11 6h2v2h-2z" />
        </g>
      </g>
    </IconBase>
  )
}

export default IconInfo
