import styled from 'styled-components'
import { Box, Flex, ZIndex } from '@sofascore/ui'

import { IconButton } from 'components/IconButton'

export const SlideButton = styled(IconButton)<{ side: 'left' | 'right' }>`
  width: 36px;
  height: 36px;

  background-color: ${p => p.theme.colors.surface.s1};
  box-shadow: 0px 0px 10px 0 rgba(15, 32, 44, 0.2);

  border-radius: 4px;

  padding: 0;
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  visibility: hidden;
  cursor: pointer;

  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  z-index: ${ZIndex.Sticky};

  ${p => `${p.side}: ${p.theme.spacing.sm};`};

  &:hover {
    background-color: ${p => p.theme.colors.surface.s1};
  }
`

export const Scrollable = styled(Box)`
  position: relative;

  &:hover {
    ${SlideButton} {
      @media (min-width: 959px) {
        visibility: visible;
      }
    }
  }
`

export const ScrollableContent = styled(Flex)`
  display: inline-flex;
  width: 100%;
  align-items: center;
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (min-width: 959px) {
    overflow: hidden;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`
