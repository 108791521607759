import { Theme, createStyles, makeStyles } from '@material-ui/core'

import { neutral, borders } from 'styles/color'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    keyboardDatePicker: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: borders.lightRgba,
      padding: 0,
      textTransform: 'none',
      border: `2px solid ${neutral}`,
      marginTop: theme.spacing(1),
      '& > div > input': {
        height: 40,
        padding: 0,
        fontWeight: 500,
        fontSize: 16,
        marginLeft: theme.spacing(2),
      },
      '& > div > div > button': {
        backgroundColor: 'white',
        borderLeft: `2px solid ${neutral}`,
        borderRadius: 0,
        padding: 0,
        width: 40,
        height: 40,
      },
      '& > div > div': {
        marginLeft: 0,
      },
      '& > p': {
        display: 'none',
      },
    },
    paperDialog: {
      color: theme.palette.text.primary,
      transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1)',
      backgroundColor: '#fff',
      borderRadius: '2px',
      margin: '8px',
      position: 'relative',
      overflowY: 'auto',
      display: 'flex',
      maxHeight: 'calc(100% - 64px)',
      flexDirection: 'column',

      // Workaround for not showing OK button
      '& > .MuiDialogActions-root > button:nth-of-type(2)': {
        display: 'none',
      },
    },
    toolbarStartDateLabel: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 300,
    },
    toolbarYear: {
      opacity: 0.7,
    },
    toolbarSelectedDate: {
      fontSize: '34px',
      color: 'white',
    },
    legendUpcomingEvents: {
      fontSize: '12px',
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
    legendToday: {
      fontSize: '12px',
      fontWeight: 400,
      color: '#fff',
      padding: '7px 13px',
    },
  }),
)
