import { Flex, Text, useMediaContext } from '@sofascore/ui'
import { EventActionType, useEventDispatch, useEventState } from 'entities/Event'
import { PeriodType, ScoreCounter } from 'entities/Score'

interface Props {
  periodKey: PeriodType
  periodName: React.ReactNode
  onScoreChange?: () => void
}

export const SetPeriodScoreFRO = ({ periodKey, periodName, onScoreChange }: Props) => {
  const { event } = useEventState()
  const eventDispatch = useEventDispatch()

  const { isMobile } = useMediaContext()

  const { homeScore, awayScore } = event

  const handleIncrementPeriodScore = (isHome: boolean) => {
    eventDispatch({
      type: EventActionType.INCREMENT_PERIOD_SCORE,
      payload: {
        isHome: isHome,
        periodKey,
        points: 1,
      },
    })

    onScoreChange?.()
  }

  const handleDecrementPeriodScore = (isHome: boolean) => {
    eventDispatch({
      type: EventActionType.DECREMENT_PERIOD_SCORE,
      payload: { isHome: isHome, periodKey, points: 1 },
    })

    onScoreChange?.()
  }

  const handleSetPeriodScore = (newScore: number, isHome: boolean) => {
    eventDispatch({
      type: EventActionType.SET_PERIOD_SCORE,
      payload: { isHome: isHome, periodKey, score: newScore },
    })

    onScoreChange?.()
  }

  return (
    <Flex justify="space-around" bg="surface.s1" br="sm" p="lg" mt="sm" h={110} elevation={2}>
      <Flex align="flex-end">
        <ScoreCounter
          value={homeScore[periodKey] === null ? undefined : (homeScore[periodKey] as number | undefined)}
          variant={isMobile ? 'compact' : 'wide'}
          onChange={newScore => handleSetPeriodScore(newScore, true)}
          onIncrement={() => handleIncrementPeriodScore(true)}
          onDecrement={() => handleDecrementPeriodScore(true)}
        />
      </Flex>

      <Text font="display.micro" whiteSpace="nowrap">
        {periodName}
      </Text>

      <Flex align="flex-end">
        <ScoreCounter
          value={awayScore[periodKey] === null ? undefined : (awayScore[periodKey] as number | undefined)}
          variant={isMobile ? 'compact' : 'wide'}
          onChange={newScore => handleSetPeriodScore(newScore, false)}
          onIncrement={() => handleIncrementPeriodScore(false)}
          onDecrement={() => handleDecrementPeriodScore(false)}
        />
      </Flex>
    </Flex>
  )
}
