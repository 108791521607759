import { Theme, createStyles, makeStyles } from '@material-ui/core'

import { selectColor } from 'styles/color'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100% - 67px)',
    },

    input: {
      '&::placeholder': {
        fontSize: 16,
        fontWeight: 500,
      },
    },

    dialogPaper: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
        width: 328,
      },
    },

    playerFormDialogPaper: {
      [theme.breakpoints.up('md')]: {
        width: 360,
      },
    },

    uploadImageDialog: {
      [theme.breakpoints.up('md')]: {
        width: 360,
        height: 640,
      },
    },

    dialogPadding: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },

    infoItem: {
      backgroundColor: selectColor,
      height: '40px',
      marginTop: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    title: {
      textAlign: 'left',
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
      color: theme.palette.text.primary,
    },
    dialogPaperDiscard: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        width: 328,
      },
    },

    dialogContent: {
      paddingTop: 0,
      paddingBottom: theme.spacing(3),
    },

    radioButtonGroup: {
      marginBottom: theme.spacing(8.5),
      '& > label > span': {
        fontWeight: 500,
      },
    },

    uploadedImageInfo: {
      marginTop: theme.spacing(3),
      padding: `0 ${theme.spacing(2)}px`,
      backgroundColor: selectColor,
      height: theme.spacing(6),
      display: 'flex',
      alignItems: 'center',
    },
  }),
)
