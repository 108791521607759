import { PropsWithChildren, createContext, useContext, useReducer } from 'react'

import { teamReducer } from './reducer'
import { TeamDispatchState, TeamState } from './interface'

const initialTeamState = {
  team: null,
}

const TeamContext = createContext<TeamState>(initialTeamState)
const TeamDispatchContext = createContext<TeamDispatchState>(() => {})

export const TeamProvider = ({ children }: PropsWithChildren) => {
  const [teamState, dispatch] = useReducer(teamReducer, initialTeamState)

  return (
    <TeamContext.Provider value={teamState}>
      <TeamDispatchContext.Provider value={dispatch}>{children}</TeamDispatchContext.Provider>
    </TeamContext.Provider>
  )
}

export const useTeam = () => {
  const { team } = useContext(TeamContext)
  return team
}

export const useTeamDispatch = () => {
  const dispatch = useContext(TeamDispatchContext)
  return dispatch
}
