export const PitchBackground = () => {
  return (
    <svg
      width="360"
      height="408"
      viewBox="0 0 360 408"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ position: 'absolute', top: 0, left: 0 }}
    >
      <path d="M360 0H0V408H360V0Z" fill="url(#paint0_linear_3972_18615)" />
      <rect width="360" height="408" fill="url(#paint1_linear_3972_18615)" />
      <path d="M356 8H4V57H356V8Z" fill="#0F202C" fillOpacity="0.05" />
      <path d="M356 106H4V155H356V106Z" fill="#0F202C" fillOpacity="0.05" />
      <path d="M356 204H4V253H356V204Z" fill="#0F202C" fillOpacity="0.05" />
      <path d="M356 302H4V351H356V302Z" fill="#0F202C" fillOpacity="0.05" />
      <defs>
        <linearGradient
          id="paint0_linear_3972_18615"
          x1="18000"
          y1="0"
          x2="18000"
          y2="40800"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#74A68B" />
          <stop offset="1" stopColor="#1F4F35" />
        </linearGradient>
        <linearGradient id="paint1_linear_3972_18615" x1="180" y1="0" x2="180" y2="408" gradientUnits="userSpaceOnUse">
          <stop stopColor="#74A68B" />
          <stop offset="1" stopColor="#1F4F35" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const PitchLines = () => {
  return (
    <svg
      width="354"
      height="402"
      viewBox="0 0 354 402"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ position: 'absolute', top: 3, left: 3 }}
    >
      <g opacity="0.3">
        <path
          d="M139 46C139.594 47.029 140.225 48.035 140.896 49.009C148.815 60.483 162.032 68 177 68C191.975 68 205.198 60.477 213.114 48.993C213.782 48.023 214.409 47.023 215 46"
          stroke="white"
        />
        <path d="M99 2V46H255V2" stroke="white" />
        <path d="M141 2V22H213V2" stroke="white" />
        <path d="M2 12C7.52285 12 12 7.52285 12 2" stroke="white" />
        <path d="M342 2C342 7.52285 346.477 12 352 12" stroke="white" />
        <path d="M239 401C239 366.759 211.241 339 177 339C142.759 339 115 366.759 115 401" stroke="white" />
        <path d="M353 1H1V401H353V1Z" stroke="white" />
      </g>
    </svg>
  )
}
