import { useParams } from 'react-router-dom'
import useSWR from 'swr'

import { standingsEditor } from 'api/routes'

import { StandingsResponse } from './interface'

export const useAllUniqueTournamentStandings = () => {
  const { seasonId, competitionId } = useParams()

  const { data, error, mutate, isLoading } = useSWR<StandingsResponse>(
    competitionId && seasonId ? standingsEditor(+competitionId, +seasonId) : null,
  )
  const { standings = [] } = data || {}

  return {
    data: standings,
    error,
    mutate,
    isLoading,
  }
}
