import { Box, Flex } from '@sofascore/ui'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const TeamInfoHeader = styled(Box)`
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  ${p => p.theme.breakpoints.query.mdMax`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    `}
`

export const Link = styled(NavLink)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  color: ${p => p.theme.colors.onSurface.nLv3};
  padding: 16px 16px 13px 16px;
  border-bottom: 3px solid transparent;
  transition: all 0.2s;

  span {
    font-weight: 500;
  }

  &:hover,
  &.active {
    color: ${p => p.theme.colors.primary.default};
    border-bottom: 3px solid ${p => p.theme.colors.primary.default};
  }

  ${p => p.theme.breakpoints.query.mdMax`
    width: 100%;
    height: 48px;
    `}
`

export const ScrollingTabs = styled(Flex)`
  @media (hover: none) {
    ::-webkit-scrollbar {
      display: none;
    }
  }
`

export const BackButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
`
