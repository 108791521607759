import isEqual from 'lodash/isEqual'
import { ENDED_CODE, FIRST_HALF_CODE, HALF_TIME_CODE, SECOND_HALF_CODE } from 'entities/Status'
import { BasicEvent } from 'entities/Event'
import { SportName, sportsWithTwoHalves } from 'entities/Sport'
import { ScoreWithPeriods } from 'entities/Score'

import { isDateValid } from 'utils/time'
import { Option } from 'components/DropdownMenu/interface'

import { MAX_ROUND, MIN_ROUND, periodStatuses } from './const'
import { UpdateEventBody } from './interface'

// Method which returns request body when we are sending request for updating event. There are additional checks
// for score sections which are removed and for setting period2 value.
export const getUpdateEventBody = (event: BasicEvent, prevEvent?: BasicEvent) => {
  const { homeScore, awayScore, currentPeriodStartTimestamp, roundInfo, status, aggregatedWinnerCode, startTimestamp } =
    event
  const sportSlug = event.tournament.category.sport.slug
  const hasStatusChanges = prevEvent && prevEvent.status.code !== event.status.code
  const hasScoreChanges =
    prevEvent && !isEqual(event.homeScore, prevEvent.homeScore) && !isEqual(event.awayScore, prevEvent.awayScore)

  if (homeScore && awayScore && status) {
    const scoreKeys = Object.keys(homeScore)

    const homeScoreToSend: ScoreWithPeriods = {}
    for (let i = 0; i < scoreKeys.length; i++) {
      if (homeScore[scoreKeys[i]] >= 0 || homeScore[scoreKeys[i]] === null) {
        homeScoreToSend[scoreKeys[i]] = homeScore[scoreKeys[i]]
      }
    }

    const awayScoreToSend: ScoreWithPeriods = {}
    for (let i = 0; i < scoreKeys.length; i++) {
      if (awayScore[scoreKeys[i]] >= 0 || awayScore[scoreKeys[i]] === null) {
        awayScoreToSend[scoreKeys[i]] = awayScore[scoreKeys[i]]
      }
    }

    // Additional check for period2 value for football and futsal
    if (status.code === ENDED_CODE && sportsWithTwoHalves.includes(sportSlug as SportName)) {
      if (typeof homeScoreToSend.period1 === 'number' && typeof homeScoreToSend.current === 'number') {
        if (
          typeof homeScoreToSend.period2 !== 'number' ||
          homeScoreToSend.period1 + homeScoreToSend.period2 !== homeScoreToSend.current
        ) {
          const extra1 =
            typeof homeScoreToSend.extra1 === 'number' && homeScoreToSend.extra1 > 0 ? homeScoreToSend.extra1 : 0
          const extra2 =
            typeof homeScoreToSend.extra2 === 'number' && homeScoreToSend.extra2 > 0 ? homeScoreToSend.extra2 : 0
          const penalties =
            typeof homeScoreToSend.penalties === 'number' && homeScoreToSend.penalties > 0
              ? homeScoreToSend.penalties
              : 0
          const period2 = homeScoreToSend.current - homeScoreToSend.period1 - extra1 - extra2 - penalties

          if (homeScoreToSend.period2 !== period2) {
            homeScoreToSend.period2 = period2
          }
        }
      }
      if (typeof awayScoreToSend.period1 === 'number' && typeof awayScoreToSend.current === 'number') {
        if (
          typeof awayScoreToSend.period2 !== 'number' ||
          awayScoreToSend.period1 + awayScoreToSend.period2 !== awayScoreToSend.current
        ) {
          const extra1 =
            typeof awayScoreToSend.extra1 === 'number' && awayScoreToSend.extra1 > 0 ? awayScoreToSend.extra1 : 0
          const extra2 =
            typeof awayScoreToSend.extra2 === 'number' && awayScoreToSend.extra2 > 0 ? awayScoreToSend.extra2 : 0
          const penalties =
            typeof awayScoreToSend.penalties === 'number' && awayScoreToSend.penalties > 0
              ? awayScoreToSend.penalties
              : 0
          const period2 = awayScoreToSend.current - awayScoreToSend.period1 - extra1 - extra2 - penalties
          if (awayScoreToSend.period2 !== period2) {
            awayScoreToSend.period2 = period2
          }
        }
      }
    }
    if ('normaltime' in homeScoreToSend || 'normaltime' in awayScoreToSend) {
      delete homeScoreToSend.normaltime
      delete awayScoreToSend.normaltime
    }

    if ('display' in homeScoreToSend || 'display' in awayScoreToSend) {
      delete homeScoreToSend.display
      delete awayScoreToSend.display
    }

    const updatedEventBody: UpdateEventBody = {
      status: { code: status.code },
      homeScore: { ...homeScoreToSend },
      awayScore: { ...awayScoreToSend },
      aggregatedWinnerCode: aggregatedWinnerCode ? aggregatedWinnerCode : 0,
      currentPeriodStartTimestamp: typeof currentPeriodStartTimestamp === 'number' ? currentPeriodStartTimestamp : null,
      startDateTimestamp: startTimestamp,
      ...((hasStatusChanges || hasScoreChanges) && { crowdsourcingDataDisplayEnabled: false }),
    }

    if (roundInfo) {
      updatedEventBody.roundInfo = roundInfo
    }

    return updatedEventBody
  }

  return null
}

// Determines if status code is on of football basic statuses (1st half, Half time, 2nd half or Ended)
export const isFootballBasicStatus = (code: number) =>
  code === FIRST_HALF_CODE || code === HALF_TIME_CODE || code === SECOND_HALF_CODE || code === ENDED_CODE

// Determines if two given events are equal. Status, current period start timestamp and scores are
// compared because only that parts of event are editable.
export const areEventsEqual = (nextEvent: BasicEvent, prevEvent?: BasicEvent) => {
  if (prevEvent) {
    if (nextEvent.homeScore && prevEvent.homeScore && nextEvent.awayScore && prevEvent.awayScore) {
      const nextEventHomeScoreKeys = Object.keys(nextEvent.homeScore)
      const prevEventHomeScoreKeys = Object.keys(prevEvent.homeScore)
      const nextEventAwayScoreKeys = Object.keys(nextEvent.awayScore)
      const prevEventAwayScoreKeys = Object.keys(prevEvent.awayScore)

      let homeScoreValuesAreSame = true
      for (let i = 0; i < nextEventHomeScoreKeys.length; i++) {
        if (nextEvent.homeScore[nextEventHomeScoreKeys[i]] !== prevEvent.homeScore[prevEventHomeScoreKeys[i]]) {
          homeScoreValuesAreSame = false
        }
      }

      let awayScoreValuesAreSame = true
      for (let i = 0; i < nextEventAwayScoreKeys.length; i++) {
        if (nextEvent.awayScore[nextEventAwayScoreKeys[i]] !== prevEvent.awayScore[prevEventAwayScoreKeys[i]]) {
          awayScoreValuesAreSame = false
        }
      }

      const statusesAreSame = nextEvent.status.code === prevEvent.status.code

      const timestampsAreSame = nextEvent.currentPeriodStartTimestamp === prevEvent.currentPeriodStartTimestamp

      const aggregatedWinnerCodeAreSame = nextEvent.aggregatedWinnerCode === prevEvent.aggregatedWinnerCode

      let roundInfoIsSame = true
      if (nextEvent.roundInfo && prevEvent.roundInfo) {
        roundInfoIsSame =
          nextEvent.roundInfo.round === prevEvent.roundInfo.round &&
          nextEvent.roundInfo.name === prevEvent.roundInfo.name
      }

      const startTimestampsAreSame = nextEvent.startTimestamp === prevEvent.startTimestamp

      return (
        homeScoreValuesAreSame &&
        awayScoreValuesAreSame &&
        statusesAreSame &&
        timestampsAreSame &&
        aggregatedWinnerCodeAreSame &&
        roundInfoIsSame &&
        startTimestampsAreSame
      )
    }
  }

  return true
}

export const isPeriodStatus = (statusValue: Option | null) => {
  return statusValue && statusValue.value && periodStatuses.includes(Number(statusValue.value))
}

export const isTimestampValid = (currentPeriodStartTime: Date | null) => {
  return currentPeriodStartTime && isDateValid(currentPeriodStartTime)
}

//----------------------------------Match details header-----------------------------------------------
//These functions determine whether the save button within the match details header is enabled/disabled

export const isEventRoundValid = (event: BasicEvent, numberOfRounds: number | null) => {
  if (event.roundInfo) {
    const { roundInfo } = event
    if (typeof roundInfo.round === 'number' && !roundInfo.name) {
      let maxRound = MAX_ROUND
      if (typeof numberOfRounds === 'number') {
        maxRound = numberOfRounds
      }
      return roundInfo.round >= MIN_ROUND && roundInfo.round <= maxRound
    }
  }
  return true
}

export const isStartTimestampValid = (event: BasicEvent) => typeof event.startTimestamp === 'number'

// For period statuses currentPeriodStartTimestamp is required
export const isCurrentPeriodStartTimestampValid = (event: BasicEvent) =>
  periodStatuses.includes(event.status.code) ? Boolean(event.currentPeriodStartTimestamp) : true
