/**
 * Resizing image to specific dimension (width and height are equal).
 * Image with dimensions 200 x 560:
 *  - resize to 560 x 560
 *  - resize to 150 x 150
 * @param base64Str encoded data
 * @param imageDim dimension of resized image
 */
export const resizeImage = (base64Str: string, imageDim: number) => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.src = base64Str as string
    image.onload = () => {
      // Find bigger dimension
      let resizedDimension = Math.max(image.width, image.height)
      // For images where both dimensions are smaller than final image dimension,
      // set resized dimension to final dimension
      if (resizedDimension < imageDim) {
        resizedDimension = imageDim
      }
      const canvas = document.createElement('canvas')
      // canvas.style.display = 'none'
      const context = canvas.getContext('2d')
      // Resizing canvas to greater dimension
      canvas.height = resizedDimension
      canvas.width = resizedDimension
      // calculate x and y offset for image displayed in canvas
      const dx = (resizedDimension - image.width) / 2
      const dy = (resizedDimension - image.height) / 2
      // First image resize, 300 x 500 -> 500 x 500 with calculated x and y offset
      context!.drawImage(image, dx, dy, image.width, image.height)
      const imageResized = new Image()
      // Resized image encoded data
      imageResized.src = canvas.toDataURL('image/png')
      imageResized.onload = async () => {
        // Setting width and height of canvas to final dimension
        canvas.height = imageDim
        canvas.width = imageDim
        // Resize already resized image to final dimension (150 x 150)
        context!.drawImage(imageResized, 0, 0, imageDim, imageDim)
        resolve(canvas.toDataURL('image/png'))
      }
    }
    image.onerror = reject
  })
}
