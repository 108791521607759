import * as React from 'react'

import { IconBase, IconProps } from './Base'

export const IconUser = (props: IconProps) => (
  <IconBase width={24} height={24} viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M12 4a4 4 0 1 1 0 8 4 4 0 0 1 0-8zm0 10c4.42 0 8 1.79 8 4v2H4v-2c0-2.21 3.58-4 8-4z"
        fill={props.fill || '#03519E'}
        fillRule="evenodd"
      />
    </g>
  </IconBase>
)
