import { Text } from '@sofascore/ui'
import styled from 'styled-components'

import { RED, textColorRgbaDark, backgrounds } from 'styles/color'

export const PlayerGroupItemName = styled(Text)<{ $lineThrough: boolean | undefined }>`
  text-decoration: ${p => (p.$lineThrough ? 'line-through' : undefined)};
  color: ${p => (p.$lineThrough ? backgrounds.lightRgba : undefined)};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
`

export const PlayerGroupItemPosition = styled(Text)`
  display: inline-block;
  margin-top: 4px;
  font-size: 12px;
  color: ${textColorRgbaDark};
`

export const PlayerGroupItemStatus = styled(Text)`
  font-size: 12px;
  color: ${RED.incident};
  margin-top: 4px;
`
