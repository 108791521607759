import { IncidentPenaltyShootoutType } from 'entities/Incident'
import { ReactNode } from 'react'
import IconAdd from '@sofascore/ui/dist/modules/Icons/IconAdd'
import IconPenaltyMissed from '@sofascore/ui/dist/modules/Icons/_IconPenaltyMissed'
import IconFootballPenalty from '@sofascore/ui/dist/modules/Icons/_IconFootballPenalty'

import { GREEN, RED } from 'styles/color'

import * as S from './AddShootoutPenaltyButton.styles'

interface AddShootoutPenaltyButtonProps {
  onClick: () => void
  enabled?: boolean
  id: number | undefined
  incidentClass: string | undefined
  scoredIcon?: ReactNode
  missedIcon?: ReactNode
  hideBackgroundOnUse?: boolean
}

export const AddShootoutPenaltyButton = ({
  enabled,
  id,
  incidentClass,
  onClick,
  scoredIcon = <IconFootballPenalty fill={GREEN.incident} />,
  missedIcon = <IconPenaltyMissed fill={RED.incident} />,
  hideBackgroundOnUse,
}: AddShootoutPenaltyButtonProps) => {
  return (
    <S.AddPenaltyButton
      $isDisabled={!enabled}
      $isUsed={!!id}
      $hideBackgroundOnUse={hideBackgroundOnUse}
      onClick={onClick}
      disabled={!enabled}
      key={id}
    >
      {incidentClass === IncidentPenaltyShootoutType.Scored && scoredIcon}
      {incidentClass === IncidentPenaltyShootoutType.Missed && missedIcon}
      {!incidentClass && <IconAdd />}
    </S.AddPenaltyButton>
  )
}
