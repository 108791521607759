import { Theme, Dialog, DialogTitle, Typography, DialogActions, Button, useTheme } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { EntityType } from 'entities/EntityType'

import { createUserSuggestion } from 'api'

import { RemovePlayerProps } from './interface'
import { useStyles } from './styles'

export const RELEASED_TRANSFER_TYPE = 6

const RemovePlayer = ({ handleClose, isOpen, playerName, playerId, openConfirmationDialog }: RemovePlayerProps) => {
  const theme: Theme = useTheme()
  const classes = useStyles()

  const removePlayerUserSuggestion = () => {
    try {
      createUserSuggestion(EntityType.PLAYER, playerId, {
        editor: true,
        transfer: { type: RELEASED_TRANSFER_TYPE, timestamp: Math.round(new Date().getTime() / 1000) },
      })
    } catch (e) {
      console.error(e)
    }
    handleClose()
    openConfirmationDialog()
  }

  return (
    <Dialog open={isOpen} classes={{ paper: classes.dialogPaper }} onClose={handleClose}>
      <DialogTitle style={{ backgroundColor: 'transparent', color: theme.palette.text.primary }}>
        <Typography style={{ fontSize: 16, fontWeight: 500, textAlign: 'center' }}>
          <FormattedMessage id="removePlayerFromTeam" values={{ playerName }} />
        </Typography>
      </DialogTitle>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button
          variant="outlined"
          onClick={handleClose}
          style={{ width: 132, height: 48, marginRight: 16, fontSize: 14 }}
        >
          <FormattedMessage id="back" />
        </Button>
        <Button variant="contained" style={{ width: 132, height: 48 }} onClick={removePlayerUserSuggestion}>
          <FormattedMessage id="remove" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RemovePlayer
