import { Team } from 'entities/Team'
import { useToast } from 'shared/lib'
import { EntityType } from 'entities/EntityType'
import { EditorSuggestionAction, SuggestionData } from 'entities/Suggestion'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { FirebaseEventName, FirebaseEventType } from 'shared/lib/firebase/model'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'

import useConfirm from 'modules/ConfirmDialog/useConfirm'
import { createSuggestion } from 'api'

import { confirmDialogConfig } from '../config'

export const useRemoveTeamHandler = () => {
  const { confirm } = useConfirm()
  const { enqueueToast } = useToast()
  const { competitionId, seasonId } = useParams()

  const intl = useIntl()

  const removeTeam = async (team: Team, tournamentId: number) => {
    if (competitionId && seasonId) {
      const { sport, country, name, id } = team

      const suggestionData: SuggestionData = {
        entity: EntityType.TEAM,
        name,
        sport: sport.slug,
        action: EditorSuggestionAction.DELETE,
        uniqueTournamentId: +competitionId,
        tournamentId: tournamentId,
        valueMap: {
          seasonId: +seasonId,
          teamId: id,
          country: {
            alpha2: country!.alpha2!,
          },
        },
      }

      const ok = await confirm(confirmDialogConfig)

      if (ok) {
        createSuggestion(suggestionData).then(() =>
          enqueueToast(
            `${intl.formatMessage({ id: 'requestSent' })} ${intl.formatMessage({ id: 'suggestionSentMessage' })}`,
            { variant: 'success' },
          ),
        )

        logFirebaseEvent(FirebaseEventName.DeleteEntity, {
          type: FirebaseEventType.Team,
          id: team.id,
          sport: team.sport.name,
        })
      }
    }
  }

  return removeTeam
}
