/* eslint-disable react-hooks/exhaustive-deps */
import { CSSProperties, ReactElement, ReactNode, useCallback, useEffect, useState } from 'react'
import IconChevronLeft from '@sofascore/ui/dist/modules/Icons/_IconChevronLeft'
import IconChevronRight from '@sofascore/ui/dist/modules/Icons/_IconChevronRight'
import { FlexProps } from '@sofascore/ui'

import { secondaryColor } from 'styles/color'

import * as S from './styles'
import useCallbackRef from './useCallbackRef'

enum ScrollPosition {
  Left = 'left',
  Middle = 'middle',
  Right = 'right',
}

// Determines if the passed element is overflowing its bounds,
// either vertically or horizontally.
// Will temporarily modify the "overflow" style to detect this
// if necessary.
function checkOverflow(el: HTMLElement) {
  const curOverflow = el.style.overflow
  if (!curOverflow || curOverflow === 'visible') el.style.overflow = 'hidden'
  const isOverflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight
  el.style.overflow = curOverflow
  return isOverflowing
}

function getScrollPosition(el: HTMLElement): ScrollPosition {
  if (el.scrollLeft === 0) {
    return ScrollPosition.Left
  } else if (el.scrollWidth - el.clientWidth <= el.scrollLeft) {
    return ScrollPosition.Right
  } else {
    return ScrollPosition.Middle
  }
}

interface ArrowSliderProps extends FlexProps {
  w: CSSProperties['width']
  initialIndex?: number
  /* Percentage of the scrollable container to scroll horizontally */
  pctScrolled?: number
  LeftControl?: ReactElement
  RightControl?: ReactElement
  children: ReactNode
}

/**
 * Component adds support for horizontal scroll to a container
 * of fixed size which children would overflow otherwise.
 */
function ArrowSlider({
  w,
  initialIndex,
  pctScrolled,
  LeftControl,
  RightControl,
  children,
  ...flexProps
}: ArrowSliderProps) {
  const [node, callback] = useCallbackRef([])
  const [scrollPosition, setScrollPosition] = useState<ScrollPosition | null>(null)
  const [wasScrolled, setWasScrolled] = useState(false)

  const alignIntoView = useCallback(
    (index: number) => {
      const targetElement = node?.children?.item(index)

      if (!targetElement) {
        return
      }

      targetElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
      setWasScrolled(true)
    },
    [node],
  )

  /**
   * Scroll clicked element into the center of the container.
   * On complete call the `onScroll` callback.
   */
  const slideIntoView = useCallback(
    (index: number) => {
      const targetElement = node?.children?.item(index)

      if (!targetElement) {
        return
      }

      targetElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
      setWasScrolled(true)
    },
    [node],
  )

  const slideByValue = useCallback(
    (value: number) => {
      const targetElement = node

      if (!targetElement) {
        return
      }

      targetElement.scrollBy({ left: value, behavior: 'smooth' })
      setWasScrolled(true)
    },
    [node],
  )

  const scrollLeft = useCallback(() => {
    const scrollable = node
    if (!scrollable) return

    if (pctScrolled && scrollable && scrollable.scrollLeft - pctScrolled * scrollable.clientWidth > 0) {
      slideByValue(-pctScrolled * scrollable.clientWidth)
    } else {
      slideIntoView(0)
    }
  }, [slideIntoView])

  const scrollRight = useCallback(() => {
    const scrollable = node
    if (!scrollable) return

    if (
      pctScrolled &&
      scrollable.scrollLeft + pctScrolled * scrollable.clientWidth < scrollable.scrollWidth - scrollable.clientWidth
    ) {
      slideByValue(pctScrolled * scrollable.clientWidth)
    } else {
      slideIntoView(scrollable.children.length - 1)
    }
  }, [children, slideIntoView])

  const updateScrollPosition = useCallback(() => {
    if (node) {
      const overflow = checkOverflow(node as HTMLElement)

      if (overflow) {
        setScrollPosition(getScrollPosition(node as HTMLElement))
      }
    }
  }, [node])

  useEffect(() => {
    if (node) {
      node.addEventListener('scrollend', updateScrollPosition, { once: true })

      updateScrollPosition()
    }
  }, [node])

  useEffect(() => {
    if (typeof initialIndex === 'number' && initialIndex > 0) {
      alignIntoView(initialIndex)
    }
  }, [initialIndex, slideIntoView])

  useEffect(() => {
    // Wait until the component scrolls itself
    if (wasScrolled) {
      setTimeout(() => {
        updateScrollPosition()
        setWasScrolled(false)
      }, 500)
    }
  }, [wasScrolled])

  return (
    <S.Scrollable w={w}>
      {!!scrollPosition && scrollPosition !== ScrollPosition.Left && (
        <S.SlideButton onClick={scrollLeft} side="left">
          {LeftControl || <IconChevronLeft fill={secondaryColor} w={22} h={22} />}
        </S.SlideButton>
      )}

      <S.ScrollableContent ref={callback} {...flexProps}>
        {children}
      </S.ScrollableContent>

      {!!scrollPosition && scrollPosition !== ScrollPosition.Right && (
        <S.SlideButton onClick={scrollRight} side="right">
          {RightControl || <IconChevronRight fill={secondaryColor} w={22} h={22} />}
        </S.SlideButton>
      )}
    </S.Scrollable>
  )
}

export default ArrowSlider
