import { AnyAction } from 'redux'
import { createContext, useContext, useReducer } from 'react'

import { MyCompetitionsState, myCompetitionsReducer } from './reducer'

const MyCompetitionsStateContext = createContext<MyCompetitionsState>({} as MyCompetitionsState)

const MyCompetitionsDispatchContext = createContext<React.Dispatch<AnyAction> | null>(null)

const initialState: MyCompetitionsState = {
  competitionsWithSeasons: [],
  uniqueTournament: undefined,
  tournaments: [],
}

export const MyCompetitionsProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(myCompetitionsReducer, initialState)

  return (
    <MyCompetitionsStateContext.Provider value={state}>
      <MyCompetitionsDispatchContext.Provider value={dispatch}>{children}</MyCompetitionsDispatchContext.Provider>
    </MyCompetitionsStateContext.Provider>
  )
}

export const useMyCompetitionsState = (): MyCompetitionsState => {
  const context = useContext(MyCompetitionsStateContext)

  if (!context) {
    throw new Error('useMyCompetitionsState must be used within a MyCompetitionsProvider')
  }

  return context
}

export const useMyCompetitionsDispatch = () => {
  const context = useContext(MyCompetitionsDispatchContext)

  if (!context) {
    throw new Error('useMyCompetitionsDispatch must be used within a MyCompetitionsProvider')
  }

  return context
}
