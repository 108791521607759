import { Box, Flex, Text, Toggle } from '@sofascore/ui'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'
import { FirebaseEventName } from 'shared/lib/firebase/model'

import { Button } from 'components/Button'
import ResponsivePopUp from 'components/ResponsivePopUp'

interface Props {
  resultOnlyMode: boolean
  setResultOnlyMode: (arg0: boolean) => void
}

export const ChangeResultOnlyMode = ({ resultOnlyMode, setResultOnlyMode }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  const toggleConfirm = () => {
    if (resultOnlyMode) {
      setResultOnlyMode(false)
    } else {
      setResultOnlyMode(true)
    }
    logFirebaseEvent(FirebaseEventName.ResultOnlyMode, {
      status: resultOnlyMode ? 'off' : 'on',
    })
    setIsOpen(false)
  }

  return (
    <>
      <Box bg="surface.s1" p="lg" br="sm" elevation={1} cursor="pointer" onClick={toggleOpen}>
        <Flex>
          <Flex w="100%">
            <Text font="display.small">
              <FormattedMessage id="result_only_mode" />
            </Text>
          </Flex>
          <Flex>
            <Toggle checked={resultOnlyMode} onChange={() => setIsOpen(true)} />
          </Flex>
        </Flex>
      </Box>

      <ResponsivePopUp
        isOpen={isOpen}
        onClose={toggleOpen}
        heading={
          <Text font="display.large" ml="sm">
            {resultOnlyMode ? (
              <FormattedMessage id="turn_off_result_mode_title" />
            ) : (
              <FormattedMessage id="turn_on_result_mode_title" />
            )}
          </Text>
        }
      >
        <Box px="sm" pb="xxl">
          <Box px="xxl" pt="lg" pb="xs" br="sm" textAlign="center">
            <Text font="body.medium">
              {resultOnlyMode ? (
                <FormattedMessage id="turn_off_result_mode_text" />
              ) : (
                <FormattedMessage id="turn_on_result_mode_text" />
              )}
            </Text>
          </Box>

          <Flex gapX="lg" px="lg" mt="xxl">
            <Button variant="outlined" fullWidth onClick={toggleOpen}>
              <FormattedMessage id="cancel" />
            </Button>
            <Button variant="contained" fullWidth onClick={toggleConfirm}>
              {resultOnlyMode ? <FormattedMessage id="turn_off_button" /> : <FormattedMessage id="turn_on_button" />}
            </Button>
          </Flex>
        </Box>
      </ResponsivePopUp>
    </>
  )
}
