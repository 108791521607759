import React from 'react'
import { useDispatch, connect } from 'react-redux'
import { Typography, useTheme, Accordion, AccordionSummary, AccordionDetails, Box } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { FormattedMessage, useIntl } from 'react-intl'
import { RoundInfo } from 'entities/Event'

import { RootState } from 'store/store'
import { NUMBER_ROUND, STAGE_ROUND } from 'modules/Matches/const'
import RoundsForm from 'modules/Matches/components/RoundsForm/RoundsForm'
import { Option } from 'components/DropdownMenu/interface'
import { SET_ROUND_INFO } from 'modules/Matches/matchesReducer'
import { useStyles } from 'modules/Matches/styles'
import { backgrounds } from 'styles/color'
import { useMyCompetitionsState } from 'modules/MyCompetitions/providers/MyCompetitionsProvider'

interface Props {
  roundInfo: RoundInfo | null
}

const Rounds = ({ roundInfo }: Props) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyles()

  const { uniqueTournament } = useMyCompetitionsState()

  const roundType = uniqueTournament?.hasGroups ? STAGE_ROUND : NUMBER_ROUND

  const handleRoundNumberChange = (e: React.ChangeEvent) => {
    const roundNum = Number((e.target as HTMLInputElement).value)
    dispatch({
      type: SET_ROUND_INFO,
      payload: {
        roundInfo: {
          round: roundNum,
        },
      },
    })
  }

  const intl = useIntl()

  const handleClickRoundMenuItem = (selectedOption: Option) =>
    dispatch({
      type: SET_ROUND_INFO,
      payload: { roundInfo: { name: selectedOption.value } },
    })

  if (!roundInfo) {
    return null
  }

  let roundValue: Option | null = null
  if (roundInfo.name) {
    roundValue = {
      label: intl.formatMessage({ id: roundInfo.name }),
      value: 0,
    }
  }

  let roundNumber = null
  // Basic round (Round 1, Round 2, etc) don't have `name` property
  // Stage round (1/16, 1/32, etc) have `name` and `round` property
  if (roundType === NUMBER_ROUND && typeof roundInfo.round === 'number' && !roundInfo.name) {
    roundNumber = roundInfo.round
  }

  return (
    <Accordion className={classes.expansionPanel} style={{ backgroundColor: backgrounds.content }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: theme.palette.secondary.main }} />}
        className={classes.expansionPanelSummary}
      >
        <Typography className={classes.heading}>
          <FormattedMessage id={roundType === STAGE_ROUND ? 'stage' : 'round'} />
        </Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.expansionPanelDetailsRoot }}>
        <Box width={`calc(33% + ${theme.spacing(2)}px)`}>
          <RoundsForm
            roundType={roundType}
            stageRoundValue={roundValue}
            roundNumber={roundNumber}
            handleRoundNumberChange={handleRoundNumberChange}
            handleClickRoundMenuItem={handleClickRoundMenuItem}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

const areStatesEqual = (nextState: RootState, prevState: RootState) => {
  if (!nextState.event || !prevState.event) {
    return false
  }

  if (nextState.event.roundInfo && prevState.event.roundInfo) {
    const nextRoundInfo = nextState.event.roundInfo
    const prevRoundInfo = prevState.event.roundInfo
    return nextRoundInfo.round === prevRoundInfo.round && nextRoundInfo.name === prevRoundInfo.name
  }

  return nextState.event.id === prevState.event.id
}

const mapStateToProps = ({ event }: RootState): Props => ({
  roundInfo: event && event.roundInfo ? event.roundInfo : null,
})

export default connect<Props, null, unknown, RootState>(mapStateToProps, null, null, { areStatesEqual })(Rounds)
