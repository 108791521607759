import { useContext } from 'react'

import { ConfirmContext, ConfirmDialogPayload, HIDE_CONFIRM, SHOW_CONFIRM } from './ConfirmContextProvider'

let resolveCallback: (value: boolean) => void

export default function useConfirm() {
  const { state, dispatch } = useContext(ConfirmContext)

  const onConfirm = () => {
    resolveCallback(true)
    closeConfirm()
  }

  const onCancel = () => {
    resolveCallback(false)
    closeConfirm()
  }

  const confirm = (config: ConfirmDialogPayload): Promise<boolean> => {
    dispatch({
      type: SHOW_CONFIRM,
      payload: config,
    })

    return new Promise(res => {
      resolveCallback = res
    })
  }

  const closeConfirm = () => {
    dispatch({
      type: HIDE_CONFIRM,
    })
  }

  return { confirm, onConfirm, onCancel, state }
}
