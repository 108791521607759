import styled from 'styled-components'

export const SettingsButton = styled.button`
  display: flex;
  background: transparent;
  border: transparent;
  cursor: pointer;
  margin-right: 32px;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
  &:hover svg {
    fill: ${p => p.theme.colors.primary.default};
  }
  ${p => p.theme.breakpoints.query.mdMax`
    margin-right: 0;
    margin-bottom: 32px;
  `}
  svg {
    margin-right: 8px;
    fill: ${p => p.theme.colors.onSurface.nLv1};
  }
`
