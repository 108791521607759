const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

/**
 * Get builtin `Date` from timestamp in seconds or milliseconds;
 * converts unix timestamps into milliseconds implicitly.
 */
export const getDate = (timestamp: number): Date => {
  // ensure timestamp is in milliseconds
  if (String(timestamp).length < 11) {
    timestamp *= 1000
  }
  return new Date(timestamp)
}

/**
 * Gets shorter month name
 */
export const getMonth = (date: Date): string => months[date.getMonth()]

/**
 * Gets current time in day in format: `hour`:`minute`
 */
export const getTimeInDay = (date: Date): string => {
  const currentHour = date.getHours()
  const currentMinute = date.getMinutes()
  const hour = currentHour < 10 ? `0${date.getHours()}` : currentHour
  const minute = currentMinute < 10 ? `0${currentMinute}` : currentMinute

  return `${hour}:${minute}`
}

/**
 * E.g. 04 Nov 1998
 *
 * Formats:
 * "dd/mm/yyyy" - 04/11/1998
 * "month dd. year" - Jun 16. 2019
 *
 * @param date
 * @param config
 * @returns
 */
type DateFormat = 'dd/mm/yyyy' | 'dd/mm/yy' | 'month dd. year'
export const formatDate = (date: Date, config: { format?: DateFormat } = { format: 'month dd. year' }): string => {
  const dd = `0${date.getDate()}`.slice(-2)
  const mm = `0${date.getMonth() + 1}`.slice(-2)

  switch (config.format) {
    default:
    case 'dd/mm/yy':
      return `${dd}/${mm}/${`${date.getFullYear()}`.slice(-2)}`
    case 'dd/mm/yyyy':
      return `${dd}/${mm}/${date.getFullYear()}`
    case 'month dd. year':
      return `${getMonth(date)} ${date.getDate()}. ${date.getFullYear()}`
  }
}

/**
 * Returns date formatted as: `YYYY-MM-DD`.
 */
export function getYYYYMMDDFormat(date: Date | number) {
  if (typeof date === 'number') {
    date = getDate(date)
  }
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  return `${year}-${('0' + month).slice(-2)}-${('0' + day).slice(-2)}`
}

// Result: 04:12
export const formatTime = (date: Date): string => {
  const minute = date.getMinutes()
  const hour = date.getHours()
  const formattedMin = minute < 10 ? `0${minute}` : minute
  const formattedHour = hour < 10 ? `0${hour}` : hour
  return `${formattedHour}:${formattedMin}`
}

export const isDateValid = (date: Date) => date instanceof Date && !isNaN(date.getTime())

/**
 * Returns an amount in seconds expressed
 * as an array of `[days, hours, minutes, seconds]`.
 */
export const getTimeParts = (seconds: number) => {
  const s = Math.floor(seconds % 60)
  const m = Math.floor(seconds / 60) % 60
  const h = Math.floor(seconds / 3600) % 24
  const d = Math.floor(seconds / 3600 / 24)
  return [d, h, m, s]
}

/**
 * Returns whether timestamps are on same day with respect to timezone
 */
export function isOnSameDay(date1: number, date2: number) {
  return getDate(date1).toDateString() === getDate(date2).toDateString()
}

/**
 * Returns true if `Date` or timestamp in seconds or milliseconds is today.
 */
export function isToday(date: Date | number) {
  if (typeof date !== 'number') {
    date = date.getTime()
  }
  return isOnSameDay(date, Date.now())
}

/**
 * Returns true if `Date` or timestamp in seconds or milliseconds is tomorrow.
 */
export function isTomorrow(date: Date | number) {
  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)

  if (typeof date === 'number') {
    date = getDate(date)
  }
  return date.toDateString() === tomorrow.toDateString()
}

/**
 * This function corrects the selected date timezone offset.
 * Date selectors select the dates at their start (midnight) and relative to the user's local time.
 * Therefore, UTC time for timezones with positive offset will fall on the day before.
 *
 * @param date JS Date object or timestamp in milliseconds
 * @returns UTC timestamp in chosen time format
 */
export function getUTCDate(date: Date | number, config?: { timeFormat: 's' | 'ms' }): number {
  const localDatetime = typeof date === 'number' ? new Date(date) : date

  // Shift UTC time right on the midnight
  const utcTimestamp = localDatetime.getTime() - localDatetime.getTimezoneOffset() * 60 * 1000

  switch (config?.timeFormat) {
    case 's':
      return Math.floor(utcTimestamp / 1000)
    case 'ms':
    default:
      return utcTimestamp
  }
}
