import { Box, Flex } from '@sofascore/ui'
import styled, { css } from 'styled-components'

import { pulseKeyframes } from 'styles/animations'
import { backgrounds, textColorRgbaDark } from 'styles/color'

const blinkStyles = css`
  animation: ${pulseKeyframes} 1s ease-in infinite;
`

export const PeriodSwitcher = styled.div`
  display: flex;
  gap: 8px;
`

export const PeriodBox = styled(Box)<{ $isActive?: boolean; $isDisabled?: boolean }>`
  flex-basis: 72px;
  width: 72px;
  min-height: 72px;
  flex-grow: 0;
  flex-shrink: 0;

  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  background-color: ${p => p.bg || (p.$isActive ? '#fff' : backgrounds.chip)};
  color: ${p => p.c || (p.$isDisabled ? textColorRgbaDark : undefined)};
  border-width: 1px;
  border-style: solid;
  border-color: ${p => (p.$isActive ? '#333' : 'transparent')};
  border-radius: 4px;
  padding: 8px;
  cursor: ${p => (p.$isDisabled ? 'not-allowed' : 'pointer')};
  transition: all 0.2s;

  &:hover {
    border-color: ${p => (p.$isDisabled ? undefined : '#333')};
  }

  &::after {
    content: '';
    display: ${p => (p.$isActive ? 'block' : 'none')};
    width: 4px;
    height: 4px;
    position: absolute;
    top: 4px;
    right: 4px;
    background-color: red;
    border-radius: 50%;

    ${p => p.$isActive && blinkStyles};
  }
`

export const PeriodBoxContent = styled(Flex)`
  justify-content: center;
  align-items: center;
  white-space: normal;
`
