import { DATE_DISPLAY_COUNT } from './config'

export const getQuickDateRange = (selectedDate: Date) => {
  const millisecondsInDay = 24 * 3600 * 1000

  const dateRange = []
  const centralRangeIndex = Math.floor((DATE_DISPLAY_COUNT - 1) / 2)

  for (let i = 0; i < DATE_DISPLAY_COUNT; i++) {
    if (i < centralRangeIndex) {
      dateRange.push(new Date(selectedDate.getTime() - millisecondsInDay * (centralRangeIndex - i)))
    } else if (i === 7) {
      dateRange.push(selectedDate)
    } else {
      dateRange.push(new Date(selectedDate.getTime() + millisecondsInDay * (i - centralRangeIndex)))
    }
  }

  return dateRange
}
