import { Flex } from '@sofascore/ui'
import { DynamicLineupsPlayer } from 'features/AddLineups'

import { TERRAIN_HEIGHT } from '../config'
import { PitchBackground, PitchLines } from './PitchGraphics'
import { RugbyPositionCoordinate } from '../model'
import * as S from './styles'
import { DragAndDropSlot } from './DesktopDragAndDropSlot'

export const DragAndDropPitch = ({
  lineupPlayers,
  kit,
  onDrop,
  onRemove,
  positions,
}: {
  lineupPlayers: DynamicLineupsPlayer[]
  kit: string
  onDrop: (player: DynamicLineupsPlayer, position: number) => void
  onRemove: (player: DynamicLineupsPlayer) => void
  positions: RugbyPositionCoordinate[]
}) => {
  return (
    <S.DragAndDropPitch>
      <PitchBackground />
      <PitchLines />

      <Flex direction="column" justify="space-between" position="relative" h={TERRAIN_HEIGHT} p="sm">
        {positions.map(coord => {
          return (
            <DragAndDropSlot
              key={coord.position}
              lineupPlayers={lineupPlayers}
              kitColor={kit}
              position={coord.position}
              onDrop={onDrop}
              onRemove={onRemove}
              style={{ position: 'absolute', top: coord.y, left: coord.x }}
            />
          )
        })}
      </Flex>
    </S.DragAndDropPitch>
  )
}
