import { Flex, Image, Text } from '@sofascore/ui'
import { IncidentType, useEventIncidentsState } from 'entities/Incident'
import { useEventState } from 'entities/Event'
import { ReactNode } from 'react'
import { BasicTeam } from 'entities/Team'

import { teamImage } from 'api/routes'
import ArrowSlider from 'components/ArrowSlider'

import { groupPenaltiesBySequence } from '../utils'
import { IncidentForButton } from '../model'
import * as S from './styles'

interface Props {
  numberOfPenaltySeries: number
  children: (
    homeSequence: IncidentForButton,
    homeTeam: BasicTeam,
    awaySequence: IncidentForButton,
    awayTeam: BasicTeam,
    sequence: number,
  ) => ReactNode
}

export const PenaltyShootoutSection = ({ numberOfPenaltySeries, children }: Props) => {
  const { event } = useEventState()
  const { incidents } = useEventIncidentsState()
  const { homeTeam, awayTeam, homeScore, awayScore } = event

  const penaltyShootoutIncidents = incidents
    ? incidents.filter(incident => incident.incidentType === IncidentType.PenaltyShootout)
    : []

  const penaltyGroups = groupPenaltiesBySequence(incidents || [], numberOfPenaltySeries)

  const sequence = penaltyShootoutIncidents ? (penaltyShootoutIncidents[0]?.sequence || 0) + 1 : 1

  return (
    <Flex bg="surface.s1" br="sm" elevation={1}>
      <S.TeamLogos flex="0 0 56px" direction="column" align="center" py="lg">
        <Flex direction="column" justify="center">
          <Image src={teamImage(homeTeam.id)} w="24" h="24" mb="xl" alt={homeTeam.name} />
          <Image src={teamImage(awayTeam.id)} w="24" h="24" alt={awayTeam.name} />
        </Flex>
      </S.TeamLogos>

      <S.Buttons minW={0} grow={1}>
        <ArrowSlider w="100%" initialIndex={penaltyGroups.length - 1}>
          {penaltyGroups.map(([home, away], index) => (
            <Flex key={index} direction="column">
              {children(home, homeTeam, away, awayTeam, sequence)}
            </Flex>
          ))}
        </ArrowSlider>
      </S.Buttons>

      <S.Results flex="0 0 56px" direction="column" justify="center">
        <Flex direction="column" justify="center" align="center">
          <Text font="mainNavigationTitle" mb="20px">
            {homeScore.penalties || 0}
          </Text>
          <Text font="mainNavigationTitle">{awayScore.penalties || 0}</Text>
        </Flex>
      </S.Results>
    </Flex>
  )
}
