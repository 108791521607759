import { Player, PlayerCard } from 'entities/Player'
import { PropsWithChildren } from 'react'

interface Props {
  player: Player
}

const SuggestedPlayer = ({ player, children }: PropsWithChildren<Props>) => {
  return (
    <PlayerCard player={player} showJerseyNumber>
      {children}
    </PlayerCard>
  )
}

export default SuggestedPlayer
