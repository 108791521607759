import styled from 'styled-components'

export const AddPenaltyButton = styled.button<{
  $isDisabled?: boolean
  $isUsed?: boolean
  $hideBackgroundOnUse?: boolean
}>`
  position: relative;
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  background-color: ${p => (p.$isUsed && p.$hideBackgroundOnUse ? 'transparent' : p.theme.colors.surface.s2)};
  border: 1px solid ${p => (p.$isUsed && p.$hideBackgroundOnUse ? 'transparent' : p.theme.colors.surface.s2)};
  border-radius: 4px;
  padding: 4px;
  margin: 0 8px;
  cursor: ${p => (p.$isDisabled ? 'default' : 'pointer')};
  transition: all 0.2s;

  &:first-child {
    margin-bottom: 16px;
  }

  &:hover {
    border-color: ${p =>
      p.$isUsed && p.$hideBackgroundOnUse ? 'transparent' : p.$isDisabled ? p.theme.colors.surface.s2 : '#333333'};
  }

  & > * {
    opacity: ${p => (p.$isDisabled && !p.$isUsed ? 0.3 : 1)};
  }
`
