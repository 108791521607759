import { RefObject, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useFirebaseWithEvent } from 'entities/Event/hooks'
import { FirebaseEventName, FirebaseEventType } from 'shared/lib/firebase/model'
import { useMediaContext, useWidth } from '@sofascore/ui'

import { Button } from 'components/Button'

import * as S from './SaveAllChanges.styles'

interface Props {
  onSubmit: () => Promise<void>
  parentRef: RefObject<HTMLDivElement>
}

export const SaveAllChanges = ({ onSubmit, parentRef }: Props) => {
  const [isSaving, setIsSaving] = useState(false)
  const { isMobile } = useMediaContext()
  const parentWidth = useWidth(parentRef, {}, 0, [isMobile])
  const logFirebaseWithEvent = useFirebaseWithEvent()

  const handleConfirm = () => {
    setIsSaving(true)
    onSubmit().finally(() => {
      logFirebaseWithEvent(FirebaseEventName.SubmitEntity, FirebaseEventType.Event)
      setIsSaving(false)
    })
  }

  return (
    <S.SaveAllChanges
      p={['xl', 0]}
      backgroundColor={['surface.s1', 'transparent']}
      left={[0, 344 + 48 + 16 + (parentWidth ? parentWidth / 2 : 0)]}
      w={['100%', 'auto']}
    >
      <Button
        variant="contained"
        fullWidth
        onClick={handleConfirm}
        isLoading={isSaving}
        style={{ padding: '16px 64px' }}
      >
        <FormattedMessage id="save_all_changes" />
      </Button>
    </S.SaveAllChanges>
  )
}
