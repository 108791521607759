import { Flex, Text } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'
import { useState } from 'react'
import IconCreate from '@sofascore/ui/dist/modules/Icons/IconCreate'
import IconTwoMinuteSuspension from '@sofascore/ui/dist/modules/Icons/_Icon2MinuteSuspension'
import { useFirebaseWithEvent } from 'entities/Event/hooks'
import { FirebaseEventName, FirebaseEventType, FirebaseEventIncidentAction } from 'shared/lib/firebase/model'

import ResponsivePopUp from 'components/ResponsivePopUp'
import { IconButton } from 'components/IconButton'

import { EditTwoMinuteSuspensionForm } from './EditTwoMinuteSuspensionForm'
import { EditIncidentProps } from '../../model'

export const EditTwoMinuteSuspension = ({ onEdit, incident }: EditIncidentProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const logFirebaseWithEvent = useFirebaseWithEvent()

  const responsivePopupClose = (discarded?: boolean | undefined) => {
    setIsOpen(false)
    if (discarded)
      logFirebaseWithEvent(
        FirebaseEventName.DiscardEntity,
        FirebaseEventType.Incident,
        FirebaseEventIncidentAction.Suspension,
      )
    else
      logFirebaseWithEvent(
        FirebaseEventName.SubmitEntity,
        FirebaseEventType.Incident,
        FirebaseEventIncidentAction.Suspension,
      )
  }

  return (
    <>
      <IconButton onClick={() => setIsOpen(true)} br="xs" hoverBg="onSurface.nLv5">
        <IconCreate fill="#000000" />
      </IconButton>

      <ResponsivePopUp
        isOpen={isOpen}
        onClose={responsivePopupClose}
        heading={
          <Flex align="center">
            <IconTwoMinuteSuspension />
            <Text font="display.large" ml="sm">
              <FormattedMessage id="edit_2_min_suspension" />
            </Text>
          </Flex>
        }
      >
        <EditTwoMinuteSuspensionForm incident={incident} onClose={responsivePopupClose} onSubmit={onEdit} />
      </ResponsivePopUp>
    </>
  )
}
