import { colors } from '@sofascore/ui'
import styled, { CSSObject } from 'styled-components'

export const borderMergeStyles = (merge?: 'top' | 'bottom'): CSSObject => {
  return {
    borderTopLeftRadius: merge === 'top' ? '0px' : '8px',
    borderTopRightRadius: merge === 'top' ? '0px' : '8px',
    borderBottomLeftRadius: merge === 'bottom' ? '0px' : '8px',
    borderBottomRightRadius: merge === 'bottom' ? '0px' : '8px',
    borderTop: merge === 'top' ? 'none' : `solid 1px ${colors.light['--on-surface-nLv4']}`,
  }
}

export const StyledButton = styled.button<{ merge?: 'top' | 'bottom' }>`
  display: flex;
  justify-content: space-between;

  width: 100%;
  padding: 16px;
  background-color: ${colors.light['--surface-s1']};

  border: solid 1px ${colors.light['--on-surface-nLv4']};
  ${p => borderMergeStyles(p.merge)}

  font-size: 14px;

  cursor: ${p => (!p.disabled ? 'pointer' : 'default')};
`
