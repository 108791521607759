import { Box, Flex, Text } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'
import { DeleteIncidentData, Incident } from 'entities/Incident'
import { useEventState } from 'entities/Event'
import { useState } from 'react'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'
import { FirebaseEventName } from 'shared/lib/firebase/model'

import { Button } from 'components/Button'

interface Props {
  incident: Incident
  onSubmit: (arg0: DeleteIncidentData) => Promise<void>
  onClose: () => void
}

const DeleteIncidentForm = ({ incident, onSubmit, onClose }: Props) => {
  const [isSaving, setIsSaving] = useState(false)

  const { event } = useEventState()
  const { incidentType, incidentClass, isHome, id, homeScore, awayScore } = incident
  const { id: eventId } = event

  const handleConfirm = async () => {
    setIsSaving(true)

    onSubmit({
      eventId,
      incidentType,
      incidentClass,
      incidentId: id as number,
      isHome,
      homeScore,
      awayScore,
    })
      .then(() => {
        logFirebaseEvent(FirebaseEventName.DeleteEntity, {
          id: event.id,
          action: incidentType,
          type: 'incident',
          status: event.status.type,
          sport: event?.tournament?.category?.sport?.name?.toLowerCase(),
        })
        onClose()
      })
      .finally(() => setIsSaving(false))
  }

  return (
    <Box px="sm" pb="xxl">
      <Flex justify="center" p="xxl">
        <Text font="display.medium" fontWeight="regular" align="center">
          <FormattedMessage id="delete_detail_text" />
        </Text>
      </Flex>

      <Flex gapX="lg" px="lg" mt="xl">
        <Button variant="outlined" fullWidth onClick={onClose}>
          <FormattedMessage id="cancel" />
        </Button>
        <Button variant="contained" fullWidth onClick={handleConfirm} isLoading={isSaving}>
          <FormattedMessage id="delete" />
        </Button>
      </Flex>
    </Box>
  )
}

export default DeleteIncidentForm
