import * as React from 'react'

import { IconBase, IconProps } from './Base'

export const IconClose = (props: IconProps) => (
  <IconBase width={24} height={24} viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={props.fill ? props.fill : '#FFF'}
        d="M19 6.4L17.6 5 12 10.6 6.4 5 5 6.4l5.6 5.6L5 17.6 6.4 19l5.6-5.6 5.6 5.6 1.4-1.4-5.6-5.6z"
      />
    </g>
  </IconBase>
)
