import { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { Text } from '@sofascore/ui'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'
import { FirebaseEventName } from 'shared/lib/firebase/model'

import { Standings } from 'modules/Standings/interface'

import { StandingsControls } from './StandingsControls'
import { ControlButton } from './Button'

interface Props {
  standingsName: string
  onCancel: () => void
  onSave: () => void
  isSaveDisabled: boolean
  tournamentStandings: Standings
}

const EditingStandingsControls = ({ standingsName, onCancel, onSave, isSaveDisabled, tournamentStandings }: Props) => {
  const saveStandings = useCallback(() => {
    if (!isSaveDisabled) {
      onSave()
      logFirebaseEvent(FirebaseEventName.ReorderStandings, { id: tournamentStandings?.tournament?.id })
    }
  }, [isSaveDisabled, onSave, tournamentStandings])

  return (
    <StandingsControls standingsName={standingsName}>
      <ControlButton primary onClick={saveStandings} disabled={isSaveDisabled}>
        <Text font="assistive.default" letterSpacing="-0.024px" color="surface.s1">
          <FormattedMessage id="save" />
        </Text>
      </ControlButton>

      <ControlButton onClick={onCancel}>
        <Text font="assistive.default" letterSpacing="-0.024px">
          <FormattedMessage id="cancel" />
        </Text>
      </ControlButton>
    </StandingsControls>
  )
}

export default EditingStandingsControls
