import { Box, Flex, Text } from '@sofascore/ui'
import { useEventState } from 'entities/Event'
import { TeamName } from 'entities/Team'
import { PropsWithChildren } from 'react'

export const EditMatchInfoHeader = ({ children }: PropsWithChildren) => {
  const { initialEvent } = useEventState()
  const { tournament, homeTeam, awayTeam } = initialEvent

  return (
    <Flex direction="column" bg="surface.s1" px="md" pb="xl" br="sm" align="center" elevation={1}>
      <Box pt="lg">
        <Text fontSize={14} fontWeight="bold">
          {tournament.name}
        </Text>
      </Box>

      <Flex justify="space-between" pt="md" style={{ width: '100%' }}>
        <TeamName id={homeTeam.id} teamName={homeTeam.name} />

        <Flex align="center">
          <Text align="center" fontWeight="medium">
            {children}
          </Text>
        </Flex>

        <TeamName id={awayTeam.id} teamName={awayTeam.name} />
      </Flex>
    </Flex>
  )
}
