import { Box, Text } from '@sofascore/ui'
import { PeriodGroup } from 'entities/Incident'

export const PeriodHeader = ({ period }: { period: PeriodGroup }) => {
  if (period.isLive) return null

  return (
    <Box py="sm">
      <Text lineHeight="100%" color="onSurface.nLv1" font="display.micro" fontSize={14} as="div">
        {period.periodIncident.text} {`${period.periodEndHomeScore} - ${period.periodEndAwayScore}`}
      </Text>
    </Box>
  )
}
