import { EntityFilter } from 'modules/MyCompetitions/MyCompetitionTeams/model'

export const teamPlayersTabs = [
  {
    msgId: 'suggestionApproved',
    statusType: EntityFilter.APPROVED,
  },
  {
    msgId: 'suggestionPending',
    statusType: EntityFilter.PENDING,
  },
  {
    msgId: 'suggestionDeclined',
    statusType: EntityFilter.DECLINED,
  },
]
