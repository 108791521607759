import { Flex, Text } from '@sofascore/ui'
import { footballSubstitutionReasonOptions } from 'entities/Incident'
import { FormattedMessage } from 'react-intl'
import { useState } from 'react'
import IconCreate from '@sofascore/ui/dist/modules/Icons/IconCreate'
import IconSubstitution from '@sofascore/ui/dist/modules/Icons/_IconSubstitution'
import { useFirebaseWithEvent } from 'entities/Event/hooks/useFirebaseWithEvent'
import { FirebaseEventName, FirebaseEventType, FirebaseEventIncidentAction } from 'shared/lib/firebase/model'

import ResponsivePopUp from 'components/ResponsivePopUp'
import { IconButton } from 'components/IconButton'

import { EditSubstitutionForm } from '../EditSubstitutionForm'
import { EditIncidentProps } from '../../model'

export const EditFootballSubstitutionRegular = ({ onEdit, incident }: EditIncidentProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const logFirebaseWithEvent = useFirebaseWithEvent()

  const responsivePopupClose = (discarded?: boolean | undefined) => {
    setIsOpen(false)
    if (discarded)
      logFirebaseWithEvent(
        FirebaseEventName.DiscardEntity,
        FirebaseEventType.Incident,
        FirebaseEventIncidentAction.Substitution,
      )
    else
      logFirebaseWithEvent(
        FirebaseEventName.SubmitEntity,
        FirebaseEventType.Incident,
        FirebaseEventIncidentAction.Substitution,
      )
  }

  return (
    <>
      <IconButton onClick={() => setIsOpen(true)} br="xs" hoverBg="onSurface.nLv5">
        <IconCreate fill="#000000" />
      </IconButton>

      <ResponsivePopUp
        isOpen={isOpen}
        onClose={responsivePopupClose}
        heading={
          <Flex align="center">
            <IconSubstitution fill="secondary.default" width="24" height="24" />
            <Text font="display.large" ml="sm">
              <FormattedMessage id="edit_substitution" />
            </Text>
          </Flex>
        }
      >
        <EditSubstitutionForm
          incident={incident}
          substitutionOptions={footballSubstitutionReasonOptions}
          onSubmit={onEdit}
          onClose={responsivePopupClose}
        />
      </ResponsivePopUp>
    </>
  )
}
