import { createStore, applyMiddleware, compose, Middleware, Store } from 'redux'
import { persistStore } from 'redux-persist'
import createSagaMiddleware, { Task } from 'redux-saga'

import { isDev } from 'utils/env'

import { persistedReducer } from './reducers'
import { rootSaga } from './sagas'

const middlewares: Array<Middleware> = []

const sagaMiddleware = createSagaMiddleware()
middlewares.push(sagaMiddleware)

let enhancer = applyMiddleware(...middlewares)
if (isDev()) {
  // @ts-ignore
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  enhancer = composeEnhancers(applyMiddleware(...middlewares))
}

const store: Store & { sagaTask?: Task } = createStore(persistedReducer, {}, enhancer)

store.sagaTask = sagaMiddleware.run(rootSaga)

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof persistedReducer>

export default store
