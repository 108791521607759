import React, { Dispatch, SetStateAction, useState } from 'react'

type Action = () => void

interface HeaderActionState {
  onSaveAction: Action | undefined
  setOnSaveAction: Dispatch<SetStateAction<Action | undefined>> | undefined
  enableSave: boolean | undefined
  setEnableSave: Dispatch<SetStateAction<boolean>> | undefined
  onDeleteAction: Action | undefined
  setOnDeleteAction: Dispatch<SetStateAction<Action | undefined>> | undefined
  enableDelete: boolean | undefined
  setEnableDelete: Dispatch<SetStateAction<boolean>> | undefined
  isSaving: boolean
  setIsSaving: Dispatch<SetStateAction<boolean>> | undefined
  hasUnsavedChanges: boolean
  setHasUnsavedChanges: Dispatch<SetStateAction<boolean>> | undefined
}

interface HeaderActionProviderProps {
  children: React.ReactNode
}

export const HeaderActionContext = React.createContext<HeaderActionState>({} as HeaderActionState)

export const HeaderActionProvider = ({ children }: HeaderActionProviderProps) => {
  const [saveAction, setSaveAction] = useState<Action | undefined>(undefined)
  const [deleteAction, setDeleteAction] = useState<Action | undefined>(undefined)

  const [enableSave, setEnableSave] = useState(false)
  const [enableDelete, setEnableDelete] = useState(false)

  const [isSaving, setIsSaving] = useState(false)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)

  return (
    <HeaderActionContext.Provider
      value={{
        onSaveAction: saveAction,
        setOnSaveAction: setSaveAction,
        enableSave: enableSave,
        setEnableSave: setEnableSave,
        onDeleteAction: deleteAction,
        setOnDeleteAction: setDeleteAction,
        enableDelete: enableDelete,
        setEnableDelete: setEnableDelete,
        isSaving: isSaving,
        setIsSaving: setIsSaving,
        hasUnsavedChanges: hasUnsavedChanges,
        setHasUnsavedChanges: setHasUnsavedChanges,
      }}
    >
      {children}
    </HeaderActionContext.Provider>
  )
}
