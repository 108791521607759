import { usePreview } from 'react-dnd-multi-backend'
import { MutableRefObject } from 'react'
import { Table } from '@sofascore/ui'

import { backgrounds } from 'styles/color'

import { TableRowCells } from './AdaptiveTableRow'
import { DragItem } from './interface'

/**
 * Component for previewing drag item on mobile screens. It's needed because
 * mobile browsers do not fully support HTML5 DND backend, so without it you
 * would see only blank rectangle being dragged instead of table row.
 */
export default function AdaptiveTableDragPreviewRow() {
  const preview = usePreview<DragItem>()

  if (!preview.display) {
    return null
  }

  const { item, ref, style, monitor } = preview

  const xOffset = monitor.getSourceClientOffset()?.x ?? 0
  const yOffset = monitor.getSourceClientOffset()?.y ?? 0
  const transform = `translate(${xOffset}px, ${yOffset}px)`
  const parentTableWidth = ref.current?.parentElement?.getBoundingClientRect().width

  const finalStyle = {
    ...style,
    transform,
    WebkitTransform: transform,
    boxShadow: `0 0 6px 0 ${backgrounds.lightRgba}`,
  }

  return (
    <table width={parentTableWidth} ref={ref as MutableRefObject<HTMLTableElement>} style={finalStyle}>
      <Table.Row>
        <TableRowCells sport={item.sport} row={item.row} isEditMode={true} />
      </Table.Row>
    </table>
  )
}
