import { Flex } from '@sofascore/ui'
import { TeamImage, BasicTeam } from 'entities/Team'

import * as S from './styles'

export default function LineupsTeamSelector({
  homeTeam,
  awayTeam,
  teamId,
  setTeamId,
}: {
  homeTeam: BasicTeam
  awayTeam: BasicTeam
  teamId: number
  setTeamId: (value: number) => void
}) {
  return (
    <Flex gapX="sm">
      <S.LineupsTeamLabel htmlFor={`${homeTeam.id}-${homeTeam.name}`}>
        <S.LineupsTeamRadio
          type="radio"
          onChange={() => setTeamId(homeTeam.id)}
          name="homeTeam"
          id={`${homeTeam.id}-${homeTeam.name}`}
          value={homeTeam.id}
          checked={teamId === homeTeam.id}
        />
        <S.LineupsTeamContent>
          <TeamImage team={homeTeam} />
          <S.LineupsTeamText ellipsis>{homeTeam.name}</S.LineupsTeamText>
        </S.LineupsTeamContent>
      </S.LineupsTeamLabel>

      <S.LineupsTeamLabel htmlFor={`${awayTeam.id}-${awayTeam.name}`}>
        <S.LineupsTeamRadio
          type="radio"
          onChange={() => setTeamId(awayTeam.id)}
          name="awayTeam"
          id={`${awayTeam.id}-${awayTeam.name}`}
          value={awayTeam.id}
          checked={teamId === awayTeam.id}
        />
        <S.LineupsTeamContent>
          <TeamImage team={awayTeam} />
          <S.LineupsTeamText ellipsis>{awayTeam.name}</S.LineupsTeamText>
        </S.LineupsTeamContent>
      </S.LineupsTeamLabel>
    </Flex>
  )
}
