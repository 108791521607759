/**
 * Conditions:
 * 1. Digits only
 * 2. Max 4 digits, 2 on each side
 * 3. Only one colon
 */
const timeInputRegexGuard = /^[0-9]{0,2}:?[0-9]{0,2}$/

export const isFormatValidToDisplay = (value: string): boolean => {
  const isNotColonOnly = value !== ':'
  const hasMaxOneColon = value.split(':').length < 3
  const isCorrectFormat = !!value.match(timeInputRegexGuard)

  const [hours, minutes] = value.split(':')
  const areHoursValid = !hours || (!isNaN(+hours) && +hours >= 0 && +hours < 24)
  const areMinutesValid = !minutes || (!isNaN(+minutes) && +minutes >= 0 && +minutes < 60)

  return isNotColonOnly && hasMaxOneColon && isCorrectFormat && areHoursValid && areMinutesValid
}

export const formatNewInputValue = (currentInputValue: string, newInputValue: string): string => {
  // inputValue is the current state
  // newInputValue is the value entered by user
  // tempInputValue is the manipulated value in the scope of this function
  let tempInputValue = newInputValue

  // When finished typing the hour, auto-concatenate colon
  if (currentInputValue.length === 1 && newInputValue.length === 2 && !newInputValue.includes(':')) {
    tempInputValue = newInputValue + ':'
  }
  // If user attempts to delete a colon, delete the last digit of hour too
  if (newInputValue === currentInputValue.replace(':', '')) {
    tempInputValue = tempInputValue.slice(0, -1)
  }

  return tempInputValue
}
