import { FormattedMessage } from 'react-intl'
import { Box, Flex, GraphicEmptyStateNoEventsCalendar, Text } from '@sofascore/ui'

const EmptyEvents = () => {
  return (
    <Flex direction="column" align="center" justify="center" m="0 auto">
      <Box mb="lg">
        <GraphicEmptyStateNoEventsCalendar />
      </Box>
      <Text font="display.medium" color="onSurface.nLv1" align="center" mb="sm">
        <FormattedMessage id="no_events" />
      </Text>
      <Text font="body.mediumParagraph" color="onSurface.nLv3" align="center">
        <FormattedMessage id="editor_calendar_empty_message" />
      </Text>
    </Flex>
  )
}

export default EmptyEvents
