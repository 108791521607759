import { FlexProps, Flex, Text } from '@sofascore/ui'
import { ReactNode, useCallback, useState } from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'

import { DatePickerCalendar } from 'components/DatePickerCalendar'
import ResponsivePopUp from 'components/ResponsivePopUp'
import { Button } from 'components/Button'

import * as S from './styles'

interface Props {
  label: ReactNode
  date: Date | undefined
  onChange: (date: Date) => void
  disabled?: boolean
  helperText?: ReactNode
  errorText?: ReactNode
  endAdornment?: ReactNode
}

// This component behaves exactly like regular DatePicker, except it's not rendered inline but
// in ResponsivePopup which is opened by clicking the input.
const FormDatePicker = ({
  label,
  date,
  onChange,
  disabled = false,
  helperText,
  errorText,
  ...flexProps
}: Props & FlexProps) => {
  const [isPickerOpened, setIsPickerOpened] = useState(false)
  const [tempDate, setTempDate] = useState<Date | undefined>(date)

  const handleConfirm = useCallback(() => {
    if (tempDate) {
      onChange(tempDate)
      setIsPickerOpened(false)
    }
  }, [onChange, tempDate])

  const handleClose = useCallback(() => {
    setTempDate(date)
    setIsPickerOpened(false)
  }, [date])

  return (
    <>
      <S.Wrapper
        w="100%"
        bg="surface.s2"
        color="surface.nLv1"
        minH="48"
        isDisabled={disabled}
        onClick={() => {
          if (!disabled) setIsPickerOpened(true)
        }}
        {...flexProps}
      >
        <Flex id="value" align="center" h="100%" minW={0} pt={16} px="lg" position="absolute" top="0" left="0">
          {date ? <FormattedDate value={date} day="numeric" month="short" year="numeric" /> : null}
        </Flex>

        <S.Label hasValue={!!date}>{label}</S.Label>
      </S.Wrapper>
      {errorText && (
        <Text block font="assistive.default" color="error.default" ml="lg">
          {errorText}
        </Text>
      )}

      {helperText && !errorText && (
        <Text block font="assistive.default" color="onSurface.nLv3" ml="lg">
          {helperText}
        </Text>
      )}

      <ResponsivePopUp
        isOpen={isPickerOpened}
        onClose={handleClose}
        heading={
          <Text fontWeight="bold" fontSize={18}>
            {label}
          </Text>
        }
      >
        <DatePickerCalendar date={tempDate} onChange={setTempDate} />

        <Flex gapX="lg" p="lg">
          <Button variant="outlined" fullWidth onClick={handleClose}>
            <FormattedMessage id="cancel" />
          </Button>
          <Button variant="contained" fullWidth onClick={handleConfirm} disabled={!tempDate}>
            <FormattedMessage id="confirm" />
          </Button>
        </Flex>
      </ResponsivePopUp>
    </>
  )
}

export default FormDatePicker
