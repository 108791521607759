import { makeStyles, Theme, createStyles } from '@material-ui/core'

import { primaryDark } from 'styles/color'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      '& > div': {
        height: theme.spacing(7),
      },
    },
    drawerPaper: {
      width: '50%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    incidentsContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '106px',
      margin: `${theme.spacing(2)}px 0px`,
      background: theme.palette.background.default,
      '& > p': {
        paddingLeft: theme.spacing(2),
        fontWeight: '500',
      },
      '& > button': {
        alignSelf: 'flex-end',
      },
    },
    indicator: {
      backgroundColor: 'white',
    },
    root: {
      backgroundColor: primaryDark,

      [theme.breakpoints.down('sm')]: {
        '& div': {
          justifyContent: 'space-around',
        },
      },
    },
    label: {
      color: theme.palette.text.secondary,
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.5px',
      [theme.breakpoints.up('md')]: {
        minWidth: 100,
      },
      textAlign: 'center',
    },
  }),
)
