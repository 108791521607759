import { ScoreWithPeriods } from 'entities/Score'
import { Incident, IncidentType } from 'entities/Incident'

/**
 * Returns tuple with first element being the last goal (or undefined if it does not exist) and second element
 * being the second to last goal (or undefined if it does not exist) from the provided incident list.
 */
export const getLastGoalsFromIncidents = (incidents: Incident[]): [Incident | undefined, Incident | undefined] => {
  const goals = incidents ? incidents.filter(i => i.incidentType === IncidentType.Goal) : []
  const [lastGoal, secondToLastGoal] = goals

  return [lastGoal, secondToLastGoal]
}

/**
 * Returns sorted incidents by their sequence (penalty shootout) or time (other incidents). Penalty shootout
 * incidents always take precedence above other incidents and they should be shown first.
 */
export const sortIncidents = (incidents: Incident[]) => {
  const sorted = incidents.sort((a, b) => {
    if (a.incidentType === IncidentType.PenaltyShootout && b.incidentType === IncidentType.PenaltyShootout) {
      // Two penalties can't have the same sequence,
      // so we don't have to check for equality
      return a.sequence! > b.sequence! ? -1 : 1
    }

    if (!a.time || !b.time) return 0

    if (a.time > b.time) return -1

    if (a.time === b.time) {
      if (a.addedTime && !b.addedTime) return -1
      if (!a.addedTime && b.addedTime) return 1

      if (a.addedTime && b.addedTime) {
        if (a.addedTime > b.addedTime) return -1
        if (a.addedTime < b.addedTime) return 1
      }

      if (
        a.homeScore !== undefined &&
        a.awayScore !== undefined &&
        b.homeScore !== undefined &&
        b.awayScore !== undefined
      ) {
        return a.homeScore + a.awayScore > b.homeScore + b.awayScore ? -1 : 1
      }

      return -1
    }

    return 1
  })
  return sorted
}

/**
 * Utility function that returns the incremented score based on `isHomeTeam` flag. Used for
 * getting the new (incremented) score in edit match form.
 */
export const getIncrementedScore = (
  homeScore: ScoreWithPeriods,
  awayScore: ScoreWithPeriods,
  isHomeTeam: boolean,
  increment = 1,
) => {
  const _homeScore = homeScore.display || 0
  const _awayScore = awayScore.display || 0

  return {
    homeScore: isHomeTeam ? _homeScore + increment : _homeScore,
    awayScore: isHomeTeam ? _awayScore : _awayScore + increment,
  }
}
