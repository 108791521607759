import { Flex, Spinner, Text } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'

import { EntityFilter } from '../model'
import { useGroupedTeamSuggestions } from '../hooks'
import { MyCompetitionTeamSuggestion } from './MyCompetitionTeamSuggestion'

export const MyCompetitionDeclinedLeagueTeams = () => {
  const { data, error, isLoading } = useGroupedTeamSuggestions(EntityFilter.DECLINED)

  if (isLoading) {
    return (
      <Flex justify="center" mt={100}>
        <Spinner width="40px" />
      </Flex>
    )
  }

  if (!data?.groups.length) {
    return (
      <Flex direction="column" align="center" gapY="lg" pt={128}>
        <Text font="display.medium">
          <FormattedMessage id="no_declined_requests" />
        </Text>
        <Text font="body.medium" color="onSurface.nLv3">
          <FormattedMessage id="declined_requests_team_empty_text" />
        </Text>
      </Flex>
    )
  }

  if (error) return null

  const suggests = data.groups[0].suggests

  return (
    <Flex direction="column" bg="surface.s1" elevation={2} br="sm" mt="sm" py="sm">
      <Flex direction="column">
        {suggests
          .sort((s1, s2) => s1.name.localeCompare(s2.name))
          .map(suggest => (
            <MyCompetitionTeamSuggestion key={suggest.id} suggestion={suggest} />
          ))}
      </Flex>
    </Flex>
  )
}
