import { useDrag } from 'react-dnd'
import { Flex } from '@sofascore/ui'
import { IconDrag } from 'shared/ui'
import { PlayerCard } from 'entities/Player'

import { DynamicLineupsPlayer } from '../../model'
import { DND_TYPE } from '../../config'

export const DragAndDropPlayer = ({ lineupPlayer }: { lineupPlayer: DynamicLineupsPlayer }) => {
  const [, drag] = useDrag(() => ({
    type: DND_TYPE,
    item: lineupPlayer,
  }))

  return (
    <div ref={drag}>
      <Flex gapX="lg" py="md" px="lg" align="center" minW={0} cursor="pointer" hoverBg="surface.s2">
        <IconDrag style={{ flexShrink: 0 }} />
        <PlayerCard player={lineupPlayer.player} showJerseyNumber />
      </Flex>
    </div>
  )
}
