import { BoxProps, Image } from '@sofascore/ui'
import { useEffect, useState } from 'react'

import { uniqueTournamentImage } from 'api/routes'
import { getAbsoluteUrl } from 'api/utilities'

interface UniqueTournamentImageProps extends BoxProps {
  id: number
  name?: string
  w?: number | [number, number]
  h?: number | [number, number]
}

export const UniqueTournamentImage = ({
  id,
  name = 'Tournament logo',
  w = 40,
  h = 40,
  ...boxProps
}: UniqueTournamentImageProps) => {
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    setHasError(false)
  }, [id])

  if (hasError) {
    return <Image w={w} h={h} src="/images/placeholders/uniqueTournament.png" {...boxProps} />
  }

  return (
    <Image
      loading="lazy"
      alt={name}
      src={getAbsoluteUrl(uniqueTournamentImage(id))}
      w={w}
      h={h}
      onError={() => setHasError(true)}
      {...boxProps}
    />
  )
}
