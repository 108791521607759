import { HTMLAttributes, ReactNode } from 'react'
import { Box, BoxProps } from '@sofascore/ui'

import * as S from './styles'

interface Props extends BoxProps {
  label: string | ReactNode
  icon?: React.ReactNode
  isActive?: boolean
  onClick: () => void
}

export const IncidentBox = ({
  label,
  icon,
  isActive,
  onClick,
  title,
  ...boxProps
}: Props & Pick<HTMLAttributes<HTMLDivElement>, 'title'>) => {
  return (
    <S.IncidentBox $isActive={isActive} onClick={onClick} title={title} {...boxProps}>
      <Box mb="auto">{icon}</Box>
      <S.IncidentBoxLabel font="table.micro" color="inherit">
        {label}
      </S.IncidentBoxLabel>
    </S.IncidentBox>
  )
}
