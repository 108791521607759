import { Flex, Text } from '@sofascore/ui'

import { RED } from 'styles/color'

export const DisplayAccumulatedFoulsValue = ({
  accumulatedFoulsValue,
  accumulatedFoulsLimit = 5,
}: {
  accumulatedFoulsValue: number
  accumulatedFoulsLimit?: number
}) => {
  return (
    <Flex
      justify="center"
      align="center"
      mx="lg"
      w="22px"
      h="22px"
      border="1px solid red"
      br="2px"
      bg={accumulatedFoulsValue > accumulatedFoulsLimit ? RED.incident : '#ffffff'}
    >
      <Text font="display.small" color={accumulatedFoulsValue > accumulatedFoulsLimit ? '#ffffff' : RED.incident}>
        {accumulatedFoulsValue}
      </Text>
    </Flex>
  )
}
