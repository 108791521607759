import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { motion } from 'framer-motion'
import { Box, useMediaContext } from '@sofascore/ui'
import { BasicEvent } from 'entities/Event'
import { useToast } from 'shared/lib'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'
import { FirebaseEventName, FirebaseEventType } from 'shared/lib/firebase/model'

import { deleteEvent } from 'api'
import { EventCell } from 'modules/EventCellNew'
import { getAuthFromState } from 'store/selectors'
import { EVENT_CELL_HEIGHT } from 'modules/EventCellNew/config'

interface EventListProps {
  events: BasicEvent[]
  revalidateEventList?: () => void
  onDelete?: (eventId: number) => void
  eventLinkNavState?: unknown
}

/**
 * TODO: This list can be extracted to a separate module so it can be reused in other part of the app.
 */
const EventList = ({ events, eventLinkNavState, revalidateEventList, onDelete }: EventListProps) => {
  const intl = useIntl()
  const { isMobile } = useMediaContext()
  const { userAccount } = useSelector(getAuthFromState)
  const { enqueueToast } = useToast()

  if (!events.length) return null

  const handleEventDelete = (event: BasicEvent) => async () => {
    if (userAccount) {
      await deleteEvent(event.id)
        .then(() => {
          enqueueToast(intl.formatMessage({ id: 'toastSuccess' }), { variant: 'success' })
          logFirebaseEvent(FirebaseEventName.DeleteEntity, {
            id: event.id,
            type: FirebaseEventType.Event,
            status: event.status.type,
            sport: event?.tournament?.category?.sport?.name?.toLowerCase(),
          })
          onDelete?.(event.id)
          revalidateEventList?.()
        })
        .catch(e => {
          console.error(e)
          enqueueToast(intl.formatMessage({ id: 'toastError' }), { variant: 'error' })
        })
    }
  }

  return (
    <>
      <motion.div
        style={{
          position: 'relative',
          overflow: 'hidden',
          transition: 'height 0.2s',
          height: events.length * EVENT_CELL_HEIGHT,
        }}
      >
        {events.map((event: BasicEvent, i: number) => {
          return (
            <Box
              key={event.id}
              w="100%"
              position="absolute"
              top={i * EVENT_CELL_HEIGHT}
              style={{ transition: 'top 0.2s' }}
            >
              <EventCell
                event={event}
                navState={eventLinkNavState}
                handleDeleteEvent={handleEventDelete(event)}
                swipeToDelete={isMobile}
              />
            </Box>
          )
        })}
      </motion.div>
    </>
  )
}

export default EventList
