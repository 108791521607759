import { Flex, Image, Text } from '@sofascore/ui'
import { IncidentResult } from 'entities/Incident'
import { useEventState } from 'entities/Event'
import { FormattedMessage } from 'react-intl'
import { useState } from 'react'
import IconCreate from '@sofascore/ui/dist/modules/Icons/IconCreate'
import { useFirebaseWithEvent } from 'entities/Event/hooks'
import { FirebaseEventIncidentAction, FirebaseEventName, FirebaseEventType } from 'shared/lib/firebase/model'

import ResponsivePopUp from 'components/ResponsivePopUp'
import { teamImage } from 'api/routes'
import { IconButton } from 'components/IconButton'

import { EditFootballLastGoalForm } from './EditLastGoalForm'
import { EditIncidentProps } from '../../model'

export const EditFootballLastGoalFromPenalty = ({ onEdit, incident }: EditIncidentProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { event } = useEventState()
  const { homeTeam, awayTeam } = event
  const { isHome, homeScore, awayScore } = incident
  const logFirebaseWithEvent = useFirebaseWithEvent()

  const responsivePopupClose = (discarded?: boolean | undefined) => {
    setIsOpen(false)
    if (discarded)
      logFirebaseWithEvent(
        FirebaseEventName.DiscardEntity,
        FirebaseEventType.Incident,
        FirebaseEventIncidentAction.Goal,
      )
    else
      logFirebaseWithEvent(FirebaseEventName.SubmitEntity, FirebaseEventType.Incident, FirebaseEventIncidentAction.Goal)
  }

  return (
    <>
      <IconButton onClick={() => setIsOpen(true)} br="xs" hoverBg="onSurface.nLv5">
        <IconCreate fill="#000000" />
      </IconButton>

      <ResponsivePopUp
        isOpen={isOpen}
        onClose={responsivePopupClose}
        heading={
          <Flex align="center" justify="space-between" w="100%" direction="row" pr="sm">
            <Flex>
              <Image
                src={teamImage(isHome ? homeTeam.id : awayTeam.id)}
                w={24}
                h={24}
                mr="sm"
                alt={isHome ? homeTeam.name : awayTeam.name}
              />
              <Text font="display.large">
                <FormattedMessage id="edit_penalty" />
              </Text>
            </Flex>

            <IncidentResult isHome={isHome} homeScore={homeScore} awayScore={awayScore} />
          </Flex>
        }
      >
        <EditFootballLastGoalForm onSubmit={onEdit} onClose={responsivePopupClose} incident={incident} />
      </ResponsivePopUp>
    </>
  )
}
