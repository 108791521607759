import { Box, Flex } from '@sofascore/ui'

import { GroupedPlayers, sortLineupPlayers } from '../../utils'
import { DragAndDropPlayer } from './DragAndDropPlayer'
import { PlayerGroup } from '../PlayerGroup'

interface Props {
  players: GroupedPlayers
  positionGroupMapping: Record<string, string>
}

export const AvailablePlayers = ({ players, positionGroupMapping }: Props) => {
  return (
    <Box position="relative" w="100%" bg="surface.s1" elevation={1} br="sm">
      <Flex direction="column" w="100%">
        {/** Always show defined groups, even if empty */}
        {Object.keys(positionGroupMapping).map(position => (
          <PlayerGroup key={position} title={positionGroupMapping[position]}>
            {players[position]
              ? sortLineupPlayers(players[position]).map(p => <DragAndDropPlayer key={p.player.id} lineupPlayer={p} />)
              : null}
          </PlayerGroup>
        ))}

        {/** Show unknown group only if there are players with unknown positions */}
        {players['-'] && (
          <PlayerGroup title="unknown">
            {sortLineupPlayers(players['-']).map(p => (
              <DragAndDropPlayer key={p.player.id} lineupPlayer={p} />
            ))}
          </PlayerGroup>
        )}
      </Flex>
    </Box>
  )
}
