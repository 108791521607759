import { Box, Flex } from '@sofascore/ui'
import { FormattedMessage, useIntl } from 'react-intl'
import { useState } from 'react'
import { useEventState } from 'entities/Event'
import { useToast } from 'shared/lib'
import { useErrorHandler } from 'shared/hooks'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'
import { FirebaseEventName } from 'shared/lib/firebase/model'

import { Button } from 'components/Button'
import { updateMatchInfo } from 'api'
import { DatePickerCalendar } from 'components/DatePickerCalendar'

interface Props {
  handleClose: () => void
  revalidateEvent: () => void
}

const UpdateStartDateForm = ({ handleClose, revalidateEvent }: Props) => {
  const { enqueueToast } = useToast()
  const intl = useIntl()
  const handleError = useErrorHandler()

  const { event } = useEventState()
  const { id, startTimestamp } = event

  const [isSaving, setIsSaving] = useState(false)

  const [selectedDate, setSelectedDate] = useState(new Date(startTimestamp * 1000))

  const handleDateChange = (newDate: Date) => {
    setSelectedDate(newDate)
  }

  const handleConfirm = async () => {
    setIsSaving(true)

    try {
      const currentStartDate = new Date(startTimestamp * 1000)
      currentStartDate.setFullYear(selectedDate.getFullYear())
      currentStartDate.setMonth(selectedDate.getMonth())
      currentStartDate.setDate(selectedDate.getDate())
      const startDateTimestamp = Math.floor(currentStartDate.getTime() / 1000)

      await updateMatchInfo(id.toString(), {
        startDateTimestamp,
      })

      revalidateEvent()
      logFirebaseEvent(FirebaseEventName.ChangeDateTime, { id: event.id })
      handleClose()
      enqueueToast(intl.formatMessage({ id: 'toastSuccess' }), { variant: 'success' })
    } catch (err) {
      handleError(err)
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <Flex direction="column" justify="space-between">
      {/* Reserve height to prevent pop up height shift */}
      <Box my="sm" h={370} style={{ margin: 'auto' }}>
        <Box bg="surface.s2" br={8} w={340}>
          <DatePickerCalendar date={selectedDate} onChange={handleDateChange} />
        </Box>
      </Box>

      <Flex justify="center" gapX={16} px={40} pb="xl" mt="xl">
        <Button variant="outlined" fullWidth onClick={handleClose}>
          <FormattedMessage id="cancel" />
        </Button>

        <Button variant="contained" fullWidth onClick={handleConfirm} isLoading={isSaving}>
          <FormattedMessage id="confirm" />
        </Button>
      </Flex>
    </Flex>
  )
}

export default UpdateStartDateForm
