export enum SportName {
  Football = 'football',
  Basketball = 'basketball',
  Tennis = 'tennis',
  Futsal = 'futsal',
  ESports = 'esports',
  Waterpolo = 'waterpolo',
  Volleyball = 'volleyball',
  MiniFootball = 'minifootball',
  Rugby = 'rugby',
}

export enum SportVariant {
  RugbyUnion = 1,
  RugbySevens = 2,
}

export interface Sport {
  id: number
  name: string
  slug: SportName
}
