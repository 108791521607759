import { FormattedMessage } from 'react-intl'
import { useState } from 'react'
import { Flex, Text, Image } from '@sofascore/ui'
import { BasicTeam } from 'entities/Team'
import { CreateIncidentData } from 'entities/Incident'
import { IconError, IconSuccess } from 'shared/ui'
import { useFirebaseWithEvent } from 'entities/Event/hooks'
import { FirebaseEventIncidentAction, FirebaseEventName, FirebaseEventType } from 'shared/lib/firebase/model'

import ResponsivePopUp from 'components/ResponsivePopUp'
import { teamImage } from 'api/routes'

import { AddRugbyShootoutPenaltyForm } from './AddShootoutPenaltyForm'
import { AddShootoutPenaltyButton } from '../AddShootoutPenaltyButton'

interface Props {
  id?: number | undefined
  incidentClass?: string | undefined
  sequence?: number | undefined
  enabled?: boolean
  team: BasicTeam
  onSubmit: (arg0: CreateIncidentData) => Promise<void>
}

export const AddRugbyShootoutPenalty = ({ id, incidentClass, team, enabled, sequence, onSubmit }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const logFirebaseWithEvent = useFirebaseWithEvent()

  const responsivePopupOpen = () => {
    setIsOpen(true)
    logFirebaseWithEvent(
      FirebaseEventName.AddEntity,
      FirebaseEventType.Incident,
      FirebaseEventIncidentAction.PenaltyShootout,
    )
  }
  const responsivePopupClose = (discarded?: boolean | undefined) => {
    setIsOpen(false)
    if (discarded)
      logFirebaseWithEvent(
        FirebaseEventName.DiscardEntity,
        FirebaseEventType.Incident,
        FirebaseEventIncidentAction.PenaltyShootout,
      )
    else
      logFirebaseWithEvent(
        FirebaseEventName.SubmitEntity,
        FirebaseEventType.Incident,
        FirebaseEventIncidentAction.PenaltyShootout,
      )
  }

  return (
    <>
      <AddShootoutPenaltyButton
        id={id}
        incidentClass={incidentClass}
        enabled={enabled}
        onClick={responsivePopupOpen}
        scoredIcon={<IconSuccess />}
        missedIcon={<IconError />}
        hideBackgroundOnUse
      />

      <ResponsivePopUp
        isOpen={isOpen}
        onClose={responsivePopupClose}
        heading={
          <Flex align="center" w="100%" direction="row">
            <Image src={teamImage(team.id)} w={24} h={24} mr="sm" alt={team.name} />
            <Text font="display.large">
              <FormattedMessage id="add_penalty_shot" />
            </Text>
          </Flex>
        }
      >
        <AddRugbyShootoutPenaltyForm
          teamId={team.id}
          sequence={sequence}
          onSubmit={onSubmit}
          onClose={() => setIsOpen(false)}
        />
      </ResponsivePopUp>
    </>
  )
}
