import { Fragment } from 'react'

import { Option } from 'components/DropdownMenu/interface'

import PickerColumn from './PickerColumn'
import * as S from './styles'

export interface ColumnsOptions {
  [columnName: string]: Option[]
}

export interface ColumnsValues {
  [columnName: string]: string | number
}

export interface ColumnsUnits {
  [columnName: string]: string
}

interface WheelPickerProps {
  columnsOptions: ColumnsOptions
  columnsValues: ColumnsValues
  columnsUnits?: ColumnsUnits
  onChange: (name: string, value: string | number) => void
  /** height of each option item */
  itemHeight?: number
  /** height of the component (scrolling window) */
  height?: number
}

/**
 * Every scrolling column has a list of options.
 *
 * Every column also has a selected value and optionally, a unit.
 *
 * Units must be defined for each column for elements to layout correctly,
 * or not defined at all.
 */
const WheelPicker = ({
  columnsOptions,
  columnsValues,
  columnsUnits,
  onChange,
  itemHeight = 32,
  height = 216,
}: WheelPickerProps) => {
  return (
    <S.PickerContainer style={{ height }}>
      <S.PickerInner>
        {Object.keys(columnsOptions).map(columnName => {
          // Find the selected item for this column
          // Index is calculated here to have the information ready for PickerColumn render
          const options = columnsOptions[columnName]
          const value = columnsValues[columnName]
          const selectedOption = options.find(option => option.value === value)

          let selectedIndex = 0
          if (selectedOption) {
            selectedIndex = options.indexOf(selectedOption)
          }

          let columnUnit
          if (columnsUnits && columnsUnits[columnName]) {
            columnUnit = columnsUnits[columnName]
          }

          return (
            <Fragment key={columnName}>
              <PickerColumn
                name={columnName}
                options={columnsOptions[columnName]}
                value={columnsValues[columnName]}
                unit={columnUnit}
                selectedIndex={selectedIndex}
                itemHeight={itemHeight}
                columnHeight={height}
                onChange={onChange}
              />
              {columnUnit && <S.HighlightUnit className="unit">{columnUnit}</S.HighlightUnit>}
            </Fragment>
          )
        })}

        <S.PickerHighlight
          style={{
            height: itemHeight,
            marginTop: -(itemHeight / 2),
          }}
        />
      </S.PickerInner>
    </S.PickerContainer>
  )
}

export default WheelPicker
