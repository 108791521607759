/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from 'styled-components'
import { Box, Button } from '@sofascore/ui'
import { motion } from 'framer-motion'

export const MotionButton = motion(Button)

export const Label = styled.label<{ hasValue: boolean }>`
  position: absolute;
  top: 14px;
  left: ${p => p.theme.spacing.lg};
  font: ${p => p.theme.typography.font.body.large};
  color: ${p => p.theme.colors.onSurface.nLv3};
  user-select: none;
  pointer-events: none;
  transition: top 100ms, font 100ms, color 100ms;
  transition-timing-function: ease-in-out;

  ${p =>
    p.hasValue &&
    `
    top: ${p.theme.spacing.xs};
    font: ${p.theme.typography.font.assistive.default};
  `}
`

export const SelectFieldWrapper = styled(Box)<{ isDisabled?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 46px;
  color: ${p => p.theme.colors.onSurface.nLv1};
  padding: ${p => `0 ${p.theme.spacing.lg}`};
  border-radius: ${p => p.theme.spacing.sm};
  cursor: ${p => (p.isDisabled ? 'not-allowed' : 'pointer')};

  border: ${p => `1px solid ${p.theme.colors.onSurface.nLv4} !important`};

  &:focus-within {
    border: ${p => `1px solid ${p.theme.colors.primary.default} !important`};
  }

  ${p => p.isDisabled && 'opacity: 0.5;'}
`

interface SelectProps {
  selectBackgroundColor?: string
  iconColor?: string
}

export const Select = styled(Box)<SelectProps>`
  ${p => {
    if (!p.iconColor) return

    const focusColor = p.theme.setAlpha(p.iconColor!, 0.1)

    return `
      & ${Button} {
        & > svg {
          fill: ${p.iconColor} !important;
        }

        &:focus,
        &:hover {
          background-color: ${focusColor} !important;
        }
      
        &:focus {
          box-shadow: 0 0 0 3px ${focusColor};
        }
    `
  }};
`
