import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { ENDED_CODE, STATUS_CODES_BY_SPORT, isSpecialStatus, getStatusCodeTranslation } from 'entities/Status'
import { SportName } from 'entities/Sport'
import { Stylable } from 'shared/model'

import { isFootballBasicStatus } from 'modules/Matches/helper'
import { DropdownMenu } from 'components/DropdownMenu'
import { Option } from 'components/DropdownMenu/interface'
import { useMyCompetitionsState } from 'modules/MyCompetitions/providers/MyCompetitionsProvider'

interface Props extends Stylable {
  value: Option | null
  onChange: (selectedOption: Option) => void
  caption: string
  hasSpecialStatusOptions?: boolean
  disabled?: boolean
}

/**
This selector is used in case the user chooses option 'Other' (for sports with two halves) or basketball competition. 
Example of selectable values in dropdown: Not started, Awaiting extra time, Start delayed etc. The display of values in
the selector depends on the chosen sport and the value of hasSpecialStatusOptions property:
  1. Football and Futsal (sports with two halves)
  2. Basketball
  3. Special status list (Canceled, Postponed, Interrupted)
 */
const StatusSelector = ({ value, onChange, caption, disabled, hasSpecialStatusOptions, className }: Props) => {
  const { uniqueTournament } = useMyCompetitionsState()

  const sport = uniqueTournament?.category.sport.slug
  const intl = useIntl()

  const filterStatus = useCallback(
    (statusCode: number, hasSpecialStatusOptions?: boolean) => {
      const isBasketball = sport === SportName.Basketball
      const isTennis = sport === SportName.Tennis
      const isWaterpolo = sport === SportName.Waterpolo
      const isVolleyball = sport === SportName.Volleyball

      if (hasSpecialStatusOptions) {
        return isSpecialStatus(statusCode)
      }

      if (isBasketball) {
        return true
      }

      if (isTennis || isWaterpolo || isVolleyball) {
        // Allow ended for tennis, waterpolo and volleyball
        return statusCode === ENDED_CODE || !isFootballBasicStatus(statusCode)
      }

      return !isFootballBasicStatus(statusCode)
    },
    [sport],
  )

  if (!sport) return null

  return (
    <DropdownMenu
      className={className}
      disabled={disabled}
      onChange={onChange}
      options={Object.keys(STATUS_CODES_BY_SPORT[sport])
        .filter(matchStatusCode => filterStatus(Number(matchStatusCode), hasSpecialStatusOptions))
        .map(matchStatusCode => ({
          label: getStatusCodeTranslation(sport, +matchStatusCode, intl),
          value: Number(matchStatusCode),
        }))}
      selectedOption={value}
      caption={caption}
    />
  )
}

export default StatusSelector
