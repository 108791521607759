import { mapMiniFootballPositionToGroupKey } from 'features/AddLineups'
import { ListLineups } from 'widgets/ListLineups'

import { LineupsLayout } from '../../LineupsLayout'
import { LineupsActionsContainer } from '../../LineupsActionsContainer'
import { responseToInitialState } from '../../Football/utils'
import { SaveLineups } from './MiniFootballLineupsActions'
import { MiniFootballLineupsLoader } from './MiniFootballLineupsLoader'

export const MiniFootballLineups = () => {
  return (
    <LineupsLayout>
      <MiniFootballLineupsLoader transformer={responseToInitialState}>
        <ListLineups positionGroupMapping={mapMiniFootballPositionToGroupKey} />
        <LineupsActionsContainer>
          <SaveLineups />
        </LineupsActionsContainer>
      </MiniFootballLineupsLoader>
    </LineupsLayout>
  )
}
