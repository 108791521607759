import React from 'react'
import { useSelector } from 'react-redux'
import { Box, Typography, useTheme } from '@material-ui/core'
import { StatusType } from 'entities/Status'
import { BasicEvent } from 'entities/Event'

import { teamImage } from 'api/routes'
import { RootState } from 'store/store'
import Image from 'components/Image/Image'
import { getScoreColor } from 'utils/colorDetermination'

import { useStyles } from './styles'

/**
 * Display information about teams in selected event. It contains following information:
 *   1. Home team name & image
 *   2. Away team name & image
 *   3. Result (only if typeof homeScore.current and awayScore.current is a number)
 */
const TeamsInfo = () => {
  const event: BasicEvent = useSelector((state: RootState) => state.event)

  const classes = useStyles()
  const theme = useTheme()

  const { homeTeam, awayTeam, homeScore, awayScore } = event

  const eventInProgress = event.status.type === StatusType.IN_PROGRESS

  const isDoubles = homeTeam.subTeams && homeTeam.subTeams.length !== 0

  const homeTeamId = isDoubles ? homeTeam.subTeams![0].id : homeTeam.id
  const awayTeamId = isDoubles ? awayTeam.subTeams![0].id : awayTeam.id

  const getWinningSide = (homeScore: number, awayScore: number) => {
    return homeScore > awayScore ? '1' : homeScore === awayScore ? 'X' : '2'
  }

  return (
    <>
      <Box data-testid="teams-info" pt={2} pb={4}>
        <Box display="flex" justifyContent="space-between">
          <Box className={classes.teamInfo}>
            <Typography variant="subtitle2" className={classes.teamName}>
              <Box>{!isDoubles ? homeTeam.name : homeTeam.subTeams![0].name}</Box>
              <Box>{isDoubles && homeTeam.subTeams![1].name}</Box>
            </Typography>

            <Box display="flex" style={{ position: 'relative', left: 5 }}>
              <Image alt={homeTeam.name} src={teamImage(homeTeamId)} className={classes.teamImage} />
              {isDoubles && (
                <Image
                  alt={homeTeam.name}
                  src={teamImage(homeTeam.subTeams![1].id)}
                  className={classes.teamImage}
                  style={{ position: 'relative', left: '-10px' }}
                />
              )}
            </Box>
          </Box>

          <Typography className={classes.teamResult}>
            {typeof homeScore.current === 'number' && typeof awayScore.current === 'number' ? (
              <>
                <span
                  style={{
                    color: getScoreColor(
                      event.status.type,
                      'home',
                      getWinningSide(homeScore.current, awayScore.current),
                    ),
                  }}
                >
                  {homeScore.current}
                </span>
                <span style={{ color: eventInProgress ? theme.palette.secondary.main : theme.palette.text.disabled }}>
                  &nbsp;-&nbsp;
                </span>
                <span
                  style={{
                    color: getScoreColor(
                      event.status.type,
                      'away',
                      getWinningSide(homeScore.current, awayScore.current),
                    ),
                  }}
                >
                  {awayScore.current}
                </span>
              </>
            ) : (
              <span style={{ color: theme.palette.text.disabled }}>-</span>
            )}
          </Typography>

          <Box className={classes.teamInfo}>
            <Typography variant="subtitle2" className={classes.teamName}>
              {!isDoubles ? awayTeam.name : awayTeam.subTeams![0].name}
              <br />
              {isDoubles && awayTeam.subTeams![1].name}
            </Typography>

            <Box display="flex" style={{ position: 'relative', left: 5 }}>
              <Image alt={awayTeam.name} src={teamImage(awayTeamId)} className={classes.teamImage} />
              {isDoubles && (
                <Image
                  alt={awayTeam.name}
                  src={teamImage(awayTeam.subTeams![1].id)}
                  className={classes.teamImage}
                  style={{ position: 'relative', left: '-10px' }}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default TeamsInfo
