import { useLocation } from 'react-router-dom'
import { Text } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'

import * as S from './styles'
import { TabProps } from './interface'

export const Tab = ({ to, msgId }: TabProps) => {
  const { state } = useLocation()

  return (
    <S.Link to={to} state={state}>
      <Text font="body.medium" color="inherit" whiteSpace="nowrap">
        <FormattedMessage id={msgId} />
      </Text>
    </S.Link>
  )
}
