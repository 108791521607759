import { Box, Image } from '@sofascore/ui'
import IconPhotoCamera from '@sofascore/ui/dist/modules/Icons/IconPhotoCamera'

import { IconEdit } from 'components/Icons/IconEdit'
import { secondaryColor } from 'styles/color'
import { Spinner } from 'components/Spinner'

import { ImageUploaderProps } from './interface'
import * as S from './styles'

/**
 * This is the new image uploader component that plans to replace the team page one,
 * when team page gets redesigned.
 *
 * Label element captures a click and triggers the input click event.
 * Then, a system dialog opens to choose an image to upload.
 *
 * @param imageUrl if imageUrl is not defined predefined fallback is used
 * @returns
 */
const ImageUploader = ({ imageUrl, onImageUpload, isUploading, disabled }: ImageUploaderProps) => {
  const showEditIcon = !!imageUrl && !disabled

  return (
    <Box w={124} h={124} position="relative">
      <input
        id="img-uploader-input"
        accept="image/*"
        style={{ display: 'none' }}
        type="file"
        onChange={e => {
          /*
            Prevent user from uploading image if internal state is disabled because media upload endpoint is available
            and backend is not checking permissions for it
          */
          if (!disabled) onImageUpload(e)
        }}
        disabled={isUploading || disabled}
      />
      <Box w="100%" h="100%" bg="surface.s2" br="50%" border="onSurface.nLv4" overflow="hidden">
        <S.ClickableArea htmlFor={!showEditIcon ? 'img-uploader-input' : undefined} isClickable={!showEditIcon}>
          <Image
            src={imageUrl}
            fallback={<IconPhotoCamera fill="rgba(15, 32, 44, 0.3)" w={32} h={32} />}
            w={124}
            h={124}
            alt="player_profile_image"
          />
        </S.ClickableArea>
      </Box>
      {/* Edit Button */}
      {(showEditIcon || isUploading) && (
        <S.EditIconLabelWrapper htmlFor={!isUploading ? 'img-uploader-input' : undefined}>
          {!isUploading ? <IconEdit width={16} height={16} fill="#222226" /> : <Spinner color={secondaryColor} />}
        </S.EditIconLabelWrapper>
      )}
    </Box>
  )
}

export default ImageUploader
