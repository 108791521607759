import * as React from 'react'
import { Text, Flex } from '@sofascore/ui'
import { useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { DialogProps } from 'shared/model'

import { Button } from 'components/Button'
import ResponsivePopUp from 'components/ResponsivePopUp'
import { logoutRequest } from 'store/auth/actions'

/**
 * Dialog shown when user wants to sign out
 * `LOGOUT_REQUEST` action is dispatched if user confirms loggin out
 */
const SingOutDialog = ({ isOpen, handleClose }: DialogProps) => {
  const dispatch = useDispatch()

  return (
    <ResponsivePopUp
      heading={
        <Text font="display.large">
          <FormattedMessage id="signOut" />
        </Text>
      }
      isOpen={isOpen}
      onClose={handleClose}
    >
      <Text p="xl">
        <FormattedMessage id="areYouSureSignOut" />
      </Text>
      <Flex gapX="lg" p="xl">
        <Button fullWidth variant="outlined" onClick={handleClose}>
          <FormattedMessage id="back" />
        </Button>
        <Button fullWidth variant="contained" onClick={() => dispatch(logoutRequest())}>
          <FormattedMessage id="signOut" />
        </Button>
      </Flex>
    </ResponsivePopUp>
  )
}

export default SingOutDialog
