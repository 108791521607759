import { Box, Text } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'
import { ENDED_CODES, INTERRUPTED_CODE, PENALTIES_CODE } from 'entities/Status'
import { CompetitionType } from 'entities/Tournament'
import { EventActionType, hasGoneToExtraTime, useEventDispatch, useEventState } from 'entities/Event'
import { PeriodSwitcher, SelectablePeriod, useEndMatchHandler } from 'features/EditPeriod'
import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { extraTimeStatusCodes } from 'components/ProgressBar/helper'
import Collapse from 'components/Collapse/Collapse'
import { UpdateEventBody } from 'modules/Matches/interface'

import { createSelectablePeriods, findActivePeriodIndex } from '../../../utils'
import { rugbyExtraPeriods, rugbyPenaltyPeriod, rugbyRegularPeriods } from '../config'

export const RugbyEditPeriodSection = () => {
  const { competitionId, seasonId } = useParams()
  const { event } = useEventState()

  const dispatch = useEventDispatch()
  const navigate = useNavigate()

  const selectablePeriods: SelectablePeriod[] = useMemo(() => {
    return createSelectablePeriods(event, rugbyRegularPeriods, rugbyExtraPeriods, rugbyPenaltyPeriod)
  }, [event])

  const currentPeriodIndex = findActivePeriodIndex(selectablePeriods, event)

  const isCup = event.tournament.competitionType === CompetitionType.Cup
  const isInExtraTime = extraTimeStatusCodes.includes(event.status.code)
  const isCupExtraTime = isCup && (isInExtraTime || hasGoneToExtraTime(event))

  const { handleEndMatch } = useEndMatchHandler()

  const onConfirmEndMatch = async (data: UpdateEventBody, eventId: number) => {
    return handleEndMatch(data, eventId).then(() => {
      if (ENDED_CODES.includes(data.status.code) || data.status.code === INTERRUPTED_CODE) {
        navigate(`/my-competitions/${competitionId}/season/${seasonId}/matches/${eventId}/info`)
      }
      if (data.status.code === PENALTIES_CODE) {
        dispatch({ type: EventActionType.RESET_PENALTIES })
      }
    })
  }

  return (
    <Box bg="surface.s1" mt="sm" br="sm" py="lg" textAlign="center" elevation={1}>
      <Collapse
        initiallyOpen
        title={
          <Text font="display.medium">
            <FormattedMessage id="edit_period" />
          </Text>
        }
        alignTitle="center"
        px="lg"
      >
        <Box mx="auto" pt="lg" w={isCupExtraTime ? 'calc(100% - 32px)' : 312}>
          <PeriodSwitcher
            currentPeriodIndex={currentPeriodIndex}
            selectablePeriods={selectablePeriods}
            onConfirm={onConfirmEndMatch}
          />
        </Box>
      </Collapse>
    </Box>
  )
}
