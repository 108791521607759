import { useLayoutEffect, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import * as ease from 'd3-ease'
import * as scale from 'd3-scale'
import * as selection from 'd3-selection'
import * as transition from 'd3-transition'
import { Box, Typography, useTheme } from '@material-ui/core'
import { NO_TIMER_CODES, StatusCode, StatusType } from 'entities/Status'
import { BasicEvent, getCurrentPeriodTime } from 'entities/Event'

import { ProgressTimer } from 'components/Timekeeping/Timekeeping'
import { backgrounds, textLightColor } from 'styles/color'

import { getElapsed, getMaxTime, getPeriodMarks } from './helper'

const d3: typeof selection & typeof transition & typeof ease & typeof scale = Object.assign(
  {},
  selection,
  transition,
  ease,
  scale,
)

function Timer({ event }: { event: BasicEvent }) {
  const { status, time } = event

  if (time && !NO_TIMER_CODES.includes(status.code)) {
    return <ProgressTimer event={event} />
  }

  if (status.code === StatusCode['Awaiting extra time']) {
    return <FormattedMessage id="AwaitingExtraTime.short" />
  }

  if (status.code === StatusCode['Extra time halftime']) {
    return <FormattedMessage id="ExtraTimeHalftime.short" />
  }

  if (status.code === StatusCode['Awaiting penalties']) {
    return <FormattedMessage id="AwaitingPenalties.short" />
  }

  if (status.code === StatusCode.Penalties || status.code === StatusCode['Penalties alt.']) {
    return <FormattedMessage id="Penalties" />
  }

  return <>{getCurrentPeriodTime(event)}</>
}

const ProgressBar = ({ event }: { event: BasicEvent }) => {
  const ref = useRef<SVGSVGElement>(null)
  const timerRef = useRef<HTMLElement>(null)

  const intl = useIntl()

  const theme = useTheme()

  function draw() {
    const root = ref.current

    if (!root) return

    const d3Root = d3.select(root)
    const rootRect = root.getBoundingClientRect()
    const elapsed = getElapsed(event)

    // this might happen if event is paused, so abort render
    if (elapsed < 0) return

    const domainMax = getMaxTime(event)
    if (!domainMax) return

    const scaleMinutes = d3.scaleLinear().domain([0, domainMax]).range([0, rootRect.width])

    const rects = d3Root.selectAll<SVGRectElement, number>('rect').data([elapsed])

    rects
      .enter()
      .append('rect')
      .merge(rects)
      .attr('fill', theme.palette.secondary.main)
      .attr('x', 0)
      .attr('y', 0)
      .transition()
      .duration(200)
      .ease(d3.easeCubicInOut)
      .attr('width', d => scaleMinutes(d))
      .attr('height', 4)

    const periodMarks = getPeriodMarks(event, intl)

    const circles = d3Root.selectAll<SVGCircleElement, { x: number; label: string }>('circle').data(periodMarks)
    circles.exit().remove()

    circles
      .enter()
      .append('circle')
      .merge(circles)
      .attr('fill', theme.palette.text.secondary)
      .attr('r', 7)
      .transition()
      .duration(200)
      .ease(d3.easeCubicInOut)
      .attr('stroke', d =>
        scaleMinutes(elapsed) >= scaleMinutes(d.x) ? theme.palette.secondary.main : backgrounds.lightRgba,
      )
      .attr('stroke-width', 4)
      .attr('cx', d => scaleMinutes(d.x))
      .attr('cy', 1)

    const texts = d3Root.selectAll<SVGTextElement, { x: number; label: string }>('text').data(periodMarks)
    texts.exit().remove()

    texts
      .enter()
      .append('text')
      .merge(texts)
      .attr('font-family', 'Helvetica')
      .attr('font-size', 16)
      .attr('text-anchor', d => (d.x === 0 ? 'start' : 'middle'))
      .transition()
      .duration(200)
      .ease(d3.easeCubicInOut)
      .attr('fill', d => (scaleMinutes(elapsed) >= scaleMinutes(d.x) ? theme.palette.secondary.main : textLightColor))
      .attr('x', d => (d.x > 0 ? scaleMinutes(d.x) : -8))
      .attr('y', 30)
      .text(d => d.label)

    if (timerRef.current) {
      const timerRectWidth = timerRef.current.getBoundingClientRect().width
      const timer = d3.select(timerRef.current)
      timer
        .style('display', 'inline-block')
        .transition()
        .duration(200)
        .ease(d3.easeCubicInOut)
        .style('transform', () => {
          let xOffset = scaleMinutes(elapsed)
          if (xOffset < timerRectWidth / 2) {
            // edge case: left
            xOffset = 0
          } else {
            // edge case: right, plus centering
            xOffset = Math.min(xOffset - timerRectWidth / 2, rootRect.width - timerRectWidth)
          }
          return `translateX(${xOffset}px)`
        })
    }
  }

  useLayoutEffect(() => {
    if (event.status.type !== StatusType.IN_PROGRESS) {
      return
    }

    draw()

    const intervalId = window.setInterval(() => {
      draw()
    }, 1000)

    return () => window.clearInterval(intervalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, event.time, event.startTimestamp])

  return (
    <Box>
      <Typography ref={timerRef} style={{ color: theme.palette.secondary.main }}>
        <Timer event={event} />
      </Typography>
      <svg
        ref={ref}
        width="100%"
        height="4"
        style={{
          backgroundColor: backgrounds.lightRgba,
          marginTop: theme.spacing(1.5),
          overflow: 'visible',
        }}
      />
    </Box>
  )
}

export default ProgressBar
