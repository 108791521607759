import { createStyles, Theme, makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      margin: 0,
      padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.secondary,
      [theme.breakpoints.down('sm')]: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      },
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.secondary.main,
    },
    dialogPaper: {
      margin: theme.spacing(2),
    },
    saveDialog: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(2),
      },
    },
    dialogActions: {
      margin: 0,
      justifyContent: 'flex-start',
      padding: `${theme.spacing(3)}px ${theme.spacing(6)}px`,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3),
        justifyContent: 'center',
      },
    },
  }),
)
