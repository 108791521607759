import {
  DeleteIncidentData,
  IncidentActionType,
  IncidentPenaltyShootoutType,
  IncidentType,
  getLastGoalsFromIncidents,
  useEventIncidentsDispatch,
  useEventIncidentsState,
} from 'entities/Incident'
import { useCallback } from 'react'
import { EventActionType, useEventDispatch } from 'entities/Event'

export const usePostMatchDeleteIncidentHandler = () => {
  const { incidents } = useEventIncidentsState()
  const [_, secondToLastGoal] = incidents ? getLastGoalsFromIncidents(incidents) : []

  const incidentsDispatch = useEventIncidentsDispatch()
  const eventDispatch = useEventDispatch()

  const handleDelete = useCallback(
    async (data: DeleteIncidentData) => {
      const { incidentType, incidentClass, isHome } = data

      incidentsDispatch({ type: IncidentActionType.DELETE_INCIDENT, payload: data })

      if (incidentType === IncidentType.Goal) {
        eventDispatch({
          type: EventActionType.SET_SCORES,
          payload: secondToLastGoal
            ? { homeScore: secondToLastGoal.homeScore, awayScore: secondToLastGoal.awayScore }
            : { homeScore: 0, awayScore: 0 },
        })
      }

      if (incidentType === IncidentType.PenaltyShootout && incidentClass === IncidentPenaltyShootoutType.Scored) {
        eventDispatch({ type: EventActionType.DECREMENT_PENALTY_SCORE, payload: { isHome: isHome } })
      }
    },
    [incidentsDispatch, eventDispatch, secondToLastGoal],
  )

  return { handleDelete }
}
