import { AnyAction } from 'redux'
import { createContext, useContext, useEffect, useMemo, useReducer } from 'react'
import isEqual from 'lodash/isEqual'
import { BasicEvent } from 'entities/Event'

import { EventState, eventReducer } from './reducer'
import { EventActionType } from './actions'

const EventStateContext = createContext<EventState>({} as EventState)

const EventDispatchContext = createContext<React.Dispatch<AnyAction> | null>(null)

export const EventProvider = ({ initialState, children }: { initialState: BasicEvent; children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(eventReducer, {
    event: initialState,
    initialEvent: initialState,
  })

  const hasChanges = useMemo(() => {
    return !isEqual(state.event, state.initialEvent)
  }, [state.event, state.initialEvent])

  useEffect(() => {
    const event = { ...initialState }

    if (Object.keys(event.homeScore).length < 1) {
      event.homeScore = { current: 0, display: 0 }
    }

    if (Object.keys(event.awayScore).length < 1) {
      event.awayScore = { current: 0, display: 0 }
    }

    dispatch({ type: EventActionType.SET_EVENT, payload: event })
    dispatch({ type: EventActionType.SET_INITIAL_EVENT, payload: event })
  }, [initialState])

  return (
    <EventStateContext.Provider value={{ ...state, hasChanges: hasChanges }}>
      <EventDispatchContext.Provider value={dispatch}>{children}</EventDispatchContext.Provider>
    </EventStateContext.Provider>
  )
}

export const useEventState = (): EventState => {
  const context = useContext(EventStateContext)

  if (!context) {
    throw new Error('useEventState must be used within a EventProvider')
  }

  return context
}

export const useEventDispatch = () => {
  const context = useContext(EventDispatchContext)

  if (!context) {
    throw new Error('useEventDispatch must be used within a EventProvider')
  }

  return context
}
