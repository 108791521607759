import { Box, Flex, Image, Text, ZIndex } from '@sofascore/ui'
import { useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import IconArrowBack from '@sofascore/ui/dist/modules/Icons/IconArrowBack'
import { FormattedMessage, useIntl } from 'react-intl'
import { getTeamCountryFlag } from 'entities/Team'
import slugify from 'slugify'

import { teamImage } from 'api/routes'
import { getAbsoluteUrl } from 'api/utilities'

import { useTeam } from '../TeamContext'
import * as S from './styles'

const TeamDetailsHeader = () => {
  const intl = useIntl()

  const location = useLocation()
  const navigate = useNavigate()

  const { teamId } = useParams()
  const [searchParams] = useSearchParams()

  const team = useTeam()

  if (!teamId || !team) return null

  const { name, country } = team

  const handleBackClick = () => {
    const backRef = location.state?.backRef
    if (backRef) {
      navigate(backRef)
    } else {
      navigate('/home')
    }
  }

  const urlQuery = searchParams.size ? `?${searchParams.toString()}` : ''

  return (
    <S.TeamInfoHeader
      forwardedAs="header"
      h={[100, 'auto']}
      bg="surface.s1"
      zIndex={ZIndex.Header}
      br={[0, 'sm']}
      elevation={2}
    >
      <Flex w="100%" px="lg" pt={['sm', 'lg']} pb={[0, 'sm']} align="flex-start">
        <Box mr={['md', 'xl']}>
          <S.BackButton onClick={handleBackClick}>
            <IconArrowBack />
          </S.BackButton>
        </Box>

        <Image src={getAbsoluteUrl(teamImage(+teamId))} w={[32, 72]} h={[32, 72]} mr="lg" alt={'teamImage'} />

        <Flex direction="column" justify="space-between" overflow="hidden">
          <Text fontSize={['medium', 'extraLarge']} fontWeight="bold" ellipsis>
            {name}
          </Text>

          <Flex mt={[0, 'sm']}>
            <Flex display="flex" mr="sm" align="center">
              <Image
                src={getTeamCountryFlag(country)}
                w={['12', '24']}
                h={['12', '24']}
                mr="sm"
                alt={country?.name ? intl.formatMessage({ id: slugify(country.name.toLowerCase()) }) : ''}
              />

              {country?.name && (
                <Text fontSize={['micro', 'regular']} fontWeight={['medium', 'bold']}>
                  <FormattedMessage id={slugify(country.name.toLowerCase())} />
                </Text>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <S.ScrollingTabs w="100%" br={[0, 'sm']} overflowY="hidden">
        <S.Link to={'info' + urlQuery} replace state={location.state}>
          <Text font="body.medium" color="inherit" whiteSpace="nowrap">
            <FormattedMessage id="info" />
          </Text>
        </S.Link>
        <S.Link to={'squad' + urlQuery} replace state={location.state}>
          <Text font="body.medium" color="inherit" whiteSpace="nowrap">
            <FormattedMessage id="squad" />
          </Text>
        </S.Link>
        <S.Link to={'matches' + urlQuery} replace state={location.state}>
          <Text font="body.medium" color="inherit" whiteSpace="nowrap">
            <FormattedMessage id="matches" />
          </Text>
        </S.Link>
      </S.ScrollingTabs>
    </S.TeamInfoHeader>
  )
}

export default TeamDetailsHeader
