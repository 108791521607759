export type RepeaterItem = {
  uid: string
  value: string
}

export interface MatchReportData {
  eventId?: number
  awayTeamManagersNames: string[]
  homeTeamManagersNames: string[]
  officialsNames: string[]
  delegateName: string
  notes: string
  lastUpdatedBy?: {
    userAccountId: string
    name: string
    email: string
  }
  createdAtTimestamp?: number
  updatedAtTimestamp?: number
}

export interface MatchReportResponse {
  matchReportData: MatchReportData
}

export interface MatchReportEmails {
  emails: string[]
}

export enum MatchReportStatus {
  PreMatch = 'pre_match',
  PostMatch = 'post_match',
}

export enum MatchReportAction {
  Create = 'create',
  Edit = 'edit',
  SendToMail = 'send_to_mail',
  Download = 'download',
}
