import { useIntl } from 'react-intl'
import { UntranslatedOption, Option } from 'shared/model'
import { useMemo, useState } from 'react'

import { footballMissedPenaltyReasonOptions } from '../config'

export const useMissedPenaltyReasonOptions = (
  config: UntranslatedOption[] = footballMissedPenaltyReasonOptions,
  incidentClass?: string | undefined,
) => {
  const intl = useIntl()

  const options = useMemo(() => {
    return config.map(option => {
      return {
        label: intl.formatMessage({ id: option.translationKey }),
        value: option.value,
      }
    })
  }, [intl, config])

  const initialMissedPenaltyReason = options.find(o => o.value === incidentClass)
  const [missedPenaltyReason, setMissedPenaltyReason] = useState<Option<string>>(
    initialMissedPenaltyReason ?? options[0],
  )

  return { options, missedPenaltyReason, setMissedPenaltyReason, initialMissedPenaltyReason }
}
