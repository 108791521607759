import * as React from 'react'
import {
  Theme,
  Dialog,
  DialogTitle,
  Typography,
  DialogActions,
  Button,
  makeStyles,
  createStyles,
  useTheme,
} from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { DialogProps } from 'shared/model'

interface DeleteDialogProps extends DialogProps {
  deleteItem: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
        width: 346,
      },
    },
  }),
)

const DeleteDialog = ({ isOpen, handleClose, deleteItem }: DeleteDialogProps) => {
  const theme: Theme = useTheme()
  const classes = useStyles()

  return (
    <Dialog open={isOpen} classes={{ paper: classes.dialogPaper }} onClose={handleClose}>
      <DialogTitle style={{ backgroundColor: 'transparent', color: theme.palette.text.primary }}>
        <Typography style={{ fontSize: 16, fontWeight: 500, textAlign: 'center' }}>
          <FormattedMessage id="deleteItem" />
        </Typography>
      </DialogTitle>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button
          variant="outlined"
          onClick={e => {
            e.stopPropagation()
            handleClose()
          }}
          style={{ width: 132, height: 48, marginRight: 16, fontSize: 14 }}
        >
          <FormattedMessage id="no" />
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            deleteItem && deleteItem()
            handleClose()
          }}
          style={{ width: 132, height: 48 }}
        >
          <FormattedMessage id="yes" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteDialog
