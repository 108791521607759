import { Text } from '@sofascore/ui'
import styled from 'styled-components'

import { secondaryColor } from 'styles/color'

export const LineupsTeamLabel = styled.label`
  width: calc(50% - 4px);
  cursor: pointer;
  border-radius: 8px;

  :hover {
    background: ${p => p.theme.colors.surface.s2};
  }
`

export const LineupsTeamContent = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 8px;
  background: ${p => p.theme.colors.surface.s2};
`

export const LineupsTeamRadio = styled.input`
  background: ${p => p.theme.colors.surface.s2};
  display: none;

  &:checked ~ ${LineupsTeamContent} {
    color: #fff;
    background: ${secondaryColor};
  }
`

export const LineupsTeamText = styled(Text)`
  font-size: 12px;
  font-weight: 500;
  margin-left: 4px;
  color: inherit;
`
