import { useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Flex, Text } from '@sofascore/ui'
import { BasicTeam } from 'entities/Team'
import { Player } from 'entities/Player'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'
import { FirebaseEventName, FirebaseEventType } from 'shared/lib/firebase/model'

import { MIN_PLAYERS } from '../../config'
import { LineupType } from '../../model'
import { NotEnoughPlayersNote } from '../NotEnoughPlayersNote'
import * as S from './styles'

interface LineupsTypeSelectorProps {
  type: LineupType
  setType: (value: LineupType) => void
  onSelect: () => void
  homeTeam: BasicTeam
  homePlayers: Player[]
  awayTeam: BasicTeam
  awayPlayers: Player[]
}

export const LineupsTypeSelector = ({
  type,
  setType,
  onSelect,
  homeTeam,
  homePlayers,
  awayTeam,
  awayPlayers,
}: LineupsTypeSelectorProps) => {
  const [warningNoteOpened, setWarningNoteOpened] = useState(false)

  const intl = useIntl()

  const options = useMemo(() => {
    return [
      {
        value: LineupType.PLAYER_LIST,
        label: intl.formatMessage({ id: 'editor_player_list' }),
        image: '/images/placeholders/lineups-player-list.webp',
      },
      {
        value: LineupType.PITCH,
        label: intl.formatMessage({ id: 'editor_pitch' }),
        image: '/images/placeholders/lineups-pitch.webp',
      },
    ]
  }, [intl])

  const path = options.find(o => o.value === type)!.image

  const handleSelectLineupType = () => {
    if (homePlayers.length < MIN_PLAYERS[type].TO_CREATE || awayPlayers.length < MIN_PLAYERS[type].TO_CREATE) {
      setWarningNoteOpened(true)
    } else {
      onSelect()
    }
    logFirebaseEvent(FirebaseEventName.AddEntity, {
      type: FirebaseEventType.Lineup,
    })
  }

  return (
    <Flex align="center" justify="center" maxW={700} w="100%" h="100%">
      <Flex direction="column" align="center" justify="center" w="100%">
        <NotEnoughPlayersNote
          type={type}
          isOpen={warningNoteOpened}
          onClose={() => setWarningNoteOpened(false)}
          homeTeam={homeTeam}
          homePlayers={homePlayers}
          awayTeam={awayTeam}
          awayPlayers={awayPlayers}
        />

        <Flex direction="column" w="100%" bg="surface.s1" p="lg" br="sm" align="center">
          <Text font="body.large" fontSize={14} fontWeight="bold" mb="md" textTransform="uppercase">
            <FormattedMessage id="editor_select_lineups_type" />
          </Text>

          <Flex align="center" mt={3} w="100%">
            {options.map(({ value, label }, index) => (
              <S.LineupsTypeLabel key={value} htmlFor={value}>
                <S.LineupsTypeRadio
                  type="radio"
                  onChange={() => setType(value)}
                  name="lineupsType"
                  id={value}
                  value={value}
                  checked={type === value}
                />
                <S.LineupsTypeContent br={index === 0 ? 'sm 0 0 sm' : '0 sm sm 0'}>
                  <Text font="display.small" color="currentColor">
                    {label}
                  </Text>
                </S.LineupsTypeContent>
              </S.LineupsTypeLabel>
            ))}
          </Flex>
        </Flex>

        <Flex direction="column" align="center" p="lg" bg="surface.s1" mt="sm" br="sm" w="100%">
          <S.LineupsTypeImage src={path} />

          <S.AddLineupsButton variant="contained" onClick={handleSelectLineupType}>
            <FormattedMessage id="editor_create_lineups" />
          </S.AddLineupsButton>
        </Flex>
      </Flex>
    </Flex>
  )
}
