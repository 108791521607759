import { mutate } from 'swr'
import { useSelector } from 'react-redux'
import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useToast } from 'shared/lib'
import { useErrorHandler } from 'shared/hooks'

import { updateEvent } from 'api'
import { getAuthFromState } from 'store/selectors'
import { eventDetails, getIncidents } from 'api/routes'
import { UpdateEventBody } from 'modules/Matches/interface'

export const useUpdatePeriodHandler = () => {
  const { userAccount } = useSelector(getAuthFromState)
  const { enqueueToast } = useToast()
  const intl = useIntl()
  const handleError = useErrorHandler()

  const handleUpdate = useCallback(
    async (data: UpdateEventBody, eventId: number) => {
      if (!userAccount) return

      try {
        await updateEvent(eventId, data, { calculatePeriodScores: true })

        mutate(eventDetails(eventId))
        mutate(getIncidents(eventId))

        enqueueToast(intl.formatMessage({ id: 'toastSuccess' }), { variant: 'success' })
      } catch (e) {
        handleError(e)
      }
    },
    [intl, userAccount, handleError, enqueueToast],
  )

  return { handleUpdate }
}
