import { Box, Flex, FlexProps, Text } from '@sofascore/ui'
import { CountryInfo } from 'entities/Country'
import { PropsWithChildren } from 'react'

import { TeamImage } from './TeamImage'
import { Team } from '../model'

interface TeamCardProps {
  team: Team
}

export const TeamCard = ({ team, children, ...flexProps }: PropsWithChildren<TeamCardProps & FlexProps>) => {
  return (
    <Flex gapX="lg" align="center" {...flexProps}>
      <TeamImage team={team} size={40} />

      <Flex direction="column" gapY={4} grow={1} minW={0}>
        <Text font="body.medium" ellipsis>
          {team.name}
        </Text>
        {team.country && <CountryInfo country={team.country} />}
      </Flex>

      <Box ml="auto">{children}</Box>
    </Flex>
  )
}
