import { Stylable } from 'shared/model'

export enum Direction {
  ROW = 'row',
  COLUMN = 'column',
}

interface BasicCounterProps extends Stylable {
  value: number
  onChange: (newValue: number) => void
  direction?: Direction
}

export interface CounterProps extends BasicCounterProps {
  canDecrement: boolean
  canIncrement: boolean
  children: React.ReactNode
  direction?: Direction
}

export interface ScoreCounterProps extends BasicCounterProps {
  disabled?: boolean
}

export interface PeriodTimeCounterProps extends BasicCounterProps {
  period: string
  hasAddedTime: boolean
}

export interface WritableCounterProps extends Stylable {
  value: number
  onChange: (newValue: number) => void
  canDecrement: boolean
  canIncrement: boolean
  isTime?: boolean
  direction?: Direction
  disabled?: boolean
}
