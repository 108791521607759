import styled from 'styled-components'
import { Box, Flex } from '@sofascore/ui'

export const TeamLogos = styled(Flex)`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -8px;
    height: 100%;
    width: 8px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%);
  }
`
export const Buttons = styled(Box)`
  display: flex;
  align-items: center;
  position: relative;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
`

export const Results = styled(Flex)`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: -8px;
    height: 100%;
    width: 8px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.05) 100%);
  }
`

export const AddPenaltyButton = styled.button<{ $isDisabled?: boolean; $isUsed?: boolean }>`
  position: relative;
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  background-color: ${p => p.theme.colors.surface.s2};
  border: 1px solid ${p => p.theme.colors.surface.s2};
  border-radius: 4px;
  padding: 4px;
  margin: 0 8px;
  cursor: ${p => (p.$isDisabled ? 'default' : 'pointer')};
  transition: all 0.2s;

  &:first-child {
    margin-bottom: 16px;
  }

  &:hover {
    border-color: ${p => (p.$isDisabled ? p.theme.colors.surface.s2 : '#333333')};
  }

  & > * {
    opacity: ${p => (p.$isDisabled && !p.$isUsed ? 0.3 : 1)};
  }
`
