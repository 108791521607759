import { useIntl } from 'react-intl'
import { AFTER_EXTRA_TIME_CODE, AFTER_PENALTIES_CODE, ENDED_CODE, INTERRUPTED_CODE } from 'entities/Status'

export const usePostMatchStatusOptions = () => {
  const intl = useIntl()

  const leaguePostMatchStatusOptions = [
    {
      label: intl.formatMessage({ id: 'Ended' }),
      value: ENDED_CODE,
    },
    {
      label: intl.formatMessage({ id: 'Interrupted' }),
      value: INTERRUPTED_CODE,
    },
  ]

  const cupPostMatchStatusOptions = [
    {
      label: intl.formatMessage({ id: 'ended_in_regular_time' }),
      value: ENDED_CODE,
    },
    {
      label: intl.formatMessage({ id: 'ended_after_et' }),
      value: AFTER_EXTRA_TIME_CODE,
    },
    {
      label: intl.formatMessage({ id: 'ended_after_penalties' }),
      value: AFTER_PENALTIES_CODE,
    },
    {
      label: intl.formatMessage({ id: 'match_interrupted' }),
      value: INTERRUPTED_CODE,
    },
  ]

  return [leaguePostMatchStatusOptions, cupPostMatchStatusOptions]
}
