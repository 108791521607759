import { Box, BoxProps } from '@sofascore/ui'

import { backgrounds, GREEN, secondaryColor } from 'styles/color'

interface ProgressBarProps extends BoxProps {
  value: number
  fullfillThreshold?: number
}

export const ProgressBar = ({ value, fullfillThreshold, ...props }: ProgressBarProps) => {
  const isFull = fullfillThreshold ? value >= fullfillThreshold : value >= 1

  return (
    <Box h={4} borderRadius={12} w={100} bg={backgrounds.lightRgba} {...props}>
      <Box
        borderRadius="md"
        bg={isFull ? GREEN.incident : secondaryColor}
        w={`${value * 100}%`}
        h="100%"
        style={{ transition: 'all 0.15s linear' }}
      />
    </Box>
  )
}
