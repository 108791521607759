import { Checkbox as UiCheckbox } from '@sofascore/ui'
import styled from 'styled-components'

export const Checkbox = styled(UiCheckbox)`
  padding: 0;
  height: auto;

  & > div {
    border-radius: 0;
  }

  & > div > svg {
    border-radius: inherit;
    width: 24px;
    height: 24px;
    margin-right: 0;
  }
`
