import React from 'react'
import { FormattedMessage } from 'react-intl'
import { RadioGroup, Box, FormControlLabel, Radio, Theme, useTheme, makeStyles, createStyles } from '@material-ui/core'
import { Stylable } from 'shared/model'
import { useMediaContext } from '@sofascore/ui'

import { FIRST_HALF, BETWEEN_PERIODS, SECOND_HALF, ENDED, OTHER } from 'modules/Matches/const'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioButtonGroup: {
      justifyContent: 'flex-start',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(4),
      marginTop: 11,
      marginBottom: theme.spacing(2.5),
      '& > label > span': {
        fontWeight: 500,
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: 0,
        justifyContent: 'space-between',
      },
    },
  }),
)

interface Props extends Stylable {
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void
}

/**
 * Choosing basic status with radio buttons.
 */
const StatusRadioGroup = ({ value, onChange, className }: Props) => {
  const classes = useStyles()
  const theme: Theme = useTheme()
  const { isMobile } = useMediaContext()

  return (
    <RadioGroup value={value} onChange={onChange} className={`${classes.radioButtonGroup} ${className}`} row>
      <>
        <Box style={isMobile ? { display: 'flex', flexDirection: 'column' } : {}}>
          <FormControlLabel
            value={FIRST_HALF}
            control={<Radio />}
            label={<FormattedMessage id="1st half" />}
            style={isMobile ? {} : { marginRight: theme.spacing(8) }}
          />
          <FormControlLabel
            value={BETWEEN_PERIODS}
            control={<Radio />}
            label={<FormattedMessage id="Halftime" />}
            style={isMobile ? {} : { marginRight: theme.spacing(8) }}
          />
        </Box>
        <Box style={isMobile ? { display: 'flex', flexDirection: 'column' } : {}}>
          <FormControlLabel
            value={SECOND_HALF}
            control={<Radio />}
            label={<FormattedMessage id="2nd half" />}
            style={isMobile ? {} : { marginRight: theme.spacing(8) }}
          />
          <FormControlLabel
            value={ENDED}
            control={<Radio />}
            label={<FormattedMessage id="Ended" />}
            style={isMobile ? { paddingRight: 21 } : { marginRight: theme.spacing(8) }}
          />
          <FormControlLabel
            value={OTHER}
            control={<Radio />}
            label={<FormattedMessage id="other" />}
            style={isMobile ? {} : { marginRight: theme.spacing(8) }}
          />
        </Box>
      </>
    </RadioGroup>
  )
}

export default StatusRadioGroup
