import IconMinimize from '@sofascore/ui/dist/modules/Icons/IconMinimize'

import * as S from './styles'

interface IncrementAccumulatedFoulsProps {
  onClick: () => void
  disabled?: boolean
}

export const DecrementAccumulatedFouls = ({ onClick, disabled }: IncrementAccumulatedFoulsProps) => {
  return (
    <S.ChangeFoulsButton onClick={onClick} disabled={disabled}>
      <IconMinimize fill="#000000" />
    </S.ChangeFoulsButton>
  )
}
