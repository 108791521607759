import useSWR from 'swr'
import { MatchesTabTitle, useEventState } from 'entities/Event'
import { SportName } from 'entities/Sport'
import { EventIncidentsProvider, IncidentsResponse } from 'entities/Incident'
import { Flex, Spinner } from '@sofascore/ui'
import { EventPlayersProvider, useTeamPlayers } from 'entities/Player'
import { useIntl } from 'react-intl'

import { getIncidents } from 'api/routes'

import { FootballEditLiveMatch } from './Football'
import { MiniFootballEditLiveMatch } from './MiniFootball'
import { RugbyEditLiveMatch } from './Rugby'
import { VolleyballEditMatch } from './Volleyball'
import { WaterPoloEditMatch } from './WaterPolo'
import { BasketballEditLiveMatch } from './Basketball'

const mapSportToForm: Record<string, () => JSX.Element> = {
  [SportName.Football]: FootballEditLiveMatch,
  [SportName.ESports]: FootballEditLiveMatch,
  [SportName.MiniFootball]: MiniFootballEditLiveMatch,
  [SportName.Futsal]: MiniFootballEditLiveMatch,
  [SportName.Rugby]: RugbyEditLiveMatch,
  [SportName.Volleyball]: VolleyballEditMatch,
  [SportName.Waterpolo]: WaterPoloEditMatch,
  [SportName.Basketball]: BasketballEditLiveMatch,
}

export const EditLiveMatch = () => {
  const { event } = useEventState()
  const { homeTeam, awayTeam, tournament } = event

  const intl = useIntl()

  const { data: incidentsData, isLoading: incidentsLoading } = useSWR<IncidentsResponse>(
    event ? getIncidents(+event.id) : null,
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    },
  )

  const { data: homeTeamData, isLoading: isHomeTeamPlayersLoading } = useTeamPlayers(homeTeam.id)
  const { data: awayTeamData, isLoading: isAwayTeamPlayersLoading } = useTeamPlayers(awayTeam.id)

  if (incidentsLoading || isHomeTeamPlayersLoading || isAwayTeamPlayersLoading) {
    return (
      <Flex w="100%" h="100%" display="flex" align="center" justify="center" direction="column">
        <Spinner color="#374df5" width="50px" />
      </Flex>
    )
  }

  const players = {
    [homeTeam.id]: homeTeamData ? homeTeamData : [],
    [awayTeam.id]: awayTeamData ? awayTeamData : [],
  }

  const sport = tournament.category.sport.slug
  const LiveMatchForm = mapSportToForm[sport]

  return (
    <EventPlayersProvider initialState={players}>
      <EventIncidentsProvider initialState={incidentsData?.incidents}>
        <MatchesTabTitle title={`⊚ ${intl.formatMessage({ id: 'edit_live' })}`} />
        <LiveMatchForm />
      </EventIncidentsProvider>
    </EventPlayersProvider>
  )
}
