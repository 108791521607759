export enum EventsFilterType {
  TournamentId = 'tournament',
  Round = 'roundNumber',
  Stage = 'roundSlug',
}

export type EventsFilter = {
  type: EventsFilterType
  value: string | undefined
  name: string | undefined
}
