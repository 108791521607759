import styled from 'styled-components'
import { Box } from '@sofascore/ui'
import { NavLink } from 'react-router-dom'

export const CompetitionList = styled(Box)<{ $hideMobile?: boolean }>`
  ${p => p.theme.breakpoints.query.mdMax`
    display: ${p.$hideMobile ? 'none' : 'block'};
    `}
`

export const CompetitionListSettingsIcon = styled(NavLink)`
  margin-left: auto;
  opacity: 0.3;
  &.active,
  &:hover {
    opacity: 1;
  }
`
