import useSWR from 'swr'

import { teamPlayers } from 'api/routes'

import { PlayersResponse } from '../model'

export const useTeamPlayers = (teamId: number) => {
  const { data, error, mutate, isLoading } = useSWR<PlayersResponse>(teamPlayers(teamId), {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  })

  const players = data ? data.players.map(p => p.player) : undefined

  return {
    data: players,
    error,
    mutate,
    isLoading,
  }
}
