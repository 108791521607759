import { Flex, FlexProps } from '@sofascore/ui'
import IconMinimize from '@sofascore/ui/dist/modules/Icons/IconMinimize'
import IconAdd from '@sofascore/ui/dist/modules/Icons/IconAdd'

import { ScoreInput } from 'components/ScoreInput'

import { CounterButton } from './ScoreCounterButton'

const MAX_SCORE = 999

interface ScoreCounterProps {
  value: number | undefined
  variant: 'wide' | 'compact'
  onChange: (value: number) => void
  onIncrement: () => void
  onDecrement: () => void
  disableButtons?: boolean
  disableInput?: boolean
}

const variantSizeMapping = {
  wide: {
    height: 48,
    incrementorWidth: 72,
  },
  compact: {
    height: 32,
    incrementorWidth: 32,
  },
}

export const ScoreCounter = ({
  value,
  onChange,
  onIncrement,
  onDecrement,
  variant,
  disableButtons,
  disableInput,
}: ScoreCounterProps & FlexProps) => {
  return (
    <Flex gapX={8}>
      <CounterButton
        width={variantSizeMapping[variant].incrementorWidth}
        height={variantSizeMapping[variant].height}
        onClick={onDecrement}
        disabled={value === undefined || disableButtons}
      >
        <IconMinimize />
      </CounterButton>

      <Flex w={56} h={variantSizeMapping[variant].height} justify="center" align="center">
        <ScoreInput
          width="100%"
          height={`${variantSizeMapping[variant].height}px`}
          value={value}
          onChange={onChange}
          textAlign="center"
          fontSize={24}
          br="xs"
          disabled={disableInput}
        />
      </Flex>

      <CounterButton
        width={variantSizeMapping[variant].incrementorWidth}
        height={variantSizeMapping[variant].height}
        onClick={onIncrement}
        disabled={value === MAX_SCORE || disableButtons}
      >
        <IconAdd />
      </CounterButton>
    </Flex>
  )
}
