import { FormattedMessage } from 'react-intl'
import { BasicEvent, getCurrentPeriodTime } from 'entities/Event'
import { NO_TIMER_CODES, StatusCode } from 'entities/Status'

import { ProgressTimer } from 'components/Timekeeping'
import { ADDITIONAL_TIME_DISPLAY_LIMIT } from 'utils/matchTime'

export default function Timer({ event }: { event: BasicEvent }) {
  if (event.time && !NO_TIMER_CODES.includes(event.status.code)) {
    return <ProgressTimer event={event} additionalTimeDisplayLimit={ADDITIONAL_TIME_DISPLAY_LIMIT} />
  }

  if (event.status.code === StatusCode['Awaiting extra time']) {
    return <FormattedMessage id="AwaitingExtraTime.short" />
  }

  if (event.status.code === StatusCode['Extra time halftime']) {
    return <FormattedMessage id="ExtraTimeHalftime.short" />
  }

  if (event.status.code === StatusCode['Awaiting penalties']) {
    return <FormattedMessage id="AwaitingPenalties.short" />
  }

  if (event.status.code === StatusCode.Penalties || event.status.code === StatusCode['Penalties alt.']) {
    return <FormattedMessage id="Penalties" />
  }

  return <>{getCurrentPeriodTime(event)}</>
}
