import { Theme, createStyles, makeStyles } from '@material-ui/core'
import styled from 'styled-components'
import { Box } from '@sofascore/ui'

export const MyCompetitions = styled(Box)`
  display: grid;
  grid-template-columns: 344px 3fr;
  grid-template-rows: auto;
  padding: 16px 48px;
  min-height: 100%;

  ${p => p.theme.breakpoints.query.mdMax`
    display: block;
    padding: 8px;
    `}
`
// TODO: remove this styles
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emptyCompetitionsContent: {
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        paddingLeft: theme.spacing(7.5),
        paddingRight: theme.spacing(7.5),
      },
    },
    dialogPaper: {
      margin: 15,
    },
    button: {
      width: 240,
      height: 48,
      fontSize: 16,
      border: '2px solid',
    },
    boxContainer: {
      marginTop: theme.spacing(24),
      width: '618px',
      marginLeft: 'auto',
      marginRight: 'auto',

      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(12),
        width: '100%',
      },
    },
    requestAccessTitle: {
      backgroundColor: theme.palette.primary.main,
    },
  }),
)
