import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { Portal, useMediaContext } from '@sofascore/ui'
import noop from 'lodash/noop'

import * as S from './styles'
import { TooltipAlignment, TooltipPlacement, TooltipProps } from './interface'
import { useIsOverflow } from './useIsOverflow'

const Tooltip = ({
  element,
  label,
  placement = 'bottom',
  alignment = 'right',
  clickable,
  ...boxProps
}: TooltipProps) => {
  const tooltipRef = useRef<HTMLElement>(null)
  const tooltipLabelRef = useRef<HTMLElement>(null)

  const [isVisible, setIsVisible] = useState(false)
  const { isMobile } = useMediaContext()

  const [isLeftOverflow, isRightOverflow, isTopOverflow, isBottomOverflow] = useIsOverflow(tooltipLabelRef, [isVisible])

  const [_placement, _alignment]: [TooltipPlacement, TooltipAlignment] = useMemo(() => {
    // Tooltip is overflowing on both sides, nothing to do here
    if ((isLeftOverflow && isRightOverflow) || (isTopOverflow && isBottomOverflow)) return [placement, alignment]

    // Left overflow? Change alignment (or placement) to right to fix it
    if (isLeftOverflow) {
      if (placement === 'left') return ['right', alignment]
      return [placement, 'right']
    }

    // Right overflow? Change alignment (or placement) to left to fix it
    if (isRightOverflow) {
      if (placement === 'right') return ['left', alignment]
      return [placement, 'left']
    }

    // Top overflow? Change placement to bottom to fix it
    if (isTopOverflow) {
      if (placement === 'top') return ['bottom', alignment]
    }

    // Bottom overflow? Change placement to top to fix it
    if (isBottomOverflow) {
      if (placement === 'bottom') return ['top', alignment]
    }

    // No overflow, leave initial options
    return [placement, alignment]
  }, [isLeftOverflow, isRightOverflow, isTopOverflow, isBottomOverflow, placement, alignment])

  const recalculateTooltipPosition = useCallback(() => {
    if (tooltipRef.current && tooltipLabelRef.current) {
      const { left, top, width: iconWidth, height: iconHeight } = tooltipRef.current.getBoundingClientRect()
      const { width, height } = tooltipLabelRef.current.getBoundingClientRect()
      const spacing = 16

      if (_placement === 'top') {
        tooltipLabelRef.current.style.top = `calc(${top}px - ${height}px - ${spacing}px)`
        tooltipLabelRef.current.style.left =
          _alignment === 'left' ? `calc(${left}px - ${width}px + ${iconWidth}px)` : `${left}px`
      }

      if (_placement === 'bottom') {
        tooltipLabelRef.current.style.top = `calc(${top}px + ${iconHeight}px + ${spacing}px)`
        tooltipLabelRef.current.style.left =
          _alignment === 'left' ? `calc(${left}px - ${width}px + ${iconWidth}px)` : `${left}px`
      }

      if (_placement === 'left') {
        tooltipLabelRef.current.style.top = `calc(${top}px - ${height / 2}px + ${iconHeight / 2}px)`
        tooltipLabelRef.current.style.left = `calc(${left}px - ${width}px - ${spacing}px)`
      }

      if (_placement === 'right') {
        tooltipLabelRef.current.style.top = `calc(${top}px - ${height / 2}px + ${iconHeight / 2}px)`
        tooltipLabelRef.current.style.left = `calc(${left}px + ${iconWidth}px + ${spacing}px)`
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tooltipRef.current, tooltipLabelRef.current, isMobile, _alignment, _placement])

  useLayoutEffect(() => {
    recalculateTooltipPosition()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tooltipRef.current, tooltipLabelRef.current, isMobile, _placement, _alignment, recalculateTooltipPosition])

  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(false)
      recalculateTooltipPosition()
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [recalculateTooltipPosition])

  useEffect(() => {
    recalculateTooltipPosition()
  }, [isVisible, recalculateTooltipPosition])

  return (
    <>
      <S.Tooltip
        ref={tooltipRef}
        onMouseEnter={clickable && isMobile ? noop : () => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        onClick={e => {
          if (clickable) {
            e.stopPropagation()
            setIsVisible(isVisible => !isVisible)
          }
        }}
      >
        {element}
      </S.Tooltip>

      <Portal>
        <S.TooltipLabel
          role="tooltip"
          ref={tooltipLabelRef}
          isVisible={isVisible}
          placement={_placement}
          alignment={_alignment}
          {...boxProps}
        >
          {label}
        </S.TooltipLabel>
      </Portal>
    </>
  )
}

export default Tooltip
