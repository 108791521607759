import { FirebaseApp } from 'firebase/app'
import type { AnalyticsCallOptions, EventParams } from 'firebase/analytics'

import { isProd } from 'api/constants'

import { FirebaseEventName, FirebaseEventNameType, LogEventProps } from './../model'
import { firebaseConfig } from './../config'

let firebaseApp: FirebaseApp | undefined

const isFirebaseDebug = new URLSearchParams(window.location.search).get('firebase_debug')

async function getFirebaseApp() {
  if (!firebaseApp) {
    const { initializeApp } = await import('firebase/app')
    firebaseApp = initializeApp(firebaseConfig)
  }

  if (isFirebaseDebug) {
    console.log('Firebase app initialized...')
  }

  return firebaseApp
}

const debugLogFirebaseEvent = (
  eventName: FirebaseEventNameType | string,
  eventParams?: EventParams,
  isQueue?: boolean,
) => {
  if (isFirebaseDebug) {
    console.table({ eventName, ...eventParams, firebase_event_status: isQueue ? 'Added to queue' : 'Sent' })
  }
}

// Events that are being fired before initialization will be added to queue and sent after initialization.
const firebaseEventQueue: LogEventProps[] = []

export let logFirebaseEvent = (
  eventName: FirebaseEventNameType | string,
  eventParams?: EventParams,
  options?: AnalyticsCallOptions,
) => {
  firebaseEventQueue.push({ eventName, eventParams, options })
  debugLogFirebaseEvent(eventName, eventParams, true)
}

export async function initializeFirebaseAnalytics() {
  const { getAnalytics, isSupported, logEvent } = await import('firebase/analytics')

  const isAnalyticsSupported = await isSupported()
  if (!isAnalyticsSupported || !isProd) return

  const firebaseApp = await getFirebaseApp()
  const firebaseAnalytics = getAnalytics(firebaseApp)
  logFirebaseEvent = (
    eventName: FirebaseEventNameType | string,
    eventParams?: EventParams,
    options?: AnalyticsCallOptions,
  ) => {
    logEvent(firebaseAnalytics, eventName, { ...eventParams }, options)
    debugLogFirebaseEvent(eventName, eventParams)
  }

  firebaseEventQueue.forEach(({ eventName, eventParams, options }) => {
    logFirebaseEvent(eventName, eventParams, options)
  })
}

/**
 * Save user id to firebase
 */
export const saveUserIDToFirebase = async (userId: string) => {
  const { getAnalytics, setUserId } = await import('firebase/analytics')
  const firebaseApp = await getFirebaseApp()
  const firebaseAnalytics = getAnalytics(firebaseApp)
  setUserId(firebaseAnalytics, userId)
}

/**
 * Utility wrapper around `logFirebaseEvent()` used to log
 * user interaction (`FirebaseEventName.UserInteraction`).
 */
export const logUserInteraction = (
  eventParams?: {
    action?: 'click' | string
    location?: string
    type?: string
  },
  options?: AnalyticsCallOptions,
) => {
  logFirebaseEvent(FirebaseEventName.UserInteraction, eventParams, options)
}
