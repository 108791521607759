import { Team } from 'entities/Team'

/**
 * Used to remove teams that are already part of tournament
 */
export const removeDuplicates = (existingTeams: Team[] | undefined, tournamentTeams: Team[] | undefined) => {
  if (!existingTeams) return []

  if (!tournamentTeams) return existingTeams

  const teamsIdList = tournamentTeams.map(team => team.id)

  return existingTeams.filter(team => !teamsIdList.includes(team.id))
}
