import { mutate } from 'swr'
import { useSelector } from 'react-redux'
import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useToast } from 'shared/lib'
import { useErrorHandler } from 'shared/hooks'

import { getAuthFromState } from 'store/selectors'
import { eventDetails, getIncidents } from 'api/routes'
import { postMatchUpdate } from 'api'

import { PostMatchUpdateData } from '../model'

export const usePostMatchUpdateHandler = () => {
  const { userAccount } = useSelector(getAuthFromState)
  const intl = useIntl()
  const { enqueueToast } = useToast()
  const handleError = useErrorHandler()

  const handleUpdate = useCallback(
    async (data: PostMatchUpdateData) => {
      if (!userAccount) return

      try {
        await postMatchUpdate(data)

        enqueueToast(intl.formatMessage({ id: 'alert_changes_saved' }), { variant: 'success' })

        mutate(eventDetails(data.eventId))
        mutate(getIncidents(data.eventId))
      } catch (e) {
        handleError(e)
      }
    },
    [intl, userAccount, handleError, enqueueToast],
  )

  return { handleUpdate }
}
