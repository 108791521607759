import { Flex, Text } from '@sofascore/ui'
import { TeamCard } from 'entities/Team'
import { FormattedMessage } from 'react-intl'
import { useRemoveTeamHandler } from 'features/RemoveTeam'
import { WithSwipeToDelete, PermissionGuard } from 'shared/ui'
import IconDelete from '@sofascore/ui/dist/modules/Icons/IconDelete'
import { useNavigate, useParams } from 'react-router-dom'
import { PermissionAction, PermissionScope } from 'entities/Permission'
import { usePermissions } from 'shared/hooks'

import { IconButton } from 'components/IconButton'
import { Spinner } from 'components/Spinner'

import { MyCompetitionAddTeam } from './MyCompetitionAddTeam'
import { useGroupedCompetitionTeams } from '../hooks'
import * as S from './styles'

export const MyCompetitionApprovedCupTeams = () => {
  const { competitionId, seasonId } = useParams()
  const { data, error, isLoading } = useGroupedCompetitionTeams()
  const navigate = useNavigate()
  const { guardEntity } = usePermissions()

  const removeTeam = useRemoveTeamHandler()

  const backRef = `/my-competitions/${competitionId}/season/${seasonId}/teams`

  if (isLoading) {
    return (
      <Flex justify="center" mt={100}>
        <Spinner width="40px" />
      </Flex>
    )
  }

  if (error) return null

  const { groups } = data!

  return (
    <>
      {groups.map(group => {
        if (group.teams.length === 0) {
          if (!guardEntity(group.tournament.allowedActions, [PermissionAction.Create], PermissionScope.Team)) {
            return null
          }
        }

        return (
          <Flex key={group.tournament.id} direction="column" bg="surface.s1" elevation={2} br="sm" mt="sm" pb="sm">
            <Flex justify="space-between" align="center" py="md" px="lg">
              <Text font="display.micro">{group.tournament.name}</Text>
              <PermissionGuard
                entityAllowedActions={group.tournament.allowedActions}
                checkAllowedActions={[PermissionAction.Create]}
                entityAllowedActionsScope={PermissionScope.Team}
              >
                <MyCompetitionAddTeam tournamentId={group.tournament.id} />
              </PermissionGuard>
            </Flex>
            <Flex direction="column">
              {group?.teams.length ? (
                group.teams
                  .sort((t1, t2) => t1.name.localeCompare(t2.name))
                  .map(team => (
                    <WithSwipeToDelete
                      key={team.id}
                      onSwipe={() => removeTeam(team, group.tournament.id)}
                      disabled={!guardEntity(team.allowedActions, [PermissionAction.Delete])}
                    >
                      <S.DeleteWrapper
                        px="lg"
                        py="sm"
                        hoverBg="surface.s2"
                        cursor="pointer"
                        onClick={() => {
                          navigate(`/team/${team.id}?uniqueTournamentId=${competitionId}`, { state: { backRef } })
                        }}
                      >
                        <TeamCard team={team}>
                          <PermissionGuard
                            entityAllowedActions={team.allowedActions}
                            checkAllowedActions={[PermissionAction.Delete]}
                          >
                            <IconButton
                              br="5px"
                              p="5px"
                              hoverBg="onSurface.nLv5"
                              onClick={e => {
                                e?.stopPropagation()
                                removeTeam(team, group.tournament.id)
                              }}
                            >
                              <IconDelete fill="onSurface.nLv1" />
                            </IconButton>
                          </PermissionGuard>
                        </TeamCard>
                      </S.DeleteWrapper>
                    </WithSwipeToDelete>
                  ))
              ) : (
                <Flex justify="center" py={40}>
                  <Text>
                    <FormattedMessage id="my_competition_empty_group" />
                  </Text>
                </Flex>
              )}
            </Flex>
          </Flex>
        )
      })}
    </>
  )
}
