import { Text } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'
import { PropsWithChildren } from 'react'
import IconPenaltyMissed from '@sofascore/ui/dist/modules/Icons/_IconPenaltyMissed'

import { RED } from 'styles/color'

import { Incident } from '../../model'
import { IncidentBody, IncidentFrame, IncidentHead, IncidentTail, IncidentTime } from '../'

interface Props {
  incident: Incident
}

export const IncidentFootballMissedPenalty = ({ children, incident }: PropsWithChildren<Props>) => {
  const { isHome, time, addedTime, description, player } = incident

  return (
    <IncidentFrame>
      <IncidentHead isHome={isHome}>
        <IconPenaltyMissed fill={RED.incident} />
        <IncidentTime addedTime={addedTime} time={time} />
      </IncidentHead>

      <IncidentBody isHome={isHome}>
        <Text font="body.medium">{player?.name || <FormattedMessage id="missed_penalty" />}</Text>
        <Text font="body.small" color="onSurface.nLv3">
          {description}
        </Text>
      </IncidentBody>

      <IncidentTail>{children}</IncidentTail>
    </IncidentFrame>
  )
}
