import { Text } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'
import { PropsWithChildren } from 'react'
import IconFootballOwnGoal from '@sofascore/ui/dist/modules/Icons/_IconFootballOwnGoal'

import { RED } from 'styles/color'

import { Incident } from '../../model'
import { IncidentBody, IncidentFrame, IncidentHead, IncidentResult, IncidentTail, IncidentTime } from '../'

interface Props {
  incident: Incident
}

export const IncidentOwnGoal = ({ children, incident }: PropsWithChildren<Props>) => {
  const { isHome, time, addedTime, player, homeScore, awayScore } = incident

  return (
    <IncidentFrame>
      <IncidentHead isHome={isHome}>
        <IconFootballOwnGoal fill={RED.incident} />
        <IncidentTime addedTime={addedTime} time={time} />
      </IncidentHead>

      <IncidentResult isHome={isHome} homeScore={homeScore} awayScore={awayScore} />

      <IncidentBody isHome={isHome}>
        <Text align={isHome ? 'left' : 'right'} font="body.medium">
          {player?.name}
          {!player?.name ? <FormattedMessage id="autogoal" /> : ''}
        </Text>
      </IncidentBody>

      <IncidentTail>{children}</IncidentTail>
    </IncidentFrame>
  )
}
