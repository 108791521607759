import { Flex, FlexProps } from '@sofascore/ui'

import { IconAddImageOutlined } from 'components/Icons/IconAddImageOutlined'
import { IconButton } from 'components/IconButton'

import * as S from './styles'

interface SubstitutionSlotEmptyProps extends FlexProps {
  pos: number
  onAddSubstitution: (position: number) => void
}

export const SubstitutionSlotEmpty = ({ pos, onAddSubstitution, ...flexProps }: SubstitutionSlotEmptyProps) => {
  return (
    <Flex
      align="center"
      justify="space-between"
      br="sm"
      px={8}
      py={6}
      border="1px dashed onSurface.nLv3"
      {...flexProps}
    >
      <S.SubstitutionSlotEmptyAvatar>
        <S.SubstitutionSlotEmptyPosition>{pos}</S.SubstitutionSlotEmptyPosition>
      </S.SubstitutionSlotEmptyAvatar>

      <IconButton onClick={() => onAddSubstitution(pos)}>
        <IconAddImageOutlined />
      </IconButton>
    </Flex>
  )
}
