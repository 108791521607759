import { Flex, Image, Text } from '@sofascore/ui'
import { useEventState } from 'entities/Event'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import IconCreate from '@sofascore/ui/dist/modules/Icons/IconCreate'
import { useFirebaseWithEvent } from 'entities/Event/hooks'
import { FirebaseEventName, FirebaseEventType, FirebaseEventIncidentAction } from 'shared/lib/firebase/model'

import ResponsivePopUp from 'components/ResponsivePopUp'
import { teamImage } from 'api/routes'
import { IconButton } from 'components/IconButton'

import { EditRugbyLastShootoutPenaltyForm } from './EditLastShootoutPenaltyForm'
import { EditIncidentProps } from '../../model'

export const EditRugbyLastShootoutPenalty = ({ onEdit, incident }: EditIncidentProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { event } = useEventState()
  const { homeTeam, awayTeam } = event
  const { isHome } = incident
  const logFirebaseWithEvent = useFirebaseWithEvent()

  const responsivePopupClose = (discarded?: boolean | undefined) => {
    setIsOpen(false)
    if (discarded)
      logFirebaseWithEvent(
        FirebaseEventName.DiscardEntity,
        FirebaseEventType.Incident,
        FirebaseEventIncidentAction.PenaltyShootout,
      )
    else
      logFirebaseWithEvent(
        FirebaseEventName.SubmitEntity,
        FirebaseEventType.Incident,
        FirebaseEventIncidentAction.PenaltyShootout,
      )
  }

  return (
    <>
      <IconButton onClick={() => setIsOpen(true)} br="xs" hoverBg="onSurface.nLv5">
        <IconCreate fill="#000000" />
      </IconButton>

      <ResponsivePopUp
        isOpen={isOpen}
        onClose={responsivePopupClose}
        heading={
          <>
            <Flex align="center" justify="space-between" w="100%" direction="row">
              <Flex>
                <Image
                  src={teamImage(isHome ? homeTeam.id : awayTeam.id)}
                  w={24}
                  h={24}
                  mr="sm"
                  alt={isHome ? homeTeam.name : awayTeam.name}
                />
                <Text font="display.large">
                  <FormattedMessage id="edit_penalty" />
                </Text>
              </Flex>
            </Flex>
          </>
        }
      >
        <EditRugbyLastShootoutPenaltyForm incident={incident} onClose={responsivePopupClose} onSubmit={onEdit} />
      </ResponsivePopUp>
    </>
  )
}
