import { Flex, Text } from '@sofascore/ui'
import { BasicEvent } from 'entities/Event'
import { sportsWithPeriodTime } from 'entities/Sport'
import { NO_TIMER_CODES, StatusType, getStatusDescription } from 'entities/Status'

import { ProgressTimer } from 'components/Timekeeping'
import { ADDITIONAL_TIME_DISPLAY_LIMIT } from 'utils/matchTime'
import { formatDate, formatTime, getTimeInDay, isToday } from 'utils/time'

// startTimestamp is in seconds
const getMatchTime = (startTimestamp: number) =>
  isToday(startTimestamp)
    ? getTimeInDay(new Date(startTimestamp * 1000))
    : formatDate(new Date(startTimestamp * 1000), { format: 'dd/mm/yy' })

const StatusSection = ({ event }: { event: BasicEvent }) => {
  const { tournament, startTimestamp, status, time: statusTime, crowdsourcingDataDisplayEnabled } = event

  const sport = tournament.category.sport.slug

  const isLive = status.type === StatusType.IN_PROGRESS
  const isLocked = event.feedLocked
  const isCrowdsourcingLive = crowdsourcingDataDisplayEnabled && isLive

  const isProgressTimerActive =
    sportsWithPeriodTime.includes(sport) &&
    !crowdsourcingDataDisplayEnabled &&
    isLive &&
    statusTime &&
    !NO_TIMER_CODES.includes(status.code)

  return (
    <Flex
      w={72}
      minW={72}
      px="sm"
      direction="column"
      justify="space-around"
      align="center"
      borderRight="solid 1px rgba(34, 34, 38, 0.15)"
      overflow="hidden"
      style={{ opacity: isLocked ? '.5' : 1 }}
    >
      <Text fontSize="small" color="onSurface.nLv3">
        {getMatchTime(startTimestamp)}
      </Text>

      {isProgressTimerActive ? (
        <Text w="100%" fontSize="small" align="center" color={isLive ? 'sofaSingles.live' : 'onSurface.nLv3'} ellipsis>
          <ProgressTimer event={event} additionalTimeDisplayLimit={ADDITIONAL_TIME_DISPLAY_LIMIT} />
        </Text>
      ) : (
        <Text
          w="100%"
          fontSize="small"
          align="center"
          color={isCrowdsourcingLive ? 'primary.default' : isLive ? 'sofaSingles.live' : 'onSurface.nLv3'}
          ellipsis
        >
          {status.type === StatusType.NOT_STARTED && !isToday(startTimestamp)
            ? formatTime(new Date(startTimestamp * 1000))
            : getStatusDescription(event)}
        </Text>
      )}
    </Flex>
  )
}

export default StatusSection
