import styled from 'styled-components'

export const LanguageDropdown = styled.div<{ isMobile?: boolean }>`
  cursor: pointer;
  background: transparent;
  margin-right: 24px;
  & > div > span {
    display: ${p => (p.isMobile ? 'block' : 'none')};
  }
`
