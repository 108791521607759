import { Flex, Text, colors } from '@sofascore/ui'
import { ReactNode } from 'react'

import { borderMergeStyles } from './styles'

interface Props {
  label: string
  endingContent: ReactNode
  textColor?: string
  merge?: 'top' | 'bottom'
  disabled?: boolean
  onClick: () => void
}

const MatchActionButton = ({ label, endingContent, textColor, merge, disabled, onClick }: Props) => {
  return (
    <Flex
      justify="space-between"
      border={`solid 1px ${colors.light['--on-surface-nLv4']}`}
      p="lg"
      bg="surface.s1"
      style={{
        fontSize: '14px',
        cursor: disabled ? 'default' : 'pointer',
        ...borderMergeStyles(merge),
      }}
      onClick={() => {
        if (!disabled) onClick()
      }}
    >
      <Text
        fontWeight="bold"
        color={disabled ? colors.light['--on-surface-nLv3'] : textColor}
        textTransform="capitalize"
      >
        {label}
      </Text>

      <Text fontSize={12} fontWeight={500} color={disabled ? colors.light['--on-surface-nLv3'] : textColor}>
        {endingContent}
      </Text>
    </Flex>
  )
}

export default MatchActionButton
