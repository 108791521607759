import { Player } from 'entities/Player'
import { ScoreWithPeriods } from 'entities/Score'
import { ReactNode } from 'react'
import { ApiResponse } from 'shared/api'
import { Stylable } from 'shared/model'

export interface IncidentData {
  incidentType: IncidentType
  isHome: boolean
  time: number | null
  addedTime?: number
  incidentClass?: string
  player?: Player
  assist1?: Player
  homeScore?: number
  awayScore?: number
  playerIn?: Player
  playerOut?: Player
  reason?: string
  reasonDescription?: string
  sequence?: number
  score?: number
  type?: string
  description?: string
  duration?: number | null
}

export interface IncidentsProps extends Stylable {
  parentContainerRef: React.RefObject<HTMLDivElement>
}

export interface IncidentsResponse extends ApiResponse {
  incidents: Incident[]
}

export enum IncidentType {
  Goal = 'goal',
  Card = 'card',
  Substitution = 'substitution',
  InGamePenalty = 'inGamePenalty',
  PenaltyShootout = 'penaltyShootout',
  InjuryTime = 'injuryTime',
  Period = 'period',
  Suspension = 'suspension',
}

export enum IncidentGoalType {
  GoalRegular = 'regular',
  GoalFromPenalty = 'penalty',
  OwnGoal = 'ownGoal',
  AccumulatedPenalty = 'accumulatedPenalty',
  Try = 'try',
  Conversion = 'conversion',
  DropGoal = 'dropGoal',
  PenaltyTry = 'penaltyTry',
}

export enum IncidentCardType {
  Yellow = 'yellow',
  Red = 'red',
  YellowRed = 'yellowRed',
}

export enum IncidentSubstitutionType {
  Regular = 'regular',
  Injury = 'injury',
  Temporary = 'temporary',
}

export enum IncidentInGamePenaltyType {
  Missed = 'missed',
}

export enum IncidentMissedInGamePenaltyType {
  OffTarget = 'offTarget',
  GoalkeeperSave = 'goalkeeperSave',
  Woodwork = 'woodwork',
}

export enum IncidentPenaltyShootoutType {
  Scored = 'scored',
  Missed = 'missed',
}

export enum IncidentSuspensionType {
  TwoMinutes = 'twoMinutes',
}

export interface IncidentBase {
  id?: number
  incidentType: IncidentType
  time: number | null
  addedTime?: number
  incidentClass?: string
  isHome?: boolean
  player?: Player
  playerIn?: Player
  playerOut?: Player
  assist1?: Player
  homeScore?: number
  awayScore?: number
  description?: string
  sequence?: number
  score?: number
  text?: string
  reason?: string
  duration?: number
}

/**
 * Base incident type for *REAL* incidents.
 * *Real* incidents don't include time or period related incidents,
 * such as `INJURY_TIME` or `PERIOD`.
 */
export interface RealIncidentBase extends IncidentBase {
  id: number
  incidentClass?: string
  isHome?: boolean
}

export interface PeriodIncident extends IncidentBase {
  text: string
  homeScore: number
  awayScore: number
  isLive?: boolean
}

export interface InjuryTimeIncident extends IncidentBase {
  length: number
}

export interface CardIncident extends RealIncidentBase {
  player?: Player
  playerName?: string
  reason?: string
}

export interface SubstitutionIncident extends RealIncidentBase {
  playerIn?: Player
  playerOut?: Player
  injury?: boolean
}

export interface GoalIncident extends RealIncidentBase {
  homeScore: number
  awayScore: number
  player?: Player
  assist1?: Player
}

export interface InGamePenalty extends RealIncidentBase {
  player?: Player
  playerName?: string
  // Since inGamePenalty incident type is used only for missed
  // penalties, incidentClass will always be 'missed'
  incidentClass: 'missed'
  description: string
}

export interface PenaltyShootoutIncident extends RealIncidentBase {
  homeScore: number
  awayScore: number
  sequence: number
  score: number
  player?: Player
  playerName?: string
}

export interface SuspensionIncident extends RealIncidentBase {
  duration?: number
}

export type Incident =
  | GoalIncident
  | SubstitutionIncident
  | CardIncident
  | InjuryTimeIncident
  | PeriodIncident
  | PenaltyShootoutIncident
  | SuspensionIncident

export interface PenaltyShootoutIndices {
  firstPenaltyShootoutIndex: number
  lastPenaltyShootoutIndex: number
}

export interface DeleteIncidentData {
  eventId: number
  incidentType: string
  incidentClass?: string
  incidentId: number
  isHome?: boolean
  homeScore?: number
  awayScore?: number
}

export interface CreateIncidentData {
  eventId: number
  incidentData: IncidentData
}

export interface UpdateIncidentData extends CreateIncidentData {
  incidentId: number
  updateScore?: boolean
}

export interface PostMatchUpdateData {
  eventId: number
  data: {
    statusCode: number
    homeScore: ScoreWithPeriods
    awayScore: ScoreWithPeriods
    incidents: Incident[]
    crowdsourcingDataDisplayEnabled?: boolean
    aggregatedWinnerCode?: number | null
  }
}

export interface IncidentResponse extends ApiResponse {
  incident: Incident
}

export interface PeriodGroup {
  incidents: Incident[]
  periodIncident: PeriodIncident
  periodEndHomeScore: number
  periodEndAwayScore: number
  isLive?: boolean
}

export interface PointTypeOption {
  translationKey: string
  goalType: IncidentGoalType
  points: number
  icon: ReactNode
}
