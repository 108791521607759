import { BoxProps, Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

import { IconIndicatorPlay } from 'components/Icons/IconIndicatorPlay'

import { Panel, PanelContent, PanelButton } from './styles'

export default function RemoveSuggestionsPanel({ onClick, ...props }: { onClick: () => void } & BoxProps) {
  return (
    <Panel {...props}>
      <PanelContent>
        <IconIndicatorPlay width={24} height={24} style={{ flexShrink: 0 }} />
        <Typography>
          <FormattedMessage id="editor_save_or_delete" />
        </Typography>
      </PanelContent>
      <PanelButton onClick={onClick}>
        <FormattedMessage id="delete" />
      </PanelButton>
    </Panel>
  )
}
