import { useEffect, useState } from 'react'
import { Box, Flex, Text } from '@sofascore/ui'
import { FormattedDate, FormattedMessage } from 'react-intl'
import useSWR from 'swr'
import { motion } from 'framer-motion'
import IconCalendar from '@sofascore/ui/dist/modules/Icons/_IconCalendarEmpty'

import { isOnSameDay, isToday } from 'utils/time'
import ArrowSlider from 'components/ArrowSlider'
import ResponsivePopUp from 'components/ResponsivePopUp'
import { EditorDaysWithEvents } from 'components/DateTimePickers/interface'
import { daysWithEditorEventsRange } from 'api/routes'

import { HomeDatePickerCalendar } from './HomeDatePickerCalendar'
import { getQuickDateRange } from './utils'
import { DATE_HEIGHT, DATE_WIDTH, DATE_WIDTH_MOBILE, DAY_HEIGHT } from './config'
import * as S from './styles'

interface CalendarSliderProps {
  selectedDate: Date
  onChange: (date: Date) => void
}

/**
 * Calendar Slider component consists of a quick date list slider and a date picker on the right-hand side.
 *
 * Quick dates are fixed until a new central date is picked with DatePicker.
 *
 * @param param0
 * @returns
 */
const CalendarSlider = ({ selectedDate, onChange }: CalendarSliderProps) => {
  const [quickDateRange, setQuickDateRange] = useState(getQuickDateRange(selectedDate))
  const [selectedQuickIndex, setSelectedQuickIndex] = useState(0)

  const { data } = useSWR<EditorDaysWithEvents>(daysWithEditorEventsRange())

  const { daysWithEvents } = { ...(data as EditorDaysWithEvents) }

  const [isDatePickerOpen, setDatePickerOpen] = useState(false)

  const handleQuickDateChange = (nextDate: Date) => {
    const targetIndex = quickDateRange.findIndex(quickDate => isOnSameDay(quickDate.getTime(), nextDate.getTime()))
    if (targetIndex !== -1) {
      onChange(nextDate)
    }
  }

  const handleDatePickerChange = (date: Date) => {
    // Recalculate quick date range on DatePicker select
    setQuickDateRange(getQuickDateRange(date))
    setDatePickerOpen(false)
    onChange(date)
  }

  useEffect(() => {
    const i = quickDateRange.findIndex(quickDate => isOnSameDay(quickDate.getTime(), selectedDate.getTime()))
    if (i !== -1) setSelectedQuickIndex(i)
  }, [quickDateRange, selectedDate])

  return (
    <Flex h={DAY_HEIGHT + DATE_HEIGHT} justify="center" bg={['surface.s1', 'transparent']} userSelect="none">
      <Box overflow="hidden" bg={['surface.s1', undefined]}>
        <ArrowSlider w="100%" initialIndex={selectedQuickIndex} pctScrolled={0.5}>
          {quickDateRange.map((date, i) => {
            const hasDateEvents = daysWithEvents?.find(month => {
              const isSameYear = month.year === date.getFullYear()
              const isSameMonth = month.month === date.getMonth() + 1
              const hasMonthEvents = month.days.includes(date.getDate())

              return isSameYear && isSameMonth && hasMonthEvents
            })

            return (
              <Flex
                key={i}
                direction="column"
                minW={[DATE_WIDTH_MOBILE, DATE_WIDTH]}
                h={DATE_HEIGHT + DAY_HEIGHT}
                cursor="pointer"
                hoverBg="onSurface.nLv5"
                br="sm"
                onClick={() => handleQuickDateChange(date)}
              >
                <Flex h={DAY_HEIGHT} justify="center" align="center">
                  <Text fontSize="small" color="onSurface.nLv3">
                    <FormattedDate value={date} weekday="short" />
                  </Text>
                </Flex>

                <Flex h={DATE_HEIGHT} direction="column" align="center">
                  <motion.div whileTap={{ scale: 0.9 }}>
                    <Flex
                      w={32}
                      h={32}
                      justify="center"
                      align="center"
                      p="xs"
                      bg={isOnSameDay(date.getTime(), selectedDate.getTime()) ? 'primary.default' : 'none'}
                      br="50%"
                      style={{ boxSizing: 'border-box' }}
                    >
                      <Text
                        fontWeight="bold"
                        color={
                          isOnSameDay(date.getTime(), selectedDate.getTime())
                            ? 'surface.s1'
                            : isToday(date)
                            ? 'primary.default'
                            : 'none'
                        }
                      >
                        {date.getDate()}
                      </Text>
                    </Flex>
                  </motion.div>

                  {hasDateEvents && (
                    <Box w={4} h={4} bg="primary.default" br="lg" style={{ transform: 'translate(0, -100%)' }} />
                  )}
                </Flex>
              </Flex>
            )
          })}
        </ArrowSlider>
      </Box>

      {/* Date Picker button */}
      <S.CalendarButton
        h={64}
        justify="center"
        align="center"
        bg="surface.s1"
        cursor="pointer"
        onClick={() => setDatePickerOpen(true)}
      >
        <IconCalendar w={24} h={24} />
      </S.CalendarButton>

      <ResponsivePopUp
        isOpen={isDatePickerOpen}
        onClose={() => setDatePickerOpen(false)}
        heading={
          <Text fontWeight="bold" fontSize="large">
            <FormattedMessage id="changeDate" />
          </Text>
        }
      >
        <HomeDatePickerCalendar date={selectedDate} onChange={handleDatePickerChange} />
      </ResponsivePopUp>
    </Flex>
  )
}

export default CalendarSlider
