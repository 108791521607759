import { Button, Flex, GraphicEmptyStateNoData, Text } from '@sofascore/ui'
import { PermissionAction, PermissionScope } from 'entities/Permission'
import { FormattedMessage } from 'react-intl'
import { PermissionGuard } from 'shared/ui'

import { useTeam } from 'modules/TeamDetailsRedesign/TeamContext'

interface Props {
  onAddNewPlayer: () => void
}

const EmptyState = ({ onAddNewPlayer }: Props) => {
  const team = useTeam()

  return (
    <Flex direction="column" align="center" pt="xxl">
      <GraphicEmptyStateNoData />

      <Text mt="lg" fontSize="medium" fontWeight="bold">
        <FormattedMessage id="no_players_added" />
      </Text>

      <Text mt="lg" color="onSurface.nLv3">
        <FormattedMessage id="emptyPlayersMyAddedItems" />
      </Text>

      <PermissionGuard
        entityAllowedActions={team?.allowedActions}
        checkAllowedActions={[PermissionAction.Create]}
        entityAllowedActionsScope={PermissionScope.Player}
      >
        <Button mt="xl" py="lg" w="100%" maxW={312} onClick={onAddNewPlayer}>
          <Text fontWeight="bold" color="surface.s1">
            <FormattedMessage id="add_new_player" />
          </Text>
        </Button>
      </PermissionGuard>
    </Flex>
  )
}

export default EmptyState
