import { Box, useMediaContext } from '@sofascore/ui'
import { NOT_STARTED_CODE } from 'entities/Status'
import { FormattedMessage, useIntl } from 'react-intl'
import { getUpdateEventBody, useEventState } from 'entities/Event'
import { useRef } from 'react'
import { SaveAllChanges } from 'widgets/SaveAllChanges'
import { useErrorHandler } from 'shared/hooks'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'
import { FirebaseEventName } from 'shared/lib/firebase/model'
import { mutate } from 'swr'
import { useToast } from 'shared/lib'
import { periodNamesSportMap } from 'entities/Score'
import { CompetitionType } from 'entities/Tournament'
import { SetAggregateWinner } from 'features/SetAggregateWinner'
import { GeneralEditMatchStatus } from 'widgets/GeneralEditMatchStatus'
import { GeneralEditMatchScoreHeader } from 'widgets/GeneralEditMatchScoreHeader'
import { SetPeriodScoreFRO } from 'features/SetPeriodScore'
import { SportName } from 'entities/Sport'

import { updateEvent } from 'api'
import { eventDetails } from 'api/routes'

import { volleyballStatusCodes, volleyballPeriods } from '../../config'

export const VolleyballEditMatch = () => {
  const intl = useIntl()
  const containerRef = useRef<HTMLDivElement>(null)
  const handleError = useErrorHandler()

  const { isMobile } = useMediaContext()
  const { event, hasChanges } = useEventState()
  const { status, tournament } = event
  const { enqueueToast } = useToast()

  const isNotStarted = status.code === NOT_STARTED_CODE
  const isCup = tournament.competitionType === CompetitionType.Cup

  const onSaveAllChanges = async () => {
    try {
      logFirebaseEvent(FirebaseEventName.EditResultPeriod, { id: event.id })

      const updateEventBody = getUpdateEventBody(event)
      if (updateEventBody) {
        await updateEvent(event.id, updateEventBody)

        mutate(eventDetails(event.id))

        enqueueToast(intl.formatMessage({ id: 'alert_changes_saved' }), { variant: 'success' })
      }
    } catch (err) {
      handleError(err)
    }
  }

  return (
    <Box ref={containerRef}>
      {/* Status & AGGREGATE Winner */}
      <GeneralEditMatchStatus availableStatusCodes={volleyballStatusCodes} />

      {/* HEADER */}
      {!isNotStarted && (
        <>
          <GeneralEditMatchScoreHeader />

          {/* SCORE BY SETS */}
          {volleyballPeriods.map(period => (
            <SetPeriodScoreFRO
              key={period}
              periodKey={period}
              periodName={<FormattedMessage id={periodNamesSportMap[period][SportName.Volleyball]} />}
            />
          ))}

          {/* AGGREGATE WINNER */}
          {isMobile && isCup && <SetAggregateWinner />}
        </>
      )}

      {hasChanges && (
        <>
          <Box h={100} />
          <SaveAllChanges onSubmit={onSaveAllChanges} parentRef={containerRef} />
        </>
      )}
    </Box>
  )
}
