import 'perfect-scrollbar/css/perfect-scrollbar.css'
import { useEffect } from 'react'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { SWRConfig } from 'swr'
import { ToastProvider, ToastContainer } from 'shared/lib'
import { initializeFirebaseAnalytics } from 'shared/lib/firebase/utils'
import ThemeProvider from 'UI/ThemeProvider'
import { LazyMotion, domMax } from 'framer-motion'
import { HelmetProvider } from 'react-helmet-async'

import store, { persistor } from 'store/store'
import { getJson } from 'api/utilities'
import { GlobalStyle } from 'styles/globalStyles'
import { ConfirmContextProvider } from 'modules/ConfirmDialog/ConfirmContextProvider'
import ConfirmDialog from 'modules/ConfirmDialog/ConfirmDialog'

import { router } from './AppRouter'
import ConnectedIntlProvider from './providers/ConnectedIntlProvider'

export const App = () => {
  useEffect(() => {
    initializeFirebaseAnalytics()
  }, [])

  return (
    <Provider store={store}>
      <SWRConfig
        value={{
          fetcher: args => getJson(args),
        }}
      >
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedIntlProvider>
            <HelmetProvider>
              <LazyMotion features={domMax} strict>
                <ToastProvider>
                  <ConfirmContextProvider>
                    <ThemeProvider>
                      <GlobalStyle />
                      <RouterProvider router={router} />
                      <ToastContainer />
                      <ConfirmDialog />
                    </ThemeProvider>
                  </ConfirmContextProvider>
                </ToastProvider>
              </LazyMotion>
            </HelmetProvider>
          </ConnectedIntlProvider>
        </PersistGate>
      </SWRConfig>
    </Provider>
  )
}
