import { Image } from '@sofascore/ui'
import styled from 'styled-components'

export const TeamImage = styled(Image)<{ tuckLeft?: boolean; tuckRight?: boolean; rounded?: boolean }>`
  position: relative;
  width: 38px;
  height: 38px;
  margin-top: ${p => p.theme.spacing.sm};
  z-index: 1;
  ${p =>
    p.rounded && {
      borderRadius: '100%',
    }};
  ${p =>
    p.tuckLeft && {
      left: '-10px',
    }};
  ${p =>
    p.tuckRight && {
      left: '10px',
    }};
  &:last-of-type {
    z-index: 0;
  }
`
