import { useState } from 'react'
import { Box, ZIndex } from '@sofascore/ui'
import useSWR from 'swr'
import { FormattedMessage, useIntl } from 'react-intl'
import { Helmet } from 'react-helmet-async'
import { tabTitleWrapper } from 'shared/utils'

import EmptyCompetitions from 'modules/MyCompetitions/components/EmptyCompetitions'
import { Loading } from 'components/Loading'
import { uniqueTournaments } from 'api/routes'
import { PAGE_HEADER_MOBILE_HEIGHT } from 'components/Layout/PageHeader/config'
import { CompetitionInfoProvider } from 'modules/Competition/competitionContext'
import { PageHeader } from 'components/Layout/PageHeader'
import { PageContent } from 'components/Layout/PageContent'
import { CalendarSlider } from 'modules/Home/components/CalendarSlider'
import { HomeWhatsNew } from 'modules/Home/components/HomeWhatsNew'
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'

import EventsForDate from './components/EventListContainer'

const Home = () => {
  const intl = useIntl()
  const { data: usersCompetitions, error: userCompetitionsError } = useSWR(uniqueTournaments())

  const hasAssignedCompetitions = !!usersCompetitions?.uniqueTournaments?.length

  const [selectedDate, setSelectedDate] = useState<Date>(new Date())

  return (
    <CompetitionInfoProvider>
      <Helmet>
        <title>{tabTitleWrapper(intl.formatMessage({ id: 'matches' }))}</title>
      </Helmet>
      <PageHeader pageName={<FormattedMessage id="matches" />} />
      <PageContent>
        <Box
          position={['sticky', 'static']}
          w={['100%', 700]}
          zIndex={ZIndex.Header}
          top={PAGE_HEADER_MOBILE_HEIGHT}
          pt={[0, 'lg']}
          m="auto"
          elevation={[2, undefined]}
        >
          <CalendarSlider selectedDate={selectedDate} onChange={(date: Date) => setSelectedDate(date)} />
        </Box>

        <Box maxW={700} mx="auto" px={['sm', 0]} pb="sm">
          <HomeWhatsNew />

          {userCompetitionsError ? (
            <ErrorMessage />
          ) : !usersCompetitions ? (
            <Loading />
          ) : !hasAssignedCompetitions ? (
            <Box mt="xxl">
              <EmptyCompetitions />
            </Box>
          ) : (
            <EventsForDate selectedDate={selectedDate} />
          )}
        </Box>
      </PageContent>
    </CompetitionInfoProvider>
  )
}

export default Home
