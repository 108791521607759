import { FormattedMessage, IntlShape } from 'react-intl'
import { ValuesType, getId } from 'shared/utils'

import { getStatusDescriptionValues } from '../utils'
import statusDescriptions from '../mappings/statusDescription.json'

// NOTE: this formats classic status description such as "Finished", "Canceled" as well as period description such as
// "1st Quarter". The reason is that period description is exactly status description when the match is in progress and
// we want to have a single source of translation mapping for it.
export const FormattedStatusDescription: React.FC<{
  id: string
}> = ({ id }) => {
  return (
    <FormattedMessage
      id={getId(statusDescriptions, id) as string}
      values={getStatusDescriptionValues(id) as ValuesType}
      defaultMessage={id}
    />
  )
}

export const formatStatusDescription = (id: string, intl: IntlShape) => {
  return intl.formatMessage(
    {
      id: getId(statusDescriptions, id),
      defaultMessage: id,
    },
    {
      ...getStatusDescriptionValues(id),
    },
  )
}
