import { Text } from '@sofascore/ui'
import { DynamicLineupsPlayer } from 'features/AddLineups'
import { Option } from 'shared/model'

export const renderRugbyOption = (option: Option<DynamicLineupsPlayer>) => (
  <Text as="div" font="body.large" px="lg" py="sm" ellipsis>
    {option.value.position ? `${option.value.position} - ${option.label}` : option.label}
  </Text>
)

export const renderRugbySelectedOption = (option?: Option<DynamicLineupsPlayer>) => (
  <Text as="div" font="body.large" ellipsis>
    {option?.value.position ? `${option?.value.position} - ${option?.label}` : option?.label}
  </Text>
)

export const sortRugbyPlayerOptions = (a: Option<DynamicLineupsPlayer>, b: Option<DynamicLineupsPlayer>) => {
  if (a.value.position && b.value.position) {
    return +a.value.position - +b.value.position
  }

  if (a.value.position && !b.value.position) {
    return -1
  }

  if (!a.value.position && b.value.position) {
    return 1
  }

  return a.value.player.name.localeCompare(b.value.player.name)
}
