import { Flex, Image, Text } from '@sofascore/ui'
import IconCrowdsourcing16 from '@sofascore/ui/dist/modules/Icons/IconCrowdsourcing16'
import { BasicEvent, WinnerCode } from 'entities/Event'
import { StatusType } from 'entities/Status'
import IconInfo from '@sofascore/ui/dist/modules/Icons/IconInfo'
import { FormattedMessage } from 'react-intl'
import { PermissionAction } from 'entities/Permission'
import { PermissionGuard } from 'shared/ui'

import { teamImage } from 'api/routes'
import { Tooltip } from 'components/Tooltip'

import PeriodScores from './PeriodScores'
import AggregateScore from './AggregateScore'

const ScoreSection = ({ event }: { event: BasicEvent }) => {
  const { status, homeTeam, awayTeam, winnerCode, crowdsourcingDataDisplayEnabled } = event

  const isLive = status.type === StatusType.IN_PROGRESS
  const isFinished = status.type === StatusType.FINISHED

  const isCrowdsourcingLive = crowdsourcingDataDisplayEnabled && isLive
  const isCrowdsourcingFinished = crowdsourcingDataDisplayEnabled && isFinished

  const isHomeTeamFaded = winnerCode === WinnerCode.Away || winnerCode === WinnerCode.Draw || isCrowdsourcingFinished
  const isAwayTeamFaded = winnerCode === WinnerCode.Home || winnerCode === WinnerCode.Draw || isCrowdsourcingFinished

  const isLocked = event.feedLocked

  return (
    <Flex grow={1} justify="space-between" px="sm" borderRight="solid 1px rgba(34, 34, 38, 0.15)" minW={0}>
      {/* Team Section */}
      <Flex
        direction="column"
        shrink={1}
        justify="space-between"
        overflow="hidden"
        style={{ opacity: isLocked ? '.5' : 1 }}
      >
        <Flex>
          <Image
            src={teamImage(homeTeam.id)}
            w={16}
            h={16}
            alt={homeTeam.name}
            fallback={
              <img src="/images/placeholders/teamLogo.png" width="16px" height="16px" alt="homeTeamPlaceholderImage" />
            }
          />
          <Text pl="sm" color={isHomeTeamFaded ? 'onSurface.nLv3' : 'onSurface.nLv1'} ellipsis>
            {homeTeam.name}
          </Text>
        </Flex>

        <Flex>
          <Image
            src={teamImage(awayTeam.id)}
            w={16}
            h={16}
            alt={awayTeam.name}
            fallback={
              <img src="/images/placeholders/teamLogo.png" width="16px" height="16px" alt="awayTeamPlaceholderImage" />
            }
          />
          <Text pl="sm" color={isAwayTeamFaded ? 'onSurface.nLv3' : 'onSurface.nLv1'} ellipsis>
            {awayTeam.name}
          </Text>
        </Flex>
      </Flex>

      {/* Score Section */}
      <Flex justify="space-between" pl="sm">
        <PermissionGuard entityAllowedActions={event.allowedActions} checkAllowedActions={[PermissionAction.Update]}>
          {(isCrowdsourcingLive || isCrowdsourcingFinished) && (
            <Flex align="center" mr="md">
              <IconCrowdsourcing16 w={20} h={20} fill={isCrowdsourcingLive ? 'primary.default' : 'onSurface.nLv3'} />
            </Flex>
          )}
        </PermissionGuard>

        {isLocked && (
          <Tooltip
            element={
              <Flex align="center">
                <IconInfo />
              </Flex>
            }
            label={
              <Text color="onSurface.nLv1" lineHeight={1.4}>
                <FormattedMessage id="locked_match_editing_info" />
              </Text>
            }
            w={244}
            placement="bottom"
            alignment="left"
            clickable
            br="sm"
            p="md"
          />
        )}

        {/* Score by periods */}
        <PeriodScores event={event} />

        {/* Aggregate */}
        <AggregateScore event={event} />
      </Flex>
    </Flex>
  )
}

export default ScoreSection
