import useSWR, { mutate as globalSWRMutate } from 'swr'
import { useMemo } from 'react'
import { ApiDomain } from 'shared/api/model'
import { useDispatch, useSelector } from 'react-redux'
import { useToast } from 'shared/lib'

import { setApiBaseBranch } from 'store/uiControls/reducer'
import { getGitBranches } from 'api'
import { getGitBranches as getGitBranchesRoute } from 'api/routes'
import { selectAPIBaseBranch } from 'store/selectors'
import { DEFAULT_BASE_BRANCH } from 'api/constants'

export interface BranchesResponse {
  branches?: string[]
}

export default function useGitBranches(apiDomain: ApiDomain) {
  const dispatch = useDispatch()
  const { enqueueToast } = useToast()
  const baseBranch = useSelector(selectAPIBaseBranch)

  const {
    data: branchesData,
    error,
    mutate,
  } = useSWR<BranchesResponse>(getGitBranchesRoute(), {
    fetcher: getGitBranches,
    onError: error => {
      //  if the error is empty it means that you don’t have god mode
      if (!error.error && baseBranch !== DEFAULT_BASE_BRANCH) {
        // fallback to base master dev branch if no branch is available
        dispatch(setApiBaseBranch(DEFAULT_BASE_BRANCH))
        // revalidate all active SWR instances
        globalSWRMutate(key => {
          return typeof key === 'string'
        })
        // revalidate branches SWR instance
        mutate()
        enqueueToast(
          `The API Base branch is encountering issues, returning a 500 error or becoming unavailable. As a fallback, we are reverting to "${DEFAULT_BASE_BRANCH}".`,
          {
            variant: 'error',
            duration: 10000,
          },
        )
      }
    },
  })

  const sortedGitBranches = useMemo(() => {
    if (branchesData && branchesData.branches) {
      return branchesData.branches
        .filter(b => b.match(apiDomain === 'dev' ? /\.dev\./ : /\.prod\./))
        .map(b => ({ label: b.slice(0, -1), value: b.slice(0, -1) })) // Remove the last slash
        .sort((a, b) => a.label.localeCompare(b.label))
    }
    return []
  }, [branchesData, apiDomain])

  return {
    data: sortedGitBranches,
    error,
    mutate,
  }
}
