import { Box, BoxProps, Divider, Text } from '@sofascore/ui'
import noop from 'lodash/noop'
import { useIntl } from 'react-intl'

import * as S from './styles'

export const PeriodBox = ({
  periodName,
  periodStartTimestamp,
  isActive,
  isDisabled,
  onClick,
  ...boxProps
}: {
  periodName: string
  periodStartTimestamp?: number | null | undefined
  isActive: boolean
  isDisabled: boolean
  onClick?: () => void
} & BoxProps) => {
  const intl = useIntl()

  return (
    <S.PeriodBox
      $isActive={isActive}
      $isDisabled={isDisabled}
      onClick={isActive || isDisabled ? noop : onClick}
      {...boxProps}
    >
      <S.PeriodBoxContent direction={periodStartTimestamp !== null ? 'column' : 'row'}>
        <Text font="body.small" color="inherit" align="center">
          {periodName}
        </Text>

        {periodStartTimestamp !== null && (
          <Box minW="30px" textAlign="center">
            <Box my="sm" w="100%">
              <Divider />
            </Box>
            <Text font="body.small" color="inherit">
              {periodStartTimestamp ? intl.formatTime(periodStartTimestamp * 1000, { hour12: false }) : '-'}
            </Text>
          </Box>
        )}
      </S.PeriodBoxContent>
    </S.PeriodBox>
  )
}
