import {
  AFTER_EXTRA_TIME_CODE,
  AFTER_PENALTIES_CODE,
  AWAITING_EXTRA_TIME_CODE,
  EXTRA_TIME_HALF_CODE,
  NOT_STARTED_CODE,
  StatusCode,
  StatusType,
  getStatusDescription,
  getStatusDescriptionId,
} from 'entities/Status'
import { isTimeLeftSport } from 'entities/Sport'
import { ScoreWithPeriods, UpdateScorePayload } from 'entities/Score'
import { IntlShape } from 'react-intl'

import { BasicEvent, Time, UpdateEventBody, WinnerCode } from '../model'

export const isFRO = (event: BasicEvent) => event.status.code === StatusCode['Not started'] && !!event.finalResultOnly

const determinePeriodTime = (time: Time, sport: string) => {
  const { played, periodLength, overtimeLength, totalPeriodCount } = time
  if (!played || !periodLength || !overtimeLength || !totalPeriodCount) return undefined

  let periodSeconds: number
  let secondsRemaining: number
  if (played > totalPeriodCount * periodLength) {
    periodSeconds = (played - totalPeriodCount * periodLength) % overtimeLength
    secondsRemaining = overtimeLength - periodSeconds
  } else {
    periodSeconds = played % periodLength
    secondsRemaining = periodLength - periodSeconds
  }
  if (periodSeconds === 0) {
    return undefined
  }
  if (isTimeLeftSport(sport)) {
    return `${Math.floor(secondsRemaining / 60)}:${String(secondsRemaining % 60).padStart(2, '0')}`
  }

  return `${Math.ceil(periodSeconds / 60)}'`
}

export const getCurrentPeriodTime = (event: BasicEvent) => {
  if (event.time === undefined || event.status === undefined || event.status.type !== StatusType.IN_PROGRESS) {
    return undefined
  }
  const shortStatusDescriptionId = getStatusDescriptionId(getStatusDescription(event), true)
  const validPeriods = [
    'ordinalPeriod.short',
    'ordinalHalf.short',
    'ordinalQuarter.short',
    'overtime.short',
    'etN',
    'qN',
  ]
  if (validPeriods.indexOf(shortStatusDescriptionId) === -1) {
    return undefined
  }
  return determinePeriodTime(event.time, event.tournament.category.sport.slug)
}

export const hasExtraTimeData = (event: BasicEvent) => {
  return Boolean(
    event.time?.extra1StartTimestamp || event.time?.extra2StartTimestamp || event.time?.penaltiesStartTimestamp,
  )
}

export const hasGoneToExtraTime = (event: BasicEvent) => {
  return Boolean(
    hasExtraTimeData(event) ||
      event.status.code === AWAITING_EXTRA_TIME_CODE ||
      event.status.code === EXTRA_TIME_HALF_CODE ||
      event.status.code === AFTER_EXTRA_TIME_CODE ||
      event.status.code === AFTER_PENALTIES_CODE,
  )
}

// Method which returns request body when we are sending request for updating event. There are additional checks
// for score sections which are removed and for setting period2 value.
export const getUpdateEventBody = (event: BasicEvent) => {
  const { homeScore, awayScore, currentPeriodStartTimestamp, roundInfo, status, aggregatedWinnerCode, startTimestamp } =
    event

  if (homeScore && awayScore && status) {
    const scoreKeys = Object.keys(homeScore) as (keyof ScoreWithPeriods)[]

    const homeScoreToSend: UpdateScorePayload = {}
    for (let i = 0; i < scoreKeys.length; i++) {
      const score = homeScore[scoreKeys[i]]

      if (typeof score === 'number' && score >= 0 && status.code !== NOT_STARTED_CODE) {
        homeScoreToSend[scoreKeys[i]] = score
      } else {
        homeScoreToSend[scoreKeys[i]] = null
      }
    }

    const awayScoreToSend: UpdateScorePayload = {}
    for (let i = 0; i < scoreKeys.length; i++) {
      const score = awayScore[scoreKeys[i]]
      if (typeof score === 'number' && score >= 0 && status.code !== NOT_STARTED_CODE) {
        awayScoreToSend[scoreKeys[i]] = score
      } else {
        awayScoreToSend[scoreKeys[i]] = null
      }
    }

    if ('display' in homeScoreToSend || 'display' in awayScoreToSend) {
      delete homeScoreToSend.display
      delete awayScoreToSend.display
    }

    const updatedEventBody: UpdateEventBody = {
      status: { code: status.code },
      homeScore: { ...homeScoreToSend },
      awayScore: { ...awayScoreToSend },
      aggregatedWinnerCode: aggregatedWinnerCode ? aggregatedWinnerCode : 0,
      currentPeriodStartTimestamp: typeof currentPeriodStartTimestamp === 'number' ? currentPeriodStartTimestamp : null,
      startDateTimestamp: startTimestamp,
    }

    if (roundInfo) {
      updatedEventBody.roundInfo = roundInfo
    }

    return updatedEventBody
  } else return null
}

// Get available options for aggregate winner
export const getAggregateWinnerOptions = (homeTeamName: string, awayTeamName: string, intl: IntlShape) => {
  return [
    { value: WinnerCode.Unknown, label: intl.formatMessage({ id: 'no_winner' }) },
    { value: WinnerCode.Home, label: homeTeamName },
    { value: WinnerCode.Away, label: awayTeamName },
    { value: WinnerCode.Draw, label: intl.formatMessage({ id: 'draw' }) },
  ]
}

// Determine aggregate winner from event and return as an option
export const getAggregateWinnerOption = (event: BasicEvent, intl: IntlShape) => {
  switch (event.aggregatedWinnerCode) {
    case WinnerCode.Home:
      return { value: WinnerCode.Home, label: event.homeTeam.name }
    case WinnerCode.Away:
      return { value: WinnerCode.Away, label: event.awayTeam.name }
    case WinnerCode.Draw:
      return { value: WinnerCode.Draw, label: intl.formatMessage({ id: 'draw' }) }
    default:
    case WinnerCode.Unknown:
      return { value: WinnerCode.Unknown, label: intl.formatMessage({ id: 'no_winner' }) }
  }
}
