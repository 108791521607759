import styled from 'styled-components'

export const PickerColumn = styled.div<{ hasUnit?: boolean }>`
  ${p => !p.hasUnit && 'flex: 1 1;'}

  position: relative;

  height: 100%;

  overflow: hidden;
  text-align: center;
`

export const PickerScroller = styled.div`
  transition: 300ms ease-out;
`

export const PickerItem = styled.div<{ isSelected: boolean; hasUnit?: boolean }>`
  position: relative;

  ${p => p.hasUnit && 'margin: 0 7px 0 10px;'}

  white-space: nowrap;
  color: ${p => (p.isSelected ? '#222' : '#999999')};
  transform: ${p => (p.isSelected ? 'scale(1.3)' : 'scale(1)')};
  transition: transform 200ms;
  overflow: hidden;
  text-overflow: ellipsis;

  cursor: pointer;
`
