import { SportName } from '../model'

// This order is used in the competitions list
export const orderedSports = [
  SportName.Football,
  SportName.MiniFootball,
  SportName.Futsal,
  SportName.Basketball,
  SportName.Tennis,
  SportName.Volleyball,
  SportName.Waterpolo,
  SportName.Rugby,
]

export const redesignedSports = [
  SportName.Football,
  SportName.MiniFootball,
  SportName.Futsal,
  SportName.Rugby,
  SportName.ESports,
  SportName.Basketball,
]

export const sportsWithGeneralEdit = [SportName.Volleyball, SportName.Waterpolo]

export const sportsWithTwoHalves = [SportName.Football, SportName.ESports, SportName.Futsal, SportName.MiniFootball]

export const sportsWithPeriodTime = [SportName.Football, SportName.Rugby]

export const sportsWithLineups = [SportName.Football, SportName.MiniFootball, SportName.Futsal, SportName.Rugby]

export const noPeriodsSports = [SportName.Football, SportName.Futsal, SportName.MiniFootball, SportName.Rugby]

export const REGULAR_MATCH_DURATION = {
  [SportName.Football]: 90,
  [SportName.Futsal]: 40,
}
export const REGULAR_PERIOD_DURATION = {
  [SportName.Football]: 45,
  [SportName.Futsal]: 20,
}

export const EXTRA_TIME_DURATION = {
  [SportName.Football]: 15,
  [SportName.Futsal]: 5,
}
