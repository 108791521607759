import { Box, Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

import { IconIndicatorPlay } from 'components/Icons/IconIndicatorPlay'

import { InfoNoteDialogTitle, InfoNoteText, InfoNoteTitle } from './styles'

export default function CrowdsourcingInfoNote({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  return (
    <Dialog onClose={onClose} open={isOpen}>
      <Box maxWidth="328px">
        <InfoNoteDialogTitle>
          <IconIndicatorPlay width={64} height={64} style={{ verticalAlign: 'middle' }} />
        </InfoNoteDialogTitle>
        <DialogContent style={{ textAlign: 'center', padding: '0 24px' }}>
          <InfoNoteTitle variant="subtitle2">
            <FormattedMessage id="editor_info_note_title" />
          </InfoNoteTitle>
          <InfoNoteText variant="body2">
            <FormattedMessage id="editor_info_note_text" />
          </InfoNoteText>
        </DialogContent>
        <DialogActions style={{ padding: 24, paddingTop: 0 }}>
          <Button variant="contained" onClick={onClose} fullWidth>
            <FormattedMessage id="gotIt" />
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
