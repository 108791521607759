import { Flex, Box, Text, RadioButton } from '@sofascore/ui'
import { useState } from 'react'
import IconInfo from '@sofascore/ui/dist/modules/Icons/IconInfo'
import { FormattedMessage, useIntl } from 'react-intl'
import { AWAITING_EXTRA_TIME_CODE, ENDED_CODE, INTERRUPTED_CODE } from 'entities/Status'
import { useEventState } from 'entities/Event'
import { CompetitionType } from 'entities/Tournament'

import { Button } from 'components/Button'
import { Tooltip } from 'components/Tooltip'
import { textPrimary } from 'styles/color'

import { AggregateWinnerPicker } from './AggregateWinnerPicker'
import { ConfirmFormProps } from '../model'

export const ConfirmFullTimeFormFRO = ({ onClose, onSubmit }: ConfirmFormProps) => {
  const [isSaving, setIsSaving] = useState(false)
  const intl = useIntl()
  const { event } = useEventState()
  const { homeTeam, awayTeam, tournament } = event

  const [selectedStatusCode, setSelectedStatusCode] = useState<number>(ENDED_CODE)
  const [aggregatedWinnerCode, setAggregatedWinnerCode] = useState<number>(0)

  const handleEndMatch = () => {
    setIsSaving(true)

    onSubmit(
      {
        status: {
          code: selectedStatusCode,
        },
        homeScore: event.homeScore,
        awayScore: event.awayScore,
        currentPeriodStartTimestamp: event.currentPeriodStartTimestamp!,
        roundInfo: event.roundInfo,
        aggregatedWinnerCode: selectedStatusCode === ENDED_CODE ? aggregatedWinnerCode : event.aggregatedWinnerCode!,
      },
      event.id,
    )
      .then(() => onClose())
      .finally(() => setIsSaving(false))
  }

  const isCup = tournament.competitionType === CompetitionType.Cup

  return (
    <>
      <Box minH={388} mb={60}>
        <Box backgroundColor="surface.s2" mt="lg" mx="md" br="sm" p="lg" mb={isCup ? 'lg' : 160}>
          <Text font="display.small" align="center" as="div">
            <FormattedMessage id="select_match_status" />
          </Text>

          <Flex direction="column" align="flex-start" mt="lg" gapY="md">
            <RadioButton
              key={ENDED_CODE}
              id="ended"
              name="matchStatusCode"
              label={intl.formatMessage({ id: 'Ended' })}
              value={ENDED_CODE.toString()}
              checked={selectedStatusCode === ENDED_CODE}
              onChange={e => setSelectedStatusCode(+e.target.value)}
              gap="md"
            />

            <RadioButton
              key={AWAITING_EXTRA_TIME_CODE}
              id="overtime"
              name="matchStatusCode"
              label={intl.formatMessage({ id: 'proceed_to_overtime' })}
              value={AWAITING_EXTRA_TIME_CODE.toString()}
              checked={selectedStatusCode === AWAITING_EXTRA_TIME_CODE}
              onChange={e => setSelectedStatusCode(+e.target.value)}
              gap="md"
            />

            <Flex justify="space-between" w="100%" align="flex-end">
              <RadioButton
                id="interrupted"
                name="matchStatusCode"
                label={intl.formatMessage({ id: 'Interrupted' })}
                value={INTERRUPTED_CODE.toString()}
                checked={selectedStatusCode === INTERRUPTED_CODE}
                onChange={e => setSelectedStatusCode(+e.target.value)}
                gap="md"
              />
              <Tooltip
                placement="bottom"
                alignment="left"
                w={200}
                p="md"
                label={
                  <Text>
                    <FormattedMessage id="match_interrupted_info_text" />
                  </Text>
                }
                element={<IconInfo fill={textPrimary} fillOpacity={0.4} pr={6} cursor="pointer" />}
              />
            </Flex>
          </Flex>
        </Box>

        {isCup && selectedStatusCode === ENDED_CODE && (
          <Box backgroundColor="surface.s2" mx="md" br="sm" pt="lg" pb="xs" mb={60}>
            <Text font="display.small" align="center" as="div" mb="lg">
              <FormattedMessage id="select_aggregate_winner" />
            </Text>

            <AggregateWinnerPicker
              homeTeam={homeTeam}
              awayTeam={awayTeam}
              winnerCode={aggregatedWinnerCode}
              onChangeWinnerCode={newCode => setAggregatedWinnerCode(newCode as number)}
            />
          </Box>
        )}
      </Box>

      <Flex gapX="lg" px="lg" mb="xl" mx="lg">
        <Button variant="outlined" fullWidth onClick={onClose}>
          <FormattedMessage id="cancel" />
        </Button>
        <Button variant="contained" fullWidth onClick={handleEndMatch} isLoading={isSaving}>
          <FormattedMessage id="confirm" />
        </Button>
      </Flex>
    </>
  )
}
