import { LineupType } from '../model'

export const DND_TYPE = 'DL-PLAYER'

export const MIN_PLAYERS = {
  [LineupType.PITCH]: {
    TO_CREATE: 11,
    TO_SAVE: 11,
  },
  [LineupType.PLAYER_LIST]: {
    TO_CREATE: 7,
    TO_SAVE: 7,
  },
}

export const lineupFormations = [
  { label: '4-2-3-1', value: '4-2-3-1' },
  { label: '4-4-2', value: '4-4-2' },
  { label: '4-3-3', value: '4-3-3' },
  { label: '4-1-4-1', value: '4-1-4-1' },
  { label: '3-5-2', value: '3-5-2' },
  { label: '3-4-3', value: '3-4-3' },
  { label: '4-3-1-2', value: '4-3-1-2' },
  { label: '3-4-1-2', value: '3-4-1-2' },
  { label: '3-4-2-1', value: '3-4-2-1' },
  { label: '4-4-1-1', value: '4-4-1-1' },
  { label: '4-5-1', value: '4-5-1' },
  { label: '5-3-2', value: '5-3-2' },
  { label: '5-4-1', value: '5-4-1' },
  { label: '4-3-2-1', value: '4-3-2-1' },
  { label: '4-1-3-2', value: '4-1-3-2' },
  { label: '3-1-4-2', value: '3-1-4-2' },
  { label: '4-1-2-3', value: '4-1-2-3' },
  { label: '4-2-2-2', value: '4-2-2-2' },
  { label: '3-5-1-1', value: '3-5-1-1' },
  { label: '5-2-3', value: '5-2-3' },
  { label: '3-3-3-1', value: '3-3-3-1' },
  { label: '4-2-1-3', value: '4-2-1-3' },
  { label: '3-2-4-1', value: '3-2-4-1' },
  { label: '4-2-4', value: '4-2-4' },
  { label: '3-3-1-3', value: '3-3-1-3' },
  { label: '2-3-4-1', value: '2-3-4-1' },
]

export const lineupPlayerKitColors = [
  { label: 'Blue', value: '#384CE0' },
  { label: 'Green', value: '#65C742' },
  { label: 'Yellow', value: '#E7CA2D' },
  { label: 'Red', value: '#D63838' },
  { label: 'Aqua', value: '#3CD0CF' },
  { label: 'Pink', value: '#DC5480' },
  { label: 'Orange', value: '#DB8534' },
  { label: 'Grey', value: '#A4A9B3' },
  { label: 'Black', value: '#222226' },
  { label: 'White', value: '#FFFFFF' },
]

export const lineupGoalkeeperKitColors = [
  { label: 'Green', value: '#65C742' },
  { label: 'Yellow', value: '#E7CA2D' },
]
