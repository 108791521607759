import { MissingPlayerReason } from 'entities/Player'

export const mapMissingReasonToTranslationKey = {
  [MissingPlayerReason.Other]: 'other',
  [MissingPlayerReason.Injured]: 'editor_player_injured',
  [MissingPlayerReason.Suspended]: 'Suspended',
  [MissingPlayerReason.YellowCard]: 'yellowCard',
  [MissingPlayerReason.YellowRedCard]: 'yellowRedCard',
  [MissingPlayerReason.RedCard]: 'redCard',
  [MissingPlayerReason.OnLoan]: 'editor_player_on_loan',
}

export const mapRugbyPositionToGroupKey = {
  F: 'position_group_forwards',
  B: 'position_group_backs',
}

export const mapFootballPositionToGroupKey = {
  G: 'position_group_goalkeepers',
  D: 'position_group_defenders',
  M: 'position_group_midfielders',
  F: 'position_group_forwards',
}

export const mapMiniFootballPositionToGroupKey = {
  G: 'position_group_goalkeepers',
  F: 'position_group_forwards',
}
