import { Flex, Text } from '@sofascore/ui'
import { UniqueTournamentImage } from 'entities/UniqueTournament'
import { BasicCategory, FormattedCategoryName } from 'entities/Category'
import { NavLink } from 'react-router-dom'
import IconSettings from '@sofascore/ui/dist/modules/Icons/IconSettings'
import { BasicTournament } from 'entities/Tournament'
import { PermissionGuard } from 'shared/ui'
import { PermissionAction, PermissionScope } from 'entities/Permission'

import { ADMIN_SETTINGS_SUPPORTED_SPORTS } from 'modules/MyCompetitions/config'

import * as S from './../styles'
interface CompetitionsItemProps {
  id: number
  name: string
  category: BasicCategory
  routeBase: string
  uniqueTournament: BasicTournament
}

const CompetitionItem = ({ id, name, category, routeBase, uniqueTournament }: CompetitionsItemProps) => {
  const sport = uniqueTournament.category.sport.slug

  return (
    <Flex h={64} px="lg" align="center" backgroundColor="inherit">
      <UniqueTournamentImage id={id} name={name} />
      <Flex direction="column" ml="lg" gapY="xxs" minW={0}>
        <Text font="body.large" ellipsis>
          {name}
        </Text>
        <Text font="body.small" color="onSurface.nLv3">
          <FormattedCategoryName slug={category.slug} name={category.name} />
        </Text>
      </Flex>
      {ADMIN_SETTINGS_SUPPORTED_SPORTS.includes(sport) && (
        <PermissionGuard
          entityAllowedActions={uniqueTournament.allowedActions}
          checkAllowedActions={[PermissionAction.Update]}
          entityAllowedActionsScope={PermissionScope.Settings}
        >
          <S.CompetitionListSettingsIcon as={NavLink} to={`${routeBase}/settings`}>
            <IconSettings />
          </S.CompetitionListSettingsIcon>
        </PermissionGuard>
      )}
    </Flex>
  )
}

export default CompetitionItem
