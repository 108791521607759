import * as React from 'react'

import { IconBase, IconProps } from './Base'

export const IconDelete = (props: IconProps) => (
  <IconBase width={24} height={24} viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={props.fill ? props.fill : '#FFF'}
        fillRule="nonzero"
        d="M17.999 7h-12v12A2 2 0 0 0 8 21h7.999a2 2 0 0 0 2-2V7m-3.5-4.001h-5L8.5 4H5.999A1 1 0 0 0 5 4.999V6h14V4.999A1 1 0 0 0 17.999 4H15.5l-1.001-1.001"
      />
    </g>
  </IconBase>
)
