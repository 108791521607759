import { LineupsAdditionalData } from 'entities/Lineups'
import { MissingPlayerReason, MissingPlayerType, Player } from 'entities/Player'
import { BasicTeam } from 'entities/Team'

export type DynamicLineupsHomeAwayColor = 'home' | 'away' | 'event'

export enum LineupType {
  PLAYER_LIST = 'PLAYER_LIST',
  PITCH = 'PITCH',
}

export interface DynamicLineupsPlayer {
  player: Player
  playerTeam: number
  position: number | string | null
  substitute: boolean
  captain: boolean
  jerseyNumber: string | null
  missingReason?: MissingPlayerReason | null
}

export interface LineupsInitialState extends LineupsAdditionalData {
  homeLineupPlayers: DynamicLineupsPlayer[]
  awayLineupPlayers: DynamicLineupsPlayer[]
}

export interface DynamicLineupsEvent {
  id: number
  homeFormation: string | null
  awayFormation: string | null
  confirmedLineups: boolean
  noMissingPlayersConfirmed: boolean
}

export interface DynamicLineupsMissingPlayer {
  player: Player
  team: BasicTeam
  reason: MissingPlayerReason
  type: MissingPlayerType
}

export interface DynamicLineupsTeamColors {
  homeTeamColorMain: string | null
  awayTeamColorMain: string | null
  homeTeamGKColorMain: string | null
  awayTeamGKColorMain: string | null
}

export interface DynamicLineupsPayload {
  event: DynamicLineupsEvent
  eventPlayers: DynamicLineupsPlayer[]
  missingPlayers: DynamicLineupsMissingPlayer[]
  homeTeamColor: DynamicLineupsHomeAwayColor
  awayTeamColor: DynamicLineupsHomeAwayColor
  teamColors: DynamicLineupsTeamColors | null
}

export interface PitchLineupsProps {
  lineupPlayers: DynamicLineupsPlayer[]
  otherTeamKit: string
  isHomeTeam: boolean
  activeTeamId: number
  onChangeTeam: (teamId: number) => void
  homeTeam: BasicTeam
  awayTeam: BasicTeam
}
