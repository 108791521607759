import React from 'react'
import { Text } from '@sofascore/ui'
import { SportName } from 'entities/Sport'

import { Option } from 'components/DropdownMenu/interface'
import { useMyCompetitionsState } from 'modules/MyCompetitions/providers/MyCompetitionsProvider'

import { useCreateMatchState } from '../context/createMatchContext'

interface CreateMatchTeamLabelProps {
  team?: Option
  subTeam1?: Option
  subTeam2?: Option
  side?: 'right' | 'left'
}

const CreateMatchTeamLabel: React.FC<CreateMatchTeamLabelProps> = ({ team, subTeam1, subTeam2, side }) => {
  const { matchType } = useCreateMatchState()
  const { uniqueTournament } = useMyCompetitionsState()

  const sport = uniqueTournament?.category.sport.slug
  const isTennis = sport === SportName.Tennis
  const areTennisDouble = matchType === 'tennisDouble'
  const emptySpotPlaceholder = isTennis ? 'Player' : 'team'

  let placeholder = emptySpotPlaceholder
  if (!isTennis) {
    placeholder = `${side === 'left' ? 'Home' : 'Away'} ${emptySpotPlaceholder}`
  }

  let teamLabels = <>{team?.label || placeholder}</>
  if (isTennis && areTennisDouble) {
    teamLabels = (
      <Text fontSize="small" whiteSpace="nowrap" align={side} fontWeight="bold">
        {subTeam1?.label || `${placeholder} 1`}
        <br />
        {subTeam2?.label || `${placeholder} 2`}
      </Text>
    )
  }

  return (
    <Text fontSize="small" whiteSpace="nowrap" align={side} fontWeight="bold">
      {teamLabels}
    </Text>
  )
}

export default CreateMatchTeamLabel
