import { useState } from 'react'
import { LineupsTeamSelector, useLineupsState } from 'features/AddLineups'

import { ListLineupsColumn } from './ListLineupsColumn'

interface MobileListLineupsProps {
  positionGroupMapping: Record<string, string>
}

export const MobileListLineups = ({ positionGroupMapping }: MobileListLineupsProps) => {
  const { homeTeam, homeLineupPlayers, awayTeam, awayLineupPlayers } = useLineupsState()
  const [activeTeamId, setActiveTeamId] = useState(homeTeam.id)

  const isHomeTeam = homeTeam.id === activeTeamId

  return (
    <ListLineupsColumn
      key={activeTeamId}
      lineupPlayers={isHomeTeam ? homeLineupPlayers : awayLineupPlayers}
      positionGroupMapping={positionGroupMapping}
      isHomeTeam={isHomeTeam}
    >
      <LineupsTeamSelector homeTeam={homeTeam} awayTeam={awayTeam} teamId={activeTeamId} setTeamId={setActiveTeamId} />
    </ListLineupsColumn>
  )
}
