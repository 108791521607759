export const facebookInitParams = {
  // appId: 723083761050073,
  appId: 773933969796669,
  version: 'v3.3',
  status: false,
  xfbml: true,
}

export const facebookOptions = { scope: 'email', fields: 'id,name,email,link' }

export const facebookURL = 'https://www.facebook.com'
