import { Dialog, Box, Typography, DialogActions } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

import { DialogConfirmButton, DialogSubtitle, DialogTitleContainer, DialogRejectButton } from './styles'

export default function RemoveSuggestionsDialog({
  open,
  onConfirm,
  onClose,
  isLoading,
}: {
  open: boolean
  onConfirm: () => void
  onClose: () => void
  isLoading: boolean
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <Box py={2} maxWidth={350} textAlign="center">
        <DialogTitleContainer>
          <Typography variant="subtitle2" style={{ marginBottom: 16 }}>
            <FormattedMessage id="editor_delete_suggestions_title" />
          </Typography>
          <DialogSubtitle variant="body2">
            <FormattedMessage id="editor_delete_suggestions_text" />
          </DialogSubtitle>
        </DialogTitleContainer>
        <DialogActions style={{ justifyContent: 'center', paddingLeft: '16px', paddingRight: '16px' }}>
          <DialogConfirmButton variant="outlined" onClick={onConfirm} disabled={isLoading}>
            <FormattedMessage id="editor_yes_delete_button" />
          </DialogConfirmButton>
          <DialogRejectButton data-testid="delete-dialog-button" variant="contained" onClick={onClose}>
            <FormattedMessage id="cancel" />
          </DialogRejectButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
