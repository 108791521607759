import { Box, Flex, Image, Text } from '@sofascore/ui'
import { ReactNode, useCallback, useState } from 'react'
import { BasicEvent, useEventState } from 'entities/Event'
import { GenericResultSection } from 'entities/Score'
import { BasicTeam } from 'entities/Team'
import { StatusType } from 'entities/Status'
import { useFirebaseWithEvent } from 'entities/Event/hooks'
import { FirebaseEventName, FirebaseEventType, FirebaseEventIncidentAction } from 'shared/lib/firebase/model'

import { teamImage } from 'api/routes'
import { IconPlusAdd } from 'components/Icons/IconPlus'

import * as S from './styles'

interface Props {
  children: (isOpen: boolean, closeAddScoreForm: () => void, team: BasicTeam, event: BasicEvent) => ReactNode
}

export const ChangeScoreSection = ({ children }: Props) => {
  const { event } = useEventState()
  const { homeTeam, awayTeam, homeScore, awayScore } = event
  const logFirebaseWithEvent = useFirebaseWithEvent()

  const [isOpen, setIsOpen] = useState(false)
  const [team, setTeam] = useState<BasicTeam>(homeTeam)

  const openAddScoreForm = useCallback(
    (team: BasicTeam) => {
      setIsOpen(true)
      setTeam(team)
      logFirebaseWithEvent(FirebaseEventName.AddEntity, FirebaseEventType.Incident, FirebaseEventIncidentAction.Goal)
    },
    [logFirebaseWithEvent],
  )

  const closeAddScoreForm = (discarded?: boolean | undefined) => {
    setIsOpen(false)
    if (discarded)
      logFirebaseWithEvent(
        FirebaseEventName.DiscardEntity,
        FirebaseEventType.Incident,
        FirebaseEventIncidentAction.Goal,
      )
    else
      logFirebaseWithEvent(FirebaseEventName.SubmitEntity, FirebaseEventType.Incident, FirebaseEventIncidentAction.Goal)
  }

  const isLive = event.status.type === StatusType.IN_PROGRESS

  return (
    <>
      <Box bg="surface.s1" p="lg" br="sm" textAlign="center" elevation={1}>
        <Flex justify="space-between" align="center">
          <S.HomeTeamBox>
            <S.HomeTeamBoxName px={[0, 'lg']} mb="md" justify={['flex-start', 'center']}>
              <Text font="display.micro" align={['left', 'center']} color="inherit">
                {homeTeam.name}
              </Text>
            </S.HomeTeamBoxName>

            <S.HomeTeamBoxAddScore align="center">
              <S.AddScoreButton onClick={() => openAddScoreForm(homeTeam)}>
                <IconPlusAdd fill="#000000" />
              </S.AddScoreButton>
            </S.HomeTeamBoxAddScore>

            <Flex align="flex-end" justify="center">
              <Image src={teamImage(homeTeam.id)} w={[32, 40]} h={[32, 40]} mr={['xl', 0]} alt={homeTeam.name} />
            </Flex>
          </S.HomeTeamBox>

          <GenericResultSection homeScore={homeScore} awayScore={awayScore} isLive={isLive} />

          <S.AwayTeamBox>
            <S.AwayTeamBoxName px={[0, 'lg']} mb="md" justify={['flex-end', 'center']}>
              <Text font="display.micro" color="inherit" align={['right', 'center']}>
                {awayTeam.name}
              </Text>
            </S.AwayTeamBoxName>

            <S.AwayTeamBoxAddScore align={['flex-end', 'center']} justify="flex-end">
              <S.AddScoreButton onClick={() => openAddScoreForm(awayTeam)}>
                <IconPlusAdd fill="#000000" />
              </S.AddScoreButton>
            </S.AwayTeamBoxAddScore>

            <Flex align="flex-end" justify="center">
              <Image src={teamImage(awayTeam.id)} w={[32, 40]} h={[32, 40]} ml={['xl', 0]} alt={awayTeam.name} />
            </Flex>
          </S.AwayTeamBox>
        </Flex>
      </Box>

      {children(isOpen, closeAddScoreForm, team, event)}
    </>
  )
}
