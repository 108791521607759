import { TeamState } from './interface'
import { TeamDispatchAction, TeamDispatchActionType } from './actions'

export const teamReducer = (state: TeamState, action: TeamDispatchAction) => {
  switch (action.type) {
    case TeamDispatchActionType.SET_TEAM: {
      return { ...state, team: action.payload.team }
    }
    default:
      return state
  }
}
