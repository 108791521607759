import { AnimatePresence, motion } from 'framer-motion'
import { repeaterItemAnimationConfig } from 'shared/config'
import IconClose from '@sofascore/ui/dist/modules/Icons/_IconClose'
import { TeamImage } from 'entities/Team'
import { Option } from 'shared/model'

import * as S from '../styles'

export const TeamRepeater = ({
  selected,
  onRemove,
}: {
  selected: Array<Option>
  onRemove: (index: number) => void
}) => {
  return (
    <AnimatePresence initial={false}>
      {selected.map?.((value, index) => (
        <motion.div key={index} {...repeaterItemAnimationConfig}>
          <S.DivisionItem elevation={1}>
            <S.DivisionItemLabel>
              {value.value && (
                <TeamImage
                  team={{
                    id: Number(value.value),
                    name: value.label || '',
                  }}
                  size={16}
                />
              )}
              <S.DivisionItemLabelText>{value.label}</S.DivisionItemLabelText>
            </S.DivisionItemLabel>
            <S.DivisionItemClear type="button" onClick={() => onRemove(index)}>
              <IconClose fill="onSurface.nLv1" width={16} height={16} />
            </S.DivisionItemClear>
          </S.DivisionItem>
        </motion.div>
      ))}
    </AnimatePresence>
  )
}
