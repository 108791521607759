import useSWR from 'swr'
import { LineupsResponse } from 'entities/Lineups'

import { getLineups } from 'api/routes'

export const useEventLineups = (eventId: number | null) => {
  const { data, error, mutate, isLoading } = useSWR<LineupsResponse>(eventId ? getLineups(eventId) : null, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  })

  return {
    data,
    error,
    mutate,
    isLoading,
  }
}
