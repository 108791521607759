import { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Box, Flex, useMediaContext } from '@sofascore/ui'
import { useIntl } from 'react-intl'
import { SportName } from 'entities/Sport'
import { Chip } from 'shared/ui'

import { SERVER_ERROR_PAGE_MESSAGE } from 'utils'
import useEditorEvents from 'modules/Home/hooks/useEditorEvents'
import { groupEventsBySportAndTournament } from 'modules/Home/utils'
import { SportGroup } from 'modules/Home/components/SportGroup'
import EmptyEvents from 'modules/Home/components/EmptyEvents'
import { GroupedEvents } from 'modules/Home/interface'
import { Loading } from 'components/Loading'

const EventsForDate = ({ selectedDate }: { selectedDate: Date }) => {
  const intl = useIntl()

  const { isMobile } = useMediaContext()

  const [selectedSport, setSelectedSport] = useState<SportName>()

  const {
    data: eventsData,
    isLoading,
    error: eventsDataError,
    mutate: revalidateEvents,
  } = useEditorEvents(selectedDate)
  const [groupedEvents, setGroupedEvents] = useState<GroupedEvents>()

  useEffect(() => {
    if (eventsData?.events) {
      const groupedEvents = groupEventsBySportAndTournament(eventsData.events)
      setGroupedEvents(groupedEvents)
      // Clear sport filter
      setSelectedSport(undefined)
    }
  }, [eventsData])

  if (eventsDataError) {
    return (
      <Grid container style={{ marginTop: 200 }}>
        <Grid item xs={12}>
          <Typography variant="h4" style={{ textAlign: 'center' }}>
            {SERVER_ERROR_PAGE_MESSAGE}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  if (isLoading || !groupedEvents) {
    return <Loading />
  }

  const sportList = groupedEvents.map(sportGroup => sportGroup.sport)

  return (
    <Box>
      {/* Sports Filter */}
      {sportList.length > 1 ? (
        <Flex pt={isMobile ? 'sm' : 'lg'} pb="sm" wrap="wrap" align="center" style={{ gap: 8 }}>
          <Chip
            value={intl.formatMessage({ id: 'suggestionAll' })}
            isActive={!selectedSport}
            onClick={() => setSelectedSport(undefined)}
          />
          {sportList.map(sport => (
            <Chip
              key={sport}
              value={intl.formatMessage({ id: sport })}
              isActive={selectedSport === sport}
              onClick={() => setSelectedSport(sport)}
            />
          ))}
        </Flex>
      ) : null}

      <Box>
        {groupedEvents.length ? (
          <>
            {groupedEvents
              .filter(sportGroup => !selectedSport || sportGroup.sport === selectedSport)
              .map(sportGroup => (
                <SportGroup
                  key={sportGroup.sport}
                  titleId={sportGroup.sport}
                  tournaments={sportGroup.tournaments}
                  displayFollowers
                  revalidateEventList={revalidateEvents}
                />
              ))}
          </>
        ) : (
          <Box mt="xl">
            <EmptyEvents />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default EventsForDate
