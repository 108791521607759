import { AnyAction } from 'redux'
import { BasicEvent } from 'entities/Event'
import { ScoreWithPeriods } from 'entities/Score'
import { INTERRUPTED_CODE, StatusType } from 'entities/Status'

import { EventActionType } from './actions'

export interface EventState {
  initialEvent: BasicEvent
  event: BasicEvent
  hasChanges?: boolean
}

export interface Scores {
  homeScore: ScoreWithPeriods
  awayScore: ScoreWithPeriods
}
export interface EventIncidentsAction {
  type: EventActionType
  payload: BasicEvent | ScoreWithPeriods | Scores
}

export const eventReducer = (state: EventState, action: AnyAction) => {
  switch (action.type) {
    case EventActionType.SET_EVENT: {
      return {
        ...state,
        event: action.payload,
      }
    }

    case EventActionType.SET_INITIAL_EVENT: {
      return {
        ...state,
        initialEvent: action.payload,
      }
    }

    case EventActionType.SET_SCORES: {
      return {
        ...state,
        event: {
          ...state.event,
          homeScore: { ...state.event.homeScore, current: action.payload.homeScore, display: action.payload.homeScore },
          awayScore: { ...state.event.awayScore, current: action.payload.awayScore, display: action.payload.awayScore },
        },
      }
    }

    case EventActionType.REVERT_SCORES: {
      const { isHome, homeScore, awayScore } = action.payload
      const newHomeScore = isHome
        ? { ...state.event.homeScore, current: homeScore, display: homeScore }
        : state.event.homeScore
      const newAwayScore = isHome
        ? state.event.awayScore
        : { ...state.event.awayScore, current: awayScore, display: awayScore }

      return {
        ...state,
        event: {
          ...state.event,
          homeScore: newHomeScore,
          awayScore: newAwayScore,
        },
      }
    }

    case EventActionType.RESET_SCORES: {
      return {
        ...state,
        event: {
          ...state.event,
          homeScore: {
            current: 0,
            display: 0,
          },
          awayScore: {
            current: 0,
            display: 0,
          },
        },
      }
    }

    case EventActionType.RESET_PERIOD_SCORES: {
      return {
        ...state,
        event: {
          ...state.event,
          homeScore: {
            ...state.event.homeScore,
            period1: undefined,
            period2: undefined,
            period3: undefined,
            period4: undefined,
            period5: undefined,
            overtime: undefined,
          },
          awayScore: {
            ...state.event.awayScore,
            period1: undefined,
            period2: undefined,
            period3: undefined,
            period4: undefined,
            period5: undefined,
            overtime: undefined,
          },
        },
      }
    }

    case EventActionType.REINITIALIZE_SCORES: {
      return {
        ...state,
        event: {
          ...state.event,
          homeScore: {
            ...state.initialEvent.homeScore,
          },
          awayScore: {
            ...state.initialEvent.awayScore,
          },
        },
      }
    }

    case EventActionType.SET_PERIOD_SCORE: {
      const teamKey = action.payload.isHome ? 'homeScore' : 'awayScore'
      const periodKey = action.payload.periodKey as keyof ScoreWithPeriods
      const score = action.payload.score

      // if one score becomes defined set other score to 0
      const otherTeamKey = action.payload.isHome ? 'awayScore' : 'homeScore'
      const otherTeamScore = state.event[otherTeamKey]
      const otherTeamNewScore =
        typeof state.event[teamKey][periodKey] !== 'number' ? 0 : state.event[otherTeamKey][periodKey]

      return {
        ...state,
        event: {
          ...state.event,
          [teamKey]: {
            ...state.event[teamKey],
            [periodKey]: score,
          },
          [otherTeamKey]: {
            ...otherTeamScore,
            [periodKey]: otherTeamNewScore,
          },
        },
      }
    }

    case EventActionType.INCREMENT_SCORE: {
      const teamKey = action.payload.isHome ? 'homeScore' : 'awayScore'
      const otherTeamKey = action.payload.isHome ? 'awayScore' : 'homeScore'
      const points = action.payload.points

      return {
        ...state,
        event: {
          ...state.event,
          [teamKey]: {
            ...state.event[teamKey],
            current: state.event[teamKey].current !== undefined ? state.event[teamKey].current! + points : points,
            display: state.event[teamKey].display !== undefined ? state.event[teamKey].display! + points : points,
          },
          [otherTeamKey]: {
            ...state.event[otherTeamKey],
            current: state.event[otherTeamKey].current !== undefined ? state.event[otherTeamKey].current : 0,
            display: state.event[otherTeamKey].display !== undefined ? state.event[otherTeamKey].display : 0,
          },
        },
      }
    }

    case EventActionType.DECREMENT_SCORE: {
      const teamKey = action.payload.isHome ? 'homeScore' : 'awayScore'
      const otherTeamKey = action.payload.isHome ? 'awayScore' : 'homeScore'
      const points = action.payload.points

      return {
        ...state,
        event: {
          ...state.event,
          [teamKey]: {
            ...state.event[teamKey],
            current:
              state.event[teamKey].current !== undefined && state.event[teamKey].current! > 0
                ? state.event[teamKey].current! - points
                : 0,
            display:
              state.event[teamKey].display !== undefined && state.event[teamKey].display! > 0
                ? state.event[teamKey].display! - points
                : 0,
          },
          [otherTeamKey]: {
            ...state.event[otherTeamKey],
            current: state.event[otherTeamKey].current !== undefined ? state.event[otherTeamKey].current : 0,
            display: state.event[otherTeamKey].display !== undefined ? state.event[otherTeamKey].display : 0,
          },
        },
      }
    }

    case EventActionType.INCREMENT_PERIOD_SCORE: {
      const teamKey = action.payload.isHome ? 'homeScore' : 'awayScore'
      const periodKey = action.payload.periodKey as keyof ScoreWithPeriods
      const points = action.payload.points

      const teamScore = state.event[teamKey]

      const newResult = typeof teamScore[periodKey] !== 'number' ? 0 : teamScore[periodKey] + points

      // set other team to zero if one of the results become defined
      const otherTeamKey = action.payload.isHome ? 'awayScore' : 'homeScore'
      const otherTeamScore = state.event[otherTeamKey]
      const otherTeamResult = typeof teamScore[periodKey] !== 'number' ? 0 : otherTeamScore[periodKey]

      return {
        ...state,
        event: {
          ...state.event,
          [teamKey]: {
            ...teamScore,
            [periodKey]: newResult,
          },
          [otherTeamKey]: {
            ...otherTeamScore,
            [periodKey]: otherTeamResult,
          },
        },
      }
    }

    case EventActionType.DECREMENT_PERIOD_SCORE: {
      const teamKey = action.payload.isHome ? 'homeScore' : 'awayScore'
      const periodKey = action.payload.periodKey as keyof ScoreWithPeriods
      const points = action.payload.points

      const periodScore = state.event[teamKey][periodKey]

      const isPeriodDefined = periodScore !== undefined && periodScore !== null
      const isScorePositive = isPeriodDefined && periodScore > 0

      const newResult = isScorePositive ? periodScore - points : null

      // set both scores to null if one is null
      const otherTeamKey = action.payload.isHome ? 'awayScore' : 'homeScore'
      const otherTeamScore = state.event[otherTeamKey]
      const otherTeamResult = newResult === null ? null : otherTeamScore[periodKey]

      return {
        ...state,
        event: {
          ...state.event,
          [teamKey]: {
            ...state.event[teamKey],
            [periodKey]: newResult,
          },
          [otherTeamKey]: {
            ...otherTeamScore,
            [periodKey]: otherTeamResult,
          },
        },
      }
    }

    case EventActionType.INCREMENT_PENALTY_SCORE: {
      const teamKey = action.payload.isHome ? 'homeScore' : 'awayScore'
      const otherTeamKey = action.payload.isHome ? 'awayScore' : 'homeScore'

      return {
        ...state,
        event: {
          ...state.event,
          [teamKey]: {
            ...state.event[teamKey],
            penalties: state.event[teamKey].penalties !== undefined ? state.event[teamKey].penalties! + 1 : 1,
            current: state.event[teamKey].current !== undefined ? state.event[teamKey].current! + 1 : 1,
          },
          [otherTeamKey]: {
            ...state.event[otherTeamKey],
            penalties: state.event[otherTeamKey].penalties !== undefined ? state.event[otherTeamKey].penalties : 0,
            current: state.event[otherTeamKey].current !== undefined ? state.event[otherTeamKey].current : 0,
          },
        },
      }
    }

    case EventActionType.DECREMENT_PENALTY_SCORE: {
      const teamKey = action.payload.isHome ? 'homeScore' : 'awayScore'
      const otherTeamKey = action.payload.isHome ? 'awayScore' : 'homeScore'

      return {
        ...state,
        event: {
          ...state.event,
          [teamKey]: {
            ...state.event[teamKey],
            penalties:
              state.event[teamKey].penalties !== undefined && state.event[teamKey].penalties! > 0
                ? state.event[teamKey].penalties! - 1
                : 0,
            current:
              state.event[teamKey].current !== undefined && state.event[teamKey].current! > 0
                ? state.event[teamKey].current! - 1
                : 0,
          },
          [otherTeamKey]: {
            ...state.event[otherTeamKey],
            penalties: state.event[otherTeamKey].penalties !== undefined ? state.event[otherTeamKey].penalties : 0,
            current: state.event[otherTeamKey].current !== undefined ? state.event[otherTeamKey].current : 0,
          },
        },
      }
    }

    case EventActionType.RESET_PENALTIES: {
      return {
        ...state,
        event: {
          ...state.event,
          homeScore: {
            ...state.event.homeScore,
            penalties: undefined,
          },
          awayScore: {
            ...state.event.awayScore,
            penalties: undefined,
          },
        },
      }
    }

    case EventActionType.UPDATE_STATUS_CODE: {
      const statusCode = action.payload

      return {
        ...state,
        event: {
          ...state.event,
          status: {
            type: statusCode === INTERRUPTED_CODE ? StatusType.INTERRUPTED : StatusType.FINISHED,
            code: statusCode,
          },
        },
      }
    }

    case EventActionType.UPDATE_WINNER_CODE: {
      const winnerCode = action.payload

      return {
        ...state,
        event: {
          ...state.event,
          aggregatedWinnerCode: winnerCode,
        },
      }
    }

    case EventActionType.RECALCULATE_CURRENT_SCORES: {
      const homeScore = state.event.homeScore
      const awayScore = state.event.awayScore
      const homeCurrentScore =
        (homeScore.period1 || 0) +
        (homeScore.period2 || 0) +
        (homeScore.period3 || 0) +
        (homeScore.period4 || 0) +
        (homeScore.penalties || 0) +
        (homeScore.overtime || 0)

      const awayCurrentScore =
        (awayScore.period1 || 0) +
        (awayScore.period2 || 0) +
        (awayScore.period3 || 0) +
        (awayScore.period4 || 0) +
        (awayScore.penalties || 0) +
        (awayScore.overtime || 0)

      return {
        ...state,
        event: {
          ...state.event,
          homeScore: {
            ...state.event.homeScore,
            current: homeCurrentScore,
            display: homeCurrentScore,
          },
          awayScore: {
            ...state.event.awayScore,
            current: awayCurrentScore,
            display: awayCurrentScore,
          },
        },
      }
    }

    default:
      return state
  }
}
