import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Flex, Text } from '@sofascore/ui'
import IconAccessTimeOutline from '@sofascore/ui/dist/modules/Icons/IconAccessTimeOutline'

import { formatTime } from 'utils/time'
import ResponsivePopUp from 'components/ResponsivePopUp'
import { Button } from 'components/Button'
import { TimePicker } from 'components/TimePicker'

import { formatNewInputValue, isFormatValidToDisplay } from './utils'
import * as S from './styles'

interface TimeInputProps {
  /* Input label */
  label: string
  /* Currently selected date */
  value?: Date
  /* This handler is called with a valid new date */
  onChange?: (date?: Date) => void
}

const TimeInput = ({ label, value, onChange }: TimeInputProps) => {
  const [inputValue, setInputValue] = useState(value ? formatTime(value) : '')

  const [isTimePickerOpened, setTimePickerOpened] = useState(false)
  const [timePickerDate, setTimePickerDate] = useState(value || new Date())

  const parseInputValue = (newInputValue: string) => {
    if (!isFormatValidToDisplay(newInputValue)) return

    const formattedValue = formatNewInputValue(inputValue, newInputValue)

    // Set inner state
    setInputValue(formattedValue)

    // Set outer date & time picker state
    const selectedTime = value ?? new Date()
    const [hours, minutes] = formattedValue.split(':')
    if (hours === undefined || minutes === undefined) {
      onChange?.(undefined)
    } else {
      selectedTime.setHours(+hours)
      selectedTime.setMinutes(+minutes)
      onChange?.(selectedTime)
      setTimePickerDate(selectedTime)
    }
  }

  /**
   * If hours and minutes are valid values, pad them with zeroes.
   */
  const handleOnBlur = () => {
    let [hours, minutes] = inputValue.split(':')
    if (isNaN(+hours) || isNaN(+minutes)) return
    hours = `00${hours}`.slice(-2)
    minutes = `00${minutes}`.slice(-2)
    setInputValue(hours + ':' + minutes)
  }

  const handleCloseTimePicker = () => {
    setTimePickerOpened(false)
  }

  const handleTimePickerConfirm = () => {
    // Convert time picker selected value into input string
    const hours = `0${timePickerDate.getHours()}`.slice(-2)
    const minutes = `0${timePickerDate.getMinutes()}`.slice(-2)
    setInputValue(hours + ':' + minutes)

    const newTime = new Date()
    newTime.setHours(timePickerDate.getHours())
    newTime.setMinutes(timePickerDate.getMinutes())
    onChange?.(newTime)

    handleCloseTimePicker()
  }

  return (
    <>
      <S.TimeInput
        label={label}
        value={inputValue}
        placeholder="HH:mm"
        onChange={value => parseInputValue(value)}
        onBlur={handleOnBlur}
        maxLength={5}
        endAdornment={
          <Flex
            p="xs"
            justify="center"
            align="center"
            cursor="pointer"
            hoverBg="onSurface.nLv5"
            br="sm"
            style={{ boxSizing: 'border-box' }}
            onClick={e => {
              e.stopPropagation()
              setTimePickerOpened(true)
            }}
          >
            <IconAccessTimeOutline />
          </Flex>
        }
      />
      <ResponsivePopUp
        isOpen={isTimePickerOpened}
        onClose={handleCloseTimePicker}
        heading={
          <Text fontWeight="bold" fontSize={18}>
            <FormattedMessage id="changeTime" />
          </Text>
        }
      >
        <Box px="lg" pb="lg">
          <Flex align="center" bg="surface.s0" br="sm">
            <TimePicker date={timePickerDate} onChange={setTimePickerDate} />
          </Flex>

          <Flex py="xl" justify="center">
            <Text
              color="primary.default"
              fontSize="small"
              fontWeight="medium"
              cursor="pointer"
              onClick={() => setTimePickerDate(new Date())}
              userSelect="none"
            >
              <FormattedMessage id="current_time" />
            </Text>
          </Flex>

          <Flex gapX="lg" mt="md">
            <Button variant="outlined" fullWidth onClick={handleCloseTimePicker}>
              <FormattedMessage id="cancel" />
            </Button>
            <Button variant="contained" fullWidth onClick={handleTimePickerConfirm}>
              <FormattedMessage id="confirm" />
            </Button>
          </Flex>
        </Box>
      </ResponsivePopUp>
    </>
  )
}

export default TimeInput
