import { UniqueTournamentDetails } from 'entities/UniqueTournament'

import { InfoGeneralFormAPIPayload, type InfoGeneralFormType } from '../model'

export function infoGeneralFormDTO(values: InfoGeneralFormType): InfoGeneralFormAPIPayload {
  return {
    tier: values.tier ? Number(values.tier) : undefined,
    lowerDivisionIds: values.lowerDivisionIds.map(option => Number(option.value)),
    upperDivisionIds: values.upperDivisionIds.map(option => Number(option.value)),
    titleHolderId: values.titleHolderId ? Number(values.titleHolderId.value) : undefined,
    titleHolderTitles: values.titleHolderTitles ? Number(values.titleHolderTitles) : undefined,
    mostTitlesTeamIds: values.mostTitlesTeamIds.map(option => Number(option.value)),
    mostTitles: values.mostTitles ? Number(values.mostTitles) : undefined,
  }
}

export function infoGeneralFormInitialValuesDTO(values: UniqueTournamentDetails): InfoGeneralFormType {
  return {
    tier: values.tier ? Number(values.tier) : undefined,
    lowerDivisionIds: values.lowerDivisions
      ? values.lowerDivisions.map(option => ({ value: option.id, label: option.name }))
      : [],
    upperDivisionIds: values.upperDivisions
      ? values.upperDivisions.map(option => ({ value: option.id, label: option.name }))
      : [],
    titleHolderId: values.titleHolder ? { value: values.titleHolder.id, label: values.titleHolder.name } : null,
    titleHolderTitles: values.titleHolderTitles ? Number(values.titleHolderTitles) : undefined,
    mostTitlesTeamIds: values.mostTitlesTeams
      ? values.mostTitlesTeams.map(option => ({ value: option.id, label: option.name }))
      : [],
    mostTitles: values.mostTitles ? Number(values.mostTitles) : undefined,
  }
}
