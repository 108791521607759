import ReactCalendar from 'react-calendar'
import styled from 'styled-components'

import { secondaryColorAlpha } from 'styles/color'

export const Calendar = styled(ReactCalendar)`
  width: 100%;

  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    appearance: none !important;
  }

  button {
    outline: none;
    border: none;
    background: none;
    font-family: 'Sofascore Sans', -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif,
      Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  }

  .react-calendar {
    &__navigation {
      display: flex;
      padding: 10px 5px;
    }

    &__navigation__label__labelText span {
      cursor: pointer;
      color: ${p => p.theme.colors.primary.default};
    }

    &__year-view__months__month,
    &__decade-view__years__year {
      cursor: pointer;
      padding: 10px;
    }

    &__year-view__months__month > div,
    &__decade-view__years__year > div {
      display: none;
    }

    &__month-view {
      &__weekdays {
        background-color: ${p => p.theme.colors.surface.s2};
        padding: 8px 0;

        &__weekday {
          text-align: center;
          font: ${p => p.theme.typography.font.body.small};
          color: ${p => p.theme.colors.onSurface.nLv3};
          > abbr {
            text-decoration: none;
          }
        }
      }

      &__days {
        padding: 4px 0;
        &__day {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          &:hover {
            div {
              background-color: ${p => p.theme.colors.primary.highlight};
            }

            div.selected {
              background-color: ${p => p.theme.colors.primary.hover};
            }
          }
        }
      }
    }

    &__navigation__arrow {
      display: flex;
      outline: none;
      transition: background-color 200ms, box-shadow 200ms, border-color 200ms, color 200ms;
      cursor: pointer;
      user-select: none;
      z-index: 1;
      border-radius: 4px;
      letter-spacing: 0.5px;
      color: ${p => p.theme.colors.primary.default};
      padding: 0px;
      margin: 0 6px;
      &:hover {
        background-color: ${secondaryColorAlpha};
        color: rgb(255, 109, 157);
      }
    }
  }
`
