import { createContext, useContext } from 'react'

import { Player } from '../model'

interface EventPlayers {
  [key: number]: Player[]
}

const EventPlayersContext = createContext<EventPlayers>({} as EventPlayers)

export const EventPlayersProvider = ({
  initialState,
  children,
}: {
  initialState: EventPlayers
  children: React.ReactNode
}) => {
  return <EventPlayersContext.Provider value={initialState}>{children}</EventPlayersContext.Provider>
}

export const useEventPlayers = (): EventPlayers => {
  const context = useContext(EventPlayersContext)

  if (!context) {
    throw new Error('useEventPlayers must be used within a EventPlayersProvider')
  }

  return context
}
