import styled from 'styled-components'
import { Box } from '@sofascore/ui'
import SearchIcon from '@sofascore/ui/dist/modules/Icons/_IconSearch'

export const DivisionItem = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${p => p.theme.colors.surface.s1};
  border-radius: 4px;
  padding: 4px 12px 4px 16px;
  margin: 8px 2px 2px 2px;
`

export const DivisionItemLabel = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
`

export const DivisionItemLabelText = styled.p`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: absolute;
  font-size: 12px;
  left: 24px;
  right: 0;
  margin: 0;
`

export const DivisionItemClear = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  padding: 0;
  margin-left: 8px;

  &:hover {
    background-color: ${p => p.theme.colors.surface.s2};
  }
`

export const SearchAdornment = styled(SearchIcon)`
  position: absolute;
  right: 9px;
  top: 12px;
  z-index: 99;
  background-color: ${p => p.theme.colors.surface.s1};
  pointer-events: none;
`
