/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Table } from '@sofascore/ui'
import { DndProvider } from 'react-dnd-multi-backend'
import { HTML5toTouch } from 'rdndmb-html5-to-touch'
import { useToast } from 'shared/lib'
import { useErrorHandler } from 'shared/hooks'
import { useTheme } from 'styled-components'
import { PermissionGuard } from 'shared/ui'
import { PermissionAction } from 'entities/Permission'

import { Standings, StandingsRow } from 'modules/StandingsRedesign/interface'
import { calculateStandings, sortStandings } from 'api'
import { EditingStandingsControls, ViewingStandingsControls } from 'modules/StandingsRedesign/components/Controls'

import AdaptiveTableRow from './AdaptiveTableRow'
import AdaptiveTableHead from './AdaptiveTableHead'
import AdaptiveTableDragPreviewRow from './AdaptiveTableDragPreviewRow'

interface Props {
  name: string
  standings: Standings
  seasonId: number
  revalidate: () => void
}

export default function AdaptiveTable({ name, standings, seasonId, revalidate }: Props) {
  const [editingStandingsRows, setEditingStandingsRows] = useState<StandingsRow[]>(standings.rows)
  const [isEditMode, setIsEditMode] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const theme = useTheme()

  const intl = useIntl()
  const { enqueueToast } = useToast()
  const handleError = useErrorHandler()

  const sport = standings.tournament.category.sport.slug

  const hasChanges = useMemo(() => {
    if (standings.rows.length !== editingStandingsRows.length) {
      return true
    }

    return standings.rows.some((row, index) => row.id !== editingStandingsRows[index].id)
  }, [standings.rows, editingStandingsRows])

  const handleMoveRow = useCallback((dragIndex: number, hoverIndex: number) => {
    setEditingStandingsRows((prevRows: StandingsRow[]) => {
      return prevRows.map((row, index) => {
        if (index === dragIndex) {
          return {
            ...prevRows[hoverIndex],
            position: prevRows[dragIndex].position,
          }
        }
        if (index === hoverIndex) {
          return {
            ...prevRows[dragIndex],
            position: prevRows[hoverIndex].position,
          }
        }

        return row
      })
    })
  }, [])

  // Method for recalculating standings (reset to default)
  const handleRecalculateStandings = useCallback(
    (tournamentId: number) => {
      setIsSaving(true)

      if (seasonId) {
        calculateStandings(tournamentId, seasonId)
          .then(() => {
            revalidate()
            enqueueToast(intl.formatMessage({ id: 'toastSuccess' }), { variant: 'success' })
          })
          .catch(err => {
            handleError(err)
          })
          .finally(() => {
            setIsSaving(false)
          })
      }
    },
    [seasonId],
  )

  // Method for saving newly sorted standings
  const handleSortStandings = useCallback(() => {
    if (editingStandingsRows) {
      const sortedStandingsData = editingStandingsRows.map(standingsRow => ({
        teamId: standingsRow.team.id,
        position: standingsRow.position,
      }))

      setIsSaving(true)

      sortStandings(standings.id, { sortedStandings: sortedStandingsData })
        .then(() => {
          revalidate()
          enqueueToast(intl.formatMessage({ id: 'toastSuccess' }), { variant: 'success' })
        })
        .catch(err => {
          handleError(err)
        })
        .finally(() => {
          setIsSaving(false)
          setIsEditMode(false)
        })
    }
  }, [editingStandingsRows, standings.id])

  const handleCancelEditing = useCallback(() => {
    setIsEditMode(false)
    setEditingStandingsRows(standings.rows)
  }, [])

  useEffect(() => {
    setEditingStandingsRows(standings.rows)
  }, [standings.rows])

  return (
    <Box bg="surface.s1" br="sm" overflow="hidden">
      {isEditMode && (
        <Box
          position="fixed"
          top={0}
          left={0}
          w="100vw"
          h="100vh"
          backgroundColor="rgba(0, 0, 0, 0.25)"
          zIndex={1500}
          onClick={() => handleCancelEditing()}
        />
      )}

      <Box position="relative" zIndex={isEditMode ? 1501 : undefined} pb="sm" backgroundColor={theme.colors.surface.s1}>
        <PermissionGuard
          entityAllowedActions={standings.allowedActions}
          checkAllowedActions={[PermissionAction.Update]}
        >
          {isEditMode ? (
            <EditingStandingsControls
              standingsName={name}
              onCancel={handleCancelEditing}
              onSave={handleSortStandings}
              isSaveDisabled={!hasChanges || isSaving}
              tournamentStandings={standings}
            />
          ) : (
            <ViewingStandingsControls
              standingsName={name}
              tournamentStandings={standings}
              isSaving={isSaving}
              onEditClick={() => setIsEditMode(true)}
              onRecalculateClick={handleRecalculateStandings}
            />
          )}
        </PermissionGuard>

        <DndProvider options={HTML5toTouch}>
          <Table w="100%" style={{ backgroundColor: theme.colors.surface.s1 }}>
            <AdaptiveTableHead sport={sport} isEditMode={isEditMode} />

            <Table.Body disabledStripes>
              {editingStandingsRows.map((row, index) => (
                <AdaptiveTableRow
                  key={row.id}
                  row={row}
                  sport={sport}
                  index={index}
                  isEditMode={isEditMode}
                  onMoveRow={handleMoveRow}
                />
              ))}

              <AdaptiveTableDragPreviewRow />
            </Table.Body>
          </Table>
        </DndProvider>
      </Box>
    </Box>
  )
}
