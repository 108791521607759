import * as React from 'react'

import { IconBase, IconProps } from './Base'

export const IconRecalculate = (props: IconProps) => (
  <IconBase width={props.width || 24} height={props.height || 24} viewBox="0 0 24 24" {...props}>
    <path
      d="M21 10V3L18.36 5.64C16.66 3.94 14.4 3 12 3C7.04 3 3 7.04 3 12C3 16.96 7.04 21 12 21C16.96 21 21 16.96 21 12H19C19 15.86 15.86 19 12 19C8.14 19 5 15.86 5 12C5 8.14 8.14 5 12 5C13.87 5 15.63 5.73 16.95 7.05L14 10H21Z"
      fill={props.fill || '#222226'}
    />
  </IconBase>
)
