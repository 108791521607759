import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useMemo } from 'react'
import { Box, Flex } from '@sofascore/ui'
import {
  AvailablePlayers,
  ClearAll,
  LineupsCaptainSelector,
  LineupsFormationSelector,
  LineupsKitSelector,
  LineupsTeamSelector,
  MissingPlayers,
  PlayerCounter,
  lineupFormations,
  lineupPlayerKitColors,
  ADD_TO_LINEUP,
  REMOVE_FROM_LINEUP,
  useLineupsDispatch,
  useLineupsState,
  PitchLineupsProps,
  DynamicLineupsPlayer,
  groupPlayersByPosition,
  mapFootballPositionToGroupKey,
} from 'features/AddLineups'
import { FormattedMessage } from 'react-intl'

import { DesktopDragAndDropPitch } from './DesktopDragAndDropPitch'
import { FootballSubstitutions } from './Substitutions'
import * as S from './styles'

export const DesktopPitchLineups = ({
  lineupPlayers,
  otherTeamKit,
  isHomeTeam,
  activeTeamId,
  onChangeTeam,
  homeTeam,
  awayTeam,
}: PitchLineupsProps) => {
  const { homeKit, homeFormation, homeGoalkeeperKit, awayKit, awayFormation, awayGoalkeeperKit } = useLineupsState()
  const dispatch = useLineupsDispatch()

  const starters = useMemo(() => {
    return lineupPlayers.filter(lp => lp.position && !lp.substitute)
  }, [lineupPlayers])

  const substitutions = useMemo(() => {
    return lineupPlayers.filter(lp => lp.substitute)
  }, [lineupPlayers])

  const missingPlayers = useMemo(() => {
    return lineupPlayers.filter(lp => lp.missingReason !== null)
  }, [lineupPlayers])

  const availablePlayers = useMemo(() => {
    return lineupPlayers.filter(lp => lp.position === null && !lp.substitute && lp.missingReason === null)
  }, [lineupPlayers])

  // Kit color must be different from the one that other team uses and also different from goalkeeper ones
  const availableKitColors = lineupPlayerKitColors.filter(o => o.value !== otherTeamKit)

  const groupedAvailablePlayers = groupPlayersByPosition(availablePlayers)

  const handleAddToLineup = (lineupPlayer: DynamicLineupsPlayer, position: number) => {
    dispatch({ type: ADD_TO_LINEUP, payload: { lineupPlayer: lineupPlayer, position: position } })
  }

  const handleRemoveFromLineup = (lineupPlayer: DynamicLineupsPlayer) => {
    dispatch({ type: REMOVE_FROM_LINEUP, payload: { lineupPlayer: lineupPlayer } })
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <S.PitchOptionsGrid>
        <Flex direction="column" elevation={1} maxW={360} bg="surface.s1" br="sm" w="100%">
          <Flex direction="column" gapY="lg" p="lg">
            <LineupsTeamSelector
              homeTeam={homeTeam}
              awayTeam={awayTeam}
              teamId={activeTeamId}
              setTeamId={onChangeTeam}
            />

            <Flex gapX="sm">
              <Box w="50%">
                <LineupsKitSelector options={availableKitColors} isHomeTeam={isHomeTeam} />
              </Box>
              <Box w="50%">
                <LineupsFormationSelector options={lineupFormations} isHomeTeam={isHomeTeam} />
              </Box>
            </Flex>

            <PlayerCounter starters={starters} limit={11}>
              <ClearAll isHomeTeam={isHomeTeam} message={<FormattedMessage id="football_clear_team_lineup_text" />} />
            </PlayerCounter>
          </Flex>

          <Flex align="stretch">
            <Flex direction="column" w="100%">
              <DesktopDragAndDropPitch
                lineupPlayers={lineupPlayers}
                kit={isHomeTeam ? homeKit : awayKit}
                goalkeeperKit={isHomeTeam ? homeGoalkeeperKit : awayGoalkeeperKit}
                formation={isHomeTeam ? homeFormation : awayFormation}
                onDrop={handleAddToLineup}
                onRemove={handleRemoveFromLineup}
              />

              <Flex direction="column" p="lg">
                <LineupsCaptainSelector starters={starters} />

                <FootballSubstitutions substitutions={substitutions} availablePlayers={availablePlayers} />

                <MissingPlayers
                  missingPlayers={missingPlayers}
                  availablePlayers={availablePlayers}
                  positionGroupMapping={mapFootballPositionToGroupKey}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <AvailablePlayers players={groupedAvailablePlayers} positionGroupMapping={mapFootballPositionToGroupKey} />
      </S.PitchOptionsGrid>
    </DndProvider>
  )
}
