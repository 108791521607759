import * as React from 'react'
import { BasicEvent } from 'entities/Event'
import { OVERTIME, FIRST_EXTRA_TIME_CODE, SECOND_EXTRA_TIME_CODE } from 'entities/Status'

import { isDev } from 'utils'
import { getTimeParts } from 'utils/time'
import { getCurrentPeriodInitialTime, getCurrentPeriodMaxTime } from 'utils/matchTime'

import * as T from './interface'

/**
 * From an array of `[hours, minutes, seconds]` get `HH:MM:SS` formatted string.
 *
 * If countdown reaches zero, time parts become negative. So 0 is set as a bottom constraint.
 */
export const getTimeString = (hms: Array<number>, config?: { precision?: 'h' | 'm' | 's' }) => {
  switch (config?.precision) {
    case 'h':
    default:
      return hms.map(n => `0${Math.max(n, 0)}`.slice(-2)).join(':')

    case 'm':
      return hms
        .map(n => `0${Math.max(n, 0)}`.slice(-2))
        .slice(1)
        .join(':')

    case 's':
      return hms
        .map(n => `0${Math.max(n, 0)}`.slice(-2))
        .slice(2)
        .join(':')
  }
}

/**
 * Takes a `referenceTime` prop which is a timestamp in seconds
 * and keeps a `delta` from it in state; this `delta` is either
 * incremented or decremented each second, depending on `modifier` prop.
 *
 * Requires `children` prop as a function, which receives
 * `timeParts`, `delta` and `formattedTime`.
 */
export class Timekeeper extends React.PureComponent<T.Props, T.State> {
  state: T.State
  intervalId: number | undefined

  timeoutIntervalId: number | undefined

  constructor(props: T.Props) {
    super(props)

    if (typeof props.children !== 'function' && isDev()) {
      throw new Error('Timekeeper component requires prop children as a function!')
    }

    const secondsNow = Math.floor(Date.now() / 1000)
    const delta = this.props.modifier < 0 ? props.referenceTime - secondsNow : secondsNow - props.referenceTime
    this.state = { delta, isPageHidden: false }

    this.tick = this.tick.bind(this)
    this.start = this.start.bind(this)
    this.stop = this.stop.bind(this)
    this.resetDelta = this.resetDelta.bind(this)
    this.onVisibilityChange = this.onVisibilityChange.bind(this)
  }

  componentDidMount() {
    this.start()
    document.addEventListener('visibilitychange', this.onVisibilityChange)
  }

  componentDidUpdate(newProps: T.Props) {
    const { referenceTime } = this.props

    // when reference time changes, recalculate delta
    if (referenceTime !== newProps.referenceTime) {
      this.resetDelta()
    }
  }

  componentWillUnmount() {
    this.stop()
    document.removeEventListener('visibilitychange', this.onVisibilityChange)
  }

  /**
   * Calculate absolute delta between now and reference time
   */
  resetDelta() {
    const { referenceTime, modifier } = this.props
    const secondsNow = Math.floor(Date.now() / 1000)
    const delta = modifier < 0 ? referenceTime - secondsNow : secondsNow - referenceTime

    this.setState({ delta })
  }

  tick() {
    if (Math.abs(this.props.modifier) <= 1) {
      const nextTick = this.state.delta + this.props.modifier
      this.setState({ delta: nextTick })
    } else {
      this.resetDelta()
    }
  }

  start() {
    this.stop()

    // if modifier is greater than one second, schedule interval on start of a minute
    if (Math.abs(this.props.modifier) > 1) {
      const secondsNow = Math.floor(Date.now() / 1000)
      const delta = secondsNow - this.props.referenceTime
      const [, , , s] = getTimeParts(delta)

      /**
       * Schedule interval to start on the next full minute of a match.
       */
      this.timeoutIntervalId = window.setTimeout(() => {
        this.intervalId = window.setInterval(this.tick, this.props.modifier * 1000)
        this.tick()
      }, (60 - s + 1) * 1000)
    } else {
      this.intervalId = window.setInterval(this.tick, 1e3)
    }
  }

  stop() {
    window.clearInterval(this.intervalId)
    window.clearTimeout(this.timeoutIntervalId)
  }

  onVisibilityChange() {
    if (document.visibilityState === 'visible') {
      this.resetDelta()
      this.setState({ isPageHidden: false })

      this.start()
    }

    // suspend timers to save on time budget for bg tabs
    if (document.visibilityState === 'hidden') {
      this.stop()
      this.setState({ isPageHidden: true })
    }
  }

  render() {
    if (this.state.isPageHidden) return '-'

    const delta = this.state.delta
    const timeParts = getTimeParts(delta)
    const formattedTime = getTimeString(timeParts.slice(1))

    return this.props.children({ timeParts, formattedTime, delta })
  }
}

/**
 * Takes `startTimestamp` (seconds) and increments from it every (second/minute).
 * If passed function as children, it will render it inside `<Timekeeper />`.
 * Otherwise renders `hh:mm:ss` formatted time.
 */
export function Timer({
  startTimestamp,
  children,
  modifier = 1,
}: {
  startTimestamp: number
  children: T.ChildrenFn
  modifier?: 1 | -1 | 60
}) {
  return (
    <Timekeeper referenceTime={startTimestamp} modifier={modifier}>
      {typeof children === 'function'
        ? children
        : ({ delta: secondsElapsed, formattedTime }) => {
            if (secondsElapsed < 0) return null
            return formattedTime
          }}
    </Timekeeper>
  )
}

export function ProgressTimer({
  event,
  additionalTimeDisplayLimit,
}: {
  event: BasicEvent
  additionalTimeDisplayLimit?: number
}) {
  const { time, currentPeriodStartTimestamp, status } = event
  const statusCode = status.code

  if (!time) return null

  const cpst = time.currentPeriodStartTimestamp || currentPeriodStartTimestamp

  if (!cpst) return null

  const initial = getCurrentPeriodInitialTime(event)
  const max = getCurrentPeriodMaxTime(event)
  const startTimestamp = cpst - initial

  return (
    <Timer startTimestamp={startTimestamp}>
      {({ timeParts, delta: secondsElapsed }) => {
        if (secondsElapsed < 0) {
          return null
        }
        // eslint-disable-next-line prefer-const
        let [_days, hours, minutes] = timeParts

        let prefix =
          statusCode && [OVERTIME, FIRST_EXTRA_TIME_CODE, SECOND_EXTRA_TIME_CODE].includes(statusCode) ? 'ET-' : ''

        if (max && secondsElapsed > max) {
          const extra = secondsElapsed - max
          minutes = Math.floor(extra / 60) % 60
          hours = Math.floor(extra / 3600) % 24
          prefix += Math.floor(max / 60) + '+'

          if (additionalTimeDisplayLimit && Math.floor(extra / 60) >= additionalTimeDisplayLimit) return <>{prefix}</>
        }

        minutes = hours * 60 + minutes + 1

        return <>{prefix + minutes}&rsquo;</>
      }}
    </Timer>
  )
}
