import { NavigateFunction } from 'react-router-dom'
import { CustomResponse } from 'shared/api'
import { UserAccount } from 'entities/User'
import { LoginPayload, LogoutPayload, ProviderData } from 'features/Auth'

/**
 *
 * ACTION CONSTANTS
 *
 */

export const GET_TOKEN = 'GET_TOKEN'
export const TOKEN_FETCH_SUCCESSFUL = 'TOKEN_FETCH_SUCCESSFUL'
export const TOKEN_REFRESH_SUCCESSFUL = 'TOKEN_REFRESH_SUCCESSFUL'
export const SET_AUTH_DATA = 'SET_AUTH_DATA'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESSFUL = 'LOGOUT_SUCCESSFUL'
export const UNPROCESSABLE_ERROR = 'UNPROCESSABLE_ERROR'
export const UNAUTHORIZED_ERROR = 'UNAUTHORIZED_ERROR'
export const GOOGLE_LOGIN = 'GOOGLE_LOGIN'
export const FACEBOOK_LOGIN = 'FACEBOOK_LOGIN'
export const START_CREDENTIALS_MANAGEMENT_PROCESS = 'START_CREDENTIALS_MANAGEMENT_PROCESS'
export const SAVE_CREDENTIALS = 'SAVE_CREDENTIALS'
export const SET_PROVIDER_DATA = 'SET_PROVIDER_DATA'
export const CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR'

/**
 *
 * ACTION CREATORS
 *
 */

/**
 * Marks token request. Token can be returned from storage or from API.
 */
export const getToken = () => ({ type: GET_TOKEN })

/**
 * Marks successful token fetch from an API.
 *
 * @param token received token
 * @param uuid uuid
 */
export const tokenFetchSuccessful = (token: string, uuid: string) => ({
  type: TOKEN_FETCH_SUCCESSFUL,
  payload: { token, uuid },
})

/**
 * Marks successful token refresh from an API.
 */
export const tokenRefreshSuccessful = (token: string) => ({
  type: TOKEN_REFRESH_SUCCESSFUL,
  payload: { token },
})

/**
 * Sets `token` and `uuid` in Redux store to ensure consistency with local storage.
 *
 * @param token token to be set
 * @param uuid uuid to be set
 */
export const setAuthData = (token: string, uuid: string) => ({
  type: SET_AUTH_DATA,
  payload: { token, uuid },
})

/**
 * Sends login request action.
 */
export const loginRequest = (loginPayload: LoginPayload) => ({
  type: LOGIN_REQUEST,
  payload: loginPayload,
})

/**
 * Marks successful login. User account can be saved.
 */
export const loginSuccessful = (userAccount: UserAccount) => ({
  type: LOGIN_SUCCESSFUL,
  payload: { userAccount },
})

/**
 * Sends logout request action.
 *
 */
export const logoutRequest = () => ({
  type: LOGOUT_REQUEST,
})

/**
 * Marks successful logout. User account can be removed.
 */
export const logoutSuccessful = (logoutPayload: LogoutPayload) => ({
  type: LOGOUT_SUCCESSFUL,
  payload: logoutPayload,
})

/**
 * Indicates authentication error with code `401 (Unauthorized)`.
 * On this action clear user auth data (`token`, `userAccount`,...), but PRESERVE `uuid`.
 *
 * @param e an Error object
 */
export const unauthorizedError = (e: Error | CustomResponse) => ({
  type: UNAUTHORIZED_ERROR,
  payload: e,
})

/**
 * Indicates authentication error with code `422 (Unprocessable)`, caused by bad credentials.
 * On this action only `userAccount` should be deleted.
 *
 * @param e an Error object
 */
export const unprocessableError = (e: Error) => ({
  type: UNPROCESSABLE_ERROR,
  payload: e,
})

/**
 * Sends google login action.
 *
 */
export const googleLogin = (navigate: NavigateFunction, jwt: string) => ({
  type: GOOGLE_LOGIN,
  payload: {
    navigate,
    jwt,
  },
})

/**
 * Sends facebook login action.
 *
 */
export const facebookLogin = (navigate: NavigateFunction) => ({
  type: FACEBOOK_LOGIN,
  payload: {
    navigate,
  },
})

/**
 * Sends action for setting provider data
 */
export const setProviderData = (providerData: ProviderData) => ({
  type: SET_PROVIDER_DATA,
  payload: { providerData },
})

/**
 * Clears login error on login page
 */
export const clearLoginError = () => ({
  type: CLEAR_LOGIN_ERROR,
})

/**
 * Starts Credentials management process
 */
export const startCredentialsManagementProcess = (navigate: NavigateFunction) => ({
  type: START_CREDENTIALS_MANAGEMENT_PROCESS,
  payload: {
    navigate,
  },
})

/**
 * Saving credentials for credentials management
 */
export const saveCredentials = (providerData: ProviderData) => ({
  type: SAVE_CREDENTIALS,
  payload: {
    providerData,
  },
})
