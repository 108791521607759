import { Box, Flex, RadioButton, Text } from '@sofascore/ui'
import { ChangeEvent, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useEventState } from 'entities/Event'
import { StatusCode } from 'entities/Status'
import { useToast } from 'shared/lib'
import { useErrorHandler } from 'shared/hooks'

import { Button } from 'components/Button'
import { updateMatchInfo } from 'api'

enum StatusSelection {
  POSTPONED = 'postponed',
  CANCELED = 'canceled',
}

interface Props {
  handleClose: () => void
  revalidateEvent: () => void
}

const PostponeCancelForm = ({ handleClose, revalidateEvent }: Props) => {
  const intl = useIntl()
  const handleError = useErrorHandler()

  const { enqueueToast } = useToast()
  const { initialEvent } = useEventState()
  const { id } = initialEvent

  const [selectedStatus, setSelectedStatus] = useState<StatusSelection>(StatusSelection.POSTPONED)

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedStatus(e.target.value as StatusSelection)
  }

  const handleConfirm = async () => {
    try {
      await updateMatchInfo(id.toString(), {
        statusCode: selectedStatus === StatusSelection.POSTPONED ? StatusCode.Postponed : StatusCode.Canceled,
      })

      revalidateEvent()
      handleClose()
      enqueueToast(intl.formatMessage({ id: 'toastSuccess' }), { variant: 'success' })
    } catch (err) {
      handleError(err)
    }
  }

  return (
    <Flex direction="column">
      <Box px={50} py="lg">
        <Text fontSize="medium" fontWeight="medium" align="center" style={{ display: 'block' }}>
          <FormattedMessage id="postponed_canceled_text" />
        </Text>
      </Box>

      <Box bg="surface.s2" mx="sm" br={8}>
        <Flex direction="column" align="center">
          <Text fontSize="regular" fontWeight="bold" mt={16} mb={8}>
            <FormattedMessage id="select_match_status" />
          </Text>
        </Flex>

        <Box p="xl">
          <RadioButton
            id="radio-postpone"
            name="postpone-cancel-match-form"
            label={intl.formatMessage({ id: 'postponed' })}
            value="postponed"
            checked={selectedStatus === StatusSelection.POSTPONED}
            onChange={onChange}
            gap="xl"
            cursor="pointer"
          />
          <RadioButton
            id="radio-cancel"
            name="postpone-cancel-match-form"
            label={intl.formatMessage({ id: 'Canceled' })}
            value="canceled"
            checked={selectedStatus === StatusSelection.CANCELED}
            onChange={onChange}
            gap="xl"
            cursor="pointer"
            mt={16}
          />
        </Box>
      </Box>

      <Flex justify="center" gapX={16} px={40} py="xl" pt={['xl', 100]}>
        <Button variant="outlined" fullWidth onClick={handleClose}>
          <FormattedMessage id="cancel" />
        </Button>

        <Button variant="contained" fullWidth onClick={handleConfirm}>
          <FormattedMessage id="confirm" />
        </Button>
      </Flex>
    </Flex>
  )
}

export default PostponeCancelForm
