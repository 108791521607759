import { Flex } from '@sofascore/ui'
import styled from 'styled-components'

import { colorTokens } from 'styles/color'

export const PickerContainer = styled.div`
  width: 100%;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  &,
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
`

export const PickerInner = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  padding: 0 20px;

  font-size: 12px;

  mask-image: linear-gradient(to top, transparent, transparent 5%, white 20%, white 80%, transparent 95%, transparent);
`

export const PickerHighlight = styled(Flex)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, 0, 0); // z-index fix for Safari

  width: 100%;
  display: flex;
  justify-content: center;

  /**
  * Pointer events are disabled.
  Column inputs are focused by clicking on the currently selected item in the PickerColumn.
  */
  pointer-events: none;
`

export const InputFrame = styled(Flex)`
  justify-content: center;
  align-items: flex-end;

  padding: 0 6px;

  border-radius: 8px;
`

export const ColumnInput = styled.input`
  height: 100%;
  border: none;
  padding: 0;
  background: none;
  font-size: 26px;
  font-weight: bold;
  font-family: inherit;
  text-align: center;

  &::selection {
    color: white;
    background: ${colorTokens.primary.selected};
  }

  &:focus-visible {
    outline: none;
  }

  /* Remove number incrementers */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  appearance: textfield;
  -moz-appearance: textfield;
`
