import { Box, Flex, Text } from '@sofascore/ui'
import { Player, PlayerCard } from 'entities/Player'
import { FormattedMessage, useIntl } from 'react-intl'
import { WithSwipeToDelete, PermissionGuard } from 'shared/ui'
import IconDelete from '@sofascore/ui/dist/modules/Icons/IconDelete'
import { useToast } from 'shared/lib'
import { EntityType } from 'entities/EntityType'
import { useState } from 'react'
import { Team } from 'entities/Team'
import { PermissionAction } from 'entities/Permission'
import { usePermissions } from 'shared/hooks'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'
import { FirebaseEventName, FirebaseEventType } from 'shared/lib/firebase/model'

import { IconButton } from 'components/IconButton'
import { createUserSuggestion } from 'api'
import { RELEASED_TRANSFER_TYPE } from 'modules/Teams/components/Dialogs/RemovePlayer'
import ResponsivePopUp from 'components/ResponsivePopUp'

import { PositionGroup } from '../interface'
import RemovePlayerDialog from './RemovePlayerDialog'
import { PlayerForm } from '../PlayerForm'

interface Props {
  team: Team
  positionGroups: PositionGroup[]
  revalidatePlayers: () => void
}

const ApprovedPlayers = ({ team, positionGroups, revalidatePlayers }: Props) => {
  const intl = useIntl()
  const { enqueueToast } = useToast()
  const { guardEntity } = usePermissions()

  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false)
  const [playerToBeRemoved, setPlayerToBeRemoved] = useState<Player>()

  const [clickedPlayer, setClickedPlayer] = useState<Player>()

  const responsivePopupClose = (discarded?: boolean | undefined) => {
    if (discarded) {
      logFirebaseEvent(FirebaseEventName.DiscardEntity, {
        type: FirebaseEventType.Player,
        id: clickedPlayer?.id,
        sport: team.sport.name,
      })
    } else {
      logFirebaseEvent(FirebaseEventName.SubmitEntity, {
        type: FirebaseEventType.Player,
        id: clickedPlayer?.id,
        sport: team.sport.name,
      })
    }
    setClickedPlayer(undefined)
  }

  const removePlayer = async (playerId: number) => {
    try {
      createUserSuggestion(EntityType.PLAYER, playerId, {
        editor: true,
        transfer: { type: RELEASED_TRANSFER_TYPE, timestamp: Math.round(new Date().getTime() / 1000) },
      })

      enqueueToast(
        `${intl.formatMessage({ id: 'requestSent' })} ${intl.formatMessage({ id: 'suggestionSentMessage' })}`,
        { variant: 'success' },
      )

      logFirebaseEvent(FirebaseEventName.DeleteEntity, {
        type: FirebaseEventType.Player,
        id: playerId,
        sport: team.sport.name,
      })
    } catch (e) {
      enqueueToast(intl.formatMessage({ id: 'toastError' }), { variant: 'error' })
      console.error(e)
    }
  }

  const handleRemoveClick = async (player: Player) => {
    setPlayerToBeRemoved(player)
    setIsRemoveDialogOpen(true)
  }

  const handleRemoveConfirm = () => {
    if (playerToBeRemoved) {
      removePlayer(playerToBeRemoved.id)
    }

    setIsRemoveDialogOpen(false)
    revalidatePlayers()
  }

  const handlePlayerClick = (player: Player) => {
    setClickedPlayer(player)
  }

  if (positionGroups.length === 0) {
    return (
      <Flex direction="column" align="center" mt={100}>
        <Text fontSize="large" fontWeight="bold">
          <FormattedMessage id="no_approved_requests" />
        </Text>

        <Text mt="lg" align="center" color="onSurface.nLv3">
          <FormattedMessage id="team_no_approved_players" />
        </Text>
      </Flex>
    )
  }

  return (
    <Flex direction="column" mt="sm" bg="surface.s1" br="sm" pb="sm" elevation={1}>
      {positionGroups.map(group => {
        return (
          <>
            <Flex key={group.positionMsgId} h={48} align="center" px="lg">
              <Text fontSize="small" fontWeight="bold">
                <FormattedMessage id={group.positionMsgId} />
              </Text>
            </Flex>

            {group.players.map(player => (
              <WithSwipeToDelete
                key={player.id}
                onSwipe={() => handleRemoveClick(player)}
                disabled={!guardEntity(player.allowedActions, [PermissionAction.Delete])}
              >
                <Box py="sm" px="lg" hoverBg="surface.s2" cursor="pointer" onClick={() => handlePlayerClick(player)}>
                  <PlayerCard player={player} showJerseyNumber>
                    <PermissionGuard
                      entityAllowedActions={player.allowedActions}
                      checkAllowedActions={[PermissionAction.Delete]}
                    >
                      <IconButton
                        w={34}
                        h={34}
                        br="5px"
                        p="5px"
                        hoverBg="onSurface.nLv5"
                        onClick={e => {
                          e?.stopPropagation()
                          handleRemoveClick(player)
                        }}
                      >
                        <IconDelete />
                      </IconButton>
                    </PermissionGuard>
                  </PlayerCard>
                </Box>
              </WithSwipeToDelete>
            ))}
          </>
        )
      })}

      {playerToBeRemoved && (
        <RemovePlayerDialog
          isOpen={isRemoveDialogOpen}
          playerName={playerToBeRemoved.name}
          onClose={() => {
            setPlayerToBeRemoved(undefined)
            setIsRemoveDialogOpen(false)
          }}
          handleConfirm={handleRemoveConfirm}
        />
      )}

      <ResponsivePopUp
        isOpen={!!clickedPlayer}
        heading={
          <Text fontSize="large" fontWeight="bold">
            <FormattedMessage id="playerInfo" />
          </Text>
        }
        onClose={responsivePopupClose}
      >
        <PlayerForm
          player={clickedPlayer}
          team={team}
          handleClose={responsivePopupClose}
          onConfirm={revalidatePlayers}
        />
      </ResponsivePopUp>
    </Flex>
  )
}

export default ApprovedPlayers
