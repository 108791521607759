import { StatusCode } from 'entities/Status'
import { PERIOD1, PERIOD2, PERIOD3, PERIOD4, PERIOD5, PeriodType } from 'entities/Score'

export const waterPoloStatusCodes = [
  StatusCode['Not started'],
  StatusCode['1st quarter'],
  StatusCode['2nd quarter'],
  StatusCode['3rd quarter'],
  StatusCode['4th quarter'],
  StatusCode.Pause,
  StatusCode['Awaiting extra time'],
  StatusCode.Overtime,
  StatusCode['Awaiting penalties'],
  StatusCode.Penalties,
  StatusCode.Ended,
  StatusCode['AET'],
  StatusCode['AP'],
  StatusCode.Canceled,
  StatusCode.Postponed,
  StatusCode.Interrupted,
]

export const volleyballStatusCodes = [
  StatusCode['Not started'],
  StatusCode['1st set'],
  StatusCode['2nd set'],
  StatusCode['3rd set'],
  StatusCode['4th set'],
  StatusCode['5th set'],
  StatusCode.Pause,
  StatusCode.Ended,
  StatusCode.Canceled,
  StatusCode.Postponed,
  StatusCode.Interrupted,
]

export const basketballStatusCodes = [
  StatusCode['Not started'],
  StatusCode['1st quarter'],
  StatusCode['2nd quarter'],
  StatusCode['3rd quarter'],
  StatusCode['4th quarter'],
  StatusCode.Pause,
  StatusCode['Awaiting extra time'],
  StatusCode.Overtime,
  StatusCode.Ended,
  StatusCode.AET,
  StatusCode.Interrupted,
]

export const volleyballPeriods: PeriodType[] = [PERIOD1, PERIOD2, PERIOD3, PERIOD4, PERIOD5]
