import { useState } from 'react'
import { Box, Flex, GraphicEmptyStateAddItem, Text } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'

import { Button } from 'components/Button'
import AddNewCompetition from 'modules/MyCompetitions/components/AddNewCompetition'

/**
 * Shown when there is no available competitions for logged in user
 */
const EmptyCompetitions = () => {
  const [isOpen, setIsOpen] = useState(false)

  // Handling `AddNewCompetition` open and close
  const handleOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Flex maxW="300px" direction="column" align="center" justify="center" m="0 auto">
        <Box mb="lg">
          <GraphicEmptyStateAddItem />
        </Box>
        <Text font="display.medium" color="onSurface.nLv1" align="center" mb="sm">
          <FormattedMessage id="no_competitions_managed_header" />
        </Text>
        <Text font="body.mediumParagraph" color="onSurface.nLv3" align="center" mb="xl">
          <FormattedMessage id="no_competitions_managed_text" />
        </Text>
        <Button variant="contained" onClick={handleOpen}>
          <FormattedMessage id="add_competition" />
        </Button>
      </Flex>

      <AddNewCompetition isOpen={isOpen} handleClose={handleOpen} />
    </>
  )
}

export default EmptyCompetitions
