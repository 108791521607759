import { useEffect, useState } from 'react'
import { Box, Button, Flex, Text } from '@sofascore/ui'
import IconChevronRight from '@sofascore/ui/dist/modules/Icons/_IconChevronRight'
import IconChevronLeft from '@sofascore/ui/dist/modules/Icons/_IconChevronLeft'
import { FormattedMessage } from 'react-intl'
import { OnArgs } from 'react-calendar'
import useSWR from 'swr'
import { Value } from 'react-calendar/dist/cjs/shared/types'

import { isOnSameDay, isToday } from 'utils/time'
import { daysWithEditorEvents } from 'api/routes'
import { EditorDaysWithEventsByMonth } from 'components/DateTimePickers/interface'

import * as S from './styles'

export interface CalendarProps {
  date: Date | undefined
  onChange: (date: Date) => void
}

export default function HomeDateTimePickerCalendar({ onChange, date }: CalendarProps) {
  const [activeMonth, setActiveMonth] = useState<Date | undefined>()

  const [month, setMonth] = useState(date ? `${date.getMonth() + 1}` : null)
  const [year, setYear] = useState(date ? `${date.getFullYear()}` : null)

  const { data } = useSWR<EditorDaysWithEventsByMonth>(month && year ? daysWithEditorEvents(year, month) : null)

  useEffect(() => {
    setActiveMonth(date)
  }, [date])

  useEffect(() => {
    if (activeMonth) {
      setMonth(`${activeMonth.getMonth() + 1}`)
      setYear(`${activeMonth.getFullYear()}`)
    }
  }, [activeMonth])

  return (
    <>
      <S.Calendar
        showNeighboringMonth={false}
        activeStartDate={activeMonth}
        formatDay={() => ''}
        navigationLabel={({ label }: { label: string }) => <Text font="display.medium">{label}</Text>}
        locale="en"
        nextLabel={<IconChevronRight fill="primary.default" />}
        next2Label={null}
        prevLabel={<IconChevronLeft fill="primary.default" />}
        prev2Label={null}
        minDetail="decade"
        value={date}
        onChange={(val: Value) => onChange(val as Date)}
        onActiveStartDateChange={({ activeStartDate }: OnArgs) => {
          if (activeStartDate) {
            setActiveMonth(activeStartDate)
          }
        }}
        tileContent={p => {
          const isSelected = date ? isOnSameDay(date.getTime(), p.date.getTime()) : false

          const hasDateEvents = data?.daysWithEvents?.includes(p.date.getDate())

          return (
            <>
              <Flex
                data-testid={isSelected ? 'selected-date' : 'unselected-date'}
                className={isSelected ? 'selected' : undefined}
                position="relative"
                justify="center"
                align="center"
                grow={0}
                shrink={0}
                bg={isSelected ? 'primary.default' : isToday(p.date) ? 'primary.highlight' : undefined}
                w={['xxl', 'xl', 'xxl']}
                h={32}
                br="lg"
                cursor="pointer"
              >
                <Text
                  fontSize="small"
                  fontWeight="medium"
                  color={isSelected ? 'surface.s1' : 'onSurface.nLv1'}
                  lineHeight={1}
                >
                  {p.date.getDate()}
                </Text>

                {hasDateEvents && <Box w={4} h={4} position="absolute" bottom={2} bg="primary.default" br="lg" />}
              </Flex>
            </>
          )
        }}
      />

      <Flex borderTop="onSurface.nLv4" p="lg" mt="lg" justify="space-between" align="center">
        <Button
          tertiary
          onClick={() => {
            const today = new Date()
            onChange(today)
            setActiveMonth(today)
          }}
        >
          <FormattedMessage id="editor_calendar_today" />
        </Button>

        <Flex align="center">
          <Box w={5} h={5} bg="primary.default" br="lg" />

          <Text pl="sm">
            <FormattedMessage id="calendar_legend" />
          </Text>
        </Flex>
      </Flex>
    </>
  )
}
