import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Flex, Text } from '@sofascore/ui'

import { IconEU } from 'components/Icons/IconEU'
import { IconEUConcurrency } from 'components/Icons/IconEUConcurrency'
import { IconEUStructure } from 'components/Icons/IconEUStructure'
import { selectLocale } from 'store/selectors'

import { getLandingPageURLPath } from '../../utils'
import * as S from '../styles'

export const DesktopEULabel = () => {
  const selectedLocale = useSelector(selectLocale)

  const euHref = `https://editor.sofascore.com${getLandingPageURLPath(selectedLocale)}`

  return (
    <S.Link href={euHref} target="_blank" rel="noopener noreferrer">
      <Flex justify="center" py="md">
        <Flex direction="column" gapY="lg">
          <Flex align="center" gapX="xxl">
            <Flex gapX="xs" shrink={0}>
              <IconEU />
              <Flex direction="column" maxW={84}>
                <Text fontSize={10} lineHeight={1.5} color="inherit">
                  <FormattedMessage id="europeanUnion" />
                </Text>
                <Text fontSize={10} lineHeight={1.5} color="inherit">
                  <FormattedMessage id="euTogetherTowardsFunds" />
                </Text>
              </Flex>
            </Flex>

            <Text fontSize={10} lineHeight={1.5} color="inherit" letterSpacing="-0.05px" maxW={164}>
              <FormattedMessage id="euRegionalFunds" />
            </Text>

            <IconEUConcurrency />

            <IconEUStructure />
          </Flex>

          <Text fontWeight={700} lineHeight={1.5} color="inherit">
            <FormattedMessage id="sofaEuCopyright" />
          </Text>
        </Flex>
      </Flex>
    </S.Link>
  )
}
