import { Box, Text } from '@sofascore/ui'
import { useState } from 'react'
import IconChevronRight16 from '@sofascore/ui/dist/modules/Icons/IconChevronRight16'
import { FormattedMessage, useIntl } from 'react-intl'
import useSWR from 'swr'
import { useNavigate } from 'react-router-dom'
import { StatusType, isSpecialStatus } from 'entities/Status'
import { useEventState } from 'entities/Event'
import { PermissionAction } from 'entities/Permission'
import { PermissionGuard } from 'shared/ui'

import { RED } from 'styles/color'
import ResponsivePopUp from 'components/ResponsivePopUp'
import { eventDetails } from 'api/routes'

import MatchActionButton from './MatchActionButton'
import PostponeCancelForm from './PostponeCancelForm'

/**
 * Action buttons for newly redesigned sports that do not have Edit Live and Postmatch fully implemented,
 * but a simple "Edit" tab.
 */
export const MatchActionButtonsReduced = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const { initialEvent, event } = useEventState()
  const { id, tournament, status, season } = initialEvent
  const { mutate: mutateEventDetails } = useSWR(eventDetails(id))

  const [isPopUpOpen, setPopUpOpen] = useState(false)
  const togglePopUp = () => setPopUpOpen(!isPopUpOpen)

  const notStarted = status.type === StatusType.NOT_STARTED

  const handlePopUpClose = () => {
    setPopUpOpen(false)
  }

  const revalidateEvent = () => {
    mutateEventDetails()
  }

  return (
    <PermissionGuard entityAllowedActions={event.allowedActions} checkAllowedActions={[PermissionAction.Update]}>
      <Box mt="md">
        {!isSpecialStatus(status.code) && (
          <>
            {/* Cancelation Actions */}
            <Box mt="sm">
              {notStarted && (
                <MatchActionButton
                  label={intl.formatMessage({ id: 'postpone_cancel_match' })}
                  endingContent={<IconChevronRight16 fill={RED.incident} />}
                  textColor={RED.incident}
                  onClick={togglePopUp}
                />
              )}
            </Box>
          </>
        )}

        {isSpecialStatus(status.code) && tournament.uniqueTournament && (
          <MatchActionButton
            label={intl.formatMessage({ id: 'create_new_match' })}
            endingContent={<IconChevronRight16 />}
            onClick={() =>
              navigate(`/my-competitions/${tournament.uniqueTournament!.id}/season/${season!.id}/matches`, {
                state: { shouldOpenForm: true },
              })
            }
          />
        )}

        <ResponsivePopUp
          isOpen={isPopUpOpen}
          onClose={togglePopUp}
          heading={
            <Text fontWeight="bold" fontSize={18}>
              <FormattedMessage id="postpone_cancel_match" />
            </Text>
          }
        >
          <PostponeCancelForm handleClose={handlePopUpClose} revalidateEvent={revalidateEvent} />
        </ResponsivePopUp>
      </Box>
    </PermissionGuard>
  )
}
