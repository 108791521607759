import { Flex } from '@sofascore/ui'
import { useEffect, useState } from 'react'
import { IncidentActionType, IncidentType, useEventIncidentsDispatch, useEventIncidentsState } from 'entities/Incident'
import { EventActionType, useEventDispatch, useEventState } from 'entities/Event'
import { AnimatePresence, motion } from 'framer-motion'
import { NOT_STARTED_CODE, PENALTIES_CODE, STARTED } from 'entities/Status'
import { AddRugbyPointsForm } from 'features/AddScore'
import {
  AddRugbySubstitution as AddSubstitution,
  AddRugbyRedCard as AddRedCard,
  AddRugbyYellowCard as AddYellowCard,
  AddRugbyYellowRedCard as AddYellowRedCard,
  AddRugbyShootoutPenalty as AddShootoutPenalty,
  useLiveMatchCreateIncidentHandler,
} from 'features/AddIncident'
import { useLiveMatchEditIncidentHandler } from 'features/EditIncident'
import { useLiveMatchDeleteIncidentHandler } from 'features/DeleteIncident'
import { GameTimeToggle } from 'widgets/GameTimeToggle'
import { ChangeScoreSection } from 'widgets/ChangeScoreSection'
import { AddIncidentSection } from 'widgets/AddIncidentSection'
import { IncidentListSection } from 'widgets/IncidentListSection'
import { PenaltyShootoutSection } from 'widgets/PenaltyShootoutSection'
import { RugbyEditPeriodSection } from 'widgets/EditPeriodSection'
import { RugbyIncidentRenderer } from 'widgets/IncidentRenderer'

import { PeriodTabs } from '../../model'

const defaultNumberOfPenaltySeries = 5

export const RugbyEditLiveMatch = () => {
  const { incidents } = useEventIncidentsState()
  const { event } = useEventState()
  const { status } = event
  const isPenaltyShootout = status?.code === PENALTIES_CODE

  const [periodTab, setPeriodTab] = useState<PeriodTabs>(PeriodTabs.FT)

  const eventDispatch = useEventDispatch()
  const incidentsDispatch = useEventIncidentsDispatch()
  const { handleCreate } = useLiveMatchCreateIncidentHandler()
  const { handleEdit } = useLiveMatchEditIncidentHandler()
  const { handleDelete } = useLiveMatchDeleteIncidentHandler()

  // Remove Penalty Shootout incidents from list
  const normalTimeIncidents = incidents
    ? incidents.filter(incident => incident.incidentType !== IncidentType.PenaltyShootout && incident.text !== 'PEN')
    : []

  const penaltyShootoutIncidents = incidents
    ? incidents.filter(incident => incident.incidentType === IncidentType.PenaltyShootout)
    : []

  const hasCrowdsourcedEventStarted = status.code === STARTED
  const hasStarted = status.code !== NOT_STARTED_CODE && !hasCrowdsourcedEventStarted

  useEffect(() => {
    isPenaltyShootout ? setPeriodTab(PeriodTabs.Penalties) : setPeriodTab(PeriodTabs.FT)
  }, [isPenaltyShootout])

  // Crowdsourced data should be ignored and editor will edit score and incidents from scratch
  useEffect(() => {
    if (event.crowdsourcingDataDisplayEnabled) {
      eventDispatch({ type: EventActionType.RESET_SCORES })
      incidentsDispatch({ type: IncidentActionType.DELETE_ALL_INCIDENTS })
    }
  }, [event.crowdsourcingDataDisplayEnabled, eventDispatch, incidentsDispatch])

  return (
    <Flex direction="column" gapY="sm" pb="sm">
      <RugbyEditPeriodSection />

      <AnimatePresence>
        {hasStarted && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
          >
            {isPenaltyShootout && (
              <GameTimeToggle
                isPenaltyShootout={periodTab === PeriodTabs.Penalties}
                onRegularTimeButtonClick={() => setPeriodTab(PeriodTabs.FT)}
                onPenaltyShootoutButtonClick={() => setPeriodTab(PeriodTabs.Penalties)}
              />
            )}

            {periodTab === PeriodTabs.FT && (
              <>
                <ChangeScoreSection>
                  {(isOpen, toggleOpen, team, event) => (
                    <AddRugbyPointsForm
                      key={isOpen.toString()}
                      isOpen={isOpen}
                      team={team}
                      event={event}
                      onSubmit={handleCreate}
                      onClose={toggleOpen}
                    />
                  )}
                </ChangeScoreSection>

                <AddIncidentSection>
                  <AddSubstitution onCreate={handleCreate} />
                  <AddYellowCard onCreate={handleCreate} />
                  <AddRedCard onCreate={handleCreate} />
                  <AddYellowRedCard onCreate={handleCreate} />
                </AddIncidentSection>

                <IncidentListSection incidents={normalTimeIncidents}>
                  {(incident, isLastGoal) => (
                    <RugbyIncidentRenderer
                      key={incident.id}
                      incident={incident}
                      isLastGoal={isLastGoal}
                      onEdit={handleEdit}
                      onDelete={handleDelete}
                    />
                  )}
                </IncidentListSection>
              </>
            )}

            {periodTab === PeriodTabs.Penalties && (
              <>
                <PenaltyShootoutSection numberOfPenaltySeries={defaultNumberOfPenaltySeries}>
                  {(home, homeTeam, away, awayTeam, sequence) => (
                    <>
                      <AddShootoutPenalty team={homeTeam} sequence={sequence} {...home} onSubmit={handleCreate} />
                      <AddShootoutPenalty team={awayTeam} sequence={sequence} {...away} onSubmit={handleCreate} />
                    </>
                  )}
                </PenaltyShootoutSection>

                <Flex direction="column" gapY="sm" mt="sm">
                  {penaltyShootoutIncidents.map((pen, index) => (
                    <RugbyIncidentRenderer
                      key={pen.id}
                      incident={pen}
                      isLastGoal={index === 0}
                      onEdit={handleEdit}
                      onDelete={handleDelete}
                    />
                  ))}
                </Flex>
              </>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </Flex>
  )
}
