import React, { Dispatch, ReactNode, useReducer } from 'react'
import { FormattedMessage } from 'react-intl'

export interface ConfirmDialogPayload {
  titleTextElement?: ReactNode
  messageTextElement: ReactNode
  confirmTextElement?: ReactNode
  closeTextElement?: ReactNode
}

interface ConfirmDialogAction {
  type: string
  payload?: ConfirmDialogPayload
}

interface ConfirmDialogState {
  show: boolean
  titleTextElement: ReactNode
  messageTextElement: ReactNode
  confirmTextElement: ReactNode
  closeTextElement?: ReactNode
}

interface ConfirmDialogProps {
  state: ConfirmDialogState
  dispatch: Dispatch<ConfirmDialogAction>
}

export const SHOW_CONFIRM = 'SHOW_CONFIRM'
export const HIDE_CONFIRM = 'HIDE_CONFIRM'

export const initialState = {
  show: false,
  titleTextElement: '',
  messageTextElement: '',
  confirmTextElement: <FormattedMessage id="yes" />,
  closeTextElement: <FormattedMessage id="no" />,
}

export const reducer = (state: ConfirmDialogState = initialState, action: ConfirmDialogAction) => {
  switch (action.type) {
    case SHOW_CONFIRM:
      return {
        ...state,
        ...action.payload,
        show: true,
      }
    case HIDE_CONFIRM:
      return initialState
    default:
      return initialState
  }
}

export const ConfirmContext = React.createContext<ConfirmDialogProps>({ state: initialState, dispatch: () => null })

export const ConfirmContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <ConfirmContext.Provider value={{ state: state, dispatch: dispatch }}>{children}</ConfirmContext.Provider>
}
