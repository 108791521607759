import { BasicCategory } from '../../Category/model'

/**
 * Returns Competition country flag
 */
export const getCompetitionCountryFlag = (category: BasicCategory) => {
  let countryFlag = '/images/placeholders/uniqueTournament.png'

  if (category?.alpha2) {
    countryFlag = `/images/flags/${category.alpha2.toLowerCase()}.png`
  } else if (category?.flag) {
    countryFlag = `/images/flags/${category.flag.toLowerCase()}.png`
  }

  return countryFlag
}
