import { Incident, IncidentType } from 'entities/Incident'

import { IncidentForButton } from '../model'

const buttonItemObject = {
  incidentType: IncidentType.PenaltyShootout,
  time: null,
  enabled: false,
}

export const groupPenaltiesBySequence = (penalties: Incident[], numberOfPenaltySeries: number) => {
  const penaltyShootoutIncidents = penalties.filter(i => i.incidentType === IncidentType.PenaltyShootout)

  // #region Buttons logic
  // At the start we create default number of buttons
  const homeTeamButtonMap: IncidentForButton[] = Array(numberOfPenaltySeries).fill(buttonItemObject)
  const awayTeamButtonMap: IncidentForButton[] = Array(numberOfPenaltySeries).fill(buttonItemObject)
  const isLastPenaltyIncidentHomeTeam = penaltyShootoutIncidents[0]?.isHome

  if (penaltyShootoutIncidents.length < 1) {
    // If no incidents, enable first buttons for both teams
    homeTeamButtonMap[0] = { ...homeTeamButtonMap[0], enabled: true }
    awayTeamButtonMap[0] = { ...awayTeamButtonMap[0], enabled: true }
  } else {
    // Else map incidents to buttons
    // By default Incidents are sorted from last to first, but here we need from first to last
    let homeTeamButtonMapIndex = 0
    let awayTeamButtonMapIndex = 0
    penaltyShootoutIncidents.reverse().map(incident => {
      if (incident.isHome === true) {
        homeTeamButtonMap[homeTeamButtonMapIndex] = incident
        homeTeamButtonMapIndex++
      } else {
        awayTeamButtonMap[awayTeamButtonMapIndex] = incident
        awayTeamButtonMapIndex++
      }
    })

    // Add buttons if more than default number of buttons
    if (
      homeTeamButtonMapIndex >= numberOfPenaltySeries &&
      awayTeamButtonMapIndex >= numberOfPenaltySeries &&
      homeTeamButtonMapIndex === awayTeamButtonMapIndex
    ) {
      homeTeamButtonMap.push(buttonItemObject)
      awayTeamButtonMap.push(buttonItemObject)
    }

    // Enable next button
    if (isLastPenaltyIncidentHomeTeam) {
      awayTeamButtonMap[awayTeamButtonMapIndex] = { ...awayTeamButtonMap[awayTeamButtonMapIndex], enabled: true }
    } else {
      homeTeamButtonMap[homeTeamButtonMapIndex] = { ...homeTeamButtonMap[homeTeamButtonMapIndex], enabled: true }
    }
  }

  return Array(homeTeamButtonMap.length)
    .fill(undefined)
    .map((_, index) => [homeTeamButtonMap[index], awayTeamButtonMap[index]])
}
