import { Flex, Spinner, Text } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'

import { EntityFilter } from '../model'
import { useGroupedTeamSuggestions } from '../hooks'
import { MyCompetitionTeamSuggestion } from './MyCompetitionTeamSuggestion'

export const MyCompetitionDeclinedCupTeams = () => {
  const { data, error, isLoading } = useGroupedTeamSuggestions(EntityFilter.DECLINED)

  if (isLoading) {
    return (
      <Flex justify="center" mt={100}>
        <Spinner width="40px" />
      </Flex>
    )
  }

  if (!data?.groups.length) {
    return (
      <Flex direction="column" align="center" gapY="lg" pt={128}>
        <Text font="display.medium">
          <FormattedMessage id="no_declined_requests" />
        </Text>
        <Text font="body.medium" color="onSurface.nLv3">
          <FormattedMessage id="declined_requests_team_empty_text" />
        </Text>
      </Flex>
    )
  }

  if (error) return null

  const groups = data.groups

  return (
    <>
      {groups.map(group => (
        <Flex key={group.tournament.id} direction="column" bg="surface.s1" elevation={2} br="sm" mt="sm" pb="sm">
          <Flex justify="space-between" align="center" py={12} px="lg" h={54}>
            <Text font="display.micro">{group.tournament.name}</Text>
          </Flex>
          <Flex direction="column">
            {group.suggests
              .sort((s1, s2) => s1.name.localeCompare(s2.name))
              .map(suggest => (
                <MyCompetitionTeamSuggestion key={suggest.id} suggestion={suggest} />
              ))}
          </Flex>
        </Flex>
      ))}
    </>
  )
}
