import { useCallback, useEffect, useState } from 'react'
import { motion, useAnimationControls, useMotionValue } from 'framer-motion'
import { Box, Flex } from '@sofascore/ui'
import IconDelete from '@sofascore/ui/dist/modules/Icons/IconDelete'
import { BasicEvent } from 'entities/Event'
import noop from 'lodash/noop'
import { PermissionAction } from 'entities/Permission'
import { usePermissions } from 'shared/hooks'

import { RED } from 'styles/color'

import * as S from './styles'
import { EVENT_CELL_HEIGHT, SWIPE_TO_DELETE_THRESHOLD } from './config'
import { CellTail, ConfirmDeletionDialog, ScoreSection, StatusSection } from './components'
import EventLink from './components/EventLink'

interface EventCellProps {
  event: BasicEvent
  handleDeleteEvent: () => Promise<void>
  swipeToDelete?: boolean
  navState?: unknown
}

const EventCell = ({ event, navState, handleDeleteEvent, swipeToDelete }: EventCellProps) => {
  const [isUnderlayVisible, setUnderlayVisible] = useState(false) // for preventing underlay flash

  const swipeXPosition = useMotionValue(0)
  const animateDeleteIcon = useAnimationControls()
  const [isDeletePossible, setDeletePossible] = useState(false)
  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)
  const [isDeleting, setDeleting] = useState(false)
  const cellPointerEvents = useMotionValue('auto')
  const { guardEntity } = usePermissions()

  const isLocked = event.feedLocked
  const canDelete = guardEntity(event.allowedActions, [PermissionAction.Delete])

  const handleDeleteConfirmation = () => {
    setDeleting(true)
    handleDeleteEvent().finally(() => {
      setDeleting(false)
      setDeleteConfirmationOpen(false)
    })
  }

  const onDrag = () => {
    // x value is negative left of the original position
    if (-swipeXPosition.get() >= SWIPE_TO_DELETE_THRESHOLD) {
      setDeletePossible(true)
    } else {
      setDeletePossible(false)
    }
  }

  const onDragEnd = () => {
    // Reset delete icon
    animateDeleteIcon.stop()
    animateDeleteIcon.start({ scale: 1 })

    if (isDeletePossible) {
      setDeleteConfirmationOpen(true)
    }
  }

  const disableCellPointerEvents = useCallback(() => {
    cellPointerEvents.set('none')
  }, [cellPointerEvents])

  const enableCellPointerEvents = useCallback(() => {
    cellPointerEvents.set('auto')
  }, [cellPointerEvents])

  /**
   * Disable mouse click events on the event cell until the swiped cell returns back to original position.
   *
   * Disabling mouse click events has two purposes:
   * 1. Prevents from clicking and entering event details while trying to delete the event swiping it.
   * 2. Prevents user from stopping the cell slide animation from returning to the original position.
   */
  useEffect(() => {
    if (swipeToDelete) {
      swipeXPosition.onChange(latest => {
        if (latest !== 0) {
          disableCellPointerEvents()
        } else {
          enableCellPointerEvents()
        }
      })
    }

    // https://www.framer.com/motion/motionvalue/###destroy
  }, [swipeToDelete, swipeXPosition, disableCellPointerEvents, enableCellPointerEvents])

  useEffect(() => {
    if (isDeleting) disableCellPointerEvents()
    else enableCellPointerEvents()
  }, [isDeleting, disableCellPointerEvents, enableCellPointerEvents])

  // Indicate the delete action with animation
  useEffect(() => {
    if (isDeletePossible) {
      animateDeleteIcon.start({ scale: 1.15 })
    } else {
      animateDeleteIcon.stop()
      animateDeleteIcon.set({ scale: 1 })
    }
  }, [isDeletePossible, animateDeleteIcon])

  return (
    <Box id={event.id.toString()} position="relative">
      {/* Event Cell */}
      <S.StyledMotionDiv
        onLoad={() => setUnderlayVisible(true)} // prevents underlay flash (loading underlay first)
        drag={swipeToDelete && !isLocked && canDelete ? 'x' : false}
        dragConstraints={{ left: 0, right: 0 }}
        dragMomentum={false}
        dragElastic={{ left: 0.8, right: 0 }}
        dragSnapToOrigin
        onDrag={onDrag}
        onDragEnd={onDragEnd}
        style={{ x: swipeXPosition, pointerEvents: cellPointerEvents }}
      >
        <EventLink event={event} navState={navState}>
          <Flex w="100%" h={EVENT_CELL_HEIGHT} hoverBg="surface.s2" cursor="pointer" py={6} userSelect="none">
            <StatusSection event={event} />

            <ScoreSection event={event} />

            {/* Followers / Delete */}
            <CellTail
              event={event}
              swipeToDelete={swipeToDelete}
              isDeleting={isDeleting}
              setDeleteConfirmationOpen={isLocked ? noop : setDeleteConfirmationOpen}
            />
          </Flex>
        </EventLink>
      </S.StyledMotionDiv>

      {/* Delete Underlay */}
      {swipeToDelete && isUnderlayVisible && (
        <Flex
          w="100%"
          h="100%"
          justify="flex-end"
          align="center"
          pr="md"
          position="absolute"
          top="0"
          backgroundColor={isDeletePossible ? RED.incident : 'onSurface.nLv3'}
          style={{
            transition: isDeletePossible ? 'background-color 0.1s' : 'none',
          }}
        >
          <motion.div
            animate={animateDeleteIcon}
            transition={{ type: 'spring', stiffness: 1000, damping: 15 }}
            style={{ width: 24, height: 24 }}
          >
            <IconDelete fill="surface.s1" />
          </motion.div>
        </Flex>
      )}

      <ConfirmDeletionDialog
        isOpen={isDeleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        handleConfirm={handleDeleteConfirmation}
        isDeleting={isDeleting}
      />
    </Box>
  )
}

export default EventCell
