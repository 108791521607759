import { Box, Flex, Text } from '@sofascore/ui'
import styled from 'styled-components'

export const Label = styled(Box)<{
  active?: boolean
}>`
  position: absolute;
  top: ${p => p.theme.spacing.xs};
  left: ${p => p.theme.spacing.lg};
  top: ${p => (p.active ? p.theme.spacing.xs : p.theme.spacing.md)};
  font: ${p => (p.active ? p.theme.typography.font.assistive.default : p.theme.typography.font.body.large)};

  color: ${p => p.theme.colors.onSurface.nLv3};

  user-select: none;
  transition: all 0.2s;
`

export const Wrapper = styled(Flex)<{
  isDisabled?: boolean
}>`
  position: relative;

  min-height: 48px;

  align-items: center;

  padding: ${p => `0 ${p.theme.spacing.lg}`};
  border: 1px solid ${p => p.theme.colors.onSurface.nLv4} !important;
  border-radius: ${p => p.theme.spacing.sm};
  box-sizing: border-box;

  cursor: ${p => (p.isDisabled ? 'not-allowed' : 'pointer')};

  ${p => p.isDisabled && 'opacity: 0.5;'}

  #value {
    border: none;
    background-color: transparent;
    box-sizing: border-box;
    outline: none;

    font: ${p => p.theme.typography.font.body.large};
    color: ${p => p.theme.colors.onSurface.nLv1};
  }
`

export const Value = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
`
