import { Box, Flex } from '@sofascore/ui'
import { Incident, groupIncidentsByPeriod } from 'entities/Incident'
import { ReactNode } from 'react'

import { getLastGoalsFromIncidents } from 'modules/Football/helper'

import { PeriodHeader } from './PeriodHeader'

interface IncidentListSectionProps {
  incidents: Incident[]
  children: (incident: Incident, isLastGoal: boolean) => ReactNode
}

export const IncidentListSection = ({ incidents, children }: IncidentListSectionProps) => {
  if (!incidents) {
    return null
  }

  const [lastGoal] = getLastGoalsFromIncidents(incidents)

  const groupedIncidents = incidents?.length ? groupIncidentsByPeriod(incidents) : []

  return (
    <Flex direction="column" gapY="sm" mt="sm">
      {groupedIncidents.length > 0 &&
        groupedIncidents.map(period => (
          <Box key={period.periodIncident.text}>
            <PeriodHeader period={period} />
            <Flex direction="column" gapY="sm">
              {period.incidents.map(incident => children(incident, incident.id === lastGoal?.id))}
            </Flex>
          </Box>
        ))}

      {groupedIncidents.length < 1 &&
        incidents.length > 0 &&
        incidents.map(incident => children(incident, incident.id === lastGoal?.id))}
    </Flex>
  )
}
