import { useEventState, MatchesTabTitle } from 'entities/Event'
import { SportName } from 'entities/Sport'
import { useIntl } from 'react-intl'

import { FootballMatchInfo } from './Football'
import { MiniFootballMatchInfo } from './MiniFootball'
import { RugbyMatchInfo } from './Rugby'
import { VolleyballMatchInfo } from './Volleyball'
import { WaterPoloMatchInfo } from './WaterPolo'
import { BasketballMatchInfo } from './Basketball'

const mapSportToForm: Record<string, () => JSX.Element> = {
  [SportName.Football]: FootballMatchInfo,
  [SportName.ESports]: FootballMatchInfo,
  [SportName.MiniFootball]: MiniFootballMatchInfo,
  [SportName.Futsal]: MiniFootballMatchInfo,
  [SportName.Rugby]: RugbyMatchInfo,
  [SportName.Volleyball]: VolleyballMatchInfo,
  [SportName.Waterpolo]: WaterPoloMatchInfo,
  [SportName.Basketball]: BasketballMatchInfo,
}

export const MatchInfo = () => {
  const { event } = useEventState()
  const intl = useIntl()

  const sport = event.tournament.category.sport.slug
  const MatchInfoForm = mapSportToForm[sport]

  return (
    <>
      <MatchesTabTitle title={intl.formatMessage({ id: 'info' })} />
      <MatchInfoForm />
    </>
  )
}
