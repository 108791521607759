import { Flex, Image } from '@sofascore/ui'
import { useIntl } from 'react-intl'
import { BasicTeam } from 'entities/Team'

import { teamImage } from 'api/routes'
import IncidentBox from 'components/IncidentBox'

interface AggregateWinnerPickerProps {
  homeTeam: BasicTeam
  awayTeam: BasicTeam
  winnerCode: number | null
  onChangeWinnerCode: (newCode: number | null) => void
}

export const AggregateWinnerPicker = ({
  homeTeam,
  awayTeam,
  winnerCode,
  onChangeWinnerCode,
}: AggregateWinnerPickerProps) => {
  return (
    <Flex mb="lg" px="xxl" justify="center">
      <IncidentBox
        label={useIntl().formatMessage({ id: 'no_winner' })}
        isActive={winnerCode === null}
        onClick={() => onChangeWinnerCode(null)}
        icon={null}
        mx="sm"
      />
      <IncidentBox
        label={homeTeam.name}
        isActive={winnerCode === 1}
        onClick={() => onChangeWinnerCode(1)}
        icon={<Image src={teamImage(homeTeam.id)} w={24} h={24} alt={homeTeam.name} />}
        mx="sm"
      />
      <IncidentBox
        label={awayTeam.name}
        isActive={winnerCode === 2}
        onClick={() => onChangeWinnerCode(2)}
        icon={<Image src={teamImage(awayTeam.id)} w={24} h={24} alt={awayTeam.name} />}
        mx="sm"
      />
    </Flex>
  )
}
