import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Flex, Text } from '@sofascore/ui'
import { UniqueTournamentImage } from 'entities/UniqueTournament'
import { useLocation } from 'react-router-dom'

import { EventList } from 'modules/Home/components/EventList'
import { TournamentGroup } from 'modules/Home/interface'

interface SportGroupProps {
  titleId: string
  tournaments: TournamentGroup[]
  displayFollowers?: boolean
  revalidateEventList?: () => void
}

const SportGroup = ({ titleId, tournaments, revalidateEventList }: SportGroupProps) => {
  const location = useLocation()
  const eventCount = useMemo(
    () => tournaments.reduce((count, tournament) => count + tournament.events.length, 0),
    [tournaments],
  )

  return (
    <Box pt="lg" pb="sm">
      <Flex justify="space-between" px="sm">
        <Text fontSize="small" fontWeight={500}>
          <FormattedMessage id={titleId} />
        </Text>

        {eventCount !== undefined && (
          <Text fontSize="small" color="onSurface.nLv3">
            {eventCount} <FormattedMessage id="events" />
          </Text>
        )}
      </Flex>

      {/* Sport group */}
      <Box bg="surface.s1" br="md" mt="sm" pt="md">
        {tournaments.map(tournament => {
          const tournamentName = tournament.events[0].tournament.name
          const utId = tournament.events[0].tournament.uniqueTournament?.id

          return (
            <Box key={tournament.id}>
              <Flex title={tournamentName}>
                <Flex minW={72} justify="center">
                  <UniqueTournamentImage id={utId!} name={tournamentName} w={24} h={24} />
                </Flex>

                <Flex align="center" pr="md" overflow="hidden">
                  <Text fontSize="small" fontWeight="bold" ellipsis>
                    {tournamentName}
                  </Text>
                </Flex>
              </Flex>

              <Box py="md">
                <EventList
                  events={tournament.events}
                  revalidateEventList={revalidateEventList}
                  eventLinkNavState={{ backRef: location.pathname + location.search }}
                />
              </Box>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default SportGroup
