import { useBlocker } from 'react-router-dom'

import SaveDialog from 'components/Dialog/SaveDialog'

interface Props {
  block: boolean
  onConfirm: () => Promise<void> | void
}

const RouteLeavingGuard = ({ block, onConfirm }: Props) => {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => block && currentLocation.pathname !== nextLocation.pathname,
  )

  const handleClose = () => {
    blocker.reset && blocker.reset()
  }

  const handleConfirmSave = async () => {
    await onConfirm()
    blocker.proceed && blocker.proceed()
  }

  const handleConfirmNavigate = () => {
    blocker.proceed && blocker.proceed()
  }

  return blocker.state === 'blocked' ? (
    <SaveDialog isOpen={!!block} onCancel={handleConfirmNavigate} onClose={handleClose} onSave={handleConfirmSave} />
  ) : null
}
export default RouteLeavingGuard
