import { useCallback, useEffect, useRef, useState } from 'react'
import { BasicEvent } from 'entities/Event'

import { teamUniqueTournamentEvents } from 'api/routes'
import { getJson } from 'api/utilities'

interface TeamEventList {
  events: BasicEvent[]
}

export const useTeamEvents = (teamId: number, uniqueTournamentId: number) => {
  const [currentPage, setCurrentPage] = useState(0)

  const [events, setEvents] = useState<BasicEvent[]>()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<{ error: { code: number } }>()

  const fetchEvents = useCallback(
    async (teamId: number, uniqueTournamentId: number, currentPage: number) => {
      setIsLoading(true)

      await getJson<TeamEventList>(teamUniqueTournamentEvents(teamId, uniqueTournamentId, currentPage))
        .then(response => {
          if (events && currentPage !== 0) {
            setEvents([...events, ...response.events])
          } else {
            setEvents(response.events)
          }
          setError(undefined)
        })
        .catch(err => {
          setError(err)
        })

      setIsLoading(false)
    },
    [events],
  )

  const loadNextPage = () => {
    fetchEvents(teamId, uniqueTournamentId, currentPage + 1)
    setCurrentPage(page => page + 1)
  }

  useEffect(() => {
    setEvents(undefined)
    setCurrentPage(0)
    fetchEvents(teamId, uniqueTournamentId, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, uniqueTournamentId])

  return { events, isLoading, error, loadNextPage }
}

export const useInfiniteScroll = (callback: () => void, isLoading: boolean) => {
  const observationTargetRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && !isLoading) {
          callback()
        }
      },
      { threshold: 1 },
    )

    const observationTarget = observationTargetRef.current

    if (observationTarget) {
      observer.observe(observationTarget)
    }

    return () => {
      if (observationTarget) {
        observer.unobserve(observationTarget)
      }
    }
  }, [callback, isLoading])

  return { observationTargetRef }
}
