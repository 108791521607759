import { RefObject, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavigateFunction } from 'react-router-dom'

import { googleInitParams } from 'config/google'
import { isDev } from 'utils'
import { googleLogin } from 'store/auth/actions'
import { selectLocale } from 'store/selectors'

import { useGoogle } from './useGoogle'
import { renderButton } from '../utils'

interface Props {
  divRef?: RefObject<HTMLDivElement>
  disableOneTap?: boolean
  renderOptions?: google.accounts.id.GsiButtonConfiguration
  disabled?: boolean
  navigate: NavigateFunction
}

/**
 *
 * @param divRef - RefObject which needs to be attached to div element which represents Sign In With Google button.
 *                 If divRef param is not passed, no Sign In With Google button will not be rendered,
 *                 but One Tap dialog can still be rendered.
 * @param disableOneTap - If not set, One Tap dialog is rendered if appropriate (Google internal logic).
 * @param disabled - if true, hook will do nothing
 * @returns - undefined --> no return value.
 */
export const useGoogleSignIn = ({ divRef, disableOneTap, disabled, navigate }: Props) => {
  const google = useGoogle()
  const dispatch = useDispatch()
  const selectedLocale = useSelector(selectLocale)

  const renderOptions: google.accounts.id.GsiButtonConfiguration = {
    type: 'standard',
    size: 'large',
    width: '328px',
    theme: 'outline',
    text: 'continue_with',
    locale: selectedLocale,
  }

  const handleCredentialResponse = (res: google.accounts.id.CredentialResponse) => {
    if (!res.credential) {
      console.error('Google credential response is missing JWT token.')
      return
    }
    // Do not redirect if user clicks on One Tap dialog.
    dispatch(googleLogin(navigate, res.credential))
  }

  useEffect(() => {
    if ((divRef && !divRef.current) || !google || disabled) {
      return
    }
    renderButton({
      google,
      element: divRef?.current || null,
      clientId: googleInitParams[isDev() ? 'localhostClientId' : 'clientId'],
      renderOptions,
      callback: handleCredentialResponse,
      disableOneTap: !!disableOneTap,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [google, disableOneTap, divRef])

  return { divRef }
}
