import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import { tabTitleWrapper } from 'shared/utils'

import { useMyCompetitionsState } from 'modules/MyCompetitions/providers/MyCompetitionsProvider'

const CompetitionTabTitle = ({ title }: { title: string }) => {
  const { seasonId } = useParams()
  const { uniqueTournament, competitionsWithSeasons } = useMyCompetitionsState()
  const activeSeason = competitionsWithSeasons.filter(
    uniqueTournament => uniqueTournament?.season?.id === Number(seasonId),
  )[0]

  const { name: uniqueTournamentName = '' } = uniqueTournament || {}
  const { season: { year = '' } = {} } = activeSeason || {}

  const tabTitle = `${title} - ${uniqueTournamentName} ${year}`

  return (
    <Helmet>
      <title>{tabTitleWrapper(tabTitle)}</title>
    </Helmet>
  )
}

export default CompetitionTabTitle
