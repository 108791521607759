import { Box, Flex, Text } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'
import { Badge } from 'shared/ui'
import { EditorSuggestionAction } from 'entities/Suggestion'
import { Team } from 'entities/Team'

import SuggestedPlayer from '../SuggestedPlayer'
import { getOrderedPositionGroups } from '../utils'
import { PlayerSuggestion } from '../interface'

interface Props {
  team: Team
  suggestedPlayers: PlayerSuggestion[]
}

const PendingPlayers = ({ team, suggestedPlayers }: Props) => {
  if (suggestedPlayers.length === 0) {
    return (
      <Flex direction="column" align="center" mt={100}>
        <Text fontSize="large" fontWeight="bold">
          <FormattedMessage id="no_pending_requests" />
        </Text>

        <Text mt="lg" align="center" color="onSurface.nLv3">
          <FormattedMessage id="team_no_pending_players" />
        </Text>
      </Flex>
    )
  }

  return (
    <Box bg="surface.s1" mt="sm" pb="sm" br="sm" elevation={1}>
      {getOrderedPositionGroups(
        team.sport.slug,
        suggestedPlayers.map(suggestion => suggestion.player),
      ).map(group => {
        return (
          <>
            <Flex key={group.positionMsgId} h={48} align="center" px="lg">
              <Text fontSize="small" fontWeight="bold">
                <FormattedMessage id={group.positionMsgId} />
              </Text>
            </Flex>

            {group.players.map(player => {
              const suggestionAction = suggestedPlayers.find(suggestion => suggestion.player.id === player.id)!.action

              const isCreateSuggest = suggestionAction === EditorSuggestionAction.CREATE

              return (
                <Box key={player.id} py="sm" px="lg">
                  <SuggestedPlayer player={player}>
                    <Badge variant={isCreateSuggest ? 'warning' : 'error'}>
                      <Text fontSize="micro" fontWeight="medium" color="inherit">
                        {isCreateSuggest ? (
                          <FormattedMessage id="suggestion_adding" />
                        ) : (
                          <FormattedMessage id="suggestion_removing" />
                        )}
                        ...
                      </Text>
                    </Badge>
                  </SuggestedPlayer>
                </Box>
              )
            })}
          </>
        )
      })}
    </Box>
  )
}

export default PendingPlayers
