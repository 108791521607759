import { Sport } from 'entities/Sport'
import { EntityType } from 'entities/EntityType'
import { Gender, MainHand } from 'entities/Team'
import { BasicUniqueTournament, BasicTournament } from 'entities/Tournament'

export enum EditorSuggestionAction {
  CREATE = 1,
  DELETE = 2,
}

export enum SuggestionStatus {
  STATUS_PENDING = 1,
  STATUS_APPROVED = 2,
  STATUS_DENIED = 3,
}

export interface SuggestPlayer {
  entity: string
  name: string
  sport: string
  action: EditorSuggestionAction
  uniqueTournamentId?: number
  tournamentId?: number
  valueMap: {
    imageHash?: string
    country: {
      alpha2: string
    }
    dateOfBirthTimestamp: number
    position: string
    jerseyNumber?: string
    teamId?: number
  }
}

export interface SuggestionData {
  entity: string
  name: string
  sport: string
  action: EditorSuggestionAction
  uniqueTournamentId?: number
  tournamentId: number
  valueMap: {
    seasonId?: number
    teamId?: number
    players?: SuggestPlayer[]
    imageHash?: string
    country?: {
      alpha2: string
    }
    dateOfBirthTimestamp?: number
    plays?: MainHand
    gender?: Gender
  }
}

export interface UserSuggestionData {
  editor: boolean
  name?: string
  dateOfBirthTimestamp?: number | null
  position?: string
  nationality?: string
  imageUrl?: string
  country?: string
  jerseyNumber?: string
  transfer?: {
    type: number
    timestamp: number
  }
}

export enum EditorSuggestionEntity {
  TEAMS = 'teams',
  PLAYERS = 'players',
  MATCHES = 'matches',
}

export interface EditorSuggestion {
  entity: EditorSuggestionEntity
  name: string
  status: SuggestionStatus
  action: EditorSuggestionAction
  valueMap: {
    seasonId?: number
    teamId?: number
    imageHash?: string
    country?: {
      alpha2: string
    }
    dateOfBirthTimestamp?: number
    position?: string
    teamName?: string
    gender?: Gender
    plays?: MainHand
    jerseyNumber?: string
  }
  childrenSuggestions?: EditorSuggestion[]
  id: number
  sport: Sport
  reason?: string
  teamName?: string
  uniqueTournament?: BasicUniqueTournament
  tournament?: BasicTournament
}

/**
 * Because of backend legacy code,
 * this status is not the same as SuggestionStatus
 */
export enum PlayerUpdateStatus {
  STATE_TO_REVIEW = 1,
  STATE_INVALID,
  STATE_VALID,
  STATE_IGNORED,
}

export interface PlayerUpdateSuggest {
  entity: EntityType
  entityId: number
  /**
   * This field represent status of approval after the suggest has been made.
   *
   * If the user is whitelisted and suggest is automatically approved, this inputState will hold STATE_VALID status
   */
  inputState: PlayerUpdateStatus
}
