import { FormattedMessage, useIntl } from 'react-intl'
import { Autocomplete, Box, Checkbox, Flex, Image, Input, Text } from '@sofascore/ui'
import DateTimePicker from '@sofascore/ui/dist/modules/DateTimePicker'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import useSWR, { mutate } from 'swr'
import { useSelector } from 'react-redux'
import { ImageUploadResponse } from 'shared/api'
import { CountryListResponseData } from 'entities/Country'
import { EntityType } from 'entities/EntityType'
import { useToast } from 'shared/lib'
import { SportName } from 'entities/Sport'
import {
  EditorSuggestion,
  EditorSuggestionAction,
  PlayerUpdateStatus,
  SuggestPlayer,
  SuggestionStatus,
  UserSuggestionData,
} from 'entities/Suggestion'
import { motion } from 'framer-motion'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'
import { FirebaseEventName, FirebaseEventType } from 'shared/lib/firebase/model'

import { IconSuccess } from 'components/Icons/IconSuccess'
import { countryListRoute, imageByHash, playerImage, suggestions } from 'api/routes'
import { ImageUploader } from 'components/ImageUploader'
import { Select } from 'components/Select'
import { getPlayerPositionId, getPlayerPositionOptions } from 'modules/Player/utils'
import { transformCountriesToOptionList } from 'utils/countries'
import { InfoField } from 'components/InfoField'
import { Button } from 'components/Button'
import { getUTCDate, isDateValid } from 'utils/time'
import { createPlayerSuggestion, createUserSuggestion, deleteImage, uploadImage } from 'api'
import { toBase64 } from 'utils/encode'
import { resizeImage } from 'utils/resize'
import { getAuthFromState } from 'store/selectors'

import { PlayerFormProps } from './interface'
import { ADD_ANOTHER_PLAYER_MIDSTEP_DURATION, PLAYER_IMAGE_SIZE } from './config'

/**
 * This component relies on the two contexts of the Team module, so it's placed inside that module.
 * Player creation will always be done with this form in the Teams menu.
 */
const PlayerForm = ({ player, team, handleClose, onConfirm }: PlayerFormProps) => {
  const { userAccount } = useSelector(getAuthFromState)

  const intl = useIntl()
  const { enqueueToast } = useToast()

  const modalContentRef = useRef<HTMLDivElement>(null)
  const [modalHeight, setModalHeight] = useState(0)

  const { country: teamCountry } = team
  const { data: countryListResponse } = useSWR<CountryListResponseData>(countryListRoute())

  const isNewPlayer = !player

  const [isSaving, setIsSaving] = useState(false)

  const [fullname, setFullname] = useState(player?.name)
  const [imageHash, setImageHash] = useState<string>()
  const [imageUrl, setImageUrl] = useState(player ? playerImage(player.id) : undefined)
  const [isUploadingImage, setIsUploadingImage] = useState(false)
  const [position, setPosition] = useState(player?.position)
  const [jerseyNumber, setJerseyNumber] = useState(player?.jerseyNumber?.toString() ?? '')
  const [dateOfBirthTimestamp, setDateOfBirthTimestamp] = useState(player?.dateOfBirthTimestamp)
  const [nationality, setNationality] = useState(player?.country.alpha2 || teamCountry?.alpha2)

  const [willAddAnotherPlayer, setWillAddAnotherPlayer] = useState(false)
  const [midstepSubmitMsg, setMidstepSubmitMsg] = useState<string>()

  const postImage = async (file: File) => {
    try {
      const encodedData = await toBase64(file)
      const resizedImageData = await resizeImage(encodedData as string, PLAYER_IMAGE_SIZE)
      const resp = (await uploadImage(resizedImageData as string)) as ImageUploadResponse

      setImageHash(resp.md5)
      setIsUploadingImage(false)
      enqueueToast(intl.formatMessage({ id: 'toastSuccess' }), { variant: 'success' })
    } catch (e) {
      console.error(e)
      enqueueToast(intl.formatMessage({ id: 'toastError' }), { variant: 'error' })
    }
  }

  // Input file upload handler
  const handleInputImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Check file availability
    if (!e.target.files?.[0]) {
      return
    }

    setIsUploadingImage(true)
    const file: File = e.target.files[0]

    // If profile image already exists delete it
    if (imageHash) deleteImage(imageHash)

    // Post new image
    postImage(file)
  }

  // Suggesting a new player
  const addPlayer = async () => {
    if (fullname && nationality && position && dateOfBirthTimestamp) {
      const suggestedPlayer: SuggestPlayer = {
        entity: EntityType.PLAYER,
        name: fullname,
        sport,
        action: EditorSuggestionAction.CREATE,
        valueMap: {
          country: {
            alpha2: nationality,
          },
          dateOfBirthTimestamp: getUTCDate(dateOfBirthTimestamp * 1000, { timeFormat: 's' }),
          position: position,
        },
      }

      if (imageHash) {
        suggestedPlayer.valueMap.imageHash = imageHash
      }

      if (team) {
        suggestedPlayer.valueMap.teamId = team.id
      }

      if (jerseyNumber && jerseyNumber.length) {
        suggestedPlayer.valueMap.jerseyNumber = jerseyNumber
      }

      // Set saving state to disable the submit button
      setIsSaving(true)

      // If we are editing existing team, we are creating new editor suggestion for player
      try {
        const { editorSuggestion }: { editorSuggestion: EditorSuggestion } = await createPlayerSuggestion(
          suggestedPlayer,
        )

        // If the editor is whitelisted all of it's player suggestions are automatically approved
        const isWhitelisted = editorSuggestion.status === SuggestionStatus.STATUS_APPROVED
        const approvedMsgId = isWhitelisted ? 'player_added' : 'player_request_submit'

        logFirebaseEvent(FirebaseEventName.SubmitEntity, { type: FirebaseEventType.Player, sport: team?.sport?.name })

        if (willAddAnotherPlayer) {
          resetFormState()
          setMidstepSubmitMsg(approvedMsgId)
          setTimeout(() => setMidstepSubmitMsg(undefined), ADD_ANOTHER_PLAYER_MIDSTEP_DURATION)
        } else {
          enqueueToast(intl.formatMessage({ id: approvedMsgId }), {
            variant: 'success',
          })
          handleClose()
        }

        mutate(suggestions(EntityType.PLAYER, userAccount!.id))
      } catch (e) {
        console.error(e)
        enqueueToast(intl.formatMessage({ id: 'toastError' }), { variant: 'error' })
        handleClose()
      } finally {
        onConfirm?.()
      }

      setIsSaving(false)
    }
  }

  // Send updated player's data as a user suggestion
  const updatePlayer = async () => {
    const oldPlayer = { ...player }

    const suggestionData: UserSuggestionData = { editor: true }

    if (fullname !== oldPlayer.name) {
      suggestionData.name = fullname
    }
    if (dateOfBirthTimestamp && dateOfBirthTimestamp !== oldPlayer.dateOfBirthTimestamp) {
      suggestionData.dateOfBirthTimestamp = getUTCDate(dateOfBirthTimestamp * 1000, { timeFormat: 's' })
    }
    if (position && position !== oldPlayer.position) {
      suggestionData.position = position
    }
    if (nationality && oldPlayer.country && nationality !== oldPlayer.country.alpha2) {
      suggestionData.country = nationality
    }
    if (imageHash) {
      suggestionData.imageUrl = imageUrl
    }
    if (jerseyNumber && jerseyNumber !== oldPlayer.jerseyNumber?.toString()) {
      suggestionData.jerseyNumber = jerseyNumber
    }

    try {
      setIsSaving(true)

      const { suggest } = await createUserSuggestion(EntityType.PLAYER, player!.id, suggestionData)

      // If the editor is whitelisted all of it's player suggestions are automatically approved
      const isWhitelisted = suggest.inputState === PlayerUpdateStatus.STATE_VALID
      const approvedMsgId = isWhitelisted ? 'player_updated' : 'player_request_submit'

      enqueueToast(intl.formatMessage({ id: approvedMsgId }), {
        variant: 'success',
      })
    } catch (e) {
      console.error(e)
      enqueueToast(intl.formatMessage({ id: 'toastError' }), { variant: 'error' })
    } finally {
      setIsSaving(false)
      onConfirm?.()
      handleClose()
    }
  }

  const resetFormState = () => {
    setFullname(player?.name)
    setImageHash(undefined)
    setImageUrl(player ? playerImage(player.id) : undefined)
    setIsUploadingImage(false)
    setPosition(player?.position)
    setJerseyNumber(player?.jerseyNumber?.toString() ?? '')
    setDateOfBirthTimestamp(player?.dateOfBirthTimestamp)
    setNationality(player?.country.alpha2 || teamCountry?.alpha2)
  }

  // If we aren't in edit mode we are creating new editor suggestion (for player).
  // If we are in edit mode we are creating new user suggestion
  const handleConfirm = async () => {
    if (isNewPlayer) {
      await addPlayer()
    } else {
      await updatePlayer()
    }
  }

  const sport = team.sport.slug
  const isRugby = sport === SportName.Rugby

  const isFormValid =
    fullname?.length &&
    position &&
    nationality &&
    dateOfBirthTimestamp &&
    isDateValid(new Date(dateOfBirthTimestamp * 100))

  const hasChanges =
    player &&
    (fullname !== player.name ||
      imageHash ||
      position !== player.position ||
      jerseyNumber !== player.jerseyNumber ||
      dateOfBirthTimestamp !== player.dateOfBirthTimestamp ||
      nationality !== player.country.alpha2)

  const isConfirmButtonEnabled = isNewPlayer ? isFormValid : isFormValid && hasChanges

  useEffect(() => {
    if (imageHash) setImageUrl(imageByHash(imageHash))
  }, [imageHash])

  useLayoutEffect(() => {
    const modalContent = modalContentRef.current
    if (modalContent) {
      setModalHeight(modalContent.clientHeight)
    }
  }, [midstepSubmitMsg])

  return (
    <motion.div
      animate={{ height: modalHeight }}
      transition={{ ease: 'easeOut', duration: 0.5 }}
      style={{ overflow: 'hidden' }}
    >
      {midstepSubmitMsg ? (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
          <Box ref={modalContentRef}>
            <Flex direction="column" align="center" pt="lg" pb="xxl" px="lg">
              <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ duration: 0.4, delay: 0.1 }}>
                <IconSuccess width={48} height={48} />
              </motion.div>

              <Text mt="xl" align="center">
                <FormattedMessage id={midstepSubmitMsg} />
              </Text>
            </Flex>
          </Box>
        </motion.div>
      ) : (
        <Box ref={modalContentRef}>
          <Flex direction="column" align="center" py="lg" px="lg">
            {/* Player Image */}
            <ImageUploader imageUrl={imageUrl} onImageUpload={handleInputImageUpload} isUploading={isUploadingImage} />

            {/* Player Name */}
            <Input
              label={intl.formatMessage({ id: 'fullname' })}
              required
              value={fullname}
              onChange={value => setFullname(value)}
              w="100%"
              mt="xxl"
            />

            {/* Position */}
            <Select
              label={intl.formatMessage({ id: 'position' })}
              options={getPlayerPositionOptions(sport, intl)}
              required
              value={
                position
                  ? { label: intl.formatMessage({ id: getPlayerPositionId(position, sport) }), value: position }
                  : undefined
              }
              onChange={option => setPosition(option.value)}
              w="100%"
              mt="lg"
              bg="surface.s2"
              disableClear
            />

            <Flex mt="lg" w="100%" gapX="md">
              {/* Shirt Number */}
              {!isRugby && (
                <Input
                  label={intl.formatMessage({ id: 'shirt_number' })}
                  value={jerseyNumber}
                  onChange={newValue => {
                    if (newValue.match(/^[0-9]*$/)) setJerseyNumber(newValue)
                  }}
                  regexGuard={/^[0-9]*$/}
                  maxLength={3}
                  w="calc(50% - 6px)"
                />
              )}

              {/* Date of Birth */}
              <DateTimePicker
                label={intl.formatMessage({ id: 'dateOfBirth' })}
                value={dateOfBirthTimestamp ? new Date(dateOfBirthTimestamp * 1000) : undefined}
                onChange={newDate => {
                  setDateOfBirthTimestamp(newDate.getTime() / 1000)
                }}
                required
                time={false}
                w={isRugby ? '100%' : 'calc(50% - 6px)'}
                style={{ width: '100%' }}
              />
            </Flex>

            {/* Nationality */}
            <Autocomplete
              label={intl.formatMessage({ id: 'nationality' })}
              options={transformCountriesToOptionList(countryListResponse?.countries || {})}
              value={
                countryListResponse && nationality
                  ? { label: countryListResponse.countries[nationality], value: nationality }
                  : undefined
              }
              onChange={option => {
                setNationality(option.value.toString())
              }}
              renderOption={option => (
                <Flex align="center" px="lg" py="sm">
                  <Image
                    width={20}
                    height={20}
                    mr="sm"
                    src={`/images/flags/${option.value.toString().toLowerCase()}.png`}
                  />
                  <Text color="onSurface.nLv1" font="body.large">
                    {option.label}
                  </Text>
                </Flex>
              )}
              startAdornment={
                nationality ? (
                  <Image width={20} height={20} mr="sm" src={`/images/flags/${nationality.toLowerCase()}.png`} />
                ) : null
              }
              required
              clearable={false}
              mt="lg"
              w="100%"
              bg="surface.s2"
              maxVisibleOptions={4}
            />

            {/* Player's Team */}
            {team && (
              <InfoField
                label={intl.formatMessage({ id: 'team' })}
                value={team.name}
                w="100%"
                mt="lg"
                bg="surface.s2"
                disabled
              />
            )}

            {/* Add another team checkbox */}
            {isNewPlayer && (
              <Flex align="center" mt="lg" w="100%" px="md" border="onSurface.nLv4" br="sm">
                <Checkbox
                  id="add-another-player"
                  checked={willAddAnotherPlayer}
                  px={0}
                  onChange={e => setWillAddAnotherPlayer(e.target.checked)}
                />

                <label htmlFor="add-another-player">
                  <Text>
                    <FormattedMessage id="add_another_player" />
                  </Text>
                </label>
              </Flex>
            )}

            {/* Form Buttons */}
            <Flex mt="xxl">
              <Button variant="outlined" onClick={handleClose} style={{ width: 132, height: 48, marginRight: 16 }}>
                <FormattedMessage id="cancel" />
              </Button>

              <Button
                variant="contained"
                disabled={!isConfirmButtonEnabled}
                onClick={handleConfirm}
                isLoading={isSaving}
                style={{ width: 132, height: 48 }}
              >
                <FormattedMessage id="confirm" />
              </Button>
            </Flex>
          </Flex>
        </Box>
      )}
    </motion.div>
  )
}

export default PlayerForm
