import { useIntl } from 'react-intl'

import { StandingsContainer } from 'modules/StandingsRedesign'

import MyCompetitionTabTitle from '../components/MyCompetitionTabTitle'

/**
 * This component is loaded in Outlet (as a page content) in MyCompetition.tsx
 */
const MyCompetitionStandings = () => {
  const intl = useIntl()

  return (
    <>
      <MyCompetitionTabTitle title={intl.formatMessage({ id: 'standings' })} />
      <StandingsContainer />
    </>
  )
}

export default MyCompetitionStandings
