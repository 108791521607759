import { useCallback, useMemo, useState } from 'react'
import useSWR from 'swr'
import { useIntl } from 'react-intl'
import { Gender } from 'entities/Team'

import { genderStringMap } from 'utils/strings'

import { BasicSearchResult, BasicSearchResultEntity } from '../model'

export function useQuerySearch<T extends BasicSearchResult<BasicSearchResultEntity>>(
  minimumQueryLength: number,
  queryFunction: (query: string, sport?: string) => string,
  sport?: string,
) {
  const intl = useIntl()
  const [query, setQuery] = useState<string>('')
  const { data, isLoading } = useSWR<T>(query.length >= minimumQueryLength ? queryFunction(query, sport) : null)

  const results = useMemo(
    () =>
      data?.results?.map(result => {
        const genderLabel =
          result?.entity?.gender && result?.entity?.gender !== Gender.Male
            ? ` (${intl.formatMessage({ id: genderStringMap[result.entity.gender as Gender] })})`
            : ''
        return {
          label: `${result.entity.name}${genderLabel}`,
          value: result.entity.id,
        }
      }) || [],
    [data, intl],
  )

  const handleQueryChange = useCallback((query: string | undefined) => setQuery(query || ''), [setQuery])

  const noOptionsText = useMemo(() => {
    if (isLoading) {
      return intl.formatMessage({ id: 'loading' })
    }
    if (query.length < minimumQueryLength) {
      return intl.formatMessage({ id: 'search_field_required_characters' }, { n: minimumQueryLength })
    }
    return undefined
  }, [isLoading, query.length, minimumQueryLength, intl])

  return {
    results,
    isLoading,
    handleQueryChange,
    noOptionsText,
  }
}
