import { Theme, createStyles, makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerPaper: {
      width: theme.spacing(39),
    },
    dialogTitle: {
      marginTop: 0,
      padding: `${theme.spacing(2)}px 0`,
    },
    dialogContent: {
      padding: `0 ${theme.spacing(2)}px`,
    },
    radioGroupList: {
      flexDirection: 'column',
    },
    resultOnlyStatusContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(1),
    },
    radioGroup: {
      paddingLeft: theme.spacing(2),
    },
    statusSelector: {
      marginBottom: theme.spacing(1),
      width: '100%',
      height: '75px',
    },
  }),
)
