import styled from 'styled-components'
import { Box, Button } from '@sofascore/ui'
import { motion } from 'framer-motion'

export const MotionButton = motion(Button)

export const Label = styled.label`
  position: absolute;
  top: ${p => p.theme.spacing.xs};
  left: ${p => p.theme.spacing.lg};

  font: ${p => p.theme.typography.font.assistive.default};
  ${p => `color: ${p.theme.colors.onSurface.nLv3};`}
  user-select: none;
  pointer-events: none;
  transition: top 100ms, font 100ms, color 100ms;
  transition-timing-function: ease-in-out;
`

export const SelectFieldWrapper = styled.div<{ isDisabled?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 48px;
  background-color: ${p => p.theme.colors.surface.s1};
  color: ${p => p.theme.colors.onSurface.nLv1};
  padding: ${p => `0 ${p.theme.spacing.lg}`};
  border-radius: ${p => p.theme.spacing.sm};
  cursor: ${p => (p.isDisabled ? 'not-allowed' : 'pointer')};

  border: ${p => `1px solid ${p.theme.colors.onSurface.nLv4} !important`};

  &:focus-within {
    border: ${p => `1px solid ${p.theme.colors.primary.default} !important`};
  }

  ${p => p.isDisabled && 'opacity: 0.5;'}
`

export const Select = styled(Box)``
