import { Theme, createStyles, makeStyles } from '@material-ui/core'

import { neutral, borders, RED } from 'styles/color'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notchedOutline: {
      borderWidth: '2px',
      borderColor: `${neutral} !important`,
    },

    cssError: {
      borderWidth: '2px',
      borderColor: `${RED.incident} !important`,
    },

    inputError: {
      color: RED.incident,
      fontWeight: 500,
    },

    multiline: {
      '& > div': {
        height: theme.spacing(12),
      },
    },

    singleLine: {
      '& > div': {
        height: theme.spacing(5.5),
      },
    },

    focused: {},

    inputField: {
      '& > div': {
        backgroundColor: borders.lightRgba,
        '&$focused': { backgroundColor: 'white' },
      },
    },

    input: {
      '&::placeholder': {
        fontWeight: 500,
      },
      fontWeight: 500,
    },
  }),
)
