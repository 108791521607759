import { useMemo } from 'react'
import { useEventState } from 'entities/Event'
import { LineupsResponse } from 'entities/Lineups'
import useSWR from 'swr'

import { getLineups } from 'api/routes'

import { Player, PlayerOption } from '../model'
import { useEventPlayers } from '../providers'

const mapPlayersToOptions = (players: Player[]) => {
  return players.map<PlayerOption>(p => ({ label: p.name, value: p }))
}

const comparePlayerOptions = (a: PlayerOption, b: PlayerOption) => {
  if (a.value.jerseyNumber && b.value.jerseyNumber) {
    return +a.value.jerseyNumber - +b.value.jerseyNumber
  }

  if (!a.value.jerseyNumber && b.value.jerseyNumber) {
    return 1
  }

  if (a.value.jerseyNumber && !b.value.jerseyNumber) {
    return -1
  }

  return a.value.name.localeCompare(b.value.name)
}

/**
 * Hook for generating player options for dropdown in edit match forms. Returns a 2-tuple
 * where first member are options for scorer (or simply a player), meaning it's filtered
 * using the `assist` parameter. Second member contains options for assist, meaning it's
 * filtered using the `scorer` parameter. If no parameters are provided, the options won't
 * be filtered.
 *
 * @param teamId Selected team ID
 * @param scorer Current scorer (used for filtering assist array)
 * @param assist Current assist (used for filtering scorer array)
 * @returns
 */
export const useTeamPlayerOptions = (teamId: number, scorerOption?: PlayerOption, assistOption?: PlayerOption) => {
  const { event } = useEventState()
  const { homeTeam } = event
  const eventPlayers = useEventPlayers()
  const teamPlayers = eventPlayers[teamId]

  const { data } = useSWR<LineupsResponse>(event ? getLineups(event.id) : null)

  const mappedPlayers = useMemo(() => {
    if (data) {
      const isHome = homeTeam.id === teamId
      const players = isHome ? data.home.players : data.away.players

      return teamPlayers.map(tp => {
        const playerInLineup = players.find(p => p.player.id === tp.id)

        return {
          ...tp,
          jerseyNumber: playerInLineup ? playerInLineup.jerseyNumber : null,
        }
      })
    }

    return teamPlayers
  }, [data, teamPlayers, teamId, homeTeam])

  const teamScorerOptions = useMemo(() => {
    if (!mappedPlayers?.length) return []

    return mapPlayersToOptions(mappedPlayers)
      .filter(p => p.value !== assistOption?.value)
      .sort(comparePlayerOptions)
  }, [assistOption, mappedPlayers])

  const teamAssistOptions = useMemo(() => {
    if (!mappedPlayers?.length) return []

    return mapPlayersToOptions(mappedPlayers)
      .filter(p => p.value !== scorerOption?.value)
      .sort(comparePlayerOptions)
  }, [scorerOption, mappedPlayers])

  return [teamScorerOptions, teamAssistOptions]
}
