import { get } from 'lodash'

export type ValuesType = { [key: string]: string | number }

/**
 * Tries to get translation id for given `name` and `sport` from the `map`. If the value for the name is not a string,
 * it should be an object containing `id` and `values` (get values using `getValues` functions). If there is no `name`
 * in the `map` or `id` is missing from the value object, it returns `name`.
 */
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const getId = (map: any, name: string, sport?: string, defaultValue?: string): string => {
  const path = sport ? [sport, name] : [name]
  let mapValue = get(map, path, get(map, name, defaultValue || name))
  if (typeof mapValue !== 'string') {
    mapValue = get(mapValue, 'id', defaultValue || name)
  }
  return mapValue
}

/**
 * Tries to get translation values for given `name` and `sport` from the map. If there is no `name` in the `map` or
 * `values` is missing from the value object, it returns an empty object.
 */
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const getValues = (map: any, name: string, sport?: string): ValuesType => {
  const path = sport ? [sport, name] : [name]
  const mapValue = get(map, path, get(map, name, name))
  if (typeof mapValue !== 'string') {
    return get(mapValue, 'values', {})
  }
  return {}
}

export const calculateAge = (dateOfBirthTimestamp: number) => {
  const birthday = new Date(dateOfBirthTimestamp)
  const ageDifMs = Date.now() - birthday.getTime()
  const ageDate = new Date(ageDifMs)

  return Math.abs(ageDate.getUTCFullYear() - 1970)
}

// It wraps title with Sofascore Editor sufix
export const tabTitleWrapper = (title: string) => {
  return `${title} | Sofascore Editor`
}

export function getEnumKeyByEnumValue<TEnumKey extends string, TEnumVal extends string | number>(
  myEnum: { [key in TEnumKey]: TEnumVal },
  enumValue: TEnumVal,
): string {
  const keys = (Object.keys(myEnum) as TEnumKey[]).filter(x => myEnum[x] === enumValue)
  return keys.length > 0 ? keys[0] : ''
}
