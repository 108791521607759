import { AnyAction } from 'redux'
import { REHYDRATE } from 'redux-persist'

import {
  TOKEN_FETCH_SUCCESSFUL,
  SET_AUTH_DATA,
  LOGIN_SUCCESSFUL,
  LOGOUT_SUCCESSFUL,
  UNAUTHORIZED_ERROR,
  UNPROCESSABLE_ERROR,
  TOKEN_REFRESH_SUCCESSFUL,
  SET_PROVIDER_DATA,
  CLEAR_LOGIN_ERROR,
} from './actions'
import { AuthReduxState } from './interface'

export const defaultState: AuthReduxState = {}

export const authReducer = (state: AuthReduxState = defaultState, action: AnyAction) => {
  switch (action.type) {
    case REHYDRATE: {
      if (action.key !== 'auth') return state
      return { ...state }
    }

    case TOKEN_FETCH_SUCCESSFUL:
    case TOKEN_REFRESH_SUCCESSFUL:
    case SET_AUTH_DATA:
    case LOGIN_SUCCESSFUL:
    case SET_PROVIDER_DATA:
    case CLEAR_LOGIN_ERROR: {
      const newState = { ...state, ...action.payload }
      // on success actions delete error property
      delete newState.error

      return newState
    }

    case LOGOUT_SUCCESSFUL: {
      return { ...action.payload, uuid: state.uuid, token: state.token }
    }

    case UNAUTHORIZED_ERROR: {
      return { uuid: state.uuid, error: action.payload }
    }

    case UNPROCESSABLE_ERROR: {
      const newState = { ...state, error: action.payload }

      return newState
    }

    default:
      return state
  }
}
