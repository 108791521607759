import { IntlShape } from 'react-intl'
import { StatusCode } from 'entities/Status'
import { BasicEvent, hasGoneToExtraTime } from 'entities/Event'
import { SportName, sportsWithPeriodTime } from 'entities/Sport'

import { getCurrentPeriodInitialTime, getCurrentPeriodMaxTime } from 'utils/matchTime'

// football period lengths (in seconds)
const REGULAR_HALF_LENGTH = 2700
const EXTRA_TIME_HALF_LENGTH = 900

// football period ends in seconds
const HT = REGULAR_HALF_LENGTH // half time
const FT = 2 * HT // full time
const EXTRA_HT = FT + EXTRA_TIME_HALF_LENGTH // extra time first half => extra half time
const EXTRA_FT = EXTRA_HT + EXTRA_TIME_HALF_LENGTH // extra time full time => extra full time

const statusCodesMappingToExtraFT = [
  StatusCode['Awaiting extra time'],
  StatusCode['1st extra'],
  StatusCode['2nd extra'],
  StatusCode['Extra time halftime'],
  StatusCode['Overtime'],
]

const pausedStatusCodes = [
  StatusCode.Pause,
  StatusCode.Halftime,
  StatusCode['Extra time halftime'],
  StatusCode['Awaiting extra time'],
  StatusCode['Awaiting penalties'],
  StatusCode.Penalties,
  StatusCode['Penalties alt.'],
]

export const extraTimeStatusCodes = [
  StatusCode['Awaiting extra time'],
  StatusCode['1st extra'],
  StatusCode['Extra time halftime'],
  StatusCode['2nd extra'],
  StatusCode['Awaiting penalties'],
  StatusCode.Penalties,
  StatusCode['Penalties alt.'],
  StatusCode['Overtime'],
]

/** Returns a 4-tuple with number of seconds elapsed to that period */
const getPeriodDurations = (event: BasicEvent) => {
  const { time, defaultPeriodCount, defaultPeriodLength } = event

  // use hard-coded values initially, but improve if data exists on event
  let [ht, ft, et1, et2] = [HT, FT, EXTRA_HT, EXTRA_FT]

  if (defaultPeriodCount && defaultPeriodLength) {
    ht = defaultPeriodLength * 60
    ft = ht * 2
    et1 = ft + (time?.overtimeLength || EXTRA_TIME_HALF_LENGTH)
    et2 = et1 + (time?.overtimeLength || EXTRA_TIME_HALF_LENGTH)
  }

  return [ht, ft, et1, et2]
}

/** determine initial (seconds played before current period start) w/o time */
const getInitialFromCPST = (event: BasicEvent) => {
  const { currentPeriodStartTimestamp, startTimestamp, time } = event

  const cpst = time?.currentPeriodStartTimestamp || currentPeriodStartTimestamp
  const delta = cpst ? cpst - startTimestamp : 0

  return delta
}

export const isExtra = (event: BasicEvent) => {
  return statusCodesMappingToExtraFT.includes(event.status.code)
}

/**
 * Returns number of seconds elapsed from event start time based on `status` and `time` event properties.
 * If football and `time` is undefined, attempt to determine elapsed duration by StatusCode, otherwise return -1.
 */
export const getElapsed = (event: BasicEvent): number => {
  const { tournament, status, startTimestamp, time } = event

  const sport = tournament.category.sport.slug

  if (sportsWithPeriodTime.includes(sport)) {
    const timeStampSeconds = Math.floor(Date.now() / 1000)
    const currentPeriodStartTimestamp = time?.currentPeriodStartTimestamp || event.currentPeriodStartTimestamp

    if (!pausedStatusCodes.includes(status.code)) {
      const initial = getCurrentPeriodInitialTime(event)
      const max = getCurrentPeriodMaxTime(event)

      // be careful, initial is 0 if 1st period
      if (currentPeriodStartTimestamp && typeof initial === 'number') {
        const elapsed = timeStampSeconds - currentPeriodStartTimestamp

        // This ensures returned value is at least `initial` an never more than `max`;
        // E.g. if 1st half, and seconds somehow overshoot HT, return value will be at most HT.
        // Likewise, if 2nd half, and seconds are still < HT, it will be at least HT.
        return Math.min(max, Math.max(initial, initial + elapsed))
      }
    }

    const [ht, ft, et1, et2] = getPeriodDurations(event)

    // try to return approximate best time based on status.code, or -1 (will pause render)
    switch (status.code) {
      case StatusCode.Halftime:
        return ht
      case StatusCode['Awaiting extra time']:
      case StatusCode['1st extra']:
        return ft
      case StatusCode['2nd extra']:
      case StatusCode['Extra time halftime']:
        return et1
      case StatusCode['Awaiting penalties']:
      case StatusCode.Penalties:
      case StatusCode['Penalties alt.']:
      case StatusCode.Shootout:
        // if current period started after full event duration, assume penalties occurred after extra time
        return hasGoneToExtraTime(event) ? et2 : ft
      default:
        return -1
    }
  }

  if (time?.played) {
    // if pause, prevent overflowing to next period
    if (status.code === StatusCode.Pause && time?.periodLength) {
      return time.played - (time.played % time.periodLength)
    }
    return time.played
  }

  if (time?.currentPeriodStartTimestamp) {
    const currentTime = Math.floor(Date.now() - time.currentPeriodStartTimestamp * 1000) / 1000
    return currentTime
  }

  return Math.floor(Date.now() - startTimestamp * 1000) / 1000
}

/** Returns maximum event duration as a number of seconds, if possible. Otherwise undefined. */
export const getMaxTime = (event: BasicEvent) => {
  const { tournament, status, time } = event

  const sport = tournament.category.sport.slug

  if (sport === SportName.Football || sport === SportName.Rugby) {
    const [_, ft, __, et2] = getPeriodDurations(event)

    // attempt to determine max time for scaling the domain properly
    let maxDomain = ft

    // attempt to determine time using period data from time object;
    // note that this is unlikely to be defined for football events
    if (time?.totalPeriodCount && time?.periodLength) {
      maxDomain = time.totalPeriodCount * time.periodLength
    }

    // is extra time...
    if (isExtra(event) || hasGoneToExtraTime(event)) {
      // if any hint for overtime duration exists on time object, use that, or fallback to default extra
      maxDomain = et2
    }

    // if awaiting penalties, attempt to determine whether event went into overtime or straight to penalties;
    // use `time.initial` or the difference between current period start and event start
    if (status.code === StatusCode['Awaiting penalties']) {
      const delta = time?.initial || getInitialFromCPST(event)

      if (delta >= maxDomain) {
        maxDomain = et2
      }
    }

    return maxDomain
  }

  if (time?.totalPeriodCount && time.periodLength) {
    return time.totalPeriodCount * time.periodLength
  }

  return undefined
}

type PeriodMarks = {
  /** Number of seconds from start time when period end occurs */
  x: number
  /** Period mark, e.g. HT, Q3, 2ND... */
  label: string
  /** Circle above the text that marks the beginning of period */
  hasDivider: boolean
}

/** Returns an array of PeriodMarks, based on sport. */
export const getPeriodMarks = (event: BasicEvent, intl: IntlShape): PeriodMarks[] => {
  const { tournament, startTimestamp, status, time } = event

  const sport = tournament.category.sport.slug
  // Currenty in editor only sport with quarter periods is basketball
  const isQSport = SportName.Basketball === sport

  if (sportsWithPeriodTime.includes(sport)) {
    const [ht, ft, et1, et2] = getPeriodDurations(event)
    const firstHalfStartTime = time?.period1StartTimestamp || startTimestamp

    const periodMarks = [
      { x: 0, label: intl.formatTime(firstHalfStartTime * 1000, { hour12: false }), hasDivider: false },
      { x: ht, label: intl.formatMessage({ id: 'Halftime.short' }), hasDivider: true },
      {
        x: ft,
        label: intl.formatMessage({ id: 'Fulltime.short' }),
        hasDivider: extraTimeStatusCodes.includes(status.code),
      },
    ]

    if (hasGoneToExtraTime(event)) {
      periodMarks.push(
        { x: et1, label: intl.formatMessage({ id: 'etN' }, { n: 1 }), hasDivider: true },
        { x: et2, label: intl.formatMessage({ id: 'etN' }, { n: 2 }), hasDivider: true },
      )
    }

    return periodMarks
  }

  if (time && time.totalPeriodCount && time.periodLength) {
    const periodMarks = [{ x: 0, label: intl.formatTime(startTimestamp * 1000), hasDivider: false }]

    for (let i = 1; i <= time.totalPeriodCount; i++) {
      const x = time.periodLength * i
      const id = isQSport ? 'qN' : 'ordinalPeriod.short'
      const label = intl.formatMessage({ id }, { n: i })

      periodMarks.push({ x, label, hasDivider: false })
    }
    return periodMarks
  }

  return []
}
