import { Table } from '@sofascore/ui'
import styled from 'styled-components'

export const StyledHeadCell = styled(Table.HeadCell)<{ textAlign?: string }>`
  padding: 16px 8px;
  border: none;
  letter-spacing: -0.07px;

  text-align: ${p => (p.textAlign ? p.textAlign : 'center')};
  font-size: 14px;
  color: ${p => p.theme.colors.onSurface.nLv3};
`
