import { Children, Stylable } from 'shared/model'

export interface IconProps extends Stylable {
  viewBox?: string
  fill?: string
  width?: number
  height?: number
  fillOpacity?: string
}

export const IconBase = ({ children, ...rest }: IconProps & Children) => <svg {...rest}>{children}</svg>
