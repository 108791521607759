import { useMediaContext } from '@sofascore/ui'

import { MobileListLineups } from './MobileListLineups'
import { DesktopListLineups } from './DesktopListLineups'

interface DesktopListLineupsProps {
  positionGroupMapping: Record<string, string>
}

export default function ListLineups({ positionGroupMapping }: DesktopListLineupsProps) {
  const { isMobile } = useMediaContext()

  if (isMobile) return <MobileListLineups positionGroupMapping={positionGroupMapping} />

  return <DesktopListLineups positionGroupMapping={positionGroupMapping} />
}
