import { SportName } from 'entities/Sport'

import { useMyCompetitionsState } from 'modules/MyCompetitions/providers/MyCompetitionsProvider'

import { AdminInfo as AdminInfoFootball } from './Football'

const mapSportToForm: Record<string, () => JSX.Element> = {
  [SportName.Football]: AdminInfoFootball,
}

export const AdminInfo = () => {
  const { uniqueTournament } = useMyCompetitionsState()
  const sport = uniqueTournament?.category?.sport?.slug
  if (!sport) throw new Error()
  const AdminInfoForm = mapSportToForm[sport]

  return (
    <>
      <AdminInfoForm />
    </>
  )
}
