import { IconProps, IconBase } from './Base'

const IconConversion = ({ fill = '#0BB32A' }: IconProps) => {
  return (
    <IconBase width={24} height={24} viewBox="0 0 24 24">
      <path
        d="M4 2v14h16V2h2v16h-2v4h-2v-4H6v4H4v-4H2V2h2zm7.8.5c2.2 0 3.8 1.2 3.8 3.4 0 2.3-1.5 3.3-3.7 3.8-1.3.3-2 .5-2 1.9v.5h5.6c.1 0 .2.1.2.2v1.6c0 .1-.1.2-.2.2H7.9c-.1 0-.2-.1-.2-.2v-2.1c0-2.6 1.4-3.5 4-4 1.2-.2 1.7-1 1.7-1.9 0-.9-.6-1.6-1.7-1.6-1.2 0-1.9.6-2 1.6 0 .1-.1.2-.2.2H7.8c-.1 0-.2-.1-.1-.2.2-2.1 1.6-3.4 4.1-3.4z"
        fill={fill}
        fillRule="evenodd"
      />
    </IconBase>
  )
}

export default IconConversion
