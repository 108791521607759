import playerPositions from 'translations/mappings/playerPositions.json'
/**
 * Format id representation of player position.
 */
export const getPlayerPositionId = (position: string | undefined, sport: string) => {
  if (!position || position === '-') return 'unknown'

  const key = playerPositions[sport][position]

  if (!key) return 'unknown'

  return key
}
