import useSWR from 'swr'
import { useSelector } from 'react-redux'
import { UniqueTournamentGroupedTeamsResponse } from 'entities/UniqueTournament'
import { useParams } from 'react-router-dom'

import { uniqueTournamentGroupedTeams } from 'api/routes'
import { getAuthFromState } from 'store/selectors'

export const useGroupedCompetitionTeams = () => {
  const { competitionId, seasonId } = useParams()
  const { userAccount } = useSelector(getAuthFromState)
  const { data, error, mutate, isLoading } = useSWR<UniqueTournamentGroupedTeamsResponse>(
    userAccount && competitionId && seasonId ? uniqueTournamentGroupedTeams(+competitionId, +seasonId) : null,
  )

  return {
    data,
    error,
    revalidate: mutate,
    isLoading,
  }
}
