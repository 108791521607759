import { AccumulatedFoul } from 'entities/AccumulatedFoul'
import { ScoreWithPeriods, UpdateScorePayload } from 'entities/Score'
import { BasicSeason } from 'entities/Season'
import { Status } from 'entities/Status'
import { BasicTeam } from 'entities/Team'
import { BasicTournament } from 'entities/Tournament'
import { IncidentGoalType } from 'entities/Incident'
import { EntityAllowedActions } from 'entities/Permission'

export interface BasicEvent {
  id: number
  customId: string
  slug: string
  status: Status
  startTimestamp: number
  tournament: BasicTournament
  homeTeam: BasicTeam
  awayTeam: BasicTeam
  homeScore: ScoreWithPeriods
  awayScore: ScoreWithPeriods
  season?: BasicSeason
  time?: Time
  defaultPeriodCount?: number
  defaultPeriodLength?: number
  defaultOvertimeLength?: number
  currentPeriodStartTimestamp?: number
  winnerCode?: WinnerCode
  aggregatedWinnerCode?: number
  roundInfo?: RoundInfo
  finalResultOnly?: boolean
  crowdsourcingDataDisplayEnabled?: boolean
  userCount?: number
  lastPeriod?: string
  periods?: PeriodNames
  feedLocked?: boolean
  allowedActions: EntityAllowedActions
  editor: EventEditor
  editorEventCity?: string
  editorEventVenue?: string
}

export interface EventEditor {
  editorEmail: string
  editorName: string
  id: string
}

export interface EventEditor {
  editorEmail: string
  editorName: string
  id: string
}

export enum WinnerCode {
  Unknown, // special case when event is cancelled or something similar
  Home,
  Away,
  Draw,
  NoContest,
}

export interface RoundInfo {
  round: number
  name?: string
  slug?: string
  prefix?: string
}

export interface Time {
  initial?: number // Number of seconds elapsed in all of the previous periods
  extra?: number // Injury time, unused
  max?: number // Max number of seconds of the current period (2700 for regular football half)
  current?: number /** @deprecated see https://youtrack.sofascore.com/issue/CORE-1780 */
  period1?: number
  period2?: number
  period3?: number
  period4?: number
  period5?: number
  overtime?: number
  penalties?: number
  played?: number
  periodLength?: number
  overtimeLength?: number
  totalPeriodCount?: number
  injuryTime1?: number
  injuryTime2?: number
  injuryTime3?: number
  injuryTime4?: number
  currentPeriodStartTimestamp?: number
  period1StartTimestamp?: number
  period2StartTimestamp?: number
  extra1StartTimestamp?: number
  extra2StartTimestamp?: number
  penaltiesStartTimestamp?: number
}

export interface EventAccumulatedFouls {
  home: AccumulatedFoul[]
  away: AccumulatedFoul[]
}

export enum ScoringTeam {
  Home = 1,
  Away = 2,
}

interface CrowdsourcingSuggestions {
  startDateTimestamp?: number
  finalScore: {
    home?: number
    away?: number
  }
  scoreSuggests: {
    scoringTeam: ScoringTeam
    home: number
    away: number
    incidentClass: IncidentGoalType
    scorer?: string
    assist?: string
  }[]
}

export interface EventCrowdsourcingSuggestsResponse {
  suggestions: CrowdsourcingSuggestions
}

export interface PeriodNames {
  current: string
  period1?: string
  period2?: string
  period3?: string
  period4?: string
  period5?: string
  period6?: string
  period7?: string
  overtime?: string
}

export interface UpdateEventBody {
  status: {
    code: number
  }
  homeScore: UpdateScorePayload
  awayScore: UpdateScorePayload
  aggregatedWinnerCode: number
  currentPeriodStartTimestamp: number | null
  roundInfo?: RoundInfo
  startDateTimestamp?: number
  resetScore?: boolean
  crowdsourcingDataDisplayEnabled?: boolean
}
