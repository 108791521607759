import { IconBase, IconProps } from './Base'

export const IconMegaphone = (props: IconProps) => (
  <IconBase width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      d="M18 5.8 2 9v5l2 .5V20h7v-.05l.05.05 2.99-2.99L18 18V5.8zM10.22 18H6v-3l5.78 1.44L10.22 18zM19 4v16h3V4h-3z"
      fillRule="evenodd"
    />
  </IconBase>
)
