import { useIntl } from 'react-intl'

import TeamSuggestForm from './TeamSuggestForm'
import { useTeam } from '../TeamContext'
import TeamTabTitle from '../TeamTabTitle'

const TeamInfo = () => {
  const intl = useIntl()
  const team = useTeam()

  if (!team) return null

  return (
    <>
      <TeamTabTitle title={intl.formatMessage({ id: 'info' })} />
      <TeamSuggestForm team={team} />
    </>
  )
}

export default TeamInfo
