import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Box,
  Button,
  Dialog,
  FormControlLabel,
  Radio,
  RadioGroup,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core'
import {
  STATUS_CODES_BY_SPORT,
  StatusType,
  StatusCode,
  getStatusCodeTranslation,
  getStatusOption,
} from 'entities/Status'
import { SportName, sportsWithTwoHalves } from 'entities/Sport'

import StatusSelector from 'modules/Matches/components/Selectors'
import { getDate } from 'utils/time'
import { RootState } from 'store/store'
import { EditMatchModeType } from 'modules/Matches/interface'
import DialogTitle from 'components/Dialog/DialogTitle'
import { Option } from 'components/DropdownMenu/interface'
import DialogContent from 'components/Dialog/DialogContent'
import DialogActions from 'components/Dialog/DialogActions'
import StatusRadioGroup from 'components/Selector/StatusRadioGroup'
import { TimePicker } from 'components/DateTimePickers/DateTimePickers'
import { isFootballBasicStatus, isPeriodStatus, isTimestampValid } from 'modules/Matches/helper'
import { BETWEEN_PERIODS, ENDED, FIRST_HALF, OTHER, RESET, SECOND_HALF } from 'modules/Matches/const'
import { SET_CURRENT_PERIOD_START_TIMESTAMP, SET_MATCH_STATUS } from 'modules/Matches/matchesReducer'
import { useMyCompetitionsState } from 'modules/MyCompetitions/providers/MyCompetitionsProvider'

import { useStyles } from './styles'

interface Props {
  isOpen: boolean
  handleClose: () => void
  mode?: EditMatchModeType
}

const SetStatusDialog = ({ isOpen, handleClose, mode }: Props) => {
  const statusCode = useSelector((state: RootState) => {
    return state.event ? state.event.status.code : null
  })

  const currentPeriodStartTimestamp = useSelector((state: RootState) => {
    return state.event ? state.event.currentPeriodStartTimestamp : null
  })

  const { uniqueTournament } = useMyCompetitionsState()
  const sport = uniqueTournament?.category.sport.slug
  const hasTwoPeriods = sportsWithTwoHalves.includes(sport as SportName)

  const dispatch = useDispatch()

  const intl = useIntl()

  const theme: Theme = useTheme()
  const classes = useStyles()

  // Map for selecting radio button according to current status code
  const basicMatchStatusRadioMap = useMemo(
    () => ({
      [StatusCode['1st half']]: FIRST_HALF,
      [StatusCode.Halftime]: BETWEEN_PERIODS,
      [StatusCode['2nd half']]: SECOND_HALF,
      [StatusCode.Ended]: ENDED,
    }),
    [],
  )

  // Match status value from radio group section
  const [matchStatusValueRadio, setMatchStatusValueRadio] = useState<string>(() => {
    if (typeof statusCode === 'number') {
      if (isFootballBasicStatus(statusCode)) {
        return basicMatchStatusRadioMap[statusCode]
      } else {
        return OTHER
      }
    }
  })

  // MatchStatus value from dropdown
  const [matchStatusValue, setMatchStatusValue] = useState<Option | null>(() => {
    if (typeof statusCode === 'number' && sport) {
      return {
        label: getStatusCodeTranslation(sport, statusCode, intl),
        value: statusCode,
      }
    }
    return null
  })

  // Selected time (date) from time picker for Match status
  const [currentPeriodStartTime, setCurrentPeriodStartTime] = useState<Date | null>(
    currentPeriodStartTimestamp ? getDate(currentPeriodStartTimestamp) : null,
  )

  // Clicking on status from drop down menu
  const handleClickMatchStatusMenuItem = (selectedOption: Option) => {
    if (selectedOption.value !== StatusCode.Ended) {
      setCurrentPeriodStartTime(null)
    }

    if ((matchStatusValue && selectedOption.value !== matchStatusValue.value) || !matchStatusValue) {
      setMatchStatusValue(selectedOption)
    }
  }

  // Clicking on status from radio group section
  const handleMatchStatusValueRadioChange = (matchStatusValueRadioEvent: React.ChangeEvent<HTMLInputElement>) => {
    const newMatchStatusRadioValue = matchStatusValueRadioEvent.target.value

    switch (newMatchStatusRadioValue) {
      case OTHER:
        setMatchStatusValue(null)
        if (currentPeriodStartTime) {
          setCurrentPeriodStartTime(null)
        }
        break
      case RESET:
        setMatchStatusValue(
          sport
            ? {
                label: STATUS_CODES_BY_SPORT[sport][StatusCode['Not started']].description,
                value: StatusCode['Not started'],
              }
            : null,
        )
        break
      default: {
        const statusOption = sport ? getStatusOption(newMatchStatusRadioValue, sport) : null
        if (statusOption) {
          handleClickMatchStatusMenuItem(statusOption)
        }
        break
      }
    }
    setMatchStatusValueRadio(newMatchStatusRadioValue)
  }

  // Current period start time handler
  const handleMatchStatusTimeChange = (date: Date | null) => {
    setCurrentPeriodStartTime(date)
  }

  const saveStatus = () => {
    if (matchStatusValue) {
      dispatch({
        type: SET_MATCH_STATUS,
        payload: { status: { code: Number(matchStatusValue.value), description: matchStatusValue.label } },
      })
    }
    dispatch({ type: SET_CURRENT_PERIOD_START_TIMESTAMP, payload: { periodStartTime: currentPeriodStartTime } })
    handleClose()
  }

  const isSaveEnabled = () => (isPeriodStatus(matchStatusValue) ? !!isTimestampValid(currentPeriodStartTime) : true)

  return (
    <Dialog open={isOpen} onClose={handleClose} classes={{ paper: classes.drawerPaper }}>
      <DialogTitle style={{ padding: `${theme.spacing(1)}px ${theme.spacing(2)}px` }}>
        <Typography variant="subtitle1">
          <FormattedMessage id="matchStatus" />
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        {mode === EditMatchModeType.RESULT_ONLY ? (
          <Box className={classes.resultOnlyStatusContainer}>
            <RadioGroup
              value={matchStatusValueRadio}
              onChange={handleMatchStatusValueRadioChange}
              className={classes.radioGroup}
            >
              <FormControlLabel value={RESET} control={<Radio />} label={<FormattedMessage id="resetMatchStatus" />} />
              <FormControlLabel value={ENDED} control={<Radio />} label={<FormattedMessage id="Ended" />} />
              <FormControlLabel value={OTHER} control={<Radio />} label={<FormattedMessage id="other" />} />
            </RadioGroup>

            {matchStatusValueRadio === OTHER ? (
              <StatusSelector
                hasSpecialStatusOptions
                value={matchStatusValue}
                onChange={handleClickMatchStatusMenuItem}
                caption={intl.formatMessage({ id: 'matchStatus' })}
                disabled={matchStatusValueRadio !== OTHER}
                className={classes.statusSelector}
              />
            ) : (
              <div style={{ height: '83px' }} />
            )}
          </Box>
        ) : (
          <>
            {hasTwoPeriods && (
              <Box pl={2}>
                <StatusRadioGroup
                  className={classes.radioGroupList}
                  value={matchStatusValueRadio}
                  onChange={handleMatchStatusValueRadioChange}
                />
              </Box>
            )}

            <Box mt={hasTwoPeriods ? 0 : 1}>
              <StatusSelector
                value={matchStatusValue}
                onChange={handleClickMatchStatusMenuItem}
                caption={intl.formatMessage({ id: 'matchStatus' })}
                disabled={hasTwoPeriods && matchStatusValueRadio !== OTHER}
                className={classes.statusSelector}
              />
            </Box>

            {matchStatusValue &&
              sport &&
              STATUS_CODES_BY_SPORT[sport][matchStatusValue.value] &&
              STATUS_CODES_BY_SPORT[sport][matchStatusValue.value].type === StatusType.IN_PROGRESS &&
              sport !== SportName.Volleyball && (
                <TimePicker
                  value={currentPeriodStartTime}
                  onChange={handleMatchStatusTimeChange}
                  label={intl.formatMessage({ id: 'time' })}
                  required={Boolean(isPeriodStatus(matchStatusValue) && !isTimestampValid(currentPeriodStartTime))}
                />
              )}
          </>
        )}
      </DialogContent>

      <DialogActions style={{ justifyContent: 'flex-end', padding: theme.spacing(2) }}>
        <Button onClick={handleClose} style={{ height: 36, marginRight: 16, fontSize: 14 }}>
          <FormattedMessage id="cancel" />
        </Button>
        <Button disabled={!isSaveEnabled()} variant="contained" onClick={saveStatus} style={{ width: 70, height: 36 }}>
          <FormattedMessage id="save" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SetStatusDialog
