import * as React from 'react'
import { Image, Flex, Dropdown, useMediaContext, Text, Box } from '@sofascore/ui'
import { DropdownChangeValue } from '@sofascore/ui/dist/types/Dropdown/interface'
import { useSelector, useDispatch } from 'react-redux'
import { Option } from 'shared/model'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'
import { FirebaseEventName } from 'shared/lib/firebase/model'
import IconChevronDown16 from '@sofascore/ui/dist/modules/Icons/IconChevronDown16'

import { SET_LOCALE } from 'store/uiControls/reducer'
import { localeNames, getLanguageFlag } from 'utils/locale'
import { selectLocale } from 'store/selectors'

import * as S from './styles'

interface LanguagePickerProps {
  showArrow?: boolean
}

export const LanguagePicker = ({ showArrow = false }: LanguagePickerProps) => {
  const { isMobile } = useMediaContext()
  const selectedLocale = useSelector(selectLocale)
  const dispatch = useDispatch()

  const languageOptions = Object.keys(localeNames).map(locale => ({
    label: localeNames[locale],
    value: locale,
  }))

  const selectedLang = React.useMemo(
    () => languageOptions?.filter(option => option?.value === selectedLocale)[0],
    [languageOptions, selectedLocale],
  )

  const changeLanguge = (event: DropdownChangeValue<Option>) => {
    logFirebaseEvent(FirebaseEventName.SelectLanguage, { selected_language: event?.selectedItem?.value })
    dispatch({ type: SET_LOCALE, payload: { selectedLocale: event?.selectedItem?.value } })
  }

  return (
    <Dropdown
      selectedItem={selectedLang}
      items={languageOptions}
      button={showArrow ? <Box cursor="pointer" userSelect="none" /> : <S.LanguageDropdown />}
      selectedElement={
        showArrow ? (
          <Flex align="center">
            <Image w="xl" mr="md" src={`/images/flags/${getLanguageFlag(selectedLang.value)}.png`} />
            <IconChevronDown16 fill={isMobile ? 'onSurface.nLv1' : 'surface.s0'} w={24} h={24} />
          </Flex>
        ) : undefined
      }
      alignRight={!isMobile}
      renderItem={option => {
        if (option)
          return (
            <Flex align="center">
              <Image w="xl" mr="sm" src={`/images/flags/${getLanguageFlag(String(option.value))}.png`} />
              <Text color="onSurface.nLv1" font="display.micro" textTransform="uppercase">
                {option.label}
              </Text>
            </Flex>
          )

        return null
      }}
      onSelectedItemChange={changeLanguge}
    />
  )
}
