import * as React from 'react'

import { secondaryColor } from 'styles/color'

import { IconBase, IconProps } from './Base'

export const IconMinus = ({ height, fill, ...otherProps }: IconProps) => (
  <IconBase width={24} height={24} viewBox="0 0 24 24" {...otherProps}>
    <g fill="none" fillRule="evenodd">
      <rect
        width="16"
        height={height ? height : 4}
        x="4"
        y="10"
        fill={fill ? fill : secondaryColor}
        fillRule="evenodd"
        rx=".5"
      />
    </g>
  </IconBase>
)
