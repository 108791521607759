import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Theme, useTheme, Typography } from '@material-ui/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { Box } from '@sofascore/ui'
import { StatusType } from 'entities/Status'
import { BasicEvent } from 'entities/Event'
import { useErrorHandler } from 'shared/hooks'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'
import { FirebaseEventName } from 'shared/lib/firebase/model'

import { deleteEvent, updateEvent } from 'api'
import { RootState } from 'store/store'
import usePrevious from 'utils/usePrevious'
import { Header } from 'components/Header/Header'
import RouteLeavingGuard from 'components/RouteLeavingGuard'
import { SET_PERIOD_SCORE_UPDATED } from 'store/uiControls/reducer'
import {
  isCurrentPeriodStartTimestampValid,
  isEventRoundValid,
  isStartTimestampValid,
  getUpdateEventBody,
  areEventsEqual,
} from 'modules/Matches/helper'
import useConfirm from 'modules/ConfirmDialog/useConfirm'
import { DeleteMatchButton, CloseMatchButton, SaveMatch } from 'modules/Matches/components/MatchHeader/HeaderButtons'

interface MatchDetailsHeaderItemsProps {
  close?: () => void
  deleteAction?: (eventId: number) => void
}

export const GeneralMatchDetailsHeader = ({ close, deleteAction }: MatchDetailsHeaderItemsProps) => {
  const { competitionId, seasonId } = useParams()

  // Event from redux
  const event: BasicEvent = useSelector((state: RootState) => state.event)

  // flag for updating score with period result sections (not current - total result section)
  const { periodScoreUpdated } = useSelector((state: RootState) => state.uicontrols)

  const { confirm } = useConfirm()
  const intl = useIntl()
  const dispatch = useDispatch()
  const theme: Theme = useTheme()

  // History
  const navigate = useNavigate()
  const handleError = useErrorHandler()

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const prevEvent = usePrevious(event)

  const hasStarted = event?.status.type !== StatusType.NOT_STARTED
  const isCrowdsourcingEvent = event?.crowdsourcingDataDisplayEnabled && hasStarted

  useEffect(() => {
    if (event && !areEventsEqual(event, prevEvent)) {
      if (typeof event.currentPeriodStartTimestamp === 'number' && isNaN(event.currentPeriodStartTimestamp)) {
        setHasUnsavedChanges(false)
      } else {
        setHasUnsavedChanges(true)
      }
    }
  }, [event, prevEvent])

  // Deletes existing match.
  const deleteMatch = async () => {
    const ok = await confirm({
      messageTextElement: intl.formatMessage({ id: 'deleteItem' }),
    })

    if (ok && event && seasonId && competitionId) {
      try {
        await deleteEvent(event.id)
        if (deleteAction) {
          deleteAction(event.id)
        } else {
          navigate(`/my-competitions/${competitionId}/season/${+seasonId}/matches`)
        }
      } catch (e) {
        handleError(e)
      }
    }
  }

  // Saving existing match
  const saveMatch = async () => {
    if (event && isSaveEnabled()) {
      try {
        const updateEventBody = getUpdateEventBody(event, prevEvent)
        if (updateEventBody) {
          setIsSaving(true)
          await updateEvent(event.id, updateEventBody)
          if (periodScoreUpdated) {
            logFirebaseEvent(FirebaseEventName.EditResultPeriod, { id: event.id })

            dispatch({ type: SET_PERIOD_SCORE_UPDATED, payload: { periodScoreUpdated: false } })
          }
          setIsSaving(false)
          setHasUnsavedChanges(false)
        }
      } catch (e) {
        setHasUnsavedChanges(false)
        handleError(e)
      }
    }
  }

  const isSaveEnabled = () => {
    return (
      isCrowdsourcingEvent ||
      (hasUnsavedChanges &&
        isEventRoundValid(event, null) &&
        isStartTimestampValid(event) &&
        isCurrentPeriodStartTimestampValid(event))
    )
  }

  return (
    <>
      <Header style={{ position: 'sticky' }}>
        <CloseMatchButton close={close} />
        <Typography variant="subtitle1" style={{ marginLeft: theme.spacing(1.5) }} noWrap>
          <FormattedMessage id="matchDetails" />
        </Typography>
        <Box ml="auto" display="flex">
          <DeleteMatchButton deleteHandler={deleteMatch} />
          <SaveMatch disabled={!isSaveEnabled()} onClick={saveMatch} isSaving={isSaving} />
        </Box>
      </Header>
      <RouteLeavingGuard block={hasUnsavedChanges} onConfirm={saveMatch} />
    </>
  )
}
