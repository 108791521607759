import { PropsWithChildren } from 'react'
import { Box, BoxProps } from '@sofascore/ui'

import { PAGE_HEADER_DESKTOP_HEIGHT, PAGE_HEADER_MOBILE_HEIGHT } from 'components/Layout/PageHeader/config'

const PageContent = ({ children, ...rest }: PropsWithChildren<BoxProps>) => {
  return (
    <Box
      pt={[PAGE_HEADER_MOBILE_HEIGHT, PAGE_HEADER_DESKTOP_HEIGHT]}
      minH="100%"
      h="100%"
      w="100%"
      position="relative"
      {...rest}
    >
      {children}
    </Box>
  )
}

export default PageContent
