import * as React from 'react'
import { Typography } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { Stylable } from 'shared/model'

import { useStyles } from './styles'

interface DialogTitleProps extends Stylable {
  children: React.ReactNode
}

/**
 * DialogTitle part of Dialog component
 */
const DialogTitle = (props: DialogTitleProps) => {
  const { children, style } = props
  const classes = useStyles()
  return (
    <MuiDialogTitle disableTypography className={classes.dialogTitle} style={style}>
      <Typography variant="subtitle1" style={{ marginTop: 0 }}>
        {children}
      </Typography>
    </MuiDialogTitle>
  )
}

export default DialogTitle
