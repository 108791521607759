import { useMemo, useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Text, Flex, Box, Autocomplete, useMediaContext } from '@sofascore/ui'
import { bindActionCreators, Dispatch } from 'redux'
import { FormattedMessage } from 'react-intl'
import { ApiDomain } from 'shared/api/model'
import { Option } from 'shared/model'
import IconSettings from '@sofascore/ui/dist/modules/Icons/IconSettings'
import { useToast } from 'shared/lib'

import { Button } from 'components/Button'
import ResponsivePopUp from 'components/ResponsivePopUp'
import { RootState } from 'store/store'
import { setApiBaseBranch, setApiDomain } from 'store/uiControls/reducer'
import { logoutRequest } from 'store/auth/actions'

import useGitBranches from './useGitBranches'
import { SettingsButton } from './styles'

interface ISettingsMenuProps {
  apiBaseBranch: string
  apiDomain: ApiDomain
  setApiBaseBranch: (url: string) => void
  setApiDomain: (domain: ApiDomain) => void
  onSave: () => void
}

const ApiDomainChangeToast = () => {
  const dispatch = useDispatch()

  return (
    <Box display="flex">
      <FormattedMessage id="toastLoginAgain" />
      <a onClick={() => dispatch(logoutRequest())} style={{ fontWeight: 'bold', marginLeft: '8px' }}>
        Login
      </a>
    </Box>
  )
}

const domainOptions = [{ label: 'dev.sofascore.com', value: 'dev' }]

const SettingsMenu = ({ apiBaseBranch, apiDomain, setApiBaseBranch, setApiDomain, onSave }: ISettingsMenuProps) => {
  const { isMobile } = useMediaContext()
  const { enqueueToast } = useToast()
  const [tempApiDomain, setTempApiDomain] = useState(apiDomain)
  const [tempApiBaseBranch, setTempApiBaseBranch] = useState(apiBaseBranch)

  // update this component state if value in reducer is changed
  useEffect(() => {
    setTempApiBaseBranch(apiBaseBranch)
  }, [apiBaseBranch])

  const [isSettingsMenuOpen, setSettingsMenuOpen] = useState(false)
  const [showBranchConfirm, setShowBranchConfirm] = useState(false)

  const { data: branchesData } = useGitBranches(apiDomain)

  const hasChanges = tempApiBaseBranch !== apiBaseBranch || tempApiDomain !== tempApiDomain

  const handleSettingsMenuOpen = () => {
    setSettingsMenuOpen(true)
  }

  const handleSettingsMenuClose = () => {
    if (tempApiDomain !== apiDomain) {
      setShowBranchConfirm(true)
      return
    }
    if (tempApiBaseBranch !== apiBaseBranch) {
      setApiDomain(tempApiDomain)
      setApiBaseBranch(tempApiBaseBranch)
      enqueueToast(`API Base branch: ${tempApiBaseBranch}`, { variant: 'success' })
      onSave()
    }

    setSettingsMenuOpen(false)
  }

  const resetSettings = () => {
    setTempApiDomain(apiDomain)
    setTempApiBaseBranch(apiBaseBranch)
  }

  const handleSettingsMenuCloseWithBranchConfirm = () => {
    if (hasChanges) resetSettings()
    setSettingsMenuOpen(false)
  }

  const apiDomainChange = (selectedOption: Option) => {
    const selectedValue = String(selectedOption?.value || '')
    const parallelBranch = tempApiBaseBranch.replace(apiDomain, selectedValue)
    setTempApiDomain(selectedValue as ApiDomain)
    setTempApiBaseBranch(parallelBranch)
  }

  const apiBaseChange = (selectedOption: Option) => {
    if (selectedOption?.value && selectedOption?.value !== tempApiBaseBranch) {
      setTempApiBaseBranch(String(selectedOption.value))
    } else {
      setTempApiBaseBranch('')
    }
  }

  const selectedBaseBranch = useMemo(
    () => branchesData?.filter(option => option?.value === tempApiBaseBranch)[0] || null,
    [branchesData, tempApiBaseBranch],
  )

  return (
    <>
      <SettingsButton onClick={handleSettingsMenuOpen}>
        <IconSettings />
        {isMobile && (
          <Text font="display.micro" color="onSurface.nLv1">
            DEVELOPER SETTINGS
          </Text>
        )}
      </SettingsButton>

      <ResponsivePopUp
        heading={<Text font="display.large">Developer Settings</Text>}
        isOpen={isSettingsMenuOpen}
        onClose={handleSettingsMenuCloseWithBranchConfirm}
      >
        <Box px="lg" pb="xl" br="sm">
          <Box mb="lg">
            <Autocomplete
              label="Domain"
              value={domainOptions?.filter(option => option?.value === tempApiDomain)[0] || null}
              options={domainOptions}
              onChange={apiDomainChange}
              clearable={false}
              disabled
            />
          </Box>

          <Autocomplete
            label="Base Branch"
            value={selectedBaseBranch}
            options={branchesData}
            onChange={apiBaseChange}
          />
        </Box>
        <Flex gapX="lg" px="lg" mt="xxl" pb="lg" br="sm">
          <Button fullWidth variant="outlined" disabled={!hasChanges} onClick={resetSettings}>
            Reset
          </Button>
          <Button fullWidth variant="contained" disabled={!tempApiBaseBranch} onClick={handleSettingsMenuClose}>
            Done
          </Button>
        </Flex>
      </ResponsivePopUp>
      <ResponsivePopUp
        heading={<Text font="display.large">Branch Change</Text>}
        isOpen={showBranchConfirm}
        onClose={() => {
          setShowBranchConfirm(false)
        }}
      >
        <Box px="lg" pb="xl" mt="lg" textAlign="center">
          <Text px="lg">Would you like to apply branch changes?</Text>
          <Flex gapX="lg" px="lg" mt="xl" pb="lg" br="sm">
            <Button
              variant="outlined"
              onClick={() => {
                setShowBranchConfirm(false)
              }}
              fullWidth
            >
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setApiDomain(tempApiDomain)
                setApiBaseBranch(tempApiBaseBranch)
                setShowBranchConfirm(false)
                setSettingsMenuOpen(false)
                enqueueToast(<ApiDomainChangeToast />, { variant: 'error', duration: 10000 })
              }}
              fullWidth
            >
              Apply
            </Button>
          </Flex>
        </Box>
      </ResponsivePopUp>
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  apiBaseBranch: state.uicontrols.apiBaseBranch,
  apiDomain: state.uicontrols.apiDomain,
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ setApiBaseBranch, setApiDomain }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SettingsMenu)
