import { useEffect, useRef, useState } from 'react'
import { Box, Flex, Text } from '@sofascore/ui'
import { useSelector, useDispatch } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useToast } from 'shared/lib'
import { useGoogleSignIn } from 'features/Auth'
import { LanguagePicker } from 'shared/ui'

import { IconEditorLogo } from 'components/Icons/IconEditorLogo'
import { IconLogoLettering } from 'components/Icons/IconLogoLettering'
import { getErrorMessageId } from 'utils'
import { getAuthFromState } from 'store/selectors'
import { IconFacebook } from 'components/Icons/IconFacebook'
import { Button } from 'components/Button'
import ResponsivePopUp from 'components/ResponsivePopUp'
import { facebookLogin, startCredentialsManagementProcess } from 'store/auth/actions'

import { MobileEULabel } from './MobileEULabel'
import { AboutSofascoreEditor } from '../AboutSofascoreEditor'
import * as S from '../styles'

export const MobileLogin = () => {
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { enqueueToast } = useToast()
  const intl = useIntl()

  const { userAccount, error, _persist } = useSelector(getAuthFromState)
  const id = userAccount ? userAccount.id : null
  const googleSignInContainerRef = useRef<HTMLDivElement>(null)

  useGoogleSignIn({ divRef: googleSignInContainerRef, disableOneTap: true, navigate })

  useEffect(() => {
    if (!id) {
      dispatch(startCredentialsManagementProcess(navigate))
    } else {
      navigate('/home')
    }
  }, [id, dispatch, _persist.rehydrated, navigate])

  useEffect(() => {
    if (error) {
      const messageId = getErrorMessageId(id, error)
      if (messageId) {
        enqueueToast(intl.formatMessage({ id: messageId }), { variant: 'error' })
      }
    }
  }, [error, intl, id, enqueueToast])

  return (
    <S.LoginContainer direction="column" h="100%">
      <Flex direction="column" h="100%" my={['xxl', 96]} px="xxl">
        <Box mb={60}>
          <LanguagePicker showArrow />
        </Box>
        <Flex direction="column" align="flex-start" maxW={328} w="100%" m="0 auto">
          <Flex align="center" gapX="sm">
            <IconEditorLogo fill="#222226" />
            <IconLogoLettering fill="#222226" />
          </Flex>

          <Box py="lg" maxW={264}>
            <Text font="body.mediumParagraph" display="block" color="onSurface.nLv1">
              <FormattedMessage id="login_editor_description" />
            </Text>
          </Box>
        </Flex>

        <Flex direction="column" gapY="md" py="lg" mt="xl" maxW={328} m="0 auto">
          {/** Google Login */}
          <div ref={googleSignInContainerRef}>
            <S.AuthProviderButton>
              <S.AuthProviderButtonText>
                <FormattedMessage id="login_google" />
              </S.AuthProviderButtonText>
            </S.AuthProviderButton>
          </div>

          {/** Facebook Login */}
          <S.AuthProviderButton onClick={() => dispatch(facebookLogin(navigate))}>
            <IconFacebook fill="#0865FE" width={22} height={22} />
            <S.AuthProviderButtonText font="body.medium">
              <FormattedMessage id="facebookSignIn" />
            </S.AuthProviderButtonText>
          </S.AuthProviderButton>

          <Flex direction="column" align="flex-start" gapY="xl" mt="64px !important">
            <Button variant="text" onClick={() => setIsOpen(true)} style={{ padding: 0, background: 'transparent' }}>
              <Text font="body.mediumParagraph" textTransform="capitalize" style={{ textDecoration: 'underline' }}>
                <FormattedMessage id="aboutEditor" />
              </Text>
            </Button>

            <ResponsivePopUp heading={<Box grow={1} />} isOpen={isOpen} onClose={() => setIsOpen(false)}>
              <AboutSofascoreEditor />

              <Box p="lg" mt="xl">
                <Button variant="contained" fullWidth onClick={() => setIsOpen(false)}>
                  <FormattedMessage id="gotIt" />
                </Button>
              </Box>
            </ResponsivePopUp>
          </Flex>
        </Flex>
      </Flex>
      <MobileEULabel />
    </S.LoginContainer>
  )
}
