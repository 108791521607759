import { useMemo } from 'react'
import { Box, Button, Flex, Input, Text, Autocomplete } from '@sofascore/ui'
import { FieldArray, Formik, FormikHelpers } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import { SearchUniqueTournamentsResponse, UniqueTournamentDetailsResponse } from 'entities/UniqueTournament'
import { SearchTeamResponse, TeamImage } from 'entities/Team'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import { useToast } from 'shared/lib'
import { useQuerySearch } from 'entities/Search/hooks'

import { teamSearch, searchUniqueTournaments, uniqueTournamentDetails } from 'api/routes'
import { useMyCompetitionsState } from 'modules/MyCompetitions/providers/MyCompetitionsProvider'

import { UniqueTournamentRepeater } from '../../common/UniqueTournamentRepeater'
import { TeamRepeater } from '../../common/TeamRepeater'
import { TeamOption } from '../../common/TeamOption'
import { UniqueTournamentOption } from '../../common/UniqueTournamentOption'
import { type InfoGeneralFormType } from '../model'
import { updateUniqueTournamentDetails } from '../api'
import { infoGeneralFormDTO, infoGeneralFormInitialValuesDTO } from '../helpers'
import * as S from './../../styles'
import { InfoGeneralFormSchema } from '../schema'

export const AdminInfoGeneral = () => {
  const intl = useIntl()
  const { competitionId } = useParams()
  const { enqueueToast } = useToast()
  const { uniqueTournament } = useMyCompetitionsState()
  const sport = uniqueTournament?.category.sport.slug

  const { data: utData, mutate } = useSWR<UniqueTournamentDetailsResponse>(
    competitionId ? uniqueTournamentDetails(+competitionId) : null,
  )

  const {
    results: utSearchResults,
    handleQueryChange: handleUtQueryChange,
    noOptionsText: utNoOptionsText,
  } = useQuerySearch<SearchUniqueTournamentsResponse>(2, searchUniqueTournaments, sport)

  const {
    results: teamsSearchResults,
    handleQueryChange: handleTeamsQueryChange,
    noOptionsText: teamsNoOptionsText,
  } = useQuerySearch<SearchTeamResponse>(2, teamSearch, sport)

  const parseInitialValues = useMemo(() => {
    if (!utData || !competitionId) return {}
    return infoGeneralFormInitialValuesDTO(utData.uniqueTournament)
  }, [utData, competitionId])

  const onSubmit = async (values: InfoGeneralFormType, formikHelpers: FormikHelpers<InfoGeneralFormType>) => {
    try {
      const processedValues = infoGeneralFormDTO(values)
      const apiResponse = await updateUniqueTournamentDetails(Number(competitionId), processedValues)
      await mutate(apiResponse, false)
      formikHelpers.resetForm()
      enqueueToast(intl.formatMessage({ id: 'toastSuccess' }), { variant: 'success' })
    } catch (error) {
      enqueueToast(intl.formatMessage({ id: 'toastError' }), { variant: 'error' })
    }
  }

  return (
    <Formik
      initialValues={InfoGeneralFormSchema.cast(parseInitialValues)}
      validationSchema={InfoGeneralFormSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {formik => (
        <Flex direction="column" bg="surface.s1" elevation={2} br="sm" mt="sm" p="lg">
          <form onSubmit={formik.handleSubmit}>
            <Flex direction="column" bg="surface.s2" elevation={2} br="sm" p="lg" mb="lg">
              <Text fontWeight="bold" fontSize="medium" mb="lg">
                <FormattedMessage id="division" />
              </Text>
              <Flex style={{ gap: '16px' }} wrap={['wrap', 'nowrap']} mb="lg">
                <Box w="100%">
                  <Input
                    label={intl.formatMessage({ id: 'tier' })}
                    onChange={value => formik.setFieldValue('tier', value.replace(/[^0-9]/g, ''))}
                    value={formik?.values?.tier?.toString()}
                    isValid={!formik.errors.tier}
                    regexGuard={/^[0-9]*$/}
                    bg="surface.s1"
                  />
                </Box>
                <Box w="100%" display={['none', 'block']}></Box>
              </Flex>
              <Flex style={{ gap: '16px' }} wrap={['wrap', 'nowrap']}>
                <FieldArray
                  name="lowerDivisionIds"
                  render={arrayHelpers => (
                    <Box w="100%" position="relative">
                      <S.SearchAdornment w={24} h={24} fill="onSurface.nLv1" />
                      <Autocomplete
                        label={intl.formatMessage({ id: 'lower_division' })}
                        onChange={value => arrayHelpers.push(value)}
                        iconColor="onSurface.nLv1"
                        filterOptions={x => !!x}
                        emptyOnChange
                        clearable={false}
                        noOptionsText={utNoOptionsText}
                        onQueryChange={handleUtQueryChange}
                        options={utSearchResults}
                        inputProps={{
                          bg: 'surface.s1',
                        }}
                        renderOption={option => <UniqueTournamentOption option={option} />}
                      />
                      <UniqueTournamentRepeater
                        selected={formik.values.lowerDivisionIds}
                        onRemove={arrayHelpers.remove}
                      />
                    </Box>
                  )}
                />
                <FieldArray
                  name="upperDivisionIds"
                  render={arrayHelpers => (
                    <Box w="100%" position="relative">
                      <S.SearchAdornment w={24} h={24} fill="onSurface.nLv1" />
                      <Autocomplete
                        label={intl.formatMessage({ id: 'upper_division' })}
                        onChange={value => arrayHelpers.push(value)}
                        emptyOnChange
                        clearable={false}
                        filterOptions={x => !!x}
                        noOptionsText={utNoOptionsText}
                        onQueryChange={handleUtQueryChange}
                        options={utSearchResults}
                        inputProps={{
                          bg: 'surface.s1',
                        }}
                        renderOption={option => <UniqueTournamentOption option={option} />}
                      />
                      <UniqueTournamentRepeater
                        selected={formik.values.upperDivisionIds}
                        onRemove={arrayHelpers.remove}
                      />
                    </Box>
                  )}
                />
              </Flex>
            </Flex>
            <Flex direction="column" bg="surface.s2" elevation={2} br="sm" p="lg" mb="lg">
              <Text fontWeight="bold" fontSize="medium" mb="lg">
                <FormattedMessage id="titles" />
              </Text>
              <Flex style={{ gap: '16px' }} mb="lg" wrap={['wrap', 'nowrap']}>
                <Box w="100%" position="relative">
                  <S.SearchAdornment w={24} h={24} fill="onSurface.nLv1" />
                  <Autocomplete
                    label={intl.formatMessage({ id: 'title_holder' })}
                    onChange={value => formik.setFieldValue('titleHolderId', value)}
                    value={formik?.values?.titleHolderId || undefined}
                    noOptionsText={teamsNoOptionsText}
                    onQueryChange={handleTeamsQueryChange}
                    options={teamsSearchResults}
                    onClear={() => formik.setFieldValue('titleHolderId', null)}
                    errorText={formik.errors.titleHolderId ? intl.formatMessage({ id: 'requiredField' }) : ''}
                    startAdornment={
                      formik.values.titleHolderId && (
                        <Box mr="xs">
                          <TeamImage
                            team={{
                              id: formik.values.titleHolderId.value,
                              name: formik.values.titleHolderId.label,
                            }}
                            size={16}
                          />
                        </Box>
                      )
                    }
                    inputProps={{
                      bg: 'surface.s1',
                    }}
                    w="100%"
                    renderOption={option => <TeamOption option={option} />}
                  />
                </Box>
                <Input
                  label={intl.formatMessage({ id: 'number_of_titles_won' })}
                  onChange={value => formik.setFieldValue('titleHolderTitles', value.replace(/[^0-9]/g, ''))}
                  value={formik?.values?.titleHolderTitles?.toString() || ''}
                  isValid={!formik.errors.titleHolderTitles}
                  w="100%"
                  bg="surface.s1"
                  regexGuard={/^[0-9]*$/}
                />
              </Flex>
              <Flex style={{ gap: '16px' }} wrap={['wrap', 'nowrap']}>
                <Box w="100%" position="relative">
                  <FieldArray
                    name="mostTitlesTeamIds"
                    render={arrayHelpers => (
                      <Box w="100%" position="relative">
                        <S.SearchAdornment w={24} h={24} fill="onSurface.nLv1" />
                        <Autocomplete
                          label={intl.formatMessage({ id: 'most_titles_won' })}
                          onChange={value => arrayHelpers.push(value)}
                          emptyOnChange
                          clearable={false}
                          filterOptions={x => !!x}
                          noOptionsText={teamsNoOptionsText}
                          onQueryChange={handleTeamsQueryChange}
                          options={teamsSearchResults}
                          inputProps={{
                            bg: 'surface.s1',
                          }}
                          renderOption={option => <TeamOption option={option} />}
                          errorText={formik.errors.mostTitlesTeamIds ? intl.formatMessage({ id: 'requiredField' }) : ''}
                        />
                        <TeamRepeater selected={formik.values.mostTitlesTeamIds} onRemove={arrayHelpers.remove} />
                      </Box>
                    )}
                  />
                </Box>
                <Box w="100%">
                  <Input
                    label={intl.formatMessage({ id: 'number_of_titles_won' })}
                    onChange={value => formik.setFieldValue('mostTitles', value.replace(/[^0-9]/g, ''))}
                    value={formik?.values?.mostTitles?.toString() || ''}
                    isValid={!formik.errors.mostTitles}
                    w="100%"
                    bg="surface.s1"
                    regexGuard={/^[0-9]*$/}
                  />
                </Box>
              </Flex>
            </Flex>
            <Flex justify="end">
              <Button disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}>
                {intl.formatMessage({ id: 'save' })}
              </Button>
            </Flex>
          </form>
        </Flex>
      )}
    </Formik>
  )
}
