import { Flex, Image } from '@sofascore/ui'

import IncidentBox from 'components/IncidentBox'
import { teamImage } from 'api/routes'

import { BasicTeam } from '../model'

interface TeamPickerProps {
  homeTeam: BasicTeam
  awayTeam: BasicTeam
  teamId: number
  onChangeTeam: (newTeamId: number) => void
}

export const TeamPicker = ({ homeTeam, awayTeam, teamId, onChangeTeam }: TeamPickerProps) => {
  return (
    <Flex mb="lg" px="xxl" justify="center">
      <IncidentBox
        label={homeTeam.name}
        isActive={teamId === homeTeam.id}
        onClick={() => onChangeTeam(homeTeam.id)}
        icon={<Image src={teamImage(homeTeam.id)} w={24} h={24} alt={homeTeam.name} />}
        mx="sm"
      />
      <IncidentBox
        label={awayTeam.name}
        isActive={teamId === awayTeam.id}
        onClick={() => onChangeTeam(awayTeam.id)}
        icon={<Image src={teamImage(awayTeam.id)} w={24} h={24} alt={awayTeam.name} />}
        mx="sm"
      />
    </Flex>
  )
}
