import { Flex } from '@sofascore/ui'
import { PropsWithChildren } from 'react'

export const IncidentFrame = ({ children }: PropsWithChildren) => {
  return (
    <Flex direction="row" align="center" bg="surface.s1" p="sm" br="sm" elevation={1}>
      {children}
    </Flex>
  )
}
