import range from 'lodash/range'
import { MatchTime } from 'entities/MatchTime'
import { BasicEvent } from 'entities/Event'
import { Incident } from 'entities/Incident'
import {
  AFTER_EXTRA_TIME_CODE,
  AFTER_PENALTIES_CODE,
  AWAITING_EXTRA_TIME_CODE,
  AWAITING_PENALTIES_CODE,
  ENDED_CODE,
  EXTRA_TIME_HALF_CODE,
  FIRST_EXTRA_TIME_CODE,
  FIRST_HALF_CODE,
  HALF_TIME_CODE,
  PENALTIES_CODE,
  SECOND_EXTRA_TIME_CODE,
  SECOND_HALF_CODE,
  StatusType,
} from 'entities/Status'

import { Option } from 'components/DropdownMenu/interface'

import { getTimeParts } from './time'

export const ADDITIONAL_TIME_DISPLAY_LIMIT = 15

/**
 * Helper function that returns true if the match went directly
 * to penalties, meaning no extra time was played in between
 * (behaviour specific for football super cups).
 */
export const isPenaltiesOnly = (event: BasicEvent) => {
  return (
    event.time?.extra1StartTimestamp == null &&
    event.time?.extra2StartTimestamp == null &&
    [PENALTIES_CODE, AFTER_PENALTIES_CODE].includes(event.status.code)
  )
}

/**
 * Helper function that calculates possible max time (equivalent
 * to `event.time.max`) for periods in which timer is stopped
 * (`event.time.initial` and `event.time.max` are undefined).
 */
export const getCurrentPeriodMaxTime = (event: BasicEvent) => {
  const defaultPeriodLength = event.defaultPeriodLength || event.time?.periodLength
  const defaultOvertimeLength = event.defaultOvertimeLength || event.time?.overtimeLength

  if (!defaultPeriodLength || !defaultOvertimeLength) return 0

  // Half durations in seconds
  const regularHalfDuration = defaultPeriodLength * 60
  const extraHalfDuration = defaultOvertimeLength * 60

  switch (event.status.code) {
    case FIRST_HALF_CODE:
    case HALF_TIME_CODE:
      return regularHalfDuration
    case SECOND_HALF_CODE:
    case ENDED_CODE:
    case AWAITING_EXTRA_TIME_CODE:
      return 2 * regularHalfDuration
    case FIRST_EXTRA_TIME_CODE:
    case EXTRA_TIME_HALF_CODE:
      return 2 * regularHalfDuration + extraHalfDuration
    case SECOND_EXTRA_TIME_CODE:
    case AWAITING_PENALTIES_CODE:
    case PENALTIES_CODE:
    case AFTER_PENALTIES_CODE:
    case AFTER_EXTRA_TIME_CODE:
      return 2 * regularHalfDuration + 2 * extraHalfDuration
    default:
      return regularHalfDuration
  }
}

/**
 * Helper function that calculates possible initial time (equivalent
 * to `event.time.initial`) for periods in which timer is stopped
 * (`event.time.initial` and `event.time.max` are undefined).
 */
export const getCurrentPeriodInitialTime = (event: BasicEvent) => {
  const defaultPeriodLength = event.defaultPeriodLength || event.time?.periodLength
  const defaultOvertimeLength = event.defaultOvertimeLength || event.time?.overtimeLength

  if (!defaultPeriodLength || !defaultOvertimeLength) return 0

  // Half durations in seconds
  const regularHalfDuration = defaultPeriodLength * 60
  const extraHalfDuration = defaultOvertimeLength * 60

  switch (event.status.code) {
    case FIRST_HALF_CODE:
    case HALF_TIME_CODE:
      return 0
    case SECOND_HALF_CODE:
    case ENDED_CODE:
    case AWAITING_EXTRA_TIME_CODE:
      return regularHalfDuration
    case FIRST_EXTRA_TIME_CODE:
    case EXTRA_TIME_HALF_CODE:
      return 2 * regularHalfDuration
    case SECOND_EXTRA_TIME_CODE:
    case AWAITING_PENALTIES_CODE:
    case PENALTIES_CODE:
    case AFTER_PENALTIES_CODE:
    case AFTER_EXTRA_TIME_CODE:
      return 2 * regularHalfDuration + extraHalfDuration
    default:
      return 0
  }
}

export const getCurrentPeriodTimeBoundaries = (event: BasicEvent) => {
  const start = getCurrentPeriodInitialTime(event) / 60 + 1
  const end = getCurrentPeriodMaxTime(event) / 60

  return [start, end]
}

export const getCurrentMatchTime = (
  event: BasicEvent,
  additionalTimeDisplayLimit = ADDITIONAL_TIME_DISPLAY_LIMIT,
): MatchTime => {
  const defaultTime = { time: 1 }

  if (event.time === undefined) return defaultTime

  if (event.time.currentPeriodStartTimestamp === undefined || event.status.type === StatusType.FINISHED) {
    return {
      time: getCurrentPeriodMaxTime(event) / 60,
      addedTime: additionalTimeDisplayLimit,
    }
  }

  const initial = getCurrentPeriodInitialTime(event)
  const max = getCurrentPeriodMaxTime(event)

  const startTimestamp = event.time.currentPeriodStartTimestamp - initial
  const secondsNow = Math.floor(Date.now() / 1000)
  const secondsElapsed = secondsNow - startTimestamp

  let [, hours, minutes, seconds] = getTimeParts(secondsElapsed)

  if (max && secondsElapsed > max) {
    const extra = secondsElapsed - max

    /* Cap for extra time display */
    if (extra > additionalTimeDisplayLimit * 60) {
      return {
        time: max / 60,
        addedTime: additionalTimeDisplayLimit,
      }
    }

    seconds = Math.floor(extra % 60)
    minutes = Math.floor(extra / 60) % 60
    hours = Math.floor(extra / 3600) % 24

    // Additional time
    return {
      time: max / 60,
      addedTime: Math.round(extra / 60) + 1,
    }
  }

  minutes = hours * 60 + minutes

  if (isNaN(minutes) || isNaN(seconds)) {
    return defaultTime
  }

  // Regular time
  return {
    time: minutes + 1,
  }
}

export const formatMatchTimeToOptionValue = (matchTime: MatchTime): string => {
  return matchTime.addedTime ? `${matchTime.time}-${matchTime.addedTime}` : matchTime.time.toString()
}

export const formatTimeOptionValueToMatchTime = (optionValue: string): MatchTime => {
  const time = optionValue.split('-')

  return {
    time: parseInt(time[0]),
    addedTime: time[1] ? parseInt(time[1]) : undefined,
  }
}

export const isAfter = (aTime: MatchTime, bTime: MatchTime) => {
  if (aTime.time > bTime.time) return true

  if (aTime.time === bTime.time && aTime.addedTime && bTime.addedTime) return aTime.addedTime > bTime.addedTime

  return false
}

/**
 * Base function for generating time options for WheelPicker in football
 * incident-related forms. Should not be called directly in components.
 *
 * -> 1', 2', ... , 45', 45+1', 45+2', 45+15', ... , 90+15'
 */
const generateFootballMatchTimeOptionsBetween = (
  event: BasicEvent,
  start: number,
  end: number,
  additionalTimeDisplayLimit = ADDITIONAL_TIME_DISPLAY_LIMIT,
) => {
  const defaultPeriodLength = event.defaultPeriodLength || 45
  const defaultOvertimeLength = event.defaultOvertimeLength || 15

  const opts: Option[] = []

  range(start, end + 1).forEach(base => {
    opts.push({ label: `${base}'`, value: `${base}` })

    if (
      base === defaultPeriodLength ||
      base === defaultPeriodLength * 2 ||
      base === defaultPeriodLength * 2 + defaultOvertimeLength ||
      base === defaultPeriodLength * 2 + 2 * defaultOvertimeLength
    ) {
      range(0, additionalTimeDisplayLimit).forEach(additional => {
        opts.push({ label: `${base}+${additional + 1}'`, value: `${base}-${additional + 1}` })
      })
    }
  })

  return opts
}

/**
 * Generates time options for WheelPicker in football incident-related forms
 * based on current period (time) of the match. Should be used on all CREATE
 * and EDIT forms except the one for adding goal (`AddGoalForm.tsx`) and the
 * one for editing goal (`EditLastGoalForm.tsx`).
 *
 * -> 1', 2', ... , 45', 45+1', 45+2', 45+15', ... , 90+15'
 */
export const generateCurrentFootballMatchTimeOptions = (
  event: BasicEvent,
  additionalTimeDisplayLimit = ADDITIONAL_TIME_DISPLAY_LIMIT,
) => {
  if (!event.time) return []

  const end = getCurrentPeriodMaxTime(event) / 60

  return generateFootballMatchTimeOptionsBetween(event, 1, end, additionalTimeDisplayLimit)
}

/**
 * Generates time options for WheelPicker in football incident-related forms
 * based on current period (time) of the match, but the options before the
 * provided incident are removed. Should be used ONLY in form for editing
 * last goal (`EditLastGoalForm.tsx`)
 *
 * -> 1', 2', ... , 45', 45+1', 45+2', 45+15', ... , 90+15'
 */
export const generateFootballGoalMatchTimeOptions = (
  event: BasicEvent,
  editingGoal: Incident,
  previousGoal?: Incident,
  additionalTimeDisplayLimit = ADDITIONAL_TIME_DISPLAY_LIMIT,
) => {
  const baseOptions = generateCurrentFootballMatchTimeOptions(event, additionalTimeDisplayLimit)

  if (!editingGoal.time || !event.time || !previousGoal) return baseOptions

  const previousGoalTime = {
    time: previousGoal ? previousGoal.time! : 1,
    addedTime: previousGoal ? previousGoal.addedTime : undefined,
  }

  const lastGoalTimeOptionValue = formatMatchTimeToOptionValue(previousGoalTime)
  const lastGoalTimeIndex = baseOptions.findIndex(option => option.value === lastGoalTimeOptionValue)

  return baseOptions.filter((_, index) => index >= lastGoalTimeIndex)
}

/**
 * Generates time options for WheelPicker in football incident-related forms
 * based on current period (time) of the match, but the options before the
 * provided incident are removed. Should be used ONLY in form for adding
 * goal (`AddGoalForm.tsx`)
 *
 * -> 1', 2', ... , 45', 45+1', 45+2', 45+15', ... , 90+15'
 */
export const generateFootballGoalMatchTimeOptionsAfter = (
  event: BasicEvent,
  previousGoal?: Incident,
  additionalTimeDisplayLimit = ADDITIONAL_TIME_DISPLAY_LIMIT,
) => {
  const baseOptions = generateCurrentFootballMatchTimeOptions(event, additionalTimeDisplayLimit)

  if (!previousGoal || !event.time) return baseOptions

  const previousGoalTime = {
    time: previousGoal ? previousGoal.time! : 1,
    addedTime: previousGoal ? previousGoal.addedTime : undefined,
  }

  const lastGoalTimeOptionValue = formatMatchTimeToOptionValue(previousGoalTime)
  const lastGoalTimeIndex = baseOptions.findIndex(option => option.value === lastGoalTimeOptionValue)

  return baseOptions.filter((_, index) => index >= lastGoalTimeIndex)
}
