import * as React from 'react'

import { textPrimary } from 'styles/color'

import { IconBase, IconProps } from './Base'

export const IconPitch = (props: IconProps) => (
  <IconBase width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      d="M4 4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H4zm0 2h7v2.13c-1.76.46-3 2.05-3 3.87a4.01 4.01 0 0 0 3 3.87V18H4v-2h3V8H4V6zm9 0h7v2h-3v8h3v2h-7v-2.13c1.76-.46 3-2.05 3-3.87a4.01 4.01 0 0 0-3-3.87V6zm-9 4h1v4H4v-4zm15 0h1v4h-1v-4zm-6 .27c.62.36 1 1.02 1 1.73s-.38 1.37-1 1.73v-3.46zm-2 0v3.46c-.62-.36-1-1.02-1-1.73s.38-1.37 1-1.73z"
      fill={props.fill || textPrimary}
      fillRule="evenodd"
    />
  </IconBase>
)
