import { makeStyles, Theme, createStyles } from '@material-ui/core'

import { backgrounds } from 'styles/color'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerPaper: {
      width: '50%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    liveStatusContainer: {
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: 172,
      backgroundColor: backgrounds.content,
      padding: theme.spacing(2),
    },

    statusContainer: {
      height: 110,
      backgroundColor: backgrounds.content,
      boxSizing: 'border-box',
      padding: theme.spacing(2),
    },

    progressBar: {
      padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    },

    timeInfo: {
      textTransform: 'capitalize',
    },

    statusDescription: {
      color: theme.palette.secondary.main,
    },
  }),
)
