import { Flex, Text, Image, ZIndex } from '@sofascore/ui'
import { NavLink, useParams } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import IconArrowBack from '@sofascore/ui/dist/modules/Icons/IconArrowBack'
import { getCompetitionCountryFlag, UniqueTournamentImage } from 'entities/UniqueTournament'
import { FormattedCategoryName, formatCategoryName } from 'entities/Category'

import { useMyCompetitionsState } from 'modules/MyCompetitions/providers/MyCompetitionsProvider'

import * as S from './styles'

export const AdminHeader = () => {
  const { competitionId, seasonId } = useParams()
  const { uniqueTournament } = useMyCompetitionsState()
  const intl = useIntl()

  const category = uniqueTournament?.category

  return (
    <S.MyCompetitionHeader
      forwardedAs="header"
      mb={[0, 'sm']}
      br={[0, 'sm']}
      bg="surface.s1"
      zIndex={ZIndex.Header}
      elevation={2}
    >
      <Flex w="100%" h={[48, 'auto']} px="lg" pt={['sm', 'lg']} pb={[0, 'sm']} align="center">
        <Flex display={['flex', 'none']} mr="sm" align="center">
          <NavLink
            to="/my-competitions"
            style={{ width: 30, height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <IconArrowBack />
          </NavLink>
        </Flex>

        {uniqueTournament && (
          <>
            <UniqueTournamentImage
              id={uniqueTournament.id}
              w={[32, 72]}
              h={[32, 72]}
              name={uniqueTournament.name}
              mr="lg"
            />

            <Flex direction="column" justify="space-between">
              <Text font={['assistive.default', 'display.extraLarge']}>{uniqueTournament.name}</Text>

              <Flex mt={[0, 'sm']}>
                <Flex display={['none', 'flex']} mr="sm" h={32} align="center">
                  <Image
                    src={getCompetitionCountryFlag(uniqueTournament.category)}
                    w="24"
                    h="24"
                    mr="sm"
                    alt={category ? formatCategoryName({ slug: category.slug, name: category.name, intl }) : ''}
                  />
                  {category && (
                    <Text font="display.small">
                      <FormattedCategoryName slug={category.slug} name={category.name} />
                    </Text>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </>
        )}
      </Flex>

      <S.ScrollingTabs w="100%" br={[0, 'sm']} overflowY="hidden">
        <S.Link to={`/my-competitions/${competitionId}/season/${seasonId}/settings`}>
          <Text font="body.medium" color="inherit" whiteSpace="nowrap">
            <FormattedMessage id="info" />
          </Text>
        </S.Link>
        <S.Link to={`/my-competitions/${competitionId}/season/${seasonId}/settings/regulations`}>
          <Text font="body.medium" color="inherit" whiteSpace="nowrap">
            <FormattedMessage id="regulations" />
          </Text>
        </S.Link>
      </S.ScrollingTabs>
    </S.MyCompetitionHeader>
  )
}

export default AdminHeader
