import { Box, Flex, Image, Text } from '@sofascore/ui'
import IconAdd from '@sofascore/ui/dist/modules/Icons/IconAdd'
import IconMinimize from '@sofascore/ui/dist/modules/Icons/IconMinimize'
import { StatusType } from 'entities/Status'
import { EventActionType, useEventDispatch, useEventState } from 'entities/Event'

import { teamImage } from 'api/routes'
import { ScoreInput } from 'components/ScoreInput'

import * as S from './styles'

export const ChangeScoreSectionFRO = () => {
  const { event } = useEventState()
  const { homeTeam, awayTeam, homeScore, awayScore, status } = event
  const dispatch = useEventDispatch()

  const isInProgress = status.type === StatusType.IN_PROGRESS

  // In FRO mode, we always increment/decrement score by 1

  const incrementHomeTeamScore = () => {
    dispatch({ type: EventActionType.INCREMENT_SCORE, payload: { isHome: true, points: 1 } })
  }

  const decrementHomeTeamScore = () => {
    dispatch({ type: EventActionType.DECREMENT_SCORE, payload: { isHome: true, points: 1 } })
  }

  const incrementAwayTeamScore = () => {
    dispatch({ type: EventActionType.INCREMENT_SCORE, payload: { isHome: false, points: 1 } })
  }

  const decrementAwayTeamScore = () => {
    dispatch({ type: EventActionType.DECREMENT_SCORE, payload: { isHome: false, points: 1 } })
  }

  const handleHomeScoreChange = (newScore: number) => {
    dispatch({
      type: EventActionType.SET_SCORES,
      payload: { isHome: true, homeScore: newScore, awayScore: awayScore.display },
    })
  }

  const handleAwayScoreChange = (newScore: number) => {
    dispatch({
      type: EventActionType.SET_SCORES,
      payload: { isHome: false, homeScore: homeScore.display, awayScore: newScore },
    })
  }

  const homeScoreValue = homeScore.display || 0
  const awayScoreValue = awayScore.display || 0

  return (
    <>
      <Box bg="surface.s1" p="lg" br="sm" textAlign="center" elevation={1}>
        <Flex justify="space-between">
          <Flex align="center" direction="column" minW={120}>
            <S.HomeTeamBoxName px={[0, 'lg']} mb="md" justify={['flex-start', 'center']}>
              <Text font="display.micro" align={['left', 'center']} color="inherit">
                {homeTeam.name}
              </Text>
            </S.HomeTeamBoxName>

            <Flex align="flex-end" justify="center">
              <Image src={teamImage(homeTeam.id)} w={[32, 40]} h={[32, 40]} alt={homeTeam.name} />
            </Flex>

            <Flex gapX="xl" mt="md">
              <S.AddScoreButtonFRO onClick={decrementHomeTeamScore}>
                <IconMinimize fill="#000000" />
              </S.AddScoreButtonFRO>

              <S.AddScoreButtonFRO onClick={incrementHomeTeamScore}>
                <IconAdd fill="#000000" />
              </S.AddScoreButtonFRO>
            </Flex>
          </Flex>

          <Flex flex={1} align="center" justify="center">
            <ScoreInput
              value={homeScoreValue}
              color={homeScoreValue > awayScoreValue ? undefined : 'onSurface.nLv3'}
              onChange={newScore => handleHomeScoreChange(newScore)}
              textAlign="right"
            />

            <Text font="display.extraLarge" color={isInProgress ? 'primary.default' : 'onSurface.nLv3'} mx="sm">
              -
            </Text>

            <ScoreInput
              value={awayScoreValue}
              color={awayScoreValue > homeScoreValue ? undefined : 'onSurface.nLv3'}
              onChange={newScore => handleAwayScoreChange(newScore)}
            />
          </Flex>

          <Flex align="center" direction="column" minW={120}>
            <S.HomeTeamBoxName px={[0, 'lg']} mb="md" justify={['flex-start', 'center']}>
              <Text font="display.micro" align={['left', 'center']} color="inherit">
                {awayTeam.name}
              </Text>
            </S.HomeTeamBoxName>

            <Flex align="flex-end" justify="center">
              <Image src={teamImage(awayTeam.id)} w={[32, 40]} h={[32, 40]} alt={awayTeam.name} />
            </Flex>

            <Flex gapX="xl" mt="md">
              <S.AddScoreButtonFRO onClick={decrementAwayTeamScore}>
                <IconMinimize fill="#000000" />
              </S.AddScoreButtonFRO>

              <S.AddScoreButtonFRO onClick={incrementAwayTeamScore}>
                <IconAdd fill="#000000" />
              </S.AddScoreButtonFRO>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </>
  )
}
