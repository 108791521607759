import { BasicEvent } from 'entities/Event'
import { redesignedSports, sportsWithGeneralEdit } from 'entities/Sport'
import { PropsWithChildren } from 'react'
import { NavLink } from 'react-router-dom'

interface Props {
  event: BasicEvent
  navState?: unknown
}

const EventLink = ({ event, navState, children }: PropsWithChildren<Props>) => {
  const { tournament, season, id, feedLocked } = event

  if (feedLocked || !season || !tournament.uniqueTournament) return children

  let eventPath = `/my-competitions/${tournament.uniqueTournament.id}/season/${season.id}/matches/${id}`
  const sport = tournament.category.sport.slug
  if (!redesignedSports.includes(sport) && !sportsWithGeneralEdit.includes(sport)) eventPath += `/post-match`

  return (
    <NavLink to={eventPath} state={navState} style={{ textDecoration: 'none' }}>
      {children}
    </NavLink>
  )
}

export default EventLink
