import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { MediaContextProvider, SofaThemeProvider } from '@sofascore/ui'
import { Children } from 'shared/model'

import {
  textLightColor,
  primaryColor,
  secondaryColor,
  textPrimary,
  textSecondary,
  secondaryColorHover,
  notActiveIconColor,
  RED,
  GREEN,
  backgrounds,
  borders,
  colorTokens,
} from 'styles/color'
import { breakpoints } from 'styles/breakpoints'

const ThemeProvider = ({ children }: Children) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
      },
      text: {
        primary: textPrimary,
        secondary: textSecondary,
        disabled: textLightColor,
      },
      error: {
        main: RED.incident,
        contrastText: RED.action,
      },
      success: {
        main: GREEN.incident,
        contrastText: GREEN.action,
      },
      background: {
        default: backgrounds.content,
        paper: '#fff',
      },
    },
    shape: {
      borderRadius: 2,
    },
    typography: {
      fontFamily: [
        'Sofascore Sans',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Helvetica Neue"',
        '"Segoe UI"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      subtitle1: {
        fontSize: 20,
        fontWeight: 500,
      },
      subtitle2: {
        fontSize: 16,
        fontWeight: 500,
      },
      body2: {
        fontSize: 16,
        color: textLightColor,
      },
      caption: {
        fontSize: 12,
        fontWeight: 500,
      },
      h1: {
        fontSize: 24,
        fontWeight: 500,
      },
    },
    overrides: {
      MuiAvatar: {
        root: {
          borderRadius: 0,
        },
        colorDefault: {
          backgroundColor: 'transparent',
        },
      },
      MuiFab: {
        secondary: {
          '&:hover': {
            backgroundColor: secondaryColorHover,
          },
        },
      },
      MuiPickersToolbarButton: {
        toolbarBtn: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiExpansionPanel: {
        root: {
          '&$expanded': {
            margin: 0,
          },
          '&:before': {
            backgroundColor: 'none',
          },
        },
      },
      MuiExpansionPanelDetails: {
        root: {
          paddingLeft: 32,
          paddingRight: 32,
          paddingTop: 2,
          '@media (max-width: 959px)': {
            paddingLeft: 16,
            paddingRight: 16,
          },
        },
      },
      MuiExpansionPanelSummary: {
        root: {
          paddingLeft: 32,
          paddingRight: 32,
          '@media (max-width: 959px)': {
            paddingLeft: 16,
            paddingRight: 16,
          },
        },
      },
      MuiPickersDay: {
        current: {
          color: 'inherit',
        },
        day: {
          color: 'inherit',
          width: '40px',
          height: '40px',

          '& p': {
            fontSize: '12px',
          },
        },
        daySelected: {
          backgroundColor: secondaryColor,
          '&:hover': {
            backgroundColor: secondaryColor,
            opacity: 0.8,
          },
        },
      },
      // @ts-ignore
      MuiPickersBasePicker: {
        pickerView: {
          minWidth: '330px',
          maxWidth: '340px',
        },
        containerLandscape: {
          flexDirection: 'column',
        },
      },
      MuiPickersCalendar: {
        transitionContainer: {
          minHeight: '240px',
        },
      },
      MuiPickersCalendarHeader: {
        transitionContainer: {
          '& > p': {
            fontSize: '14px !important',
            fontWeight: 500,
          },
        },
        dayLabel: {
          width: '40px',
        },
      },
      MuiButton: {
        textPrimary: {
          color: secondaryColor,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        contained: {
          color: 'white',
          backgroundColor: secondaryColor,
          '&:hover': {
            backgroundColor: secondaryColorHover,
          },
          '&:disabled': {
            backgroundColor: notActiveIconColor,
            color: 'white',
          },
        },
        outlined: {
          color: secondaryColor,
          backgroundColor: 'white',
          border: `2px solid`,
          '&:hover': {
            color: secondaryColorHover,
            backgroundColor: 'white',
          },
          '&:disabled': {
            backgroundColor: 'white',
            color: notActiveIconColor,
          },
        },
        text: {
          color: secondaryColor,
          '&:hover': {
            color: secondaryColorHover,
            backgroundColor: 'white',
          },
          '&:disabled': {
            backgroundColor: 'white',
            color: notActiveIconColor,
          },
        },
      },

      MuiPickersClockPointer: {
        pointer: {
          backgroundColor: secondaryColor,
        },
        thumb: {
          border: `14px solid ${secondaryColor}`,
        },
      },
      MuiPickersClock: {
        pin: {
          backgroundColor: secondaryColor,
        },
      },
      MuiPickersClockNumber: {
        clockNumberSelected: {
          backgroundColor: secondaryColor,
        },
      },

      MuiToolbar: {
        regular: {
          '@media (min-width: 600px)': {
            minHeight: '56px',
          },
        },
        gutters: {
          '@media (max-width: 959px)': {
            paddingLeft: 16,
            paddingRight: 16,
          },
        },
      },
      MuiTableCell: {
        root: {
          '@media (max-width: 1500px)': {
            padding: '14px',
          },
          '@media (max-width: 959px)': {
            fontSize: 15,
            padding: '14px 5px 14px 5px',
            '&:last-child': {
              paddingRight: 6,
            },
            '&:first-child': {
              paddingLeft: 12,
            },
            '@media (max-width: 341px)': {
              padding: '14px 4px 14px 4px',
            },
          },
        },
      },
      MuiTableRow: {
        root: {
          '&:last-child td': {
            borderBottom: 0,
          },
        },
      },
      MuiRadio: {
        colorSecondary: {
          color: secondaryColor,
          '&$disabled': {
            color: secondaryColor,
            opacity: 0.7,
          },
        },
      },
      MuiCheckbox: {
        root: {
          color: secondaryColor,
          '&$checked': {
            color: secondaryColor,
          },
        },
      },
      MuiChip: {
        root: {
          backgroundColor: backgrounds.chip,
        },
        outlined: {
          border: `1.5px solid ${borders.chip}`,
        },
      },
    },
  })

  return (
    <MuiThemeProvider theme={theme}>
      <SofaThemeProvider theme={{ colors: colorTokens, breakpoints: breakpoints, settings: { theme: 'light' } }}>
        <MediaContextProvider>{children}</MediaContextProvider>
      </SofaThemeProvider>
    </MuiThemeProvider>
  )
}

export default ThemeProvider
