import { AnyAction } from 'redux'
import { createContext, useContext, useReducer } from 'react'

import { LineupsState, lineupsReducer } from './reducer'

export const LineupsStateContext = createContext<LineupsState>({} as LineupsState)

const LineupsDispatchContext = createContext<React.Dispatch<AnyAction> | null>(null)

export const LineupsInfoProvider = ({
  initialState,
  children,
}: {
  initialState: LineupsState
  children: React.ReactNode
}) => {
  const [state, dispatch] = useReducer(lineupsReducer, initialState)

  return (
    <LineupsStateContext.Provider value={state}>
      <LineupsDispatchContext.Provider value={dispatch}>{children}</LineupsDispatchContext.Provider>
    </LineupsStateContext.Provider>
  )
}

export const useLineupsState = (): LineupsState => {
  const context = useContext(LineupsStateContext)

  if (!context) {
    throw new Error('useLineupsState must be used within a LineupsInfoProvider')
  }

  return context
}

export const useLineupsDispatch = () => {
  const context = useContext(LineupsDispatchContext)

  if (!context) {
    throw new Error('useLineupsDispatch must be used within a LineupsInfoProvider')
  }

  return context
}
