import { Flex } from '@sofascore/ui'
import { PropsWithChildren } from 'react'

interface IncidentBaseProps {
  isHome: boolean | undefined
}

export const IncidentBody = ({ isHome, children }: PropsWithChildren<IncidentBaseProps>) => {
  return (
    <Flex order={isHome ? '3' : '1'} justify={isHome ? 'flex-start' : 'flex-end'} w="100%" px="sm">
      <Flex direction="column">{children}</Flex>
    </Flex>
  )
}
