/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useToast } from 'shared/lib'

export const useErrorHandler = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { enqueueToast } = useToast()

  const handleError = useCallback(
    (e: any) => {
      if (e.error && e.error.key) {
        enqueueToast(intl.formatMessage({ id: e.error.key }), { variant: 'error' })
      } else {
        enqueueToast(intl.formatMessage({ id: 'toastError' }), { variant: 'error' })
      }

      if (e.error && e.error.key === 'error_match_locked') {
        navigate(-1)
      }
    },
    [navigate, intl, enqueueToast],
  )

  return handleError
}
