import { useIntl } from 'react-intl'
import { UntranslatedOption, Option } from 'shared/model'
import { useMemo, useState } from 'react'

export const useSubstitutionReasonOptions = (config: UntranslatedOption[], incidentClass?: string | undefined) => {
  const intl = useIntl()

  const options = useMemo(() => {
    return config.map(option => {
      return {
        label: intl.formatMessage({ id: option.translationKey }),
        value: option.value,
      }
    })
  }, [intl, config])

  const initialSubstitutionReason = options.find(o => o.value === incidentClass)
  const [substitutionReason, setSubstitutionReason] = useState<Option>(initialSubstitutionReason ?? options[0])

  return { options, substitutionReason, setSubstitutionReason }
}
