import { useEffect, useRef, useState } from 'react'
import { Box, Flex, Text } from '@sofascore/ui'
import { useSelector, useDispatch } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useToast } from 'shared/lib'
import { useGoogleSignIn } from 'features/Auth'
import { LanguagePicker } from 'shared/ui'

import { IconEditorLogo } from 'components/Icons/IconEditorLogo'
import { IconLogoLettering } from 'components/Icons/IconLogoLettering'
import { getErrorMessageId } from 'utils'
import { getAuthFromState } from 'store/selectors'
import { IconFacebook } from 'components/Icons/IconFacebook'
import { Button } from 'components/Button'
import ResponsivePopUp from 'components/ResponsivePopUp'
import { facebookLogin, startCredentialsManagementProcess } from 'store/auth/actions'

import { AboutSofascoreEditor } from '../AboutSofascoreEditor'
import { DesktopEULabel } from './DesktopEULabel'
import * as S from '../styles'

export const DesktopLogin = () => {
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { enqueueToast } = useToast()
  const intl = useIntl()

  const { userAccount, error, _persist } = useSelector(getAuthFromState)
  const id = userAccount ? userAccount.id : null
  const googleSignInContainerRef = useRef<HTMLDivElement>(null)

  useGoogleSignIn({ divRef: googleSignInContainerRef, disableOneTap: true, navigate })

  useEffect(() => {
    if (!id) {
      dispatch(startCredentialsManagementProcess(navigate))
    } else {
      navigate('/home')
    }
  }, [id, dispatch, _persist.rehydrated, navigate])

  useEffect(() => {
    if (error) {
      const messageId = getErrorMessageId(id, error)
      if (messageId) {
        enqueueToast(intl.formatMessage({ id: messageId }), { variant: 'error' })
      }
    }
  }, [error, intl, id, enqueueToast])

  return (
    <S.LoginContainer direction="column" h="100%">
      <Flex direction="column" h="100%" my={['xxl', 96]} ml={[0, 72, 72, 144]}>
        <Flex direction="column" align="flex-start" px="xxl" maxW={392}>
          <Box mb={112}>
            <LanguagePicker showArrow />
          </Box>

          <Flex align="center" gapX="sm">
            <IconEditorLogo />
            <IconLogoLettering />
          </Flex>

          <Box py="lg" w={256}>
            <Text font="body.mediumParagraph" display="block" color="surface.s0">
              <FormattedMessage id="login_editor_description" />
            </Text>
          </Box>
        </Flex>

        <Flex justify="space-between" mt="xxl">
          <Flex direction="column" gapY="md" px="xxl" pt="md" pb="lg" maxW={392}>
            {/** Google Login */}
            <div ref={googleSignInContainerRef}>
              <S.AuthProviderButton>
                <S.AuthProviderButtonText>
                  <FormattedMessage id="login_google" />
                </S.AuthProviderButtonText>
              </S.AuthProviderButton>
            </div>

            {/** Facebook Login */}
            <S.AuthProviderButton onClick={() => dispatch(facebookLogin(navigate))}>
              <IconFacebook fill="#0865FE" width={22} height={22} />
              <S.AuthProviderButtonText font="body.medium">
                <FormattedMessage id="facebookSignIn" />
              </S.AuthProviderButtonText>
            </S.AuthProviderButton>
          </Flex>

          <Box w={428} display={['none', 'block']} textAlign="right" mr={[0, 72, 72, 196]}>
            <Text color="surface.s0" fontSize={38} lineHeight="47px" fontWeight={700} letterSpacing="-0.076px">
              <FormattedMessage id="login_editor_text" />
            </Text>
          </Box>
        </Flex>

        <Flex direction="column" align="flex-start" px="xxl" gapY="xl" mb="xxl" mt="auto">
          <Button variant="text" onClick={() => setIsOpen(true)} style={{ padding: 0, background: 'transparent' }}>
            <Text
              font="body.mediumParagraph"
              textTransform="capitalize"
              color="#fff"
              style={{ textDecoration: 'underline' }}
            >
              <FormattedMessage id="aboutEditor" />
            </Text>
          </Button>

          <ResponsivePopUp heading={<Box grow={1} />} isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <AboutSofascoreEditor />

            <Box p="lg" mt="xl">
              <Button variant="contained" fullWidth onClick={() => setIsOpen(false)}>
                <FormattedMessage id="gotIt" />
              </Button>
            </Box>
          </ResponsivePopUp>

          <DesktopEULabel />
        </Flex>
      </Flex>
    </S.LoginContainer>
  )
}
