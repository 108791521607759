import { OptionSchema } from 'shared/schema'
import * as Yup from 'yup'

export const InfoGeneralFormSchema = Yup.object().shape({
  tier: Yup.number().positive(),
  lowerDivisionIds: Yup.array().of(OptionSchema).default([]),
  upperDivisionIds: Yup.array().of(OptionSchema).default([]),
  titleHolderTitles: Yup.number().positive(),
  titleHolderId: OptionSchema.nullable()
    .when('titleHolderTitles', {
      is: (titleHolderTitles: number) => !!titleHolderTitles,
      then: schema => schema.required(),
      otherwise: schema => schema.nullable().notRequired(),
    })
    .default(null),
  mostTitles: Yup.number().positive(),
  mostTitlesTeamIds: Yup.array()
    .of(OptionSchema)
    .default([])
    .when('mostTitles', {
      is: (mostTitles: number) => !!mostTitles,
      then: schema => schema.min(1).required(),
    }),
})

export const SeasonGeneralFormSchema = Yup.object().shape({
  season: OptionSchema,
  startDate: Yup.date().required(),
  endDate: Yup.date().required(),
  lowerDivisionIds: Yup.array().of(OptionSchema).default([]),
  upperDivisionIds: Yup.array().of(OptionSchema).default([]),
  otherIds: Yup.array().of(OptionSchema).default([]),
})
