import { countriesInfo } from '../mappings'

/**
 * Returns 3-letter code for country.
 *
 * @param alpha2 ALPHA2 code for country
 */
export function getAlpha3(alpha2: string) {
  const country = countriesInfo[alpha2]

  if (!country) {
    throw Error('No country info for provided ALPHA2 code.')
  }

  return country.alpha3
}

/**
 * Returns slug for country.
 *
 * @param alpha2 ALPHA2 code for country
 */
export function getCountrySlug(alpha2: string): string {
  const country = countriesInfo[alpha2]

  if (!country) {
    throw Error('No country info for provided ALPHA2 code.')
  }

  return country.slug
}

/**
 * Returns all data for country.
 *
 * @param alpha2 ALPHA2 code for country
 */
export function getCountryData(alpha2: string) {
  const country = countriesInfo[alpha2]

  if (!country) {
    throw Error('No country info for provided ALPHA2 code.')
  }

  return country
}
