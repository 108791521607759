import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useMemo } from 'react'
import { Box, Flex } from '@sofascore/ui'
import {
  LineupsKitSelector,
  LineupsCaptainSelector,
  Substitutions,
  MissingPlayers,
  LineupsTeamSelector,
  PlayerCounter,
  ClearAll,
  AvailablePlayers,
  ADD_TO_LINEUP,
  REMOVE_FROM_LINEUP,
  useLineupsDispatch,
  useLineupsState,
  DynamicLineupsPlayer,
  PitchLineupsProps,
  lineupPlayerKitColors,
  groupPlayersByPosition,
  mapRugbyPositionToGroupKey,
} from 'features/AddLineups'

import { DragAndDropPitch } from './DesktopDragAndDropPitch'
import { useRugbyPositions } from '../hooks'
import { renderRugbyOption, renderRugbySelectedOption, sortRugbyPlayerOptions } from '../utils'
import * as S from './styles'

export const DesktopPitchLineups = ({
  lineupPlayers,
  otherTeamKit,
  isHomeTeam,
  activeTeamId,
  onChangeTeam,
  homeTeam,
  awayTeam,
}: PitchLineupsProps) => {
  const { homeKit, awayKit } = useLineupsState()
  const { starterPositions, substitutionPositions } = useRugbyPositions()
  const dispatch = useLineupsDispatch()

  const starters = useMemo(() => {
    return lineupPlayers.filter(lp => lp.position && !lp.substitute)
  }, [lineupPlayers])

  const substitutions = useMemo(() => {
    return lineupPlayers.filter(lp => lp.substitute)
  }, [lineupPlayers])

  const missingPlayers = useMemo(() => {
    return lineupPlayers.filter(lp => lp.missingReason !== null)
  }, [lineupPlayers])

  const availablePlayers = useMemo(() => {
    return lineupPlayers.filter(lp => lp.position === null && !lp.substitute && lp.missingReason === null)
  }, [lineupPlayers])

  // Kit color must be different from the one that other team uses and also different from goalkeeper ones
  const availableKitColors = lineupPlayerKitColors.filter(o => o.value !== otherTeamKit)

  const groupedAvailablePlayers = groupPlayersByPosition(availablePlayers)

  const handleAddToLineup = (lineupPlayer: DynamicLineupsPlayer, position: number) => {
    dispatch({ type: ADD_TO_LINEUP, payload: { lineupPlayer: lineupPlayer, position: position } })
  }

  const handleRemoveFromLineup = (lineupPlayer: DynamicLineupsPlayer) => {
    dispatch({ type: REMOVE_FROM_LINEUP, payload: { lineupPlayer: lineupPlayer } })
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <S.PitchOptionsGrid>
        <Flex direction="column" elevation={1} maxW={360} bg="surface.s1" br="sm" w="100%">
          <Flex direction="column" gapY="lg" p="lg">
            <LineupsTeamSelector
              homeTeam={homeTeam}
              awayTeam={awayTeam}
              teamId={activeTeamId}
              setTeamId={onChangeTeam}
            />

            <LineupsKitSelector options={availableKitColors} isHomeTeam={isHomeTeam} />

            <PlayerCounter starters={starters} limit={starterPositions.length}>
              <ClearAll isHomeTeam={isHomeTeam} />
            </PlayerCounter>
          </Flex>

          <Flex align="stretch">
            <Flex direction="column" w="100%">
              <DragAndDropPitch
                lineupPlayers={lineupPlayers}
                kit={isHomeTeam ? homeKit : awayKit}
                onDrop={handleAddToLineup}
                onRemove={handleRemoveFromLineup}
                positions={starterPositions}
              />

              <Box p="lg">
                <LineupsCaptainSelector
                  starters={starters}
                  renderOption={renderRugbyOption}
                  renderSelectedOption={renderRugbySelectedOption}
                  sortOptions={sortRugbyPlayerOptions}
                />
              </Box>

              <Substitutions
                substitutions={substitutions}
                availablePlayers={availablePlayers}
                substitutionPositions={substitutionPositions}
                positionGroupMapping={mapRugbyPositionToGroupKey}
              />

              <Box pb="lg" pl="lg" pr={28}>
                <MissingPlayers
                  missingPlayers={missingPlayers}
                  availablePlayers={availablePlayers}
                  positionGroupMapping={mapRugbyPositionToGroupKey}
                />
              </Box>
            </Flex>
          </Flex>
        </Flex>

        <AvailablePlayers players={groupedAvailablePlayers} positionGroupMapping={mapRugbyPositionToGroupKey} />
      </S.PitchOptionsGrid>
    </DndProvider>
  )
}
