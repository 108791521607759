import { BasicCategory } from 'entities/Category'
import { EntityAllowedActions } from 'entities/Permission'

export interface BasicTournament {
  id: number
  name: string
  slug: string
  category: BasicCategory
  competitionType?: CompetitionType
  uniqueTournament?: BasicUniqueTournament
  firstCupRound?: CupRound
  periodLength?: Period
  allowedActions?: EntityAllowedActions
}

export interface BasicUniqueTournament {
  id: number
  name: string
  slug: string
}

export interface ListUniqueTournament extends BasicUniqueTournament {
  hasBoxScore?: boolean
  hasEventPlayerHeatMap?: boolean
  hasEventPlayerStatistics?: boolean
}

export interface ListTournament extends BasicTournament {
  uniqueTournament?: ListUniqueTournament
}

export enum CompetitionType {
  League = 1,
  Cup,
}

export enum CupRound {
  FINAL = 1,
  SEMIFINAL = 2,
  QUARTERFINAL = 4,
  FINAL_SIXTEEN = 8,
  FINAL_THIRTY_TWO = 16,
  FINAL_SIXTY_FOUR = 32,
  FINAL_128 = 64,
  FINAL_256 = 128,
  QUALIFICATIONS = 'qualifications',
}

export interface Period {
  normaltime?: number
  overtime?: number
}
