import { Box, Button, Flex, Text } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'
import { DialogProps } from 'shared/model'

import ResponsivePopUp from 'components/ResponsivePopUp'

interface RecalculateDialogProps extends DialogProps {
  proceed: (tournamentId: number) => void
  tournamentId: number
}

/**
 * Recalculate confirmation dialog shown to make sure user want to recalculate
 * standings (set standings to sofa standings calculation)
 */
const RecalculateConfirmationDialog = ({ isOpen, handleClose, proceed, tournamentId }: RecalculateDialogProps) => {
  return (
    <ResponsivePopUp
      isOpen={isOpen}
      heading={
        <Text fontSize="large" fontWeight="bold">
          <FormattedMessage id="recalculate" />
        </Text>
      }
      onClose={handleClose}
    >
      <Box px="lg" py="lg">
        <Text align="center">
          <FormattedMessage id="standingsRecalculate" />
        </Text>

        <Box mt="lg">
          <Text align="center">
            <FormattedMessage id="standingsRecalculateProceed" />
          </Text>
        </Box>

        <Flex justify="center" mt="xxl">
          <Button
            variant="outline"
            onClick={handleClose}
            color="secondary"
            style={{ border: '2px solid', width: 132, height: 48, marginRight: 16, fontSize: 14 }}
          >
            <FormattedMessage id="cancel" />
          </Button>

          <Button
            variant="solid"
            onClick={() => proceed(tournamentId)}
            color="secondary"
            style={{ border: '2px solid secondary', width: 132, height: 48 }}
          >
            <FormattedMessage id="proceed" />
          </Button>
        </Flex>
      </Box>
    </ResponsivePopUp>
  )
}

export default RecalculateConfirmationDialog
