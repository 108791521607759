import { AnyAction } from 'redux'

import { generateIncident } from 'modules/Incidents/utils'

import { CreateIncidentData, DeleteIncidentData, Incident } from '../model'
import { IncidentActionType } from './actions'

export interface EventIncidentsState {
  initialIncidents?: Incident[]
  incidents?: Incident[]
  hasChanges?: boolean
}

export interface EventIncidentsAction {
  type: IncidentActionType
  payload: CreateIncidentData | DeleteIncidentData | Incident[] | boolean
}

export const eventIncidentsReducer = (state: EventIncidentsState, action: AnyAction) => {
  switch (action.type) {
    case IncidentActionType.SET_INCIDENTS: {
      return {
        ...state,
        incidents: action.payload,
      }
    }

    case IncidentActionType.SET_INITIAL_INCIDENTS: {
      return {
        ...state,
        initialIncidents: action.payload,
      }
    }

    case IncidentActionType.ADD_INCIDENT: {
      const incident = generateIncident(action.payload)

      const incidents = [...(state.incidents as Incident[])]
      incidents.push(incident)

      return {
        ...state,
        incidents,
      }
    }

    case IncidentActionType.DELETE_INCIDENT: {
      const incidents = state.incidents?.filter(incident => {
        return incident.id !== action.payload.incidentId
      })

      return {
        ...state,
        incidents: incidents || [],
      }
    }

    case IncidentActionType.EDIT_INCIDENT: {
      const incidents = [...(state.incidents as Incident[])]

      const incidentIndex = incidents.findIndex(incident => {
        return incident.id === action.payload.incidentId
      })

      const updatedIncident = generateIncident(action.payload)

      incidents[incidentIndex] = updatedIncident

      return {
        ...state,
        incidents,
      }
    }

    case IncidentActionType.DELETE_ALL_INCIDENTS: {
      return {
        ...state,
        incidents: [],
      }
    }

    default:
      return state
  }
}
