import { Flex, Text } from '@sofascore/ui'

interface IncidentResultProps {
  isHome: boolean | undefined
  homeScore: number | undefined
  awayScore: number | undefined
}

export const IncidentResult = ({ isHome, homeScore, awayScore }: IncidentResultProps) => {
  return (
    <Flex order="2" direction="row">
      <Text font="display.medium" color={isHome ? 'onSurface.nLv1' : 'onSurface.nLv3'}>
        {homeScore}
      </Text>
      <Text font="display.medium" color="onSurface.nLv3" mx="1">
        -
      </Text>
      <Text font="display.medium" color={!isHome ? 'onSurface.nLv1' : 'onSurface.nLv3'}>
        {awayScore}
      </Text>
    </Flex>
  )
}
