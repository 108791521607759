import * as React from 'react'

import { IconBase, IconProps } from './Base'

export const IconSignOut = (props: IconProps) => (
  <IconBase width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill={props.fill}
      fillRule="evenodd"
      d="M17 17v-3h-7v-4h7V7l5 5-5 5zM15 2c1.1 0 2 .9 2 2v2h-2V4H6v16h9v-2h2v2c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h9z"
    />
  </IconBase>
)
