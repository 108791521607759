import { Flex, FlexProps, Text } from '@sofascore/ui'
import { PropsWithChildren } from 'react'

interface ChipProps {
  value: string
  isActive?: boolean
  onClick?: () => void
}

const Chip = ({ value, isActive, onClick, ...flexProps }: PropsWithChildren<ChipProps & FlexProps>) => {
  return (
    <Flex
      h={32}
      align="center"
      px="md"
      bg={isActive ? 'primary.default' : 'surface.s1'}
      br="sm"
      cursor="pointer"
      userSelect="none"
      style={{ transition: 'background-color 0.1s', whiteSpace: 'nowrap' }}
      onClick={onClick}
      {...flexProps}
    >
      <Text color={isActive ? 'surface.s1' : 'onSurface.nLv1'} fontWeight={500} style={{ transition: 'color 0.1s' }}>
        {value}
      </Text>
    </Flex>
  )
}

export default Chip
