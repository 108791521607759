import { ForwardedRef, RefObject, forwardRef } from 'react'

import { IconButtonProps } from './interface'
import * as S from './styles'

export const IconButton = forwardRef(
  ({ children, onClick, ...boxProps }: IconButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    return (
      <S.IconButton
        forwardedAs="button"
        onClick={event => onClick(event as React.MouseEvent<HTMLElement>)}
        {...boxProps}
        ref={ref as RefObject<HTMLButtonElement>}
      >
        {children}
      </S.IconButton>
    )
  },
)
