import { useParams } from 'react-router-dom'
import { Box } from '@sofascore/ui'
import { useIntl } from 'react-intl'
import { PermissionAction, PermissionScope } from 'entities/Permission'
import { PermissionGuard } from 'shared/ui'

import { useMyCompetitionsState } from 'modules/MyCompetitions/providers/MyCompetitionsProvider'
import { getCompetitionsWithAllSeasons } from 'modules/MyCompetitions/helper'
import { SeasonDuration } from 'modules/MyCompetitions/MyCompetitionInfo/components/SeasonDuration'
import { NewSeason } from 'modules/MyCompetitions/MyCompetitionInfo/components/NewSeason'

import MyCompetitionTabTitle from '../components/MyCompetitionTabTitle'

/**
 * This component is loaded in Outlet (as a page content) in MyCompetition.tsx
 */
const MyCompetitionInfo = () => {
  const intl = useIntl()
  const { competitionId, seasonId } = useParams()
  const { competitionsWithSeasons } = useMyCompetitionsState()

  const competitions = getCompetitionsWithAllSeasons(competitionsWithSeasons)
  const competition = competitions.find(({ uniqueTournament }) => uniqueTournament.id === Number(competitionId))
  const selectedSeason = competition?.seasons?.find(season => season?.id === Number(seasonId))
  const lastSeason = competition?.seasons[0]

  // Option for marking season as finished and creating new season can only be available
  // on last season for which editor has access
  const isLastSeasonSelected = lastSeason && lastSeason.id === Number(seasonId)

  // Season is finished when endDateTimestamp is less than current time.
  const seasonFinished =
    selectedSeason?.endDateTimestamp && selectedSeason.endDateTimestamp < Math.round(new Date().getTime() / 1000)

  return (
    <>
      <MyCompetitionTabTitle title={intl.formatMessage({ id: 'info' })} />
      <SeasonDuration
        startTimestamp={selectedSeason?.startDateTimestamp}
        endTimestamp={selectedSeason?.endDateTimestamp}
        seasonFinished={!!seasonFinished}
        season={selectedSeason}
      />

      {selectedSeason && isLastSeasonSelected && seasonFinished && (
        <PermissionGuard entityScopes={[PermissionScope.Season]} checkAllowedActions={[PermissionAction.Create]}>
          <Box w={['100%', '50%']}>
            <NewSeason />
          </Box>
        </PermissionGuard>
      )}
    </>
  )
}

export default MyCompetitionInfo
