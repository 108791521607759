import { Box, Flex, useMediaContext } from '@sofascore/ui'
import { useEventState } from 'entities/Event'
import { NOT_STARTED_CODE, StatusCode } from 'entities/Status'
import { CompetitionType } from 'entities/Tournament'
import { SetAggregateWinner } from 'features/SetAggregateWinner'
import { SetMatchStatus } from 'features/SetMatchStatus'

interface GeneralEditMatchStatus {
  availableStatusCodes: StatusCode[]
}

export const GeneralEditMatchStatus = ({ availableStatusCodes }: GeneralEditMatchStatus) => {
  const { isMobile } = useMediaContext()
  const { event } = useEventState()
  const { status, tournament } = event

  const isNotStarted = status.code === NOT_STARTED_CODE
  const isCup = tournament.competitionType === CompetitionType.Cup

  const showAggregateWinner = !isNotStarted && isCup

  if (isMobile) {
    return <SetMatchStatus availableStatusCodes={availableStatusCodes} bg="surface.s1" />
  }

  return (
    <Flex gapX="lg" p="lg" bg="surface.s1" br="sm" elevation={2}>
      <Box w="50%">
        <SetMatchStatus availableStatusCodes={availableStatusCodes} />
      </Box>

      {showAggregateWinner && (
        <Box w="50%">
          <SetAggregateWinner />
        </Box>
      )}
    </Flex>
  )
}
