import { UniqueTournamentImage } from 'entities/UniqueTournament'
import { Flex, Text } from '@sofascore/ui'
import { Option } from 'shared/model'

export const UniqueTournamentOption = ({ option }: { option: Option }) => {
  return (
    <Flex align="center" py="md" px="lg">
      <UniqueTournamentImage id={Number(option.value)} name={option.label} w={32} h={32} mr="lg" />
      <Text mr="lg">{option.label}</Text>
    </Flex>
  )
}
