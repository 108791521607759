import { Input, Theme, makeStyles, createStyles } from '@material-ui/core'
import styled from 'styled-components'

import { selectColor, neutral } from 'styles/color'

import { Direction } from './interface'

interface StyledProps {
  isDirectionColumn?: boolean
}

export const WritableCounterInput = styled(Input)<{ direction: Direction | undefined }>`
  min-width: 40;
  max-width: ${p => (p.direction === Direction.COLUMN ? 96 : undefined)};
  margin: 0;
  background-color: ${selectColor};
  width: 100%;

  & > input {
    font-size: 24px;
    font-weight: 500;
    padding: 6px;
    text-align: center;
  }

  &::before,
  &::after {
    display: none;
  }

  & > fieldset {
    border: none;
  }
`

export const useStyles = makeStyles<Theme, StyledProps>(theme =>
  createStyles({
    counterTitle: {
      textAlign: 'center',
      color: theme.palette.text.primary,
      fontSize: 24,
    },
    counterItem: {
      width: 'calc(100% - 80px)',
      backgroundColor: selectColor,
    },
    input: {
      margin: 0,
      backgroundColor: selectColor,
      width: '100%',
      '& > div > input': {
        textAlign: 'center',
      },
      '& > div > fieldset': {
        border: 'none',
      },
    },
    operationItem: {
      width: props => (props.isDirectionColumn ? 96 : 40),
      backgroundColor: 'white',
      textAlign: 'center',
    },
    counterContainer: {
      display: 'flex',
      flexDirection: props => (props.isDirectionColumn ? 'column-reverse' : 'row'),
      border: `2px solid ${neutral}`,
      height: props => (props.isDirectionColumn ? 125 : 40),
      marginTop: '8px',
    },
    iconButton: {
      width: 40,
      height: 40,
      padding: 0,
    },
  }),
)
