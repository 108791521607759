import { ReactNode } from 'react'
import { Flex } from '@sofascore/ui'
import { useEventPlayers } from 'entities/Player'
import { LineupsResponse } from 'entities/Lineups'
import {
  LineupsInfoProvider,
  LineupsInitialState,
  useEventLineups,
  mergePlayers,
  toDynamicLineupPlayers,
} from 'features/AddLineups'
import { useEventState } from 'entities/Event'

import { Spinner } from 'components/Spinner'

interface MiniFootballLineupsLoaderProps {
  transformer: (data: LineupsResponse) => Partial<LineupsInitialState>
  children: ReactNode
}

export const MiniFootballLineupsLoader = ({ children, transformer }: MiniFootballLineupsLoaderProps) => {
  const { event } = useEventState()
  const { homeTeam, awayTeam } = event
  const players = useEventPlayers()

  const homePlayers = players[homeTeam.id] || []
  const awayPlayers = players[awayTeam.id] || []

  const { data: eventLineupsData, error: eventLineupsError } = useEventLineups(event ? event.id : null)

  if (!eventLineupsData && !eventLineupsError) {
    return (
      <Flex w="100%" h={400} display="flex" align="center" justify="center" direction="column">
        <Spinner color="#374df5" width="50px" />
      </Flex>
    )
  }

  const transformedLineupsData = eventLineupsData ? transformer(eventLineupsData) : undefined

  // If lineups already exist, we need to merge those lineup players
  // with all available so user can have all team players at disposal
  const homeLineupPlayers = mergePlayers(
    transformedLineupsData?.homeLineupPlayers || [],
    toDynamicLineupPlayers(homePlayers, true),
  )
  const awayLineupPlayers = mergePlayers(
    transformedLineupsData?.awayLineupPlayers || [],
    toDynamicLineupPlayers(awayPlayers, false),
  )

  return (
    <LineupsInfoProvider
      initialState={{
        homeTeam: homeTeam,
        homeLineupPlayers: homeLineupPlayers,
        homeKit: '',
        homeFormation: '',
        homeGoalkeeperKit: '',
        awayTeam: awayTeam,
        awayLineupPlayers: awayLineupPlayers,
        awayKit: '',
        awayFormation: '',
        awayGoalkeeperKit: '',
        hasChanges: false,
      }}
    >
      {children}
    </LineupsInfoProvider>
  )
}
