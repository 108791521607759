import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'

import InputWheelPicker from 'components/InputWheelPicker/InputWheelPicker'

interface TimePickerProps {
  date: Date
  onChange: Dispatch<SetStateAction<Date>>
}

const timeOptions = {
  hours: Array(24)
    .fill(undefined)
    .map((_, index) => {
      return {
        label: `0${index.toString()}`.slice(-2),
        value: index,
      }
    }),
  minutes: Array(60)
    .fill(undefined)
    .map((_, index) => {
      return {
        label: `0${index.toString()}`.slice(-2),
        value: index,
      }
    }),
}

const getHoursAndMinutesFromDate = (value: Date) => {
  return {
    hours: value.getHours(),
    minutes: value.getMinutes(),
  }
}

const TimePicker = ({ date, onChange }: TimePickerProps) => {
  const [values, setValues] = useState(getHoursAndMinutesFromDate(date))

  const handleChange = useCallback(
    (name: string, value: string | number) => {
      setValues(prev => ({ ...prev, [name]: value }))

      const selectedTime = date
      selectedTime.setHours(name === 'hours' ? +value : values.hours)
      selectedTime.setMinutes(name === 'minutes' ? +value : values.minutes)

      onChange(selectedTime)
    },
    [date, onChange, values.hours, values.minutes],
  )

  useEffect(() => {
    setValues(getHoursAndMinutesFromDate(date))
  }, [date])

  return (
    <InputWheelPicker
      columnsOptions={timeOptions}
      columnsValues={values}
      delimiter=":"
      onChange={handleChange}
      height={120}
      inputWidth={35}
    />
  )
}

export default TimePicker
