import { IconBase, IconProps } from './Base'

export const IconSuccess = (props: IconProps) => (
  <IconBase width={72} height={72} viewBox="0 0 72 72" {...props}>
    <g fill="none" fillRule="evenodd" transform="translate(4 4)">
      <circle cx="32" cy="32" r="32" stroke="#0CB66F" strokeWidth="4" />
      <path d="M16 16h32v32H16z" />
      <path fill="#0CB66F" d="M27.73 35.724l-6.497-6.336L18 32.541 27.7 42 46 24.153 42.767 21 27.73 35.724z" />
    </g>
  </IconBase>
)
