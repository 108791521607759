import { Box, Flex } from '@sofascore/ui'
import { useEventState } from 'entities/Event'
import { MatchProperties } from 'features/MatchProperties'
import { MatchActionButtons } from 'features/MatchActionButtons'
import { CommunitySuggestions } from 'features/CommunitySuggestions'
import { StatusType } from 'entities/Status'
import { CountdownSection } from 'features/CountdownSection'
import { ScoreSection } from 'features/ScoreSection'
import { MatchReportDownload } from 'features/MatchReportDownload'

import { EditMatchInfoHeader } from '../MatchInfoHeader'
import { DISABLE_EDIT_LIVE_INACTIVE_THRESHOLD, ENABLE_EDIT_LIVE_THRESHOLD } from '../../config'

export const FootballMatchInfo = () => {
  const { event } = useEventState()
  const { crowdsourcingDataDisplayEnabled } = event

  const isNotStarted = event.status.type === StatusType.NOT_STARTED

  return (
    <Flex mt="sm" minH={604} direction="column" justify="space-between">
      <Box>
        <MatchReportDownload />
        <EditMatchInfoHeader>
          {isNotStarted ? (
            <CountdownSection
              enableEditLiveThreshold={ENABLE_EDIT_LIVE_THRESHOLD}
              disableEditLiveInactiveThreshold={DISABLE_EDIT_LIVE_INACTIVE_THRESHOLD}
            />
          ) : (
            <ScoreSection hasTimer />
          )}
        </EditMatchInfoHeader>
        <MatchProperties />
      </Box>

      <Box mt="md">
        {crowdsourcingDataDisplayEnabled && <CommunitySuggestions event={event} />}
        <MatchActionButtons
          enableEditLiveThreshold={ENABLE_EDIT_LIVE_THRESHOLD}
          disableEditLiveInactiveThreshold={DISABLE_EDIT_LIVE_INACTIVE_THRESHOLD}
        />
      </Box>
    </Flex>
  )
}
