import { Box, Flex, Text } from '@sofascore/ui'
import useSWR from 'swr'
import { FormattedMessage, useIntl } from 'react-intl'
import IconInfo from '@sofascore/ui/dist/modules/Icons/IconInfo'
import IconCrowdsourcing16 from '@sofascore/ui/dist/modules/Icons/IconCrowdsourcing16'
import { BasicEvent, EventCrowdsourcingSuggestsResponse, ScoringTeam } from 'entities/Event'
import { IncidentType } from 'entities/Incident'
import { TeamName } from 'entities/Team'

import Collapse from 'components/Collapse/Collapse'
import { getEventCrowdsourcingSuggests } from 'api/routes'
import { Tooltip } from 'components/Tooltip'
import { formatTime } from 'utils/time'
import { iconsMap } from 'components/IncidentIcon/IncidentIcon'

import * as S from './styles'

const CollapseHeader = () => {
  return (
    <Flex w="100%" justify="space-between" align="center">
      <Flex align="center">
        <IconCrowdsourcing16 w={20} h={20} fill="primary.default" />

        <Text ml="sm" color="primary.default" fontWeight="bold">
          <FormattedMessage id="editor_crowdsourcing_community" />
        </Text>
      </Flex>

      <Box mr="md">
        <Tooltip
          label={
            <Text color="onSurface.nLv1" lineHeight={1.4}>
              <FormattedMessage id="editor_crowdsourcing_info_text" />
            </Text>
          }
          placement="top"
          alignment="left"
          clickable
          w={240}
          br="sm"
          p="md"
          style={{ right: '-6px' }}
          element={<IconInfo w={24} h={24} fill="neutral.default" />}
        />
      </Box>
    </Flex>
  )
}

interface Props {
  event: BasicEvent
}

export const CommunitySuggestions = ({ event }: Props) => {
  const intl = useIntl()

  const { id, homeTeam, awayTeam } = event

  const { data: crowdsourcingSuggestsResponse, error } = useSWR<EventCrowdsourcingSuggestsResponse>(
    getEventCrowdsourcingSuggests(id),
  )

  if (error || !crowdsourcingSuggestsResponse?.suggestions) return null

  const { startDateTimestamp, finalScore, scoreSuggests } = crowdsourcingSuggestsResponse.suggestions

  return (
    <Collapse
      title={<CollapseHeader />}
      bg="onColor.primary"
      br="sm"
      headerStyles={{ padding: '12px 16px' }}
      maxHeight={400}
    >
      <Box px="sm" pb="sm">
        <Box bg="surface.s2" br="sm" p="lg" textAlign="center">
          <Text color="onSurface.nLv3">
            <FormattedMessage id="match_started" />

            {startDateTimestamp && ` (${formatTime(new Date(startDateTimestamp * 1000))})`}
          </Text>
        </Box>

        <Flex bg="surface.s2" mt="sm" br="sm" py="lg" justify="space-between">
          <TeamName id={homeTeam.id} teamName={homeTeam.name} color="onSurface.nLv3" />

          <Flex align="center">
            <Text align="center" fontWeight="medium" color="onSurface.nLv3" fontSize="extraLarge">
              {finalScore.home} - {finalScore.away}
            </Text>
          </Flex>

          <TeamName id={awayTeam.id} teamName={awayTeam.name} color="onSurface.nLv3" />
        </Flex>

        <S.IncidentList br="sm" textAlign="center" mt="sm">
          {scoreSuggests.map(suggest => {
            const { home, away, scoringTeam, incidentClass, scorer, assist } = suggest

            const isHomeScore = scoringTeam === ScoringTeam.Home

            return (
              <Flex
                key={`${home}-${away}`}
                h={40}
                align="center"
                bg="surface.s2"
                br="sm"
                px="lg"
                py="sm"
                direction={isHomeScore ? 'row' : 'row-reverse'}
              >
                {iconsMap[IncidentType.Goal][incidentClass]}

                <Box h="100%" mx="md" borderRight="onSurface.nLv4" />

                <Text
                  mr={isHomeScore ? 'md' : undefined}
                  ml={!isHomeScore ? 'md' : undefined}
                  fontSize="medium"
                  color="onSurface.nLv3"
                  fontWeight="bold"
                >
                  {home} - {away}
                </Text>

                {scorer && (
                  <Flex direction="column" align={isHomeScore ? 'flex-start' : 'flex-end'}>
                    <Text color="onSurface.nLv3">{scorer}</Text>

                    {assist && (
                      <Text color="onSurface.nLv3" fontSize="small">
                        {intl.formatMessage({ id: 'assist' })}: {assist}
                      </Text>
                    )}
                  </Flex>
                )}
              </Flex>
            )
          })}
        </S.IncidentList>
      </Box>
    </Collapse>
  )
}
