import * as React from 'react'

import { secondaryColor } from 'styles/color'

import { IconBase, IconProps } from './Base'

export const IconRemoveFilled = (props: IconProps) => (
  <IconBase width={25} height={24} viewBox="0 0 25 24" {...props}>
    <g transform="translate(.273)" fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <circle fill="#FFF" cx="12" cy="12" r="10" />
      <path
        d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
        fill={props.fill || secondaryColor}
      />
    </g>
  </IconBase>
)
