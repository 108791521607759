import { useCallback, useEffect } from 'react'
import debounce from 'lodash/debounce'

interface DesktopScrollProps {
  containerRef: React.MutableRefObject<HTMLDivElement | null>
  handler: () => void
}

export const useOnBottomHandler = ({ containerRef, handler }: DesktopScrollProps) => {
  const handleScroll = useCallback(
    (e: Event) => {
      if (e.target instanceof Document && containerRef.current) {
        const containerBottom = containerRef.current.getBoundingClientRect().bottom
        const documentOffset = document.body.offsetHeight

        if (containerBottom <= documentOffset) {
          handler()
        }
      }
    },
    [handler, containerRef],
  )

  useEffect(() => {
    const debouncedScrollHandler = debounce(handleScroll, 200)

    document.addEventListener('scroll', debouncedScrollHandler)

    return () => {
      document.removeEventListener('scroll', debouncedScrollHandler)
    }
  }, [containerRef, handleScroll])
}
