import * as React from 'react'
import { IntlProvider } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'

import enMessages from 'translations/en.json'
import { RootState } from 'store/store'
import { SET_LOCALE } from 'store/uiControls/reducer'
import { getBrowserLocale, languageTranslations } from 'utils/locale'

type Translations = Record<string, string>

const ConnectedIntlProvider = ({ children }: { children: React.ReactNode }) => {
  const selectedLocale = useSelector((state: RootState) => state.uicontrols.selectedLocale)
  const dispatch = useDispatch()

  const [translations, setTranslations] = React.useState<Translations>(enMessages)

  React.useEffect(() => {
    const getNewTranslations = async () => {
      try {
        const translationFileName = languageTranslations[selectedLocale]
        const newTranslations = await import(`../../translations/${translationFileName}.json`)
        setTranslations(newTranslations)
      } catch (e) {
        console.error(e)
        dispatch({ type: SET_LOCALE, payload: { selectedLocale: getBrowserLocale() } })
      }
    }

    getNewTranslations()
  }, [selectedLocale, dispatch])

  return (
    <IntlProvider locale={selectedLocale} messages={translations}>
      {children}
    </IntlProvider>
  )
}

export default ConnectedIntlProvider
