import { Box, Flex, Text, Image } from '@sofascore/ui'
import { getUpdateEventBody, useEventState } from 'entities/Event'
import { BasketballEditPeriodSection } from 'widgets/EditPeriodSection/ui/Basketball/ui'
import { SetPeriodScoreLive } from 'features/SetPeriodScore'
import { GenericResultSection, periodNamesSportMap, PeriodType, ScoreWithPeriods } from 'entities/Score'
import { SportName } from 'entities/Sport'
import { FormattedMessage } from 'react-intl'
import { StatusType } from 'entities/Status'
import { MatchStarter } from 'features/EditPeriod'
import { useState } from 'react'
import { useErrorHandler } from 'shared/hooks'
import { mutate } from 'swr'
import { useBasketballPeriods } from 'widgets/EditPeriodSection'

import { eventDetails, teamImage } from 'api/routes'
import { updateEvent } from 'api'

export const BasketballEditLiveMatch = () => {
  const { event } = useEventState()
  const { homeScore, awayScore, homeTeam, awayTeam } = event

  const [isSaving, setIsSaving] = useState(false)

  const { activePeriodIndex, selectablePeriods } = useBasketballPeriods()
  const handleError = useErrorHandler()

  const hasNotStarted = event.status.type === StatusType.NOT_STARTED

  const handleConfirm = async (newHomeScore: ScoreWithPeriods, newAwayScore: ScoreWithPeriods) => {
    setIsSaving(true)

    const updateEventBody = getUpdateEventBody({ ...event, homeScore: newHomeScore, awayScore: newAwayScore })

    if (updateEventBody) {
      updateEvent(event.id, updateEventBody)
        .then(() => {
          mutate(eventDetails(event.id))
        })
        .catch(handleError)
        .finally(() => setIsSaving(false))
    }
  }

  const handleIncrementPeriodScore = (isHome: boolean, periodKey: PeriodType) => {
    const newHomeScore = {
      ...homeScore,
      display: isHome ? (homeScore.display || 0) + 1 : homeScore.display,
      current: isHome ? (homeScore.current || 0) + 1 : homeScore.current,
      [periodKey]: isHome ? (homeScore[periodKey] || 0) + 1 : homeScore[periodKey] || 0,
    }

    const newAwayScore = {
      ...awayScore,
      display: isHome ? awayScore.display : (awayScore.display || 0) + 1,
      current: isHome ? awayScore.current : (awayScore.current || 0) + 1,
      [periodKey]: isHome ? awayScore[periodKey] || 0 : (awayScore[periodKey] || 0) + 1,
    }

    handleConfirm(newHomeScore, newAwayScore)
  }

  const handleDecrementPeriodScore = (isHome: boolean, periodKey: PeriodType) => {
    const newHomeScore = {
      ...homeScore,
      display: isHome ? (homeScore.display || 0) - 1 : homeScore.display,
      current: isHome ? (homeScore.current || 0) - 1 : homeScore.current,
      [periodKey]: isHome ? (homeScore[periodKey] || 0) - 1 : homeScore[periodKey] || 0,
    }

    const newAwayScore = {
      ...awayScore,
      display: isHome ? awayScore.display : (awayScore.display || 0) - 1,
      current: isHome ? awayScore.current : (awayScore.current || 0) - 1,
      [periodKey]: isHome ? awayScore[periodKey] || 0 : (awayScore[periodKey] || 0) - 1,
    }

    handleConfirm(newHomeScore, newAwayScore)
  }

  if (hasNotStarted) {
    return <MatchStarter />
  }

  return (
    <Box>
      <BasketballEditPeriodSection />

      <Box bg="surface.s1" p="lg" br="sm" textAlign="center" elevation={1} mt="sm">
        <Flex justify="center" align="center">
          <Flex direction="column" gapY="sm" flex="1 1 0">
            <Box h={28}>
              <Text font="display.micro" color="inherit">
                {homeTeam.name}
              </Text>
            </Box>

            <Flex align="flex-end" justify="center">
              <Image src={teamImage(homeTeam.id)} w={[32, 40]} h={[32, 40]} alt={homeTeam.name} />
            </Flex>
          </Flex>

          <Box w={106}>
            <GenericResultSection homeScore={homeScore} awayScore={awayScore} isLive />
          </Box>

          <Flex direction="column" gapY="sm" flex="1 1 0">
            <Box h={28}>
              <Text font="display.micro" color="inherit">
                {awayTeam.name}
              </Text>
            </Box>

            <Flex align="flex-end" justify="center">
              <Image src={teamImage(awayTeam.id)} w={[32, 40]} h={[32, 40]} alt={awayTeam.name} />
            </Flex>
          </Flex>
        </Flex>
      </Box>

      {/* SCORE BY PERIODS */}
      {selectablePeriods
        .map(
          ({ scorePath }, index) =>
            scorePath &&
            index <= activePeriodIndex && (
              <SetPeriodScoreLive
                key={scorePath}
                periodKey={scorePath}
                periodName={<FormattedMessage id={periodNamesSportMap[scorePath][SportName.Basketball]} />}
                onIncrementPeriodScore={isHome => handleIncrementPeriodScore(isHome, scorePath)}
                onDecrementPeriodScore={isHome => handleDecrementPeriodScore(isHome, scorePath)}
                disableButtons={isSaving}
                disableInput
              />
            ),
        )
        .reverse()}
    </Box>
  )
}
