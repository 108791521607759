import { Text } from '@sofascore/ui'
import { SportName } from 'entities/Sport'

import { Player, PlayerOption, Position } from '../model'

export const renderPlayerOption = (option: PlayerOption) => {
  return (
    <Text as="div" font="body.large" px="lg" py="sm">
      {option.value.jerseyNumber ? `${option.value.jerseyNumber} - ${option.label}` : option.label}
    </Text>
  )
}

export const renderSelectedPlayerOption = (option: PlayerOption | undefined) => {
  return option ? (
    <>{option.value.jerseyNumber ? `${option.value.jerseyNumber} - ${option.label}` : option.label}</>
  ) : (
    <></>
  )
}

export const getDefaultOption = (initialPlayer: Player | undefined) => {
  return initialPlayer
    ? {
        label: initialPlayer.name,
        value: initialPlayer,
      }
    : undefined
}

const orderedPositions: Record<string, Position[]> = {
  [SportName.Football]: ['G', 'D', 'M', 'F'],
  [SportName.Futsal]: ['G', 'F'],
  [SportName.MiniFootball]: ['G', 'F'],
  [SportName.Basketball]: ['C', 'CF', 'F', 'FC', 'FG', 'G', 'GF'],
  [SportName.Waterpolo]: ['G', 'W', 'D', 'P', 'CF'],
  [SportName.Rugby]: ['F', 'B'],
}

export const getOrderedPositions = (sport: SportName) => {
  return orderedPositions[sport]
}
