import { Text } from '@sofascore/ui'
import noop from 'lodash/noop'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { INTERRUPTED_CODE, StatusType } from 'entities/Status'
import { useFirebaseWithEvent } from 'entities/Event/hooks'
import { FirebaseEventName, FirebaseEventType, FirebaseEventIncidentAction } from 'shared/lib/firebase/model'

import { GREEN, RED, textSecondary } from 'styles/color'
import ResponsivePopUp from 'components/ResponsivePopUp'

import * as S from './styles'
import { EndPeriodBox } from '../model'
import { ConfirmEndMatchFormFRO } from './ConfirmEndMatchFormFRO'

export const ConfirmEndMatchFRO = ({ event, onClick, onConfirm, ...boxProps }: EndPeriodBox) => {
  const [isOpen, setIsOpen] = useState(false)
  const logFirebaseWithEvent = useFirebaseWithEvent()

  const responsivePopupOpen = () => {
    setIsOpen(true)
    logFirebaseWithEvent(FirebaseEventName.AddEntity, FirebaseEventType.Incident, FirebaseEventIncidentAction.Period)
  }
  const responsivePopupClose = (discarded?: boolean | undefined) => {
    setIsOpen(false)
    if (discarded)
      logFirebaseWithEvent(
        FirebaseEventName.DiscardEntity,
        FirebaseEventType.Incident,
        FirebaseEventIncidentAction.Period,
      )
    else
      logFirebaseWithEvent(
        FirebaseEventName.SubmitEntity,
        FirebaseEventType.Incident,
        FirebaseEventIncidentAction.Period,
      )
  }

  const hasFinished = event.status.type === StatusType.FINISHED
  const isInterrupted = event.status.code === INTERRUPTED_CODE

  // Disable confirming full time if match has ended, has special status
  // (canceled, postponed, interrupted etc.)
  const isDisabled = hasFinished || isInterrupted

  return (
    <>
      <S.PeriodBox
        $isDisabled={isDisabled}
        onClick={isDisabled ? noop : responsivePopupOpen}
        bg={hasFinished ? GREEN.timelineEnd : isInterrupted ? RED.incident : undefined}
        c={hasFinished ? 'onSurface.nLv1' : isInterrupted ? textSecondary : undefined}
        {...boxProps}
      >
        <S.PeriodBoxContent>
          <Text font="body.small" color="inherit" align="center">
            <FormattedMessage id={hasFinished ? 'match_ended' : isInterrupted ? 'match_interrupted' : 'confirm_end'} />
          </Text>
        </S.PeriodBoxContent>
      </S.PeriodBox>

      <ResponsivePopUp
        isOpen={isOpen}
        onClose={responsivePopupClose}
        heading={
          <Text font="display.medium">
            <FormattedMessage id="end_of_the_match_details" />
          </Text>
        }
      >
        <ConfirmEndMatchFormFRO onClose={responsivePopupClose} onSubmit={onConfirm} />
      </ResponsivePopUp>
    </>
  )
}
