import { useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Flex, Text, useMediaContext } from '@sofascore/ui'
import { PlayerCard } from 'entities/Player'

import ResponsivePopUp from 'components/ResponsivePopUp'
import Collapse from 'components/Collapse/Collapse'

import { groupPlayersByPosition, sortLineupPlayers } from '../../utils'
import { DynamicLineupsPlayer } from '../../model'
import { SubstitutionSlotFilled } from './SubstitutionSlotFilled'
import { SubstitutionSlotEmpty } from './SubstitutionSlotEmpty'
import { ADD_TO_SUBSTITUTIONS_SINGLE, REMOVE_FROM_SUBSTITUTIONS, useLineupsDispatch } from '../../providers'
import { SubstitutionSlotDropLayer } from './SubstitutionSlotDropLayer'

export default function Substitutions({
  substitutions,
  substitutionPositions,
  availablePlayers,
  positionGroupMapping,
}: {
  substitutions: DynamicLineupsPlayer[]
  substitutionPositions: Array<number>
  availablePlayers: DynamicLineupsPlayer[]
  positionGroupMapping: Record<string, string>
}) {
  const { isMobile } = useMediaContext()

  const [selectedPosition, setSelectedPosition] = useState<number | undefined>(undefined)
  const dispatch = useLineupsDispatch()

  const groupedAvailablePlayers = groupPlayersByPosition(availablePlayers)

  const handlePositionPick = (pos: number) => {
    setSelectedPosition(pos)
  }

  const handlePositionClear = (player: DynamicLineupsPlayer) => {
    dispatch({ type: REMOVE_FROM_SUBSTITUTIONS, payload: { lineupPlayer: player } })
  }

  const handleAddSubstitutionOnPosition = (player: DynamicLineupsPlayer, position?: number) => {
    dispatch({
      type: ADD_TO_SUBSTITUTIONS_SINGLE,
      payload: { lineupPlayer: player, position: position || selectedPosition },
    })
    setSelectedPosition(undefined)
  }

  const availableSlots: Record<number, DynamicLineupsPlayer | undefined> = useMemo(() => {
    return substitutionPositions.reduce((map, slot) => {
      map[slot] = substitutions.find(f => f.position === Number(slot))
      return map
    }, {})
  }, [substitutions, substitutionPositions])

  return (
    <>
      <Collapse
        initiallyOpen
        title={
          <Text as="div" font="display.micro" fontWeight={600} py={18}>
            <FormattedMessage id="editor_substitutions" />
          </Text>
        }
        alignTitle="center"
        px="lg"
      >
        <Flex direction="column" gapY="sm">
          {Object.keys(availableSlots).map(slotNumber =>
            availableSlots[slotNumber] ? (
              <SubstitutionSlotFilled
                key={slotNumber}
                lineupPlayer={availableSlots[slotNumber]}
                onRemoveSubstitution={handlePositionClear}
              />
            ) : isMobile ? (
              <SubstitutionSlotEmpty key={slotNumber} pos={+slotNumber} onAddSubstitution={handlePositionPick} />
            ) : (
              <SubstitutionSlotDropLayer
                key={slotNumber}
                position={+slotNumber}
                onDrop={handleAddSubstitutionOnPosition}
              >
                {(canDrop, isOver) => (
                  <SubstitutionSlotEmpty
                    pos={+slotNumber}
                    onAddSubstitution={handlePositionPick}
                    border={canDrop && isOver ? '1px solid onSurface.nLv3' : '1px dashed onSurface.nLv3'}
                    bg={canDrop && isOver ? 'surface.s2' : 'transparent'}
                  />
                )}
              </SubstitutionSlotDropLayer>
            ),
          )}
        </Flex>
      </Collapse>

      <ResponsivePopUp
        isOpen={!!selectedPosition}
        onClose={() => setSelectedPosition(undefined)}
        heading={
          <Text font="display.large">
            <FormattedMessage id="editor_add_substitutions" />
          </Text>
        }
      >
        <Box overflow="auto" maxH={600} br="lg">
          {Object.keys(positionGroupMapping).map(position => {
            return (
              <Box key={position}>
                <Box px="lg" pt="lg" pb="sm">
                  <Text font="assistive.default">
                    <FormattedMessage id={positionGroupMapping[position]} />
                  </Text>
                </Box>
                <Flex direction="column" gapY="sm">
                  {groupedAvailablePlayers[position] &&
                    sortLineupPlayers(groupedAvailablePlayers[position]).map(lineupPlayer => (
                      <Box
                        role="button"
                        key={lineupPlayer.player.id}
                        py="sm"
                        px="lg"
                        cursor="pointer"
                        hoverBg="surface.s2"
                        onClick={() => handleAddSubstitutionOnPosition(lineupPlayer)}
                      >
                        <PlayerCard player={lineupPlayer.player} />
                      </Box>
                    ))}
                </Flex>
              </Box>
            )
          })}

          {groupedAvailablePlayers['-'] && (
            <Box>
              <Box px="lg" pt="lg" pb="sm">
                <Text font="assistive.default">
                  <FormattedMessage id="unknown" />
                </Text>
              </Box>
              <Flex direction="column" gapY="sm">
                {groupedAvailablePlayers['-'] &&
                  sortLineupPlayers(groupedAvailablePlayers['-']).map(lineupPlayer => (
                    <Box
                      role="button"
                      key={lineupPlayer.player.id}
                      py="sm"
                      px="lg"
                      cursor="pointer"
                      hoverBg="surface.s2"
                      onClick={() => handleAddSubstitutionOnPosition(lineupPlayer)}
                    >
                      <PlayerCard player={lineupPlayer.player} />
                    </Box>
                  ))}
              </Flex>
            </Box>
          )}
        </Box>
      </ResponsivePopUp>
    </>
  )
}
