import { useIntl } from 'react-intl'

import { useMyCompetitionsState } from 'modules/MyCompetitions/providers/MyCompetitionsProvider'
import MyCompetitionTabTitle from 'modules/MyCompetitions/components/MyCompetitionTabTitle'

import { MyCompetitionCupTeams } from './MyCompetitionCupTeams'
import { MyCompetitionLeagueTeams } from './MyCompetitionLeagueTeams'

/**
 * This component is loaded in Outlet (as a page content) in MyCompetition.tsx
 */
export const MyCompetitionTeams = () => {
  const intl = useIntl()
  const { uniqueTournament } = useMyCompetitionsState()

  const isCup = uniqueTournament?.hasGroups

  if (isCup) {
    return (
      <>
        <MyCompetitionTabTitle title={intl.formatMessage({ id: 'teams' })} />
        <MyCompetitionCupTeams />
      </>
    )
  }

  return (
    <>
      <MyCompetitionTabTitle title={intl.formatMessage({ id: 'teams' })} />
      <MyCompetitionLeagueTeams />
    </>
  )
}
