export enum PermissionAction {
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
}

export enum PermissionCheckType {
  Partial = 'partial',
  Exact = 'exact',
}

export enum PermissionScope {
  This = 'this',
  UniqueTournament = 'uniqueTournament',
  Tournament = 'tournament',
  Season = 'season',
  Event = 'event',
  Team = 'team',
  Player = 'player',
  Standings = 'standings',
  CupTree = 'cupTree',
  MatchReport = 'matchReport',
  UniqueTournamentSettings = 'uniqueTournamentSettings',
  Settings = 'settings',
}

export type PermissionGeneral = {
  [scope in PermissionScope]: {
    [action: string]: true | Array<number>
  }
}

export type PermissionSeasons = {
  [seassonID in number]: {
    [scope in PermissionScope]: {
      [action: string]: true | Array<number>
    }
  }
}

export interface EntityAllowedActions {
  [key: string]: PermissionAction[]
}
