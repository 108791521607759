import { useParams, useSearchParams } from 'react-router-dom'
import useSWR from 'swr'
import { useIntl } from 'react-intl'
import { useCallback, useMemo } from 'react'

import { RoundsResponse } from 'modules/Matches/MatchesList/MatchesFilter/interface'
import { uniqueTournamentRounds } from 'api/routes'
import { useMyCompetitionsState } from 'modules/MyCompetitions/providers/MyCompetitionsProvider'
import { Option } from 'components/DropdownMenu/interface'

import { getRoundOptionFromActiveFilter, getRoundOptions } from '../utils'
import { EventsFilterType } from '../model'

export const useMatchesFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const { competitionId, seasonId } = useParams()
  const { tournaments } = useMyCompetitionsState()

  const selectedRound = searchParams.get(EventsFilterType.Round) || searchParams.get(EventsFilterType.Stage)
  const selectedTournamentId = searchParams.get(EventsFilterType.TournamentId)

  const intl = useIntl()

  const { data: roundsResponse } = useSWR<RoundsResponse>(
    competitionId && seasonId ? uniqueTournamentRounds(+competitionId, +seasonId) : null,
  )

  const roundOptions = useMemo(
    () => (roundsResponse ? getRoundOptions(roundsResponse.rounds, intl) : []),
    [roundsResponse, intl],
  )

  const selectedRoundOption = useMemo(
    () => getRoundOptionFromActiveFilter(selectedRound, roundOptions),
    [selectedRound, roundOptions],
  )

  const selectedTournamentOption = useMemo(() => {
    if (!tournaments.length || !selectedTournamentId) return undefined

    return {
      value: selectedTournamentId,
      label: tournaments.find(t => t.id === +selectedTournamentId)!.name,
    }
  }, [tournaments, selectedTournamentId])

  const applyFilters = useCallback(
    (round: Option | undefined, tournament: Option | undefined) => {
      const isCup = round ? round.value.toString().includes('cup') : false
      const roundOrStage = round ? round.value.toString().split(':')[1] : ''

      setSearchParams({
        ...(roundOrStage && !isCup && { [EventsFilterType.Round]: roundOrStage }),
        ...(roundOrStage && isCup && { [EventsFilterType.Stage]: roundOrStage }),
        ...(tournament && { [EventsFilterType.TournamentId]: tournament.value.toString() }),
      })
    },
    [setSearchParams],
  )

  return {
    selectedRound,
    selectedRoundOption,
    selectedTournamentId,
    selectedTournamentOption,
    roundOptions,
    searchParams,
    applyFilters,
  }
}
