import styled from 'styled-components'

export const DeleteButton = styled.button`
  height: 100%;
  border: 0;
  padding: 5px;
  border-radius: 5px;
  background: none;

  display: flex;
  align-items: center;

  transition: background-color 0.2s;

  &:hover {
    background-color: var(--on-surface-nLv5);
    cursor: inherit;
  }
`
