import { Box, Flex, Image, Text } from '@sofascore/ui'

import { teamImage } from 'api/routes'

interface TeamNameProps {
  id: number
  teamName: string
  color?: string
}

export const TeamName = ({ id, teamName, color }: TeamNameProps) => {
  return (
    <Flex w={[100, 150, 200, 200]} direction="column" justify="space-between" align="center">
      <Text fontSize={12} fontWeight="bold" align="center" color={color}>
        {teamName}
      </Text>

      <Box mt="sm">
        <Image src={teamImage(id)} w={[32, 40]} h={[32, 40]} alt={teamName} />
      </Box>
    </Flex>
  )
}
