import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { App } from 'app'

import { isProd } from 'api/constants'
import { SENTRY_DSN } from 'config/sentry'

import * as serviceWorker from './serviceWorker'

import 'normalize.css'

if (isProd) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [Sentry.captureConsoleIntegration()],
  })
}

const container = document.getElementById('root') as HTMLElement
const root = ReactDOM.createRoot(container)
root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
