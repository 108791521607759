import {
  FIRST_EXTRA_TIME_CODE,
  SECOND_EXTRA_TIME_CODE,
  PENALTIES_CODE,
  OVERTIME as OVERTIME_CODE,
} from 'entities/Status'
import { ScoreWithPeriods } from 'entities/Score'

import { EXTRA_TIME_1, EXTRA_TIME_2, OVERTIME } from 'modules/Matches/const'

export const extra1InitialValue = (
  matchStatusCode: number | null,
  homeScore: ScoreWithPeriods | null,
  awayScore: ScoreWithPeriods | null,
) => {
  if (matchStatusCode === FIRST_EXTRA_TIME_CODE) {
    return true
  }
  if (homeScore) {
    if (typeof homeScore[EXTRA_TIME_1] === 'number') {
      return true
    }
  }
  if (awayScore) {
    if (typeof awayScore[EXTRA_TIME_1] === 'number') {
      return true
    }
  }
  return false
}

export const extra2InitialValue = (
  matchStatusCode: number | null,
  homeScore: ScoreWithPeriods | null,
  awayScore: ScoreWithPeriods | null,
) => {
  if (matchStatusCode === SECOND_EXTRA_TIME_CODE) {
    return true
  }
  if (homeScore) {
    if (typeof homeScore[EXTRA_TIME_2] === 'number') {
      return true
    }
  }
  if (awayScore) {
    if (typeof awayScore[EXTRA_TIME_2] === 'number') {
      return true
    }
  }
  return false
}

export const penaltiesInitialValue = (
  matchStatusCode: number | null,
  homeScore: ScoreWithPeriods | null,
  awayScore: ScoreWithPeriods | null,
) => {
  if (matchStatusCode === PENALTIES_CODE) {
    return true
  }
  if (homeScore) {
    if (typeof homeScore[PENALTIES_CODE] === 'number') {
      return true
    }
  }
  if (awayScore) {
    if (typeof awayScore[PENALTIES_CODE] === 'number') {
      return true
    }
  }
  return false
}

export const overtimeInitialValue = (
  matchStatusCode: number | null,
  homeScore: ScoreWithPeriods | null,
  awayScore: ScoreWithPeriods | null,
) => {
  if (matchStatusCode === OVERTIME_CODE) {
    return true
  }
  if (homeScore) {
    if (typeof homeScore[OVERTIME] === 'number') {
      return true
    }
  }
  if (awayScore) {
    if (typeof awayScore[OVERTIME] === 'number') {
      return true
    }
  }
  return false
}
