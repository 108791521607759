import { Flex, Text } from '@sofascore/ui'
import { calculateAge } from 'shared/utils'
import { CountryInfo } from 'entities/Country'

import { Player } from '../model'

interface PlayerInfoProps {
  player: Player
}

export const PlayerInfo = ({ player }: PlayerInfoProps) => {
  const { dateOfBirthTimestamp, country } = player

  return (
    <Flex gapX="sm" align="center">
      {dateOfBirthTimestamp && (
        <Text font="assistive.default" color="onSurface.nLv3">
          {calculateAge(dateOfBirthTimestamp * 1000)} yrs
        </Text>
      )}
      <CountryInfo country={country} />
    </Flex>
  )
}
