import { Flex, FlexProps } from '@sofascore/ui'

import * as S from './styles'

interface Props {
  label: string
  value: string | number
  disabled?: boolean
  variant?: 'outlined' | 'contained'
  onClick?: () => void
}

// TODO: Replace Match Info MatchProperties buttons with this InfoField component
export default function InfoField({
  label,
  value,
  onClick,
  disabled,
  variant = 'outlined',
  ...boxProps
}: Props & FlexProps) {
  return (
    <S.Wrapper
      bg="surface.s2"
      color="onSurface.nLv1"
      variant={variant}
      isDisabled={disabled}
      onClick={onClick}
      {...boxProps}
    >
      <S.Label>{label}</S.Label>

      <Flex id="value" align="center" mt="xxs" wrap="wrap">
        {value}
      </Flex>
    </S.Wrapper>
  )
}
