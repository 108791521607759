import { AnyAction } from 'redux'
import { UniqueTournamentDetails } from 'entities/UniqueTournament'
import { BasicTournament } from 'entities/Tournament'

import { UniqueTournamentSeason } from 'modules/MyCompetitions/interface'

import { MyCompetitionsActionType } from './actions'

export interface MyCompetitionsState {
  competitionsWithSeasons: UniqueTournamentSeason[]
  uniqueTournament: UniqueTournamentDetails | undefined
  tournaments: BasicTournament[]
}

export const myCompetitionsReducer = (state: MyCompetitionsState, action: AnyAction) => {
  switch (action.type) {
    case MyCompetitionsActionType.SET_COMPETITIONS: {
      return {
        ...state,
        competitionsWithSeasons: action.payload,
      }
    }

    case MyCompetitionsActionType.SET_UNIQUE_TOURNAMENT: {
      return {
        ...state,
        uniqueTournament: action.payload,
      }
    }

    case MyCompetitionsActionType.SET_TOURNAMENTS: {
      return {
        ...state,
        tournaments: action.payload,
      }
    }

    default:
      return state
  }
}
