import { EventActionType, useEventDispatch, useEventState } from 'entities/Event'
import { IncidentActionType, IncidentType, useEventIncidentsDispatch, useEventIncidentsState } from 'entities/Incident'
import { useEffect, useRef, useState } from 'react'

export const useResultOnlyMode = (): [boolean, () => void] => {
  const isFirstRenderRef = useRef(false)
  const isInitialResultOnlyModeRef = useRef(false)

  const [resultOnlyMode, setResultOnlyMode] = useState(false)

  const { event } = useEventState()
  const { initialIncidents, incidents } = useEventIncidentsState()

  const eventDispatch = useEventDispatch()
  const incidentsDispatch = useEventIncidentsDispatch()

  const { homeScore, awayScore } = event

  useEffect(() => {
    isFirstRenderRef.current = true
  }, [])

  // On initial load, check if it is result only mode
  // result only mode is defined if the score is different than 0 and there are no incidents
  // otherwise it is normal mode
  useEffect(() => {
    // Clean period incidents
    const cleanIncidents = incidents?.filter(incident => incident.incidentType !== IncidentType.Period) || []
    if (
      isFirstRenderRef.current &&
      cleanIncidents.length < 1 &&
      (homeScore.current || homeScore.penalties || awayScore.current || awayScore.penalties)
    ) {
      setResultOnlyMode(true)
      isInitialResultOnlyModeRef.current = true
    }
  }, [incidents, homeScore, awayScore])

  const toggleResultOnlyMode = () => {
    if (
      (isInitialResultOnlyModeRef.current && resultOnlyMode === true) ||
      (!isInitialResultOnlyModeRef.current && resultOnlyMode === false)
    ) {
      incidentsDispatch({ type: IncidentActionType.DELETE_ALL_INCIDENTS })
      eventDispatch({ type: EventActionType.RESET_SCORES })
    } else if (
      (isInitialResultOnlyModeRef.current && resultOnlyMode === false) ||
      (!isInitialResultOnlyModeRef.current && resultOnlyMode === true)
    ) {
      incidentsDispatch({ type: IncidentActionType.SET_INCIDENTS, payload: initialIncidents })
      eventDispatch({ type: EventActionType.REINITIALIZE_SCORES })
    }

    setResultOnlyMode(!resultOnlyMode)
  }

  return [resultOnlyMode, toggleResultOnlyMode]
}
