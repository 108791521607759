import { Text, colors } from '@sofascore/ui'
import IconChevronRight16 from '@sofascore/ui/dist/modules/Icons/IconChevronRight16'
import { FormattedMessage, useIntl } from 'react-intl'
import { useEventState } from 'entities/Event'
import { StatusType } from 'entities/Status'

import { Timekeeper, getTimeString } from 'components/Timekeeping'
import { getTimeParts, isToday, isTomorrow } from 'utils/time'

import { SportSettings } from '../model'
import * as S from './styles'

interface Props {
  minutesUntilStart: number
  minuteAfterStart: number
  merge?: 'top' | 'bottom'
  onEditPostMatchClick: () => void
}

const EditPostMatchButton = ({
  merge,
  minutesUntilStart,
  minuteAfterStart,
  enableEditLiveThreshold,
  disableEditLiveInactiveThreshold,
  onEditPostMatchClick,
}: Props & SportSettings) => {
  const intl = useIntl()
  const { initialEvent } = useEventState()
  const { startTimestamp, status } = initialEvent

  const notStarted = status.type === StatusType.NOT_STARTED
  const hasEnded = status.type === StatusType.FINISHED

  /**
   * Post-match editing becomes available when the match ends,
   * or the inactive period of 1h 45mins after the start of the match elapses.
   */
  const isEditPostmatchEnabled = hasEnded || (notStarted && minuteAfterStart >= disableEditLiveInactiveThreshold)

  const isCountdownShown =
    // If today and live editing becomes enabled, show countdown when the post-match will unlock
    isToday(startTimestamp) &&
    minutesUntilStart <= enableEditLiveThreshold &&
    // If the editor starts the match, post-match countdown is no longer available as it's impossible to predict when
    // the match will end or whether the editor will stop it at some point
    status.type !== StatusType.IN_PROGRESS &&
    // If the event has not started and 1h 45mins passes, post-match will become available
    !isEditPostmatchEnabled

  let endingContent: string | JSX.Element
  if (isEditPostmatchEnabled) {
    endingContent = <IconChevronRight16 />
  } else if (isToday(startTimestamp) && notStarted) {
    endingContent = intl.formatMessage({ id: 'editor_calendar_today' })
  } else if (status.type === StatusType.IN_PROGRESS) {
    endingContent = ''
  } else if (isTomorrow(startTimestamp)) {
    endingContent = intl.formatMessage({ id: 'editor_tomorrow' })
  } else {
    const daysRemaining = getTimeParts(startTimestamp - Date.now() / 1000)[0]
    endingContent = intl.formatRelativeTime(daysRemaining, 'day')
  }

  return (
    <S.StyledButton onClick={onEditPostMatchClick} disabled={!isEditPostmatchEnabled} merge={merge}>
      <Text
        fontWeight="bold"
        textTransform="capitalize"
        color={isEditPostmatchEnabled ? undefined : colors.light['--on-surface-nLv3']}
      >
        <FormattedMessage id="edit_post_match" />
      </Text>

      <Text
        fontSize={12}
        fontWeight={500}
        color={isEditPostmatchEnabled ? undefined : colors.light['--on-surface-nLv3']}
      >
        {isCountdownShown ? (
          // Enable Live Editing 15mins before start of the match
          <Timekeeper referenceTime={startTimestamp + 105 * 60} modifier={-1}>
            {({ timeParts }) => {
              const [_days, hours, minutes, seconds] = timeParts
              return <>{getTimeString([hours, minutes, seconds])}</>
            }}
          </Timekeeper>
        ) : (
          endingContent
        )}
      </Text>
    </S.StyledButton>
  )
}

export default EditPostMatchButton
