import { useIntl } from 'react-intl'
import { EventActionType, useEventDispatch, useEventState } from 'entities/Event'
import { StatusCode } from 'entities/Status'
import { Option } from 'shared/model'
import { ColorKey } from '@sofascore/ui'

import { Select } from 'components/Select'

import { getStatusOption } from '../utils'

interface SetMatchStatusProps {
  availableStatusCodes: StatusCode[]
  bg?: ColorKey
}

export const SetMatchStatus = ({ availableStatusCodes, bg = 'surface.s2' }: SetMatchStatusProps) => {
  const intl = useIntl()
  const eventDispatch = useEventDispatch()

  const { event } = useEventState()
  const { status } = event

  const statusOptions = availableStatusCodes.map(statusCode => getStatusOption(statusCode, intl))

  const handleStatusUpdate = (option: Option<number, string>) => {
    eventDispatch({ type: EventActionType.UPDATE_STATUS_CODE, payload: option.value })
  }

  return (
    <Select
      label={intl.formatMessage({ id: 'match_status' })}
      options={statusOptions}
      value={getStatusOption(status.code, intl)}
      onChange={handleStatusUpdate}
      bg={bg}
      borderRadius="8px"
      disableClear
    />
  )
}
