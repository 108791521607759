import { Box, Flex, Text } from '@sofascore/ui'
import { TeamCard } from 'entities/Team'
import { Badge } from 'shared/ui'
import { EditorSuggestion, EditorSuggestionAction } from 'entities/Suggestion'
import IconInfo from '@sofascore/ui/dist/modules/Icons/IconInfo'
import { FormattedMessage } from 'react-intl'

import { Tooltip } from 'components/Tooltip'

import { mapEditorSuggestionToTeam } from '../utils'
import { mapReasonToKey } from '../mappings'

interface MyCompetitionTeamSuggestionProps {
  suggestion: EditorSuggestion
}

export const MyCompetitionTeamSuggestion = ({ suggestion }: MyCompetitionTeamSuggestionProps) => {
  return (
    <Box key={suggestion.id} px="lg" py="sm" hoverBg="surface.s2">
      <TeamCard team={mapEditorSuggestionToTeam(suggestion)}>
        <Flex align="center" gapX="lg">
          {suggestion.reason && (
            <Tooltip
              element={
                <Flex align="center" cursor="pointer">
                  <IconInfo fill="onSurface.nLv3" />
                </Flex>
              }
              label={
                <Text color="onSurface.nLv1" lineHeight={1.4}>
                  <FormattedMessage id={mapReasonToKey[suggestion.reason]} />
                </Text>
              }
              placement="bottom"
              alignment="left"
              clickable
              br="sm"
              p="md"
            />
          )}

          <Badge variant={suggestion.action === EditorSuggestionAction.CREATE ? 'warning' : 'error'}>
            <Text font="assistive.micro" color="inherit">
              <FormattedMessage
                id={suggestion.action === EditorSuggestionAction.CREATE ? 'suggestion_adding' : 'suggestion_removing'}
              />
              {!suggestion.reason && '...'}
            </Text>
          </Badge>
        </Flex>
      </TeamCard>
    </Box>
  )
}
