import { Flex } from '@sofascore/ui'
import { DynamicLineupsPlayer } from 'features/AddLineups'

import { PLAYER_SLOT_HEIGHT, PLAYER_SLOT_WIDTH, TERRAIN_HEIGHT } from '../config'
import { DesktopDragAndDropSlot } from './DesktopDragAndDropSlot'
import { PitchBackground, PitchLines } from './PitchGraphics'
import * as S from './styles'

export const DesktopDragAndDropPitch = ({
  lineupPlayers,
  formation,
  kit,
  goalkeeperKit,
  onDrop,
  onRemove,
}: {
  lineupPlayers: DynamicLineupsPlayer[]
  formation: string
  kit: string
  goalkeeperKit: string
  onDrop: (player: DynamicLineupsPlayer, position: number) => void
  onRemove: (player: DynamicLineupsPlayer) => void
}) => {
  const lines = formation.split('-')
  const linesCount = lines.length

  let position = 1

  return (
    <S.DragAndDropPitch>
      <PitchBackground />
      <PitchLines />

      <Flex direction="column" gapY={linesCount === 3 ? 36 : 28} position="relative" h={TERRAIN_HEIGHT} p="sm">
        {/** Goalkeeper */}
        <Flex align="center" justify="center">
          <DesktopDragAndDropSlot
            lineupPlayers={lineupPlayers}
            kitColor={goalkeeperKit}
            position={position}
            onDrop={onDrop}
            onRemove={onRemove}
            style={{ width: PLAYER_SLOT_WIDTH, height: PLAYER_SLOT_HEIGHT }}
          />
        </Flex>

        {/** Other lines */}
        {lines.map((line, index) => {
          return (
            <Flex key={`${line}-${index}`} gapX="sm" align="center" justify="center">
              {Array(Number(line))
                .fill(undefined)
                .map(() => {
                  position++
                  return (
                    <DesktopDragAndDropSlot
                      key={position}
                      lineupPlayers={lineupPlayers}
                      kitColor={kit}
                      position={position}
                      onDrop={onDrop}
                      onRemove={onRemove}
                      style={{ width: PLAYER_SLOT_WIDTH, height: PLAYER_SLOT_HEIGHT }}
                    />
                  )
                })}
            </Flex>
          )
        })}
      </Flex>
    </S.DragAndDropPitch>
  )
}
