import {
  LineupsAdditionalData,
  LineupsResponse,
  LineupsResponseMissingPlayer,
  LineupsResponsePlayer,
} from 'entities/Lineups'
import { MissingPlayerType } from 'entities/Player'
import { BasicTeam } from 'entities/Team'
import {
  DynamicLineupsEvent,
  DynamicLineupsMissingPlayer,
  DynamicLineupsPayload,
  DynamicLineupsPlayer,
  DynamicLineupsTeamColors,
  LineupsInitialState,
  LineupsState,
} from 'features/AddLineups'

export const createRugbyLineupsDto = (
  eventId: number,
  homeLineupPlayers: DynamicLineupsPlayer[],
  awayLineupPlayers: DynamicLineupsPlayer[],
  homeTeam: BasicTeam,
  awayTeam: BasicTeam,
  additionalData: LineupsAdditionalData | null,
): DynamicLineupsPayload => {
  const _event = {
    id: eventId,
    homeFormation: additionalData ? additionalData.homeFormation : null,
    awayFormation: additionalData ? additionalData.awayFormation : null,
    confirmedLineups: true,
    noMissingPlayersConfirmed: false,
  }

  const homeStarters = homeLineupPlayers.filter(lp => lp.position && !lp.substitute)
  const awayStarters = awayLineupPlayers.filter(lp => lp.position && !lp.substitute)

  const homeSubstitutes = homeLineupPlayers.filter(lp => lp.substitute)
  const awaySubstitutes = awayLineupPlayers.filter(lp => lp.substitute)

  const _eventPlayers = [...homeStarters, ...awayStarters, ...homeSubstitutes, ...awaySubstitutes].map(ep => {
    return {
      ...ep,
      position: null,
      jerseyNumber: ep.position ? ep.position.toString() : ep.jerseyNumber,
    }
  })

  const _missingPlayers = [...homeLineupPlayers, ...awayLineupPlayers]
    .filter(lp => lp.missingReason !== null)
    .map<DynamicLineupsMissingPlayer>(mlp => {
      return {
        player: mlp.player,
        team: mlp.playerTeam === 1 ? homeTeam : awayTeam,
        reason: mlp.missingReason!,
        type: MissingPlayerType.Missing,
      }
    })

  const _teamColors = additionalData
    ? {
        homeTeamColorMain: additionalData.homeKit,
        awayTeamColorMain: additionalData.awayKit,
      }
    : null

  return {
    event: _event as DynamicLineupsEvent,
    eventPlayers: _eventPlayers,
    missingPlayers: _missingPlayers,
    homeTeamColor: 'event' as const,
    awayTeamColor: 'event' as const,
    teamColors: _teamColors as DynamicLineupsTeamColors,
  }
}

export const responseToInitialState = (data: LineupsResponse): Partial<LineupsInitialState> => {
  const allHomePlayers = [...data.home.players, ...(data.home.missingPlayers || [])]
  const allAwayPlayers = [...data.away.players, ...(data.away.missingPlayers || [])]

  const toDynamicLineupsPlayer = (
    p: LineupsResponsePlayer | LineupsResponseMissingPlayer,
    isHomePlayer: boolean,
  ): DynamicLineupsPlayer => {
    if ('reason' in p) {
      return {
        ...p,
        position: null,
        substitute: false,
        captain: false,
        playerTeam: isHomePlayer ? 1 : 2,
        missingReason: typeof p.reason === 'number' ? p.reason : null,
        jerseyNumber: null,
      }
    }

    return {
      ...p,
      position: p.jerseyNumber ? +p.jerseyNumber : p.position,
      substitute: !!p.substitute,
      captain: !!p.captain,
      playerTeam: isHomePlayer ? 1 : 2,
      missingReason: null,
      jerseyNumber: p.jerseyNumber,
    }
  }

  return {
    homeLineupPlayers: allHomePlayers.map<DynamicLineupsPlayer>(p => toDynamicLineupsPlayer(p, true)),
    awayLineupPlayers: allAwayPlayers.map<DynamicLineupsPlayer>(p => toDynamicLineupsPlayer(p, false)),
    homeKit: `#${data.home.playerColor.primary?.toUpperCase()}`,
    awayKit: `#${data.away.playerColor.primary?.toUpperCase()}`,
  }
}

export const isPitchLineupValid = (state: LineupsState) => {
  return state.hasChanges
}
