import useSWR from 'swr'
import { Box, Input } from '@sofascore/ui'
import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { CompetitionType } from 'entities/Tournament'
import { RoundInfo } from 'entities/Event'

import { tournamentCupRounds } from 'api/routes'
import { Option } from 'components/DropdownMenu/interface'
import { Select } from 'components/Select'
import { FormattedTournamentRound } from 'translations/mappings/components/FormattedRound'

import { SET_ROUND, useCreateMatchDispatch, useCreateMatchState } from '../context/createMatchContext'

interface RoundsResponse {
  rounds: Exclude<RoundInfo, 'slug' | 'prefix'>[]
}

const Rounds = () => {
  const [numberRound, setNumberRound] = useState('')
  const [stageRound, setStageRound] = useState<Option | undefined>()

  const { selectedTournament } = useCreateMatchState()
  const dispatch = useCreateMatchDispatch()

  const intl = useIntl()
  const isCup = selectedTournament?.competitionType === CompetitionType.Cup

  const { data: rounds } = useSWR<RoundsResponse>(
    selectedTournament && isCup ? tournamentCupRounds(selectedTournament.id) : null,
  )

  const roundsOptions = useMemo(() => {
    return rounds
      ? rounds.rounds.map(round => {
          return {
            label: round.name!,
            value: round.round,
          }
        })
      : []
  }, [rounds])

  if (isCup) {
    return (
      <Select
        label={intl.formatMessage({ id: 'stage' })}
        options={roundsOptions}
        renderOption={option => (
          <Box px="lg" py="sm">
            <FormattedTournamentRound id={option.label} />
          </Box>
        )}
        value={stageRound}
        renderSelectedOption={option => {
          return option ? <FormattedTournamentRound id={option.label} /> : <></>
        }}
        onChange={option => {
          setStageRound(option)
          dispatch({
            type: SET_ROUND,
            payload: option ? { name: option.label, round: +option.value } : undefined,
          })
        }}
        maxVisibleOptions={6}
        w="100%"
        selectBackgroundColor="surface.s2"
      />
    )
  }

  return (
    <Box w="100%">
      <Input
        label={intl.formatMessage({ id: 'round' })}
        onChange={newValue => {
          if (newValue === '') {
            setNumberRound(newValue)
            dispatch({ type: SET_ROUND, payload: undefined })
          }
          if (newValue.match(/^[1-9][0-9]*$/)) {
            setNumberRound(newValue)
            dispatch({ type: SET_ROUND, payload: { round: +newValue } })
          }
        }}
        regexGuard={/^[1-9][0-9]*$/}
        maxLength={3}
        value={numberRound}
      />
    </Box>
  )
}

export default Rounds
