import { Box, Flex, Text } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'
import { useState } from 'react'
import { useEventState } from 'entities/Event'
import { mutate } from 'swr'

import { Button } from 'components/Button'
import ResponsivePopUp from 'components/ResponsivePopUp'
import { eventDetails } from 'api/routes'

import { SelectablePeriod } from '../model'
import { useUpdatePeriodHandler } from '../hooks'

const ProceedToPeriodPanel = ({ children }: { children: React.ReactNode }) => {
  return (
    <Flex h={152} justify="center" align="center">
      <Text fontWeight="bold" fontSize={16} letterSpacing={0.25}>
        {children}
      </Text>
    </Flex>
  )
}

export const PeriodStartFormFRO = ({
  isOpen,
  selectedPeriod,
  onClose,
}: {
  isOpen: boolean
  selectedPeriod: SelectablePeriod | null
  onClose: () => void
}) => {
  const [isSaving, setIsSaving] = useState(false)
  const { event } = useEventState()

  const { handleUpdate } = useUpdatePeriodHandler()

  const handleProceed = async () => {
    if (selectedPeriod) {
      setIsSaving(true)

      handleUpdate(
        {
          status: {
            code: selectedPeriod.code,
          },
          homeScore: event.homeScore,
          awayScore: event.awayScore,
          aggregatedWinnerCode: event.aggregatedWinnerCode!,
          currentPeriodStartTimestamp: Math.round(new Date().getTime() / 1000),
          roundInfo: event.roundInfo,

          // If editor takes over crowdsourced event, delete all incidents
          resetScore: event.crowdsourcingDataDisplayEnabled,
        },
        event.id,
      )
        .then(() => {
          mutate(eventDetails(event.id))
          onClose()
        })
        .finally(() => setIsSaving(false))
    }
  }

  if (!selectedPeriod) return null

  return (
    <ResponsivePopUp
      isOpen={isOpen}
      onClose={onClose}
      heading={
        <Text font="display.medium">
          {selectedPeriod.longTitleKey && <FormattedMessage id={selectedPeriod.longTitleKey} />}
        </Text>
      }
    >
      <Box>
        <ProceedToPeriodPanel>
          {selectedPeriod.descriptionKey && <FormattedMessage id={selectedPeriod.descriptionKey} />}
        </ProceedToPeriodPanel>

        <Flex gapX="lg" px="lg" mb="xl" mx="lg">
          <Button variant="outlined" fullWidth onClick={onClose}>
            <FormattedMessage id="cancel" />
          </Button>
          <Button variant="contained" fullWidth onClick={handleProceed} isLoading={isSaving}>
            <FormattedMessage id="confirm" />
          </Button>
        </Flex>
      </Box>
    </ResponsivePopUp>
  )
}
