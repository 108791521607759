import { Box, Flex } from '@sofascore/ui'
import { MatchProperties } from 'features/MatchProperties'
import { useEventState } from 'entities/Event'
import { ScoreSection } from 'features/ScoreSection'
import { CountdownSection } from 'features/CountdownSection'
import { StatusType } from 'entities/Status'
import { MatchActionButtonsReduced } from 'features/MatchActionButtons'

import { EditMatchInfoHeader } from '../MatchInfoHeader'
import { DISABLE_EDIT_LIVE_INACTIVE_THRESHOLD, ENABLE_EDIT_LIVE_THRESHOLD } from '../../config'

export const WaterPoloMatchInfo = () => {
  const { event } = useEventState()

  const isNotStarted = event.status.type === StatusType.NOT_STARTED

  return (
    <Flex mt="sm" minH={604} direction="column" justify="space-between">
      <Box>
        <EditMatchInfoHeader>
          {isNotStarted ? (
            <CountdownSection
              enableEditLiveThreshold={ENABLE_EDIT_LIVE_THRESHOLD}
              disableEditLiveInactiveThreshold={DISABLE_EDIT_LIVE_INACTIVE_THRESHOLD}
            />
          ) : (
            <ScoreSection />
          )}
        </EditMatchInfoHeader>

        <MatchProperties hideLocation />
      </Box>

      <Box mt="md">
        <MatchActionButtonsReduced />
      </Box>
    </Flex>
  )
}
