import { useMediaContext } from '@sofascore/ui'

import { DesktopLogin } from './Desktop'
import { MobileLogin } from './Mobile'

/**
 * Login page. If user is already logged in, redirect user to home page
 * Google login, Facebook login
 */
export const Login = () => {
  const { isMobile } = useMediaContext()

  if (isMobile) {
    return <MobileLogin />
  }

  return <DesktopLogin />
}
