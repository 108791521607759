import { Box, ZIndex } from '@sofascore/ui'
import styled, { css } from 'styled-components'

import { backgrounds } from 'styles/color'

import { TooltipLabelProps } from './interface'

const bottomPlacementPosition = css<TooltipLabelProps>`
  &:after {
    top: -9px;
    right: ${p => (p.alignment === 'right' ? undefined : '8px')};
  }
`

const topPlacementPosition = css<TooltipLabelProps>`
  &:after {
    bottom: -9px;
    right: ${p => (p.alignment === 'right' ? undefined : '8px')};
    transform: rotate(225deg);
  }
`

const leftPlacementPosition = css<TooltipLabelProps>`
  &:after {
    right: -9px;
    top: 50%;
    transform: translateY(-50%) rotate(135deg);
  }
`

const rightPlacementPosition = css<TooltipLabelProps>`
  &:after {
    left: -9px;
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
  }
`

export const Tooltip = styled(Box)`
  position: relative;
  display: inline-flex;
`

export const TooltipLabel = styled(Box)<TooltipLabelProps>`
  visibility: ${p => (p.isVisible ? 'visible' : 'hidden')};
  z-index: ${ZIndex.Popover};

  position: fixed;
  padding: ${p => p.p || p.theme.spacing.sm};

  background-color: ${p => p.bg || p.backgroundColor || '#fff'};
  border: ${p => p.border || '1px solid transparent'};
  border-radius: ${p => p.br || p.borderRadius || '4px'};

  box-shadow: 0 0 6px 0 ${backgrounds.lightRgba};
  filter: drop-shadow(0 0 8px ${backgrounds.lightRgba});

  // Tooltip arrow
  &::after {
    position: absolute;
    content: '';
    display: block;
    background-color: inherit;
    border: inherit;
    border-radius: 2px;
    clip-path: polygon(0 0, 0% 100%, 100% 0);

    width: 16px;
    height: 16px;
    transform: rotate(45deg);
  }

  ${p => p.placement === 'bottom' && bottomPlacementPosition};
  ${p => p.placement === 'top' && topPlacementPosition};
  ${p => p.placement === 'left' && leftPlacementPosition};
  ${p => p.placement === 'right' && rightPlacementPosition};
`
