import { PropsWithChildren, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Flex } from '@sofascore/ui'
import { useEventState } from 'entities/Event'
import { mutate } from 'swr'
import { useToast } from 'shared/lib'
import { useErrorHandler } from 'shared/hooks'
import { useLineupsDispatch, useLineupsState, RESET, LineupsState } from 'features/AddLineups'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'
import { FirebaseEventName, FirebaseEventType } from 'shared/lib/firebase/model'

import { createLineups } from 'api'
import { Button } from 'components/Button'
import { getLineups } from 'api/routes'

import { createRugbyLineupsDto } from '../utils'

interface LineupsSaverProps {
  isLineupValid: (state: LineupsState) => boolean
}

export const RugbyLineupsActions = ({ children, isLineupValid }: PropsWithChildren<LineupsSaverProps>) => {
  const intl = useIntl()
  const dispatch = useLineupsDispatch()
  const state = useLineupsState()
  const handleError = useErrorHandler()

  const { homeTeam, homeKit, homeLineupPlayers, awayTeam, awayKit, awayLineupPlayers } = state
  const { event } = useEventState()
  const { enqueueToast } = useToast()

  const [isSaving, setIsSaving] = useState(false)

  const firebasePayload = useMemo(() => {
    return {
      type: FirebaseEventType.Lineup,
      sport: event?.tournament?.category.sport.slug,
      status: event?.status?.type,
      id: event?.id,
    }
  }, [event])

  const saveLineups = () => {
    if (event) {
      const payload = createRugbyLineupsDto(event.id, homeLineupPlayers, awayLineupPlayers, homeTeam, awayTeam, {
        homeKit,
        awayKit,
      })

      setIsSaving(true)

      createLineups(event.id, payload)
        .then(() => {
          dispatch({ type: RESET })
          mutate(getLineups(event.id))
          enqueueToast(intl.formatMessage({ id: 'toastSuccess' }), { variant: 'success' })
          logFirebaseEvent(FirebaseEventName.SubmitEntity, firebasePayload)
        })
        .catch(e => {
          handleError(e)
        })
        .finally(() => {
          setIsSaving(false)
        })
    }
  }

  return (
    <>
      {children}

      <Flex
        justify="center"
        position="fixed"
        p="lg"
        left={0}
        bottom={0}
        w="100%"
        bg="surface.s1"
        elevation={1}
        zIndex={3}
      >
        <Button
          variant="contained"
          onClick={saveLineups}
          isLoading={isSaving}
          disabled={!isLineupValid(state)}
          style={{ padding: '14px 64px' }}
        >
          <FormattedMessage id="save_all_changes" />
        </Button>
      </Flex>
    </>
  )
}
