import { Dispatch, SetStateAction, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useEventState } from 'entities/Event'
import { mutate } from 'swr'
import { useToast } from 'shared/lib'
import { useErrorHandler } from 'shared/hooks'
import { useLineupsDispatch, useLineupsState, RESET, LineupType, CLEAR_ALL, MIN_PLAYERS } from 'features/AddLineups'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'
import { FirebaseEventName, FirebaseEventType } from 'shared/lib/firebase/model'

import { createLineups } from 'api'
import { Button } from 'components/Button'
import { getLineups } from 'api/routes'
import useConfirm from 'modules/ConfirmDialog/useConfirm'

import { createFootballLineupsDto, isListLineupValid, isPitchLineupValid } from '../utils'

interface SaveLineupsProps {
  lineupType: LineupType
}

export const SaveLineups = ({ lineupType }: SaveLineupsProps) => {
  const intl = useIntl()
  const dispatch = useLineupsDispatch()
  const state = useLineupsState()
  const handleError = useErrorHandler()

  const {
    homeTeam,
    homeKit,
    homeFormation,
    homeGoalkeeperKit,
    homeLineupPlayers,
    awayTeam,
    awayKit,
    awayFormation,
    awayGoalkeeperKit,
    awayLineupPlayers,
  } = state
  const { event } = useEventState()
  const { enqueueToast } = useToast()

  const [isSaving, setIsSaving] = useState(false)

  const saveLineups = () => {
    if (event) {
      const payload = createFootballLineupsDto(
        event.id,
        homeLineupPlayers,
        awayLineupPlayers,
        homeTeam,
        awayTeam,

        // We pass additional lineup data
        // only in case of pitch lineups
        lineupType === LineupType.PITCH
          ? {
              homeKit,
              awayKit,
              homeGoalkeeperKit,
              awayGoalkeeperKit,
              homeFormation,
              awayFormation,
            }
          : null,
      )

      setIsSaving(true)

      createLineups(event.id, payload)
        .then(() => {
          dispatch({ type: RESET })
          mutate(getLineups(event.id), undefined)
          enqueueToast(intl.formatMessage({ id: 'toastSuccess' }), { variant: 'success' })
          logFirebaseEvent(FirebaseEventName.SubmitEntity, {
            type: FirebaseEventType.Lineup,
            status: event?.status?.type,
            id: event?.id,
            sport: event?.tournament?.category?.sport?.slug,
          })
        })
        .catch(e => {
          handleError(e)
        })
        .finally(() => {
          setIsSaving(false)
        })
    }
  }

  return (
    <Button
      variant="contained"
      onClick={saveLineups}
      isLoading={isSaving}
      disabled={lineupType === LineupType.PITCH ? !isPitchLineupValid(state) : !isListLineupValid(state)}
      style={{ padding: '14px 64px' }}
    >
      <FormattedMessage id="save_all_changes" />
    </Button>
  )
}

interface SwitchLineupsProps {
  lineupType: LineupType
  setLineupType: Dispatch<SetStateAction<LineupType>>
}

export const SwitchLineups = ({ lineupType, setLineupType }: SwitchLineupsProps) => {
  const dispatch = useLineupsDispatch()
  const { homeLineupPlayers, awayLineupPlayers } = useLineupsState()
  const { event } = useEventState()
  const { confirm } = useConfirm()

  const handleLineupTypeChange = async () => {
    const ok = await confirm({
      titleTextElement: <FormattedMessage id="editor_switch_lineup_question" />,
      messageTextElement: <FormattedMessage id="editor_switch_lineup_message" />,
      confirmTextElement: <FormattedMessage id="switch" />,
      closeTextElement: <FormattedMessage id="cancel" />,
    })

    if (ok) {
      dispatch({ type: CLEAR_ALL, payload: { isHome: true } })
      dispatch({ type: CLEAR_ALL, payload: { isHome: false } })
      setLineupType(lineupType === LineupType.PLAYER_LIST ? LineupType.PITCH : LineupType.PLAYER_LIST)
      logFirebaseEvent(FirebaseEventName.ChangeLineupsType, {
        id: event?.id,
        size: homeLineupPlayers.length + awayLineupPlayers.length,
        type: lineupType === LineupType.PLAYER_LIST ? 'pitch' : 'list',
      })
    }
  }

  const enablePitchLineups =
    homeLineupPlayers.length >= MIN_PLAYERS.PITCH.TO_CREATE && awayLineupPlayers.length >= MIN_PLAYERS.PITCH.TO_CREATE

  if (!enablePitchLineups) return null

  return (
    <Button variant="text" onClick={handleLineupTypeChange} style={{ padding: '14px 64px' }}>
      <FormattedMessage id={lineupType === LineupType.PITCH ? 'editor_switch_list_view' : 'editor_switch_pitch_view'} />
    </Button>
  )
}
