import { CreateIncidentData } from 'entities/Incident'
import { FormattedMessage, useIntl } from 'react-intl'
import { useState } from 'react'
import { Flex, Text } from '@sofascore/ui'
import IconTwoMinuteSuspension from '@sofascore/ui/dist/modules/Icons/_Icon2MinuteSuspension'
import { useFirebaseWithEvent } from 'entities/Event/hooks'
import { FirebaseEventIncidentAction, FirebaseEventName, FirebaseEventType } from 'shared/lib/firebase/model'

import IncidentBox from 'components/IncidentBox'
import ResponsivePopUp from 'components/ResponsivePopUp'

import { AddTwoMinuteSuspensionForm } from './AddTwoMinuteSuspensionForm'

interface Props {
  onCreate: (arg0: CreateIncidentData) => Promise<void>
}

export const AddTwoMinuteSuspension = ({ onCreate }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const intl = useIntl()
  const logFirebaseWithEvent = useFirebaseWithEvent()

  const responsivePopupOpen = () => {
    setIsOpen(true)
    logFirebaseWithEvent(
      FirebaseEventName.AddEntity,
      FirebaseEventType.Incident,
      FirebaseEventIncidentAction.Suspension,
    )
  }
  const responsivePopupClose = (discarded?: boolean | undefined) => {
    setIsOpen(false)
    if (discarded)
      logFirebaseWithEvent(
        FirebaseEventName.DiscardEntity,
        FirebaseEventType.Incident,
        FirebaseEventIncidentAction.Suspension,
      )
    else
      logFirebaseWithEvent(
        FirebaseEventName.SubmitEntity,
        FirebaseEventType.Incident,
        FirebaseEventIncidentAction.Suspension,
      )
  }

  return (
    <>
      <IncidentBox
        label={intl.formatMessage({ id: 'minifootball_2_min_suspension' })}
        onClick={responsivePopupOpen}
        icon={<IconTwoMinuteSuspension />}
      />

      <ResponsivePopUp
        isOpen={isOpen}
        onClose={responsivePopupClose}
        heading={
          <Flex align="center">
            <IconTwoMinuteSuspension />
            <Text font="display.large" ml="sm">
              <FormattedMessage id="add_2_min_suspension" />
            </Text>
          </Flex>
        }
      >
        <AddTwoMinuteSuspensionForm onClose={responsivePopupClose} onSubmit={onCreate} />
      </ResponsivePopUp>
    </>
  )
}
