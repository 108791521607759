/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, Fragment, PropsWithChildren, useCallback, useMemo } from 'react'
import { Box, Flex, Text } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'
import { PlayerCard } from 'entities/Player'
import { Checkbox } from 'shared/ui'
import {
  useLineupsDispatch,
  ADD_TO_LINEUP,
  REMOVE_FROM_LINEUP,
  ADD_TO_SUBSTITUTIONS_SINGLE,
  REMOVE_FROM_SUBSTITUTIONS,
  DynamicLineupsPlayer,
  LineupType,
  LineupsCaptainSelector,
  groupPlayersByPosition,
  sortLineupPlayers,
} from 'features/AddLineups'

import { IconBench } from 'components/Icons/IconBench'
import { IconPitch } from 'components/Icons/IconPitch'

import { ListLineupsCounter } from './ListLineupsCounter'

interface ListLineupsColumnProps {
  lineupPlayers: DynamicLineupsPlayer[]
  positionGroupMapping: Record<string, string>
  isHomeTeam?: boolean
}

export const ListLineupsColumn = ({
  children,
  lineupPlayers,
  positionGroupMapping,
  isHomeTeam,
}: PropsWithChildren<ListLineupsColumnProps>) => {
  const dispatch = useLineupsDispatch()

  const starters = lineupPlayers.filter(lp => lp.position && !lp.substitute)
  const substitutions = lineupPlayers.filter(lp => lp.substitute)

  const groupedAvailablePlayers = useMemo(() => {
    return groupPlayersByPosition(lineupPlayers)
  }, [lineupPlayers.length])

  const handleCheckLineupPlayer = useCallback(
    (event: ChangeEvent<HTMLInputElement>, lineupPlayer: DynamicLineupsPlayer) => {
      dispatch({
        type: event.target.checked ? ADD_TO_LINEUP : REMOVE_FROM_LINEUP,
        payload: { lineupPlayer },
      })
    },
    [],
  )

  const handleCheckSubstitutionPlayer = useCallback(
    (event: ChangeEvent<HTMLInputElement>, lineupPlayer: DynamicLineupsPlayer) => {
      dispatch({
        type: event.target.checked ? ADD_TO_SUBSTITUTIONS_SINGLE : REMOVE_FROM_SUBSTITUTIONS,
        payload: { lineupPlayer, position: null },
      })
    },
    [],
  )

  return (
    <Box h="100%" p="lg" bg="surface.s1" br={[0, 'sm']}>
      {children}

      <Box my="lg">
        <LineupsCaptainSelector starters={starters} />
      </Box>

      <ListLineupsCounter type={LineupType.PLAYER_LIST} lineupPlayers={lineupPlayers} isHomeTeam={!!isHomeTeam} />

      <Flex align="center" justify="end" gapX="lg" mt="lg">
        <IconPitch />
        <IconBench />
      </Flex>

      {Object.keys(positionGroupMapping).map(position => {
        return (
          <Fragment key={position}>
            <Box py="lg">
              <Text font="display.micro" fontWeight={600}>
                <FormattedMessage id={positionGroupMapping[position]} />
              </Text>
            </Box>

            <Flex direction="column">
              {groupedAvailablePlayers[position] &&
                sortLineupPlayers(groupedAvailablePlayers[position]).map(lp => {
                  return (
                    <Flex key={lp.player.id} justify="space-between" align="center" py="sm">
                      <PlayerCard player={lp.player} showJerseyNumber />
                      <Box ml="auto">
                        <Checkbox
                          name={`checkbox-lineup-${lp.player.id}`}
                          checked={!!starters.find(s => s.player.id === lp.player.id)}
                          onChange={event => handleCheckLineupPlayer(event, lp)}
                        />
                      </Box>
                      <Box ml="lg">
                        <Checkbox
                          name={`checkbox-substitution-${lp.player.id}`}
                          checked={!!substitutions.find(s => s.player.id === lp.player.id)}
                          onChange={event => handleCheckSubstitutionPlayer(event, lp)}
                        />
                      </Box>
                    </Flex>
                  )
                })}
            </Flex>
          </Fragment>
        )
      })}

      {groupedAvailablePlayers['-'] && (
        <>
          <Box py="lg">
            <Text font="display.micro" fontWeight={600}>
              <FormattedMessage id="unknown" />
            </Text>
          </Box>

          <Flex direction="column">
            {groupedAvailablePlayers['-'] &&
              sortLineupPlayers(groupedAvailablePlayers['-']).map(lp => {
                return (
                  <Flex key={lp.player.id} justify="space-between" align="center" py="sm">
                    <PlayerCard player={lp.player} showJerseyNumber />
                    <Box ml="auto">
                      <Checkbox
                        name={`checkbox-lineup-${lp.player.id}`}
                        checked={!!starters.find(s => s.player.id === lp.player.id)}
                        onChange={event => handleCheckLineupPlayer(event, lp)}
                      />
                    </Box>
                    <Box ml="lg">
                      <Checkbox
                        name={`checkbox-substitution-${lp.player.id}`}
                        checked={!!substitutions.find(s => s.player.id === lp.player.id)}
                        onChange={event => handleCheckSubstitutionPlayer(event, lp)}
                      />
                    </Box>
                  </Flex>
                )
              })}
          </Flex>
        </>
      )}
    </Box>
  )
}
