import { CSSProperties } from 'react'
import { Flex } from '@sofascore/ui'
import { DynamicLineupsPlayer } from 'features/AddLineups'

import { IconShirt } from 'components/Icons/IconShirt'
import { IconButton } from 'components/IconButton'
import { IconAddImage } from 'components/Icons/IconAddImage'
import { IconRemoveFilled } from 'components/Icons/IconRemoveFilled'
import { IconShirtEmpty } from 'components/Icons/IconShirtEmpty'

import * as S from './styles'

export const MobileClickSlot = ({
  lineupPlayers,
  position,
  onClick,
  kitColor,
  onRemove,
  style,
}: {
  lineupPlayers: DynamicLineupsPlayer[]
  position: number
  onClick: (position: number) => void
  kitColor: string
  onRemove: (player: DynamicLineupsPlayer) => void
  style?: CSSProperties
}) => {
  const addedPlayer = lineupPlayers.find(p => p.position === position)

  if (addedPlayer) {
    return (
      <Flex direction="column" align="center" style={style} textAlign="center">
        <Flex align="flex-end" position="relative">
          <IconShirt fill={kitColor} width={28} height={32} />
          <S.JerseyNumber $isWhiteJersey={kitColor === '#FFFFFF'}>{position}</S.JerseyNumber>
          <IconButton position="absolute" left="95%" bottom={-2} onClick={() => onRemove(addedPlayer)} p="0px">
            <IconRemoveFilled />
          </IconButton>
        </Flex>

        <S.PlayerName>
          {addedPlayer.captain ? '(c) ' : ''} {addedPlayer.player.name}
        </S.PlayerName>
      </Flex>
    )
  }

  return (
    <Flex direction="column" align="center" style={style} textAlign="center">
      <Flex align="flex-end" position="relative">
        <IconShirtEmpty fill={kitColor} width={28} height={32} />
        <S.JerseyNumber $isWhiteJersey={kitColor === '#FFFFFF'}>{position}</S.JerseyNumber>
        <IconButton position="absolute" left="95%" bottom={-2} onClick={() => onClick(position)} p="0px">
          <IconAddImage width={24} height={24} />
        </IconButton>
      </Flex>
    </Flex>
  )
}
