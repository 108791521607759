import { ChangeEvent, MouseEvent, useEffect, useLayoutEffect, useState } from 'react'
import { Box, Flex, Text } from '@sofascore/ui'

import { Option } from 'components/DropdownMenu/interface'

import PickerColumn from './PickerColumn'
import * as S from './styles'

export interface ColumnsOptions {
  [columnName: string]: Option[]
}

export interface ColumnsValues {
  [columnName: string]: string | number
}

interface InputWheelPickerProps {
  columnsOptions: ColumnsOptions
  columnsValues: ColumnsValues
  delimiter?: string
  onChange: (name: string, value: string | number) => void
  /** height of each option item */
  itemHeight?: number
  /** height of the component (scrolling window) */
  height?: number
  /* width of the column input */
  inputWidth?: number
}

/**
 * This component is a variation of the WheelPicker component,
 * where each column has an input for user free entry.
 *
 * Every scrolling column has a list of options and a single selected value.
 */
const InputWheelPicker = ({
  columnsOptions,
  columnsValues,
  delimiter,
  onChange,
  itemHeight = 32,
  height = 216,
  inputWidth = 35,
}: InputWheelPickerProps) => {
  const [inputCount, setInputCount] = useState(0)
  const [focusedColumnIndex, setFocusedColumnIndex] = useState(0)

  const [delimiterWidth, setDelimiterWidth] = useState(0)

  const handleInputClick = (columnIndex: number) => (event: MouseEvent<HTMLInputElement>) => {
    setInputCount(0)
    ;(event.target as HTMLInputElement).select()
    setFocusedColumnIndex(columnIndex)
  }

  const handleInputChange = (columnName: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(`${event.target.value}`)
    const newValueIsValid = columnsOptions[columnName].find(option => option.value === newValue)
    if (newValueIsValid) {
      onChange(columnName, newValue)
      if (newValue !== 0) {
        setInputCount(count => count + 1)
      } else {
        setInputCount(0)
      }
    }
  }

  useEffect(() => {
    // Focus next column input after two inputs
    const isLastColumnInput = focusedColumnIndex === Object.keys(columnsOptions).length - 1
    if (!isLastColumnInput && inputCount === 2) {
      // Reset counter
      setInputCount(0)
      const nextInputElement = document.getElementById(`iwp-ci-${focusedColumnIndex + 1}`) as HTMLInputElement
      if (nextInputElement) {
        nextInputElement.select()
      }
    }
  }, [columnsOptions, inputCount, focusedColumnIndex])

  useLayoutEffect(() => {
    const delimiter = document.getElementById('iwp-delimiter')
    if (delimiter) {
      setDelimiterWidth(delimiter.clientWidth)
    }
  }, [delimiter])

  return (
    <S.PickerContainer style={{ height }}>
      <S.PickerInner>
        {Object.keys(columnsOptions).map((columnName, columnIndex, array) => {
          // Find the selected item for this column
          // Index is calculated here to have the information ready for PickerColumn render
          const options = columnsOptions[columnName]
          const value = columnsValues[columnName]
          const selectedOption = options.find(option => option.value === value)

          let selectedIndex = 0
          if (selectedOption) {
            selectedIndex = options.indexOf(selectedOption)
          }

          const isFirstColumnOfMany = array.length > 1 && columnIndex === 0
          const isLastColumnOfMany = array.length > 1 && columnIndex === array.length - 1

          return (
            <Flex key={columnName} h="100%" grow={1}>
              <PickerColumn
                name={columnName}
                options={columnsOptions[columnName]}
                value={columnsValues[columnName]}
                selectedIndex={selectedIndex}
                itemHeight={itemHeight}
                columnHeight={height}
                columnIndex={columnIndex}
                alignColumn={isFirstColumnOfMany ? 'flex-end' : isLastColumnOfMany ? 'flex-start' : 'center'}
                onChange={onChange}
              />
              {/* Space for delimiter */}
              {columnIndex !== array.length - 1 && <Box w={delimiterWidth} h={itemHeight} />}
            </Flex>
          )
        })}

        <S.PickerHighlight
          style={{
            height: itemHeight,
            marginTop: -(itemHeight / 2),
          }}
        >
          <S.InputFrame id="iwp-input-frame" bg="neutral.highlight" border="onSurface.nLv4">
            {Object.keys(columnsOptions).map((columnName, columnIndex) => {
              const inputValue = `0${columnsValues[columnName]}`.slice(-2)
              return (
                <Flex key={columnName} h="100%" align="center">
                  <S.ColumnInput
                    id={`iwp-ci-${columnIndex}`}
                    className="iwp-column-input"
                    type="number"
                    value={inputValue}
                    onClick={handleInputClick(columnIndex)}
                    onChange={handleInputChange(columnName)}
                    onKeyDown={event => {
                      if (event.key === 'Enter') {
                        ;(event.target as HTMLInputElement).blur()
                      }
                    }}
                    style={{ width: inputWidth }}
                  />
                  {columnIndex === 0 && (
                    <Text id="iwp-delimiter" fontSize="extraLarge" fontWeight="bold">
                      {delimiter}
                    </Text>
                  )}
                </Flex>
              )
            })}
          </S.InputFrame>
        </S.PickerHighlight>
      </S.PickerInner>
    </S.PickerContainer>
  )
}

export default InputWheelPicker
