import styled from 'styled-components'
import { Box } from '@sofascore/ui'

export const SaveAllChanges = styled(Box)`
  position: fixed;
  bottom: 0;

  transform: translateX(0);
  z-index: 1;

  ${p => p.theme.breakpoints.query.mdMin`
    transform: translateX(-50%);
    bottom: 24px;
  `}
`
