import { Flex, Text, Box, GraphicEmptyStateNoData } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'

export const MyCompetitionNoTeams = () => {
  return (
    <Flex direction="column" align="center" mt={64}>
      <GraphicEmptyStateNoData fill="primary.default" />

      <Box textAlign="center" mt="xl">
        <Text fontWeight="bold" fontSize="medium">
          <FormattedMessage id="no_teams_added_header" />
        </Text>

        <Box mt="md">
          <Text color="onSurface.nLv2">
            <FormattedMessage id="no_teams_added_text" />
          </Text>
        </Box>
      </Box>
    </Flex>
  )
}
