import { Box } from '@sofascore/ui'
import { useSelector } from 'react-redux'
import { useEventState } from 'entities/Event'

import { selectEvent } from 'store/selectors'
import { HeaderActionProvider } from 'modules/Matches/MatchDetails/headerActionContext'
import { Details, Header } from 'modules/Matches/MatchDetails/NewSportsMatchDetails'
import { MY_COMPETITION_HEADER_MOBILE_HEIGHT } from 'modules/MyCompetitions/components/MyCompetitionHeader/config'

export default function NewSportsMatchDetailsForm() {
  const { event: eventData } = useEventState()
  const event = useSelector(selectEvent)

  if (!event) return null

  return (
    <Box overflow="auto" mt={[-MY_COMPETITION_HEADER_MOBILE_HEIGHT, 0]}>
      <HeaderActionProvider>
        <Header />

        <Box style={{ maxWidth: 664, marginLeft: 'auto', marginRight: 'auto' }}>
          <Details eventData={eventData} />
        </Box>
      </HeaderActionProvider>
    </Box>
  )
}
