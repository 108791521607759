import { useCallback, useEffect, useState } from 'react'
import { Box, Flex, Text } from '@sofascore/ui'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useSWR, { mutate } from 'swr'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Team } from 'entities/Team'
import { SportName } from 'entities/Sport'
import { NewSeasonData, NewSeasonResponse } from 'entities/Season'
import { BasicTournament } from 'entities/Tournament'
import { UniqueTournamentTeamsResponse } from 'entities/UniqueTournament'
import { useToast } from 'shared/lib'

import { getAuthFromState } from 'store/selectors'
import { createNewSeason } from 'api'
import { getUpdatedURLPath } from 'utils/url'
import { useMyCompetitionsState } from 'modules/MyCompetitions/providers/MyCompetitionsProvider'
import { tournamentsList, uniqueTournamentAllTeams, uniqueTournaments } from 'api/routes'
import { BasicTournamentsResponse } from 'modules/TournamentDetails/interface'
import { Button } from 'components/Button'
import { FormDatePicker } from 'components/FormDatePicker'
import { Loading } from 'components/Loading'

import { TeamItem } from './TeamItem'

interface Props {
  onClose: () => void
}

export const CreateNewSeasonForm = ({ onClose }: Props) => {
  const { enqueueToast } = useToast()
  const { userAccount } = useSelector(getAuthFromState)
  const { uniqueTournament } = useMyCompetitionsState()
  const { competitionId, seasonId } = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const intl = useIntl()
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const [isSaving, setIsSaving] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [tournaments, setTournaments] = useState<BasicTournament[]>()
  const [hasOneTournament, setHasOneTournament] = useState(false)
  const [teams, setTeams] = useState<Team[]>()
  const [teamIds, setTeamIds] = useState<number[]>()
  const isTennis = uniqueTournament?.category?.sport?.slug === SportName.Tennis

  /* 
    When a unique tournament has only one tournament, then the list of teams participating in a 
    particular tournament can be selected when creating a new season. 
    If there are several tournaments, the selection of teams is only possible after creating a season.
  */
  // Fetch list of tournaments
  const { data: allTournaments } = useSWR<BasicTournamentsResponse>(
    tournamentsList(Number(competitionId), Number(seasonId)),
  )

  useEffect(() => {
    setTournaments(allTournaments?.tournaments)
    if (allTournaments?.tournaments?.length === 1) {
      setHasOneTournament(true)
    } else {
      setHasOneTournament(false)
    }
  }, [allTournaments])

  const { data: allTeams } = useSWR<UniqueTournamentTeamsResponse>(
    hasOneTournament && uniqueTournamentAllTeams(Number(competitionId)),
  )

  useEffect(() => {
    setTeams(allTeams?.teams)
  }, [allTeams])

  const handleCreateNewSeason = useCallback(async () => {
    if (startDate && endDate && userAccount) {
      try {
        setIsSaving(true)

        let seasonData: NewSeasonData = {
          startDateTimestamp: Math.round(startDate.getTime() / 1000),
          endDateTimestamp: Math.round(endDate.getTime() / 1000),
          addTeams: hasOneTournament,
        }

        if (hasOneTournament && tournaments) {
          seasonData = { ...seasonData, tournament: tournaments[0]?.id, teamIds }
        }

        const response = (await createNewSeason(Number(competitionId), seasonData)) as unknown as NewSeasonResponse

        enqueueToast(intl.formatMessage({ id: 'seasonCreated' }), { variant: 'success' })

        mutate(uniqueTournaments())

        // Go to the new season
        const newPath = getUpdatedURLPath(pathname, 'season', response.season.id.toString())
        navigate(newPath)
        onClose()
      } catch (error) {
        enqueueToast(intl.formatMessage({ id: 'toastError' }), { variant: 'error' })
        console.error(error)
      } finally {
        setIsSaving(false)
      }
    }
  }, [
    competitionId,
    startDate,
    endDate,
    hasOneTournament,
    tournaments,
    teamIds,
    onClose,
    userAccount,
    intl,
    navigate,
    pathname,
    enqueueToast,
  ])

  const handleStartDate = (date: Date) => {
    setStartDate(date)
  }

  const handleEndDate = (date: Date) => {
    setEndDate(date)
  }

  const handleTeamOnClick = (teamId: number) => {
    const tempTeamIds = teamIds ? [...teamIds] : []
    const index = tempTeamIds.indexOf(teamId)

    if (index > -1) {
      tempTeamIds.splice(index, 1)
    } else {
      tempTeamIds.push(teamId)
    }

    setTeamIds(tempTeamIds)
  }

  // Handle if form is valid
  useEffect(() => {
    if (startDate && endDate && startDate.getTime() < endDate.getTime()) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }, [startDate, endDate])

  return (
    <Box px="lg" pb="xxl">
      <Flex mb="lg" gapX="sm" direction="row">
        <Box w="50%">
          <FormDatePicker
            label={<FormattedMessage id="startDate" />}
            date={startDate}
            onChange={handleStartDate}
            helperText={<FormattedMessage id="requiredField" />}
          />
        </Box>
        <Box w="50%">
          <FormDatePicker
            label={<FormattedMessage id="endDate" />}
            date={endDate}
            onChange={handleEndDate}
            helperText={<FormattedMessage id="requiredField" />}
            errorText={
              startDate && endDate && startDate.getTime() > endDate.getTime() ? (
                <FormattedMessage id="invalidEndDate" />
              ) : undefined
            }
          />
        </Box>
      </Flex>

      <Flex w="100%" direction="column">
        {!allTournaments || (hasOneTournament && !allTeams) ? (
          <Loading mt="0" />
        ) : !hasOneTournament || (teams && teams.length === 0) ? (
          <Box px="lg" py="sm" border="1px solid onSurface.nLv4" br="sm">
            <Text>
              <FormattedMessage
                id="teamsSelectionMessage"
                values={{
                  teamsOrPlayers: intl.formatMessage({ id: isTennis ? 'players' : 'teams' }).toLowerCase(),
                  tabType: intl.formatMessage({ id: isTennis ? 'players' : 'teams' }).toUpperCase(),
                }}
              />
            </Text>
          </Box>
        ) : (
          <Flex w="100%" direction="column">
            <Box px="lg" py="sm" mb="lg" border="1px solid onSurface.nLv4" br="sm">
              <Text>
                <FormattedMessage
                  id="participatingTeamsDesc"
                  values={{
                    teamsOrPlayers: intl.formatMessage({ id: isTennis ? 'players' : 'teams' }).toLowerCase(),
                    tabType: intl.formatMessage({ id: isTennis ? 'players' : 'teams' }).toUpperCase(),
                  }}
                />
              </Text>
            </Box>
            {teams?.map(team => (
              <TeamItem
                team={team}
                key={team.id}
                checked={teamIds && teamIds.indexOf(team.id) > -1 ? true : false}
                handleChange={handleTeamOnClick}
              />
            ))}
          </Flex>
        )}
      </Flex>

      <Flex gapX="lg" px="lg" mt="xxl">
        <Button variant="outlined" fullWidth onClick={onClose}>
          <FormattedMessage id="cancel" />
        </Button>
        <Button
          variant="contained"
          fullWidth
          onClick={handleCreateNewSeason}
          isLoading={isSaving}
          disabled={!isValid || isSaving}
        >
          <FormattedMessage id="confirm" />
        </Button>
      </Flex>
    </Box>
  )
}
