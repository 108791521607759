import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { matchesReducer } from 'modules/Matches/matchesReducer'

import uiControlsReducer from './uiControls/reducer'
import { authReducer } from './auth/reducer'

const authPersistConfig = {
  key: 'auth',
  storage,
  blacklist: ['error'],
}

const uiControlsPersistConfig = {
  key: 'uicontrols',
  storage,
}

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  event: matchesReducer,
  uicontrols: persistReducer(uiControlsPersistConfig, uiControlsReducer),
})

export const persistedReducer = rootReducer
