import { Box } from '@sofascore/ui'
import styled from 'styled-components'

export const TeamDetailsContainer = styled(Box)`
  display: grid;
  grid-template-columns: 344px 3fr;
  grid-template-rows: auto;
  padding: 16px 48px;
  min-height: 100%;

  ${p => p.theme.breakpoints.query.mdMax`
    display: block;
    padding: 8px;
    `}
`
