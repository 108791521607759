import { Flex, Portal } from '@sofascore/ui'
import { AnimatePresence, motion, useMotionValue } from 'framer-motion'
import noop from 'lodash/noop'
import { ReactNode, useState } from 'react'

import { useToastDispatch, useToastState } from '../hooks'
import { DEQUEUE_TOAST } from '../providers'
import Toast from './Toast'
import { ToastAction, ToastOptions } from '../model'

interface Props {
  id: string
  action?: ToastAction
  content: ReactNode
  options: ToastOptions
}

const DRAG_THRESH = 100 // px

const ToastCard = ({ id, action, content, options }: Props) => {
  const dispatch = useToastDispatch()

  const x = useMotionValue(0)

  const [dismissAvailable, setDismissAvailable] = useState(false)

  const scheduleDismissal = (id: string, duration: number) => {
    setTimeout(() => {
      dispatch({ type: DEQUEUE_TOAST, payload: id })
    }, duration)
  }

  const handleDrag = () => {
    if (x.get() > DRAG_THRESH) {
      setDismissAvailable(true)
    } else {
      setDismissAvailable(false)
    }
  }

  const handleDragEnd = () => {
    if (dismissAvailable) {
      dispatch({ type: DEQUEUE_TOAST, payload: id })
    }
  }

  return (
    <motion.div
      initial={{ x: 300, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: 300, opacity: 0 }}
      transition={{ duration: 0.5 }}
      drag="x"
      dragConstraints={{ left: 0 }}
      dragElastic={{ left: 0 }}
      dragMomentum={false}
      onDrag={handleDrag}
      onDragEnd={handleDragEnd}
      dragSnapToOrigin
      style={{ x }}
    >
      <Toast
        onLoad={options.duration ? () => scheduleDismissal(id, options.duration!) : noop}
        variant={options.variant}
        action={action}
      >
        {content}
      </Toast>
    </motion.div>
  )
}

export const ToastContainer = () => {
  const state = useToastState()

  return (
    <Portal>
      <Flex
        id="toasts"
        direction="column"
        position="fixed"
        right="xl"
        bottom="lg"
        gapY="sm"
        role="status"
        aria-live="assertive"
      >
        <AnimatePresence>
          {state.messages.map(({ id, action, content, options }) => (
            <ToastCard key={id} id={id} action={action} content={content} options={options} />
          ))}
        </AnimatePresence>
      </Flex>
    </Portal>
  )
}
