import { CountriesInfo } from '../model'

export const countriesInfo: CountriesInfo = {
  AF: { alpha2: 'AF', alpha3: 'AFG', ioc: 'AFG', language: ['fa', 'ps'], continent: 'Asia', slug: 'afghanistan' },
  AX: { alpha2: 'AX', alpha3: 'ALA', language: ['sv'], slug: 'aland-islands' },
  AL: { alpha2: 'AL', alpha3: 'ALB', ioc: 'ALB', language: ['sq'], continent: 'Europe', slug: 'albania' },
  DZ: { alpha2: 'DZ', alpha3: 'DZA', ioc: 'ALG', language: ['ar'], continent: 'Africa', slug: 'algeria' },
  AS: { alpha2: 'AS', alpha3: 'ASM', ioc: 'ASA', language: ['en', 'sm'], slug: 'american-samoa' },
  AD: { alpha2: 'AD', alpha3: 'AND', ioc: 'AND', language: ['ca'], continent: 'Europe', slug: 'andorra' },
  AO: { alpha2: 'AO', alpha3: 'AGO', ioc: 'ANG', language: ['pt'], continent: 'Africa', slug: 'angola' },
  AI: { alpha2: 'AI', alpha3: 'AIA', language: ['en'], continent: 'North America', slug: 'anguilla' },
  AQ: { alpha2: 'AQ', alpha3: 'ATA', language: ['en', 'es', 'fr', 'ru'], slug: 'antarctica' },
  AG: {
    alpha2: 'AG',
    alpha3: 'ATG',
    ioc: 'ANT',
    language: ['en'],
    continent: 'North America',
    slug: 'antigua-and-barbuda',
  },
  AR: { alpha2: 'AR', alpha3: 'ARG', ioc: 'ARG', language: ['es'], continent: 'South America', slug: 'argentina' },
  AM: { alpha2: 'AM', alpha3: 'ARM', ioc: 'ARM', language: ['hy'], continent: 'Asia', slug: 'armenia' },
  AW: { alpha2: 'AW', alpha3: 'ABW', ioc: 'ARU', language: ['nl'], continent: 'North America', slug: 'aruba' },
  AU: { alpha2: 'AU', alpha3: 'AUS', ioc: 'AUS', language: ['en'], continent: 'Australia', slug: 'australia' },
  AT: { alpha2: 'AT', alpha3: 'AUT', ioc: 'AUT', language: ['de'], continent: 'Europe', slug: 'austria' },
  AZ: { alpha2: 'AZ', alpha3: 'AZE', ioc: 'AZE', language: ['az'], continent: 'Asia', slug: 'azerbaijan' },
  BS: { alpha2: 'BS', alpha3: 'BHS', ioc: 'BAH', language: ['en'], continent: 'North America', slug: 'bahamas' },
  BH: { alpha2: 'BH', alpha3: 'BHR', ioc: 'BRN', language: ['ar'], continent: 'Asia', slug: 'bahrain' },
  BD: { alpha2: 'BD', alpha3: 'BGD', ioc: 'BAN', language: ['bn'], continent: 'Asia', slug: 'bangladesh' },
  BB: { alpha2: 'BB', alpha3: 'BRB', ioc: 'BAR', language: ['en'], continent: 'North America', slug: 'barbados' },
  BY: { alpha2: 'BY', alpha3: 'BLR', ioc: 'BLR', language: ['be', 'ru'], continent: 'Europe', slug: 'belarus' },
  BE: { alpha2: 'BE', alpha3: 'BEL', ioc: 'BEL', language: ['nl', 'fr', 'de'], continent: 'Europe', slug: 'belgium' },
  BZ: { alpha2: 'BZ', alpha3: 'BLZ', ioc: 'BIZ', language: ['en'], continent: 'North America', slug: 'belize' },
  BJ: { alpha2: 'BJ', alpha3: 'BEN', ioc: 'BEN', language: ['fr'], continent: 'Africa', slug: 'benin' },
  BM: { alpha2: 'BM', alpha3: 'BMU', ioc: 'BER', language: ['en'], continent: 'North America', slug: 'bermuda' },
  BT: { alpha2: 'BT', alpha3: 'BTN', ioc: 'BHU', language: ['dz'], continent: 'Asia', slug: 'bhutan' },
  BO: { alpha2: 'BO', alpha3: 'BOL', ioc: 'BOL', language: ['es'], continent: 'South America', slug: 'bolivia' },
  BQ: {
    alpha2: 'BQ',
    alpha3: 'BES',
    language: ['nl'],
    continent: 'North America',
    slug: 'bonaire-sint-eustatius-and-saba',
  },
  BA: {
    alpha2: 'BA',
    alpha3: 'BIH',
    ioc: 'BIH',
    language: ['bs', 'hr', 'sr'],
    continent: 'Europe',
    slug: 'bosnia-herzegovina',
  },
  BW: { alpha2: 'BW', alpha3: 'BWA', ioc: 'BOT', language: ['en', 'tn'], continent: 'Africa', slug: 'botswana' },
  BV: { alpha2: 'BV', alpha3: 'BVT', language: ['no'], slug: 'bouvet-island' },
  BR: { alpha2: 'BR', alpha3: 'BRA', ioc: 'BRA', language: ['pt'], continent: 'South America', slug: 'brazil' },
  IO: { alpha2: 'IO', alpha3: 'IOT', language: ['en'], slug: 'british-indian-ocean-territory' },
  BN: { alpha2: 'BN', alpha3: 'BRN', ioc: 'BRU', language: ['ms'], continent: 'Asia', slug: 'brunei' },
  BG: { alpha2: 'BG', alpha3: 'BGR', ioc: 'BUL', language: ['bg'], continent: 'Europe', slug: 'bulgaria' },
  BF: { alpha2: 'BF', alpha3: 'BFA', ioc: 'BUR', language: ['fr'], continent: 'Africa', slug: 'burkina-faso' },
  BI: { alpha2: 'BI', alpha3: 'BDI', ioc: 'BDI', language: ['fr'], continent: 'Africa', slug: 'burundi' },
  CV: { alpha2: 'CV', alpha3: 'CPV', ioc: 'CPV', language: ['pt'], continent: 'Africa', slug: 'cape-verde' },
  KH: { alpha2: 'KH', alpha3: 'KHM', ioc: 'CAM', language: ['km'], continent: 'Asia', slug: 'cambodia' },
  CM: { alpha2: 'CM', alpha3: 'CMR', ioc: 'CMR', language: ['fr', 'en'], continent: 'Africa', slug: 'cameroon' },
  CA: { alpha2: 'CA', alpha3: 'CAN', ioc: 'CAN', language: ['en', 'fr'], continent: 'North America', slug: 'canada' },
  KY: { alpha2: 'KY', alpha3: 'CYM', ioc: 'CAY', language: ['en'], continent: 'North America', slug: 'cayman-islands' },
  CF: {
    alpha2: 'CF',
    alpha3: 'CAF',
    ioc: 'CAF',
    language: ['fr', 'sg'],
    continent: 'Africa',
    slug: 'central-african-rep',
  },
  TD: { alpha2: 'TD', alpha3: 'TCD', ioc: 'CHA', language: ['fr', 'ar'], continent: 'Africa', slug: 'chad' },
  CL: { alpha2: 'CL', alpha3: 'CHL', ioc: 'CHI', language: ['es'], continent: 'South America', slug: 'chile' },
  CN: { alpha2: 'CN', alpha3: 'CHN', ioc: 'CHN', language: ['zh'], continent: 'Asia', slug: 'china' },
  CX: { alpha2: 'CX', alpha3: 'CXR', language: ['en'], slug: 'christmas-island' },
  CC: { alpha2: 'CC', alpha3: 'CCK', language: ['en'], slug: 'cocos-keeling-islands' },
  CO: { alpha2: 'CO', alpha3: 'COL', ioc: 'COL', language: ['es'], continent: 'South America', slug: 'colombia' },
  KM: { alpha2: 'KM', alpha3: 'COM', ioc: 'COM', language: ['ar', 'fr', 'sw'], continent: 'Africa', slug: 'comoros' },
  CG: { alpha2: 'CG', alpha3: 'COG', ioc: 'CGO', language: ['fr'], continent: 'Africa', slug: 'congo' },
  CD: { alpha2: 'CD', alpha3: 'COD', ioc: 'COD', language: ['fr'], continent: 'Africa', slug: 'dr-congo' },
  CK: { alpha2: 'CK', alpha3: 'COK', ioc: 'COK', language: ['en'], continent: 'Asia', slug: 'cook-islands' },
  CR: { alpha2: 'CR', alpha3: 'CRI', ioc: 'CRC', language: ['es'], continent: 'North America', slug: 'costa-rica' },
  CI: { alpha2: 'CI', alpha3: 'CIV', ioc: 'CIV', language: ['fr'], continent: 'Africa', slug: 'ivory-coast' },
  HR: { alpha2: 'HR', alpha3: 'HRV', ioc: 'CRO', language: ['hr'], continent: 'Europe', slug: 'croatia' },
  CU: { alpha2: 'CU', alpha3: 'CUB', ioc: 'CUB', language: ['es'], continent: 'North America', slug: 'cuba' },
  CW: { alpha2: 'CW', alpha3: 'CUW', language: ['nl', 'en'], continent: 'North America', slug: 'curacao' },
  CY: { alpha2: 'CY', alpha3: 'CYP', ioc: 'CYP', language: ['el', 'tr'], continent: 'Europe', slug: 'cyprus' },
  CZ: { alpha2: 'CZ', alpha3: 'CZE', ioc: 'CZE', language: ['cs'], continent: 'Europe', slug: 'czech-republic' },
  DK: { alpha2: 'DK', alpha3: 'DNK', ioc: 'DEN', language: ['da'], continent: 'Europe', slug: 'denmark' },
  DJ: {
    alpha2: 'DJ',
    alpha3: 'DJI',
    ioc: 'DJI',
    language: ['fr', 'ar', 'so', 'aa'],
    continent: 'Africa',
    slug: 'djibouti',
  },
  DM: { alpha2: 'DM', alpha3: 'DMA', ioc: 'DMA', language: ['en'], continent: 'North America', slug: 'dominica' },
  DO: { alpha2: 'DO', alpha3: 'DOM', ioc: 'DOM', language: ['es'], continent: 'North America', slug: 'dominican-rep' },
  EC: { alpha2: 'EC', alpha3: 'ECU', ioc: 'ECU', language: ['es'], continent: 'South America', slug: 'ecuador' },
  EG: { alpha2: 'EG', alpha3: 'EGY', ioc: 'EGY', language: ['ar'], continent: 'Africa', slug: 'egypt' },
  SV: { alpha2: 'SV', alpha3: 'SLV', ioc: 'ESA', language: ['es'], continent: 'North America', slug: 'el-salvador' },
  GQ: {
    alpha2: 'GQ',
    alpha3: 'GNQ',
    ioc: 'GEQ',
    language: ['es', 'fr', 'pt'],
    continent: 'Africa',
    slug: 'equatorial-guinea',
  },
  ER: { alpha2: 'ER', alpha3: 'ERI', ioc: 'ERI', language: ['ar', 'en'], continent: 'Africa', slug: 'eritrea' },
  EE: { alpha2: 'EE', alpha3: 'EST', ioc: 'EST', language: ['et'], continent: 'Europe', slug: 'estonia' },
  SZ: { alpha2: 'SZ', alpha3: 'SWZ', ioc: 'SWZ', language: ['en'], continent: 'Africa', slug: 'eswatini' },
  ET: { alpha2: 'ET', alpha3: 'ETH', ioc: 'ETH', language: ['am'], continent: 'Africa', slug: 'ethiopia' },
  FK: { alpha2: 'FK', alpha3: 'FLK', language: ['en'], slug: 'falkland-islands-malvinas' },
  FO: { alpha2: 'FO', alpha3: 'FRO', ioc: 'FAR', language: ['da', 'fo'], slug: 'faroe-islands' },
  FJ: { alpha2: 'FJ', alpha3: 'FJI', ioc: 'FIJ', language: ['en'], continent: 'Australia', slug: 'fiji' },
  FI: { alpha2: 'FI', alpha3: 'FIN', ioc: 'FIN', language: ['fi', 'sv'], continent: 'Europe', slug: 'finland' },
  FR: { alpha2: 'FR', alpha3: 'FRA', ioc: 'FRA', language: ['fr'], continent: 'Europe', slug: 'france' },
  GF: { alpha2: 'GF', alpha3: 'GUF', ioc: 'FGU', language: ['fr'], slug: 'french-guyana' },
  PF: { alpha2: 'PF', alpha3: 'PYF', ioc: 'FPO', language: ['fr'], slug: 'tahiti' },
  TF: { alpha2: 'TF', alpha3: 'ATF', language: ['fr'], slug: 'french-southern-territories' },
  GA: { alpha2: 'GA', alpha3: 'GAB', ioc: 'GAB', language: ['fr'], continent: 'Africa', slug: 'gabon' },
  GM: { alpha2: 'GM', alpha3: 'GMB', ioc: 'GAM', language: ['en'], continent: 'Africa', slug: 'gambia' },
  GE: { alpha2: 'GE', alpha3: 'GEO', ioc: 'GEO', language: ['ka'], continent: 'Asia', slug: 'georgia' },
  DE: { alpha2: 'DE', alpha3: 'DEU', ioc: 'GER', language: ['de'], continent: 'Europe', slug: 'germany' },
  GH: { alpha2: 'GH', alpha3: 'GHA', ioc: 'GHA', language: ['en'], continent: 'Africa', slug: 'ghana' },
  GI: { alpha2: 'GI', alpha3: 'GIB', language: ['en'], slug: 'gibraltar' },
  GR: { alpha2: 'GR', alpha3: 'GRC', ioc: 'GRE', language: ['el'], continent: 'Europe', slug: 'greece' },
  GL: { alpha2: 'GL', alpha3: 'GRL', language: ['kl', 'da'], slug: 'greenland' },
  GD: { alpha2: 'GD', alpha3: 'GRD', ioc: 'GRN', language: ['en'], continent: 'North America', slug: 'grenada' },
  GP: { alpha2: 'GP', alpha3: 'GLP', ioc: 'GLP', language: ['fr'], slug: 'guadeloupe' },
  GU: { alpha2: 'GU', alpha3: 'GUM', ioc: 'GUM', language: ['en', 'ch'], slug: 'guam' },
  GT: { alpha2: 'GT', alpha3: 'GTM', ioc: 'GUA', language: ['es'], continent: 'North America', slug: 'guatemala' },
  GG: { alpha2: 'GG', alpha3: 'GGY', ioc: 'GCI', language: ['en'], slug: 'guernsey' },
  GN: { alpha2: 'GN', alpha3: 'GIN', ioc: 'GUI', language: ['fr'], continent: 'Africa', slug: 'guinea' },
  GW: { alpha2: 'GW', alpha3: 'GNB', ioc: 'GBS', language: ['pt'], continent: 'Africa', slug: 'guinea-bissau' },
  GY: { alpha2: 'GY', alpha3: 'GUY', ioc: 'GUY', language: ['en'], continent: 'South America', slug: 'guyana' },
  HT: { alpha2: 'HT', alpha3: 'HTI', ioc: 'HAI', language: ['fr', 'ht'], continent: 'North America', slug: 'haiti' },
  HM: { alpha2: 'HM', alpha3: 'HMD', language: ['en'], slug: 'heard-island-and-mcdonald-islands' },
  VA: { alpha2: 'VA', alpha3: 'VAT', language: ['it'], continent: 'Europe', slug: 'holy-see-vatican-city-state' },
  HN: { alpha2: 'HN', alpha3: 'HND', ioc: 'HON', language: ['es'], continent: 'North America', slug: 'honduras' },
  HK: { alpha2: 'HK', alpha3: 'HKG', ioc: 'HKG', language: ['zh', 'en'], continent: 'Asia', slug: 'hong-kong' },
  HU: { alpha2: 'HU', alpha3: 'HUN', ioc: 'HUN', language: ['hu'], continent: 'Europe', slug: 'hungary' },
  IS: { alpha2: 'IS', alpha3: 'ISL', ioc: 'ISL', language: ['is'], continent: 'Europe', slug: 'iceland' },
  IN: { alpha2: 'IN', alpha3: 'IND', ioc: 'IND', language: ['en', 'hi'], continent: 'Asia', slug: 'india' },
  ID: { alpha2: 'ID', alpha3: 'IDN', ioc: 'INA', language: ['id'], continent: 'Asia', slug: 'indonesia' },
  IR: { alpha2: 'IR', alpha3: 'IRN', ioc: 'IRI', language: ['fa'], continent: 'Asia', slug: 'iran' },
  IQ: { alpha2: 'IQ', alpha3: 'IRQ', ioc: 'IRQ', language: ['ar', 'ku'], continent: 'Asia', slug: 'iraq' },
  IE: { alpha2: 'IE', alpha3: 'IRL', ioc: 'IRL', language: ['en'], continent: 'Europe', slug: 'ireland' },
  IM: { alpha2: 'IM', alpha3: 'IMN', language: ['en'], slug: 'isle-of-man' },
  IL: { alpha2: 'IL', alpha3: 'ISR', ioc: 'ISR', language: ['he'], continent: 'Asia', slug: 'israel' },
  IT: { alpha2: 'IT', alpha3: 'ITA', ioc: 'ITA', language: ['it'], continent: 'Europe', slug: 'italy' },
  JM: { alpha2: 'JM', alpha3: 'JAM', ioc: 'JAM', language: ['en'], continent: 'North America', slug: 'jamaica' },
  JP: { alpha2: 'JP', alpha3: 'JPN', ioc: 'JPN', language: ['ja'], continent: 'Asia', slug: 'japan' },
  JE: { alpha2: 'JE', alpha3: 'JEY', ioc: 'JCI', language: ['en'], slug: 'jersey' },
  JO: { alpha2: 'JO', alpha3: 'JOR', ioc: 'JOR', language: ['ar'], continent: 'Asia', slug: 'jordan' },
  KZ: { alpha2: 'KZ', alpha3: 'KAZ', ioc: 'KAZ', language: ['kk', 'ru'], continent: 'Asia', slug: 'kazakhstan' },
  KE: { alpha2: 'KE', alpha3: 'KEN', ioc: 'KEN', language: ['en', 'sw'], continent: 'Africa', slug: 'kenya' },
  KI: { alpha2: 'KI', alpha3: 'KIR', ioc: 'KIR', language: ['en'], continent: 'Australia', slug: 'kiribati' },
  KP: { alpha2: 'KP', alpha3: 'PRK', ioc: 'PRK', language: ['ko'], continent: 'Asia', slug: 'north-korea' },
  KR: { alpha2: 'KR', alpha3: 'KOR', ioc: 'KOR', language: ['ko', 'en'], continent: 'Asia', slug: 'south-korea' },
  XK: { alpha2: 'XK', alpha3: 'XKX', ioc: 'KOS', language: ['sr', 'sq'], continent: 'Europe', slug: 'kosovo' },
  KW: { alpha2: 'KW', alpha3: 'KWT', ioc: 'KUW', language: ['ar'], continent: 'Asia', slug: 'kuwait' },
  KG: { alpha2: 'KG', alpha3: 'KGZ', ioc: 'KGZ', language: ['ky', 'ru'], continent: 'Asia', slug: 'kyrgyzstan' },
  LA: { alpha2: 'LA', alpha3: 'LAO', ioc: 'LAO', language: ['lo'], continent: 'Asia', slug: 'laos' },
  LV: { alpha2: 'LV', alpha3: 'LVA', ioc: 'LAT', language: ['lv'], continent: 'Europe', slug: 'latvia' },
  LB: { alpha2: 'LB', alpha3: 'LBN', ioc: 'LIB', language: ['ar', 'fr'], continent: 'Asia', slug: 'lebanon' },
  LS: { alpha2: 'LS', alpha3: 'LSO', ioc: 'LES', language: ['en', 'st'], continent: 'Africa', slug: 'lesotho' },
  LR: { alpha2: 'LR', alpha3: 'LBR', ioc: 'LBR', language: ['en'], continent: 'Africa', slug: 'liberia' },
  LY: { alpha2: 'LY', alpha3: 'LBY', ioc: 'LBA', language: ['ar'], continent: 'Africa', slug: 'libya' },
  LI: { alpha2: 'LI', alpha3: 'LIE', ioc: 'LIE', language: ['de'], continent: 'Europe', slug: 'liechtenstein' },
  LT: { alpha2: 'LT', alpha3: 'LTU', ioc: 'LTU', language: ['lt'], continent: 'Europe', slug: 'lithuania' },
  LU: {
    alpha2: 'LU',
    alpha3: 'LUX',
    ioc: 'LUX',
    language: ['lb', 'fr', 'de'],
    continent: 'Europe',
    slug: 'luxembourg',
  },
  MO: { alpha2: 'MO', alpha3: 'MAC', ioc: 'MAC', language: ['zh', 'pt'], continent: 'Asia', slug: 'macao' },
  MK: { alpha2: 'MK', alpha3: 'MKD', ioc: 'MKD', language: ['mk'], continent: 'Europe', slug: 'north-macedonia' },
  MG: { alpha2: 'MG', alpha3: 'MDG', ioc: 'MAD', language: ['mg', 'fr'], continent: 'Africa', slug: 'madagascar' },
  MW: { alpha2: 'MW', alpha3: 'MWI', ioc: 'MAW', language: ['en'], continent: 'Africa', slug: 'malawi' },
  MY: { alpha2: 'MY', alpha3: 'MYS', ioc: 'MAS', language: ['ms'], continent: 'Asia', slug: 'malaysia' },
  MV: { alpha2: 'MV', alpha3: 'MDV', ioc: 'MDV', language: ['dv'], continent: 'Asia', slug: 'maldives' },
  ML: { alpha2: 'ML', alpha3: 'MLI', ioc: 'MLI', language: ['fr'], continent: 'Africa', slug: 'mali' },
  MT: { alpha2: 'MT', alpha3: 'MLT', ioc: 'MLT', language: ['en', 'mt'], continent: 'Europe', slug: 'malta' },
  MH: { alpha2: 'MH', alpha3: 'MHL', ioc: 'MSH', language: ['en'], slug: 'marshall-islands' },
  MQ: { alpha2: 'MQ', alpha3: 'MTQ', language: ['fr'], continent: 'Africa', slug: 'martinique' },
  MR: { alpha2: 'MR', alpha3: 'MRT', ioc: 'MTN', language: ['ar', 'fr'], continent: 'Africa', slug: 'mauritania' },
  MU: { alpha2: 'MU', alpha3: 'MUS', ioc: 'MRI', language: ['en', 'fr'], continent: 'Africa', slug: 'mauritius' },
  YT: { alpha2: 'YT', alpha3: 'MYT', ioc: 'MAY', language: ['fr'], slug: 'mayotte' },
  MX: { alpha2: 'MX', alpha3: 'MEX', ioc: 'MEX', language: ['es'], continent: 'North America', slug: 'mexico' },
  FM: { alpha2: 'FM', alpha3: 'FSM', language: ['en'], continent: 'Australia', slug: 'micronesia-federated-states-of' },
  MD: { alpha2: 'MD', alpha3: 'MDA', ioc: 'MDA', language: ['ro', 'ru', 'uk'], continent: 'Europe', slug: 'moldova' },
  MC: { alpha2: 'MC', alpha3: 'MCO', ioc: 'MON', language: ['fr'], continent: 'Europe', slug: 'monaco' },
  MN: { alpha2: 'MN', alpha3: 'MNG', ioc: 'MGL', language: ['mn'], continent: 'Asia', slug: 'mongolia' },
  ME: {
    alpha2: 'ME',
    alpha3: 'MNE',
    ioc: 'MNE',
    language: ['sr', 'hr', 'bs', 'sq'],
    continent: 'Europe',
    slug: 'montenegro',
  },
  MS: { alpha2: 'MS', alpha3: 'MSR', ioc: 'MNT', language: ['en'], slug: 'montserrat' },
  MA: { alpha2: 'MA', alpha3: 'MAR', ioc: 'MAR', language: ['fr', 'ar'], continent: 'Africa', slug: 'morocco' },
  MZ: { alpha2: 'MZ', alpha3: 'MOZ', ioc: 'MOZ', language: ['pt'], continent: 'Africa', slug: 'mozambique' },
  MM: { alpha2: 'MM', alpha3: 'MMR', ioc: 'MYA', language: ['my'], continent: 'Asia', slug: 'burma' },
  NA: { alpha2: 'NA', alpha3: 'NAM', ioc: 'NAM', language: ['en', 'de'], continent: 'Africa', slug: 'namibia' },
  NR: { alpha2: 'NR', alpha3: 'NRU', ioc: 'NRU', language: ['en', 'na'], slug: 'nauru' },
  NP: { alpha2: 'NP', alpha3: 'NPL', ioc: 'NEP', language: ['ne'], continent: 'Asia', slug: 'nepal' },
  NL: { alpha2: 'NL', alpha3: 'NLD', ioc: 'NED', language: ['nl'], continent: 'Europe', slug: 'netherlands' },
  AN: { alpha2: 'AN', alpha3: 'ANT', ioc: 'AHO', language: ['nl'], slug: 'netherlands-antilles' },
  NC: { alpha2: 'NC', alpha3: 'NCL', ioc: 'NCD', language: ['fr'], slug: 'new-caledonia' },
  NZ: { alpha2: 'NZ', alpha3: 'NZL', ioc: 'NZL', language: ['en', 'mi'], continent: 'Australia', slug: 'new-zealand' },
  NI: { alpha2: 'NI', alpha3: 'NIC', ioc: 'NCA', language: ['es'], continent: 'North America', slug: 'nicaragua' },
  NE: { alpha2: 'NE', alpha3: 'NER', ioc: 'NIG', language: ['fr'], continent: 'Africa', slug: 'niger' },
  NG: { alpha2: 'NG', alpha3: 'NGA', ioc: 'NGR', language: ['en'], continent: 'Africa', slug: 'nigeria' },
  NU: { alpha2: 'NU', alpha3: 'NIU', language: ['en'], slug: 'niue' },
  NF: { alpha2: 'NF', alpha3: 'NFK', ioc: 'NFI', language: ['en'], slug: 'norfolk-island' },
  MP: { alpha2: 'MP', alpha3: 'MNP', ioc: 'NMA', language: ['en', 'ch'], slug: 'northern-mariana-islands' },
  NO: { alpha2: 'NO', alpha3: 'NOR', ioc: 'NOR', language: ['no'], continent: 'Europe', slug: 'norway' },
  OM: { alpha2: 'OM', alpha3: 'OMN', ioc: 'OMA', language: ['ar'], continent: 'Asia', slug: 'oman' },
  PK: { alpha2: 'PK', alpha3: 'PAK', ioc: 'PAK', language: ['en', 'ur'], continent: 'Asia', slug: 'pakistan' },
  PW: { alpha2: 'PW', alpha3: 'PLW', ioc: 'PLW', language: ['en'], slug: 'palau' },
  PS: { alpha2: 'PS', alpha3: 'PSE', ioc: 'PLE', language: ['ar', 'he'], continent: 'Asia', slug: 'palestine' },
  PA: { alpha2: 'PA', alpha3: 'PAN', ioc: 'PAN', language: ['es'], continent: 'North America', slug: 'panama' },
  PG: {
    alpha2: 'PG',
    alpha3: 'PNG',
    ioc: 'PNG',
    language: ['en', 'ho'],
    continent: 'Australia',
    slug: 'papua-new-guinea',
  },
  PY: { alpha2: 'PY', alpha3: 'PRY', ioc: 'PAR', language: ['es'], continent: 'South America', slug: 'paraguay' },
  PE: { alpha2: 'PE', alpha3: 'PER', ioc: 'PER', language: ['es'], continent: 'South America', slug: 'peru' },
  PH: { alpha2: 'PH', alpha3: 'PHL', ioc: 'PHI', language: ['en', 'tl'], continent: 'Asia', slug: 'philippines' },
  PN: { alpha2: 'PN', alpha3: 'PCN', language: ['en'], slug: 'pitcairn' },
  PL: { alpha2: 'PL', alpha3: 'POL', ioc: 'POL', language: ['pl'], continent: 'Europe', slug: 'poland' },
  PT: { alpha2: 'PT', alpha3: 'PRT', ioc: 'POR', language: ['pt'], continent: 'Europe', slug: 'portugal' },
  PR: {
    alpha2: 'PR',
    alpha3: 'PRI',
    ioc: 'PUR',
    language: ['en', 'es'],
    continent: 'North America',
    slug: 'puerto-rico',
  },
  QA: { alpha2: 'QA', alpha3: 'QAT', ioc: 'QAT', language: ['ar'], continent: 'Asia', slug: 'qatar' },
  RE: { alpha2: 'RE', alpha3: 'REU', language: ['fr'], slug: 'reunion' },
  RO: { alpha2: 'RO', alpha3: 'ROU', ioc: 'ROU', language: ['ro'], continent: 'Europe', slug: 'romania' },
  RU: { alpha2: 'RU', alpha3: 'RUS', ioc: 'RUS', language: ['ru'], continent: 'Europe', slug: 'russia' },
  RW: { alpha2: 'RW', alpha3: 'RWA', ioc: 'RWA', language: ['rw', 'fr', 'en'], continent: 'Africa', slug: 'rwanda' },
  BL: { alpha2: 'BL', alpha3: 'BLM', language: ['fr'], slug: 'saint-barthelemy' },
  SH: {
    alpha2: 'SH',
    alpha3: 'SHN',
    ioc: 'HEL',
    language: ['en'],
    slug: 'saint-helena-ascension-and-tristan-da-cunha',
  },
  KN: { alpha2: 'KN', alpha3: 'KNA', ioc: 'SKN', language: ['en'], slug: 'st-kitts-and-nevis' },
  LC: { alpha2: 'LC', alpha3: 'LCA', ioc: 'LCA', language: ['en'], slug: 'saint-lucia' },
  MF: { alpha2: 'MF', alpha3: 'MAF', language: ['fr'], slug: 'saint-martin-french-part' },
  PM: { alpha2: 'PM', alpha3: 'SPM', language: ['fr'], slug: 'saint-pierre-and-miquelon' },
  VC: { alpha2: 'VC', alpha3: 'VCT', ioc: 'VIN', language: ['en'], slug: 'saint-vincent-and-the-grenadines' },
  WS: { alpha2: 'WS', alpha3: 'WSM', ioc: 'SAM', language: ['en', 'sm'], continent: 'Australia', slug: 'samoa' },
  SM: { alpha2: 'SM', alpha3: 'SMR', ioc: 'SMR', language: ['it'], continent: 'Europe', slug: 'san-marino' },
  ST: { alpha2: 'ST', alpha3: 'STP', ioc: 'STP', language: ['pt'], slug: 'sao-tome-and-principe' },
  SA: { alpha2: 'SA', alpha3: 'SAU', ioc: 'KSA', language: ['ar'], continent: 'Asia', slug: 'saudi-arabia' },
  SN: { alpha2: 'SN', alpha3: 'SEN', ioc: 'SEN', language: ['fr'], continent: 'Africa', slug: 'senegal' },
  RS: { alpha2: 'RS', alpha3: 'SRB', ioc: 'SRB', language: ['sr'], continent: 'Europe', slug: 'serbia' },
  SC: { alpha2: 'SC', alpha3: 'SYC', ioc: 'SEY', language: ['fr', 'en'], continent: 'Asia', slug: 'seychelles' },
  SL: { alpha2: 'SL', alpha3: 'SLE', ioc: 'SLE', language: ['en'], continent: 'Africa', slug: 'sierra-leone' },
  SG: {
    alpha2: 'SG',
    alpha3: 'SGP',
    ioc: 'SIN',
    language: ['en', 'zh', 'ms', 'ta'],
    continent: 'Asia',
    slug: 'singapore',
  },
  SK: { alpha2: 'SK', alpha3: 'SVK', ioc: 'SVK', language: ['sk'], continent: 'Europe', slug: 'slovakia' },
  SI: { alpha2: 'SI', alpha3: 'SVN', ioc: 'SLO', language: ['sl'], continent: 'Europe', slug: 'slovenia' },
  SB: { alpha2: 'SB', alpha3: 'SLB', ioc: 'SOL', language: ['en'], slug: 'solomon-islands' },
  SO: { alpha2: 'SO', alpha3: 'SOM', ioc: 'SOM', language: ['so', 'ar'], continent: 'Africa', slug: 'somalia' },
  ZA: {
    alpha2: 'ZA',
    alpha3: 'ZAF',
    ioc: 'RSA',
    language: ['en', 'af', 'st', 'tn', 'xh', 'zu'],
    continent: 'Africa',
    slug: 'south-africa',
  },
  GS: { alpha2: 'GS', alpha3: 'SGS', language: ['en'], slug: 'south-georgia-and-the-south-sandwich-islands' },
  SS: { alpha2: 'SS', alpha3: 'SSD', language: ['en'], continent: 'Africa', slug: 'south-sudan' },
  ES: { alpha2: 'ES', alpha3: 'ESP', ioc: 'ESP', language: ['es'], continent: 'Europe', slug: 'spain' },
  LK: { alpha2: 'LK', alpha3: 'LKA', ioc: 'SRI', language: ['ta'], continent: 'Asia', slug: 'sri-lanka' },
  SD: { alpha2: 'SD', alpha3: 'SDN', ioc: 'SUD', language: ['ar', 'en'], continent: 'Africa', slug: 'sudan' },
  SR: { alpha2: 'SR', alpha3: 'SUR', ioc: 'SUR', language: ['nl'], continent: 'South America', slug: 'surinam' },
  SJ: { alpha2: 'SJ', alpha3: 'SJM', language: ['no'], slug: 'svalbard-and-jan-mayen' },
  SE: { alpha2: 'SE', alpha3: 'SWE', ioc: 'SWE', language: ['sv'], continent: 'Europe', slug: 'sweden' },
  SW: { alpha2: 'SW', alpha3: 'SXM', language: ['en', 'nl'], slug: 'sint-maartin-dutch-part' },
  CH: {
    alpha2: 'CH',
    alpha3: 'CHE',
    ioc: 'SUI',
    language: ['de', 'fr', 'it', 'rm'],
    continent: 'Europe',
    slug: 'switzerland',
  },
  SY: { alpha2: 'SY', alpha3: 'SYR', ioc: 'SYR', language: ['ar'], continent: 'Asia', slug: 'syria' },
  TW: { alpha2: 'TW', alpha3: 'TWN', ioc: 'TPE', language: ['zh'], slug: 'taiwan' },
  TJ: { alpha2: 'TJ', alpha3: 'TJK', ioc: 'TJK', language: ['tg', 'ru'], continent: 'Asia', slug: 'tajikistan' },
  TZ: { alpha2: 'TZ', alpha3: 'TZA', ioc: 'TAN', language: ['sw', 'en'], continent: 'Africa', slug: 'tanzania' },
  TH: { alpha2: 'TH', alpha3: 'THA', ioc: 'THA', language: ['th'], continent: 'Asia', slug: 'thailand' },
  TL: { alpha2: 'TL', alpha3: 'TLS', ioc: 'TLS', language: ['pt'], continent: 'Asia', slug: 'timor-leste' },
  TG: { alpha2: 'TG', alpha3: 'TGO', ioc: 'TOG', language: ['fr'], continent: 'Africa', slug: 'togo' },
  TK: { alpha2: 'TK', alpha3: 'TKL', language: ['en', 'sm'], slug: 'tokelau' },
  TO: { alpha2: 'TO', alpha3: 'TON', ioc: 'TGA', language: ['en'], continent: 'Australia', slug: 'tonga' },
  TT: {
    alpha2: 'TT',
    alpha3: 'TTO',
    ioc: 'TRI',
    language: ['en'],
    continent: 'North America',
    slug: 'trinidad-and-tobago',
  },
  TN: { alpha2: 'TN', alpha3: 'TUN', ioc: 'TUN', language: ['ar', 'fr'], continent: 'Africa', slug: 'tunisia' },
  TR: { alpha2: 'TR', alpha3: 'TUR', ioc: 'TUR', language: ['tr'], continent: 'Asia', slug: 'turkey' },
  TM: { alpha2: 'TM', alpha3: 'TKM', ioc: 'TKM', language: ['tk'], continent: 'Asia', slug: 'turkmenistan' },
  TC: { alpha2: 'TC', alpha3: 'TCA', ioc: 'TKS', language: ['en'], slug: 'turks-and-caicos-islands' },
  TV: { alpha2: 'TV', alpha3: 'TUV', ioc: 'TUV', language: ['en'], slug: 'tuvalu' },
  UG: { alpha2: 'UG', alpha3: 'UGA', ioc: 'UGA', language: ['en', 'sw'], continent: 'Africa', slug: 'uganda' },
  UA: { alpha2: 'UA', alpha3: 'UKR', ioc: 'UKR', language: ['uk'], continent: 'Europe', slug: 'ukraine' },
  AE: { alpha2: 'AE', alpha3: 'ARE', ioc: 'UAE', language: ['ar'], continent: 'Asia', slug: 'united-arab-emirates' },
  GB: { alpha2: 'GB', alpha3: 'GBR', ioc: 'GBR', language: ['en'], continent: 'Europe', slug: 'great-britain' },
  US: { alpha2: 'US', alpha3: 'USA', ioc: 'USA', language: ['en'], continent: 'North America', slug: 'usa' },
  UM: { alpha2: 'UM', alpha3: 'UMI', language: ['en'], slug: 'usa-minor-outlying-islands' },
  UY: { alpha2: 'UY', alpha3: 'URY', ioc: 'URU', language: ['es'], continent: 'South America', slug: 'uruguay' },
  UZ: { alpha2: 'UZ', alpha3: 'UZB', ioc: 'UZB', language: ['uz'], continent: 'Asia', slug: 'uzbekistan' },
  VU: { alpha2: 'VU', alpha3: 'VUT', ioc: 'VAN', language: ['en', 'fr', 'bi'], slug: 'vanuatu' },
  VE: { alpha2: 'VE', alpha3: 'VEN', ioc: 'VEN', language: ['es'], continent: 'South America', slug: 'venezuela' },
  VN: { alpha2: 'VN', alpha3: 'VNM', ioc: 'VIE', language: ['vi'], continent: 'Asia', slug: 'vietnam' },
  VG: { alpha2: 'VG', alpha3: 'VGB', ioc: 'IVB', language: ['en'], slug: 'british-virgin-islands' },
  VI: { alpha2: 'VI', alpha3: 'VIR', ioc: 'ISV', language: ['en'], slug: 'virgin-islands-us' },
  WF: { alpha2: 'WF', alpha3: 'WLF', ioc: 'WAF', language: ['fr'], slug: 'wallis-and-futuna' },
  EH: { alpha2: 'EH', alpha3: 'ESH', language: ['ar', 'es', 'fr'], continent: 'Africa', slug: 'western-sahara' },
  YE: { alpha2: 'YE', alpha3: 'YEM', ioc: 'YEM', language: ['ar'], continent: 'Asia', slug: 'yemen' },
  ZM: { alpha2: 'ZM', alpha3: 'ZMB', ioc: 'ZAM', language: ['en'], continent: 'Africa', slug: 'zambia' },
  ZW: { alpha2: 'ZW', alpha3: 'ZWE', ioc: 'ZIM', language: ['en'], continent: 'Africa', slug: 'zimbabwe' },
  EN: { alpha2: 'EN', alpha3: 'ENG', language: ['en'], slug: 'england' },
  SX: { alpha2: 'SX', alpha3: 'SCO', language: ['en'], slug: 'scotland' },
  WA: { alpha2: 'WA', alpha3: 'WAL', language: ['en'], slug: 'wales' },
  NX: { alpha2: 'NX', alpha3: 'NIR', language: ['en'], slug: 'northern-ireland' },
}
