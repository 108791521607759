/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, BoxProps, Button, Flex, Text, ZIndex } from '@sofascore/ui'
import { useCallback, useEffect, useRef, useState } from 'react'
import IconArrowDropdown from '@sofascore/ui/dist/modules/Icons/IconArrowDropdown'
import IconClose from '@sofascore/ui/dist/modules/Icons/_IconClose'

import { secondaryColor } from 'styles/color'

import { MENU_PADDING, ROW_SIZE } from './config'
import { SelectProps } from './interface'
import * as S from './styles'

/**
 * Simple select component
 */
const Select = <T extends object & { label?: string }>({
  label,
  options,
  value,
  required,
  helperText,
  noOptionsText = 'No Options',
  maxVisibleOptions = 10,
  rowHeight = ROW_SIZE,
  disabled,
  onChange,
  onBlur,
  getOptionLabel = option => option?.label || '',
  getOptionKey = getOptionLabel,
  renderOption,
  renderSelectedOption,
  disableClear,
  selectBackgroundColor,
  ...boxProps
}: SelectProps<T> & BoxProps) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const optionRefs = useRef<(HTMLLIElement | null)[]>(Array.from({ length: options.length }))

  const [selected, _setSelected] = useState(value)
  const [isOpen, setIsOpen] = useState(false)

  const maxMenuHeight = maxVisibleOptions * rowHeight + MENU_PADDING

  const setSelected: typeof _setSelected = useCallback(
    s => {
      _setSelected(s)
      if (typeof s !== 'function' && s !== selected) {
        onChange(s!)
      }
    },
    [onChange, _setSelected, selected],
  )

  // Outside click
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (e.target && e.target instanceof Node && !wrapperRef.current?.contains(e.target)) {
        setIsOpen(false)
        if (selected) onBlur?.(selected)
      }
    }

    document.addEventListener('click', handleClick, { capture: true })

    return () => document.removeEventListener('click', handleClick)
  }, [selected, onBlur])

  // Reset initial value from outside
  useEffect(() => {
    _setSelected(value)
  }, [value])

  return (
    <S.Select
      position="relative"
      onClick={() => {
        if (!disabled) setIsOpen(!isOpen)
      }}
      ref={wrapperRef}
      {...boxProps}
    >
      <S.SelectFieldWrapper isDisabled={disabled} bg={selectBackgroundColor}>
        <Flex align="center" minW={0} grow={1} pt={18} wrap="wrap" mr="md">
          <Box grow={1} minW={0} p={0} overflow="hidden" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            {renderSelectedOption ? renderSelectedOption(selected) : selected?.label}
          </Box>
        </Flex>

        {!required && value && !disableClear && (
          <S.MotionButton
            variant="unshielded"
            p={0}
            mr="sm"
            border="none"
            tabIndex={-1}
            initial={{ transform: 'scale(0.5)' }}
            animate={{ transform: 'scale(1)' }}
            transition={{ duration: 0.1 }}
          >
            <IconClose
              fill={secondaryColor}
              onClick={() => {
                setSelected(undefined)
                setIsOpen(false)
              }}
              cursor="pointer"
            />
          </S.MotionButton>
        )}

        {!disabled && (
          <Button variant="unshielded" p={0} border="none" tabIndex={-1}>
            <IconArrowDropdown
              style={{ transform: isOpen ? 'rotate(180deg)' : undefined }}
              fill={secondaryColor}
              onClick={e => {
                e.stopPropagation()
                setIsOpen(!isOpen)
              }}
            />
          </Button>
        )}

        <S.Label hasValue={!!selected}>
          {label}
          {required && ' *'}
        </S.Label>
      </S.SelectFieldWrapper>

      {helperText && (
        <Text block font="assistive.default" color="onSurface.nLv3" mb="md" ml="lg">
          {helperText}
        </Text>
      )}

      {isOpen && (
        <Box
          as="ul"
          position="absolute"
          br="md"
          bg="surface.s1"
          elevation={3}
          m="xs 0 0"
          p="md 0"
          left={0}
          right={0}
          top={helperText && 50}
          maxH={maxMenuHeight}
          overflowY="auto"
          zIndex={ZIndex.Dropdown}
          style={{ boxSizing: 'border-box' }}
        >
          {!options.length && (
            <Text as="li" style={{ listStyle: 'none' }} font="assistive.default" c="onSurface.nLv3" px="lg" py="sm">
              {noOptionsText}
            </Text>
          )}

          {options.map((option, index) => {
            const optionLabel = getOptionLabel(option)
            const optionKey = getOptionKey(option)
            const isCustomRendering = typeof renderOption !== 'undefined'

            return (
              <Box
                as="li"
                key={optionKey}
                ref={(node: HTMLLIElement | null) => {
                  optionRefs.current[index] = node
                }}
                style={{ listStyle: 'none' }}
                hoverBg="surface.s2"
                onClick={() => {
                  setSelected(option)

                  if (!isOpen) {
                    setIsOpen(true)
                  }
                }}
              >
                <Box cursor="pointer">
                  {isCustomRendering ? (
                    renderOption(option)
                  ) : (
                    <Text as="div" font="body.large" px="lg" py="sm">
                      {optionLabel}
                    </Text>
                  )}
                </Box>
              </Box>
            )
          })}
        </Box>
      )}
    </S.Select>
  )
}

export default Select
