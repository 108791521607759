import { useState, useMemo } from 'react'
import { Box, Flex, Input, TextArea } from '@sofascore/ui'
import { FormattedMessage, useIntl } from 'react-intl'
import { SportName, orderedSports } from 'entities/Sport'

import { Button } from 'components/Button'
import { Select } from 'components/Select'
import { Option } from 'components/DropdownMenu/interface'
import { AddNewCompetitionData } from 'modules/MyCompetitions/interface'

export interface Props {
  onClose: () => void
  onSubmit: (arg0: AddNewCompetitionData) => Promise<boolean>
  initialValues?: {
    email: string
  }
}

const AddNewCompetitionForm = ({ onClose, onSubmit, initialValues }: Props) => {
  const intl = useIntl()
  const [isSaving, setIsSaving] = useState(false)
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>(initialValues?.email || '')
  const [sport, setSport] = useState<Option | undefined>()
  const [competitionName, setCompetitionName] = useState<string>('')
  const [message, setMessage] = useState<string>('')

  const isValid = useMemo(() => {
    if (name?.length && email?.length && sport && competitionName?.length && message?.length) {
      return true
    } else {
      return false
    }
  }, [name, email, sport, competitionName, message])

  const handleConfirm = async () => {
    setIsSaving(true)

    onSubmit({
      name,
      email,
      sport: sport?.value as string,
      league: competitionName,
      message,
    })
      .then(() => {
        onClose()
      })
      .catch(() => {})
      .finally(() => setIsSaving(false))
  }

  const sportOptions = useMemo(() => {
    return orderedSports
      .map(sport => {
        return {
          label: intl.formatMessage({ id: sport }),
          value: sport,
        }
      })
      .filter(option => option.value !== SportName.Tennis)
  }, [intl])

  return (
    <Box px="lg" pb="xxl" br="sm">
      <Input
        label={intl.formatMessage({ id: 'fullname' })}
        onChange={newValue => {
          setName(newValue)
        }}
        value={name}
        required
        mb="lg"
      />

      <Input
        label={intl.formatMessage({ id: 'email' })}
        onChange={newValue => {
          setEmail(newValue)
        }}
        value={email}
        required
        disabled={email?.length ? true : false}
        mb="lg"
      />

      <Select
        label={intl.formatMessage({ id: 'sport' })}
        options={sportOptions}
        value={sport}
        onChange={option => setSport(option)}
        maxVisibleOptions={6}
        w="100%"
        selectBackgroundColor="surface.s2"
        required
        mb="lg"
      />

      <Input
        label={intl.formatMessage({ id: 'leagueName' })}
        onChange={newValue => {
          setCompetitionName(newValue)
        }}
        value={competitionName}
        required
        mb="lg"
      />

      <TextArea
        label={intl.formatMessage({ id: 'message' })}
        onChange={newValue => {
          setMessage(newValue)
        }}
        value={message}
        required
        placeholder={intl.formatMessage({ id: 'manageLeagueReason' })}
        rows={5}
      />

      <Flex gapX="lg" px="lg" mt="xxl">
        <Button variant="outlined" fullWidth onClick={onClose}>
          <FormattedMessage id="cancel" />
        </Button>
        <Button variant="contained" fullWidth onClick={handleConfirm} isLoading={isSaving} disabled={!isValid}>
          <FormattedMessage id="send" />
        </Button>
      </Flex>
    </Box>
  )
}

export default AddNewCompetitionForm
