import { Box, Flex, RadioButton, Text } from '@sofascore/ui'
import { FormattedMessage, useIntl } from 'react-intl'
import { SportName } from 'entities/Sport'

import { useMyCompetitionsState } from 'modules/MyCompetitions/providers/MyCompetitionsProvider'
import { Select } from 'components/Select'
import { Option } from 'components/DropdownMenu/interface'

import {
  SET_AWAY_SUB_TEAM1,
  SET_AWAY_SUB_TEAM2,
  SET_AWAY_TEAM,
  SET_HOME_SUB_TEAM1,
  SET_HOME_SUB_TEAM2,
  SET_HOME_TEAM,
  SET_MATCH_TYPE,
  useCreateMatchDispatch,
  useCreateMatchState,
} from '../context/createMatchContext'
import { TENNIS_DOUBLE, TENNIS_SINGLE } from '../../const'

const Teams = ({ teams }: { teams: Option[] }) => {
  const { uniqueTournament } = useMyCompetitionsState()
  const { matchType, homeTeam, awayTeam, homeSubTeam1, homeSubTeam2, awaySubTeam1, awaySubTeam2 } =
    useCreateMatchState()

  const dispatch = useCreateMatchDispatch()

  const intl = useIntl()

  const isTennis = uniqueTournament?.category.sport.slug === SportName.Tennis
  const isSingles = matchType === TENNIS_SINGLE

  if (isTennis) {
    return (
      <>
        <Flex gapX="xxl">
          <RadioButton
            id="tennisSingle"
            name="tennisSingle"
            label={intl.formatMessage({ id: 'tennis_singles' })}
            value={TENNIS_SINGLE}
            checked={isSingles}
            onChange={e => dispatch({ type: SET_MATCH_TYPE, payload: e.target.value })}
            gap="sm"
            mt="sm"
          />
          <RadioButton
            id="tennisDouble"
            name="tennisDouble"
            label={intl.formatMessage({ id: 'tennis_doubles' })}
            value={TENNIS_DOUBLE}
            checked={matchType === TENNIS_DOUBLE}
            onChange={e => dispatch({ type: SET_MATCH_TYPE, payload: e.target.value })}
            gap="sm"
            mt="sm"
          />
        </Flex>

        {isSingles ? (
          <>
            <Select
              label={intl.formatMessage({ id: 'tennis_player' }, { value: '1' })}
              options={teams.filter(t => ![awayTeam?.value].includes(t.value))}
              value={homeTeam}
              onChange={t => dispatch({ type: SET_HOME_TEAM, payload: t })}
              maxVisibleOptions={6}
              w="100%"
              selectBackgroundColor="surface.s2"
            />

            <Select
              label={intl.formatMessage({ id: 'tennis_player' }, { value: '2' })}
              options={teams.filter(t => ![homeTeam?.value].includes(t.value))}
              value={awayTeam}
              onChange={t => dispatch({ type: SET_AWAY_TEAM, payload: t })}
              maxVisibleOptions={6}
              w="100%"
              selectBackgroundColor="surface.s2"
            />
          </>
        ) : (
          <>
            <Box>
              <Text font="body.medium">
                <FormattedMessage id="tennis_team" values={{ value: 'A' }} />
              </Text>
            </Box>

            <Select
              label={intl.formatMessage({ id: 'tennis_player' }, { value: '1' })}
              options={teams.filter(
                t => ![homeSubTeam2?.value, awaySubTeam1?.value, awaySubTeam2?.value].includes(t.value),
              )}
              value={homeSubTeam1}
              onChange={hst1 => dispatch({ type: SET_HOME_SUB_TEAM1, payload: hst1 })}
              maxVisibleOptions={6}
              w="100%"
              selectBackgroundColor="surface.s2"
            />

            <Select
              label={intl.formatMessage({ id: 'tennis_player' }, { value: '2' })}
              options={teams.filter(
                t => ![homeSubTeam1?.value, awaySubTeam1?.value, awaySubTeam2?.value].includes(t.value),
              )}
              value={homeSubTeam2}
              onChange={hst2 => dispatch({ type: SET_HOME_SUB_TEAM2, payload: hst2 })}
              maxVisibleOptions={6}
              w="100%"
              selectBackgroundColor="surface.s2"
            />

            <Box>
              <Text font="body.medium">
                <FormattedMessage id="tennis_team" values={{ value: 'B' }} />
              </Text>
            </Box>

            <Select
              label={intl.formatMessage({ id: 'tennis_player' }, { value: '1' })}
              options={teams.filter(
                t => ![homeSubTeam1?.value, homeSubTeam2?.value, awaySubTeam2?.value].includes(t.value),
              )}
              value={awaySubTeam1}
              onChange={ast1 => dispatch({ type: SET_AWAY_SUB_TEAM1, payload: ast1 })}
              maxVisibleOptions={6}
              w="100%"
              selectBackgroundColor="surface.s2"
            />

            <Select
              label={intl.formatMessage({ id: 'tennis_player' }, { value: '2' })}
              options={teams.filter(
                t => ![homeSubTeam1?.value, homeSubTeam2?.value, awaySubTeam1?.value].includes(t.value),
              )}
              onChange={ast2 => dispatch({ type: SET_AWAY_SUB_TEAM2, payload: ast2 })}
              value={awaySubTeam2}
              maxVisibleOptions={6}
              w="100%"
              selectBackgroundColor="surface.s2"
            />
          </>
        )}
      </>
    )
  }

  return (
    <>
      <Select
        label={intl.formatMessage({ id: 'homeTeam' })}
        options={teams.filter(t => ![awayTeam?.value].includes(t.value))}
        value={homeTeam}
        onChange={t => dispatch({ type: SET_HOME_TEAM, payload: t })}
        maxVisibleOptions={6}
        w="100%"
        selectBackgroundColor="surface.s2"
      />

      <Select
        label={intl.formatMessage({ id: 'awayTeam' })}
        options={teams.filter(t => ![homeTeam?.value].includes(t.value))}
        value={awayTeam}
        onChange={t => dispatch({ type: SET_AWAY_TEAM, payload: t })}
        maxVisibleOptions={6}
        w="100%"
        selectBackgroundColor="surface.s2"
      />
    </>
  )
}

export default Teams
