import IconAdd from '@sofascore/ui/dist/modules/Icons/IconAdd'

import * as S from './styles'

interface IncrementAccumulatedFoulsProps {
  onClick: () => void
  disabled?: boolean
}

export const IncrementAccumulatedFouls = ({ onClick, disabled }: IncrementAccumulatedFoulsProps) => {
  return (
    <S.ChangeFoulsButton onClick={onClick} disabled={disabled}>
      <IconAdd fill="#000000" />
    </S.ChangeFoulsButton>
  )
}
