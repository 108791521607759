import { AccumulatedFoul } from 'entities/AccumulatedFoul'
import { StatusCode } from 'entities/Status'

import { EXTRA_TIME_1, EXTRA_TIME_2, PERIOD1, PERIOD2 } from 'modules/Matches/const'

/**
 * Extra time fouls are sum of all fouls from both extra time halves and second half of regular time.
 * @param foulsByPeriods
 * @returns
 */
export const getAccumulatedFoulsDisplay = (foulsByPeriods: AccumulatedFoul[], statusCode: number) => {
  if (statusCode === StatusCode['1st half']) {
    return foulsByPeriods.find(periodData => periodData.period === PERIOD1)?.accumulatedFouls || 0
  } else {
    const secondHalfFouls = foulsByPeriods.find(periodData => periodData.period === PERIOD2)?.accumulatedFouls || 0
    const firstExtraTimeFouls =
      foulsByPeriods.find(periodData => periodData.period === EXTRA_TIME_1)?.accumulatedFouls || 0
    const secondExtraTimeFouls =
      foulsByPeriods.find(periodData => periodData.period === EXTRA_TIME_2)?.accumulatedFouls || 0

    return secondHalfFouls + firstExtraTimeFouls + secondExtraTimeFouls
  }
}
