import { Flex, Text, useMediaContext } from '@sofascore/ui'
import { useEventState } from 'entities/Event'
import { PeriodType, ScoreCounter } from 'entities/Score'
import noop from 'lodash/noop'

interface Props {
  periodKey: PeriodType
  periodName: React.ReactNode
  onIncrementPeriodScore: (isHome: boolean) => void
  onDecrementPeriodScore: (isHome: boolean) => void
  onSetPeriodScore?: (newScore: number, isHome: boolean) => void
  disableButtons?: boolean
  disableInput?: boolean
}

export const SetPeriodScoreLive = ({
  periodKey,
  periodName,
  onIncrementPeriodScore,
  onDecrementPeriodScore,
  onSetPeriodScore,
  disableButtons,
  disableInput,
}: Props) => {
  const { isMobile } = useMediaContext()
  const { event } = useEventState()

  const { homeScore, awayScore } = event

  return (
    <Flex justify="space-around" bg="surface.s1" br="sm" p="lg" mt="sm" h={110} elevation={2}>
      <Flex align="flex-end">
        <ScoreCounter
          value={homeScore[periodKey] === null ? undefined : (homeScore[periodKey] as number | undefined)}
          variant={isMobile ? 'compact' : 'wide'}
          onChange={onSetPeriodScore ? newScore => onSetPeriodScore(newScore, true) : noop}
          onIncrement={() => onIncrementPeriodScore(true)}
          onDecrement={() => onDecrementPeriodScore(true)}
          disableButtons={disableButtons}
          disableInput={disableInput}
        />
      </Flex>

      <Text font="display.micro" whiteSpace="nowrap">
        {periodName}
      </Text>

      <Flex align="flex-end">
        <ScoreCounter
          value={awayScore[periodKey] === null ? undefined : (awayScore[periodKey] as number | undefined)}
          variant={isMobile ? 'compact' : 'wide'}
          onChange={onSetPeriodScore ? newScore => onSetPeriodScore(newScore, false) : noop}
          onIncrement={() => onIncrementPeriodScore(false)}
          onDecrement={() => onDecrementPeriodScore(false)}
          disableButtons={disableButtons}
          disableInput={disableInput}
        />
      </Flex>
    </Flex>
  )
}
