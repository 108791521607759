import { FormattedMessage } from 'react-intl'
import { Box, Flex, Text } from '@sofascore/ui'

import ResponsivePopUp from 'components/ResponsivePopUp'
import { Button } from 'components/Button'

interface SaveDialogProps {
  isOpen: boolean
  onSave: () => void
  onCancel: () => void
  onClose: () => void
}

const SaveDialog = ({ isOpen, onSave, onCancel, onClose }: SaveDialogProps) => {
  return (
    <ResponsivePopUp heading="&nbsp;" isOpen={isOpen} onClose={onClose}>
      <Box py="xl" mb="lg" textAlign="center">
        <Text style={{ fontSize: 16, fontWeight: 500, textAlign: 'center' }}>
          <FormattedMessage id="saveChanges" />
        </Text>
      </Box>

      <Flex justify="center" gapX="lg" px="lg" mb="xl" mx="lg">
        <Button variant="outlined" fullWidth onClick={onCancel}>
          <FormattedMessage id="cancel" />
        </Button>
        <Button variant="contained" fullWidth onClick={onSave}>
          <FormattedMessage id="save" />
        </Button>
      </Flex>
    </ResponsivePopUp>
  )
}

export default SaveDialog
