import { FormattedMessage } from 'react-intl'

import { getTimeString } from 'components/Timekeeping'
import { isToday, isTomorrow } from 'utils/time'

export const getText = (
  eventStart: Date,
  timeParts: number[],
  delta: number,
  disableEditLiveInactiveThreshold: number,
  enableEditLiveThreshold: number,
): { primary: JSX.Element | string; secondary?: JSX.Element | string } => {
  const [days, hours, minutes, seconds] = timeParts

  const isEventToday = isToday(eventStart)
  const isEventTomorrow = isTomorrow(eventStart)

  const minutesRemaining = delta / 60

  // If live editing ends display "-"
  const minutesAfterStart = Math.abs(minutesRemaining)
  if (minutesAfterStart >= disableEditLiveInactiveThreshold) {
    const primary = '-'
    const secondary = undefined
    return { primary, secondary }
  }

  // If it starts today, show countdown in seconds
  else if (isEventToday) {
    let primary
    if (minutesRemaining <= enableEditLiveThreshold) {
      primary = getTimeString([hours, minutes, seconds], { precision: 'm' })
    } else {
      primary = <FormattedMessage id="editor_calendar_today" />
    }

    return { primary }
  }

  // For tomorrow or later, shor broader countdown
  else if (isEventTomorrow) {
    const primary = <FormattedMessage id={'editor_tomorrow'} />
    return { primary }
  } else {
    const primary = `In ${days} days`
    return { primary }
  }
}
