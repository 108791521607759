import { BasicEvent } from 'entities/Event'
import { SportName, orderedSports } from 'entities/Sport'

import { GroupedEvents } from './interface'

export const groupEventsBySportAndTournament = (events: BasicEvent[]) => {
  // Guarantee sport order
  const groupedEvents: GroupedEvents = orderedSports.map(sport => {
    return { sport, tournaments: [] }
  })

  events.forEach(event => {
    // Determine Sport
    const sport: SportName = event.tournament.category.sport.slug
    const sportGroupIndex = groupedEvents.findIndex(sportGroup => sportGroup.sport === sport)

    if (groupedEvents[sportGroupIndex]) {
      // Determine Tournament
      const tournamentId = event.tournament.id
      const tournamentGroupIndex = groupedEvents[sportGroupIndex].tournaments.findIndex(
        tournament => tournament.id === tournamentId,
      )

      // If tournament exists add the event, else add the tournament together with event
      if (tournamentGroupIndex === -1)
        groupedEvents[sportGroupIndex].tournaments.push({
          id: tournamentId,
          name: event.tournament.name,
          events: [event],
        })
      else groupedEvents[sportGroupIndex].tournaments[tournamentGroupIndex].events.push(event)
    }
  })

  // Remove empty sport groups
  return groupedEvents.filter(sportGroup => {
    // Sort tournaments alphabetically
    sportGroup.tournaments = sportGroup.tournaments.sort((t1, t2) => {
      return t1.name >= t2.name ? 1 : -1
    })

    // Remove empty sport groups
    return sportGroup.tournaments.length !== 0
  })
}
