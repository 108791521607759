import { Flex, Spinner, Text } from '@sofascore/ui'
import { useParams } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { useState } from 'react'
import { Chip, PermissionGuard } from 'shared/ui'
import { PermissionAction, PermissionScope } from 'entities/Permission'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'
import { FirebaseEventName, FirebaseEventType } from 'shared/lib/firebase/model'
import { usePermissions } from 'shared/hooks'

import { EntityFilter as SuggestionStatus } from 'modules/MyCompetitions/MyCompetitionTeams/model'
import { SERVER_ERROR_PAGE_MESSAGE } from 'utils'
import { IconButton } from 'components/IconButton'
import { IconPlusCircle } from 'components/Icons/IconPlusCircle'
import ResponsivePopUp from 'components/ResponsivePopUp'

import { useTeam } from '../TeamContext'
import ApprovedPlayers from './ApprovedPlayers'
import { getOrderedPositionGroups } from './utils'
import PendingPlayers from './PendingPlayers'
import DeclinedPlayers from './DeclinedPlayers'
import { teamPlayersTabs } from './config'
import { useAllPlayers } from './hooks'
import { PlayerForm } from './PlayerForm'
import { TeamPlayersTabs } from './interface'
import EmptyState from './EmptyState'
import TeamTabTitle from '../TeamTabTitle'

const TeamPlayers = () => {
  const { teamId } = useParams()
  const team = useTeam()
  const { guardEntity } = usePermissions()
  const canCreatePlayerPermission = guardEntity(team?.allowedActions, [PermissionAction.Create], PermissionScope.Player)

  const intl = useIntl()

  const [selectedStatus, setSelectedStatus] = useState(SuggestionStatus.APPROVED)

  const {
    allPlayers,
    isLoading: arePlayersLoading,
    isError,
    revalidateApprovedPlayers,
    revalidatePendingPlayers,
  } = useAllPlayers(teamId)

  const [isPlayerFormOpen, setIsPlayerFormOpen] = useState(false)

  const responsivePopupOpen = () => {
    setIsPlayerFormOpen(true)
    logFirebaseEvent(FirebaseEventName.AddEntity, {
      type: FirebaseEventType.Player,
    })
  }
  const responsivePopupClose = (discarded?: boolean | undefined) => {
    setIsPlayerFormOpen(false)
    if (discarded) {
      logFirebaseEvent(FirebaseEventName.DiscardEntity, {
        type: FirebaseEventType.Player,
      })
    } else {
      logFirebaseEvent(FirebaseEventName.SubmitEntity, {
        type: FirebaseEventType.Player,
      })
    }
  }

  if (isError) {
    return (
      <>
        <TeamTabTitle title={intl.formatMessage({ id: 'squad' })} />
        <Flex direction="column" align="center" pt="xxl">
          <Text mt={50} fontSize="extraLarge">
            {SERVER_ERROR_PAGE_MESSAGE}
          </Text>
        </Flex>
      </>
    )
  }

  if (!team || arePlayersLoading)
    return (
      <>
        <TeamTabTitle title={intl.formatMessage({ id: 'squad' })} />
        <Flex justify="center" pt={100}>
          <Spinner width="40px" />
        </Flex>
      </>
    )

  const filterChips = (tab: TeamPlayersTabs) => {
    if (!canCreatePlayerPermission) return tab.statusType === SuggestionStatus.APPROVED
    return true
  }

  const revalidatePlayers = () => {
    revalidateApprovedPlayers()
    revalidatePendingPlayers()
  }

  const isListEmpty =
    allPlayers.approved.length === 0 && allPlayers.declined.length === 0 && allPlayers.pending.length === 0

  return (
    <>
      {isListEmpty ? (
        <EmptyState onAddNewPlayer={responsivePopupOpen} />
      ) : (
        <>
          {/* Control Panel */}
          <Flex
            h={48}
            justify="space-between"
            align="center"
            bg="surface.s1"
            mt={[0, 'sm']}
            br="sm"
            pl="lg"
            pr="md"
            elevation={1}
          >
            <Text fontSize="medium" fontWeight="bold">
              <FormattedMessage id="players" />
            </Text>

            <PermissionGuard
              entityAllowedActions={team.allowedActions}
              checkAllowedActions={[PermissionAction.Create]}
              entityAllowedActionsScope={PermissionScope.Player}
            >
              <IconButton onClick={responsivePopupOpen}>
                <IconPlusCircle />
              </IconButton>
            </PermissionGuard>
          </Flex>

          {/* Suggestion Status Chips */}
          <Flex mt="sm" gapX="sm" w="100%" overflow="auto">
            {teamPlayersTabs.filter(filterChips).map(tab => (
              <Chip
                key={tab.msgId}
                value={intl.formatMessage({ id: tab.msgId })}
                isActive={selectedStatus === tab.statusType}
                onClick={() => setSelectedStatus(tab.statusType)}
              />
            ))}
          </Flex>

          {/* Player List */}
          {selectedStatus === SuggestionStatus.APPROVED && (
            <ApprovedPlayers
              team={team}
              positionGroups={getOrderedPositionGroups(team.sport.slug, allPlayers.approved)}
              revalidatePlayers={revalidatePlayers}
            />
          )}

          <PermissionGuard
            entityAllowedActions={team.allowedActions}
            checkAllowedActions={[PermissionAction.Create]}
            entityAllowedActionsScope={PermissionScope.Player}
          >
            {selectedStatus === SuggestionStatus.PENDING && (
              <PendingPlayers team={team} suggestedPlayers={allPlayers.pending} />
            )}

            {selectedStatus === SuggestionStatus.DECLINED && (
              <DeclinedPlayers team={team} suggestedPlayers={allPlayers.declined} />
            )}
          </PermissionGuard>
        </>
      )}

      <ResponsivePopUp
        isOpen={isPlayerFormOpen}
        heading={
          <Text fontSize="large" fontWeight="bold">
            <FormattedMessage id="add_new_player" />
          </Text>
        }
        onClose={responsivePopupClose}
      >
        <PlayerForm team={team} handleClose={responsivePopupClose} onConfirm={revalidatePlayers} />
      </ResponsivePopUp>
    </>
  )
}

export default TeamPlayers
