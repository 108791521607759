import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Box, Flex, Text } from '@sofascore/ui'
import { IconFilter, PermissionGuard } from 'shared/ui'
import { BasicEvent } from 'entities/Event'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'
import { FirebaseEventName, FirebaseEventType } from 'shared/lib/firebase/model'
import { PermissionAction, PermissionScope } from 'entities/Permission'
import { MatchesFiltersForm } from 'features/FilterMatches'

import { useMyCompetitionsState } from 'modules/MyCompetitions/providers/MyCompetitionsProvider'
import ResponsivePopUp from 'components/ResponsivePopUp'
import { IconButton } from 'components/IconButton'
import { IconPlusCircle } from 'components/Icons/IconPlusCircle'

import { MATCHES_NAV_HEIGHT } from './config'
import { useCompetitionMatchesDispatch } from '../CompetitionMatchesContext'
import { CreateMatchForm } from '../CreateMatch'
import { registerRevalidatedEvent } from '../CompetitionMatchesContext/actions'
import * as S from './styles'

const MatchesNavigation = () => {
  const { state } = useLocation()
  const { tournaments, uniqueTournament } = useMyCompetitionsState()

  const dispatch = useCompetitionMatchesDispatch()

  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [isCreateMatchFormOpen, setIsCreateMatchFormOpen] = useState(state?.shouldOpenForm)

  const firebasePayload = {
    type: FirebaseEventType.Event,
    sport: uniqueTournament?.category?.sport?.name?.toLowerCase(),
  }

  const handleMatchCreate = (event: BasicEvent) => {
    dispatch(registerRevalidatedEvent(event))
    logFirebaseEvent(FirebaseEventName.SubmitEntity, { ...firebasePayload, status: event.status.type })
  }

  const createMatchPopupOpen = () => {
    logFirebaseEvent(FirebaseEventName.AddEntity, { type: firebasePayload.type })
    setIsCreateMatchFormOpen(true)
  }
  const createMatchPopupClose = (discarded?: boolean | undefined) => {
    setIsCreateMatchFormOpen(false)
    if (discarded) logFirebaseEvent(FirebaseEventName.DiscardEntity, firebasePayload)
  }

  useEffect(() => {
    if (state && state.shouldOpenForm) {
      setIsCreateMatchFormOpen(true)
    }
  }, [state])

  return (
    <S.MatchesNavigation
      w="100%"
      h={MATCHES_NAV_HEIGHT}
      br="sm"
      px="lg"
      justify="space-between"
      align="center"
      bg="surface.s1"
      elevation={2}
    >
      <Text fontWeight="bold" color="onSurface.nLv1" fontSize="medium">
        <FormattedMessage id="matches" />
      </Text>

      <Flex align="center">
        <S.NavigationButton variant="text" onClick={() => setIsFilterOpen(true)}>
          <IconFilter />
        </S.NavigationButton>
        <PermissionGuard entityScopes={[PermissionScope.Event]} checkAllowedActions={[PermissionAction.Create]}>
          <Box pl="lg">
            <IconButton onClick={createMatchPopupOpen}>
              <IconPlusCircle />
            </IconButton>
          </Box>
        </PermissionGuard>
      </Flex>

      {/* Matches Filter */}
      <ResponsivePopUp
        isOpen={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        heading={
          <Text fontWeight="bold" fontSize="large">
            <FormattedMessage id="filter" />
          </Text>
        }
      >
        <MatchesFiltersForm tournaments={tournaments} onClose={() => setIsFilterOpen(false)} />
      </ResponsivePopUp>

      {/* Create Match Form */}
      <ResponsivePopUp
        isOpen={isCreateMatchFormOpen}
        onClose={createMatchPopupClose}
        heading={
          <Text font="display.large">
            <FormattedMessage id="editor_add_match" />
          </Text>
        }
      >
        <CreateMatchForm onClose={createMatchPopupClose} onCreate={handleMatchCreate} />
      </ResponsivePopUp>
    </S.MatchesNavigation>
  )
}

export default MatchesNavigation
