import { useRef } from 'react'
import { Box, BoxProps } from '@sofascore/ui'

import * as S from './styles'

type TextAlign = 'left' | 'right' | 'center'

interface ScoreInputProps {
  value: number | undefined
  onChange: (newValue: number) => void
  width?: string
  height?: string
  textAlign?: TextAlign
  color?: string
  fontSize?: number
  disabled?: boolean
}

const MAX_SCORE_VALUE = 999

const ScoreInput = ({
  value,
  onChange,
  width = '56',
  height = 'auto',
  textAlign = 'left',
  color,
  fontSize,
  disabled,
  ...boxProps
}: ScoreInputProps & BoxProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <Box
      w={width}
      h={height}
      bg="surface.s2"
      border="onSurface.nLv4"
      br="sm"
      pr={textAlign === 'right' ? 'xxs' : undefined}
      pl={textAlign === 'left' ? 'xxs' : undefined}
      color={color}
      onClick={() => {
        inputRef.current?.select()
      }}
      {...boxProps}
    >
      <S.Input
        ref={inputRef}
        value={value !== undefined ? value.toString() : '-'}
        onChange={e => {
          const newValue = +e.target.value

          const isNegative = newValue < 0
          const isOutOfBounds = newValue > MAX_SCORE_VALUE
          if (isNaN(newValue) || isNegative || isOutOfBounds) return

          onChange(newValue)
        }}
        fontSize={fontSize}
        style={{ textAlign }}
        disabled={disabled}
      />
    </Box>
  )
}

export default ScoreInput
