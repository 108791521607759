import React from 'react'
import { Flex } from '@sofascore/ui'
import { SportName } from 'entities/Sport'

import { teamImage } from 'api/routes'
import { Option } from 'components/DropdownMenu/interface'
import { useMyCompetitionsState } from 'modules/MyCompetitions/providers/MyCompetitionsProvider'

import * as S from './styles'
import { useCreateMatchState } from '../context/createMatchContext'

interface CreateMatchTeamImageProps {
  team?: Option
  subTeam1?: Option
  subTeam2?: Option
  side?: 'right' | 'left'
}

const CreateMatchTeamImage: React.FC<CreateMatchTeamImageProps> = ({ team, subTeam1, subTeam2, side }) => {
  const { matchType } = useCreateMatchState()
  const { uniqueTournament } = useMyCompetitionsState()

  const sport = uniqueTournament?.category.sport.slug
  const isTennis = sport === SportName.Tennis
  const areTennisDouble = matchType === 'tennisDouble'

  const fallbackImage = isTennis ? '/images/placeholders/player.svg' : '/images/placeholders/teamLogo.png'
  const teamImageUrl = team?.value ? teamImage(Number(team?.value)) : ''

  const subTeam1ImageUrl = subTeam1?.value ? teamImage(Number(subTeam1?.value)) : ''
  const subTeam2ImageUrl = subTeam2?.value ? teamImage(Number(subTeam2?.value)) : ''

  let teamImages = (
    <Flex justify={side === 'right' ? 'flex-end' : 'flex-start'}>
      <S.TeamImage
        src={teamImageUrl}
        rounded={isTennis}
        fallback={<S.TeamImage src={fallbackImage} rounded={isTennis} />}
      />
    </Flex>
  )

  if (isTennis && areTennisDouble) {
    teamImages = (
      <Flex justify={side === 'right' ? 'flex-end' : 'flex-start'}>
        <S.TeamImage
          src={subTeam1ImageUrl}
          fallback={<S.TeamImage tuckRight={side === 'right'} src={fallbackImage} rounded={isTennis} />}
          tuckRight={side === 'right'}
          rounded={isTennis}
        />
        <S.TeamImage
          src={subTeam2ImageUrl}
          fallback={<S.TeamImage tuckLeft={side === 'left'} src={fallbackImage} rounded={isTennis} />}
          tuckLeft={side === 'left'}
          rounded={isTennis}
        />
      </Flex>
    )
  }

  return teamImages
}

export default CreateMatchTeamImage
