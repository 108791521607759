import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Typography, Box, Theme, Accordion, AccordionSummary, AccordionDetails, useTheme } from '@material-ui/core'
import { useMediaContext } from '@sofascore/ui'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import get from 'lodash/get'
import {
  STATUS_CODES_BY_SPORT,
  FIRST_HALF_CODE,
  SECOND_HALF_CODE,
  HALF_TIME_CODE,
  ENDED_CODE,
  StatusType,
  getStatusOption,
} from 'entities/Status'
import { SportName, sportsWithTwoHalves } from 'entities/Sport'

import { useStyles } from 'modules/Matches/styles'
import { TimePicker } from 'components/DateTimePickers/DateTimePickers'
import { backgrounds } from 'styles/color'
import { FIRST_HALF, SECOND_HALF, BETWEEN_PERIODS, ENDED, OTHER } from 'modules/Matches/const'
import { isFootballBasicStatus, isPeriodStatus, isTimestampValid } from 'modules/Matches/helper'
import { SET_MATCH_STATUS, SET_CURRENT_PERIOD_START_TIMESTAMP } from 'modules/Matches/matchesReducer'
import { RootState } from 'store/store'
import { Option } from 'components/DropdownMenu/interface'
import { getDate } from 'utils/time'
import StatusRadioGroup from 'components/Selector/StatusRadioGroup'
import StatusSelector from 'modules/Matches/components/Selectors'
import { useMyCompetitionsState } from 'modules/MyCompetitions/providers/MyCompetitionsProvider'

/**
 * Panel for displaying event period start date and time
 */
const CurrentPeriodPanel = () => {
  const dispatch = useDispatch()
  const statusCode = useSelector((state: RootState) => {
    return state.event ? state.event.status.code : null
  })
  const currentPeriodStartTimestamp = useSelector((state: RootState) => {
    return state.event ? state.event.currentPeriodStartTimestamp : null
  })

  const { uniqueTournament } = useMyCompetitionsState()

  const sport = uniqueTournament?.category.sport.slug
  const intl = useIntl()

  // Map for selecting radio button according to current status code
  const basicMatchStatusRadioMap = useMemo(
    () => ({
      [FIRST_HALF_CODE]: FIRST_HALF,
      [HALF_TIME_CODE]: BETWEEN_PERIODS,
      [SECOND_HALF_CODE]: SECOND_HALF,
      [ENDED_CODE]: ENDED,
    }),
    [],
  )

  // MatchStatus value from dropdown
  const [matchStatusValue, setMatchStatusValue] = useState<Option | null>(() => {
    if (typeof statusCode === 'number' && sport) {
      return {
        label: intl.formatMessage({ id: get(STATUS_CODES_BY_SPORT[sport], `${statusCode}.description`, '') }),
        value: statusCode,
      }
    }
    return null
  })

  // Match status value from radio group section
  const [matchStatusValueRadio, setMatchStatusValueRadio] = useState<string>(() => {
    if (typeof statusCode === 'number') {
      if (isFootballBasicStatus(statusCode)) {
        return basicMatchStatusRadioMap[statusCode]
      } else {
        return OTHER
      }
    }
  })

  useEffect(() => {
    if (matchStatusValue && statusCode !== matchStatusValue.value) {
      if (isFootballBasicStatus(statusCode)) {
        setMatchStatusValueRadio(basicMatchStatusRadioMap[statusCode])
      } else {
        setMatchStatusValueRadio(OTHER)
      }

      setMatchStatusValue(
        sport
          ? {
              label: intl.formatMessage({ id: STATUS_CODES_BY_SPORT[sport][statusCode].description }),
              value: Number(statusCode),
            }
          : null,
      )
    }
  }, [statusCode, sport, basicMatchStatusRadioMap, matchStatusValue, intl])

  // Selected time (date) from time picker for Match status
  const [currentPeriodStartTime, setCurrentPeriodStartTime] = useState<Date | null>(
    currentPeriodStartTimestamp ? getDate(currentPeriodStartTimestamp) : null,
  )

  // Current period start time handler
  const handleMatchStatusTimeChange = useCallback(
    (date: Date | null) => {
      setCurrentPeriodStartTime(date)
      dispatch({ type: SET_CURRENT_PERIOD_START_TIMESTAMP, payload: { periodStartTime: date } })
    },
    [dispatch],
  )

  // Deleting current period start time
  const clearCurrentPeriodStartTime = () => {
    setCurrentPeriodStartTime(null)
    dispatch({ type: SET_CURRENT_PERIOD_START_TIMESTAMP, payload: { periodStartTime: null } })
  }

  // Set current period start time to current time
  const setNowCurrentPeriodStartTime = () => {
    const now = new Date()
    setCurrentPeriodStartTime(now)
    dispatch({ type: SET_CURRENT_PERIOD_START_TIMESTAMP, payload: { periodStartTime: now } })
  }

  // Clicking on status from drop down menu
  const handleClickMatchStatusMenuItem = (selectedOption: Option) => {
    if (selectedOption.value !== ENDED_CODE) {
      setCurrentPeriodStartTime(null)
      dispatch({ type: SET_CURRENT_PERIOD_START_TIMESTAMP, payload: { periodStartTime: null } })
    }

    if ((matchStatusValue && selectedOption.value !== matchStatusValue.value) || !matchStatusValue) {
      setMatchStatusValue(selectedOption)
      dispatch({
        type: SET_MATCH_STATUS,
        payload: { status: { code: Number(selectedOption.value) } },
      })
    }
  }

  // Clicking on status from radio group section
  const handleMatchStatusValueRadioChange = (matchStatusValueRadioEvent: React.ChangeEvent<HTMLInputElement>) => {
    const newMatchStatusRadioValue = matchStatusValueRadioEvent.target.value

    if (newMatchStatusRadioValue === OTHER) {
      setMatchStatusValue(null)
      if (currentPeriodStartTime) {
        dispatch({ type: SET_CURRENT_PERIOD_START_TIMESTAMP, payload: { periodStartTime: null } })
        setCurrentPeriodStartTime(null)
      }
    } else {
      const statusOption = sport ? getStatusOption(newMatchStatusRadioValue, sport) : null
      if (statusOption) {
        handleClickMatchStatusMenuItem(statusOption)
      }
    }

    setMatchStatusValueRadio(newMatchStatusRadioValue)
  }

  const theme: Theme = useTheme()
  const classes = useStyles()
  const { isMobile } = useMediaContext()
  const hasTwoPeriods = sportsWithTwoHalves.includes(sport as SportName)

  const showRequiredFieldLabel = Boolean(isPeriodStatus(matchStatusValue) && !isTimestampValid(currentPeriodStartTime))

  const isOtherPeriodStatus = matchStatusValueRadio === OTHER && isPeriodStatus

  return typeof statusCode === 'number' ? (
    <Accordion className={classes.expansionPanel} defaultExpanded style={{ backgroundColor: backgrounds.content }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: theme.palette.secondary.main }} />}
        className={classes.expansionPanelSummary}
      >
        <Typography className={classes.heading}>
          <FormattedMessage id="currentPeriodStartTime" />
        </Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.expansionPanelDetailsRoot }}>
        <div className={classes.container} style={{ flexDirection: 'column' }}>
          {hasTwoPeriods && (
            <StatusRadioGroup value={matchStatusValueRadio} onChange={handleMatchStatusValueRadioChange} />
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              marginBottom: isMobile ? undefined : theme.spacing(2.5),
            }}
          >
            {(matchStatusValueRadio === OTHER || !hasTwoPeriods) && (
              <StatusSelector
                value={matchStatusValue}
                onChange={handleClickMatchStatusMenuItem}
                caption={intl.formatMessage({ id: 'matchStatus' })}
                className={classes.statusSelector}
              />
            )}
            {matchStatusValue &&
            sport &&
            STATUS_CODES_BY_SPORT[sport][matchStatusValue.value] &&
            STATUS_CODES_BY_SPORT[sport][matchStatusValue.value].type === StatusType.IN_PROGRESS ? (
              <>
                <TimePicker
                  value={currentPeriodStartTime}
                  onChange={handleMatchStatusTimeChange}
                  label={intl.formatMessage({ id: 'time' })}
                  style={
                    isMobile
                      ? { marginBottom: 20 }
                      : {
                          paddingRight: theme.spacing(3),
                          width: '33.33%',
                        }
                  }
                />

                {showRequiredFieldLabel && (
                  <Typography
                    className={classes.inputInfoLabel}
                    style={{
                      left: isOtherPeriodStatus && !isMobile ? `calc(33.33% + ${theme.spacing(1.5)}px)` : undefined,
                    }}
                  >
                    <FormattedMessage id="requiredField" />
                  </Typography>
                )}
                {currentPeriodStartTime ? (
                  <Typography
                    className={classes.label}
                    style={{ marginBottom: 0 }}
                    onClick={clearCurrentPeriodStartTime}
                  >
                    <FormattedMessage id="clear" />
                  </Typography>
                ) : (
                  <Typography
                    className={classes.label}
                    onClick={setNowCurrentPeriodStartTime}
                    style={{ marginBottom: 0 }}
                    noWrap
                  >
                    <FormattedMessage id="currentTime" />
                  </Typography>
                )}
              </>
            ) : (
              <>
                <div className={classes.infoContainer} style={isMobile ? { marginBottom: 0 } : {}}>
                  <Typography variant="caption" color="primary">
                    <FormattedMessage id="time" />
                  </Typography>
                  <Box className={classes.infoItem} style={{ opacity: 0.6 }}>
                    <Typography className={classes.title} variant="subtitle2">
                      -
                    </Typography>
                  </Box>
                </div>
              </>
            )}
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  ) : (
    <></>
  )
}

export default CurrentPeriodPanel
