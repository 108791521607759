import { Theme, createStyles, makeStyles } from '@material-ui/core'

import { RED } from 'styles/color'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioButtonContainer: {
      [theme.breakpoints.up('md')]: {
        width: `calc(33.33% - ${theme.spacing(2)}px)`,
        marginTop: theme.spacing(2.5),
      },
    },

    title: {
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(0.5),
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        marginTop: 0,
      },
    },

    roundsInputContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },

    errorText: {
      color: RED.incident,
      fontSize: 12,
      fontWeight: 'normal',
      marginTop: theme.spacing(1),
    },
  }),
)
