import { CSSProperties } from 'react'
import { useDrop, DropTargetMonitor } from 'react-dnd'
import { Flex } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'
import { DND_TYPE, DynamicLineupsPlayer } from 'features/AddLineups'

import { IconRemoveFilled } from 'components/Icons/IconRemoveFilled'
import { IconShirt } from 'components/Icons/IconShirt'
import { IconButton } from 'components/IconButton'
import { IconShirtEmpty } from 'components/Icons/IconShirtEmpty'

import * as S from './styles'

interface Props {
  lineupPlayers: DynamicLineupsPlayer[]
  kitColor: string
  position: number
  onDrop: (player: DynamicLineupsPlayer, position: number) => void
  onRemove: (player: DynamicLineupsPlayer) => void
  style?: CSSProperties
}

export const DesktopDragAndDropSlot = ({ lineupPlayers, kitColor, position, onDrop, onRemove, style }: Props) => {
  const insertedPlayer = lineupPlayers.find(p => p.position === position)

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: DND_TYPE,
    drop: (player: DynamicLineupsPlayer) => {
      onDrop(player, position)
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }))

  if (insertedPlayer) {
    return (
      <S.DragAndDropSlot style={style}>
        <Flex align="flex-end" position="relative">
          <IconShirt fill={kitColor} width={28} height={32} />
          <S.JerseyNumber $isWhiteJersey={kitColor === '#FFFFFF'}>{insertedPlayer.player.jerseyNumber}</S.JerseyNumber>
          <IconButton position="absolute" left="95%" onClick={() => onRemove(insertedPlayer)} p="0px">
            <IconRemoveFilled />
          </IconButton>
        </Flex>

        <S.PlayerName>
          {insertedPlayer.captain ? '(c) ' : ''} {insertedPlayer.player.name}
        </S.PlayerName>
      </S.DragAndDropSlot>
    )
  }

  return (
    <S.DragAndDropSlot ref={drop} style={style}>
      <IconShirtEmpty />
      {canDrop && isOver && (
        <S.DragAndDropSlotText font="assistive.default" color="onColor.primary">
          <FormattedMessage id="editor_drop_here" />
        </S.DragAndDropSlotText>
      )}
    </S.DragAndDropSlot>
  )
}
