import { SportName } from '../../Sport'
import { StatusCode, StatusCodeMap, StatusType } from '../model'

export const NOT_STARTED_CODE = 0
export const FIRST_HALF_CODE = 6
export const SECOND_HALF_CODE = 7
export const FIRST_QUARTER_CODE = 13
export const SECOND_QUARTER_CODE = 14
export const THIRD_QUARTER_CODE = 15
export const FOURTH_QUARTER_CODE = 16
export const STARTED = 20
export const OVERTIME = 40
export const FIRST_EXTRA_TIME_CODE = 41
export const SECOND_EXTRA_TIME_CODE = 42
export const PENALTIES_CODE = 50
export const ENDED_CODE = 100
export const HALF_TIME_CODE = 31
export const CANCELED_CODE = 70
export const POSTPONED_CODE = 60
export const INTERRUPTED_CODE = 80
export const PAUSE_CODE = 30
export const AWAITING_EXTRA_TIME_CODE = 32
export const EXTRA_TIME_HALF_CODE = 33
export const AWAITING_PENALTIES_CODE = 34
export const AET = 110
export const AP = 120
export const AFTER_EXTRA_TIME_CODE = 110
export const AFTER_PENALTIES_CODE = 120

const froStatuses: StatusCodeMap = {
  0: {
    description: 'Not Started',
    type: StatusType.NOT_STARTED,
  },
  60: {
    description: 'Postponed',
    type: StatusType.POSTPONED,
  },
  70: {
    description: 'Canceled',
    type: StatusType.CANCELED,
  },
  80: {
    description: 'Interrupted',
    type: StatusType.INTERRUPTED,
  },
  100: {
    description: 'Ended',
    type: StatusType.FINISHED,
  },
}

const started: StatusCodeMap = {
  20: {
    description: 'Started',
    type: StatusType.STARTED,
  },
}

const baseStatuses: StatusCodeMap = {
  30: {
    description: 'Pause',
    type: StatusType.PAUSE,
  },
  61: {
    description: 'Start delayed',
    type: StatusType.DELAYED,
  },
  81: {
    description: 'Suspended',
    type: StatusType.SUSPENDED,
  },
  90: {
    description: 'Abandoned',
    type: StatusType.CANCELED,
  },
  91: {
    description: 'Walkover',
    type: StatusType.FINISHED,
  },
  92: {
    description: 'Retired',
    type: StatusType.FINISHED,
  },
  140: {
    description: 'Will continue',
    type: StatusType.WILL_CONTINUE,
  },
}

const extraTime: StatusCodeMap = {
  32: {
    description: 'Awaiting extra time',
    type: StatusType.IN_PROGRESS,
  },
  110: {
    description: 'AET',
    type: StatusType.FINISHED,
  },
}

const halves: StatusCodeMap = {
  6: {
    description: '1st half',
    type: StatusType.IN_PROGRESS,
  },
  7: {
    description: '2nd half',
    type: StatusType.IN_PROGRESS,
  },
}

const sets: StatusCodeMap = {
  8: {
    description: '1st set',
    type: StatusType.IN_PROGRESS,
  },
  9: {
    description: '2nd set',
    type: StatusType.IN_PROGRESS,
  },
  10: {
    description: '3rd set',
    type: StatusType.IN_PROGRESS,
  },
  11: {
    description: '4th set',
    type: StatusType.IN_PROGRESS,
  },
  12: {
    description: '5th set',
    type: StatusType.IN_PROGRESS,
  },
}

const quarters: StatusCodeMap = {
  13: {
    description: '1st quarter',
    type: StatusType.IN_PROGRESS,
  },
  14: {
    description: '2nd quarter',
    type: StatusType.IN_PROGRESS,
  },
  15: {
    description: '3rd quarter',
    type: StatusType.IN_PROGRESS,
  },
  16: {
    description: '4th quarter',
    type: StatusType.IN_PROGRESS,
  },
}

const halftime: StatusCodeMap = {
  31: {
    description: 'Halftime',
    type: StatusType.IN_PROGRESS,
  },
}

const pause: StatusCodeMap = {
  30: {
    description: 'Break',
    type: StatusType.IN_PROGRESS,
  },
}

const overtime: StatusCodeMap = {
  40: {
    description: 'Overtime',
    type: StatusType.IN_PROGRESS,
  },
}

const extraTimeHalves: StatusCodeMap = {
  41: {
    description: '1st extra',
    type: StatusType.IN_PROGRESS,
  },
  42: {
    description: '2nd extra',
    type: StatusType.IN_PROGRESS,
  },
  33: {
    description: 'Extra time halftime',
    type: StatusType.IN_PROGRESS,
  },
}

const penalties: StatusCodeMap = {
  120: {
    description: 'AP',
    type: StatusType.FINISHED,
  },
  50: {
    description: 'Penalties',
    type: StatusType.IN_PROGRESS,
  },
  34: {
    description: 'Awaiting penalties',
    type: StatusType.IN_PROGRESS,
  },
}

export const STATUS_CODES: StatusCodeMap = {
  ...baseStatuses,
  ...halves,
  ...quarters,
  ...halftime,
  ...extraTimeHalves,
  ...penalties,
  ...overtime,
}

export const STATUS_CODES_BY_SPORT: { [sport: string]: StatusCodeMap } = {
  [SportName.Football]: {
    ...started,
    ...froStatuses,
    ...baseStatuses,
    ...extraTime,
    ...halves,
    ...halftime,
    ...extraTimeHalves,
    ...penalties,
  },
  [SportName.ESports]: {
    ...froStatuses,
    ...baseStatuses,
    ...extraTime,
    ...halves,
    ...halftime,
    ...extraTimeHalves,
    ...penalties,
  },
  [SportName.Futsal]: {
    ...froStatuses,
    ...baseStatuses,
    ...extraTime,
    ...halves,
    ...halftime,
    ...extraTimeHalves,
    ...penalties,
  },
  [SportName.Basketball]: {
    ...froStatuses,
    ...baseStatuses,
    ...extraTime,
    ...quarters,
    ...pause,
    ...overtime,
  },
  [SportName.Waterpolo]: {
    ...froStatuses,
    ...baseStatuses,
    ...extraTime,
    ...quarters,
    ...overtime,
    ...penalties,
  },
  [SportName.Tennis]: {
    ...froStatuses,
  },
  [SportName.Volleyball]: {
    ...froStatuses,
    ...baseStatuses,
    ...sets,
  },
  [SportName.MiniFootball]: {
    ...started,
    ...froStatuses,
    ...baseStatuses,
    ...extraTime,
    ...halves,
    ...halftime,
    ...extraTimeHalves,
    ...penalties,
  },
  [SportName.Rugby]: {
    ...started,
    ...froStatuses,
    ...baseStatuses,
    ...extraTime,
    ...halves,
    ...halftime,
    ...extraTimeHalves,
    ...penalties,
  },
}

export const SPECIAL_STATUSES = [
  StatusCode.Canceled,
  StatusCode.Postponed,
  StatusCode.Interrupted,
  StatusCode.Walkover,
  StatusCode.Retired,
  StatusCode.Suspended,
  StatusCode.Abandoned,
  StatusCode.Removed,
]

export const NO_TIMER_CODES = [
  StatusCode.Pause,
  StatusCode.Halftime,
  StatusCode['Awaiting extra time'],
  StatusCode['Extra time halftime'],
  StatusCode['Awaiting penalties'],
  StatusCode.Penalties,
  StatusCode['Penalties alt.'],
  StatusCode.AP,
]

export const ENDED_CODES = [StatusCode.Ended, StatusCode.AET, StatusCode.AP]

export const NO_PLAYER_ID = -1

export const FIRST_HALF = 'firstHalf'
export const SECOND_HALF = 'secondHalf'
export const FIRST_EXTRA = 'firstExtra'
export const SECOND_EXTRA = 'secondExtra'
export const BETWEEN_PERIODS = 'halftime'
export const ENDED = 'ended'
export const PENALTIES = 'penalties'
export const YELLOW_CARD = 'yellow'
export const RED_CARD = 'red'
export const SECOND_YELLOW_CARD = 'yellowRed'
export const HOME_TEAM = 'homeTeam'
export const AWAY_TEAM = 'awayTeam'
export const INJURY = 'injury'
export const REGULAR = 'regular'
export const SCORED = 'scored'
export const MISSED = 'missed'
// Window number for new goal incident
export const ADD_INCIDENT_FORM_GOAL_LENGTH = 5
// Window number for new card incident
export const ADD_INCIDENT_FORM_CARD_LENGTH = 4
// Window number for new substitution incident
export const ADD_INCIDENT_FORM_SUBSTITUTION_LENGTH = 3
// Window number for penalty shootout incident
export const ADD_INCIDENT_PENALTY_SHOOTOUT_LENGTH = 3

// First minute of first half
export const FIRST_MINUTE_FIRST_HALF = 1
