import { Box, Flex } from '@sofascore/ui'
import {
  LineupsCaptainSelector,
  LineupsTeamSelector,
  LineupsKitSelector,
  MissingPlayers,
  PlayerCounter,
  ClearAll,
  ADD_TO_LINEUP,
  REMOVE_FROM_LINEUP,
  useLineupsDispatch,
  DynamicLineupsPlayer,
  PitchLineupsProps,
  lineupPlayerKitColors,
  useLineupsState,
  LineupsFormationSelector,
  lineupFormations,
  mapFootballPositionToGroupKey,
} from 'features/AddLineups'
import { FormattedMessage } from 'react-intl'

import { MobileClickPitch } from './MobileClickPitch'
import { FootballSubstitutions } from './Substitutions'

export const MobilePitchLineups = ({
  lineupPlayers,
  otherTeamKit,
  isHomeTeam,
  activeTeamId,
  onChangeTeam,
  homeTeam,
  awayTeam,
}: PitchLineupsProps) => {
  const { homeKit, homeFormation, homeGoalkeeperKit, awayKit, awayFormation, awayGoalkeeperKit } = useLineupsState()
  const dispatch = useLineupsDispatch()

  const starters = lineupPlayers.filter(lp => lp.position && !lp.substitute)
  const substitutions = lineupPlayers.filter(lp => lp.substitute)
  const missingPlayers = lineupPlayers.filter(lp => lp.missingReason !== null)

  const availablePlayers = lineupPlayers.filter(
    lp => lp.position === null && !lp.substitute && lp.missingReason === null,
  )

  // Kit color must be different from the one that other team uses and also different from goalkeeper ones
  const availableKitColors = lineupPlayerKitColors.filter(o => o.value !== otherTeamKit)

  const handleAddToLineup = (lineupPlayer: DynamicLineupsPlayer, position: number) => {
    dispatch({ type: ADD_TO_LINEUP, payload: { lineupPlayer: lineupPlayer, position: position } })
  }

  const handleRemoveFromLineup = (lineupPlayer: DynamicLineupsPlayer) => {
    dispatch({ type: REMOVE_FROM_LINEUP, payload: { lineupPlayer: lineupPlayer } })
  }

  return (
    <Box bg="surface.s1">
      <Flex direction="column" gapY="lg" p="lg">
        <LineupsTeamSelector homeTeam={homeTeam} awayTeam={awayTeam} teamId={activeTeamId} setTeamId={onChangeTeam} />

        <Flex gapX="sm">
          <Box w="50%">
            <LineupsKitSelector options={availableKitColors} isHomeTeam={isHomeTeam} />
          </Box>
          <Box w="50%">
            <LineupsFormationSelector options={lineupFormations} isHomeTeam={isHomeTeam} />
          </Box>
        </Flex>

        <PlayerCounter starters={starters} limit={11}>
          <ClearAll isHomeTeam={isHomeTeam} message={<FormattedMessage id="football_clear_team_lineup_text" />} />
        </PlayerCounter>
      </Flex>

      <MobileClickPitch
        lineupPlayers={lineupPlayers}
        kit={isHomeTeam ? homeKit : awayKit}
        formation={isHomeTeam ? homeFormation : awayFormation}
        goalkeeperKit={isHomeTeam ? homeGoalkeeperKit : awayGoalkeeperKit}
        onDrop={handleAddToLineup}
        onRemove={handleRemoveFromLineup}
      />

      <Flex direction="column" pb="lg" px="lg">
        <Box py="lg">
          <LineupsCaptainSelector starters={starters} />
        </Box>

        <FootballSubstitutions substitutions={substitutions} availablePlayers={availablePlayers} />

        <MissingPlayers
          missingPlayers={missingPlayers}
          availablePlayers={availablePlayers}
          positionGroupMapping={mapFootballPositionToGroupKey}
        />
      </Flex>
    </Box>
  )
}
