import { useEffect, useState } from 'react'
import { Box, Flex, Text } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'
import useSWR from 'swr'
import { EventAccumulatedFouls, useEventState } from 'entities/Event'
import { StatusCode } from 'entities/Status'
import {
  DecrementAccumulatedFouls,
  DisplayAccumulatedFoulsValue,
  IncrementAccumulatedFouls,
  getAccumulatedFoulsDisplay,
  useUpdateAccumulatedFoulsHandler,
} from 'features/ChangeAccumulatedFouls'

import { eventAccumulatedFouls } from 'api/routes'
import { PERIOD1 } from 'modules/Matches/const'

export const ChangeAccumulatedFoulsSection = () => {
  const { event } = useEventState()
  const { id: eventId, status, lastPeriod, homeTeam, awayTeam } = event

  const showFirstHalfFouls = status.code !== StatusCode['1st half']

  const { handleUpdate } = useUpdateAccumulatedFoulsHandler()

  /**
   * Display values
   * Can be different from current period value because extra time sums all accumulated fouls from
   * second half of regular time and both extra halves
   */
  const [homeFoulsDisplay, setHomeFoulsDisplay] = useState(0)
  const [awayFoulsDisplay, setAwayFoulsDisplay] = useState(0)

  // Current period values are used when adding acc fouls for current period
  const [homeCurrentPeriodFouls, setHomeCurrentPeriodFouls] = useState(0)
  const [awayCurrentPeriodFouls, setAwayCurrentPeriodFouls] = useState(0)

  const [homePeriod1FoulsValue, setHomePeriod1FoulsValue] = useState<number>(0)
  const [awayPeriod1FoulsValue, setAwayPeriod1FoulsValue] = useState<number>(0)

  const [isSaving, setIsSaving] = useState(false)

  // Get all Accumulated Fouls
  const { data: eventAccumulatedFoulsData } = useSWR<EventAccumulatedFouls>(
    eventId ? eventAccumulatedFouls(eventId) : null,
    { revalidateOnFocus: false },
  )

  useEffect(() => {
    if (!eventAccumulatedFoulsData) {
      setHomeFoulsDisplay(0)
      setAwayFoulsDisplay(0)
      return
    }

    const homeFouls = getAccumulatedFoulsDisplay(eventAccumulatedFoulsData.home, status.code)
    const awayFouls = getAccumulatedFoulsDisplay(eventAccumulatedFoulsData.away, status.code)
    setHomeFoulsDisplay(homeFouls)
    setAwayFoulsDisplay(awayFouls)

    // Set last period values if needed to display above the adding section
    const homePeriod1Fouls = eventAccumulatedFoulsData?.home?.find(item => item.period === PERIOD1)?.accumulatedFouls
    const awayPeriod1Fouls = eventAccumulatedFoulsData?.away?.find(item => item.period === PERIOD1)?.accumulatedFouls
    setHomePeriod1FoulsValue(homePeriod1Fouls || 0)
    setAwayPeriod1FoulsValue(awayPeriod1Fouls || 0)

    // Set current period values
    const homeCurrentPeriodFouls = eventAccumulatedFoulsData?.home?.find(
      item => item.period === lastPeriod,
    )?.accumulatedFouls
    const awayCurrentPeriodFouls = eventAccumulatedFoulsData?.away?.find(
      item => item.period === lastPeriod,
    )?.accumulatedFouls
    setHomeCurrentPeriodFouls(homeCurrentPeriodFouls || 0)
    setAwayCurrentPeriodFouls(awayCurrentPeriodFouls || 0)
  }, [eventAccumulatedFoulsData, lastPeriod, status.code])

  const handleClick = (teamId: number, fouls: number) => {
    setIsSaving(true)

    handleUpdate({
      eventId,
      data: {
        period: lastPeriod as string,
        teamId,
        accumulatedFouls: fouls,
      },
    }).finally(() => setIsSaving(false))
  }

  return (
    <>
      <Box bg="surface.s1" p="lg" mt="sm" br="sm" textAlign="center" elevation={1}>
        <Flex direction="row" mb="xl">
          {showFirstHalfFouls && (
            <Flex w="25%">
              <Text font="body.small" color="onSurface.nLv3">
                <FormattedMessage id="1st_half" /> ({homePeriod1FoulsValue})
              </Text>
            </Flex>
          )}
          <Flex w={showFirstHalfFouls ? '50%' : '100%'} justify="center">
            <Text font="display.medium">
              <FormattedMessage id="minifootball_accumulated_fouls" />
            </Text>
          </Flex>
          {showFirstHalfFouls && (
            <Flex w="25%" justify="flex-end">
              <Text font="body.small" color="onSurface.nLv3">
                <FormattedMessage id="1st_half" /> ({awayPeriod1FoulsValue})
              </Text>
            </Flex>
          )}
        </Flex>

        <Flex justify="space-between">
          <Flex align="center" direction="row">
            <DecrementAccumulatedFouls
              onClick={() => handleClick(homeTeam.id, homeCurrentPeriodFouls - 1)}
              disabled={isSaving || !lastPeriod || homeFoulsDisplay === 0}
            />

            <DisplayAccumulatedFoulsValue accumulatedFoulsValue={homeFoulsDisplay} />

            <IncrementAccumulatedFouls
              onClick={() => handleClick(homeTeam.id, homeCurrentPeriodFouls + 1)}
              disabled={isSaving || !lastPeriod}
            />
          </Flex>

          <Flex align="center" direction="row">
            <DecrementAccumulatedFouls
              onClick={() => handleClick(awayTeam.id, awayCurrentPeriodFouls - 1)}
              disabled={isSaving || !lastPeriod || awayFoulsDisplay === 0}
            />

            <DisplayAccumulatedFoulsValue accumulatedFoulsValue={awayFoulsDisplay} />

            <IncrementAccumulatedFouls
              onClick={() => handleClick(awayTeam.id, awayCurrentPeriodFouls + 1)}
              disabled={isSaving || !lastPeriod}
            />
          </Flex>
        </Flex>
      </Box>
    </>
  )
}
