import { Theme, createStyles, makeStyles } from '@material-ui/core'

import { neutral, selectColor } from 'styles/color'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    expansionPanelDetailsRoot: {
      display: 'flex',
      paddingTop: 2,
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(3),
      },
    },
    expansionPanelSummary: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },
    },
    heading: {
      fontSize: 16,
      fontWeight: 900,
      color: theme.palette.text.primary,
    },
    expansionPanel: {
      boxShadow: 'none',
      borderBottom: `1px solid ${neutral}`,
      '&.MuiAccordion-root.Mui-expanded': {
        margin: 0,
      },
    },
    container: {
      display: 'flex',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(3),
      },
    },
    timeContainer: {
      display: 'flex',
      width: '100%',
      '& > div:first-child': {
        marginRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
          marginRight: theme.spacing(2),
        },
      },
      '& > div:nth-child(2)': {
        [theme.breakpoints.up('md')]: {
          marginRight: theme.spacing(3),
        },
      },
    },
    infoContainer: {
      width: '33.33%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: theme.spacing(2.5),
      },
    },
    infoItem: {
      backgroundColor: selectColor,
      height: '40px',
      marginTop: '8px',
    },
    title: {
      textAlign: 'left',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      color: theme.palette.text.primary,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },

    iconClose: {
      marginLeft: 'auto',
      padding: `0 ${theme.spacing(1.5)}px`,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },

    label: {
      textTransform: 'uppercase',
      fontSize: 14,
      fontWeight: 500,
      color: theme.palette.secondary.main,
      width: '33.33%',
      cursor: 'pointer',
      [theme.breakpoints.up('md')]: {
        paddingTop: 40,
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: 20,
        width: 100,
        marginLeft: 'auto',
        textAlign: 'right',
      },
    },

    boxContainer: {
      marginTop: theme.spacing(24),
      width: '618px',
      marginLeft: 'auto',
      marginRight: 'auto',

      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(18),
        width: '100%',
      },
    },
    emptyTeamsContent: {
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        paddingLeft: theme.spacing(7.5),
        paddingRight: theme.spacing(7.5),
      },
    },
    checkboxContainer: {
      display: 'flex',
      padding: `${theme.spacing(2)}px ${theme.spacing(2.5)}px`,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
      },
    },
    inputInfoLabel: {
      color: theme.palette.text.primary,
      fontSize: 12,
      fontWeight: 'normal',
      position: 'absolute',
      bottom: 18,
      [theme.breakpoints.down('sm')]: {
        bottom: 44,
      },
    },
    formControlLabel: {
      fontSize: 16,
      fontWeight: 500,
    },
    statusSelector: {
      width: '33%',
      paddingRight: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
        width: '100%',
      },
    },
  }),
)
