import { FormattedMessage } from 'react-intl'
import { Text } from '@sofascore/ui'
import { PropsWithChildren } from 'react'

import Collapse from 'components/Collapse/Collapse'

interface PlayerGroupProps {
  title: string
}

export const PlayerGroup = ({ title, children }: PropsWithChildren<PlayerGroupProps>) => {
  return (
    <Collapse
      initiallyOpen
      title={
        <Text as="div" font="display.micro" fontWeight={600} py={18}>
          <FormattedMessage id={title} />
        </Text>
      }
      alignTitle="center"
      headerStyles={{ margin: '0px 16px' }}
    >
      {children}
    </Collapse>
  )
}
