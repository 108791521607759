import { DependencyList, RefObject, useCallback, useEffect, useLayoutEffect, useState } from 'react'

export type OverflowState = [boolean | undefined, boolean | undefined, boolean | undefined, boolean | undefined]

export const useIsOverflow = (ref: RefObject<HTMLElement>, deps: DependencyList) => {
  const [isOverflow, setIsOverflow] = useState<OverflowState>([undefined, undefined, undefined, undefined])

  const trigger = useCallback(() => {
    const { current } = ref

    if (current) {
      const { x, y, width, height } = current.getBoundingClientRect()

      const hasRightOverflow = x + width > window.innerWidth
      const hasLeftOverflow = x < 0

      const hasTopOverflow = y < 0
      const hasBottomOverflow = y + height > window.innerHeight

      setIsOverflow([hasLeftOverflow, hasRightOverflow, hasTopOverflow, hasBottomOverflow])
    }
  }, [ref])

  useLayoutEffect(() => {
    const { current } = ref

    if (current) {
      if ('ResizeObserver' in window) {
        new ResizeObserver(trigger).observe(current)
      }

      trigger()
    }
  }, [ref, trigger])

  useEffect(() => {
    trigger()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps])

  return isOverflow
}
