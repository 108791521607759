import { useEffect, useRef, useState } from 'react'

const MAX_NUMBER_OF_POLLING_ATTEMPTS = 30

/**
 * Hook that triggers new render after window.google has loaded.
 * Logs an error message if window.google has not loaded in given time defined by MAX_NUMBER_OF_POLLING_ATTEMPTS.
 *
 * @returns Google object loaded with script from https://accounts.google.com/gsi/client or undefined.
 */
export const useGoogle = () => {
  const interval = useRef<NodeJS.Timer | null>(null)
  const attemptsCount = useRef<number>(0)
  const [google, setGoogle] = useState<typeof window.google>()

  const checkIfLoaded = () => {
    if (window?.google) {
      setGoogle(window.google)
    } else {
      attemptsCount.current += 1
    }
  }

  useEffect(() => {
    if (!interval?.current) {
      /** Set interval for polling only once. */
      interval.current = setInterval(checkIfLoaded, 100)
    } else if (google) {
      /** Clear interval if window.google has loaded. */
      clearInterval(interval.current)
    } else if (attemptsCount.current > MAX_NUMBER_OF_POLLING_ATTEMPTS) {
      /** Throw an error if window.google has not loaded in given number of attempts (time). */
      console.error('Failed to load window.google.')
    }

    /* Cleanup */
    return () => {
      if (interval?.current) {
        clearInterval(interval.current)
      }
    }
  }, [google])

  return google
}
