import { useParams, useSearchParams, useLocation } from 'react-router-dom'
import useSWR from 'swr'
import { useEffect, useState } from 'react'
import { Option } from 'shared/model'
import { TeamsUniqueTournamentsResponse } from 'entities/Team'
import {
  Box,
  Dropdown,
  Flex,
  GraphicEmptyStateNoData,
  GraphicEmptyStateNoEventsCalendar,
  Spinner,
  Text,
  ZIndex,
} from '@sofascore/ui'
import { useTheme } from 'styled-components'
import { FormattedMessage, useIntl } from 'react-intl'
import { BasicEvent } from 'entities/Event'
import { UniqueTournamentImage } from 'entities/UniqueTournament'

import { teamUniqueTournaments } from 'api/routes'
import { NOT_FOUND_STATUS_CODE, SERVER_ERROR_PAGE_MESSAGE } from 'utils'
import { EventList } from 'modules/Home/components/EventList'

import { useInfiniteScroll, useTeamEvents } from './hooks'
import TeamTabTitle from '../TeamTabTitle'

interface Props {
  teamId: number
  uniqueTournamentId: number
  uniqueTournamentName: string
}

const TeamMatches = ({ teamId, uniqueTournamentId, uniqueTournamentName }: Props) => {
  const location = useLocation()

  const { events, isLoading, error, loadNextPage } = useTeamEvents(teamId, uniqueTournamentId)
  const { observationTargetRef } = useInfiniteScroll(loadNextPage, isLoading)

  // Local events are used to revalidate the list without need to know exact page of the revalidated event
  const [localEvents, setLocalEvents] = useState<BasicEvent[]>()

  const handleEventDelete = (eventId: number) => {
    if (localEvents) {
      const targetIndex = localEvents.findIndex(event => event.id === eventId)
      if (targetIndex !== -1) {
        const newLocalEvents = [...localEvents]
        newLocalEvents.splice(targetIndex, 1)
        setLocalEvents(newLocalEvents)
      }
    }
  }

  useEffect(() => {
    setLocalEvents(events)
  }, [events])

  if (!localEvents && isLoading) {
    return (
      <Flex justify="center" pt={100}>
        <Spinner width="40px" />
      </Flex>
    )
  }

  if (!localEvents && error) {
    if (error.error.code === NOT_FOUND_STATUS_CODE) {
      return (
        <Flex direction="column" align="center" mt="xl">
          <Box>
            <GraphicEmptyStateNoEventsCalendar />
          </Box>

          <Text fontSize="medium" fontWeight="bold" mt="lg">
            <FormattedMessage id="no_matches" />
          </Text>

          <Text color="onSurface.nLv3" mt="lg">
            <FormattedMessage id="emptyMatches" />
          </Text>
        </Flex>
      )
    } else {
      return (
        <Flex justify="center" pt={100}>
          <Text fontSize="extraLarge">{SERVER_ERROR_PAGE_MESSAGE}</Text>
        </Flex>
      )
    }
  }

  if (!localEvents) return null

  return (
    <Box position="relative" bg="surface.s1" mt={[0, 'sm']} pb="sm" br="sm" elevation={1}>
      <Flex h={48} align="center" px="lg">
        <UniqueTournamentImage id={uniqueTournamentId} w={24} h={24} name={uniqueTournamentName} />

        <Text fontSize="small" fontWeight="bold" ml="xl" ellipsis>
          {uniqueTournamentName}
        </Text>
      </Flex>

      <EventList
        events={localEvents}
        eventLinkNavState={{ backRef: location.pathname + location.search }}
        onDelete={handleEventDelete}
      />

      {!error && (
        <Box ref={observationTargetRef} position="absolute" bottom={0} mb="sm" w="100%" zIndex={ZIndex.Popover}>
          <Flex justify="center">
            <Spinner width="20px" />
          </Flex>
        </Box>
      )}
    </Box>
  )
}

const TeamMatchesContainer = () => {
  const intl = useIntl()
  const [searchParams] = useSearchParams()

  const theme = useTheme()

  const { teamId } = useParams()

  const { data: uniqueTournamentsResponse, error: uniqueTournamentsResponseError } =
    useSWR<TeamsUniqueTournamentsResponse>(teamId ? teamUniqueTournaments(+teamId) : null)

  const [uniqueTournamentsOptions, setUniqueTournamentsOptions] = useState<Option<number, string>[]>([])
  const [selectedUniqueTournamentOption, setSelectedUniqueTournamentOption] = useState<Option<number, string>>()

  useEffect(() => {
    const uniqueTournaments = uniqueTournamentsResponse?.uniqueTournaments

    if (uniqueTournaments) {
      setUniqueTournamentsOptions(
        uniqueTournaments
          .map(ut => {
            return { value: ut.id, label: ut.name }
          })
          .sort((ut1, ut2) => ut1.label.localeCompare(ut2.label)),
      )

      const preselectedUniqueTournament = uniqueTournaments.find(uniqueTournament => {
        const queryParam = searchParams.get('uniqueTournamentId')
        return queryParam && +queryParam === uniqueTournament.id
      })
      if (preselectedUniqueTournament) {
        setSelectedUniqueTournamentOption({
          value: preselectedUniqueTournament.id,
          label: preselectedUniqueTournament.name,
        })
      }
    }
  }, [uniqueTournamentsResponse, searchParams])

  if (uniqueTournamentsResponseError) {
    if (uniqueTournamentsResponseError.error.code === 404) {
      return (
        <>
          <TeamTabTitle title={intl.formatMessage({ id: 'matches' })} />
          <Flex direction="column" align="center" mt="xxl">
            <Box>
              <GraphicEmptyStateNoData />
            </Box>

            <Text fontSize="medium" fontWeight="bold" mt="lg">
              <FormattedMessage id="team_matches_no_active_seasons" />
            </Text>

            <Text color="onSurface.nLv3" mt="lg">
              <FormattedMessage id="team_matches_no_active_seasons_description" />
            </Text>
          </Flex>
        </>
      )
    } else {
      return (
        <>
          <TeamTabTitle title={intl.formatMessage({ id: 'matches' })} />
          <Flex justify="center" pt={100}>
            <Text fontSize="extraLarge">{SERVER_ERROR_PAGE_MESSAGE}</Text>
          </Flex>
        </>
      )
    }
  }

  if (!teamId || !selectedUniqueTournamentOption)
    return (
      <>
        <TeamTabTitle title={intl.formatMessage({ id: 'matches' })} />
        <Flex justify="center" pt={100}>
          <Spinner width="40px" />
        </Flex>
      </>
    )

  return (
    <>
      <TeamTabTitle title={intl.formatMessage({ id: 'matches' })} />
      {uniqueTournamentsOptions.length > 1 && (
        <Flex pt="sm" justify="flex-end">
          <Dropdown
            items={uniqueTournamentsOptions}
            selectedItem={selectedUniqueTournamentOption}
            onSelectedItemChange={changeValue => {
              setSelectedUniqueTournamentOption(changeValue.selectedItem ?? undefined)
            }}
            renderItem={item => (item ? item.label : uniqueTournamentsResponse!.uniqueTournaments[0].name)}
            buttonStyle={{ backgroundColor: theme.colors.surface.s1 }}
          />
        </Flex>
      )}

      {selectedUniqueTournamentOption ? (
        <TeamMatches
          teamId={+teamId}
          uniqueTournamentId={selectedUniqueTournamentOption.value}
          uniqueTournamentName={selectedUniqueTournamentOption.label}
        />
      ) : (
        SERVER_ERROR_PAGE_MESSAGE
      )}
    </>
  )
}

export default TeamMatchesContainer
