import { BasicEvent } from 'entities/Event'

import { RootState } from './store'

/**
 * Returns `auth` property of the state
 */
export const getAuthFromState = (state: RootState) => state.auth || {}

export const selectEvent = (state: RootState): BasicEvent | null => state.event

export const selectAPIBaseBranch = (state: RootState): string | null => state.uicontrols.apiBaseBranch

export const selectLocale = (state: RootState): string => state.uicontrols.selectedLocale
