import { Box } from '@sofascore/ui'
import styled from 'styled-components'

export const KnockoutContainer = styled(Box)`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`
