import { useState } from 'react'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'
import { FirebaseEventName } from 'shared/lib/firebase/model'

import { IconButton } from 'components/IconButton'
import { IconPlusCircle } from 'components/Icons/IconPlusCircle'
import { iconButonDarkHover } from 'styles/color'
import AddNewCompetition from 'modules/MyCompetitions/components/AddNewCompetition'

const CompetitionRequest = () => {
  const [isOpen, setIsOpen] = useState(false)

  const closeModal = (discarded?: boolean | undefined) => {
    if (discarded) logFirebaseEvent(FirebaseEventName.DiscardEntity, { type: 'competition' })
    else logFirebaseEvent(FirebaseEventName.SubmitEntity, { type: 'competition' })
    setIsOpen(!isOpen)
  }

  const openModal = () => {
    setIsOpen(true)
    logFirebaseEvent(FirebaseEventName.AddEntity, { type: 'competition' })
  }

  return (
    <>
      <IconButton data-testid="add-competition" onClick={openModal} hoverBg={iconButonDarkHover} p="0">
        <IconPlusCircle />
      </IconButton>

      <AddNewCompetition isOpen={isOpen} handleClose={closeModal} />
    </>
  )
}

export default CompetitionRequest
