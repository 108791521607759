import { Button, Flex } from '@sofascore/ui'
import { motion } from 'framer-motion'
import styled from 'styled-components'

export const MotionButton = motion(Button)

export const FormFieldWrapper = styled(Flex)`
  position: relative;
  align-items: center;
  min-height: 48px;
  padding: 8px 16px;
  border-radius: ${p => p.theme.spacing.sm};

  box-sizing: border-box;

  border: ${p => `1px solid ${p.theme.colors.onSurface.nLv4} !important`};
  transition: border 100ms;

  &:focus-within {
    border: ${p => `1px solid ${p.theme.colors.primary.default} !important`};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: ${p => p.theme.colors.onSurface.nLv1};
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  input[type='number'] {
    appearance: textfield;
  }

  input {
    border: none;
    background-color: transparent;
    font: ${p => p.theme.typography.font.body.large};
    color: ${p => p.theme.colors.onSurface.nLv1};
    box-sizing: border-box;
    outline: none;

    overflow: hidden;
    text-overflow: ellipsis;

    /* hide file upload buttons: */
    &::file-selector-button {
      visibility: hidden;
    }
    &::-ms-browse {
      visibility: hidden;
    }
    &::-webkit-file-upload-button {
      visibility: hidden;
    }
  }
`
