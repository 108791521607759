import { RoundInfo } from 'entities/Event'
import { IntlShape } from 'react-intl'

import { formatRoundLabel } from 'translations/mappings/components/FormattedRound'
import { Option } from 'components/DropdownMenu/interface'

export const getRoundOptions = (rounds: RoundInfo[], intl: IntlShape) => {
  return (
    rounds
      // Map to dropdown options
      .map(round => {
        const isLeague = typeof round.round === 'number' && !round.slug && !round.name

        return {
          label: formatRoundLabel({ round, intl }),
          value: isLeague ? `league:${round.round}` : `cup:${round.slug}`,
        }
      })
  )
}

export const getRoundOptionFromActiveFilter = (filter: string | null, availableRounds: Option[]) => {
  if (!filter || !availableRounds) return undefined

  const option = availableRounds.find(r => r.value.toString().split(':')[1] === filter)

  return option as Option<string, string>
}
