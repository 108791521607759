// this header marks that user should refresh token for next request
export const TOKEN_REFRESH_HEADER = 'X-Token-Refresh'

export const IS_SOFA_VPN = typeof window !== 'undefined' && window.location.host.indexOf('sofavpn') !== -1

export const DEV_HOST_NAME = 'master.dev.sofascore.dev'
export const PRODUCTION_HOST_NAME = 'api.sofascore.com'

export const isDev = typeof window !== 'undefined' && window.location.host.indexOf('.dev') !== -1
export const isProd = process.env.NODE_ENV === 'production' && !isDev

export const HOST_NAME = isProd ? PRODUCTION_HOST_NAME : DEV_HOST_NAME

export const NEW_API_BASENAME = IS_SOFA_VPN ? 'https://www.sofavpn.com/api/v1' : `https://${HOST_NAME}/api/v1`

export const DEFAULT_BASE_BRANCH = 'master.dev.sofascore.dev'
