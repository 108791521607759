import { Image } from '@sofascore/ui'

import { teamImage } from 'api/routes'

import { BasicTeam, Team } from '../model'

interface TeamImageProps {
  team: Pick<Team | BasicTeam, 'id' | 'name'>
  size?: number
}

export const TeamImage = ({ team, size = 24 }: TeamImageProps) => {
  return (
    <Image
      alt={team.name}
      src={teamImage(team.id)}
      fallback={<Image src="/images/placeholders/teamLogo.png" />}
      style={{ width: size, height: size }}
    />
  )
}
