import { Team } from 'entities/Team'

export enum TeamDispatchActionType {
  SET_TEAM = 'SET_TEAM',
}

export interface TeamDispatchPayload {
  team: Team
}

export interface TeamDispatchAction {
  type: TeamDispatchActionType
  payload: TeamDispatchPayload
}

export const setTeam = (payload: TeamDispatchPayload) => {
  return { type: TeamDispatchActionType.SET_TEAM, payload }
}
