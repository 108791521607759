import { Box, Flex, Text } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'
import { EditorSuggestionAction } from 'entities/Suggestion'
import { Badge } from 'shared/ui'
import IconInfo from '@sofascore/ui/dist/modules/Icons/IconInfo'
import { Team } from 'entities/Team'

import { Tooltip } from 'components/Tooltip'
import { mapReasonToKey } from 'modules/MyCompetitions/MyCompetitionTeams/mappings'

import SuggestedPlayer from '../SuggestedPlayer'
import { getOrderedPositionGroups } from '../utils'
import { PlayerSuggestion } from '../interface'

interface Props {
  team: Team
  suggestedPlayers: PlayerSuggestion[]
}

const DeclinedPlayers = ({ team, suggestedPlayers }: Props) => {
  if (suggestedPlayers.length === 0) {
    return (
      <Flex direction="column" align="center" mt={100}>
        <Text fontSize="large" fontWeight="bold">
          <FormattedMessage id="no_declined_requests" />
        </Text>

        <Text mt="lg" align="center" color="onSurface.nLv3">
          <FormattedMessage id="team_no_declined_players" />
        </Text>
      </Flex>
    )
  }

  return (
    <Box bg="surface.s1" mt="sm" pb="sm" br="sm" elevation={1}>
      {getOrderedPositionGroups(
        team.sport.slug,
        suggestedPlayers.map(suggestion => suggestion.player),
      ).map(group => {
        return (
          <>
            <Flex key={group.positionMsgId} h={48} align="center" px="lg">
              <Text fontSize="small" fontWeight="bold">
                <FormattedMessage id={group.positionMsgId} />
              </Text>
            </Flex>

            {group.players.map(player => {
              const suggestionAction = suggestedPlayers.find(suggestion => suggestion.player.id === player.id)!.action
              const suggestionReason = suggestedPlayers.find(suggestion => suggestion.player.id === player.id)!.reason

              const isCreateSuggest = suggestionAction === EditorSuggestionAction.CREATE

              return (
                <Box key={player.id} py="sm" px="lg">
                  <SuggestedPlayer player={player}>
                    <Flex align="center">
                      {suggestionReason && (
                        <Flex mr="md">
                          <Tooltip
                            element={
                              <Flex align="center" cursor="pointer">
                                <IconInfo fill="onSurface.nLv3" />
                              </Flex>
                            }
                            label={
                              <Text color="onSurface.nLv1" lineHeight={1.4}>
                                <FormattedMessage id={mapReasonToKey[suggestionReason]} />
                              </Text>
                            }
                            placement="bottom"
                            alignment="left"
                            clickable
                            br="sm"
                          />
                        </Flex>
                      )}

                      <Badge variant={isCreateSuggest ? 'warning' : 'error'}>
                        <Text fontSize="micro" fontWeight="medium" color="inherit">
                          {isCreateSuggest ? (
                            <FormattedMessage id="suggestion_adding" />
                          ) : (
                            <FormattedMessage id="suggestion_removing" />
                          )}
                        </Text>
                      </Badge>
                    </Flex>
                  </SuggestedPlayer>
                </Box>
              )
            })}
          </>
        )
      })}
    </Box>
  )
}

export default DeclinedPlayers
