export const firebaseConfig = {
  apiKey: 'AIzaSyCXZYwhRjbIFWLeSVJCqB_Z3YfUNPd3s34',
  authDomain: 'sofascore-editor-1568268854968.firebaseapp.com',
  databaseURL: 'https://sofascore-editor-1568268854968.firebaseio.com',
  projectId: 'sofascore-editor-1568268854968',
  storageBucket: 'sofascore-editor-1568268854968.appspot.com',
  messagingSenderId: '264301378454',
  appId: '1:264301378454:web:8670a74ee1df6d4716bbb7',
  measurementId: 'G-DHPJDJ78S8',
}
