import { IconProps, IconBase } from './Base'

const IconTry = ({ fill = '#0BB32A' }: IconProps) => {
  return (
    <IconBase width={24} height={24} viewBox="0 0 24 24">
      <path
        d="m8.62 19.72 1.11-2.63 7.35-7.35 2.91-1.18 1.91-.2c.03-.28.06-.56.07-.84.1-2.1-.19-3.48-.98-4.42l-.1-.1c-1.16-.98-2.89-1.05-4.42-.98-.29.01-.57.05-.85.08l-.26 2.19-1.11 2.63-7.35 7.35-2.91 1.18-1.9.2c-.03.28-.06.55-.07.84-.01.27-.02.52-.02.78 0 1.7.31 2.83 1 3.65l.1.1C4.27 22 6 22.06 7.53 22c.29-.01.57-.05.85-.08l.26-2.19-.02-.01zm-4.99-6.25 2.13-.9 6.8-6.8.9-2.13.13-1.23c-2.7.63-5.15 1.97-7.2 3.96C4.37 8.44 3.03 10.9 2.4 13.6l1.23-.13zm16.72-2.94-2.13.9-6.79 6.79-.9 2.13-.13 1.23c2.7-.63 5.15-1.97 7.21-3.96 2.01-2.06 3.35-4.52 3.98-7.22l-1.23.13h-.01z"
        fill={fill}
        fillRule="evenodd"
      />
    </IconBase>
  )
}

export default IconTry
