import {
  IncidentGoalType,
  IncidentType,
  IncidentSubstitutionType,
  IncidentSubstitutionRegular,
  IncidentSubstitutionInjury,
  IncidentCardType,
  IncidentYellowCard,
  IncidentRedCard,
  IncidentYellowRedCard,
  IncidentSubstitutionTemporary,
  IncidentTry,
  IncidentConversion,
  IncidentDropGoal,
  IncidentPenaltyTry,
  IncidentPenaltyShootoutType,
  IncidentRugbyGoalFromPenalty,
  IncidentRugbyShootoutPenaltyScored,
  IncidentRugbyShootoutPenaltyMissed,
} from 'entities/Incident'
import { DeleteIncident } from 'features/DeleteIncident'
import {
  EditPointTry,
  EditPointConversion,
  EditPointPenalty,
  EditPointDropGoal,
  EditLastPointTry,
  EditLastPointConversion,
  EditLastPointPenalty,
  EditLastPointDropGoal,
  EditLastPointPenaltyTry,
  EditRugbyLastShootoutPenalty as EditLastShootoutPenalty,
  EditRugbyShootoutPenalty as EditShootoutPenalty,
  EditRugbyRedCard as EditRedCard,
  EditRugbyYellowCard as EditYellowCard,
  EditRugbyYellowRedCard as EditYellowRedCard,
  EditRugbySubstitutionRegular as EditSubstitutionRegular,
  EditRugbySubstitutionInjury as EditSubstitutionInjury,
  EditRugbySubstitutionTemporary as EditSubstitutionTemporary,
} from 'features/EditIncident'

import { IncidentRendererProps } from '../../model'

export const RugbyIncidentRenderer = ({ incident, isLastGoal = false, onEdit, onDelete }: IncidentRendererProps) => {
  const { incidentType, incidentClass } = incident

  const props = {
    incident,
    isLastGoal,
    onEdit,
    onDelete,
  }

  if (incidentType === IncidentType.Goal && incidentClass == IncidentGoalType.Try) {
    return (
      <IncidentTry incident={incident}>
        {isLastGoal ? <DeleteIncident {...props} /> : null}
        {isLastGoal ? <EditLastPointTry {...props} /> : <EditPointTry {...props} />}
      </IncidentTry>
    )
  }

  if (incidentType === IncidentType.Goal && incidentClass == IncidentGoalType.Conversion) {
    return (
      <IncidentConversion incident={incident}>
        {isLastGoal ? <DeleteIncident {...props} /> : null}
        {isLastGoal ? <EditLastPointConversion {...props} /> : <EditPointConversion {...props} />}
      </IncidentConversion>
    )
  }

  if (incidentType === IncidentType.Goal && incidentClass == IncidentGoalType.GoalFromPenalty) {
    return (
      <IncidentRugbyGoalFromPenalty incident={incident}>
        {isLastGoal ? <DeleteIncident {...props} /> : null}
        {isLastGoal ? <EditLastPointPenalty {...props} /> : <EditPointPenalty {...props} />}
      </IncidentRugbyGoalFromPenalty>
    )
  }

  if (incidentType === IncidentType.Goal && incidentClass == IncidentGoalType.DropGoal) {
    return (
      <IncidentDropGoal incident={incident}>
        {isLastGoal ? <DeleteIncident {...props} /> : null}
        {isLastGoal ? <EditLastPointDropGoal {...props} /> : <EditPointDropGoal {...props} />}
      </IncidentDropGoal>
    )
  }

  if (incidentType === IncidentType.Goal && incidentClass == IncidentGoalType.PenaltyTry) {
    return (
      <IncidentPenaltyTry incident={incident}>
        {isLastGoal ? <DeleteIncident {...props} /> : null}
        {isLastGoal ? <EditLastPointPenaltyTry {...props} /> : null}
      </IncidentPenaltyTry>
    )
  }

  if (incidentType === IncidentType.PenaltyShootout && incidentClass === IncidentPenaltyShootoutType.Scored) {
    return (
      <IncidentRugbyShootoutPenaltyScored incident={incident}>
        {isLastGoal ? <DeleteIncident {...props} /> : null}
        {isLastGoal ? <EditLastShootoutPenalty {...props} /> : <EditShootoutPenalty {...props} />}
      </IncidentRugbyShootoutPenaltyScored>
    )
  }

  if (incidentType === IncidentType.PenaltyShootout && incidentClass === IncidentPenaltyShootoutType.Missed) {
    return (
      <IncidentRugbyShootoutPenaltyMissed incident={incident}>
        {isLastGoal ? <DeleteIncident {...props} /> : null}
        {isLastGoal ? <EditLastShootoutPenalty {...props} /> : <EditShootoutPenalty {...props} />}
      </IncidentRugbyShootoutPenaltyMissed>
    )
  }

  if (incidentType === IncidentType.Substitution && incidentClass == IncidentSubstitutionType.Regular) {
    return (
      <IncidentSubstitutionRegular incident={incident}>
        <DeleteIncident {...props} />
        <EditSubstitutionRegular {...props} />
      </IncidentSubstitutionRegular>
    )
  }

  if (incidentType === IncidentType.Substitution && incidentClass == IncidentSubstitutionType.Injury) {
    return (
      <IncidentSubstitutionInjury incident={incident}>
        <DeleteIncident {...props} />
        <EditSubstitutionInjury {...props} />
      </IncidentSubstitutionInjury>
    )
  }

  if (incidentType === IncidentType.Substitution && incidentClass == IncidentSubstitutionType.Temporary) {
    return (
      <IncidentSubstitutionTemporary incident={incident}>
        <DeleteIncident {...props} />
        <EditSubstitutionTemporary {...props} />
      </IncidentSubstitutionTemporary>
    )
  }

  if (incidentType === IncidentType.Card && incidentClass == IncidentCardType.Yellow) {
    return (
      <IncidentYellowCard incident={incident}>
        <DeleteIncident {...props} />
        <EditYellowCard {...props} />
      </IncidentYellowCard>
    )
  }

  if (incidentType === IncidentType.Card && incidentClass == IncidentCardType.Red) {
    return (
      <IncidentRedCard incident={incident}>
        <DeleteIncident {...props} />
        <EditRedCard {...props} />
      </IncidentRedCard>
    )
  }

  if (incidentType === IncidentType.Card && incidentClass == IncidentCardType.YellowRed) {
    return (
      <IncidentYellowRedCard incident={incident}>
        <DeleteIncident {...props} />
        <EditYellowRedCard {...props} />
      </IncidentYellowRedCard>
    )
  }

  return null
}
