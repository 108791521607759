import get from 'lodash/get'
import { CustomError } from 'shared/api'

/**
 * Returns error code for Sofa API error.
 *
 * @param e Thrown error
 */
export const getErrorCode = (e: Error): number => get(e, 'error.code', get(e, 'code', 400))

export const SERVER_ERROR_PAGE_MESSAGE = 'Something went wrong'

export const MATCH_NOT_FOUND_MESSAGE = 'Match not found'

export const NOT_FOUND_PAGE_MESSAGE = 'Page Not Found'

export const TEAM_NOT_FOUND = 'Team Not Found'

export const FORBIDDEN_PAGE_MESSAGE = 'Forbidden'

export const FORBIDDEN_STATUS_CODE = 403

export const NOT_FOUND_STATUS_CODE = 404

export const SERVER_ERROR_STATUS_CODE = 500

export const INIT_FAIL_ERROR = 'idpiframe_initialization_failed'

/**
 * Returns error message id for login.
 */
export const getErrorMessageId = (userAccountId: string | null, error: { error: string | CustomError }) => {
  let errorText

  if (typeof error.error === 'string' && error.error === INIT_FAIL_ERROR) {
    errorText = 'allowCookiesToLogin'
  } else {
    const customError = error.error as CustomError
    if (customError && typeof customError.code === 'number') {
      if (customError.code === 401 && !userAccountId) {
        errorText = 'logInAgain'
      } else {
        errorText = 'toastError'
      }
    }
  }
  return errorText
}

export const isNotFoundError = (error: { error: CustomError }) => {
  return error?.error?.code === NOT_FOUND_STATUS_CODE
}
