import { Box, Flex } from '@sofascore/ui'
import { PropsWithChildren } from 'react'

export const AddIncidentSection = ({ children }: PropsWithChildren) => {
  return (
    <Box bg="surface.s1" p="lg" br="sm" textAlign="center" overflowY="auto" elevation={1}>
      <Flex minW={392} gapX="sm" justify="center">
        {children}
      </Flex>
    </Box>
  )
}
