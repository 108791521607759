import { useLocation } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { Box, Flex, Text } from '@sofascore/ui'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'
import { FirebaseEventName } from 'shared/lib/firebase/model'

import { isItemRead as checkIfItemIsRead, markItemAsRead } from 'modules/WhatsNew/utils/rss'
import { IconMegaphone } from 'components/Icons'
import { Collapse } from 'components/Collapse'

import * as S from './styles'

interface FeedItemProps {
  id: string
  title: string
  summary: string
  imgUrl?: string
  richTextContent: string
  pubDate: string
  handleArticleRead: () => void
}

const HEADER_OFFSET = 56

export const FeedItem = ({
  id,
  title,
  summary,
  imgUrl,
  richTextContent,
  pubDate,
  handleArticleRead,
}: FeedItemProps) => {
  const location = useLocation()

  const feedItemRef = useRef<HTMLDivElement>(null)
  const [isItemRead, setItemRead] = useState(checkIfItemIsRead(id))
  const [isItemExpanded, setIsItemExpanded] = useState(false)

  const scrollToTargetItemAdjusted = (element: HTMLElement) => {
    const elementPosition = element.getBoundingClientRect().top
    const offsetPosition = elementPosition - HEADER_OFFSET

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    })
  }

  const handleExpandClick = () => {
    if (!isItemExpanded) logFirebaseEvent(FirebaseEventName.OpenInfo)
    markItemAsRead(id)
    setItemRead(true)
    setIsItemExpanded(prev => !prev)
    handleArticleRead()
  }

  // Scroll to target news
  useEffect(() => {
    if (location.hash && feedItemRef.current && location.hash.replace('#', '') === id) {
      scrollToTargetItemAdjusted(feedItemRef.current)
    }
  }, [id, location, feedItemRef])

  return (
    <div ref={feedItemRef}>
      <Flex key={id} id={id} align="flex-start" mb="2px" bg="surface.s1">
        <Box p="28px">
          {imgUrl ? (
            <img src={imgUrl} width="72px" height="72px" style={{ objectFit: 'contain' }} />
          ) : (
            <S.FeedItemPlaceholder>
              <IconMegaphone fill="#000" />
            </S.FeedItemPlaceholder>
          )}
        </Box>

        <Flex direction="column" justify="space-between" grow={1}>
          <Collapse
            pt="28px"
            pr="28px"
            title={
              <Box pr={32}>
                <Text fontSize={16} fontWeight="bold">
                  {title}
                </Text>
                {!isItemRead && <Text color="primary.default" fontSize={20} ml={4}>{`\u2022`}</Text>}
              </Box>
            }
            onCollapse={handleExpandClick}
          >
            <Box dangerouslySetInnerHTML={{ __html: richTextContent }} />
          </Collapse>
          {!isItemExpanded && <S.FeedItemSummary>{summary}</S.FeedItemSummary>}

          <S.FeedItemPubDate mt="lg" mb="lg">
            {pubDate}
          </S.FeedItemPubDate>
        </Flex>
      </Flex>
    </div>
  )
}
