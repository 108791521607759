import { DeleteIncidentData, IncidentType } from 'entities/Incident'
import { mutate } from 'swr'
import { useSelector } from 'react-redux'
import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useToast } from 'shared/lib'
import { useErrorHandler } from 'shared/hooks'

import { deleteIncident } from 'api'
import { getAuthFromState } from 'store/selectors'
import { eventDetails, getIncidents } from 'api/routes'

export const useLiveMatchDeleteIncidentHandler = () => {
  const { userAccount } = useSelector(getAuthFromState)
  const { enqueueToast } = useToast()
  const intl = useIntl()
  const handleError = useErrorHandler()

  const handleDelete = useCallback(
    async (data: DeleteIncidentData) => {
      if (!userAccount) return

      try {
        const { eventId, incidentType } = data
        const isGoal = incidentType === IncidentType.Goal || incidentType === IncidentType.PenaltyShootout

        await deleteIncident(data, isGoal)

        enqueueToast(intl.formatMessage({ id: 'alert_detail_deleted' }), { variant: 'success' })
        mutate(getIncidents(eventId))

        if (isGoal) {
          mutate(eventDetails(eventId))
        }
      } catch (e) {
        handleError(e)
      }
    },
    [intl, userAccount, handleError, enqueueToast],
  )

  return { handleDelete }
}
