import useSWR from 'swr'

import { editorEvents } from 'api/routes'
import { EditorEventsResponse } from 'modules/TournamentMatches/interface'
import { getYYYYMMDDFormat, isToday } from 'utils/time'

export default function useEditorEvents(date: Date) {
  const { data, isLoading, error, mutate } = useSWR<EditorEventsResponse>(
    editorEvents(!isToday(date) ? { date: getYYYYMMDDFormat(date) } : {}),
  )

  return {
    data,
    isLoading,
    error,
    mutate,
  }
}
