import { useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useEventState } from 'entities/Event'
import { mutate } from 'swr'
import { useToast } from 'shared/lib'
import { useErrorHandler } from 'shared/hooks'
import { useLineupsDispatch, useLineupsState, RESET } from 'features/AddLineups'
import { FirebaseEventName, FirebaseEventType } from 'shared/lib/firebase/model'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'

import { createLineups } from 'api'
import { Button } from 'components/Button'
import { getLineups } from 'api/routes'

import { isListLineupValid } from '../utils'
import { createFootballLineupsDto } from '../../Football/utils'

export const SaveLineups = () => {
  const intl = useIntl()
  const dispatch = useLineupsDispatch()
  const state = useLineupsState()
  const handleError = useErrorHandler()

  const { homeTeam, homeLineupPlayers, awayTeam, awayLineupPlayers } = state
  const { event } = useEventState()
  const { enqueueToast } = useToast()

  const [isSaving, setIsSaving] = useState(false)

  const firebasePayload = useMemo(() => {
    return {
      type: FirebaseEventType.Lineup,
      sport: event?.tournament?.category.sport.slug,
      status: event?.status?.type,
      id: event?.id,
    }
  }, [event])

  const saveLineups = () => {
    if (event) {
      const payload = createFootballLineupsDto(event.id, homeLineupPlayers, awayLineupPlayers, homeTeam, awayTeam, null)

      setIsSaving(true)

      createLineups(event.id, payload)
        .then(() => {
          dispatch({ type: RESET })
          mutate(getLineups(event.id), undefined)
          enqueueToast(intl.formatMessage({ id: 'toastSuccess' }), { variant: 'success' })
          logFirebaseEvent(FirebaseEventName.SubmitEntity, firebasePayload)
        })
        .catch(e => {
          handleError(e)
        })
        .finally(() => {
          setIsSaving(false)
        })
    }
  }

  return (
    <Button
      variant="contained"
      onClick={saveLineups}
      isLoading={isSaving}
      disabled={!isListLineupValid(state)}
      style={{ padding: '14px 64px' }}
    >
      <FormattedMessage id="save_all_changes" />
    </Button>
  )
}
