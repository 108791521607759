import { ChangeEvent, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Flex, Box, Text, Spinner } from '@sofascore/ui'
import { useToast } from 'shared/lib'
import { useParams } from 'react-router-dom'
import { Team, TeamCard } from 'entities/Team'
import { Checkbox, Search } from 'shared/ui'

import { getAuthFromState } from 'store/selectors'
import { Button } from 'components/Button'
import { useMyCompetitionsState } from 'modules/MyCompetitions/providers/MyCompetitionsProvider'
import { addTeams } from 'modules/Teams/api/repository'

import { useExistingTeams } from '../hooks/useExistingTeams'
import { AddTeamFormProps } from '../model'

export const AddExistingTeamForm = ({ tournamentId, onSave, onClose }: AddTeamFormProps) => {
  const intl = useIntl()

  const { userAccount } = useSelector(getAuthFromState)
  const { enqueueToast } = useToast()
  const { uniqueTournament } = useMyCompetitionsState()
  const { seasonId, competitionId } = useParams()
  const { teams, revalidate } = useExistingTeams(tournamentId)

  const [query, setQuery] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [checkedTeamIds, setCheckedTeamIds] = useState<number[]>([])

  const handleCheckTeam = (e: ChangeEvent<HTMLInputElement>, team: Team) => {
    if (e.target.checked) {
      setCheckedTeamIds(previousTeams => [...previousTeams, team.id])
    } else {
      setCheckedTeamIds(previousTeams => previousTeams.filter(t => t !== team.id))
    }
  }

  const handleAddCheckedTeams = async () => {
    if (userAccount && seasonId && uniqueTournament) {
      setIsSaving(true)

      addTeams(uniqueTournament.id, tournamentId, +seasonId, { teamIds: checkedTeamIds })
        .then(() => {
          enqueueToast(
            intl.formatMessage({ id: 'teamsAdded' }, { teamsOrPlayers: intl.formatMessage({ id: 'teams' }) }),
            { variant: 'success' },
          )
          revalidate()
          onSave()
          onClose()
        })
        .catch(e => {
          console.error(e)
          enqueueToast(intl.formatMessage({ id: 'toastError' }), { variant: 'error' })
        })
        .finally(() => setIsSaving(false))
    }
  }

  const isFormValid = competitionId && seasonId && tournamentId && checkedTeamIds.length

  const filteredTeams = useMemo(() => {
    return teams?.filter(t => {
      return t.name.toLocaleLowerCase().includes(query.trim().toLocaleLowerCase())
    })
  }, [teams, query])

  return (
    <Box>
      <Flex direction="column" align="center" gapY="lg" px="lg">
        <Box w="100%">
          <Search label={`${intl.formatMessage({ id: 'search' })}...`} value={query} onChange={setQuery} />
        </Box>
      </Flex>

      <Flex justify="right" mt="lg" mb="sm" px="lg">
        <Text>
          <FormattedMessage id="selected_teams_counter" values={{ n: checkedTeamIds.length }} />
        </Text>
      </Flex>

      <Flex direction="column" h={336} overflowY="auto">
        {filteredTeams ? (
          filteredTeams
            .sort((t1, t2) => t1.name.localeCompare(t2.name))
            .map(t => (
              <TeamCard key={t.id} team={t} px="lg" py="sm">
                <Checkbox
                  name={`team-${t.id}`}
                  checked={checkedTeamIds.includes(t.id)}
                  onChange={e => handleCheckTeam(e, t)}
                />
              </TeamCard>
            ))
        ) : (
          <Spinner width="48px" />
        )}
      </Flex>

      <Flex gapX="lg" p="xl">
        <Button variant="outlined" fullWidth onClick={onClose}>
          <FormattedMessage id="cancel" />
        </Button>
        <Button
          variant="contained"
          fullWidth
          onClick={handleAddCheckedTeams}
          isLoading={isSaving}
          disabled={!isFormValid}
        >
          <FormattedMessage id="confirm" />
        </Button>
      </Flex>
    </Box>
  )
}
