import { useCallback, ChangeEvent } from 'react'
import { Flex, Text, Box } from '@sofascore/ui'

import * as S from './styles'
import {
  SET_HOME_TEAM_SCORE,
  SET_AWAY_TEAM_SCORE,
  useCreateMatchDispatch,
  useCreateMatchState,
} from './../context/createMatchContext'
import CreateMatchTeamLabel from '../CreateMatchTeamLabel'
import CreateMatchTeamImage from '../CreateMatchTeamImage'

const Results = () => {
  const { homeTeam, awayTeam, homeTeamScore, awayTeamScore, homeSubTeam1, homeSubTeam2, awaySubTeam1, awaySubTeam2 } =
    useCreateMatchState()
  const dispatch = useCreateMatchDispatch()

  const handleInputValue = useCallback(
    (ce: ChangeEvent<HTMLInputElement>, dispatchAction: 'SET_HOME_TEAM_SCORE' | 'SET_AWAY_TEAM_SCORE') => {
      const value = ce.target.value
      if (value.match(/^[0-9]{0,3}$/)) {
        if (Number(value) === 0) {
          dispatch({ type: dispatchAction, payload: 0 })
        } else {
          dispatch({ type: dispatchAction, payload: value.replace(/^0+/, '') })
        }
      }
    },
    [dispatch],
  )

  return (
    <>
      <Flex mx="lg" mt="sm" wrap="nowrap" justify="space-between">
        <S.LabelHolder>
          <CreateMatchTeamLabel team={homeTeam} subTeam1={homeSubTeam1} subTeam2={homeSubTeam2} side="left" />
        </S.LabelHolder>
        <S.LabelHolder away>
          <CreateMatchTeamLabel team={awayTeam} subTeam1={awaySubTeam1} subTeam2={awaySubTeam2} side="right" />
        </S.LabelHolder>
      </Flex>
      <Flex m="lg" mt={0} wrap="nowrap" align="end" justify="center">
        <Box w="100%">
          <CreateMatchTeamImage team={homeTeam} subTeam1={homeSubTeam1} subTeam2={homeSubTeam2} side="left" />
        </Box>
        <S.ResultInput
          value={homeTeamScore?.toString()}
          onChange={ce => handleInputValue(ce, SET_HOME_TEAM_SCORE)}
          onClick={fe => (fe.target as HTMLInputElement).select()}
        />
        <Text mx="xs" fontSize={28}>
          -
        </Text>
        <S.ResultInput
          away
          value={awayTeamScore?.toString()}
          onChange={ce => handleInputValue(ce, SET_AWAY_TEAM_SCORE)}
          onClick={fe => (fe.target as HTMLInputElement).select()}
        />
        <Box w="100%" textAlign="right">
          <CreateMatchTeamImage team={awayTeam} subTeam1={awaySubTeam1} subTeam2={awaySubTeam2} side="right" />
        </Box>
      </Flex>
    </>
  )
}

export default Results
