import { Box, Flex } from '@sofascore/ui'
import { useCallback, useEffect, useRef, useState } from 'react'
import { AFTER_PENALTIES_CODE, ENDED_CODE, NOT_STARTED_CODE } from 'entities/Status'
import { Incident, IncidentType, useEventIncidentsState, usePostMatchUpdateHandler } from 'entities/Incident'
import {
  AddTwoMinuteSuspension,
  AddMiniFootballRedCard as AddRedCard,
  AddMiniFootballShootoutPenalty as AddShootoutPenalty,
  AddMiniFootballYellowCard as AddYellowCard,
  AddMiniFootballMissedPenalty as AddMissedPenalty,
  usePostMatchCreateIncidentHandler,
} from 'features/AddIncident'
import { EventActionType, useEventDispatch, useEventState } from 'entities/Event'
import { AddMiniFootballGoalForm } from 'features/AddScore'
import { usePostMatchEditIncidentHandler } from 'features/EditIncident'
import { usePostMatchDeleteIncidentHandler } from 'features/DeleteIncident'
import { GameTimeToggle } from 'widgets/GameTimeToggle'
import { ChangeScoreSection } from 'widgets/ChangeScoreSection'
import { AddIncidentSection } from 'widgets/AddIncidentSection'
import { IncidentListSection } from 'widgets/IncidentListSection'
import { PenaltyShootoutSection } from 'widgets/PenaltyShootoutSection'
import { MiniFootballIncidentRenderer, useIncidentExtender } from 'widgets/IncidentRenderer'
import { ChangeResultOnlyMode } from 'widgets/ChangeResultOnlyMode'
import { ChangeScoreSectionFRO } from 'widgets/ChangeScoreSectionFRO'
import { MatchEndedStatusPicker } from 'widgets/MatchEndedStatusPicker'
import { PenaltyShootoutSectionFRO } from 'widgets/PenaltyShootoutSectionFRO'
import { SaveAllChanges } from 'widgets/SaveAllChanges'

import { PeriodTabs } from '../../model'
import { useResultOnlyMode } from '../../hooks'
import * as S from '../styles'

const defaultNumberOfPenaltySeries = 5

export const MiniFootballEditPostMatch = () => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [resultOnlyMode, toggleResultOnlyMode] = useResultOnlyMode()

  const { event, hasChanges: hasEventChanges } = useEventState()
  const eventDispatch = useEventDispatch()

  const { incidents, hasChanges: hasIncidentChanges } = useEventIncidentsState()

  const { handleCreate } = usePostMatchCreateIncidentHandler()
  const { handleEdit } = usePostMatchEditIncidentHandler()
  const { handleDelete } = usePostMatchDeleteIncidentHandler()
  const { handleUpdate } = usePostMatchUpdateHandler()

  const { id: eventId, status, homeScore, awayScore, aggregatedWinnerCode } = event

  const [periodTab, setPeriodTab] = useState<PeriodTabs>(PeriodTabs.FT)

  // Remove Penalty Shootout incidents from list
  const normalTimeIncidents = incidents
    ? incidents.filter(incident => incident.incidentType !== IncidentType.PenaltyShootout && incident.text !== 'PEN')
    : []

  const penaltyShootoutIncidents = incidents
    ? incidents.filter(incident => incident.incidentType === IncidentType.PenaltyShootout)
    : []

  const onSaveAllChanges = useCallback(async () => {
    await handleUpdate({
      eventId,
      data: {
        statusCode: status.code,
        homeScore,
        awayScore,
        incidents: incidents as Incident[],
        aggregatedWinnerCode,
      },
    })
  }, [handleUpdate, eventId, status.code, homeScore, awayScore, incidents, aggregatedWinnerCode])

  useEffect(() => {
    if (status.code === AFTER_PENALTIES_CODE) {
      setPeriodTab(PeriodTabs.Penalties)
    }
    // If match status is not started, set status to ended after regular time
    if (status.code === NOT_STARTED_CODE) {
      eventDispatch({ type: EventActionType.UPDATE_STATUS_CODE, payload: ENDED_CODE })
    }
  }, [status.code, eventDispatch])

  useIncidentExtender()

  return (
    <Box ref={containerRef} position="relative">
      <Flex mt="sm" gapY="sm" direction="column" pb={hasIncidentChanges ? '106px' : 'sm'}>
        <S.OptionsContainer>
          <MatchEndedStatusPicker
            onSave={(statusCode, winnerCode) => {
              eventDispatch({ type: EventActionType.UPDATE_STATUS_CODE, payload: statusCode })
              eventDispatch({ type: EventActionType.UPDATE_WINNER_CODE, payload: winnerCode })
            }}
          />
          <ChangeResultOnlyMode resultOnlyMode={resultOnlyMode} setResultOnlyMode={toggleResultOnlyMode} />
        </S.OptionsContainer>

        {status.code === AFTER_PENALTIES_CODE && (
          <GameTimeToggle
            isPenaltyShootout={periodTab === PeriodTabs.Penalties}
            onRegularTimeButtonClick={() => setPeriodTab(PeriodTabs.FT)}
            onPenaltyShootoutButtonClick={() => setPeriodTab(PeriodTabs.Penalties)}
          />
        )}

        {status.code === AFTER_PENALTIES_CODE && !resultOnlyMode && periodTab === PeriodTabs.Penalties && (
          <>
            <PenaltyShootoutSection numberOfPenaltySeries={defaultNumberOfPenaltySeries}>
              {(home, homeTeam, away, awayTeam, sequence) => (
                <>
                  <AddShootoutPenalty team={homeTeam} sequence={sequence} {...home} onSubmit={handleCreate} />
                  <AddShootoutPenalty team={awayTeam} sequence={sequence} {...away} onSubmit={handleCreate} />
                </>
              )}
            </PenaltyShootoutSection>

            <Flex direction="column" gapY="sm" mt="sm">
              {penaltyShootoutIncidents.map((pen, index) => (
                <MiniFootballIncidentRenderer
                  key={pen.id}
                  incident={pen}
                  isLastGoal={index === 0}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                />
              ))}
            </Flex>
          </>
        )}

        {status.code === AFTER_PENALTIES_CODE && resultOnlyMode && periodTab === PeriodTabs.Penalties && (
          <PenaltyShootoutSectionFRO />
        )}

        {(status.code === AFTER_PENALTIES_CODE && resultOnlyMode && periodTab === PeriodTabs.FT) ||
        (status.code !== AFTER_PENALTIES_CODE && resultOnlyMode) ? (
          <ChangeScoreSectionFRO />
        ) : null}

        {(status.code !== AFTER_PENALTIES_CODE && !resultOnlyMode) ||
        (status.code === AFTER_PENALTIES_CODE && !resultOnlyMode && periodTab === PeriodTabs.FT) ? (
          <>
            <ChangeScoreSection>
              {(isOpen, toggleOpen, team, event) => (
                <AddMiniFootballGoalForm
                  key={isOpen.toString()}
                  isOpen={isOpen}
                  team={team}
                  event={event}
                  onSubmit={handleCreate}
                  onClose={toggleOpen}
                />
              )}
            </ChangeScoreSection>

            <AddIncidentSection>
              <AddTwoMinuteSuspension onCreate={handleCreate} />
              <AddYellowCard onCreate={handleCreate} />
              <AddRedCard onCreate={handleCreate} />
              <AddMissedPenalty onCreate={handleCreate} />
            </AddIncidentSection>

            <IncidentListSection incidents={normalTimeIncidents}>
              {(incident, isLastGoal) => (
                <MiniFootballIncidentRenderer
                  key={incident.id}
                  incident={incident}
                  isLastGoal={isLastGoal}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                />
              )}
            </IncidentListSection>
          </>
        ) : null}
      </Flex>

      {(hasEventChanges || hasIncidentChanges) && (
        <SaveAllChanges onSubmit={onSaveAllChanges} parentRef={containerRef} />
      )}
    </Box>
  )
}
