import { useCallback } from 'react'
import { useRouteLoaderData } from 'react-router-dom'
import {
  PermissionAction,
  PermissionScope,
  PermissionGeneral,
  PermissionCheckType,
  EntityAllowedActions,
} from 'entities/Permission'

export const defaultScopes: Array<PermissionScope> = [
  PermissionScope.UniqueTournament,
  PermissionScope.Tournament,
  PermissionScope.Season,
  PermissionScope.Event,
  PermissionScope.Team,
  PermissionScope.Player,
  PermissionScope.Standings,
  PermissionScope.CupTree,
  PermissionScope.MatchReport,
] as const

export const defaultEntityAllowedActions: EntityAllowedActions = {
  this: [PermissionAction.Create, PermissionAction.Read, PermissionAction.Update, PermissionAction.Delete],
} as const

export const defaultActionsToCheck: Array<PermissionAction> = [
  PermissionAction.Create,
  PermissionAction.Read,
  PermissionAction.Update,
  PermissionAction.Delete,
] as const

const derivatePermissionValues = (
  accu: Array<number> | boolean,
  key: string,
  activePermissions: PermissionGeneral,
  scope: PermissionScope,
  action: PermissionAction,
  entityScope: PermissionScope,
) => {
  const keySplit = key.split('_')
  // Destructure keySplit array to get 'keyAction', 'for', and 'keyScope'
  const [keyAction, , keyScope] = keySplit

  // If key is only the action name (e.g., "update", "read", "delete"),
  // it means it has all permissions and its value is true
  if (keySplit.length === 1 && keyAction === action) {
    accu = activePermissions?.[scope]?.[key]
  }

  // If key has the action and scope, retrieve its value (list of entity IDs)
  if (keyAction === action && keyScope === entityScope) {
    accu = activePermissions?.[scope]?.[key]
  }

  return accu
}

export function usePermissions(loaderCtxName: string = 'competitionRoutes') {
  //  Gets active permissions from unique tournament enpoint specific to user
  const activePermissions = useRouteLoaderData(loaderCtxName) as PermissionGeneral

  //  gets all active permission scopes and checks intersection with provided scope in Guard component
  const checkScopeAndScopeActions = useCallback(
    (activePermissions: PermissionGeneral, entityScopes: Array<PermissionScope>, actions: Array<PermissionAction>) => {
      //  check if all data required for Guard check is available
      if (activePermissions && entityScopes && actions) {
        //  get all scopes from active season permissions
        const activePermissionsScope = Object.keys(activePermissions) as Array<PermissionScope>

        //  intersection within active permission scope and provided scope in Guard component
        const intersectingScopes = entityScopes && activePermissionsScope.filter(value => entityScopes.includes(value))
        const intersectingScopesActions = intersectingScopes?.reduce(
          (acc: PermissionGeneral, scopeKey: PermissionScope) => {
            acc[scopeKey] = activePermissions[scopeKey]
            return acc
          },
          {} as PermissionGeneral,
        )
        //  checking scope
        const checkScope = entityScopes && activePermissionsScope.some(value => entityScopes.includes(value))
        //  checking actions
        const checkActions = intersectingScopes?.some(scope => {
          return Object.keys(intersectingScopesActions[scope]).some(activeScopeAction =>
            actions.some(
              prefix => activeScopeAction.startsWith(prefix) && !!intersectingScopesActions[scope][activeScopeAction],
            ),
          )
        })

        return checkScope && checkActions
      }
      return false
    },
    [],
  )

  //  checking intersection of entity allowed actions and action
  const checkEntityActions = useCallback(
    (
      entityAllowedActions: EntityAllowedActions,
      actions: Array<PermissionAction>,
      scope: PermissionScope = PermissionScope.This,
    ) => {
      return entityAllowedActions[scope]?.some(entityAction => actions.includes(entityAction)) || false
    },
    [],
  )

  //  If guards return true it means user has permissions
  const guardScope = (entityScopes?: Array<PermissionScope>, actions?: Array<PermissionAction>) =>
    checkScopeAndScopeActions(activePermissions, entityScopes || defaultScopes, actions || defaultActionsToCheck)

  const guardEntity = (
    entityAllowedActions?: EntityAllowedActions,
    actions?: Array<PermissionAction>,
    scope?: PermissionScope,
  ) => checkEntityActions(entityAllowedActions || defaultEntityAllowedActions, actions || defaultActionsToCheck, scope)

  /*  
    entityPermissionCheck('event', 'delete', 'team', [ 1234, 2345, 3456 ], 'exact')
    - returns boolean
    - boolen 'true' - has action permission for all entities provided
    - boolean 'false' - has no action permission for any entity
    - The checkType parameter distinguishes between two types of checks:
    - - Partial Check: This type of check determines whether at least one of the entity IDs is valid.
    - - Exact Check: This type of check requires all entity IDs to be valid for the check to be successful.
  */
  const entityPermissionCheck = useCallback(
    (
      scope: PermissionScope,
      action: PermissionAction,
      entityScope: PermissionScope,
      entities: Array<number>,
      checkType: PermissionCheckType = PermissionCheckType.Partial,
    ) => {
      let check: boolean | Array<number> = false

      if (activePermissions?.[scope]) {
        const permissionKeys = Object.keys(activePermissions[scope])
        check = permissionKeys.reduce(
          (accu, key) => derivatePermissionValues(accu, key, activePermissions, scope, action, entityScope),
          check as Array<number> | boolean,
        )
      }

      if (Array.isArray(check)) {
        if (checkType === 'partial') return check.some(value => entities.includes(value))
        //  Exact - every entity ID needs to be available on permissions
        return entities.every(value => check.includes(value))
      }

      return check
    },
    [activePermissions],
  )

  /*  
    entityPermissionGet('event', 'delete', 'team')
    - returns entity Array<entity ID> or boolean
    - array of entity ID's means that has action permissions for that entities
    - boolen 'true' - has action permission for all entities
    - boolean 'false' - has no action permission for any entity
  */
  const entityPermissionGet = useCallback(
    (scope: PermissionScope, action: PermissionAction, entityScope: PermissionScope) => {
      let check: boolean | Array<number> = false

      if (activePermissions && activePermissions[scope]) {
        const permissionKeys = Object.keys(activePermissions[scope])
        check = permissionKeys.reduce(
          (accu, key) => derivatePermissionValues(accu, key, activePermissions, scope, action, entityScope),
          check as Array<number> | boolean,
        )
      }

      return check
    },
    [activePermissions],
  )

  //  it returns all permissions for current active permission loader
  const getGeneralPermissions = () => activePermissions

  // If there is no permissions for current active permission loader returns false
  const permissionsLoaded = !!activePermissions

  return {
    guardScope,
    guardEntity,
    permissionsLoaded,
    entityPermissionCheck,
    entityPermissionGet,
    getGeneralPermissions,
  }
}
