import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Flex, Text } from '@sofascore/ui'
import { useLocation } from 'react-router-dom'

import { EventList } from 'modules/Home/components/EventList'
import { FormattedRound } from 'translations/mappings/components/FormattedRound'

import { EventGroup } from '../interface'
import { GROUP_HEADER_HEIGHT, GROUP_SUBHEADER_HEIGHT } from './config'
import { unregisterRevalitedEvent } from '../CompetitionMatchesContext/actions'
import { NoCreatedEvents } from '../EmptyStates'

interface Props {
  eventGroups: EventGroup[]
}

const EventGroupList = ({ eventGroups }: Props) => {
  const location = useLocation()
  const [revalidatedEventGroups, setRevalidatedEventGroups] = useState(eventGroups)

  const removeEventFromList = (eventId: number) => {
    unregisterRevalitedEvent(eventId)

    const newEventGroups = [...eventGroups]

    // Remove the whole group if the group contains one subgroup and that subgroup contains the target to remove
    let groupIndexToRemove = -1

    // Remove the subgroup if that subgroup only contains the target
    const subgroupIndexToRemove = [-1, -1] // Group and subgroup indices

    newEventGroups.forEach((group, groupIndex) => {
      group.subgroups.forEach((subgroup, subgroupIndex) => {
        // If target event exists
        const targetEventIndex = subgroup.events.findIndex(event => event.id === eventId)
        if (targetEventIndex !== -1) {
          // If subgroup contains only target event
          if (subgroup.events.length === 1) {
            subgroupIndexToRemove[0] = groupIndex
            subgroupIndexToRemove[1] = subgroupIndex

            if (group.subgroups.length === 1) {
              groupIndexToRemove = groupIndex
            }
          }
          // Else safely remove it
          else {
            subgroup.events.splice(targetEventIndex, 1)
          }
        }
      })
    })

    if (groupIndexToRemove !== -1) {
      newEventGroups.splice(groupIndexToRemove, 1)
    } else if (subgroupIndexToRemove[0] !== -1) {
      newEventGroups[subgroupIndexToRemove[0]].subgroups.splice(subgroupIndexToRemove[1], 1)
    }

    setRevalidatedEventGroups(newEventGroups)
  }

  useEffect(() => {
    setRevalidatedEventGroups(eventGroups)
  }, [eventGroups])

  if (!revalidatedEventGroups.length) {
    return <NoCreatedEvents />
  }

  return (
    <>
      {revalidatedEventGroups.map(eventGroup => {
        const lastSubgroupEvents = eventGroup.subgroups[eventGroup.subgroups.length - 1].events
        const lastGroupEventId = lastSubgroupEvents[lastSubgroupEvents.length - 1].id
        const groupKey = `${eventGroup.title}-${lastGroupEventId}`

        return (
          <Box key={groupKey} py="sm" br="sm" bg="surface.s1" overflow="hidden" elevation={2}>
            {eventGroup.isHeaderDisplayed && (
              <Flex h={GROUP_HEADER_HEIGHT} justify="space-between" align="center" px="lg">
                <Text color="onSurface.nLv1" fontWeight="medium" fontSize="small">
                  {eventGroup.roundInfo ? <FormattedRound round={eventGroup.roundInfo} /> : eventGroup.title}
                </Text>

                <Text color="onSurface.nLv3" fontSize="small">
                  {eventGroup.eventCount} <FormattedMessage id="events" />
                </Text>
              </Flex>
            )}

            {eventGroup.subgroups.map(subgroup => {
              return (
                <Box key={subgroup.title}>
                  {subgroup.isSubheaderDisplayed && (
                    <Flex h={GROUP_SUBHEADER_HEIGHT} justify="space-between" align="center">
                      <Text color="onSurface.nLv3" fontSize="small" px="lg">
                        {subgroup.roundInfo ? <FormattedRound round={subgroup.roundInfo} /> : subgroup.title}
                      </Text>
                    </Flex>
                  )}

                  <EventList
                    events={subgroup.events}
                    onDelete={removeEventFromList}
                    eventLinkNavState={{ backRef: location.pathname + location.search }}
                  />
                </Box>
              )
            })}
          </Box>
        )
      })}
    </>
  )
}

export default EventGroupList
