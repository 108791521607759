import { Box, Flex, Text } from '@sofascore/ui'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import useSWR from 'swr'
import { StatusType } from 'entities/Status'
import { CompetitionType } from 'entities/Tournament'
import { useEventState } from 'entities/Event'
import { PermissionAction } from 'entities/Permission'
import { usePermissions } from 'shared/hooks'

import ResponsivePopUp from 'components/ResponsivePopUp'
import { eventDetails } from 'api/routes'
import { formatTournamentRound } from 'translations/mappings/components/FormattedRound'

import {
  PropertyButton,
  UpdateRoundForm,
  UpdateStartDateForm,
  UpdateStartTimeForm,
  UpdateCityForm,
  UpdateVenueForm,
} from './components'

enum MatchProperty {
  Date,
  Time,
  Round,
  City,
  Venue,
}

interface Props {
  hideLocation?: boolean
}

const MatchProperties = ({ hideLocation }: Props) => {
  const intl = useIntl()
  const { initialEvent, event } = useEventState()
  const { id, tournament, startTimestamp, roundInfo, status, editorEventCity, editorEventVenue } = initialEvent
  const { mutate: mutateEventDetails } = useSWR(eventDetails(id))
  const { guardEntity } = usePermissions()

  const [isPopUpOpen, setPopUpOpen] = useState(false)
  const [selectedProperty, setSelectedProperty] = useState<MatchProperty | null>(null)

  const matchInfoUpdateDisabled =
    status.type !== StatusType.NOT_STARTED || !guardEntity(event.allowedActions, [PermissionAction.Update])

  const isLeague = tournament.competitionType === CompetitionType.League

  const togglePopUp = () => setPopUpOpen(!isPopUpOpen)

  const handlePropertyClick = (property: MatchProperty) => {
    setSelectedProperty(property)
    setPopUpOpen(true)
  }

  const handlePopUpClose = () => {
    setPopUpOpen(false)
  }

  const revalidateEvent = () => {
    mutateEventDetails()
  }

  return (
    <Box id="match-properties-container">
      <Flex mt="sm" direction="column" gapY="sm">
        <Flex gapX={10}>
          <Box w="calc(50% - 5px)">
            <PropertyButton
              label={intl.formatMessage({ id: 'date' })}
              w="100%"
              value={intl.formatDate(startTimestamp * 1000, { dateStyle: 'medium' })}
              onClick={() => handlePropertyClick(MatchProperty.Date)}
              disabled={matchInfoUpdateDisabled}
            />
          </Box>

          <PropertyButton
            label={intl.formatMessage({ id: 'time' })}
            w="calc(50% - 5px)"
            value={intl.formatTime(startTimestamp * 1000, { hour12: false })}
            onClick={() => handlePropertyClick(MatchProperty.Time)}
            disabled={matchInfoUpdateDisabled}
          />
        </Flex>

        {isLeague && roundInfo && (
          <Box cursor="pointer">
            <PropertyButton
              label={intl.formatMessage({ id: 'round' })}
              w="calc(50% - 5px)"
              value={roundInfo.round}
              onClick={() => handlePropertyClick(MatchProperty.Round)}
              disabled={matchInfoUpdateDisabled}
            />
          </Box>
        )}

        {!isLeague && roundInfo?.name && (
          <Box cursor="pointer">
            <PropertyButton
              label={intl.formatMessage({ id: 'stage' })}
              w="calc(50% - 5px)"
              value={formatTournamentRound(roundInfo.name, intl)}
              onClick={() => handlePropertyClick(MatchProperty.Round)}
              disabled={matchInfoUpdateDisabled}
            />
          </Box>
        )}

        {!hideLocation && (
          <>
            <Text mt="lg">
              <FormattedMessage id="location" />
            </Text>

            <Flex gapX={[0, 10]} gapY={[10, 0]} direction={['column', 'row']} w="100%" mt="lg">
              <PropertyButton
                label={intl.formatMessage({ id: 'city' })}
                value={editorEventCity || ''}
                w="100%"
                onClick={() => handlePropertyClick(MatchProperty.City)}
                disabled={matchInfoUpdateDisabled}
              />
              <PropertyButton
                label={intl.formatMessage({ id: 'venue' })}
                value={editorEventVenue || ''}
                w="100%"
                ml={[0, '10px !important']}
                onClick={() => handlePropertyClick(MatchProperty.Venue)}
                disabled={matchInfoUpdateDisabled}
              />
            </Flex>
          </>
        )}

        <ResponsivePopUp
          isOpen={isPopUpOpen}
          onClose={togglePopUp}
          heading={
            <Text fontWeight="bold" fontSize="large">
              {selectedProperty === MatchProperty.Date ? (
                <FormattedMessage id="changeDate" />
              ) : selectedProperty === MatchProperty.Time ? (
                <FormattedMessage id="changeTime" />
              ) : selectedProperty === MatchProperty.City ? (
                <FormattedMessage id="change_city" />
              ) : selectedProperty === MatchProperty.Venue ? (
                <FormattedMessage id="change_venue" />
              ) : (
                <FormattedMessage id={isLeague ? 'changeRound' : 'changeStage'} />
              )}
            </Text>
          }
          // This is a hack for the input component in Update Round Form, where the input component doesn't work properly
          // inside of the UI Modal if it's rendered outside MUI's presentation container via React Portal
          portalsContainerId="match-properties-container"
        >
          {selectedProperty === MatchProperty.Date ? (
            <UpdateStartDateForm handleClose={handlePopUpClose} revalidateEvent={revalidateEvent} />
          ) : selectedProperty === MatchProperty.Time ? (
            <UpdateStartTimeForm handleClose={handlePopUpClose} revalidateEvent={revalidateEvent} />
          ) : selectedProperty === MatchProperty.City ? (
            <UpdateCityForm handleClose={handlePopUpClose} revalidateEvent={revalidateEvent} />
          ) : selectedProperty === MatchProperty.Venue ? (
            <UpdateVenueForm handleClose={handlePopUpClose} revalidateEvent={revalidateEvent} />
          ) : (
            <UpdateRoundForm handleClose={handlePopUpClose} revalidateEvent={revalidateEvent} />
          )}
        </ResponsivePopUp>
      </Flex>
    </Box>
  )
}

export default MatchProperties
