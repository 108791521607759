import { useCallback, useEffect, useRef, useState } from 'react'

import { Option } from 'components/DropdownMenu/interface'

import * as S from './styles'

interface PickerColumnProps {
  options: Option[]
  name: string
  value: string | number
  selectedIndex: number
  itemHeight: number
  columnHeight: number
  columnIndex: number
  alignColumn?: 'center' | 'flex-start' | 'flex-end'
  onChange: (name: string, value: string | number) => void
}

const PickerColumn = (props: PickerColumnProps) => {
  const { options, name, value, selectedIndex, itemHeight, columnHeight, columnIndex, alignColumn, onChange } = props

  const columnRef = useRef<HTMLDivElement>(null)

  const [scrollerTranslation, setScrollTranslation] = useState(
    columnHeight / 2 - itemHeight / 2 - selectedIndex * itemHeight,
  )
  const [maxTranslation, setMaxTranslation] = useState(0)
  const [minTranslation, setMinTranslation] = useState(0)
  const [startTouchY, setStartTouchY] = useState(0)
  const [startScrollerTranslation, setStartScrollerTranslation] = useState(0)
  const [isMoving, setIsMoving] = useState(false)

  /**
   * When currently selected value is clicked focus the input component.
   *
   * Reason for this logic is that the WheelEvent cannot be passed through the input component
   * efficiently. So all pointer events on input are disabled and input interactivity goes through here.
   */
  const handleItemClick = (option: Option) => {
    if (option.value !== value) {
      onChange(name, option.value)
    } else {
      const targetInput = document.getElementById(`iwp-ci-${columnIndex}`) as HTMLInputElement
      if (targetInput) {
        targetInput.click()
      }
    }
  }

  const computeTranslation = () => {
    setScrollTranslation(columnHeight / 2 - itemHeight / 2 - selectedIndex * itemHeight)
    setMinTranslation(columnHeight / 2 - itemHeight * options.length + itemHeight / 2)
    setMaxTranslation(columnHeight / 2 - itemHeight / 2)
  }

  const onScrollerTranslateSettled = useCallback(
    (scrollerTranslation: number) => {
      let activeIndex = 0
      if (scrollerTranslation >= maxTranslation) {
        activeIndex = 0
      } else if (scrollerTranslation <= minTranslation) {
        activeIndex = options.length - 1
      } else {
        activeIndex = -Math.round((scrollerTranslation - maxTranslation) / itemHeight)
      }

      onChange(name, options[activeIndex].value)
    },
    [maxTranslation, minTranslation, options, itemHeight, onChange, name],
  )

  const handleWheel = useCallback(
    (event: WheelEvent) => {
      // Stop page from scrolling
      event.preventDefault()

      let delta = -event.deltaY * 0.1
      if (Math.abs(event.deltaY) < 200) {
        delta *= 0.1
      }
      if (Math.abs(delta) < itemHeight) {
        delta = itemHeight * Math.sign(delta)
      }

      onScrollerTranslateSettled(scrollerTranslation + delta)
    },
    [itemHeight, scrollerTranslation, onScrollerTranslateSettled],
  )

  const handleTouchStart = useCallback(
    (event: TouchEvent) => {
      const startTouchY = event.targetTouches[0].pageY
      setStartTouchY(startTouchY)
      setStartScrollerTranslation(scrollerTranslation)
    },
    [scrollerTranslation],
  )

  const handleTouchMove = useCallback(
    (event: TouchEvent) => {
      event.preventDefault()
      const touchY = event.targetTouches[0].pageY

      if (!isMoving) {
        setIsMoving(true)
      }

      let nextScrollerTranslation = startScrollerTranslation + touchY - startTouchY
      if (nextScrollerTranslation < minTranslation) {
        nextScrollerTranslation = minTranslation - Math.pow(minTranslation - nextScrollerTranslation, 0.8)
      } else if (nextScrollerTranslation > maxTranslation) {
        nextScrollerTranslation = maxTranslation + Math.pow(nextScrollerTranslation - maxTranslation, 0.8)
      }
      setScrollTranslation(nextScrollerTranslation)
    },
    [isMoving, startScrollerTranslation, startTouchY, minTranslation, maxTranslation],
  )

  const handleTouchEnd = useCallback(() => {
    if (!isMoving) {
      return
    }
    setIsMoving(false)
    setStartTouchY(0)
    setStartScrollerTranslation(0)

    setTimeout(() => {
      onScrollerTranslateSettled(scrollerTranslation)
    }, 0)
  }, [isMoving, onScrollerTranslateSettled, scrollerTranslation])

  const handleTouchCancel = useCallback(() => {
    if (!isMoving) {
      return
    }
    setIsMoving(false)
    setStartTouchY(0)
    setScrollTranslation(startScrollerTranslation)
    setStartScrollerTranslation(0)
  }, [isMoving, startScrollerTranslation])

  // Manage listeners
  useEffect(() => {
    const pickerColumn = columnRef.current

    pickerColumn?.addEventListener('wheel', handleWheel)
    pickerColumn?.addEventListener('touchstart', handleTouchStart)
    pickerColumn?.addEventListener('touchmove', handleTouchMove)
    pickerColumn?.addEventListener('touchend', handleTouchEnd)
    pickerColumn?.addEventListener('touchcancel', handleTouchCancel)

    return () => {
      pickerColumn?.removeEventListener('wheel', handleWheel)
      pickerColumn?.removeEventListener('touchstart', handleTouchStart)
      pickerColumn?.removeEventListener('touchmove', handleTouchMove)
      pickerColumn?.removeEventListener('touchend', handleTouchEnd)
      pickerColumn?.removeEventListener('touchcancel', handleTouchCancel)
    }
  }, [handleWheel, handleTouchStart, handleTouchMove, handleTouchEnd, handleTouchCancel])

  // Recompute scroller translation on every prop change
  useEffect(() => {
    if (!isMoving) {
      computeTranslation()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  const translationString = `translate3d(0, ${scrollerTranslation}px, 0)`

  return (
    <S.PickerColumn id="wheel-picker-column" ref={columnRef} style={{ justifyContent: alignColumn ?? 'center' }}>
      <S.PickerScroller
        style={{
          transform: translationString,
          msTransform: translationString,
          WebkitTransform: translationString,
          OTransform: translationString,
          transitionDuration: isMoving ? '0ms' : undefined,
        }}
        data-testid="picker-scroller"
      >
        {options.map(option => {
          const isSelected = option.value === value
          return (
            <S.PickerItem
              key={option.value}
              onClick={() => handleItemClick(option)}
              isSelected={isSelected}
              style={{
                height: `${itemHeight}px`,
                lineHeight: `${itemHeight}px`,
              }}
              data-testid={isSelected ? 'selected-value' : undefined}
            >
              {option.label}
            </S.PickerItem>
          )
        })}
      </S.PickerScroller>
    </S.PickerColumn>
  )
}

export default PickerColumn
