import * as Yup from 'yup'

import { InfoGeneralFormSchema, SeasonGeneralFormSchema } from '../schema'

export enum AdminInfoTabs {
  General = 'general',
  Seasons = 'seasons',
}

export type InfoGeneralFormType = Yup.InferType<typeof InfoGeneralFormSchema>
export interface InfoGeneralFormAPIPayload {
  tier?: number
  lowerDivisionIds?: number[]
  upperDivisionIds?: number[]
  titleHolderId?: number
  titleHolderTitles?: number
  mostTitlesTeamIds?: number[]
  mostTitles?: number
}

export type SeasonGeneralFormType = Yup.InferType<typeof SeasonGeneralFormSchema>
export interface SeasonGeneralFormAPIPayload {
  season?: number
  lowerDivisionIds?: number[]
  upperDivisionIds?: number[]
  otherIds?: number[]
}
