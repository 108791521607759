import * as React from 'react'
import { Typography, useTheme } from '@material-ui/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { CupRound } from 'entities/Tournament'

import { NUMBER_ROUND, STAGE_ROUND, MIN_ROUND, MAX_ROUND } from 'modules/Matches/const'
import { Option } from 'components/DropdownMenu/interface'
import Input from 'components/Input/Input'
import { DropdownMenu } from 'components/DropdownMenu'

import { useStyles } from './styles'
import { getTournamentCupRounds } from './helper'

interface RoundsFormProps {
  roundType: string
  roundNumber: number | null
  stageRoundValue: Option | null
  handleClickRoundMenuItem: (selectedOption: Option) => void
  handleRoundNumberChange: (e: React.ChangeEvent) => void
  hasTitle?: boolean
}

const RoundsForm = (props: RoundsFormProps) => {
  const { roundType, roundNumber, stageRoundValue, handleClickRoundMenuItem, handleRoundNumberChange, hasTitle } = props
  const theme = useTheme()
  const classes = useStyles()

  const error = !!roundNumber && (roundNumber < MIN_ROUND || roundNumber > MAX_ROUND)

  const intl = useIntl()

  return (
    <>
      {hasTitle && (
        <Typography variant="caption" color="primary" className={classes.title}>
          <FormattedMessage id={roundType === STAGE_ROUND ? 'stage' : 'round'} />
        </Typography>
      )}

      <div className={classes.roundsInputContainer}>
        {roundType === NUMBER_ROUND ? (
          <>
            <Input
              name="roundNumber"
              type="number"
              onChange={handleRoundNumberChange}
              value={roundNumber ? String(roundNumber) : ''}
              placeholder={intl.formatMessage({ id: 'typeHere' })}
              inputProps={{
                min: MIN_ROUND,
                max: MAX_ROUND,
              }}
              style={{ width: '100%', margin: 0, marginTop: theme.spacing(1) }}
            />

            {error && (
              <Typography variant="caption" className={classes.errorText}>
                <FormattedMessage id="invalidRoundNumber" values={{ MAX_ROUND }} />
              </Typography>
            )}
          </>
        ) : (
          <DropdownMenu
            onChange={handleClickRoundMenuItem}
            options={getTournamentCupRounds(CupRound.FINAL_256).map((cupRound: string) => {
              const fraction = cupRound.match(/\d+[/]\d+/)
              return {
                label: fraction ? cupRound : intl.formatMessage({ id: cupRound }),
                value: cupRound,
              }
            })}
            style={{ width: '100%', margin: 0, marginTop: `${theme.spacing(1)}px` }}
            selectedOption={stageRoundValue}
          />
        )}
      </div>
    </>
  )
}

export default RoundsForm
