import { Flex, Text, Image } from '@sofascore/ui'
import { BasicEvent } from 'entities/Event'
import { PropsWithChildren, ReactNode } from 'react'
import { BasicTeam } from 'entities/Team'

import ResponsivePopUp from 'components/ResponsivePopUp'
import { teamImage } from 'api/routes'

import { getNextResultColoring } from '../utils'

interface AddScoreModalProps {
  isOpen: boolean
  onClose: () => void
  title: ReactNode
  team: BasicTeam
  event: BasicEvent
  nextHomeScore: number
  nextAwayScore: number
}

export const AddScoreModal = ({
  isOpen,
  onClose,
  title,
  team,
  event,
  nextHomeScore,
  nextAwayScore,
  children,
}: PropsWithChildren<AddScoreModalProps>) => {
  const { homeTeam } = event
  const isHomeTeam = homeTeam.id === team.id

  return (
    <ResponsivePopUp
      isOpen={isOpen}
      onClose={onClose}
      heading={
        <Flex align="center" justify="space-between" w="100%" direction="row">
          <Flex>
            <Image src={teamImage(team.id)} w={24} h={24} mr="sm" alt={team.name} />
            <Text font="display.large">{title}</Text>
          </Flex>
          <Flex direction="row" px="sm">
            <Text font="display.medium" color={getNextResultColoring(true, isHomeTeam)}>
              {nextHomeScore}
            </Text>
            <Text font="display.medium" color="onSurface.nLv3" mx="1">
              -
            </Text>
            <Text font="display.medium" color={getNextResultColoring(false, isHomeTeam)}>
              {nextAwayScore}
            </Text>
          </Flex>
        </Flex>
      }
    >
      {children}
    </ResponsivePopUp>
  )
}
