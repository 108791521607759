/**
 * Returns provided `params` object as query string.
 * Only assigns value to the key.
 * Callers' responsibility is to stringify complex types such as objects and arrays.
 *
 * @param params object to be returned as query string
 */
export const getQueryString = (params: { [key: string]: string | number | boolean }) => {
  const queryArray = Object.keys(params).map(key => `${key}=${params[key]}`)

  if (!queryArray.length) {
    return ''
  }

  return `?${queryArray.join('&')}`
}

/**
 * Returns URL path with updated entity id
 */
export const getUpdatedURLPath = (pathName: string, entity: string, id: string) => {
  const pathNameArray = pathName.split('/')
  const seasonIndex = pathNameArray.findIndex(item => item === entity)
  pathNameArray[seasonIndex + 1] = id
  const newPath = pathNameArray.join('/')

  return newPath
}
