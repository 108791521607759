import { UniqueTournamentGroupedSuggestionsResponse } from 'entities/UniqueTournament'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'

import { uniqueTournamentGroupedSuggestions } from 'api/routes'

import { EntityFilter } from '../model'

export const useGroupedTeamSuggestions = (status: EntityFilter) => {
  const { competitionId, seasonId } = useParams()
  const { data, error, isLoading } = useSWR<UniqueTournamentGroupedSuggestionsResponse>(
    competitionId && seasonId ? uniqueTournamentGroupedSuggestions(+competitionId, +seasonId, status) : null,
  )

  return {
    data,
    error,
    isLoading,
  }
}
