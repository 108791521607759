import { Flex } from '@sofascore/ui'
import styled from 'styled-components'

import { Button } from 'components/Button'

export const LineupsTypeLabel = styled.label`
  width: 50%;
  cursor: pointer;
`

export const LineupsTypeContent = styled(Flex)`
  position: relative;
  align-items: center;
  justify-content: center;

  flex-grow: 0;
  flex-shrink: 0;

  padding: ${p => p.theme.spacing.lg};
  gap: 4px;
  font: ${p => p.theme.typography.font.display.small};
  border-width: 1px;
  border-style: solid;
  border-color: ${p => p.theme.colors.onSurface.nLv4};
  transition: all 0.2s;
  white-space: nowrap;

  color: ${p => p.theme.colors.onSurface.nLv3};
  background: ${p => p.theme.colors.surface.s1};

  &:hover {
    border-color: ${p => p.theme.colors.onSurface.nLv1};
    color: ${p => p.theme.colors.onSurface.nLv1};
  }
`

export const LineupsTypeRadio = styled.input`
  display: none;

  &:checked ~ ${LineupsTypeContent} {
    color: ${p => p.theme.colors.onSurface.nLv1};
    border-color: ${p => p.theme.colors.onSurface.nLv1};
  }
`

export const LineupsTypeImage = styled.img`
  display: block;
  margin-top: 24px;
  pointer-events: none;
  width: 216px;
`

export const AddLineupsButton = styled(Button)`
  width: 280px;
  text-transform: uppercase;
  padding: 12px 0 !important;
  margin-top: 48px !important;

  @media (min-width: 959px) {
    position: relative;
  }
`
