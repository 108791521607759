import { FormattedMessage, useIntl } from 'react-intl'
import { Box, Dropdown, Flex, GraphicEmptyStateNoStandings, Spinner, Text, useMediaContext } from '@sofascore/ui'
import { useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { useEffect, useState } from 'react'

import { SERVER_ERROR_PAGE_MESSAGE } from 'utils'

import StandingsTable from './components/AdaptiveTable/AdaptiveTable'
import { ALL_FILTER_VALUE, getDefaultFilterOption, getStandingsOptions } from './utils'
import { useAllUniqueTournamentStandings } from './hooks'

const EmptyStandings = () => {
  return (
    <Box mt={50} mx="md">
      <Flex display="flex" direction="column" align="center">
        <GraphicEmptyStateNoStandings />

        <Box textAlign="center" mt="xl">
          <Text fontSize="medium" fontWeight="bold">
            <FormattedMessage id="no_teams_added_header" />
          </Text>

          <Box mt="lg">
            <Text color="onSurface.nLv3">
              <FormattedMessage id="standings_empty_state" />
            </Text>
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}

const StandingsContainer = () => {
  const intl = useIntl()

  const theme = useTheme()

  const { seasonId } = useParams()

  const [selectedTournamentOption, setSelectedTournamentOption] = useState(getDefaultFilterOption(intl))

  const { data: standings, error, mutate, isLoading } = useAllUniqueTournamentStandings()

  const { isMobile } = useMediaContext()

  // On season change reset the filter state
  useEffect(() => {
    setSelectedTournamentOption(getDefaultFilterOption(intl))
  }, [intl, seasonId])

  if (isLoading || !seasonId) {
    return (
      <Box textAlign="center" style={{ paddingTop: isMobile ? '30%' : '15%' }}>
        <Spinner width="40px" color={theme.colors.primary.default} />
      </Box>
    )
  }

  if (standings.length === 0) {
    if (!error) {
      return <EmptyStandings />
    }

    return (
      <Box mt={100}>
        <Text as="h4" fontSize="extraExtraLarge" align="center">
          {SERVER_ERROR_PAGE_MESSAGE}
        </Text>
      </Box>
    )
  }

  const tournamentSelectOptions = getStandingsOptions(standings, intl)

  return (
    <Box>
      {tournamentSelectOptions.length > 2 && (
        <Flex justify="flex-end">
          <Dropdown
            items={tournamentSelectOptions}
            selectedItem={selectedTournamentOption}
            onSelectedItemChange={changeValue => {
              setSelectedTournamentOption(
                changeValue.selectedItem ?? {
                  value: ALL_FILTER_VALUE,
                  label: intl.formatMessage({ id: ALL_FILTER_VALUE }),
                },
              )
            }}
            renderItem={item => intl.formatMessage({ id: item ? item.value : ALL_FILTER_VALUE })}
            buttonStyle={{ backgroundColor: theme.colors.surface.s1 }}
          />
        </Flex>
      )}

      <Flex direction="column" gapY="sm" mt="sm">
        {standings
          .filter(
            table =>
              selectedTournamentOption.value === ALL_FILTER_VALUE || selectedTournamentOption.value === table.name,
          )
          .sort((table1, table2) => (table1.name > table2.name ? 1 : -1))
          .map(table => (
            <Box key={table.id}>
              <StandingsTable
                name={standings.length > 1 ? table.name : intl.formatMessage({ id: 'standings' })}
                standings={table}
                revalidate={mutate}
                seasonId={+seasonId}
              />
            </Box>
          ))}
      </Flex>
    </Box>
  )
}

export default StandingsContainer
