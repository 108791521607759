import { PropsWithChildren } from 'react'
import { Box, BoxProps } from '@sofascore/ui'

export const LineupsLayout = ({ children, ...boxProps }: PropsWithChildren & BoxProps) => {
  return (
    <Box maxW={[960, 700]} mx="auto" mt="sm" pb={[164, 96]} {...boxProps}>
      {children}
    </Box>
  )
}
