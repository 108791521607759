import { withStyles, Theme } from '@material-ui/core'
import MuiDialogContent from '@material-ui/core/DialogContent'

/**
 * DialogContent part of Dialog compoennt
 */
const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

export default DialogContent
