import { useTheme } from '@material-ui/core'

import { IconButton } from 'components/IconButton'
import { IconClose } from 'components/Icons/IconClose'
import useBoolean from 'utils/useBoolean'

import DeleteDialog from './DeleteDialog'

const DeleteSectionButton = ({ deleteHandler }: { deleteHandler?: () => void }) => {
  const theme = useTheme()
  const [isOpen, { setTrue: openDialog, setFalse: closeDialog }] = useBoolean(false)

  return (
    <>
      <IconButton
        onClick={event => {
          event?.stopPropagation()
          openDialog()
        }}
        ml="auto"
        p="0px"
      >
        <IconClose fill={theme.palette.secondary.main} />
      </IconButton>
      {deleteHandler && <DeleteDialog isOpen={isOpen} handleClose={closeDialog} deleteItem={deleteHandler} />}
    </>
  )
}

export default DeleteSectionButton
