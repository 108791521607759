import { mutate } from 'swr'
import { useSelector } from 'react-redux'
import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { CreateAccumulatedFoulData } from 'entities/AccumulatedFoul'
import { useToast } from 'shared/lib'

import { getAuthFromState } from 'store/selectors'
import { eventAccumulatedFouls } from 'api/routes'
import { createAccumulatedFoul } from 'api'

export const useUpdateAccumulatedFoulsHandler = () => {
  const { userAccount } = useSelector(getAuthFromState)
  const { enqueueToast } = useToast()
  const intl = useIntl()

  const handleUpdate = useCallback(
    async (data: CreateAccumulatedFoulData) => {
      if (userAccount) {
        try {
          const { eventId } = data
          const updatedAccFouls = await createAccumulatedFoul(data)

          mutate(eventAccumulatedFouls(eventId), updatedAccFouls, { revalidate: false })
        } catch {
          enqueueToast(intl.formatMessage({ id: 'toastError' }), { variant: 'error' })
        }
      }
    },
    [intl, userAccount, enqueueToast],
  )

  return { handleUpdate }
}
