import { Box, BoxProps } from '@sofascore/ui'
import { useEffect, useRef, useState } from 'react'
import IconChevronUp16 from '@sofascore/ui/dist/modules/Icons/IconChevronUp16'
import { CSSObject } from 'styled-components'

import { textPrimary } from 'styles/color'

import * as S from './styles'

interface Props {
  /** Initial setting */
  initiallyOpen?: boolean
  /** Component header */
  title: React.ReactNode
  /** Collapsable content */
  children: React.ReactNode
  /** Title alignment */
  alignTitle?: 'center' | 'left'
  /** Title styles are separated because of potential problems with Expand Icon */
  headerStyles?: CSSObject
  /** Max height */
  maxHeight?: number
  /** Function to executer when collapsing the section */
  onCollapse?: () => void
}

const Collapse = ({
  initiallyOpen = false,
  title,
  children,
  alignTitle = 'left',
  headerStyles,
  maxHeight,
  onCollapse,
  ...boxProps
}: Props & BoxProps) => {
  const [isOpen, setIsOpen] = useState(initiallyOpen)
  const [height, setHeight] = useState<number | undefined>(initiallyOpen ? undefined : 0)
  const [maxContentHeight, setMaxContentHeight] = useState<number>()

  const headerRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)

  const handleCollapse = () => {
    setIsOpen(prev => !prev)
    onCollapse?.()
  }

  useEffect(() => {
    if (maxHeight && headerRef.current)
      setMaxContentHeight(maxHeight - headerRef.current.getBoundingClientRect().height)
  }, [maxHeight])

  useEffect(() => {
    setHeight(isOpen ? contentRef.current?.getBoundingClientRect().height : 0)
  }, [isOpen, children])

  const expandButtonMarginRight = boxProps.pr || boxProps.px || boxProps.p

  return (
    <S.Collapse {...boxProps}>
      <S.CollapseHeader ref={headerRef} onClick={handleCollapse} alignTitle={alignTitle} style={headerStyles}>
        <Box grow={1}>{title}</Box>

        <Box className="expand-button" pr={alignTitle === 'center' ? expandButtonMarginRight : undefined}>
          <IconChevronUp16
            w={16}
            h={16}
            fill={textPrimary}
            style={{ transform: `rotate(${isOpen ? '360deg' : '180deg'})`, transition: 'transform 0.2s' }}
          />
        </Box>
      </S.CollapseHeader>

      <S.CollapseContentWrapper br={boxProps.br}>
        <S.CollapseContent h={height} maxH={maxContentHeight}>
          <Box ref={contentRef}>{children}</Box>
        </S.CollapseContent>
      </S.CollapseContentWrapper>
    </S.Collapse>
  )
}

export default Collapse
