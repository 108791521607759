import { Box, Flex, Text } from '@sofascore/ui'
import { useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { IncidentGoalType, useEventIncidentsState } from 'entities/Incident'
import { useEventState } from 'entities/Event'
import {
  useTeamPlayerOptions,
  PlayerOption,
  getDefaultOption,
  renderPlayerOption,
  renderSelectedPlayerOption,
} from 'entities/Player'
import IconFootballPenalty from '@sofascore/ui/dist/modules/Icons/_IconFootballPenalty'
import IconFootballBall from '@sofascore/ui/dist/modules/Icons/_IconFootballBall'
import IconFootballOwnGoal from '@sofascore/ui/dist/modules/Icons/_IconFootballOwnGoal'

import { Button } from 'components/Button'
import WheelPicker from 'components/WheelPicker'
import IncidentBox from 'components/IncidentBox'
import {
  formatMatchTimeToOptionValue,
  formatTimeOptionValueToMatchTime,
  generateFootballGoalMatchTimeOptions,
} from 'utils/matchTime'
import { getLastGoalsFromIncidents } from 'modules/Football/helper'
import { Select } from 'components/Select'
import { GREEN, RED } from 'styles/color'

import { additionalTimeDisplayLimit } from '../../config'
import { EditIncidentFormProps } from '../../model'

export const EditFootballLastGoalForm = ({ onSubmit, onClose, incident }: EditIncidentFormProps) => {
  const { event } = useEventState()
  const intl = useIntl()
  const { incidents } = useEventIncidentsState()

  const { id: eventId, homeTeam, awayTeam } = event
  const {
    id,
    isHome,
    incidentType,
    incidentClass: initialIncidentClass,
    homeScore,
    awayScore,
    player: initialPlayer,
    assist1: initialAssist,
    time: initialTime,
    addedTime: initialAddedTime,
  } = incident

  const teamId = isHome ? homeTeam.id : awayTeam.id

  const [isSaving, setIsSaving] = useState(false)
  const [goalType, setGoalType] = useState<IncidentGoalType>(initialIncidentClass as IncidentGoalType)

  const [scorer, setScorer] = useState<PlayerOption | undefined>(getDefaultOption(initialPlayer))
  const [assist, setAssist] = useState<PlayerOption | undefined>(getDefaultOption(initialAssist))

  const [matchTime, setMatchTime] = useState(
    initialTime
      ? {
          time: formatMatchTimeToOptionValue({ time: initialTime, addedTime: initialAddedTime }),
        }
      : { time: '1' },
  )

  const isHomeTeam = homeTeam.id === teamId
  const isOwnGoal = goalType === IncidentGoalType.OwnGoal
  const _teamId = isOwnGoal ? (isHomeTeam ? awayTeam.id : homeTeam.id) : isHomeTeam ? homeTeam.id : awayTeam.id
  const [scorerOptions, assistOptions] = useTeamPlayerOptions(_teamId, scorer, assist)

  // Handle time options to limit them depending on the last goal time
  const timeOptions = useMemo(() => {
    const [lastGoal, secondToLastGoal] = getLastGoalsFromIncidents(incidents || [])
    return generateFootballGoalMatchTimeOptions(event, lastGoal!, secondToLastGoal, additionalTimeDisplayLimit)
  }, [event, incidents])

  const handleConfirm = async () => {
    const incidentTime = formatTimeOptionValueToMatchTime(matchTime.time)

    onSubmit({
      eventId: eventId,
      incidentId: id as number,
      incidentData: {
        incidentType,
        incidentClass: goalType,
        isHome: isHome as boolean,
        time: incidentTime.time,
        addedTime: incidentTime.addedTime,
        homeScore,
        awayScore,
        player: scorer ? scorer.value : undefined,
        assist1: assist ? assist.value : undefined,
      },
    })
      .then(() => onClose())
      .finally(() => setIsSaving(false))
  }

  return (
    <Box px="sm" pb="xxl">
      <Box px="sm" pt="lg" pb="sm" mb="lg" br="sm" bg="surface.s2">
        <Flex justify="center" mb="lg">
          <Text font="display.small">
            <FormattedMessage id="confirm_time" />
          </Text>
        </Flex>
        <Box>
          <WheelPicker
            columnsOptions={{ time: timeOptions }}
            columnsValues={matchTime}
            onChange={(_name, option) => {
              setMatchTime({ time: option as string })
            }}
            height={100}
          />
        </Box>
      </Box>

      <Box px="sm" pt="lg" pb="xs" br="sm" bg="surface.s2">
        <Flex mb="xl" justify="center">
          <Text font="display.small">
            <FormattedMessage id="goal_type_and_scorer" />
          </Text>
        </Flex>

        <Flex mb="lg" px="xxl" justify="center">
          <IncidentBox
            label={intl.formatMessage({ id: 'goal' })}
            isActive={goalType === IncidentGoalType.GoalRegular}
            onClick={() => {
              setGoalType(IncidentGoalType.GoalRegular)
              setScorer(undefined)
              setAssist(undefined)
            }}
            icon={<IconFootballBall fill={GREEN.incident} />}
            mx="sm"
          />

          <IncidentBox
            label={intl.formatMessage({ id: 'penalty' })}
            isActive={goalType === IncidentGoalType.GoalFromPenalty}
            onClick={() => {
              setGoalType(IncidentGoalType.GoalFromPenalty)
              setScorer(undefined)
              setAssist(undefined)
            }}
            icon={<IconFootballPenalty fill={GREEN.incident} />}
            mx="sm"
          />

          <IncidentBox
            label={intl.formatMessage({ id: 'autogoal' })}
            isActive={goalType === IncidentGoalType.OwnGoal}
            onClick={() => {
              setGoalType(IncidentGoalType.OwnGoal)
              setScorer(undefined)
              setAssist(undefined)
            }}
            icon={<IconFootballOwnGoal fill={RED.incident} />}
            mx="sm"
          />
        </Flex>

        <Select
          label={intl.formatMessage({ id: 'player' })}
          options={scorerOptions}
          helperText="Optional"
          value={scorer}
          onChange={setScorer}
          maxVisibleOptions={6}
          w="100%"
          selectBackgroundColor="surface.s1"
          renderOption={renderPlayerOption}
          renderSelectedOption={renderSelectedPlayerOption}
        />

        {goalType === IncidentGoalType.GoalRegular && (
          <Select
            label={intl.formatMessage({ id: 'assist' })}
            options={assistOptions}
            helperText="Optional"
            value={assist}
            onChange={setAssist}
            maxVisibleOptions={6}
            w="100%"
            selectBackgroundColor="surface.s1"
            renderOption={renderPlayerOption}
            renderSelectedOption={renderSelectedPlayerOption}
          />
        )}
      </Box>

      <Flex gapX="lg" px="lg" mt="xxl">
        <Button variant="outlined" fullWidth onClick={onClose}>
          <FormattedMessage id="cancel" />
        </Button>
        <Button variant="contained" fullWidth onClick={handleConfirm} isLoading={isSaving}>
          <FormattedMessage id="confirm" />
        </Button>
      </Flex>
    </Box>
  )
}
