import styled from 'styled-components'
import { Box } from '@sofascore/ui'

export const ResultInput = styled.input.attrs({ type: 'number', required: true })<{ away?: boolean }>`
  width: 54px;
  height: 36px;
  border-radius: 8px;
  padding: 0px 2px;
  margin: 0px;
  border: 1px solid ${p => p.theme.colors.onSurface.nLv4};
  background-color: ${p => p.theme.colors.surface.s2};
  font-size: 28px;
  text-align: ${p => (p.away ? 'left' : 'right')};
  color: ${p => p.theme.colors.onSurface.nLv1};
  font-weight: bold;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    /* Firefox */
    appearance: textfield;
    -moz-appearance: textfield;
  }
  &:focus {
    border-color: ${p => p.theme.colors.onSurface.nLv1};
  }
`

export const LabelHolder = styled(Box)<{ away?: boolean }>`
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: ${p => (p.away ? 'right' : 'left')};
  margin-right: ${p => (p.away ? 0 : p.theme.spacing.sm)};
  margin-left: ${p => (p.away ? p.theme.spacing.sm : 0)};
`
