import { Box } from '@sofascore/ui'
import { Outlet, useParams } from 'react-router-dom'
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { useEffect } from 'react'
import useSWR from 'swr'
import { useDispatch } from 'react-redux'
import { EventProvider } from 'entities/Event'
import { redesignedSports, sportsWithGeneralEdit } from 'entities/Sport'

import { eventDetails } from 'api/routes'
import { EventDetailsResponse } from 'modules/Matches/interface'
import { SET_EVENT, REMOVE_EVENT } from 'modules/Matches/matchesReducer'
import { MATCH_NOT_FOUND_MESSAGE } from 'utils'

import { MatchDetailsHeader } from './MatchDetailsHeader'
import { GeneralMatchDetailsHeader } from '../GeneralMatchDetailsHeader'

export const MatchDetailsLayout = () => {
  const { eventId } = useParams()
  const dispatch = useDispatch()

  const {
    data: eventData,
    error: eventError,
    isLoading: eventLoading,
  } = useSWR<EventDetailsResponse>(eventId ? eventDetails(+eventId) : null)

  useEffect(() => {
    if (eventData) {
      dispatch({ type: SET_EVENT, payload: { event: eventData.event } })
    }

    return () => {
      dispatch({ type: REMOVE_EVENT })
    }
  }, [dispatch, eventData])

  if (eventLoading) {
    return (
      <Box textAlign="center" pt={['30%', '20%']}>
        <CircularProgress />
      </Box>
    )
  }

  if (eventError) {
    return (
      <Grid container style={{ marginTop: 200 }}>
        <Grid item xs={12}>
          <Typography variant="h4" color="textPrimary" style={{ textAlign: 'center' }}>
            {MATCH_NOT_FOUND_MESSAGE}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const isRedesignedSport = eventData
    ? redesignedSports.includes(eventData.event.tournament.category.sport.slug)
    : false

  const isGeneralEditSport = eventData
    ? sportsWithGeneralEdit.includes(eventData.event.tournament.category.sport.slug)
    : false

  return (
    <EventProvider initialState={eventData!.event}>
      {isRedesignedSport && <MatchDetailsHeader />}
      {isGeneralEditSport && <GeneralMatchDetailsHeader />}

      <Outlet />
    </EventProvider>
  )
}
