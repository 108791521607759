import { PermissionAction, PermissionScope } from 'entities/Permission'
import { Outlet } from 'react-router-dom'
import { usePermissions } from 'shared/hooks'
import { CompetitionType } from 'entities/Tournament'

import { useMyCompetitionsState } from 'modules/MyCompetitions/providers/MyCompetitionsProvider'
interface MyCompetitionPermissionsOutletInterface {
  scopes: PermissionScope[]
  actions?: PermissionAction[]
}

const MyCompetitionPermissionsOutlet = ({ scopes, actions }: MyCompetitionPermissionsOutletInterface) => {
  const { guardScope } = usePermissions()

  /*
    cupTree and standings are not available over permissions so we need to check
    if user has access to them via tournaments 
    (this hack will be removed in the future when we get cupTree and standings permissions)
  */
  const { tournaments } = useMyCompetitionsState()

  if (
    (scopes.includes(PermissionScope.Standings) || scopes.includes(PermissionScope.CupTree)) &&
    tournaments.length === 0
  )
    return null
  if (scopes.includes(PermissionScope.Standings)) {
    if (!guardScope(scopes)) throw Error()
    const hasStandings = !!tournaments?.find(tournament => tournament.competitionType === CompetitionType.League)
    if (hasStandings) return <Outlet />
    throw Error()
  }
  if (scopes.includes(PermissionScope.CupTree)) {
    if (!guardScope([PermissionScope.Standings])) throw Error()
    const hasCupTournament = !!tournaments?.find(tournament => tournament.competitionType === CompetitionType.Cup)
    if (hasCupTournament) return <Outlet />
    throw Error()
  }

  if (guardScope(scopes, actions)) return <Outlet />
  throw Error()
}

export default MyCompetitionPermissionsOutlet
