import { Box, Flex, Text } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'
import { useEffect, useState } from 'react'
import { useEventState } from 'entities/Event'
import { EXTRA_TIME_HALF_CODE, HALF_TIME_CODE, PENALTIES_CODE } from 'entities/Status'

import { Button } from 'components/Button'
import ResponsivePopUp from 'components/ResponsivePopUp'
import { TimePicker } from 'components/TimePicker'

import { SelectablePeriod } from '../model'
import { useUpdatePeriodHandler } from '../hooks'

const ProceedToPeriodPanel = ({ children }: { children: React.ReactNode }) => {
  return (
    <Flex h={152} justify="center" align="center">
      <Text fontWeight="bold" fontSize={16} letterSpacing={0.25}>
        {children}
      </Text>
    </Flex>
  )
}

export const PeriodStartTimePickerForm = ({
  isOpen,
  selectedPeriod,
  onClose,
}: {
  isOpen: boolean
  selectedPeriod: SelectablePeriod | null
  onClose: () => void
}) => {
  const [selectedTime, setSelectedTime] = useState<Date>(new Date())
  const [isSaving, setIsSaving] = useState(false)
  const { event } = useEventState()

  const { handleUpdate } = useUpdatePeriodHandler()

  const isHalfTime = selectedPeriod?.code === HALF_TIME_CODE
  const isETHalfTime = selectedPeriod?.code === EXTRA_TIME_HALF_CODE
  const isPenalties = selectedPeriod?.code === PENALTIES_CODE

  const isPeriodWithoutStartTime = isHalfTime || isETHalfTime || isPenalties

  const handleUpdatePeriodOnEvent = async (currentPeriodStartTime: Date | null) => {
    if (selectedPeriod) {
      setIsSaving(true)

      handleUpdate(
        {
          status: {
            code: selectedPeriod.code,
          },
          homeScore: event.homeScore,
          awayScore: event.awayScore,
          aggregatedWinnerCode: event.aggregatedWinnerCode!,
          currentPeriodStartTimestamp: currentPeriodStartTime
            ? Math.round(currentPeriodStartTime.getTime() / 1000)
            : null,
          roundInfo: event.roundInfo,
          // If editor takes over crowdsourced event, delete all incidents
          resetScore: event.crowdsourcingDataDisplayEnabled,
        },
        event.id,
      )
        .then(() => onClose())
        .finally(() => setIsSaving(false))
    }
  }

  const handleSetCurrentTime = () => setSelectedTime(new Date())

  useEffect(() => {
    setSelectedTime(new Date())
  }, [isOpen])

  if (!selectedPeriod) return null

  return (
    <ResponsivePopUp
      isOpen={isOpen}
      onClose={onClose}
      heading={
        <Text font="display.medium">
          <FormattedMessage id={selectedPeriod.longTitleKey!} />
        </Text>
      }
    >
      <Box>
        {isPeriodWithoutStartTime ? (
          <ProceedToPeriodPanel>
            <FormattedMessage id={selectedPeriod.descriptionKey!} />
          </ProceedToPeriodPanel>
        ) : (
          <Flex direction="column" mb={200}>
            <Box backgroundColor="surface.s2" textAlign="center" mt="lg" br="sm" p="lg" mx="md">
              <Text font="display.small">
                <FormattedMessage id="select_confirm_time" />
              </Text>
              <TimePicker date={selectedTime} onChange={val => setSelectedTime(val)} />
            </Box>
            <Button
              variant="text"
              style={{ textTransform: 'unset', fontSize: '12px', fontWeight: 500 }}
              onClick={handleSetCurrentTime}
            >
              <FormattedMessage id="current_time" />
            </Button>
          </Flex>
        )}

        <Flex gapX="lg" px="lg" mb="xl" mx="lg">
          <Button variant="outlined" fullWidth onClick={onClose}>
            <FormattedMessage id="cancel" />
          </Button>
          <Button
            variant="contained"
            fullWidth
            onClick={() => handleUpdatePeriodOnEvent(isHalfTime ? null : selectedTime)}
            isLoading={isSaving}
          >
            <FormattedMessage id="confirm" />
          </Button>
        </Flex>
      </Box>
    </ResponsivePopUp>
  )
}
