import { Box } from '@sofascore/ui'
import { useLineupsState } from 'features/AddLineups'

import { ListLineupsColumn } from './ListLineupsColumn'
import { ListLineupsHeader } from './ListLineupsHeader'
import * as S from './styles'

interface DesktopPlayerListLineupsProps {
  positionGroupMapping: Record<string, string>
}

export const DesktopListLineups = ({ positionGroupMapping }: DesktopPlayerListLineupsProps) => {
  const { homeTeam, homeLineupPlayers, awayTeam, awayLineupPlayers } = useLineupsState()

  return (
    <S.PlayerListGrid>
      <Box h="100%" minW={0}>
        <ListLineupsColumn lineupPlayers={homeLineupPlayers} positionGroupMapping={positionGroupMapping} isHomeTeam>
          <ListLineupsHeader team={homeTeam} />
        </ListLineupsColumn>
      </Box>

      <Box h="100%" minW={0}>
        <ListLineupsColumn lineupPlayers={awayLineupPlayers} positionGroupMapping={positionGroupMapping}>
          <ListLineupsHeader team={awayTeam} />
        </ListLineupsColumn>
      </Box>
    </S.PlayerListGrid>
  )
}
