/* eslint-disable @typescript-eslint/no-explicit-any */
import { CreateIncidentData, IncidentType } from 'entities/Incident'
import { mutate } from 'swr'
import { useSelector } from 'react-redux'
import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useToast } from 'shared/lib'
import { useErrorHandler } from 'shared/hooks'

import { createIncident } from 'api'
import { getAuthFromState } from 'store/selectors'
import { eventDetails, getIncidents } from 'api/routes'

export const useLiveMatchCreateIncidentHandler = () => {
  const { userAccount } = useSelector(getAuthFromState)
  const { enqueueToast } = useToast()
  const intl = useIntl()
  const handleError = useErrorHandler()

  const handleCreate = useCallback(
    async (data: CreateIncidentData) => {
      if (!userAccount) return

      try {
        const { eventId } = data
        const { incidentType } = data.incidentData
        const isGoal = incidentType === IncidentType.Goal || incidentType === IncidentType.PenaltyShootout

        await createIncident(data, isGoal)

        enqueueToast(intl.formatMessage({ id: 'alertIncidentAdded' }), { variant: 'success' })

        mutate(getIncidents(eventId))

        if (isGoal) {
          mutate(eventDetails(eventId))
        }
      } catch (e) {
        handleError(e)
      }
    },
    [intl, userAccount, handleError, enqueueToast],
  )

  return { handleCreate }
}
