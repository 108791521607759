import { ReactNode, useContext } from 'react'

import { ENQUEUE_TOAST, ToastDispatchContext, ToastStateContext } from '../providers'
import { ToastOptions, ToastState } from '../model'
import { DEFAULT_OPTIONS } from '../config'

export const useToastState = (): ToastState => {
  const context = useContext(ToastStateContext)

  if (!context) {
    throw new Error('useToastState must be used within a ToastProvider')
  }

  return context
}

export const useToastDispatch = () => {
  const context = useContext(ToastDispatchContext)

  if (!context) {
    throw new Error('useToastDispatch must be used within a ToastProvider')
  }

  return context
}

export const useToast = () => {
  const dispatch = useToastDispatch()

  const enqueueToast = (content: ReactNode, options: ToastOptions) => {
    dispatch({ type: ENQUEUE_TOAST, payload: { content: content, options: { ...DEFAULT_OPTIONS, ...options } } })
  }

  return { enqueueToast }
}
