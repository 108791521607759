import { Box, Button, Theme, withStyles } from '@material-ui/core'

export const Panel = withStyles((theme: Theme) => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    backgroundColor: theme.palette.background.default,
    display: 'inline-flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    minWidth: '240px',
  },
}))(Box)

export const PanelContent = withStyles((theme: Theme) => ({
  root: {
    display: 'inline-flex',
    gap: theme.spacing(3),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))(Box)

export const PanelButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.secondary.main,
    marginTop: theme.spacing(1),
    fontWeight: 'bold',
  },
}))(Button)
