import { useEventState } from 'entities/Event'
import { SportVariant } from 'entities/Sport'

import {
  rugby15StarterPositions,
  rugby7StarterPositions,
  rugby15SubstitutionPositions,
  rugby7SubstitutionPositions,
} from '../config'

export const useRugbyPositions = () => {
  const { event } = useEventState()

  return event.tournament.category.sportVariant === SportVariant.RugbySevens
    ? { starterPositions: rugby7StarterPositions, substitutionPositions: rugby7SubstitutionPositions }
    : { starterPositions: rugby15StarterPositions, substitutionPositions: rugby15SubstitutionPositions }
}
