import { FormattedMessage } from 'react-intl'
import { GraphicEmptyStateNoData, Box, Flex, Text } from '@sofascore/ui'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/Button'
import { PageHeader } from 'components/Layout/PageHeader'
import { PageContent } from 'components/Layout/PageContent'

const Error404 = () => {
  const navigate = useNavigate()

  return (
    <>
      <PageHeader />
      <PageContent>
        <Flex minH="100%" justify="center" align="center">
          <Box textAlign="center" maxW="300" my={20}>
            <GraphicEmptyStateNoData />
            <Box my={20}>
              <Text font="display.extraLarge">404</Text>
            </Box>
            <Box mb={30}>
              <Text color="onSurface.nLv3">
                <FormattedMessage id="404_error_description" />
              </Text>
            </Box>
            <Button onClick={() => navigate('/')} variant="contained" fullWidth>
              <FormattedMessage id="back_to_homepage" />
            </Button>
          </Box>
        </Flex>
      </PageContent>
    </>
  )
}

export default Error404
