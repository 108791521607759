import { IconProps, IconBase } from './Base'

const IconInfo = ({ fill = '#3AB069' }: IconProps) => {
  return (
    <IconBase width={24} height={24} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <circle fill={fill} cx="12" cy="12" r="12" />
        <path d="m10.079 15.022-3.341-3.135-1.114 1.045 4.455 4.18 9.545-8.956-1.113-1.045z" fill="#FFF" />
      </g>
    </IconBase>
  )
}

export default IconInfo
