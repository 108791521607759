import { mapFootballPositionToGroupKey, LineupType } from 'features/AddLineups'
import { FootballPitchLineups } from 'widgets/PitchLineups'
import { ListLineups } from 'widgets/ListLineups'

import { LineupsLayout } from '../../LineupsLayout'
import { LineupsActionsContainer } from '../../LineupsActionsContainer'
import { responseToInitialState } from '../utils'
import { FootballLineupsLoader } from './FootballLineupsLoader'
import { SaveLineups, SwitchLineups } from './FootballLineupsActions'

export const FootballLineups = () => {
  return (
    <LineupsLayout>
      <FootballLineupsLoader transformer={responseToInitialState}>
        {(type, setType) =>
          type === LineupType.PITCH ? (
            <>
              <FootballPitchLineups />
              <LineupsActionsContainer>
                <SwitchLineups lineupType={LineupType.PITCH} setLineupType={setType} />
                <SaveLineups lineupType={LineupType.PITCH} />
              </LineupsActionsContainer>
            </>
          ) : (
            <>
              <ListLineups positionGroupMapping={mapFootballPositionToGroupKey} />
              <LineupsActionsContainer>
                <SwitchLineups lineupType={LineupType.PLAYER_LIST} setLineupType={setType} />
                <SaveLineups lineupType={LineupType.PLAYER_LIST} />
              </LineupsActionsContainer>
            </>
          )
        }
      </FootballLineupsLoader>
    </LineupsLayout>
  )
}
