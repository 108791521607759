import { AnyObject, StringMap } from 'shared/model'

export const socialLoginPromises = {
  fbLogin: (options: StringMap) =>
    new Promise((resolve, reject) => {
      window.FB.login((response: AnyObject) => {
        if (response.authResponse) {
          resolve(response.authResponse)
        } else {
          reject(response.status)
        }
      }, options)
    }),
  fbGet: (options: StringMap, path: string) =>
    new Promise(resolve => {
      window.FB.api(path, options, (me: AnyObject) => resolve(me))
    }),
}

/**
 * Check if credential management is supported from browser
 */
export const hasCredentials = () =>
  typeof PasswordCredential !== 'undefined' || typeof FederatedCredential !== 'undefined'
