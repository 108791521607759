import { FormattedMessage } from 'react-intl'
import { Box, Text } from '@sofascore/ui'
import { NavLink, useParams } from 'react-router-dom'

import { UniqueTournament } from 'modules/MyCompetitions/interface'
import { backgrounds, textPrimary } from 'styles/color'

import CompetitionsItem from './CompetitionItem'

interface SportLeagueListProps {
  sport: string
  competitions: UniqueTournament[]
}

export const SportLeagueList = ({ sport, competitions }: SportLeagueListProps) => {
  const { competitionId } = useParams()

  return (
    <Box bg="surface.s1" br="sm" mb="sm" pb="sm">
      <Box px="lg" py={14}>
        <Text font="display.medium">
          <FormattedMessage id={sport} />
        </Text>
      </Box>

      {competitions.map(({ seasons, uniqueTournament }) => (
        <NavLink
          key={uniqueTournament.id}
          to={`/my-competitions/${uniqueTournament.id}/season/${seasons[0]?.id}`}
          style={({ isActive }) => {
            return {
              textDecoration: 'none',
              color: textPrimary,
              background: isActive || Number(competitionId) === uniqueTournament.id ? backgrounds.chip : undefined,
            }
          }}
        >
          <CompetitionsItem
            id={uniqueTournament.id}
            name={uniqueTournament.name}
            category={uniqueTournament.category}
            routeBase={`/my-competitions/${uniqueTournament.id}/season/${seasons[0]?.id}`}
            uniqueTournament={uniqueTournament}
          />
        </NavLink>
      ))}
    </Box>
  )
}
