import * as React from 'react'

import { secondaryColor } from 'styles/color'

import { IconBase, IconProps } from './Base'

export const IconDragAndDrop = (props: IconProps) => (
  <IconBase width={props.width || 20} height={props.height || 8} viewBox="0 0 20 8" {...props}>
    <g clipPath="url(#clip0_8252_56104)">
      <path d="M20 0H0V2H20V0Z" fill={props.fill || secondaryColor} />
      <path d="M20 6H0V8H20V6Z" fill={props.fill || secondaryColor} />
    </g>
    <defs>
      <clipPath id="clip0_8252_56104">
        <rect width="20" height="8" fill="white" />
      </clipPath>
    </defs>
  </IconBase>
)
