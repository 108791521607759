import styled, { css } from 'styled-components'

import { RED, YELLOW } from 'styles/color'

import { BadgeProps } from './interface'

const warningStyles = css`
  color: ${YELLOW.warning};
  background-color: ${YELLOW.light};
`

const errorStyles = css`
  color: ${RED.action};
  background-color: ${RED.light};
`

export const Badge = styled.div<BadgeProps>`
  display: inline-flex;
  padding: 6px 8px;
  border: 1px solid currentColor;
  border-radius: 4px;

  ${p => p.variant === 'warning' && warningStyles}
  ${p => p.variant === 'error' && errorStyles}
`
