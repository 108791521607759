import { useCallback, useEffect, useMemo, useState } from 'react'
import { BasicEvent } from 'entities/Event'

import { EventListResponse } from '../interface'
import { usePaginatedEvents } from './usePaginatedEvents'
import { useCompetitionMatchesState } from '../CompetitionMatchesContext'

interface EventsHookProps {
  competitionId: string | undefined
  seasonId: string | undefined
}

const INITIAL_LAST_EVENTS_COUNT = 4

export const useEvents = ({ competitionId, seasonId }: EventsHookProps) => {
  const [initialLastEventsLoaded, setInitialLastEventsLoaded] = useState(false)
  const [areInitialEventsLoaded, setAreInitialEventsLoaded] = useState(false)

  const { revalidatedEvents } = useCompetitionMatchesState()

  const {
    data: lastEventPages,
    isLoading: areLastEventsLoading,
    size: lastEventPagesSize,
    loadMoreEvents: _loadMoreLastEvents,
    hasMoreEvents: hasMoreLastEvents,
  } = usePaginatedEvents({
    competitionId,
    seasonId,
    isPast: true,
  })

  const {
    data: nextEventPages,
    isLoading: areNextEventsLoading,
    error: nextEventsError,
    size: nextEventPagesSize,
    loadMoreEvents: loadMoreNextEvents,
    hasMoreEvents: hasMoreNextEvents,
  } = usePaginatedEvents({
    competitionId,
    seasonId,
    isPast: false,
  })

  const loadMoreLastEvents = useCallback(() => {
    if (lastEventPagesSize === 1 && !initialLastEventsLoaded) {
      setInitialLastEventsLoaded(true)
    } else {
      _loadMoreLastEvents()
    }
  }, [_loadMoreLastEvents, setInitialLastEventsLoaded, lastEventPagesSize, initialLastEventsLoaded])

  const _hasMoreLastEvents =
    lastEventPagesSize === 1 && !initialLastEventsLoaded
      ? lastEventPages &&
        lastEventPages[lastEventPagesSize - 1] &&
        lastEventPages[lastEventPagesSize - 1].events.length >= INITIAL_LAST_EVENTS_COUNT
      : hasMoreLastEvents

  const events = useMemo(() => {
    const _last = lastEventPages
      ? lastEventPages.reduce((allEvents: BasicEvent[], currentPageEvents: EventListResponse, index) => {
          return allEvents.concat(
            currentPageEvents.events
              .map(e => ({ ...e, page: -1 * (index + 1) }))
              .slice(index === 0 && !initialLastEventsLoaded ? -1 * INITIAL_LAST_EVENTS_COUNT : 0),
          )
        }, [])
      : []

    const _next = nextEventPages
      ? nextEventPages.reduce((allEvents: BasicEvent[], currentPageEvents: EventListResponse, index) => {
          return allEvents.concat(currentPageEvents.events.map(e => ({ ...e, page: index + 1 })))
        }, [])
      : []

    return [..._last, ..._next, ...revalidatedEvents]
  }, [lastEventPages, nextEventPages, initialLastEventsLoaded, revalidatedEvents])

  useEffect(() => {
    if (!areLastEventsLoading && !areNextEventsLoading && !areInitialEventsLoaded) setAreInitialEventsLoaded(true)
  }, [events, nextEventsError, areInitialEventsLoaded, areLastEventsLoading, areNextEventsLoading])

  return {
    events,
    areInitialEventsLoaded,
    areLastEventsLoading,
    lastEventPagesSize,
    loadMoreLastEvents,
    hasMoreLastEvents: !!_hasMoreLastEvents,
    areNextEventsLoading,
    nextEventPagesSize,
    loadMoreNextEvents,
    hasMoreNextEvents,
  }
}
