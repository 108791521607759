import { Box, Flex } from '@sofascore/ui'
import { PropsWithChildren, ReactNode } from 'react'

import * as S from './styles'
import { Player } from '../model'
import { PlayerImage } from './PlayerImage'
import { PlayerInfo } from './PlayerInfo'

interface PlayerCardProps {
  player: Player
  badgeContent?: ReactNode
  bottomContent?: ReactNode
  showJerseyNumber?: boolean
}

export const PlayerCard = ({
  player,
  badgeContent,
  bottomContent,
  showJerseyNumber,
  children,
}: PropsWithChildren<PlayerCardProps>) => {
  const isJerseyNumberDisplayed = showJerseyNumber && player.jerseyNumber

  return (
    <Flex gapX="lg" align="center" minW={0}>
      <Flex position="relative" align="center" h={42}>
        <PlayerImage player={player} />
        {badgeContent && <S.PlayerCardBadge>{badgeContent}</S.PlayerCardBadge>}
      </Flex>

      <Flex direction="column" minW={0} gapY={4} grow={1}>
        <S.PlayerName ellipsis>
          {isJerseyNumberDisplayed ? `${player.jerseyNumber} ` : ''}
          {player.name}
        </S.PlayerName>

        {bottomContent || <PlayerInfo player={player} />}
      </Flex>

      <Box h="100%">{children}</Box>
    </Flex>
  )
}
