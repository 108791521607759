import { AnyAction } from 'redux'
import { ApiDomain } from 'shared/api'

import { getBrowserLocale } from 'utils/locale'
import { DEV_HOST_NAME, PRODUCTION_HOST_NAME, isProd } from 'api/constants'

export interface UIControlsState {
  privacyAccepted: boolean
  selectedLocale: string
  periodScoreUpdated?: boolean
  apiBaseBranch: string
  apiDomain: ApiDomain
}

export const SET_PRIVACY = 'SET_PRIVACY'
export const SET_LOCALE = 'SET_LOCALE'
export const SET_PERIOD_SCORE_UPDATED = 'SET_PERIOD_SCORE_UPDATED'
export const SET_API_BASE_BRANCH = 'SET_BASE_BRANCH'
export const SET_API_DOMAIN = 'SET_API_DOMAIN'

export const setApiBaseBranch = (branch: string) => ({
  type: SET_API_BASE_BRANCH,
  payload: branch,
})

export const setApiDomain = (domain: string) => ({
  type: SET_API_DOMAIN,
  payload: domain,
})

const getInitialState = (): UIControlsState => ({
  privacyAccepted: false,
  selectedLocale: getBrowserLocale(),
  apiBaseBranch: isProd ? PRODUCTION_HOST_NAME : DEV_HOST_NAME,
  apiDomain: 'dev',
})

const reducer = (state: UIControlsState = getInitialState(), action: AnyAction) => {
  switch (action.type) {
    case SET_PRIVACY:
      return { ...state, privacyAccepted: true }
    case SET_LOCALE:
    case SET_PERIOD_SCORE_UPDATED:
      return { ...state, ...action.payload }
    case SET_API_BASE_BRANCH:
      return { ...state, apiBaseBranch: action.payload }
    case SET_API_DOMAIN:
      return { ...state, apiDomain: action.payload }
    default:
      return state
  }
}

export default reducer
