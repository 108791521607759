import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import Error404 from 'pages/Error404'
import { Login } from 'pages/Login'
import { MatchInfo } from 'pages/MatchInfo'
import { Lineups } from 'pages/Lineups'
import { EditLiveMatch } from 'pages/EditLiveMatch'
import { EditPostMatch } from 'pages/EditPostMatch'
import { PermissionAction, PermissionScope } from 'entities/Permission'
import { AdminInfo } from 'pages/AdminInfo'

import Home from 'modules/Home'
import { MyCompetitions } from 'modules/MyCompetitions'
import { MyCompetition } from 'modules/MyCompetitions/MyCompetition'
import { MyCompetitionInfo } from 'modules/MyCompetitions/MyCompetitionInfo'
import { MyCompetitionMatches } from 'modules/MyCompetitions/MyCompetitionMatches'
import { MyCompetitionTeams } from 'modules/MyCompetitions/MyCompetitionTeams'
import { MyCompetitionStandings } from 'modules/MyCompetitions/MyCompetitionStandings'
import { MyCompetitionKnockout } from 'modules/MyCompetitions/MyCompetitionKnockout'
import { WhatsNewContainer } from 'modules/WhatsNew'
import { MyCompetitionsContextLayout } from 'modules/MyCompetitions/providers/MyCompetitionsContextLayout'
import TeamDetailsContainer, { TeamInfo, TeamPlayers, TeamMatches } from 'modules/TeamDetailsRedesign'
import { loadCompetitionPermissions } from 'modules/MyCompetitions/loader'
import { MyCompetitionLayout } from 'modules/MyCompetitions/MyCompetitionLayout/MyCompetitionLayout'
import { RedirectWithLocationState } from 'components/RedirectWithLocationState'
import MyCompetitionPermissionsOutlet from 'modules/MyCompetitions/components/MyCompetitionPermissionsOutlet'

import AuthGuard from './AuthGuard'
import { MatchDetailsLayout, AdminLayout } from './layouts'

const routes = (
  <>
    <Route path="login" element={<Login />} />

    <Route element={<AuthGuard />} errorElement={<Error404 />}>
      <Route path="home" element={<Home />} />
      <Route index element={<Navigate to="home" />} />

      <Route element={<MyCompetitionsContextLayout />}>
        <Route path="my-competitions" element={<MyCompetitions />} />

        <Route
          path="my-competitions/:competitionId/season/:seasonId"
          loader={loadCompetitionPermissions}
          id="competitionRoutes"
          element={<MyCompetition />}
        >
          <Route path="matches/:eventId" element={<MyCompetitionPermissionsOutlet scopes={[PermissionScope.Event]} />}>
            <Route element={<MatchDetailsLayout />}>
              <Route path="info" element={<MatchInfo />} />
              <Route index element={<RedirectWithLocationState to="info" />} />

              <Route
                element={
                  <MyCompetitionPermissionsOutlet
                    scopes={[PermissionScope.Event]}
                    actions={[PermissionAction.Update]}
                  />
                }
              >
                <Route path="lineups" element={<Lineups />} />
                <Route path="live" element={<EditLiveMatch />} />
                <Route path="post-match" element={<EditPostMatch />} />
              </Route>
            </Route>
          </Route>

          <Route element={<AdminLayout />}>
            <Route
              path="settings"
              element={<MyCompetitionPermissionsOutlet scopes={[PermissionScope.UniqueTournamentSettings]} />}
            >
              <Route path="info" element={<AdminInfo />} />
              <Route index element={<RedirectWithLocationState to="info" />} />
            </Route>
          </Route>

          <Route element={<MyCompetitionLayout />}>
            <Route path="matches" element={<MyCompetitionPermissionsOutlet scopes={[PermissionScope.Event]} />}>
              <Route index element={<MyCompetitionMatches />} />
            </Route>

            <Route path="teams" element={<MyCompetitionPermissionsOutlet scopes={[PermissionScope.Team]} />}>
              <Route index element={<MyCompetitionTeams />} />
            </Route>

            <Route path="standings" element={<MyCompetitionPermissionsOutlet scopes={[PermissionScope.Standings]} />}>
              <Route index element={<MyCompetitionStandings />} />
            </Route>

            <Route path="knockout" element={<MyCompetitionPermissionsOutlet scopes={[PermissionScope.CupTree]} />}>
              <Route index element={<MyCompetitionKnockout />} />
            </Route>

            <Route path="info" element={<MyCompetitionInfo />} />

            <Route index element={<Navigate to="matches" replace />} />
          </Route>
        </Route>
      </Route>

      <Route element={<MyCompetitionsContextLayout />}>
        <Route path="team/:teamId" element={<TeamDetailsContainer />}>
          <Route path="info" element={<TeamInfo />} />
          <Route path="squad" element={<TeamPlayers />} />
          <Route path="matches" element={<TeamMatches />} />
          <Route index element={<RedirectWithLocationState to="info" />} />
        </Route>
      </Route>

      <Route path="whats-new" element={<WhatsNewContainer />} />

      <Route path="*" element={<Error404 />} />
    </Route>
  </>
)

export const router = createBrowserRouter(createRoutesFromElements(routes))
