import { Dispatch, PropsWithChildren, createContext, useReducer } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Action, ToastState } from '../model'

const initialState: ToastState = {
  messages: [],
}

export const ToastStateContext = createContext<ToastState>(initialState)
export const ToastDispatchContext = createContext<Dispatch<Action> | null>(null)

export const ENQUEUE_TOAST = 'ENQUEUE_TOAST'
export const DEQUEUE_TOAST = 'DEQUEUE_TOAST'

const toastReducer = (state: ToastState, action: Action) => {
  switch (action.type) {
    case ENQUEUE_TOAST: {
      return {
        ...state,
        messages: [...state.messages, { ...action.payload, id: uuidv4() }],
      }
    }
    case DEQUEUE_TOAST: {
      return {
        ...state,
        messages: state.messages.filter(m => m.id !== action.payload),
      }
    }
    default: {
      return state
    }
  }
}

export const ToastProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(toastReducer, initialState)

  return (
    <ToastStateContext.Provider value={state}>
      <ToastDispatchContext.Provider value={dispatch}>{children}</ToastDispatchContext.Provider>
    </ToastStateContext.Provider>
  )
}
