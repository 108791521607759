import * as React from 'react'

import { secondaryColor } from 'styles/color'

import { IconBase, IconProps } from './Base'

export const IconAddImageOutlined = (props: IconProps) => (
  <IconBase width={props.width || 24} height={props.height || 24} viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
        fill={secondaryColor}
      />
    </g>
  </IconBase>
)
