import { Flex, Text } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'

const ErrorMessage = ({ msg }: { msg?: string }) => {
  return (
    <Flex pt={150} w="100%" justify="center">
      <Text fontSize="extraLarge">{msg ?? <FormattedMessage id="toastError" />}</Text>
    </Flex>
  )
}

export default ErrorMessage
