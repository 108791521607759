import { IconBase, IconProps } from './Base'

export const IconLogoLettering = ({ fill, ...rest }: IconProps) => (
  <IconBase width={267} height={30} viewBox="0 0 267 30" fill="#fff" {...rest}>
    <path
      d="M16.9832 14.6401C15.5395 14.0081 13.5934 13.6161 11.1447 13.4721C9.11881 13.3521 7.72301 13.0081 6.97326 12.4481C6.21553 11.8881 5.84066 11.1041 5.84066 10.0881C5.84066 9.14409 6.23149 8.36009 7.00516 7.72809C7.78681 7.09609 8.86358 6.77609 10.2514 6.77609C11.7828 6.77609 13.0111 7.12009 13.9443 7.81609C14.8376 8.48809 15.38 9.44809 15.5714 10.6961C15.5874 10.7761 15.6512 10.8321 15.731 10.8321H20.389C20.4847 10.8321 20.5565 10.7521 20.5485 10.6561C20.2693 8.04009 19.2404 6.01609 17.4538 4.58409C15.6273 3.12009 13.2504 2.39209 10.3312 2.39209C8.55252 2.39209 6.96528 2.72009 5.5615 3.37609C4.16569 4.03209 3.05702 4.95209 2.24346 6.12809C1.42991 7.30409 1.02313 8.66409 1.02313 10.2001C1.02313 12.0081 1.42193 13.4481 2.22751 14.5121C3.03309 15.5761 4.15771 16.3601 5.60138 16.8561C7.04504 17.3521 8.75989 17.6641 10.738 17.7841C12.6682 17.9121 14.056 18.2561 14.9094 18.8241C15.7629 19.3921 16.1856 20.2641 16.1856 21.4241C16.1856 22.5681 15.731 23.4801 14.8297 24.1601C13.9284 24.8401 12.6442 25.1841 10.9932 25.1841C9.46178 25.1841 8.18562 24.8321 7.17266 24.1201C6.19161 23.4321 5.5934 22.3841 5.37805 20.9681C5.37007 20.8881 5.29829 20.8321 5.21853 20.8321H0.560517C0.464804 20.8321 0.39302 20.9121 0.400996 21.0001C0.544565 22.8801 1.09491 24.4561 2.06001 25.7201C3.04904 27.0241 4.31723 27.9921 5.86459 28.6241C7.41194 29.2561 9.11084 29.5761 10.9613 29.5761C12.9393 29.5761 14.6941 29.2241 16.2255 28.5121C17.7569 27.8081 18.9533 26.8241 19.8067 25.5761C20.6602 24.3201 21.0829 22.8801 21.0829 21.2401C21.0829 19.6801 20.7638 18.3441 20.1178 17.2401C19.4797 16.1441 18.4348 15.2721 16.9832 14.6401Z"
      fill={fill}
    />
    <path
      d="M37.123 10.3682C35.6793 9.5842 34.1001 9.2002 32.3932 9.2002C30.6385 9.2002 29.0432 9.5922 27.6076 10.3682C26.1719 11.1522 25.0393 12.2962 24.1938 13.8082C23.3563 15.3202 22.9336 17.1682 22.9336 19.3522C22.9336 21.5362 23.3563 23.3842 24.1938 24.8962C25.0313 26.4082 26.1719 27.5602 27.6076 28.3522C29.0432 29.1442 30.6385 29.5442 32.3932 29.5442C34.1001 29.5442 35.6793 29.1442 37.123 28.3522C38.5666 27.5602 39.7232 26.4082 40.5926 24.8962C41.454 23.3842 41.8927 21.5362 41.8927 19.3522C41.8927 17.1682 41.4619 15.3202 40.5926 13.8082C39.7311 12.2962 38.5746 11.1442 37.123 10.3682ZM35.8069 23.6802C34.9694 24.6882 33.8289 25.1842 32.3932 25.1842C30.9575 25.1842 29.8249 24.6802 29.0034 23.6802C28.1739 22.6722 27.7591 21.2402 27.7591 19.3842C27.7591 17.4962 28.1818 16.0562 29.0193 15.0482C29.8568 14.0402 30.9814 13.5442 32.3932 13.5442C33.8289 13.5442 34.9615 14.0482 35.8069 15.0482C36.6444 16.0562 37.0671 17.4962 37.0671 19.3842C37.0671 21.2482 36.6524 22.6802 35.8069 23.6802Z"
      fill={fill}
    />
    <path
      d="M47.4349 2.5922C46.4299 3.4482 45.9354 4.7522 45.9354 6.5202V9.4082C45.9354 9.4962 45.8636 9.5682 45.7758 9.5682H43.056C42.9683 9.5682 42.8965 9.6402 42.8965 9.7282V13.8002C42.8965 13.8882 42.9683 13.9602 43.056 13.9602H45.7758C45.8636 13.9602 45.9354 14.0322 45.9354 14.1202V29.0482C45.9354 29.1362 46.0071 29.2082 46.0949 29.2082H50.4498C50.5375 29.2082 50.6093 29.1362 50.6093 29.0482V14.1122C50.6093 14.0242 50.6811 13.9522 50.7688 13.9522H55.4907C55.5784 13.9522 55.6502 13.8802 55.6502 13.7922V9.7202C55.6502 9.6322 55.5784 9.5602 55.4907 9.5602H50.7688C50.6811 9.5602 50.6093 9.4882 50.6093 9.4002V7.4402C50.6093 6.2722 51.1038 5.6882 52.0929 5.6882H55.4907C55.5784 5.6882 55.6502 5.6162 55.6502 5.5282V1.4642C55.6502 1.3762 55.5784 1.3042 55.4907 1.3042H51.1995C49.6921 1.3042 48.4398 1.7362 47.4349 2.5922Z"
      fill={fill}
    />
    <path
      d="M72.1371 11.1279C70.5658 9.83989 68.4442 9.19189 65.7802 9.19189C64.2249 9.19189 62.8131 9.45589 61.5529 9.97589C60.2927 10.4959 59.2638 11.2559 58.4741 12.2479C57.7164 13.1999 57.2538 14.3279 57.0863 15.6399C57.0703 15.7359 57.1501 15.8159 57.2458 15.8159H61.6805C61.7603 15.8159 61.8241 15.7599 61.84 15.6879C62.0075 14.9279 62.3904 14.3359 63.0045 13.9039C63.6506 13.4479 64.5598 13.2159 65.7483 13.2159C68.4681 13.2159 69.832 14.3599 69.832 16.6399V16.9279C69.832 17.0159 69.7602 17.0879 69.6725 17.0879H64.6795C62.2548 17.0879 60.3325 17.6479 58.9128 18.7599C57.4931 19.8799 56.7832 21.4239 56.7832 23.4079C56.7832 24.6479 57.0065 25.6719 57.4532 26.4799C57.8998 27.2879 58.4741 27.9279 59.176 28.3919C59.8779 28.8639 60.6516 29.1919 61.497 29.3759C62.3345 29.5599 63.1401 29.6559 63.9058 29.6559C65.6605 29.6559 67.1281 29.3119 68.3006 28.6319C68.9307 28.2639 69.4731 27.8399 69.9357 27.3439C70.0314 27.2399 70.2149 27.3119 70.2149 27.4559V29.0559C70.2149 29.1439 70.2866 29.2159 70.3744 29.2159H74.3544C74.4422 29.2159 74.514 29.1439 74.514 29.0559V16.6319C74.49 14.2479 73.7084 12.4159 72.1371 11.1279ZM67.1122 25.2639C66.3465 25.6079 65.6047 25.7839 64.8869 25.7839C63.8739 25.7839 63.0604 25.5759 62.4382 25.1679C61.8161 24.7599 61.513 24.1359 61.513 23.2879C61.513 22.4719 61.8161 21.8479 62.4223 21.4319C63.0284 21.0079 63.9856 20.7999 65.2936 20.7999H69.6565C69.7443 20.7999 69.8161 20.8719 69.8161 20.9599V21.0639C69.8161 22.1279 69.5529 23.0079 69.0344 23.7039C68.5239 24.3919 67.8779 24.9119 67.1122 25.2639Z"
      fill={fill}
    />
    <path
      d="M92.2609 18.8482C90.9768 17.9442 88.8472 17.4002 85.8801 17.2322C84.7395 17.1842 83.8781 17.0802 83.2879 16.9122C82.6976 16.7522 82.2909 16.5362 82.0835 16.2642C81.8761 15.9922 81.7644 15.6482 81.7644 15.2242C81.7644 14.6322 82.0436 14.1442 82.6019 13.7762C83.1603 13.4082 83.9898 13.2162 85.1064 13.2162C86.1672 13.2162 87.0446 13.4162 87.7385 13.8082C88.3925 14.1842 88.7834 14.7362 88.903 15.4642C88.919 15.5362 88.9828 15.5922 89.0625 15.5922H93.4972C93.5929 15.5922 93.6647 15.5122 93.6567 15.4162C93.4254 13.4722 92.572 11.9522 91.0964 10.8722C89.573 9.7602 87.579 9.2002 85.1064 9.2002C83.5511 9.2002 82.1712 9.4642 80.9748 9.9842C79.7784 10.5042 78.8372 11.2242 78.1593 12.1442C77.4813 13.0642 77.1383 14.1442 77.1383 15.3842C77.1383 16.7762 77.4733 17.8802 78.1433 18.6962C78.8133 19.5122 79.7625 20.1122 80.9987 20.4802C82.235 20.8482 83.7186 21.0722 85.4494 21.1522C87.0526 21.2242 88.1293 21.4322 88.6797 21.7682C89.2221 22.1042 89.4932 22.6162 89.4932 23.3122C89.4932 24.0082 89.1902 24.5602 88.584 24.9682C87.9778 25.3762 87.0207 25.5842 85.7126 25.5842C84.5481 25.5842 83.567 25.3922 82.7615 25.0082C81.9958 24.6402 81.5571 24.0722 81.4295 23.2962C81.4135 23.2242 81.3497 23.1682 81.2699 23.1682H76.8672C76.7714 23.1682 76.6997 23.2482 76.7076 23.3362C76.8033 24.7202 77.25 25.8722 78.0317 26.7762C78.8452 27.7202 79.914 28.4242 81.238 28.8962C82.5621 29.3682 84.0376 29.6002 85.6727 29.6002C87.4753 29.6002 89.0147 29.3122 90.2908 28.7442C91.567 28.1762 92.5321 27.3922 93.2021 26.4002C93.8721 25.4082 94.2071 24.2962 94.2071 23.0482C94.1832 21.1442 93.5451 19.7522 92.2609 18.8482Z"
      fill={fill}
    />
    <path
      d="M107.869 24.52C107.08 24.968 106.226 25.192 105.309 25.192C103.873 25.192 102.741 24.696 101.919 23.704C101.09 22.712 100.675 21.272 100.675 19.392C100.675 17.504 101.098 16.064 101.935 15.056C102.773 14.048 103.897 13.552 105.309 13.552C106.346 13.552 107.223 13.816 107.941 14.336C108.627 14.84 109.09 15.616 109.321 16.664C109.337 16.736 109.401 16.792 109.481 16.792H113.955C114.051 16.792 114.131 16.704 114.115 16.608C113.772 14.12 112.799 12.272 111.211 11.064C109.576 9.82401 107.59 9.20801 105.237 9.20801C103.578 9.20801 102.039 9.59201 100.619 10.36C99.1994 11.128 98.0508 12.272 97.1735 13.784C96.2961 15.296 95.8574 17.16 95.8574 19.368C95.8574 21.576 96.2802 23.432 97.1176 24.952C97.9551 26.464 99.0877 27.608 100.507 28.392C101.927 29.176 103.506 29.56 105.237 29.56C106.649 29.56 107.981 29.304 109.241 28.8C110.501 28.288 111.562 27.504 112.432 26.44C113.261 25.416 113.843 24.144 114.17 22.616C114.194 22.52 114.115 22.424 114.011 22.424H109.544C109.473 22.424 109.409 22.472 109.393 22.544C109.138 23.424 108.627 24.088 107.869 24.52Z"
      fill={fill}
    />
    <path
      d="M130.18 10.3682C128.736 9.5842 127.157 9.2002 125.45 9.2002C123.695 9.2002 122.1 9.5922 120.664 10.3682C119.229 11.1522 118.096 12.2962 117.25 13.8082C116.413 15.3202 115.99 17.1682 115.99 19.3522C115.99 21.5362 116.413 23.3842 117.25 24.8962C118.088 26.4082 119.229 27.5602 120.664 28.3522C122.1 29.1442 123.695 29.5442 125.45 29.5442C127.157 29.5442 128.736 29.1442 130.18 28.3522C131.623 27.5602 132.78 26.4082 133.649 24.8962C134.511 23.3842 134.949 21.5362 134.949 19.3522C134.949 17.1682 134.519 15.3202 133.649 13.8082C132.78 12.2962 131.623 11.1442 130.18 10.3682ZM128.864 23.6802C128.026 24.6882 126.886 25.1842 125.45 25.1842C124.014 25.1842 122.882 24.6802 122.06 23.6802C121.231 22.6722 120.816 21.2402 120.816 19.3842C120.816 17.4962 121.238 16.0562 122.076 15.0482C122.913 14.0402 124.038 13.5442 125.45 13.5442C126.886 13.5442 128.018 14.0482 128.864 15.0482C129.701 16.0562 130.124 17.4962 130.124 19.3842C130.124 21.2482 129.701 22.6802 128.864 23.6802Z"
      fill={fill}
    />
    <path
      d="M142.806 10.5841C142.503 10.8721 142.216 11.2081 141.944 11.5841C141.857 11.7121 141.657 11.6481 141.657 11.4881V9.72006C141.657 9.63206 141.586 9.56006 141.498 9.56006H137.478C137.39 9.56006 137.318 9.63206 137.318 9.72006V29.0401C137.318 29.1281 137.39 29.2001 137.478 29.2001H141.833C141.921 29.2001 141.992 29.1281 141.992 29.0401V17.9361C141.992 16.9681 142.144 16.1921 142.439 15.6081C142.734 15.0241 143.133 14.6001 143.627 14.3441C144.122 14.0801 144.688 13.9521 145.334 13.9521H148.924C149.011 13.9521 149.083 13.8801 149.083 13.7921V9.72006C149.083 9.63206 149.011 9.56006 148.924 9.56006H145.964C144.577 9.56806 143.524 9.90406 142.806 10.5841Z"
      fill={fill}
    />
    <path
      d="M167.029 13.8242C166.239 12.2962 165.155 11.1442 163.783 10.3682C162.411 9.5922 160.872 9.2002 159.165 9.2002C157.506 9.2002 155.974 9.6082 154.562 10.4242C153.151 11.2402 152.018 12.4082 151.173 13.9202C150.319 15.4322 149.896 17.2562 149.896 19.3922C149.896 21.5762 150.303 23.4242 151.117 24.9362C151.93 26.4482 153.031 27.5922 154.419 28.3762C155.807 29.1602 157.346 29.5442 159.053 29.5442C161.23 29.5442 163.129 29.0082 164.764 27.9442C166.351 26.9122 167.388 25.3282 167.874 23.1922C167.898 23.0962 167.819 23.0002 167.715 23.0002H163.209C163.137 23.0002 163.081 23.0482 163.057 23.1122C162.802 23.8962 162.347 24.4642 161.685 24.8002C160.991 25.1602 160.154 25.3362 159.165 25.3362C157.657 25.3362 156.517 24.8242 155.735 23.8082C155.153 23.0482 154.786 22.0242 154.642 20.7522C154.634 20.6562 154.706 20.5762 154.802 20.5762H168.01C168.09 20.5762 168.154 20.5202 168.17 20.4402C168.202 20.2882 168.217 20.1362 168.217 19.9922C168.217 19.8082 168.217 19.6002 168.217 19.3762C168.217 17.2002 167.819 15.3522 167.029 13.8242ZM159.125 13.2482C160.584 13.2482 161.653 13.7042 162.331 14.6242C162.77 15.2082 163.065 15.9442 163.216 16.8162C163.232 16.9122 163.161 17.0082 163.057 17.0082H155.009C154.905 17.0082 154.826 16.9122 154.858 16.8082C155.057 16.0322 155.352 15.3682 155.759 14.8322C156.541 13.7762 157.665 13.2482 159.125 13.2482Z"
      fill={fill}
    />
    <path
      d="M176.057 2.38428H173.68V28.8323H176.057H177.173H191.115V25.4883H177.173V16.8963H191.115V13.5443H177.173V5.73628H191.115V2.38428H177.173H176.057Z"
      fill={fill}
    />
    <path
      d="M207.363 11.456C206.892 10.848 206.366 10.352 205.768 9.95204C204.627 9.19204 203.343 8.81604 201.907 8.81604C200.296 8.81604 198.829 9.19204 197.497 9.95204C196.165 10.712 195.088 11.848 194.29 13.376C193.485 14.904 193.086 16.792 193.086 19.048C193.086 21.304 193.485 23.184 194.29 24.68C195.096 26.184 196.165 27.312 197.497 28.064C198.829 28.824 200.304 29.2 201.907 29.2C203.343 29.2 204.627 28.824 205.768 28.064C206.478 27.592 207.092 26.984 207.618 26.224V28.824H210.769V0.936035H207.355V11.456H207.363ZM205.951 24.184C205.01 25.352 203.742 25.936 202.131 25.936C200.448 25.936 199.116 25.352 198.127 24.184C197.138 23.016 196.643 21.296 196.643 19.016C196.643 16.736 197.138 15.008 198.127 13.848C199.116 12.68 200.448 12.096 202.131 12.096C203.734 12.096 205.01 12.68 205.951 13.848C206.892 15.016 207.363 16.736 207.363 19.016C207.363 21.296 206.892 23.016 205.951 24.184Z"
      fill={fill}
    />
    <path d="M218.186 9.19189H214.74V28.8319H218.186V9.19189Z" fill={fill} />
    <path d="M218.632 0.936035H214.293V5.13604H218.632V0.936035Z" fill={fill} />
    <path
      d="M228.091 4.61621H224.678V9.19221H221.344V12.3922H224.678V24.4482C224.678 25.9362 225.037 27.0402 225.755 27.7602C226.472 28.4802 227.501 28.8402 228.833 28.8402H233.762V25.6402H229.79C229.272 25.6402 228.857 25.5042 228.546 25.2322C228.235 24.9602 228.084 24.5282 228.084 23.9282V12.3922H233.754V9.19221H228.084V4.61621H228.091Z"
      fill={fill}
    />
    <path
      d="M249.101 9.99222C247.681 9.20822 246.102 8.82422 244.371 8.82422C242.641 8.82422 241.061 9.21622 239.642 9.99222C238.222 10.7762 237.097 11.9202 236.268 13.4322C235.438 14.9442 235.023 16.7922 235.023 18.9762C235.023 21.1842 235.438 23.0402 236.268 24.5602C237.097 26.0722 238.222 27.2242 239.642 28.0162C241.061 28.8082 242.641 29.2082 244.371 29.2082C246.102 29.2082 247.681 28.8082 249.101 28.0162C250.521 27.2242 251.653 26.0722 252.491 24.5602C253.328 23.0482 253.751 21.1842 253.751 18.9762C253.751 16.7922 253.328 14.9442 252.491 13.4322C251.653 11.9202 250.521 10.7762 249.101 9.99222ZM248.655 24.1442C247.626 25.3842 246.198 26.0002 244.371 26.0002C242.545 26.0002 241.117 25.3842 240.104 24.1442C239.091 22.9042 238.581 21.1922 238.581 19.0082C238.581 16.8002 239.091 15.0802 240.12 13.8562C241.149 12.6322 242.561 12.0162 244.363 12.0162C246.19 12.0162 247.618 12.6322 248.647 13.8562C249.675 15.0802 250.186 16.8002 250.186 19.0082C250.194 21.1922 249.683 22.9042 248.655 24.1442Z"
      fill={fill}
    />
    <path
      d="M263.88 9.19189C262.372 9.19189 261.224 9.53589 260.434 10.2319C259.988 10.6239 259.581 11.0959 259.214 11.6399V9.19189H256.023V28.8319H259.437V17.4879C259.437 16.2719 259.637 15.2959 260.027 14.5519C260.426 13.8079 260.937 13.2639 261.567 12.9119C262.197 12.5679 262.859 12.3919 263.553 12.3919H266.998V9.19189H263.88Z"
      fill={fill}
    />
  </IconBase>
)
