import styled from 'styled-components'

import { colorTokens } from 'styles/color'

export const Input = styled.input<{ fontSize?: number }>`
  width: 100%;
  height: 100%;

  padding: 0;

  font-weight: bold;
  font-size: ${p => (p.fontSize ? p.fontSize : 29)}px;
  color: inherit;

  outline: none;
  border: none;
  background-color: transparent;

  &::selection {
    color: white;
    background: ${colorTokens.primary.selected};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    /* Firefox */
    appearance: textfield;
    -moz-appearance: textfield;
  }
`
