/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useRef, useState } from 'react'

/**
 * Helper for "callback ref" pattern implementation.
 *
 * @param deps `useCallback` deps array
 */
function useCallbackRef(deps: any[]): [Element | null, (a: any) => any] {
  const node = useRef<Element>()
  const [, forceUpdate] = useState<string>()

  const callback = useCallback((n: any) => {
    node.current = n
    forceUpdate('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  return [node.current || null, callback]
}

export default useCallbackRef
