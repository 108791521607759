import { Theme, createStyles, makeStyles } from '@material-ui/core'

import { selectColor, neutral, textSecondary, lightGray } from 'styles/color'

export const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    caption: {
      color: lightGray,
      fontSize: 12,
      fontWeight: 500,
      marginBottom: theme.spacing(1),
      display: 'flex',
      justifyContent: 'center',
    },

    setContainer: {
      display: 'flex',
      flexDirection: 'column',
    },

    counterContainer: {
      width: 32,
      height: 32,
      border: `2px solid ${neutral}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    value: {
      fontSize: 20,
      fontWeight: 500,
    },

    setterContainer: {
      position: 'absolute',
      top: '-13px',
      left: `-${theme.spacing(1)}px`,
      transform: 'translate(-100%, 0)',
      flexDirection: 'column',
      backgroundColor: textSecondary,
      padding: 8,
      boxShadow: '-16px 4px 32px 0 rgba(15, 32, 44, 0.25), -4px 4px 8px 0 rgba(15, 32, 44, 0.1)',
    },

    operationItem: {
      backgroundColor: selectColor,
      border: `2px solid ${neutral}`,
      width: 40,
      height: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    iconButton: {
      padding: 0,
      width: 30,
      height: 30,
    },

    input: {
      padding: 0,
      width: 32,
      height: 32,
      border: 'none',
      textAlign: 'center',
      background: 'transparent',

      '&:focus-visible': {
        outline: 'none',
      },
    },
  }),
)
