import { Box, Flex, GraphicEmptyStateAddItem, Text, useMediaContext } from '@sofascore/ui'
import useSWR from 'swr'
import { FormattedMessage } from 'react-intl'

import { Loading } from 'components/Loading'
import { Error } from 'components/Error'
import { SERVER_ERROR_PAGE_MESSAGE, SERVER_ERROR_STATUS_CODE } from 'utils'
import { uniqueTournaments } from 'api/routes'
import CompetitionRequest from 'modules/MyCompetitions/components/CompetitionRequest'
import { PageHeader } from 'components/Layout/PageHeader'
import { PageContent } from 'components/Layout/PageContent'
import EmptyCompetitions from 'modules/MyCompetitions/components/EmptyCompetitions'

import { UniqueTournamentsResponse } from './interface'
import { CompetitionList } from './components/CompetitionList'
import * as S from './styles'

const MyCompetitions = () => {
  const { data: fetchedCompetitions, error } = useSWR<UniqueTournamentsResponse>(uniqueTournaments())

  const { isMobile } = useMediaContext()

  const noCompetitions =
    (error && error?.error?.code !== SERVER_ERROR_STATUS_CODE) ||
    (fetchedCompetitions && fetchedCompetitions.uniqueTournaments && !fetchedCompetitions.uniqueTournaments.length)

  return (
    <>
      <PageHeader pageName={<FormattedMessage id="my_competitions" />}>
        <CompetitionRequest />
      </PageHeader>

      <PageContent>
        {error?.error?.code == SERVER_ERROR_STATUS_CODE && <Error title={SERVER_ERROR_PAGE_MESSAGE} />}

        {!error && !fetchedCompetitions && <Loading />}

        {noCompetitions && (
          <Box mt="140px" maxW={['100%', 700]} w="100%" mx="auto" px={['sm', 0]} pb="sm">
            <EmptyCompetitions />
          </Box>
        )}

        {fetchedCompetitions?.uniqueTournaments?.length && fetchedCompetitions.uniqueTournaments.length > 0 ? (
          <S.MyCompetitions>
            <CompetitionList />

            {!isMobile && (
              <Flex w="75%" direction="column" mx="lg" align="center">
                <Box mt="xxl">
                  <GraphicEmptyStateAddItem />
                </Box>

                <Text mt="lg" fontSize="medium" fontWeight="bold" align="center">
                  <FormattedMessage id="competition_list_not_selected" />
                </Text>
              </Flex>
            )}
          </S.MyCompetitions>
        ) : null}
      </PageContent>
    </>
  )
}

export default MyCompetitions
