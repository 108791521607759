import { Box } from '@sofascore/ui'
import styled, { css } from 'styled-components'

export const Collapse = styled(Box)`
  width: 100%;
  box-sizing: border-box;
`

const leftAlignmentStyles = css`
  justify-content: space-between;
`

const centerAlignmentStyles = css`
  justify-content: center;

  & > .expand-button {
    position: absolute;
    right: 0;
  }
`

export const CollapseHeader = styled(Box)<{ alignTitle: 'left' | 'center' }>`
  display: flex;
  align-items: center;
  position: relative;

  cursor: pointer;

  & > .expand-button {
    display: flex;
    align-items: center;

    transition: transform 2s;
  }

  ${p => p.alignTitle === 'left' && leftAlignmentStyles}
  ${p => p.alignTitle === 'center' && centerAlignmentStyles}
`

/**
 * This wrapper fixes bottom right border radius of the scroller.
 */
export const CollapseContentWrapper = styled(Box)`
  overflow: hidden;

  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
`

export const CollapseContent = styled(Box)`
  transition: height 0.2s ease-in-out;

  overflow: hidden;

  ${p => p.maxH && 'overflow-y: scroll;'}

  /* Width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${p => p.theme.colors.onColor.primary};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${p => p.theme.colors.onSurface.nLv4};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${p => p.theme.colors.onSurface.nLv3};
  }
`
