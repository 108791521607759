import { EventActionType, useEventDispatch, useEventState } from 'entities/Event'
import { Box, Flex, Text, useMediaContext } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'
import { TeamImage } from 'entities/Team'
import { NOT_STARTED_CODE } from 'entities/Status'
import { ScoreCounter } from 'entities/Score'

interface GeneralEditMatchScoreHeaderProps {
  onScoreChange?: () => void
}

export const GeneralEditMatchScoreHeader = ({ onScoreChange }: GeneralEditMatchScoreHeaderProps) => {
  const { event } = useEventState()
  const eventDispatch = useEventDispatch()

  const { isMobile } = useMediaContext()

  const { status, homeTeam, awayTeam, homeScore, awayScore } = event
  const isNotStarted = status.code === NOT_STARTED_CODE

  const handleTotalScoreIncrement = (type: 'home' | 'away') => {
    eventDispatch({ type: EventActionType.INCREMENT_SCORE, payload: { isHome: type === 'home', points: 1 } })

    onScoreChange?.()
  }

  const handleTotalScoreDecrement = (type: 'home' | 'away') => {
    eventDispatch({ type: EventActionType.DECREMENT_SCORE, payload: { isHome: type === 'home', points: 1 } })

    onScoreChange?.()
  }

  return (
    <Flex justify="space-around" bg="surface.s1" br="sm" p="lg" mt="sm" elevation={2}>
      <Flex direction="column" align="center">
        <Flex direction="column" align="center" gapY="sm">
          <Text font="display.micro" maxW={216} ellipsis title={awayTeam.name}>
            {homeTeam.name}
          </Text>

          <TeamImage team={homeTeam} size={40} />
        </Flex>

        {!isNotStarted && (
          <Box mt="xl">
            <ScoreCounter
              value={homeScore.display}
              variant={isMobile ? 'compact' : 'wide'}
              onChange={newScore => {
                eventDispatch({
                  type: EventActionType.SET_SCORES,
                  payload: { homeScore: newScore, awayScore: awayScore.display },
                })

                onScoreChange?.()
              }}
              onIncrement={() => handleTotalScoreIncrement('home')}
              onDecrement={() => handleTotalScoreDecrement('home')}
            />
          </Box>
        )}
      </Flex>

      <Flex align="center" mt={40} h={20}>
        <Text font="display.small">
          <FormattedMessage id="match" />
        </Text>
      </Flex>

      <Flex direction="column" align="center">
        <Flex direction="column" align="center" gapY="sm">
          <Text font="display.micro" maxW={216} ellipsis title={awayTeam.name}>
            {awayTeam.name}
          </Text>

          <TeamImage team={awayTeam} size={40} />
        </Flex>

        {!isNotStarted && (
          <Box mt="xl">
            <ScoreCounter
              value={awayScore.display}
              variant={isMobile ? 'compact' : 'wide'}
              onChange={newScore => {
                eventDispatch({
                  type: EventActionType.SET_SCORES,
                  payload: { homeScore: homeScore.display, awayScore: newScore },
                })

                onScoreChange?.()
              }}
              onIncrement={() => handleTotalScoreIncrement('away')}
              onDecrement={() => handleTotalScoreDecrement('away')}
            />
          </Box>
        )}
      </Flex>
    </Flex>
  )
}
