import { Flex } from '@sofascore/ui'
import { useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import {
  NOT_STARTED_CODE,
  FIRST_HALF_CODE,
  SECOND_HALF_CODE,
  HALF_TIME_CODE,
  PENALTIES_CODE,
  AWAITING_EXTRA_TIME_CODE,
  FIRST_EXTRA_TIME_CODE,
  SECOND_EXTRA_TIME_CODE,
  EXTRA_TIME_HALF_CODE,
} from 'entities/Status'
import { IncidentType, useEventIncidentsState } from 'entities/Incident'
import { useEventState } from 'entities/Event'
import { AddMiniFootballGoalForm } from 'features/AddScore'
import {
  AddTwoMinuteSuspension,
  AddMiniFootballRedCard as AddRedCard,
  AddMiniFootballYellowCard as AddYellowCard,
  AddMiniFootballMissedPenalty as AddMissedPenalty,
  AddMiniFootballShootoutPenalty as AddShootoutPenalty,
  useLiveMatchCreateIncidentHandler,
} from 'features/AddIncident'
import { useLiveMatchEditIncidentHandler } from 'features/EditIncident'
import { useLiveMatchDeleteIncidentHandler } from 'features/DeleteIncident'
import { GameTimeToggle } from 'widgets/GameTimeToggle'
import { ChangeScoreSection } from 'widgets/ChangeScoreSection'
import { AddIncidentSection } from 'widgets/AddIncidentSection'
import { IncidentListSection } from 'widgets/IncidentListSection'
import { PenaltyShootoutSection } from 'widgets/PenaltyShootoutSection'
import { ChangeAccumulatedFoulsSection } from 'widgets/ChangeAccumulatedFoulsSection'
import { MiniFootballEditPeriodSection } from 'widgets/EditPeriodSection'
import { MiniFootballIncidentRenderer, useIncidentExtender } from 'widgets/IncidentRenderer'

import { PeriodTabs } from '../../model'

const defaultNumberOfPenaltySeries = 5

const accFoulsAvailableStatuses = [
  FIRST_HALF_CODE,
  SECOND_HALF_CODE,
  HALF_TIME_CODE,
  AWAITING_EXTRA_TIME_CODE,
  FIRST_EXTRA_TIME_CODE,
  SECOND_EXTRA_TIME_CODE,
  EXTRA_TIME_HALF_CODE,
]

export const MiniFootballEditLiveMatch = () => {
  const { incidents } = useEventIncidentsState()
  const { event } = useEventState()

  const { status } = event

  const isPenaltyShootout = status.code === PENALTIES_CODE
  const hasStarted = status.code !== NOT_STARTED_CODE

  const [periodTab, setPeriodTab] = useState<PeriodTabs>(PeriodTabs.FT)

  const { handleCreate } = useLiveMatchCreateIncidentHandler()
  const { handleEdit } = useLiveMatchEditIncidentHandler()
  const { handleDelete } = useLiveMatchDeleteIncidentHandler()

  // Remove Penalty Shootout incidents from list
  const normalTimeIncidents = incidents
    ? incidents.filter(incident => incident.incidentType !== IncidentType.PenaltyShootout && incident.text !== 'PEN')
    : []

  const penaltyShootoutIncidents = incidents
    ? incidents.filter(incident => incident.incidentType === IncidentType.PenaltyShootout)
    : []

  const areAccumulatedFoulsAvailable = accFoulsAvailableStatuses.includes(status.code)

  useEffect(() => {
    isPenaltyShootout ? setPeriodTab(PeriodTabs.Penalties) : setPeriodTab(PeriodTabs.FT)
  }, [isPenaltyShootout])

  useIncidentExtender()

  return (
    <Flex direction="column" gapY="sm" pb="sm">
      <MiniFootballEditPeriodSection />

      <AnimatePresence>
        {hasStarted && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
          >
            {isPenaltyShootout && (
              <GameTimeToggle
                isPenaltyShootout={periodTab === PeriodTabs.Penalties}
                onRegularTimeButtonClick={() => setPeriodTab(PeriodTabs.FT)}
                onPenaltyShootoutButtonClick={() => setPeriodTab(PeriodTabs.Penalties)}
              />
            )}

            {periodTab === PeriodTabs.FT && (
              <>
                <ChangeScoreSection>
                  {(isOpen, toggleOpen, team, event) => (
                    <AddMiniFootballGoalForm
                      key={isOpen.toString()}
                      isOpen={isOpen}
                      team={team}
                      event={event}
                      onClose={toggleOpen}
                      onSubmit={handleCreate}
                    />
                  )}
                </ChangeScoreSection>

                {areAccumulatedFoulsAvailable && <ChangeAccumulatedFoulsSection />}

                <AddIncidentSection>
                  <AddTwoMinuteSuspension onCreate={handleCreate} />
                  <AddYellowCard onCreate={handleCreate} />
                  <AddRedCard onCreate={handleCreate} />
                  <AddMissedPenalty onCreate={handleCreate} />
                </AddIncidentSection>

                <IncidentListSection incidents={normalTimeIncidents}>
                  {(incident, isLastGoal) => (
                    <MiniFootballIncidentRenderer
                      key={incident.id}
                      incident={incident}
                      isLastGoal={isLastGoal}
                      onEdit={handleEdit}
                      onDelete={handleDelete}
                    />
                  )}
                </IncidentListSection>
              </>
            )}

            {periodTab === PeriodTabs.Penalties && (
              <>
                <PenaltyShootoutSection numberOfPenaltySeries={defaultNumberOfPenaltySeries}>
                  {(home, homeTeam, away, awayTeam, sequence) => (
                    <>
                      <AddShootoutPenalty team={homeTeam} sequence={sequence} {...home} onSubmit={handleCreate} />
                      <AddShootoutPenalty team={awayTeam} sequence={sequence} {...away} onSubmit={handleCreate} />
                    </>
                  )}
                </PenaltyShootoutSection>

                <Flex direction="column" gapY="sm" mt="sm">
                  {penaltyShootoutIncidents.map((pen, index) => (
                    <MiniFootballIncidentRenderer
                      key={pen.id}
                      incident={pen}
                      isLastGoal={index === 0}
                      onEdit={handleEdit}
                      onDelete={handleDelete}
                    />
                  ))}
                </Flex>
              </>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </Flex>
  )
}
