import { Text } from '@sofascore/ui'
import { PropsWithChildren } from 'react'
import { IconTry } from 'shared/ui'
import { FormattedMessage } from 'react-intl'

import { GREEN } from 'styles/color'

import { Incident } from '../../model'
import { IncidentBody, IncidentFrame, IncidentHead, IncidentResult, IncidentTail, IncidentTime } from '../'

interface Props {
  incident: Incident
}

export const IncidentTry = ({ children, incident }: PropsWithChildren<Props>) => {
  const { isHome, time, addedTime, player, homeScore, awayScore } = incident

  return (
    <IncidentFrame>
      <IncidentHead isHome={isHome}>
        <IconTry fill={GREEN.incident} />
        <IncidentTime addedTime={addedTime} time={time} />
      </IncidentHead>

      <IncidentResult isHome={isHome} homeScore={homeScore} awayScore={awayScore} />

      <IncidentBody isHome={isHome}>
        <Text align={isHome ? 'left' : 'right'} font="body.medium">
          {player?.name}
          {!player?.name ? <FormattedMessage id="rugby_try" /> : ''}
        </Text>
      </IncidentBody>

      <IncidentTail>{children}</IncidentTail>
    </IncidentFrame>
  )
}
