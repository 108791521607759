import { DeleteIncidentData, CreateIncidentData, UpdateIncidentData, PostMatchUpdateData } from 'entities/Incident'
import { RoundInfo } from 'entities/Event'
import { CreateAccumulatedFoulData } from 'entities/AccumulatedFoul'
import { EntityType } from 'entities/EntityType'
import { NewSeasonData } from 'entities/Season'
import { DynamicLineupsPayload } from 'features/AddLineups'
import { EmailData } from 'entities/Email'
import { EditorSuggestion, PlayerUpdateSuggest, SuggestionData, UserSuggestionData } from 'entities/Suggestion'
import { LoginPayload } from 'features/Auth'
import { MatchReportData, MatchReportEmails } from 'features/MatchReportDownload/model'

import { TeamValue, UpdateEventBody, UpdateEventLocation, UpdateMatchInfoBody } from 'modules/Matches/interface'
import { CreateDoublesEventData, CreateEventData } from 'modules/Matches/CreateMatch/interface'
import { SortStandingsData } from 'modules/Standings/interface'

import { postJson, getAuthHeader, getJson, deleteJson, putJson } from './utilities'
import * as routes from './routes'

// #region user
export const postUserLogin = (payload: LoginPayload, token: string) =>
  postJson(routes.loginUser(), payload, { headers: getAuthHeader(token) })

export const postUserLogout = (token: string) => postJson(routes.logoutUser(), {}, { headers: getAuthHeader(token) })
// #endregion user

// #region Auth & token
export const fetchRefreshedToken = (token: string) =>
  postJson(routes.tokenRefresh(), {}, { headers: getAuthHeader(token) })

export const fetchToken = (uuid: string, deviceType = 'web') =>
  postJson(routes.tokenInit(), {
    uuid,
    deviceType,
  })
// #endregion Auth & token

// #region unique tournaments
export const fetchUniqueTournamentDetails = (uniqueTournamentId: number) =>
  getJson(routes.uniqueTournamentDetails(uniqueTournamentId))

export const fetchUniqueTournamentSeasons = (uniqueTournamentId: number) =>
  getJson(routes.uniqueTournamentSeasons(uniqueTournamentId))

export const fetchUniqueTournamentTeams = (uniqueTournamentId: number, seasonId: number) =>
  getJson(routes.uniqueTournamentTeams(uniqueTournamentId, seasonId))

export const fetchUniqueTournamentPermissions = (uniqueTournamentId: number) =>
  getJson(routes.uniqueTournamentPermissions(uniqueTournamentId))

export const fetchTournamentsList = (uniqueTournamentId: number, seasonId: number) =>
  getJson(routes.tournamentsList(uniqueTournamentId, seasonId))

// #endregion unique tournaments

// #region event
export const fetchEventListEvents = ({
  competitionId,
  tournamentId,
  seasonId,
  teamId,
  roundInfo,
  page = 0,
  isPast = false,
}: {
  competitionId: number
  tournamentId: number
  seasonId: number
  teamId?: number
  roundInfo?: RoundInfo
  page?: number
  isPast?: boolean
}) => {
  if (teamId) {
    return getJson(routes.eventListEventsByTeam(competitionId, seasonId, teamId, page, isPast))
  }
  if (roundInfo) {
    return getJson(routes.eventListEventsByRound(tournamentId, seasonId, roundInfo, page, isPast))
  }
  return getJson(routes.eventListEvents(tournamentId, seasonId, page, isPast))
}

export const createEvent = (createEventData: CreateEventData) => postJson(routes.createEvent(), { ...createEventData })

export const createDoublesEvent = (createDoublesEventData: CreateDoublesEventData) =>
  postJson(routes.createDoublesEvent(), { ...createDoublesEventData })

export const fetchEventDetails = (eventId: number) => getJson(routes.eventDetails(eventId))

export const deleteEvent = (eventId: number) => deleteJson(routes.deleteEvent(eventId))

export const updateEvent = (eventId: number, updateReqBody: UpdateEventBody, query: Record<string, boolean> = {}) =>
  putJson(routes.updateEvent(eventId, query), updateReqBody)

export const updateEventLocation = (eventId: number, body: UpdateEventLocation, query: Record<string, boolean> = {}) =>
  putJson(routes.updateEvent(eventId, query), body)

export const fetchLastCreatedEvents = (userId: string) => getJson(routes.lastCreatedEvents(userId))

export const updateMatchInfo = (eventId: string, body: UpdateMatchInfoBody) =>
  postJson(routes.updateMatchInfoRoute(eventId), body)

export const postMatchUpdate = (postMatchUpdateData: PostMatchUpdateData) =>
  putJson(routes.postMatchUpdate(postMatchUpdateData.eventId), postMatchUpdateData.data)

export const createMatchReport = (body: MatchReportData) => postJson(routes.storeMatchReport(), body)
export const sendMatchReportEmail = (eventId: number, body: MatchReportEmails) =>
  postJson(routes.sendMatchReportEmail(eventId), body)
// #endregion event

// #region lineups
export const getLineups = (eventId: number) => getJson(routes.getLineups(eventId))

export const createLineups = (eventId: number, payload: DynamicLineupsPayload) =>
  postJson(routes.createLineups(eventId), payload)

export const deleteLineups = (eventId: number, teamType: TeamValue) =>
  deleteJson(routes.getEditorLineups(eventId, teamType))
// #endregion lineups

// #region player
export const fetchTeamPlayers = (teamId: number) => getJson(routes.teamPlayersEditor(teamId))
// #endregion player

// #region incident
export const createIncident = (createIncidentData: CreateIncidentData, updateScore?: boolean) =>
  postJson(routes.createIncident(createIncidentData.eventId, updateScore), createIncidentData.incidentData)

export const fetchIncidents = (eventId: number) => getJson(routes.getIncidents(eventId))

export const deleteIncident = (deleteIncidentData: DeleteIncidentData, updateScore?: boolean) =>
  deleteJson(
    routes.deleteIncident(
      deleteIncidentData.eventId,
      deleteIncidentData.incidentType,
      deleteIncidentData.incidentId,
      updateScore,
    ),
  )

export const updateIncident = (updateIncidentData: UpdateIncidentData, updateScore?: boolean) =>
  putJson(
    routes.updateIncident(updateIncidentData.eventId, updateIncidentData.incidentId, updateScore),
    updateIncidentData.incidentData,
  )
// #endregion incident

// #region accumulated fouls
export const createAccumulatedFoul = (createAccumulatedFoulData: CreateAccumulatedFoulData) =>
  postJson(routes.createOrUpdateAccumulatedFoul(createAccumulatedFoulData.eventId), createAccumulatedFoulData.data)

// #region team
export const fetchTeamDetails = (teamId: number) => getJson(routes.teamDetails(teamId))
export const fetchTeamCreatePlayerPermissionCheck = (teamId: number) =>
  getJson(routes.teamCreatePlayerPermissionCheck(teamId))
// #endregion team

// #region image
export const uploadImage = (encodedData: string) => postJson(routes.image(), { data: encodedData })

export const deleteImage = (hash: string) => deleteJson(routes.deleteImage(hash))
// #endregion image

// #region suggestion
export const createUserSuggestion = (
  entityType: EntityType,
  entityId: number,
  userSuggestionData: UserSuggestionData,
) => postJson<{ suggest: PlayerUpdateSuggest }>(routes.createUserSuggestion(entityType, entityId), userSuggestionData)

export const createSuggestion = (suggestionData: SuggestionData) => postJson(routes.createSuggestion(), suggestionData)

export const createPlayerSuggestion = (suggestionData: Omit<SuggestionData, 'tournamentId'>) =>
  postJson<{ editorSuggestion: EditorSuggestion }>(routes.createSuggestion(), suggestionData)

export const deleteSuggestion = (suggestId: number) => deleteJson(routes.deleteSuggestion(suggestId))

export const fetchSuggestions = (entityType: EntityType, userId: string) =>
  getJson(routes.suggestions(entityType, userId))
// #endregion suggestion

// #region standings
export const fetchStandings = (tournamentId: number, seasonId: number) =>
  getJson(routes.standings(tournamentId, seasonId))

export const fetchEditorStandings = (uniqueTournamentId: number, seasonId: number) =>
  getJson(routes.standingsEditor(uniqueTournamentId, seasonId))

export const calculateStandings = (tournamentId: number, seasonId: number) =>
  postJson(routes.calculateStandings(), { tournamentId, seasonId })

export const sortStandings = (standingsId: number, sortStandingsData: SortStandingsData) =>
  postJson(routes.sortStandings(standingsId), sortStandingsData.sortedStandings)
// #endregion standings

export const createNewSeason = (uniqueTournamentId: number, newSeasonData: NewSeasonData) =>
  postJson(routes.createSeason(uniqueTournamentId), { ...newSeasonData })

export const sendEmail = (emailData: EmailData) => postJson(routes.contact(), { ...emailData })

// #region App
export const getGitBranches = (url: string = routes.getGitBranches()) => postJson(url)
// #endregion App
