import { useState, Fragment } from 'react'
import { useIntl } from 'react-intl'
import { NOT_STARTED_CODE, PENALTIES_CODE, SECOND_HALF_CODE, StatusType, isSpecialStatus } from 'entities/Status'
import { useEventState } from 'entities/Event'
import { useFirebaseWithEvent } from 'entities/Event/hooks'
import { FirebaseEventName, FirebaseEventType, FirebaseEventIncidentAction } from 'shared/lib/firebase/model'

import ArrowSlider from 'components/ArrowSlider'
import { UpdateEventBody } from 'modules/Matches/interface'

import { PeriodBox } from './PeriodBox'
import { PeriodStartTimePickerForm } from './PeriodStartTimePickerForm'
import { ConfirmFullTime } from './ConfirmFullTime'
import { ConfirmEndMatch } from './ConfirmEndMatch'
import { SelectablePeriod } from '../model'

export const PeriodSwitcher = ({
  currentPeriodIndex,
  selectablePeriods,
  onConfirm,
}: {
  currentPeriodIndex: number
  selectablePeriods: SelectablePeriod[]
  onConfirm: (arg0: UpdateEventBody, eventId: number) => Promise<void>
}) => {
  const logFirebaseWithEvent = useFirebaseWithEvent()
  const [selectedPeriod, setSelectedPeriod] = useState<SelectablePeriod | null>(null)

  const { event } = useEventState()
  const intl = useIntl()

  const handleOpenPeriod = (period: SelectablePeriod) => {
    setSelectedPeriod(period)
    logFirebaseWithEvent(FirebaseEventName.AddEntity, FirebaseEventType.Incident, FirebaseEventIncidentAction.Period)
  }

  const responsivePopupClose = (discarded?: boolean | undefined) => {
    setSelectedPeriod(null)
    if (discarded)
      logFirebaseWithEvent(
        FirebaseEventName.DiscardEntity,
        FirebaseEventType.Incident,
        FirebaseEventIncidentAction.Period,
      )
    else
      logFirebaseWithEvent(
        FirebaseEventName.SubmitEntity,
        FirebaseEventType.Incident,
        FirebaseEventIncidentAction.Period,
      )
  }

  const hasNotStarted = event.status.code === NOT_STARTED_CODE
  const hasFinished = event.status.type === StatusType.FINISHED || isSpecialStatus(event.status.code)

  return (
    <>
      <ArrowSlider w="100%" initialIndex={currentPeriodIndex}>
        {selectablePeriods.map((period, index) => (
          <Fragment key={index}>
            <PeriodBox
              onClick={() => handleOpenPeriod(period)}
              periodName={intl.formatMessage({ id: period.shortTitleKey })}
              periodStartTimestamp={period.startTimestamp}
              isActive={event.status.code === period.code}
              isDisabled={currentPeriodIndex > index || hasFinished}
              mr="sm"
            />

            {/** If match has started, render FT box after second half period box */}
            {!hasNotStarted && period.code === SECOND_HALF_CODE && (
              <ConfirmFullTime event={event} onConfirm={onConfirm} />
            )}

            {/** If match has started, render Confirm End box after second half period box */}
            {!hasNotStarted && period.code === PENALTIES_CODE && (
              <ConfirmEndMatch event={event} onConfirm={onConfirm} />
            )}
          </Fragment>
        ))}
      </ArrowSlider>

      <PeriodStartTimePickerForm
        isOpen={!!selectedPeriod}
        selectedPeriod={selectedPeriod}
        onClose={responsivePopupClose}
      />
    </>
  )
}
