import { IntlShape } from 'react-intl'
import { SportName } from 'entities/Sport'

import playerPositions from 'translations/mappings/playerPositions.json'

/**
 * All labels are appropriate string ids.
 *
 * @param sport
 * @returns
 */
export const getPlayerPositionOptions = (sport: SportName, intl: IntlShape) => {
  const options = Object.keys(playerPositions[sport]).map(positionKey => ({
    label: intl.formatMessage({ id: playerPositions[sport][positionKey] }),
    value: positionKey,
  }))

  options.push({ label: intl.formatMessage({ id: 'unknown' }), value: '-' })

  return options
}

export const getPlayerPositionId = (position: string, sport: string) => {
  const key = playerPositions[sport][position]

  if (!key || position === '-') return 'unknown'

  return key
}
