import { BasicEvent } from 'entities/Event'
import { StatusType } from 'entities/Status'

import { MY_COMPETITION_HEADER_MOBILE_HEIGHT } from 'modules/MyCompetitions/components/MyCompetitionHeader/config'
import { EVENT_CELL_HEIGHT } from 'modules/EventCellNew/config'
import { isToday } from 'utils/time'

import { EventGroup } from '../interface'
import { MATCHES_NAV_HEIGHT } from '../MatchesNavigation/config'
import { MATCHES_FILTER_HEIGHT } from '../FilterList/config'
import { GROUP_HEADER_HEIGHT, GROUP_SUBHEADER_HEIGHT } from '../EventGroupList/config'

export const getScrollerHeight = ({ hasFilters }: { hasFilters: boolean }) => {
  return `calc(100vh - ${MY_COMPETITION_HEADER_MOBILE_HEIGHT}px - ${MATCHES_NAV_HEIGHT}px - 24px${
    hasFilters ? ` - ${MATCHES_FILTER_HEIGHT}px - 8px` : ''
  })`
}

const isEventRelevant = (event: BasicEvent) => {
  return (
    (isToday(event.startTimestamp) && event.status.type === StatusType.IN_PROGRESS) ||
    event.startTimestamp * 1000 >= Date.now()
  )
}

export const calculateLatestGroupOffset = (eventGroups: EventGroup[]) => {
  let scrollTop = 0

  eventGroups.forEach(group => {
    // Check if the last event is before today
    const hasGroupNextEvent = group.subgroups.find(subgroup => {
      return subgroup.events.find(event => isEventRelevant(event))
    })

    if (!hasGroupNextEvent) {
      const headerHeight = group.isHeaderDisplayed ? GROUP_HEADER_HEIGHT : 0
      const subheaderHeight = group.subgroups[0].isSubheaderDisplayed ? GROUP_SUBHEADER_HEIGHT : 0
      const groupTopMargin = 8

      scrollTop +=
        2 * groupTopMargin + headerHeight + subheaderHeight + group.eventCount * EVENT_CELL_HEIGHT + groupTopMargin
    }
  })

  return scrollTop
}

export const getGroupsHeight = (groups: EventGroup[]) => {
  const space = 8

  const groupsHeights = groups.reduce((height, group) => {
    const headerHeight = group.isHeaderDisplayed ? GROUP_HEADER_HEIGHT : 0
    const subheaderHeight = group.subgroups[0].isSubheaderDisplayed ? GROUP_SUBHEADER_HEIGHT : 0
    const groupPadding = 8

    return height + groupPadding + headerHeight + subheaderHeight + group.eventCount * EVENT_CELL_HEIGHT + groupPadding
  }, 0)

  return groupsHeights + (groups.length - 1) * space
}
