import { Country } from 'entities/Country'
import { EntityAllowedActions } from 'entities/Permission'
import { BasicTeam } from 'entities/Team'
import { ApiResponse } from 'shared/api'
import { Option } from 'shared/model'

export interface BasicPlayer {
  id: number
  name: string
  shortName?: string
  slug?: string
}

export interface Player extends BasicPlayer {
  imageUrl?: string
  country: Country
  dateOfBirthTimestamp: number
  height?: number
  jerseyNumber: string | null
  position?: string
  prefferedFoot?: string
  team?: BasicTeam
  userCount?: number
  allowedActions?: EntityAllowedActions
}

export enum MissingPlayerReason {
  Other = 0,
  Injured = 1,
  Suspended = 3,
  YellowCard = 11,
  YellowRedCard = 12,
  RedCard = 13,
  OnLoan = 21,
}

export enum MissingPlayerType {
  Missing = 'missing',
  Doubtful = 'doubtful',
}

export interface FetchedPlayer {
  player: Player
}

export interface PlayersResponse extends ApiResponse {
  foreignPlayers: FetchedPlayer[]
  nationalPlayers: FetchedPlayer[]
  players: FetchedPlayer[]
}

export type PlayerOption = Option<Player>
export interface PlayersResponseEditor extends ApiResponse {
  players: Player[]
}

/**
 * These position are entirely dependant on the sport context.
 *
 * E.g. G is "goalkeeper" in football and "guard" in basketball.
 *
 * See in translations/mappings meaning of each position by sport.
 */
export type Position = 'G' | 'D' | 'M' | 'F' | 'C' | 'CF' | 'FC' | 'FG' | 'GF' | 'W' | 'P' | 'OF' | 'B'
