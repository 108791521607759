import { FlexProps } from '@sofascore/ui'

import * as S from './styles'

interface Props {
  label: string
  value: string | number
  disabled?: boolean
  onClick: () => void
}

export default function PropertyButton({ label, value, onClick, disabled, ...boxProps }: Props & FlexProps) {
  return (
    <S.Wrapper
      w="100%"
      bg="surface.s1"
      color="surface.nLv1"
      minH="48"
      isDisabled={disabled}
      onClick={() => {
        if (!disabled) onClick()
      }}
      {...boxProps}
    >
      <S.Value id="value" minW={0} pt={18} px="lg" mr="md" position="absolute" top="0" left="0">
        {value}
      </S.Value>

      <S.Label active={!!value}>{label}</S.Label>
    </S.Wrapper>
  )
}
