import { useIntl } from 'react-intl'

import { Option } from 'components/DropdownMenu/interface'
import { Select } from 'components/Select'
import { backgrounds } from 'styles/color'

import { SET_FORMATION, useLineupsDispatch, useLineupsState } from '../../providers'

interface LineupsFormationSelectorProps {
  isHomeTeam: boolean
  options: Option[]
}

export const LineupsFormationSelector = ({ isHomeTeam, options }: LineupsFormationSelectorProps) => {
  const { homeFormation, awayFormation } = useLineupsState()
  const dispatch = useLineupsDispatch()
  const intl = useIntl()

  const formation = isHomeTeam ? homeFormation : awayFormation
  const selectedFormation = options.find(k => k.value === formation)!

  const setFormation = (value: string) => {
    dispatch({
      type: SET_FORMATION,
      payload: { formation: value, isHome: isHomeTeam },
    })
  }

  return (
    <Select
      value={selectedFormation}
      onChange={option => setFormation(option.value.toString())}
      options={options}
      label={intl.formatMessage({ id: 'editor_team_formation' })}
      selectBackgroundColor={backgrounds.chip}
      disableClear
    />
  )
}
