import { AnalyticsCallOptions, CustomEventName, EventParams } from 'firebase/analytics'

/**
 * See more:
 * https://docs.google.com/spreadsheets/d/1fLwQVbN1hrdCgY0LMDK9A5i-QsI3UnedzOcGLP_hYc0/edit#gid=1126751686
 */
export enum FirebaseEventName {
  /**
   * User clicks on the 'Add' button to add an entity (player, match, competition, incident, etc.)
   *
   * @param {string} action - goal, missed_penalty, substitution, card, suspension
   *  action is only required if type is incident
   * @param {string} type - event, team, player, competition, incident, season
   */
  AddEntity = 'add_entity',

  /**
   * User changes the date and time of an event
   *
   * @param {int} id - event ID
   */
  ChangeDateTime = 'change_date_time',

  /**
   * User changes the date and time of an event
   *
   * @param {int} id - Event id  should be sent only when user clicks on 'Change type'.
   * @param {int} size - Count of players entered by user before modifying the lineup type.
   * @param {string} type - pitch, list
   */
  ChangeLineupsType = 'change_lineups_type',

  /**
   *  User deletes an entity (player, match, team) by clicking on the 'Delete' option in the popup.
   *
   * @param {string} action - Incident type.
   * @param {int} id - Id of deleted entity (event id, player id,...), but if the type is incident, id is event id.
   * @param {string} sport - Sport of deleted entity.
   * @param {string} type - Entity type (match, player, competition, team, incident).
   */
  DeleteEntity = 'delete_entity',

  /**
   *  User deletes an entity (player, match, team) by clicking on the 'Delete' option in the popup.
   *
   * @param {string} action - Incident type.
   * @param {int} id - Entity ID (dependent on the property type: match ID, player ID, team ID,
   *  but if the type is incident, id is event id.) that user added or edited after
   *  clicking on 'Submit Changes' or 'Save'. . Null if user submits new entity, otherwise entitiy ID.
   * @param {string} sport - Sport of created/edited entity.
   * @param {string} status - Type of an event that user created/edited (notstarted, inprogress, finished)
   * @param {string} type - Entity type (event, competition, player, team, season, inquiry).
   */
  SubmitEntity = 'submit_entity',

  /**
   * ser discards a new or edited entity (player, competition, team) by clicking on the 'Are you sure you want to exit?'
   *  popup and selecting 'Confirm'.
   *
   * @param {string} action - Incident type.
   * @param {int} id - Id of deleted entity (event id, player id,...), but if the type is incident, id is event id.
   * @param {string} sport - Sport of deleted entity.
   * @param {string} type - Entity type (match, player, competition, team, incident).
   */
  DiscardEntity = 'discard_entity',

  /**
   * User clicks on one of the options inside the Sofa Editor navigation.
   *
   * @param {string} name - Contact Us, News, Privacy, Language, Sign Out
   */
  NavigationAction = 'navigation_action',

  /**
   * User clicks on one of the news link  on MainScreen (MatchesTab or More Tab)
   */
  OpenInfo = 'open_info',

  /**
   * User reorders the standings in the chosen league.
   *
   * @param {int} id - tournament id
   */
  ReorderStandings = 'reorder_standings',

  /**
   * Competition (tournament) id where user recalculates the standings
   *  (this event should be send only if a user clicks proceed).
   *
   * @param {int} id - tournament id
   */
  RecalculateStandings = 'recalculate_standings',

  /**
   * The type of action on the toggle that the user changed (on or off).
   *
   * @param {string} status - on, off
   */
  ResultOnlyMode = 'result_only_mode',

  /**
   * User login method
   *
   * @param {string} method - facebook, google
   */
  SignIn = 'sign_in',

  /**
   * Use this to track all new interactions by users instead of separate events for each click/open/etc.
   *
   * @param action 'click' | 'open' | ...
   * @param location page where interaction occurred, e.g. `event_details`
   * @param type custom, usually component or interaction to track
   */
  UserInteraction = 'user_interaction',

  /**
   * use this to track actions that open external core functionality
   *
   * @param location location where core functionality was opened
   */
  OpenCore = 'open_core',

  /**
   * use this to track actions that open external core functionality
   *
   * @param {int} id - event id
   * @param {string} sport - Sport of the event
   * @param {string} status - Status of the event
   * @param location location where core functionality was opened
   */
  ClearLineup = 'clear_lineup',

  /**
   * use this to track match report download
   * @param {string} action - possible values: create, edit, send_to_mail, download - depends on the user's click
   * @param {int} id - event id
   * @param {string} sport - Sport of the event
   * @param {string} status - possible values: pre_match, post_match - depends on the period during which a specific action was taken
   */
  MatchReport = 'match_report',

  //  depricated firebase events
  SelectLanguage = 'select_language',
  AboutSofasscoreEditor = 'about_sofasscore_editor',
  ClickRequestAccess = 'click_request_access',
  SendRequestAccess = 'send_request_access',
  HomepageOpenMatch = 'homepage_open_match',
  ClickEditStandings = 'click_edit_standings',
  ClickEditPlayer = 'click_edit_player',
  EditPlayer = 'edit_player',
  AddTeam = 'add_team',
  ClickTeamEdit = 'click_team_edit',
  ClickFilter = 'click_filter',
  ClickCreateNewMatch = 'click_create_new_match',
  AboutSofascoreEditor = 'about_sofascore_editor',
  OpenMatch = 'open_match',
  EditResultPeriod = 'edit_result_period',
}

export type FirebaseEventNameType = CustomEventName<FirebaseEventName>

export enum FirebaseEventType {
  Competition = 'competition',
  Incident = 'incident',
  Event = 'event',
  NewTeam = 'new_team',
  ExistingTeam = 'existing_team',
  Team = 'team',
  Player = 'player',
  Season = 'season',
  Lineup = 'lineup',
}

export enum FirebaseEventIncidentAction {
  Goal = 'goal',
  InGamePenalty = 'inGamePenalty',
  PenaltyShootout = 'penalty_shootout',
  Substitution = 'substitution',
  Card = 'card',
  Suspension = 'suspension',
  Period = 'period',
}

// event name as string because we have some old log event's that we whant to leave active
export interface LogEventProps {
  eventName: FirebaseEventNameType | string
  eventParams?: EventParams
  options?: AnalyticsCallOptions
}
