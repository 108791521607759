import { PropsWithChildren } from 'react'
import { Flex, Text } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'
import { Incident } from 'entities/Incident'
import IconFootballPenalty from '@sofascore/ui/dist/modules/Icons/_IconFootballPenalty'

import { GREEN } from 'styles/color'

import { IncidentBody, IncidentFrame, IncidentHead, IncidentResult, IncidentTail } from '../'

interface Props {
  incident: Incident
}

export const IncidentShootoutPenaltyScored = ({ children, incident }: PropsWithChildren<Props>) => {
  const { isHome, player, homeScore, awayScore } = incident

  return (
    <>
      <IncidentFrame>
        <IncidentHead isHome={isHome}>
          <IconFootballPenalty fill={GREEN.incident} />
        </IncidentHead>

        <IncidentResult isHome={isHome} homeScore={homeScore} awayScore={awayScore} />

        <IncidentBody isHome={isHome}>
          <Flex direction="column" align={isHome ? 'flex-start' : 'flex-end'}>
            <Text font="body.medium">
              {player?.name}
              {!player?.name && <FormattedMessage id="scored_penalty" />}
            </Text>
          </Flex>
        </IncidentBody>

        <IncidentTail>{children}</IncidentTail>
      </IncidentFrame>
    </>
  )
}
