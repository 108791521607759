import { ChangeEvent, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Text, Box, Flex } from '@sofascore/ui'
import { MissingPlayerReason, Player, PlayerCard } from 'entities/Player'

import { IconAddImageOutlined } from 'components/Icons/IconAddImageOutlined'
import { IconButton } from 'components/IconButton'
import { IconRemoveFilled } from 'components/Icons/IconRemoveFilled'
import ResponsivePopUp from 'components/ResponsivePopUp'
import { Button } from 'components/Button'
import { RED } from 'styles/color'

import { DynamicLineupsPlayer } from '../../model'
import { groupPlayersByPosition, sortLineupPlayers } from '../../utils'
import { mapMissingReasonToTranslationKey } from '../../mappings'
import { MissingPlayerGroupItem } from './MissingPlayersGroupItem'
import { ADD_TO_MISSING_PLAYERS, REMOVE_FROM_MISSING_PLAYERS, useLineupsDispatch } from '../../providers'

export default function MissingPlayers({
  missingPlayers,
  availablePlayers,
  positionGroupMapping,
}: {
  missingPlayers: DynamicLineupsPlayer[]
  availablePlayers: DynamicLineupsPlayer[]
  positionGroupMapping: Record<string, string>
}) {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [checkedPlayers, setCheckedPlayers] = useState<DynamicLineupsPlayer[]>([])

  const intl = useIntl()
  const dispatch = useLineupsDispatch()
  const groupedAvailablePlayers = groupPlayersByPosition(availablePlayers)

  const handleCheckPlayer = (
    event: ChangeEvent<HTMLInputElement>,
    player: DynamicLineupsPlayer,
    reason: MissingPlayerReason,
  ) => {
    if (event.target.checked) {
      setCheckedPlayers(previousPlayers => [...previousPlayers, { ...player, missingReason: reason }])
    } else {
      setCheckedPlayers(previousPlayers => previousPlayers.filter(p => p.player.id !== player.player.id))
    }
  }

  const handleChangeReason = (player: Player, reason: MissingPlayerReason) => {
    setCheckedPlayers(previousCheckedPlayers => {
      return previousCheckedPlayers.map(cp => {
        if (cp.player.id === player.id) {
          return {
            ...cp,
            missingReason: reason,
          }
        }

        return cp
      })
    })
  }

  const handleRemoveMissingPlayer = (missingPlayer: DynamicLineupsPlayer) => {
    dispatch({ type: REMOVE_FROM_MISSING_PLAYERS, payload: missingPlayer })
  }

  const handleAddMissingPlayers = (missingPlayers: DynamicLineupsPlayer[]) => {
    dispatch({ type: ADD_TO_MISSING_PLAYERS, payload: missingPlayers })
  }

  const handleSave = () => {
    handleAddMissingPlayers(checkedPlayers)
    setCheckedPlayers([])
    setIsDialogOpen(false)
  }

  const handleClose = () => {
    setCheckedPlayers([])
    setIsDialogOpen(false)
  }

  return (
    <Box>
      <Flex justify="space-between" align="center" py="lg">
        <Text font="display.micro" fontWeight={600}>
          <FormattedMessage id="editor_missing_players" />
        </Text>

        <IconButton onClick={() => setIsDialogOpen(true)} p="0">
          <IconAddImageOutlined />
        </IconButton>
      </Flex>

      <Flex direction="column" gapY="sm">
        {sortLineupPlayers(missingPlayers).map((lineupPlayer: DynamicLineupsPlayer) => (
          <Flex key={lineupPlayer.player.id} justify="space-between" align="center" py={6}>
            <PlayerCard
              player={lineupPlayer.player}
              showJerseyNumber
              bottomContent={
                typeof lineupPlayer.missingReason === 'number' && (
                  <Text font="assistive.default" color={RED.incident}>
                    {intl.formatMessage({ id: mapMissingReasonToTranslationKey[lineupPlayer.missingReason] })}
                  </Text>
                )
              }
            />
            <IconButton onClick={() => handleRemoveMissingPlayer(lineupPlayer)} p="0">
              <IconRemoveFilled />
            </IconButton>
          </Flex>
        ))}
      </Flex>

      <ResponsivePopUp
        isOpen={isDialogOpen}
        onClose={handleClose}
        heading={
          <Text font="display.large">
            <FormattedMessage id="editor_add_missing_players" />
          </Text>
        }
      >
        <Box overflow="auto" maxH={500}>
          {Object.keys(positionGroupMapping).map(position => {
            return (
              <Box key={position}>
                <Box px="lg" pt="lg" pb="sm">
                  <Text font="assistive.default">
                    <FormattedMessage id={positionGroupMapping[position]} />
                  </Text>
                </Box>
                <Flex direction="column">
                  {groupedAvailablePlayers[position] &&
                    sortLineupPlayers(groupedAvailablePlayers[position]).map((lineupPlayer: DynamicLineupsPlayer) => (
                      <MissingPlayerGroupItem
                        key={lineupPlayer.player.id}
                        lineupPlayer={lineupPlayer}
                        checked={!!checkedPlayers.find(cp => cp.player.id === lineupPlayer.player.id)}
                        onCheck={(event, reason) => handleCheckPlayer(event, lineupPlayer, reason)}
                        onChangeReason={reason => handleChangeReason(lineupPlayer.player, reason)}
                      />
                    ))}
                </Flex>
              </Box>
            )
          })}

          {groupedAvailablePlayers['-'] && (
            <Box>
              <Box px="lg" pt="lg" pb="sm">
                <Text font="assistive.default">
                  <FormattedMessage id="unknown" />
                </Text>
              </Box>
              <Flex direction="column" gapY="sm">
                {groupedAvailablePlayers['-'] &&
                  sortLineupPlayers(groupedAvailablePlayers['-']).map(lineupPlayer => (
                    <MissingPlayerGroupItem
                      key={lineupPlayer.player.id}
                      lineupPlayer={lineupPlayer}
                      checked={!!checkedPlayers.find(cp => cp.player.id === lineupPlayer.player.id)}
                      onCheck={(event, reason) => handleCheckPlayer(event, lineupPlayer, reason)}
                      onChangeReason={reason => handleChangeReason(lineupPlayer.player, reason)}
                    />
                  ))}
              </Flex>
            </Box>
          )}
        </Box>

        <Flex gapX="lg" p="xl">
          <Button variant="outlined" fullWidth onClick={handleClose}>
            <FormattedMessage id="cancel" />
          </Button>
          <Button variant="contained" fullWidth onClick={handleSave} disabled={checkedPlayers.length === 0}>
            <FormattedMessage id="add" />
          </Button>
        </Flex>
      </ResponsivePopUp>
    </Box>
  )
}
