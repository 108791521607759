import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Flex, Text } from '@sofascore/ui'

import { IconEU } from 'components/Icons/IconEU'
import { IconEUStructureReversed } from 'components/Icons/IconEUStructureReversed'
import { selectLocale } from 'store/selectors'
import { IconEUConcurrencyReversed } from 'components/Icons/IconEUConcurrencyReversed'

import { getLandingPageURLPath } from '../../utils'
import * as S from '../styles'

export const MobileEULabel = () => {
  const selectedLocale = useSelector(selectLocale)

  const euHref = `https://editor.sofascore.com${getLandingPageURLPath(selectedLocale)}`

  return (
    <S.Link href={euHref} target="_blank" rel="noopener noreferrer">
      <Flex p="lg" justify="center">
        <Flex direction="column" gapY="lg">
          <Flex align="center" gapX="lg">
            <Flex gapX="xs" shrink={0}>
              <IconEU width={32} height={22} />
              <Flex direction="column" maxW={44}>
                <Text fontSize={5} lineHeight={1.5} color="onSurface.nLv1">
                  <FormattedMessage id="europeanUnion" />
                </Text>
                <Text fontSize={5} lineHeight={1.5} color="onSurface.nLv1">
                  <FormattedMessage id="euTogetherTowardsFunds" />
                </Text>
              </Flex>
            </Flex>

            <Text fontSize={5} lineHeight={1.5} color="onSurface.nLv1" letterSpacing="-0.05px" maxW={66}>
              <FormattedMessage id="euRegionalFunds" />
            </Text>

            <IconEUConcurrencyReversed />

            <IconEUStructureReversed />
          </Flex>

          <Text fontSize={7} fontWeight={700} lineHeight={1.5} color="onSurface.nLv1">
            <FormattedMessage id="sofaEuCopyright" />
          </Text>
        </Flex>
      </Flex>
    </S.Link>
  )
}
