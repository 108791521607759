import { UniqueTournament, UniqueTournamentSeason } from 'modules/MyCompetitions/interface'

/**
 * Returns Unique tournament with seasons array
 * Unique tournament response transformed to unique tournament with seasons array
 */
export const getCompetitionsWithAllSeasons = (competitionsWithSeasons: UniqueTournamentSeason[]) => {
  const competitions = competitionsWithSeasons.reduce((acc, curr) => {
    const existingUT = acc?.find(ut => ut.uniqueTournament.id === curr.uniqueTournament.id)
    if (existingUT) {
      existingUT.seasons.push(curr.season)
    } else {
      const { season, ...ut } = curr
      acc.push({ ...ut, seasons: [season] })
    }
    return acc
  }, [] as UniqueTournament[])

  return competitions
}
