import { FormattedMessage, IntlShape } from 'react-intl'
import { RoundInfo } from 'entities/Event'
import { ValuesType, getId, getValues } from 'shared/utils'

import tournamentRounds from '../tournamentRounds.json'

export const FormattedTournamentRound = ({ id }: { id: string }) => (
  <FormattedMessage id={getId(tournamentRounds, id)} values={getValues(tournamentRounds, id) as ValuesType} />
)

export const formatTournamentRound = (id: string, intl: IntlShape) => {
  return intl.formatMessage({ id: getId(tournamentRounds, id) }, getValues(tournamentRounds, id) as ValuesType)
}

const getRoundLabel = (roundName: string, intl: IntlShape): string => {
  if (!isNaN(+roundName)) {
    return intl.formatMessage({ id: 'numberRound' }, { roundNumber: roundName })
  }

  const roundNumber = roundName.match(/\d+/)
  const secondLeg = roundName.match(/2nd leg/)
  const placeMatch = roundName.match(/place/)

  if (roundNumber && !secondLeg && !placeMatch) {
    return roundName.match(/qualification/i)
      ? intl.formatMessage({ id: 'qualificationRoundN', defaultMessage: roundName }, { roundNumber: roundNumber[0] })
      : roundName.match(/round of/i) || roundName.match(/\d+[/]\d+/i) || roundName.match(/R\d+/i)
      ? formatTournamentRound(roundName, intl)
      : intl.formatMessage({ id: 'numberRound', defaultMessage: roundName }, { roundNumber: roundNumber[0] })
  }

  // Fallback for older tournaments
  if (roundName.match(/quarterfinal/i) || roundName.match(/semifinal/i)) {
    return formatTournamentRound(roundName, intl)
  }

  if (secondLeg && secondLeg.index) {
    const prefix = roundName.slice(0, secondLeg.index - 1)
    return `${getRoundLabel(prefix, intl)} ${intl.formatMessage({ id: 'secondLeg', defaultMessage: secondLeg[0] })}`
  }

  return intl.formatMessage({ id: roundName.toLowerCase(), defaultMessage: roundName })
}

function RoundLabel({ roundName }: { roundName: string }) {
  if (!isNaN(+roundName)) {
    return <FormattedMessage id="numberRound" values={{ roundNumber: roundName }} />
  }

  const roundNumber = roundName.match(/\d+/)
  const secondLeg = roundName.match(/2nd leg/)
  const placeMatch = roundName.match(/place/)

  if (roundNumber && !secondLeg && !placeMatch) {
    return roundName.match(/qualification/i) ? (
      <FormattedMessage id="qualificationRoundN" values={{ roundNumber: roundNumber[0] }} defaultMessage={roundName} />
    ) : roundName.match(/round of/i) || roundName.match(/\d+[/]\d+/i) || roundName.match(/R\d+/i) ? (
      <FormattedTournamentRound id={roundName} />
    ) : (
      <FormattedMessage id="numberRound" values={{ roundNumber: roundNumber[0] }} defaultMessage={roundName} />
    )
  }

  // Fallback for older tournaments
  if (roundName.match(/quarterfinal/i) || roundName.match(/semifinal/i)) {
    return <FormattedTournamentRound id={roundName} />
  }

  if (secondLeg && secondLeg.index) {
    const prefix = roundName.slice(0, secondLeg.index - 1)
    return (
      <>
        <RoundLabel roundName={prefix} /> <FormattedMessage id="secondLeg" defaultMessage={secondLeg[0]} />
      </>
    )
  }

  return <FormattedMessage id={roundName.toLowerCase()} defaultMessage={roundName} />
}

export const FormattedRound = ({ round }: { round?: RoundInfo | null }) => {
  if (!round) {
    return null
  }

  return (
    <>
      {round.prefix && (
        <>
          <RoundLabel roundName={round.prefix} />
          &nbsp;
        </>
      )}
      <RoundLabel roundName={round.name || round.round.toString()} />
    </>
  )
}

export const formatRoundLabel = ({ round, intl }: { round?: RoundInfo | null; intl: IntlShape }) => {
  if (!round) {
    return ''
  }

  return `${round.prefix ? `${getRoundLabel(round.prefix, intl)} ` : ''}${getRoundLabel(
    round.name || round.round.toString(),
    intl,
  )}`
}
