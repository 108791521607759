export const PitchBackground = () => {
  return (
    <svg
      style={{ position: 'absolute', bottom: 0, left: 0 }}
      width="360"
      height="503"
      viewBox="0 0 360 503"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="7lyipm6sya">
          <stop stopColor="#74A68B" offset="0%" />
          <stop stopColor="#1F4F35" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path fill="url(#7lyipm6sya)" d="M0 0h360v503H0z" />
        <g fill="#0F202C" fillOpacity=".05">
          <path d="M4 16.386h352V55.71H4zM4 95.037h352v39.325H4zM4 173.688h352v39.325H4zM4 252.644h352v39.325H4zM4 331.294h352v39.325H4zM4 409.945h352v39.325H4z" />
        </g>
      </g>
    </svg>
  )
}

export const PitchLines = () => {
  return (
    <svg
      style={{ position: 'absolute', bottom: 8, left: 12 }}
      width="338"
      height="487"
      viewBox="0 0 338 487"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="#FFF" fill="none" fillRule="evenodd">
        <path
          opacity=".304"
          strokeLinecap="square"
          d="M.5 28.5h336M.5 454.5h336M.5 235.5h336M.5 118.5h336M.5 364.5h336"
        />
        <path opacity=".304" strokeLinecap="square" strokeDasharray="21" d="M26.5 280.5h284M26.5 190.5h284" />
        <path opacity=".303" d="M.5.5h337v486H.5z" />
        <g opacity=".301" strokeLinecap="square">
          <path d="M36.5 180.5v20M79.5 180.5v20" />
        </g>
        <g opacity=".301" strokeLinecap="square">
          <path d="M36.5 108.5v20M79.5 108.5v20" />
        </g>
        <g opacity=".301" strokeLinecap="square">
          <path d="M36.5 51.5v20M79.5 51.5v20" />
        </g>
        <g opacity=".301" strokeLinecap="square">
          <path d="M36.5 225.5v20M79.5 225.5v20" />
        </g>
        <g opacity=".301" strokeLinecap="square">
          <path d="M36.5 270.5v20M79.5 270.5v20" />
        </g>
        <g opacity=".301" strokeLinecap="square">
          <path d="M36.5 354.5v20M79.5 354.5v20" />
        </g>
        <g opacity=".301" strokeLinecap="square">
          <path d="M36.5 410.5v20M79.5 410.5v20" />
        </g>
        <g opacity=".301" strokeLinecap="square">
          <path d="M45.5 431.5h-20M89.5 431.5h-20" />
        </g>
        <g opacity=".301" strokeLinecap="square">
          <path d="M45.5 50.5h-20M89.5 50.5h-20" />
        </g>
        <g opacity=".301" strokeLinecap="square">
          <path d="M255.5 431.5h-20M299.5 431.5h-20" />
        </g>
        <g opacity=".301" strokeLinecap="square">
          <path d="M255.5 50.5h-20M299.5 50.5h-20" />
        </g>
        <path
          opacity=".301"
          strokeLinecap="square"
          d="M132.5 50.5h-20M132.5 431.5h-20M214.5 50.5h-20M214.5 431.5h-20M172.5 50.5h-20M172.5 431.5h-20"
        />
        <g opacity=".301" strokeLinecap="square">
          <path d="M246.5 180.5v20M289.5 180.5v20" />
        </g>
        <g opacity=".301" strokeLinecap="square">
          <path d="M246.5 108.5v20M289.5 108.5v20" />
        </g>
        <g opacity=".301" strokeLinecap="square">
          <path d="M246.5 51.5v20M289.5 51.5v20" />
        </g>
        <g opacity=".301" strokeLinecap="square">
          <path d="M246.5 225.5v20M289.5 225.5v20" />
        </g>
        <g opacity=".301" strokeLinecap="square">
          <path d="M246.5 270.5v20M289.5 270.5v20" />
        </g>
        <g opacity=".301" strokeLinecap="square">
          <path d="M246.5 354.5v20M289.5 354.5v20" />
        </g>
        <g opacity=".301" strokeLinecap="square">
          <path d="M246.5 410.5v20M289.5 410.5v20" />
        </g>
      </g>
    </svg>
  )
}
