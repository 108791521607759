import { createStyles, makeStyles, Theme } from '@material-ui/core'

import { backgrounds, secondaryColor, borders } from 'styles/color'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listContainer: {
      '& > div + div': {
        marginTop: theme.spacing(1),
      },
    },

    whatsNewHomeTileContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: '#fff',
      boxShadow: `0 0 6px 0 ${backgrounds.lightRgba}`,
      padding: theme.spacing(1.5),
      cursor: 'pointer',
    },

    feedItemPlaceholder: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 56,
      height: 56,
      backgroundColor: borders.lightRgba,
      borderRadius: 4,
    },

    textContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginLeft: theme.spacing(1.5),
    },

    markerNew: {
      backgroundColor: secondaryColor,
      color: '#fff',
      fontSize: 12,
      width: '45px',
      height: '18px',
      borderRadius: '5px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    title: {
      fontSize: 16,
      fontWeight: 'bold',
      marginTop: theme.spacing(1),
      textAlign: 'left',
    },

    closeButtonContainer: {
      '& .MuiIconButton-root': {
        padding: theme.spacing(0.5),
        transform: `translate(${theme.spacing(1)}px, -${theme.spacing(1)}px)`,
      },
    },
  }),
)
