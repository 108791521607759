import { Flex, Text } from '@sofascore/ui'

import { secondaryColor, textLightColor } from 'styles/color'

import { ScoreWithPeriods } from '../model'
import { getScoreColor, getWinningSide } from '../utils'

interface Props {
  homeScore: ScoreWithPeriods
  awayScore: ScoreWithPeriods
  isLive: boolean
}

export const GenericResultSection = ({ homeScore, awayScore, isLive }: Props) => {
  return (
    <Flex flex={1} justify="center" px="lg">
      <Text
        font="display.extraLarge"
        color={getScoreColor(isLive, 'home', getWinningSide(homeScore.display, awayScore.display))}
      >
        {homeScore.display || 0}
      </Text>
      <Text font="display.extraLarge" color={isLive ? secondaryColor : textLightColor} mx="sm">
        -
      </Text>
      <Text
        font="display.extraLarge"
        color={getScoreColor(isLive, 'away', getWinningSide(homeScore.display, awayScore.display))}
      >
        {awayScore.display || 0}
      </Text>
    </Flex>
  )
}
