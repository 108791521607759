import { CANCELED_CODE, NOT_STARTED_CODE, POSTPONED_CODE } from 'entities/Status'
import { Team } from 'entities/Team'
import { BasicEvent, RoundInfo } from 'entities/Event'
import { Player } from 'entities/Player'
import { UpdateScorePayload } from 'entities/Score'
import { ApiResponse } from 'shared/api'

export interface TeamsResponse extends ApiResponse {
  teams: Team[]
}

export interface EventDetailsResponse extends ApiResponse {
  event: BasicEvent
}

export interface SimpleScore {
  home: number
  away: number
}

export interface ScoreState {
  [key: string]: SimpleScore
}

export type TeamValue = 'home' | 'away' | 'event'

export interface UpdateEventBody {
  status: {
    code: number
  }
  homeScore: UpdateScorePayload
  awayScore: UpdateScorePayload
  aggregatedWinnerCode: number
  currentPeriodStartTimestamp: number | null
  roundInfo?: RoundInfo
  startDateTimestamp?: number
  resetScore?: boolean
  crowdsourcingDataDisplayEnabled?: boolean
}

export interface UpdateEventLocation {
  city?: string
  editorVenue?: string
}

export interface UpdateMatchInfoBody {
  startDateTimestamp?: number
  roundInfo?: {
    name?: string
    round?: number
  }
  // Only these supported
  statusCode?: typeof NOT_STARTED_CODE | typeof POSTPONED_CODE | typeof CANCELED_CODE
}

export interface FetchedPlayer {
  player: Player
}

export interface PlayersResponse extends ApiResponse {
  foreignPlayers: FetchedPlayer[]
  nationalPlayers: FetchedPlayer[]
  players: FetchedPlayer[]
}

export enum EditMatchModeType {
  RESULT_ONLY = 'resultOnly',
  LIVE = 'live',
}
