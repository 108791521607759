import { Fragment, useState } from 'react'
import { useIntl } from 'react-intl'
import { FOURTH_QUARTER_CODE, NOT_STARTED_CODE, OVERTIME, StatusType, isSpecialStatus } from 'entities/Status'
import { useEventState } from 'entities/Event'
import { useFirebaseWithEvent } from 'entities/Event/hooks'
import { FirebaseEventName, FirebaseEventType, FirebaseEventIncidentAction } from 'shared/lib/firebase/model'

import ArrowSlider from 'components/ArrowSlider'
import { UpdateEventBody } from 'modules/Matches/interface'

import { PeriodBox } from './PeriodBox'
import { SelectablePeriod } from '../model'
import { PeriodStartFormFRO } from './PeriodStartFormFRO'
import { ConfirmFullTimeFRO } from './ConfirmFullTimeFRO'
import { ConfirmEndMatchFRO } from './ConfirmEndMatchFRO'

export const PeriodSwitcherFRO = ({
  currentPeriodIndex,
  selectablePeriods,
  onConfirm,
}: {
  currentPeriodIndex: number
  selectablePeriods: SelectablePeriod[]
  onConfirm: (arg0: UpdateEventBody, eventId: number) => Promise<void>
}) => {
  const logFirebaseWithEvent = useFirebaseWithEvent()
  const [selectedPeriod, setSelectedPeriod] = useState<SelectablePeriod | null>(null)

  const { event } = useEventState()

  const intl = useIntl()

  const handleOpenPeriod = (period: SelectablePeriod) => {
    setSelectedPeriod(period)
    logFirebaseWithEvent(FirebaseEventName.AddEntity, FirebaseEventType.Incident, FirebaseEventIncidentAction.Period)
  }

  const responsivePopupClose = (discarded?: boolean | undefined) => {
    setSelectedPeriod(null)
    if (discarded)
      logFirebaseWithEvent(
        FirebaseEventName.DiscardEntity,
        FirebaseEventType.Incident,
        FirebaseEventIncidentAction.Period,
      )
    else
      logFirebaseWithEvent(
        FirebaseEventName.SubmitEntity,
        FirebaseEventType.Incident,
        FirebaseEventIncidentAction.Period,
      )
  }

  const hasFinished = event.status.type === StatusType.FINISHED || isSpecialStatus(event.status.code)
  const hasNotStarted = event.status.code === NOT_STARTED_CODE

  return (
    <>
      <ArrowSlider w="100%" initialIndex={currentPeriodIndex} gapX="sm">
        {selectablePeriods.map((period, index) => (
          <Fragment key={index}>
            <PeriodBox
              onClick={() => handleOpenPeriod(period)}
              periodName={intl.formatMessage({ id: period.shortTitleKey })}
              periodStartTimestamp={null}
              isActive={!!period.active}
              isDisabled={(index !== currentPeriodIndex && index !== currentPeriodIndex + 1) || hasFinished}
            />

            {!hasNotStarted && period.code === FOURTH_QUARTER_CODE && (
              <ConfirmFullTimeFRO event={event} onConfirm={onConfirm} />
            )}

            {!hasNotStarted && period.code === OVERTIME && <ConfirmEndMatchFRO event={event} onConfirm={onConfirm} />}
          </Fragment>
        ))}
      </ArrowSlider>

      <PeriodStartFormFRO isOpen={!!selectedPeriod} selectedPeriod={selectedPeriod} onClose={responsivePopupClose} />
    </>
  )
}
