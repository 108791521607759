import { EntityAllowedActions, PermissionAction, PermissionScope } from 'entities/Permission'
import { usePermissions } from 'shared/hooks'

interface PermissionGuard {
  children: React.ReactNode
  entityScopes?: Array<PermissionScope>
  entityAllowedActions?: EntityAllowedActions
  checkAllowedActions?: Array<PermissionAction>
  entityAllowedActionsScope?: PermissionScope
  loaderCtxName?: string
}

export const PermissionGuard = ({
  children,
  entityScopes,
  entityAllowedActions,
  checkAllowedActions = [
    PermissionAction.Create,
    PermissionAction.Read,
    PermissionAction.Update,
    PermissionAction.Delete,
  ],
  entityAllowedActionsScope = PermissionScope.This,
  loaderCtxName,
}: PermissionGuard) => {
  const { guardScope, guardEntity } = usePermissions(loaderCtxName)

  // Check scope and actions
  if (entityScopes && guardScope(entityScopes, checkAllowedActions)) return children

  // Check entity actions
  if (entityAllowedActions && guardEntity(entityAllowedActions, checkAllowedActions, entityAllowedActionsScope))
    return children

  return null
}
