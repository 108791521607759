import { useEffect } from 'react'
import { Box } from '@sofascore/ui'
import { Outlet, useParams } from 'react-router-dom'
import useSWR from 'swr'
import { BasicTournamentsResponse, UniqueTournamentDetailsResponse } from 'entities/UniqueTournament'

import { tournamentsList, uniqueTournamentDetails } from 'api/routes'
import {
  MyCompetitionsActionType,
  useMyCompetitionsDispatch,
  useMyCompetitionsState,
} from 'modules/MyCompetitions/providers/MyCompetitionsProvider'
import { Loading } from 'components/Loading'
import { PageHeader } from 'components/Layout/PageHeader'
import { PageContent } from 'components/Layout/PageContent'
import { Error } from 'components/Error'
import { CompetitionList } from 'modules/MyCompetitions/components/CompetitionList'
import { SERVER_ERROR_PAGE_MESSAGE } from 'utils'
import { PAGE_HEADER_DESKTOP_HEIGHT } from 'components/Layout/PageHeader/config'
import { MY_COMPETITION_HEADER_MOBILE_HEIGHT } from 'modules/MyCompetitions/components/MyCompetitionHeader/config'
import * as S from 'modules/MyCompetitions/styles'

const MyCompetition = () => {
  const { competitionId, seasonId } = useParams()
  const { uniqueTournament } = useMyCompetitionsState()
  const myCompetitionsDispatch = useMyCompetitionsDispatch()

  const { data: uniqueTournamentResponse, error: uniqueTournamentError } = useSWR<UniqueTournamentDetailsResponse>(
    competitionId && Number(competitionId) ? uniqueTournamentDetails(Number(competitionId)) : null,
  )
  const { data: tournamentListResponse, error: tournamentListError } = useSWR<BasicTournamentsResponse>(
    competitionId && seasonId ? tournamentsList(+competitionId, +seasonId) : null,
  )

  useEffect(() => {
    if (uniqueTournamentResponse) {
      myCompetitionsDispatch({
        type: MyCompetitionsActionType.SET_UNIQUE_TOURNAMENT,
        payload: uniqueTournamentResponse.uniqueTournament,
      })
    }
  }, [myCompetitionsDispatch, uniqueTournamentResponse])

  useEffect(() => {
    if (tournamentListResponse) {
      myCompetitionsDispatch({
        type: MyCompetitionsActionType.SET_TOURNAMENTS,
        payload: tournamentListResponse.tournaments,
      })
    }
  }, [myCompetitionsDispatch, tournamentListResponse])

  const error = uniqueTournamentError | tournamentListError

  return (
    <>
      <PageHeader hideMobile={true} />

      <PageContent pt={[MY_COMPETITION_HEADER_MOBILE_HEIGHT, PAGE_HEADER_DESKTOP_HEIGHT]}>
        <S.MyCompetitions>
          <CompetitionList hideMobile={true} />

          <Box maxW={['100%', 700]} w="100%" mx={[0, 'lg']}>
            {!error && !uniqueTournament && <Loading />}
            {!error && uniqueTournament && (
              <>
                <Outlet />
              </>
            )}
            {!!error && <Error title={SERVER_ERROR_PAGE_MESSAGE} />}
          </Box>
        </S.MyCompetitions>
      </PageContent>
    </>
  )
}

export default MyCompetition
