import { Box, Flex } from '@sofascore/ui'
import styled from 'styled-components'

export const Label = styled(Box)<{ hasValue: boolean }>`
  position: absolute;
  top: 14px;
  left: ${p => p.theme.spacing.lg};

  font: ${p => p.theme.typography.font.body.large};
  color: ${p => p.theme.colors.onSurface.nLv3};

  user-select: none;
  pointer-events: none;
  transition: top 100ms, font 100ms, color 100ms;
  transition-timing-function: ease-in-out;

  ${p =>
    p.hasValue &&
    `
    top: ${p.theme.spacing.xs};
    font: ${p.theme.typography.font.assistive.default};
  `}
`

export const Wrapper = styled(Flex)<{
  isDisabled?: boolean
}>`
  position: relative;

  min-height: 48px;

  align-items: center;

  padding: ${p => `0 ${p.theme.spacing.lg}`};
  border: 1px solid ${p => p.theme.colors.onSurface.nLv4} !important;
  border-radius: ${p => p.theme.spacing.sm};
  box-sizing: border-box;

  cursor: ${p => (p.isDisabled ? 'not-allowed' : 'pointer')};

  ${p => p.isDisabled && 'opacity: 0.5;'}

  #value {
    border: none;
    background-color: transparent;
    box-sizing: border-box;
    outline: none;

    font: ${p => p.theme.typography.font.body.large};
    color: ${p => p.theme.colors.onSurface.nLv1};
  }
`
