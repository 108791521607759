import styled from 'styled-components'

export const PickerContainer = styled.div`
  z-index: 10001;
  width: 100%;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  &,
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
`

export const PickerInner = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  padding: 0 20px;

  font-size: 12px;

  mask-image: linear-gradient(to top, transparent, transparent 5%, white 20%, white 80%, transparent 95%, transparent);

  .unit + .column {
    margin-left: 10px;
  }
`

export const HighlightUnit = styled.div`
  color: rgba(34, 34, 38, 0.45);
  margin-left: 3px;
`

export const PickerHighlight = styled.div`
  position: absolute;
  top: 50%;
  left: 0;

  width: 100%;

  pointer-events: none;

  background-color: rgba(15, 32, 44, 0.15);
  border-radius: 16px;
`
