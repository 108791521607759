import { Box, Flex, Image, Text } from '@sofascore/ui'
import { FormattedMessage, useIntl } from 'react-intl'
import { useState } from 'react'
import { useEventState } from 'entities/Event'
import { StatusCode } from 'entities/Status'
import { useToast } from 'shared/lib'
import { useErrorHandler } from 'shared/hooks'

import { Button } from 'components/Button'
import IncidentBox from 'components/IncidentBox'
import { teamImage } from 'api/routes'
import { updateEvent } from 'api'
import { UpdateEventBody } from 'modules/Matches/interface'

interface Props {
  handleClose: () => void
  revalidateEvent: () => void
}

const InterruptForm = ({ handleClose, revalidateEvent }: Props) => {
  const intl = useIntl()
  const handleError = useErrorHandler()

  const { initialEvent } = useEventState()
  const { enqueueToast } = useToast()
  const { id, homeTeam, awayTeam, homeScore, awayScore, currentPeriodStartTimestamp, crowdsourcingDataDisplayEnabled } =
    initialEvent

  const [selectedTeamId, setSelectedTeamId] = useState<number | null>(null)

  const handleTeamChange = (newTeamId: number | null) => {
    setSelectedTeamId(newTeamId)
  }

  const handleConfirm = async () => {
    try {
      const updateData: UpdateEventBody = {
        status: { code: StatusCode.Interrupted },
        homeScore,
        awayScore,
        currentPeriodStartTimestamp: currentPeriodStartTimestamp ?? null,
        aggregatedWinnerCode: selectedTeamId === homeTeam.id ? 1 : selectedTeamId === awayTeam.id ? 2 : 3,
        // If editor takes over crowdsourced event, delete all incidents
        resetScore: crowdsourcingDataDisplayEnabled,
      }

      await updateEvent(id, updateData)

      revalidateEvent()
      handleClose()
      enqueueToast(intl.formatMessage({ id: 'toastSuccess' }), { variant: 'success' })
    } catch (err) {
      handleError(err)
    }
  }

  return (
    <Flex direction="column">
      <Box px={50} py="lg">
        <Text fontSize="medium" fontWeight="medium" align="center" style={{ display: 'block' }}>
          <FormattedMessage id="match_interrupted_info_text" />
        </Text>
      </Box>

      <Box bg="surface.s2" mx="sm" br={8}>
        <Flex direction="column" align="center">
          <Text fontSize="regular" fontWeight="bold" mt={16} mb={8}>
            <FormattedMessage id="select_aggregate_winner" />
          </Text>
        </Flex>

        <Flex mt="xl" mb="lg" px="xxl" justify="center">
          <IncidentBox
            label={intl.formatMessage({ id: 'no_winner' })}
            isActive={!selectedTeamId}
            onClick={() => handleTeamChange(null)}
            mx="sm"
          />
          <IncidentBox
            label={homeTeam.name}
            isActive={selectedTeamId === homeTeam.id}
            onClick={() => handleTeamChange(homeTeam.id)}
            icon={<Image src={teamImage(homeTeam.id)} w={24} h={24} alt={homeTeam.name} />}
            mx="sm"
          />
          <IncidentBox
            label={awayTeam.name}
            isActive={selectedTeamId === awayTeam.id}
            onClick={() => handleTeamChange(awayTeam.id)}
            icon={<Image src={teamImage(awayTeam.id)} w={24} h={24} alt={awayTeam.name} />}
            mx="sm"
          />
        </Flex>
      </Box>

      <Flex justify="center" gapX={16} px={40} py="xl" pt={['xl', 100]}>
        <Button variant="outlined" fullWidth onClick={handleClose}>
          <FormattedMessage id="cancel" />
        </Button>

        <Button variant="contained" fullWidth onClick={handleConfirm}>
          <FormattedMessage id="confirm" />
        </Button>
      </Flex>
    </Flex>
  )
}

export default InterruptForm
