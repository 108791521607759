import { DialogTitle, Theme, Typography, withStyles } from '@material-ui/core'

export const InfoNoteDialogTitle = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
    padding: theme.spacing(3),
    textAlign: 'center',
  },
}))(DialogTitle)

export const InfoNoteTitle = withStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
}))(Typography)

export const InfoNoteText = withStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(4),
    padding: '0 4px',
    fontSize: 16,
  },
}))(Typography)
