import { Flex, Text } from '@sofascore/ui'
import { BasicEvent, WinnerCode } from 'entities/Event'
import { StatusType } from 'entities/Status'

interface AggregateScoreProps {
  event: BasicEvent
}

const AggregateScore = ({ event }: AggregateScoreProps) => {
  const { homeScore, awayScore, winnerCode, feedLocked, status, crowdsourcingDataDisplayEnabled } = event

  const isLive = status.type === StatusType.IN_PROGRESS
  const isFinished = status.type === StatusType.FINISHED

  const isCrowdsourcingLive = crowdsourcingDataDisplayEnabled && isLive
  const isCrowdsourcingFinished = crowdsourcingDataDisplayEnabled && isFinished

  const isHomeScoreFaded = winnerCode === WinnerCode.Away || winnerCode === WinnerCode.Draw || isCrowdsourcingFinished
  const isAwayScoreFaded = winnerCode === WinnerCode.Home || winnerCode === WinnerCode.Draw || isCrowdsourcingFinished

  if (homeScore?.display === undefined || awayScore?.display === undefined) return null

  return (
    <Flex direction="column" justify="space-between" style={{ opacity: feedLocked ? '.5' : 1 }} ml="sm">
      <Text
        color={
          isCrowdsourcingLive
            ? 'primary.default'
            : isLive
            ? 'sofaSingles.live'
            : isHomeScoreFaded
            ? 'onSurface.nLv3'
            : 'onSurface.nLv1'
        }
        align="right"
      >
        {homeScore.display}
      </Text>
      <Text
        color={
          isCrowdsourcingLive
            ? 'primary.default'
            : isLive
            ? 'sofaSingles.live'
            : isAwayScoreFaded
            ? 'onSurface.nLv3'
            : 'onSurface.nLv1'
        }
        align="right"
      >
        {awayScore.display}
      </Text>
    </Flex>
  )
}

export default AggregateScore
