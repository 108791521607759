import { IconProps, IconBase } from './Base'

const IconFilter = ({ width = 24, height = 24, fill }: IconProps) => {
  return (
    <IconBase width={width} height={height} viewBox="0 0 24 24">
      <path fill={fill} d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" fillRule="evenodd" />
    </IconBase>
  )
}

export default IconFilter
