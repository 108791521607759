import { IconBase, IconProps } from './Base'

export const IconEditorLogo = ({ width = 32, height = 32, fill = '#fff', ...rest }: IconProps) => (
  <IconBase width={width} height={height} viewBox="0 0 32 32" {...rest}>
    <g id="Sofascore_Logomark">
      <path
        id="editor_logomark"
        d="M31.6393 16.0172C31.7702 16.0172 31.873 16.1235 31.873 16.2515V31.7656C31.873 31.8938 31.7702 32 31.6393 32H0.233745C0.105969 32 4.78561e-05 31.8969 4.78561e-05 31.7656V26.7037C4.78561e-05 26.5755 0.102859 26.4693 0.233745 26.4693H26.1439C26.2716 26.4693 26.3776 26.335 26.3776 26.2037V16.2515C26.3776 16.1235 26.4804 16.0172 26.6112 16.0172H31.6393ZM9.57036 17.6983L14.2465 22.3916L9.79465 22.3978C9.66695 22.3978 9.57036 22.3009 9.57036 22.1729V17.6983ZM31.6848 0.000959999C31.8156 0.000959999 31.9016 0.0875199 31.9016 0.21568V2.3916L16.3369 17.9982L17.2963 18.9606L31.9016 4.30592L31.9042 8.48039L18.2933 22.3291H16.1966L9.56095 15.6672V13.5644L23.0442 0.0032H27.4368L12.9006 14.5705L13.8602 15.5329L23.7421 5.59904L29.3247 0L31.6848 0.000959999ZM21.0597 4e-05L15.6078 5.53388H5.74784C5.62015 5.53388 5.51422 5.637 5.51422 5.7682V15.7485C5.51422 15.8766 5.41141 15.9828 5.28053 15.9828H0.233777C0.106001 15.9828 0 15.8797 0 15.7485V0.23436C0 0.10628 0.102811 4e-05 0.233777 4e-05H21.0597Z"
        fill={fill}
      />
    </g>
  </IconBase>
)
