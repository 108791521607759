import { IconProps, IconBase } from './Base'

const IconError = ({ fill = '#E03E3E' }: IconProps) => {
  return (
    <IconBase width={24} height={24} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <circle cx="12" cy="12" r="12" fill={fill} />
        <path
          fill="#FFF"
          d="M18 7.2 16.8 6 12 10.8 7.2 6 6 7.2l4.8 4.8L6 16.8 7.2 18l4.8-4.8 4.8 4.8 1.2-1.2-4.8-4.8z"
        />
      </g>
    </IconBase>
  )
}

export default IconError
