import { Autocomplete, Box, Button, Flex, Image, Input, Text } from '@sofascore/ui'
import { Team } from 'entities/Team'
import React, { useEffect, useState } from 'react'
import { useToast } from 'shared/lib'
import { FormattedMessage, useIntl } from 'react-intl'
import { ImageUploadResponse } from 'shared/api'
import { UserSuggestionData } from 'entities/Suggestion'
import { EntityType } from 'entities/EntityType'
import { PermissionAction } from 'entities/Permission'
import { usePermissions } from 'shared/hooks'
import { FirebaseEventName, FirebaseEventType } from 'shared/lib/firebase/model'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'

import { ImageUploader } from 'components/ImageUploader'
import { createUserSuggestion, deleteImage, uploadImage } from 'api'
import { toBase64 } from 'utils/encode'
import { resizeImage } from 'utils/resize'
import { imageByHash, teamImage } from 'api/routes'
import { useCountryOptions } from 'utils/countries'

import { TEAM_IMAGE_SIZE } from './config'
import { getTeamCountryOption } from './utils'

interface Props {
  team: Team
}

const ToastSuccessMessage = () => {
  return (
    <Flex align="center">
      <Box>
        <FormattedMessage id="requestSent" /> <FormattedMessage id="suggestionSentMessage" />
      </Box>
    </Flex>
  )
}

const TeamSuggestForm = ({ team }: Props) => {
  const { guardEntity } = usePermissions()
  const canUpdatePermission = guardEntity(team.allowedActions, [PermissionAction.Update])

  const intl = useIntl()

  const countryOptions = useCountryOptions()

  const [teamName, setTeamName] = useState(team.name)
  const [teamCountry, setTeamCountry] = useState(getTeamCountryOption(team.country, intl))

  const [isUploadingImage, setIsUploadingImage] = useState(false)
  const [imageHash, setImageHash] = useState<string>()
  const [imageUrl, setImageUrl] = useState<string>(teamImage(team.id))

  const [hasChanges, setHasChanges] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const { enqueueToast } = useToast()

  const postImage = async (file: File) => {
    try {
      const encodedData = await toBase64(file)
      const resizedImageData = await resizeImage(encodedData as string, TEAM_IMAGE_SIZE)
      const resp = (await uploadImage(resizedImageData as string)) as ImageUploadResponse

      setImageHash(resp.md5)
      setIsUploadingImage(false)
      enqueueToast(intl.formatMessage({ id: 'toastSuccess' }), { variant: 'success' })
    } catch (e) {
      console.error(e)
      enqueueToast(intl.formatMessage({ id: 'toastError' }), { variant: 'error' })
    }
  }

  // Input file upload handler
  const handleInputImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Check file availability
    if (!e.target.files?.[0]) {
      return
    }

    setIsUploadingImage(true)
    const file: File = e.target.files[0]

    // If profile image already exists delete it
    if (imageHash) deleteImage(imageHash)

    // Post new image
    postImage(file)

    setHasChanges(true)
  }

  const resetTeamInfo = () => {
    setTeamName(team.name)
    setTeamCountry(getTeamCountryOption(team.country, intl))
    setImageUrl(teamImage(team.id))
    setImageHash(undefined)
  }

  const handleSubmit = async () => {
    if (teamName.length && teamCountry?.value) {
      setIsSaving(true)

      const suggestionData: UserSuggestionData = {
        editor: true,
      }

      if (teamName !== team.name) {
        suggestionData.name = teamName
      }
      if (imageHash) {
        suggestionData.imageUrl = imageUrl
      }
      if (teamCountry.value !== team.country?.alpha2) {
        suggestionData.country = teamCountry.value
      }

      try {
        await createUserSuggestion(EntityType.TEAM, team.id, suggestionData)
        logFirebaseEvent(FirebaseEventName.SubmitEntity, {
          type: FirebaseEventType.ExistingTeam,
          id: team.id,
          sport: team.sport.name,
        })

        enqueueToast(<ToastSuccessMessage />, { variant: 'success', duration: 10000 })
      } catch (e) {
        console.error(e)
        enqueueToast(intl.formatMessage({ id: 'toastError' }), { variant: 'error' })
      } finally {
        setIsSaving(false)
        resetTeamInfo()
      }
    }
  }

  useEffect(() => {
    setHasChanges(teamName !== team.name || teamCountry?.value !== team.country?.alpha2)
  }, [teamName, teamCountry, team.name, team.country])

  useEffect(() => {
    if (imageHash) setImageUrl(imageByHash(imageHash))
  }, [imageHash])

  const isSubmitDisabled = teamName === '' || !teamCountry

  return (
    <Flex direction="column" align="center" mt={[0, 'sm']} p="lg" bg="surface.s1" br="sm" elevation={2}>
      <ImageUploader
        imageUrl={imageUrl}
        onImageUpload={handleInputImageUpload}
        isUploading={isUploadingImage}
        disabled={!canUpdatePermission}
      />

      <Input
        label={intl.formatMessage({ id: 'teamName' })}
        value={teamName}
        onChange={newTeamName => setTeamName(newTeamName)}
        mt="lg"
        w="100%"
        maxW={330}
        disabled={!canUpdatePermission}
      />

      <Autocomplete
        label={intl.formatMessage({ id: 'country' })}
        value={teamCountry}
        options={countryOptions}
        disabled={!canUpdatePermission}
        renderOption={option => (
          <Flex align="center" px="md" py="sm">
            <Image
              w={24}
              h={24}
              src={`/images/flags/${option.value.toString().toLowerCase()}.png`}
              alt="teamCountryOption"
              mr="sm"
            />

            <Text fontSize="regular">{option.label}</Text>
          </Flex>
        )}
        startAdornment={
          teamCountry ? (
            <Image
              w={20}
              h={20}
              src={`/images/flags/${teamCountry.value.toString().toLowerCase()}.png`}
              alt="selectedTeamCountry"
              mr="xs"
            />
          ) : null
        }
        onChange={setTeamCountry}
        clearable={false}
        maxVisibleOptions={6}
        mt="lg"
        w="100%"
        maxW={330}
      />

      {hasChanges && (
        <Box position="fixed" bottom={0} w="100%" maxW={280} px="sm" pb={['sm', 'xl']}>
          <Button py="lg" w="100%" onClick={handleSubmit} disabled={isSubmitDisabled || isSaving}>
            <Flex justify="center">
              <FormattedMessage id="save_all_changes" />
            </Flex>
          </Button>
        </Box>
      )}
    </Flex>
  )
}

export default TeamSuggestForm
