import { Box, Flex } from '@sofascore/ui'
import styled from 'styled-components'

export const Label = styled(Box)`
  margin-top: ${p => p.theme.spacing.xs};

  font: ${p => p.theme.typography.font.assistive.default};
  color: ${p => p.theme.colors.onSurface.nLv3};

  user-select: none;
`

export const Wrapper = styled(Flex)<{
  onClick?: () => void
  isDisabled?: boolean
  variant?: 'contained' | 'outlined'
}>`
  min-height: 48px;

  display: flex;
  flex-direction: column;

  padding: ${p => `0 ${p.theme.spacing.lg}`};
  border: 1px solid ${p => (p.variant === 'contained' ? 'none' : p.theme.colors.onSurface.nLv4)};
  border-radius: ${p => p.theme.spacing.sm};
  box-sizing: border-box;

  cursor: ${p => {
    if (p.onClick) {
      if (p.isDisabled) {
        return 'not-allowed'
      } else {
        return 'pointer'
      }
    } else {
      return 'default'
    }
  }};

  ${p => p.isDisabled && 'opacity: 0.5;'}

  #value {
    background-color: transparent;
    box-sizing: border-box;

    font: ${p => p.theme.typography.font.body.large};
    color: ${p => p.theme.colors.onSurface.nLv1};
  }
`
