import { ChangeEvent, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Flex, Text } from '@sofascore/ui'
import { PlayerCard } from 'entities/Player'
import {
  ADD_TO_SUBSTITUTIONS_MULTIPLE,
  DynamicLineupsPlayer,
  REMOVE_FROM_SUBSTITUTIONS,
  groupPlayersByPosition,
  mapFootballPositionToGroupKey,
  sortLineupPlayers,
  useLineupsDispatch,
} from 'features/AddLineups'
import { Checkbox } from 'shared/ui'

import ResponsivePopUp from 'components/ResponsivePopUp'
import { IconButton } from 'components/IconButton'
import { IconAddImageOutlined } from 'components/Icons/IconAddImageOutlined'
import { IconRemoveFilled } from 'components/Icons/IconRemoveFilled'
import { Button } from 'components/Button'
import { getPlayerPositionId } from 'utils/player'

export const FootballSubstitutions = ({
  substitutions,
  availablePlayers,
}: {
  substitutions: DynamicLineupsPlayer[]
  availablePlayers: DynamicLineupsPlayer[]
}) => {
  const [checkedPlayers, setCheckedPlayers] = useState<DynamicLineupsPlayer[]>([])
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useLineupsDispatch()

  const groupedAvailablePlayers = groupPlayersByPosition(availablePlayers)

  const handleRemoveSubstitution = (player: DynamicLineupsPlayer) => {
    dispatch({ type: REMOVE_FROM_SUBSTITUTIONS, payload: { lineupPlayer: player } })
  }

  const handleCheckPlayer = (event: ChangeEvent<HTMLInputElement>, player: DynamicLineupsPlayer) => {
    if (event.target.checked) {
      setCheckedPlayers(previousPlayers => [...previousPlayers, { ...player }])
    } else {
      setCheckedPlayers(previousPlayers => previousPlayers.filter(p => p.player.id !== player.player.id))
    }
  }

  const handleAddSubstitutions = (players: DynamicLineupsPlayer[]) => {
    dispatch({ type: ADD_TO_SUBSTITUTIONS_MULTIPLE, payload: { lineupPlayers: players } })
    setIsOpen(false)
  }

  const handleSave = () => {
    handleAddSubstitutions(checkedPlayers)
    setCheckedPlayers([])
    setIsOpen(false)
  }

  const handleClose = () => {
    setCheckedPlayers([])
    setIsOpen(false)
  }

  return (
    <Box>
      <Flex justify="space-between" align="center" py="lg">
        <Text font="display.micro" fontWeight={600}>
          <FormattedMessage id="editor_substitutions" />
        </Text>

        <IconButton onClick={() => setIsOpen(true)} p="0">
          <IconAddImageOutlined />
        </IconButton>
      </Flex>

      <Flex direction="column">
        {sortLineupPlayers(substitutions).map((lineupPlayer: DynamicLineupsPlayer) => (
          <Flex key={lineupPlayer.player.id} justify="space-between" align="center" py="sm">
            <PlayerCard
              player={lineupPlayer.player}
              showJerseyNumber
              bottomContent={
                <Text font="assistive.default" color="onSurface.nLv3">
                  <FormattedMessage id={getPlayerPositionId(lineupPlayer.player.position, 'football')} />
                </Text>
              }
            />
            <IconButton onClick={() => handleRemoveSubstitution(lineupPlayer)} p="0">
              <IconRemoveFilled />
            </IconButton>
          </Flex>
        ))}
      </Flex>

      <ResponsivePopUp
        isOpen={isOpen}
        onClose={handleClose}
        heading={
          <Text font="display.large">
            <FormattedMessage id="editor_add_substitutions" />
          </Text>
        }
      >
        <Box overflow="auto" maxH={600} br="lg">
          {Object.keys(mapFootballPositionToGroupKey).map(position => {
            return (
              <Box key={position}>
                <Box px="lg" pt="lg" pb="sm">
                  <Text font="assistive.default">
                    <FormattedMessage id={mapFootballPositionToGroupKey[position]} />
                  </Text>
                </Box>
                <Flex direction="column">
                  {groupedAvailablePlayers[position] &&
                    sortLineupPlayers(groupedAvailablePlayers[position]).map(lineupPlayer => (
                      <Flex key={lineupPlayer.player.id} align="center" justify="space-between" px="lg" py="sm">
                        <PlayerCard player={lineupPlayer.player} showJerseyNumber />
                        <Checkbox
                          name={`missing-player-${lineupPlayer.player.id}`}
                          checked={!!checkedPlayers.find(cp => cp.player.id === lineupPlayer.player.id)}
                          onChange={e => handleCheckPlayer(e, lineupPlayer)}
                        />
                      </Flex>
                    ))}
                </Flex>
              </Box>
            )
          })}

          {groupedAvailablePlayers['-'] && (
            <Box>
              <Box px="lg" pt="lg" pb="sm">
                <Text font="assistive.default">
                  <FormattedMessage id="unknown" />
                </Text>
              </Box>
              <Flex direction="column" gapY="sm">
                {groupedAvailablePlayers['-'] &&
                  sortLineupPlayers(groupedAvailablePlayers['-']).map(lineupPlayer => (
                    <Flex key={lineupPlayer.player.id} align="center" justify="space-between" px="lg" py="sm">
                      <PlayerCard player={lineupPlayer.player} showJerseyNumber />
                      <Checkbox
                        name={`missing-player-${lineupPlayer.player.id}`}
                        checked={!!checkedPlayers.find(cp => cp.player.id === lineupPlayer.player.id)}
                        onChange={e => handleCheckPlayer(e, lineupPlayer)}
                      />
                    </Flex>
                  ))}
              </Flex>
            </Box>
          )}
        </Box>

        <Box p="xl">
          <Button variant="contained" fullWidth onClick={handleSave} disabled={checkedPlayers.length === 0}>
            <FormattedMessage id="add" />
          </Button>
        </Box>
      </ResponsivePopUp>
    </Box>
  )
}
