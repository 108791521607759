import { Box } from '@sofascore/ui'
import styled from 'styled-components'

import { borders, textLightColor } from 'styles/color'

export const FeedItemPlaceholder = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 72px;
  background-color: ${borders.lightRgba};
  border-radius: 4px;
`

export const FeedItemSummary = styled(Box)`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-top: 10px;
  color: ${textLightColor};
  padding-right: 28px;
`

export const FeedItemPubDate = styled(Box)`
  font-size: 12px;
  color: ${textLightColor};
`
