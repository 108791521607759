import { useEventState } from 'entities/Event'
import { SelectablePeriod } from 'features/EditPeriod'
import { useMemo } from 'react'
import { PAUSE_CODE } from 'entities/Status'

import { isExtra } from 'components/ProgressBar/helper'

import { basketballExtraPeriods, basketballRegularPeriods } from '../config'

export const useBasketballPeriods = () => {
  const { event } = useEventState()

  const selectablePeriods: SelectablePeriod[] = useMemo(() => {
    const initialPeriods = Array.from(basketballRegularPeriods)

    if (isExtra(event)) {
      initialPeriods.push(...basketballExtraPeriods)
    }

    return initialPeriods.map(({ startTimeStampPath, ...otherProps }, index) => {
      return {
        ...otherProps,
        startTimeStampPath: startTimeStampPath,
        active: (() => {
          if (otherProps.code === PAUSE_CODE && event.status.code === otherProps.code) {
            // @ts-ignore
            const ind = initialPeriods.findLastIndex(p => event.time[p.startTimeStampPath])
            const pauseIndex = ind + 1

            return pauseIndex === index
          }

          return event.status.code === otherProps.code
        })(),
      }
    })
  }, [event])

  const activePeriodIndex = selectablePeriods.findIndex(p => p.active)

  return {
    activePeriodIndex,
    selectablePeriods,
  }
}
