import { FormattedMessage } from 'react-intl'
import { Box, Flex, Text } from '@sofascore/ui'
import { PropsWithChildren } from 'react'

import { backgrounds, GREEN, secondaryColor } from 'styles/color'

import { DynamicLineupsPlayer } from '../../model'

interface PlayerCounterProps {
  starters: DynamicLineupsPlayer[]
  limit: number
}

export const PlayerCounter = ({ starters, limit, children }: PropsWithChildren<PlayerCounterProps>) => {
  const value = starters.length / limit
  const isFull = value >= 1

  return (
    <Flex align="center" justify="space-between">
      <Flex align="center">
        <Text font="display.medium" fontWeight="bold">
          <FormattedMessage id="players" />
        </Text>

        <Box display="block" h={6} w={6} bg="#ddd" borderRadius="50%" mx="sm"></Box>

        <Text font="display.medium" fontWeight="bold">
          {starters.length}/{limit}
        </Text>

        <Box h={4} borderRadius={12} w={100} bg={backgrounds.lightRgba} ml="lg">
          <Box
            borderRadius="md"
            bg={isFull ? GREEN.incident : secondaryColor}
            w={`${value * 100}%`}
            h="100%"
            style={{ transition: 'all 0.15s linear' }}
          />
        </Box>
      </Flex>

      {children}
    </Flex>
  )
}
