import { IconButton } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { SyntheticEvent, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box } from '@sofascore/ui'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'
import { FirebaseEventName } from 'shared/lib/firebase/model'

import { IconClose } from 'components/Icons/IconClose'
import { lightGray } from 'styles/color'
import {
  extractFeatureImageUrl,
  getIdFromGuid,
  isItemHidden,
  isItemRead,
  markItemAsHidden,
  useRssFeedItems,
} from 'modules/WhatsNew/utils/rss'
import { IconMegaphone } from 'components/Icons'

import { useStyles } from './styles'

interface WhatsNewHomeTile {
  id: string
  imgUrl?: string
  title: string
  removeFromList: () => void
}

const WhatsNewHomeTile = ({ id, imgUrl, title, removeFromList }: WhatsNewHomeTile) => {
  const classes = useStyles()
  const navigate = useNavigate()

  const handleTileClick = () => {
    logFirebaseEvent(FirebaseEventName.OpenInfo)
    navigate(`/whats-new#${id}`)
  }

  const handleCloseButtonClick = (e: SyntheticEvent) => {
    markItemAsHidden(id)
    removeFromList()
    e.stopPropagation()
  }

  return (
    <Box className={classes.whatsNewHomeTileContainer} onClick={handleTileClick}>
      <Box display="flex" style={{ alignItems: 'center' }}>
        <Box>
          {imgUrl ? (
            <img src={imgUrl} width="56px" height="56px" style={{ objectFit: 'contain' }} />
          ) : (
            <Box className={classes.feedItemPlaceholder}>
              <IconMegaphone fill="#000" />
            </Box>
          )}
        </Box>

        <Box className={classes.textContent}>
          <Box className={classes.markerNew}>
            <FormattedMessage id="wn_new" />
          </Box>
          <Box className={classes.title}>{title}</Box>
        </Box>
      </Box>

      <Box className={classes.closeButtonContainer}>
        <IconButton onClick={handleCloseButtonClick}>
          <IconClose width={24} height={24} fill={lightGray} />
        </IconButton>
      </Box>
    </Box>
  )
}

export const HomeWhatsNew = () => {
  const classes = useStyles()

  const feedItems = useRssFeedItems()

  // Used for component update
  const [counter, setCounter] = useState<number>(0)

  const filteredFeed = feedItems
    // Display only not read features
    .filter(item => {
      const id = getIdFromGuid(item.guid)
      return !isItemRead(id)
    })
    // Display only not hidden features
    .filter(item => {
      const id = getIdFromGuid(item.guid)
      return !isItemHidden(id)
    })
    // Show max 2 new features on home screen
    .filter((_, index) => {
      return index < 2
    })

  return (
    <>
      {filteredFeed.length !== 0 && (
        <Box className={classes.listContainer} py="sm">
          {
            // Default feed sort is by date (newest first)
            filteredFeed.map(item => {
              const id = getIdFromGuid(item.guid)

              return (
                <WhatsNewHomeTile
                  key={id}
                  id={id}
                  title={item.title}
                  imgUrl={extractFeatureImageUrl(item['content:encoded'])}
                  removeFromList={() => {
                    setCounter(counter + 1)
                  }}
                />
              )
            })
          }
        </Box>
      )}
    </>
  )
}
