import { useState } from 'react'
import { useMediaContext } from '@sofascore/ui'
import { useLineupsState } from 'features/AddLineups'

import { MobilePitchLineups } from './MobilePitchLineups'
import { DesktopPitchLineups } from './DesktopPitchLineups'

export const FootballPitchLineups = () => {
  const { homeTeam, homeLineupPlayers, homeKit, awayTeam, awayLineupPlayers, awayKit } = useLineupsState()
  const { isMobile } = useMediaContext()
  const [activeTeamId, setActiveTeamId] = useState(homeTeam.id)

  const isHomeTeam = homeTeam.id === activeTeamId

  const commonProps = {
    lineupPlayers: isHomeTeam ? homeLineupPlayers : awayLineupPlayers,
    otherTeamKit: isHomeTeam ? awayKit : homeKit,
    isHomeTeam,
    activeTeamId,
    onChangeTeam: (teamId: number) => setActiveTeamId(teamId),
    homeTeam,
    awayTeam,
  }

  if (isMobile) {
    return <MobilePitchLineups {...commonProps} />
  }

  return <DesktopPitchLineups {...commonProps} />
}
