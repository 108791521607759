import { Helmet } from 'react-helmet-async'
import { tabTitleWrapper } from 'shared/utils'
import type { ReactNode } from 'react'
import { useEventState } from 'entities/Event'

export const MatchesTabTitle = ({ title }: { title: string | ReactNode }) => {
  const { event } = useEventState()
  const {
    homeTeam: { name: homeTeamName = '' } = {},
    awayTeam: { name: awayTeamName = '' } = {},
    tournament: { name: tournamentName = '' } = {},
    season: { year = '' } = {},
  } = event || {}

  const tabTitle = `${title} - ${homeTeamName} vs ${awayTeamName} - ${tournamentName} ${year}`

  return (
    <Helmet>
      <title>{tabTitleWrapper(tabTitle)}</title>
    </Helmet>
  )
}
