import { Player, getOrderedPositions } from 'entities/Player'
import { SportName } from 'entities/Sport'
import { EditorSuggestion, EditorSuggestionAction } from 'entities/Suggestion'

import playerPositions from 'translations/mappings/playerPositions.json'

import { PlayerSuggestion, PositionGroup } from './interface'

export const getOrderedPositionGroups = (sport: SportName, players: Player[]) => {
  let positionGroups: PositionGroup[] = []

  // Initialize all positions
  getOrderedPositions(sport).forEach(position => {
    const positionMsgId = playerPositions[sport][position]
    positionGroups.push({ positionMsgId, players: [] })
  })

  // Add unknown category
  positionGroups.push({ positionMsgId: 'unknown', players: [] })

  players.forEach(player => {
    const targetGroupIndex = positionGroups.findIndex(
      group => group.positionMsgId === playerPositions[sport][player.position],
    )
    if (targetGroupIndex !== -1) {
      positionGroups[targetGroupIndex].players.push(player)
    }
    // If position not known add to the "Unknown" position
    else {
      positionGroups[positionGroups.length - 1].players.push(player)
    }
  })

  // Remove empty groups
  positionGroups = positionGroups.filter(group => group.players.length !== 0)

  // Sort by players jersey number, or alphabetically if no jersey number
  positionGroups.forEach(group => {
    group.players.sort((player1, player2) => {
      let movePlayer1AfterPlayer2: boolean = false

      const hasP1JerseyNumber = typeof player1.jerseyNumber === 'string'
      const hasP2JerseyNumber = typeof player2.jerseyNumber === 'string'

      if (hasP1JerseyNumber && hasP2JerseyNumber) {
        movePlayer1AfterPlayer2 = +player1.jerseyNumber! > +player2.jerseyNumber!
      } else if (hasP1JerseyNumber && !hasP2JerseyNumber) {
        movePlayer1AfterPlayer2 = false
      } else if (!hasP1JerseyNumber && hasP2JerseyNumber) {
        movePlayer1AfterPlayer2 = true
      } else {
        movePlayer1AfterPlayer2 = player1.name > player2.name
      }

      return movePlayer1AfterPlayer2 ? 1 : -1
    })
  })

  return positionGroups
}

export const mapPlayerSuggestions = (playerSuggestions: EditorSuggestion[]): PlayerSuggestion[] => {
  return playerSuggestions.map(playerSuggestion => {
    return {
      player: {
        id: playerSuggestion.id,
        name: playerSuggestion.name,
        country: playerSuggestion.valueMap.country!,
        position: playerSuggestion.valueMap.position,
        dateOfBirthTimestamp: playerSuggestion.valueMap.dateOfBirthTimestamp!,
        jerseyNumber: playerSuggestion.valueMap.jerseyNumber ?? null,
      },
      action: playerSuggestion.action,
      reason: playerSuggestion.reason,
    }
  })
}

export const mapRemovePlayerSuggestions = (playerSuggestions: Player[]): PlayerSuggestion[] => {
  return playerSuggestions.map(playerSuggestion => {
    return {
      player: {
        id: playerSuggestion.id,
        name: playerSuggestion.name,
        country: playerSuggestion.country,
        position: playerSuggestion.position,
        dateOfBirthTimestamp: playerSuggestion.dateOfBirthTimestamp!,
        jerseyNumber: playerSuggestion.jerseyNumber ?? null,
      },
      action: EditorSuggestionAction.DELETE,
    }
  })
}
