import { Flex, Image, Text } from '@sofascore/ui'
import IconAdd from '@sofascore/ui/dist/modules/Icons/IconAdd'
import IconMinimize from '@sofascore/ui/dist/modules/Icons/IconMinimize'
import { EventActionType, useEventDispatch, useEventState } from 'entities/Event'

import { teamImage } from 'api/routes'

import * as S from './styles'

export const PenaltyShootoutSectionFRO = () => {
  const { event } = useEventState()
  const { homeTeam, awayTeam, homeScore, awayScore } = event
  const dispatch = useEventDispatch()

  const incrementHomeTeamPenaltyScore = () => {
    dispatch({ type: EventActionType.INCREMENT_PENALTY_SCORE, payload: { isHome: true } })
  }

  const decrementHomeTeamPenaltyScore = () => {
    dispatch({ type: EventActionType.DECREMENT_PENALTY_SCORE, payload: { isHome: true } })
  }

  const incrementAwayTeamPenaltyScore = () => {
    dispatch({ type: EventActionType.INCREMENT_PENALTY_SCORE, payload: { isHome: false } })
  }

  const decrementAwayTeamPenaltyScore = () => {
    dispatch({ type: EventActionType.DECREMENT_PENALTY_SCORE, payload: { isHome: false } })
  }

  return (
    <>
      <Flex br="sm" elevation={1}>
        <S.TeamLogos flex="0 0 56px" direction="column" align="center" py="lg">
          <Flex direction="column" justify="center">
            <Image src={teamImage(homeTeam.id)} w="24" h="24" mb="xl" alt={homeTeam.name} />
            <Image src={teamImage(awayTeam.id)} w="24" h="24" alt={awayTeam.name} />
          </Flex>
        </S.TeamLogos>

        <S.Buttons minW={0} grow={1}>
          <Flex direction="column">
            <S.AddPenaltyButton onClick={decrementHomeTeamPenaltyScore}>
              <IconMinimize />
            </S.AddPenaltyButton>
            <S.AddPenaltyButton onClick={decrementAwayTeamPenaltyScore}>
              <IconMinimize />
            </S.AddPenaltyButton>
          </Flex>
          <Flex direction="column">
            <S.AddPenaltyButton onClick={incrementHomeTeamPenaltyScore}>
              <IconAdd />
            </S.AddPenaltyButton>
            <S.AddPenaltyButton onClick={incrementAwayTeamPenaltyScore}>
              <IconAdd />
            </S.AddPenaltyButton>
          </Flex>
        </S.Buttons>

        <S.Results flex="0 0 56px" direction="column" justify="center">
          <Flex direction="column" justify="center" align="center">
            <Text font="mainNavigationTitle" mb="20px">
              {homeScore.penalties || 0}
            </Text>
            <Text font="mainNavigationTitle">{awayScore.penalties || 0}</Text>
          </Flex>
        </S.Results>
      </Flex>
    </>
  )
}
