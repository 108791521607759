import { Link, useParams } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { Box, GraphicEmptyStateCupTree, Text } from '@sofascore/ui'
import { useTheme } from 'styled-components'
import { FirebaseEventName } from 'shared/lib/firebase/model'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'

import { Button } from 'components/Button'
import { Spinner } from 'components/Spinner'

import { useMyCompetitionsState } from '../providers/MyCompetitionsProvider'
import * as S from './styles'
import MyCompetitionTabTitle from '../components/MyCompetitionTabTitle'

/**
 * This component is loaded in Outlet (as a page content) in MyCompetition.tsx
 */
const MyCompetitionKnockout = () => {
  const intl = useIntl()
  const { competitionId, seasonId } = useParams()
  const { uniqueTournament } = useMyCompetitionsState()

  const theme = useTheme()

  const isLoading = !uniqueTournament || Object.values(uniqueTournament).length === 0

  if (isLoading) {
    return (
      <S.KnockoutContainer pt={50}>
        <Spinner width="40px" color={theme.colors.primary.default} />
      </S.KnockoutContainer>
    )
  }

  const sport = uniqueTournament?.category.sport.slug
  const uniqueTournamentSlug = uniqueTournament.slug
  const uniqueTournamentCategory = uniqueTournament.category.slug

  return (
    <>
      <MyCompetitionTabTitle title={intl.formatMessage({ id: 'knockout' })} />
      <S.KnockoutContainer p="xl">
        <Box mt="xxl">
          <GraphicEmptyStateCupTree />
        </Box>

        <Text mt="lg" fontSize="medium" color="onSurface.nLv1" fontWeight="bold">
          <FormattedMessage id="knockoutCupTree" />
        </Text>

        <Text mt="sm" color="onSurface.nLv3" align="center">
          <FormattedMessage id="cupTreeCreatedDesc" />
        </Text>

        <Box mt="xl">
          <Link
            to={`https://www.sofascore.com/tournament/${sport}/${uniqueTournamentCategory}/${uniqueTournamentSlug}/${competitionId}#${seasonId}`}
            target="_blank"
            rel="noreferrer noopener"
            onMouseDown={e => {
              if ([1, 4].includes(e.buttons)) {
                logFirebaseEvent(FirebaseEventName.OpenCore, { location: 'knockout' })
              }
            }}
          >
            <Button variant="contained">
              <FormattedMessage id="checkCupTree" />
            </Button>
          </Link>
        </Box>
      </S.KnockoutContainer>
    </>
  )
}

export default MyCompetitionKnockout
