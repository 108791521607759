import { useState } from 'react'
import { Flex } from '@sofascore/ui'
import { Chip } from 'shared/ui'
import { useIntl } from 'react-intl'

import { AdminInfoGeneral } from './AdminInfoGeneral'
import { AdminInfoSeason } from './AdminInfoSeason'
import { AdminInfoTabs } from '../model'

export const AdminInfo = () => {
  const intl = useIntl()
  const [tab, setTab] = useState(AdminInfoTabs.General)

  return (
    <>
      <Flex gapX="sm">
        <Chip
          value={intl.formatMessage({ id: 'general' })}
          isActive={tab === AdminInfoTabs.General}
          onClick={() => setTab(AdminInfoTabs.General)}
        />
        <Chip
          value={intl.formatMessage({ id: 'seasons' })}
          isActive={tab === AdminInfoTabs.Seasons}
          onClick={() => setTab(AdminInfoTabs.Seasons)}
        />
      </Flex>
      {tab === AdminInfoTabs.General && <AdminInfoGeneral />}
      {tab === AdminInfoTabs.Seasons && <AdminInfoSeason />}
    </>
  )
}
