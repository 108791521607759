import { AppBar, Toolbar, Theme, useTheme } from '@material-ui/core'
import { useMediaContext } from '@sofascore/ui'
import { PropsWithChildren } from 'react'
import { Stylable } from 'shared/model'

import { primaryDark } from 'styles/color'

export const Header = ({ children, className, style }: PropsWithChildren<Stylable>) => {
  const theme: Theme = useTheme()
  const { isMobile } = useMediaContext()
  const appBarStyles = !isMobile ? { width: '100%', backgroundColor: primaryDark } : { width: '100%' }

  return (
    <AppBar
      position="fixed"
      color="primary"
      style={{ ...appBarStyles, ...style }}
      classes={{ positionFixed: className }}
    >
      <Toolbar
        style={
          !isMobile
            ? { justifyContent: 'space-between', minHeight: theme.spacing(7), paddingLeft: 20, paddingRight: 20 }
            : {
                minHeight: theme.spacing(7),
                paddingRight: 4,
                paddingLeft: 4,
              }
        }
      >
        {children}
      </Toolbar>
    </AppBar>
  )
}
