import * as React from 'react'

import { IconBase, IconProps } from './Base'

export const IconCalendar = (props: IconProps) => (
  <IconBase width={25} height={24} viewBox="0 0 25 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M1 0h24v24H1z" />
      <path
        fill={props.fill || '#FF407F'}
        d="M18 10H8v2h10v-2zm2-7h-1V1h-2v2H9V1H7v2H6c-1.11 0-1.99.9-1.99 2L4 19a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H6V8h14v11zm-5-5H8v2h7v-2z"
      />
    </g>
  </IconBase>
)
