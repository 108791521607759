import { Keyframes, keyframes } from 'styled-components'

export const pulseKeyframes = keyframes`
  0% { opacity: 1; }
  30% { opacity: 1; }
  60% { opacity: 0.25; }
  100% { opacity: 1; }
` as Keyframes & { slow: Keyframes; fast: Keyframes }

pulseKeyframes.slow = keyframes`
  0% { opacity: 1; }
  5% { opacity: 1; }
  10% { opacity: .25; }
  15% { opacity: 1; }
  100% { opacity: 1; }
`

pulseKeyframes.fast = keyframes`
  0% { opacity: 1; }
  50% { opacity: 1; }
  51% { opacity: 0; }
  100% { opacity: 0; }
`
