import styled from 'styled-components'
import { Flex, Text } from '@sofascore/ui'

export const Link = styled.a`
  color: #fff;
  text-decoration: none;
`

export const LoginContainer = styled(Flex)`
  ${p => p.theme.breakpoints.query.md`
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%),
      url('/images/backgrounds/login-background.png');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  `}
`

export const AuthProviderButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  padding: 0 ${p => p.theme.spacing.md};

  background: ${p => p.theme.colors.surface.s1};
  border: 1px solid ${p => p.theme.colors.onSurface.nLv4};
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  transition: background-color 0.218s;

  &:hover {
    border-color: #d2e3fc;
    background-color: #f8faff;
  }
`

export const AuthProviderButtonText = styled(Text)`
  display: inline-block;
  padding: 11px 0;
  width: 300px;
  text-align: center;

  line-height: 16px;
`

export const AboutSofascoreEditorLink = styled.a`
  color: ${p => p.theme.colors.primary.default};
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.colors.primary.hover};
  }
`
