import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Flex, Box, Text } from '@sofascore/ui'
import { BasicTeam, TeamImage } from 'entities/Team'
import { Player } from 'entities/Player'

import { IconAddImageOutlined } from 'components/Icons/IconAddImageOutlined'
import { textColorRgbaDark, GREEN, RED } from 'styles/color'
import { Button } from 'components/Button'
import ResponsivePopUp from 'components/ResponsivePopUp'

import { MIN_PLAYERS } from '../../config'
import { LineupType } from '../../model'

const TeamPlayerCounter = ({
  team,
  playerCount,
  minPlayers,
}: {
  team: BasicTeam
  playerCount: number
  minPlayers: number
}) => {
  return (
    <Flex align="center">
      <TeamImage team={team} />
      <Text font="body.medium" ml="sm">
        {team.name}
      </Text>
      <Box h={6} w={6} bg="#ddd" br="50%" mx="sm"></Box>
      <Text font="body.mediumParagraph" color={textColorRgbaDark}>
        <span style={{ color: playerCount >= minPlayers ? GREEN.incident : RED.incident }}>{playerCount}</span>/
        {minPlayers}
      </Text>
      <Flex display="inline-flex" align="center" ml="auto" cursor="pointer">
        <Link to={`/team/${team.id}/squad`} style={{ display: 'flex', alignItems: 'center' }}>
          <IconAddImageOutlined width={24} height={24} />
        </Link>
      </Flex>
    </Flex>
  )
}

export const NotEnoughPlayersNote = ({
  type,
  isOpen,
  onClose,
  homeTeam,
  homePlayers,
  awayTeam,
  awayPlayers,
}: {
  type: string
  isOpen: boolean
  onClose: () => void
  homeTeam: BasicTeam
  homePlayers: Player[]
  awayTeam: BasicTeam
  awayPlayers: Player[]
}) => {
  const minPlayers = MIN_PLAYERS[type].TO_CREATE

  return (
    <ResponsivePopUp
      onClose={onClose}
      isOpen={isOpen}
      heading={
        <Text font="display.large" as="div" align="center" px="md">
          <FormattedMessage id="editor_lineup_popover_title" />
        </Text>
      }
    >
      <Box p="xl">
        <Text as="div" font="body.medium" lineHeight={1.5} fontWeight="regular" align="left">
          <FormattedMessage
            id={type === LineupType.PITCH ? 'editor_lineup_popover_text' : 'editor_list_lineup_info_note'}
            values={{ minimum_players: minPlayers }}
          />
        </Text>
      </Box>

      <Flex direction="column" gapY="md" px="xl">
        <TeamPlayerCounter team={homeTeam} playerCount={homePlayers.length} minPlayers={minPlayers} />
        <TeamPlayerCounter team={awayTeam} playerCount={awayPlayers.length} minPlayers={minPlayers} />
      </Flex>

      <Box p="xl">
        <Button variant="contained" onClick={onClose} fullWidth>
          <FormattedMessage id="close" />
        </Button>
      </Box>
    </ResponsivePopUp>
  )
}
