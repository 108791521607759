import * as React from 'react'
import { Text } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'
import { useTheme } from 'styled-components'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'
import { FirebaseEventName } from 'shared/lib/firebase/model'

import { Standings } from 'modules/Standings/interface'
import { IconRecalculate } from 'components/Icons/IconRecalculate'
import { IconEdit } from 'components/Icons/IconEdit'
import RecalculateConfirmationDialog from 'modules/StandingsRedesign/components/Dialogs/RecalculateConfirmationDialog'

import { StandingsControls } from './StandingsControls'
import { ControlButton } from './Button'
interface Props {
  standingsName: string
  tournamentStandings: Standings
  isSaving: boolean
  onEditClick: (id: number) => void
  onRecalculateClick: (tournamentId: number) => void
}

const ViewingStandingsControls = ({
  standingsName,
  tournamentStandings,
  isSaving,
  onEditClick,
  onRecalculateClick,
}: Props) => {
  const { colors } = useTheme()

  const [isOpenRecalculateDialog, setIsOpenRecalculateDialog] = React.useState<boolean>(false)
  const openRecalculateDialog = () => {
    setIsOpenRecalculateDialog(true)
  }
  const closeRecalculateDialog = () => setIsOpenRecalculateDialog(false)

  // When we open recalculating dialog, we need to close Recalculate confirmation dialog
  // and send recalculating standings request. After getting response we are closing recalculating dialog.
  const openRecalculatingDialog = (tournamentId: number) => {
    closeRecalculateDialog()
    onRecalculateClick(tournamentId)
    logFirebaseEvent(FirebaseEventName.RecalculateStandings, { id: tournamentStandings?.tournament?.id })
  }

  const onStandingsEditClick = () => {
    onEditClick(tournamentStandings.id)
  }

  return (
    <>
      <StandingsControls standingsName={standingsName}>
        <ControlButton onClick={openRecalculateDialog} isSaving={isSaving}>
          <IconRecalculate width={24} height={24} fill={colors.onSurface.nLv1} />

          <Text ml="sm" font="assistive.default" letterSpacing="-0.024px">
            <FormattedMessage id="recalculate" />
          </Text>
        </ControlButton>

        <ControlButton onClick={onStandingsEditClick}>
          <IconEdit width={24} height={24} fill={colors.onSurface.nLv1} />

          <Text ml="sm" font="assistive.default" letterSpacing="-0.024px">
            <FormattedMessage id="edit" />
          </Text>
        </ControlButton>
      </StandingsControls>

      <RecalculateConfirmationDialog
        isOpen={isOpenRecalculateDialog}
        handleClose={closeRecalculateDialog}
        proceed={openRecalculatingDialog}
        tournamentId={tournamentStandings.tournament.id}
      />
    </>
  )
}

export default ViewingStandingsControls
