import { secondaryColor, textLightColor, textPrimary } from 'styles/color'

export const getScoreColor = (isLive: boolean, side: 'home' | 'away', winningSide: '1' | '2' | 'X') => {
  if (isLive) {
    return secondaryColor
  } else {
    if ((side === 'home' && winningSide === '1') || (side === 'away' && winningSide === '2')) {
      return textPrimary
    } else {
      return textLightColor
    }
  }
}

/**
 * Utility function for determining the winning side using the provided homeScore and awayScore.
 * Returns `1` if winning side is home team, `2` if winning side is away team or `X` the current
 * result is draw. Returned values are used for result coloring.
 */
export const getWinningSide = (homeScore?: number, awayScore?: number) => {
  if (homeScore === undefined || awayScore === undefined) {
    return 'X'
  }

  return homeScore > awayScore ? '1' : homeScore === awayScore ? 'X' : '2'
}
