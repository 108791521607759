import { Text } from '@sofascore/ui'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'
import { FirebaseEventName, FirebaseEventType } from 'shared/lib/firebase/model'

import ResponsivePopUp from 'components/ResponsivePopUp'

import { AddNewTeamForm } from './AddNewTeamForm'
import { AddTeamProps } from '../model'

export const AddNewTeam = ({ children, tournamentId, onSave }: AddTeamProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const firebaseLogPayload = { type: FirebaseEventType.NewTeam }
  const responsivePopupOpen = () => {
    setIsOpen(true)
    logFirebaseEvent(FirebaseEventName.AddEntity, firebaseLogPayload)
  }
  const responsivePopupClose = (discarded?: boolean | undefined) => {
    setIsOpen(false)
    if (discarded) logFirebaseEvent(FirebaseEventName.DiscardEntity, firebaseLogPayload)
    else logFirebaseEvent(FirebaseEventName.SubmitEntity, firebaseLogPayload)
  }

  return (
    <>
      {children(isOpen, responsivePopupOpen)}

      <ResponsivePopUp
        isOpen={isOpen}
        onClose={responsivePopupClose}
        heading={
          <Text font="display.large" ml="sm">
            <FormattedMessage id="add_new_team" />
          </Text>
        }
      >
        <AddNewTeamForm tournamentId={tournamentId} onSave={onSave} onClose={responsivePopupClose} />
      </ResponsivePopUp>
    </>
  )
}
