import { Box, Flex, Text } from '@sofascore/ui'
import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { IncidentPenaltyShootoutType } from 'entities/Incident'
import { useEventState } from 'entities/Event'
import {
  useTeamPlayerOptions,
  PlayerOption,
  getDefaultOption,
  renderPlayerOption,
  renderSelectedPlayerOption,
} from 'entities/Player'
import { IconError, IconSuccess } from 'shared/ui'

import IncidentBox from 'components/IncidentBox'
import { Button } from 'components/Button'
import { Select } from 'components/Select'

import { EditIncidentFormProps } from '../../model'

export const EditRugbyLastShootoutPenaltyForm = ({ onSubmit, onClose, incident }: EditIncidentFormProps) => {
  const [isSaving, setIsSaving] = useState(false)

  const { event } = useEventState()
  const intl = useIntl()
  const { id: eventId, homeTeam, awayTeam } = event
  const {
    id,
    incidentType,
    isHome,
    player: initialPlayer,
    incidentClass: initialIncidentClass,
    homeScore: initialHomeScore,
    awayScore: initialAwayScore,
    time,
    addedTime,
  } = incident
  const teamId = isHome ? homeTeam.id : awayTeam.id
  const initialScored = initialIncidentClass === IncidentPenaltyShootoutType.Scored ? 1 : 0

  const [scorer, setScorer] = useState<PlayerOption | undefined>(getDefaultOption(initialPlayer))
  const [scored, setScored] = useState<number>(initialScored)
  const [scorerOptions] = useTeamPlayerOptions(teamId, scorer)

  const handleConfirm = () => {
    setIsSaving(true)

    let updateScore = false
    let _homeScore = initialHomeScore || 0
    let _awayScore = initialAwayScore || 0

    if (scored !== initialScored) {
      updateScore = true

      if (scored === 1 && isHome) {
        _homeScore = _homeScore + 1
      }
      if (scored === 1 && !isHome) {
        _awayScore = _awayScore + 1
      }
      if (scored === 0 && isHome) {
        _homeScore = _homeScore - 1
      }
      if (scored === 0 && !isHome) {
        _awayScore = _awayScore - 1
      }
    }

    onSubmit({
      eventId: eventId,
      incidentId: id as number,
      updateScore,
      incidentData: {
        incidentType,
        incidentClass: scored === 1 ? IncidentPenaltyShootoutType.Scored : IncidentPenaltyShootoutType.Missed,
        isHome: isHome as boolean,
        time: time,
        addedTime: addedTime,
        homeScore: _homeScore,
        awayScore: _awayScore,
        player: scorer ? scorer.value : undefined,
        score: scored,
        sequence: incident.sequence,
      },
    })
      .then(() => onClose())
      .finally(() => setIsSaving(false))
  }

  useEffect(() => {
    setScorer(scorerOptions.find(s => s.value.id === initialPlayer?.id))
  }, [initialPlayer, scorerOptions])

  return (
    <Box px="sm" pb="xxl">
      <Box px="sm" pt="lg" pb="xs" br="sm" bg="surface.s2">
        <Flex mb="xl" justify="center">
          <Text font="display.small">
            <FormattedMessage id="select_outcome_penalty_taker" />
          </Text>
        </Flex>

        <Flex mb="lg" px="xxl" justify="center">
          <IncidentBox
            label={intl.formatMessage({ id: 'scored' })}
            isActive={scored === 1}
            onClick={() => {
              setScored(1)
            }}
            icon={<IconSuccess />}
            mx="sm"
          />

          <IncidentBox
            label={intl.formatMessage({ id: 'missed' })}
            isActive={scored === 0}
            onClick={() => {
              setScored(0)
            }}
            icon={<IconError />}
            mx="sm"
          />
        </Flex>

        <Select
          label={intl.formatMessage({ id: 'player' })}
          options={scorerOptions}
          helperText="Optional"
          value={scorer}
          onChange={setScorer}
          maxVisibleOptions={6}
          w="100%"
          selectBackgroundColor="surface.s1"
          renderOption={renderPlayerOption}
          renderSelectedOption={renderSelectedPlayerOption}
        />
      </Box>

      <Flex gapX="lg" px="lg" mt="xxl">
        <Button variant="outlined" fullWidth onClick={onClose}>
          <FormattedMessage id="cancel" />
        </Button>
        <Button variant="contained" fullWidth onClick={handleConfirm} isLoading={isSaving}>
          <FormattedMessage id="confirm" />
        </Button>
      </Flex>
    </Box>
  )
}
