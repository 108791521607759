import noop from 'lodash/noop'
import { FormattedMessage } from 'react-intl'
import { Box, Flex, Text } from '@sofascore/ui'
import { PlayerCard } from 'entities/Player'
import {
  mapFootballPositionToGroupKey,
  DynamicLineupsPlayer,
  groupPlayersByPosition,
  sortLineupPlayers,
} from 'features/AddLineups'

import ResponsivePopUp from 'components/ResponsivePopUp'

export const MobileAddPlayerDialog = ({
  open,
  lineupPlayers,
  selectedPosition,
  onAddPlayer,
  onClose,
}: {
  open: boolean
  lineupPlayers: DynamicLineupsPlayer[]
  selectedPosition: number
  onAddPlayer: (lineupPlayer: DynamicLineupsPlayer, position: number) => void
  onClose: () => void
}) => {
  // In mobile add players dialog, we also show missing players
  const displayedPlayers = lineupPlayers.filter(
    lp => !lp.substitute && lp.position === null && lp.missingReason === null,
  )
  const groupedDisplayedPlayers = groupPlayersByPosition(displayedPlayers)

  const handleAddPlayer = (player: DynamicLineupsPlayer) => {
    onAddPlayer(player, selectedPosition)
    onClose()
  }

  return (
    <ResponsivePopUp
      isOpen={open}
      onClose={onClose}
      heading={
        <Text font="display.large">
          <FormattedMessage id="editor_add_player" />
        </Text>
      }
    >
      <Flex display="flex" direction="column" w="100%">
        {Object.keys(mapFootballPositionToGroupKey).map(position => {
          return (
            <Box key={position}>
              <Box px="lg" pt="lg" pb="sm">
                <Text font="assistive.default">
                  <FormattedMessage id={mapFootballPositionToGroupKey[position]} />
                </Text>
              </Box>
              <Flex direction="column" gapY="sm">
                {groupedDisplayedPlayers[position] &&
                  sortLineupPlayers(groupedDisplayedPlayers[position]).map((lineupPlayer: DynamicLineupsPlayer) => (
                    <Box
                      role="button"
                      key={lineupPlayer.player.id}
                      py="sm"
                      px="lg"
                      cursor="pointer"
                      hoverBg="surface.s2"
                      onClick={lineupPlayer.missingReason !== null ? noop : () => handleAddPlayer(lineupPlayer)}
                    >
                      <PlayerCard player={lineupPlayer.player} />
                    </Box>
                  ))}
              </Flex>
            </Box>
          )
        })}
      </Flex>
    </ResponsivePopUp>
  )
}
