import { BasicTournament } from 'entities/Tournament'
import { StatusCode } from 'entities/Status'

import { TENNIS_SINGLE } from 'modules/Matches/const'

import { CreateDoublesEventData, CreateEventData, CreateMatchState } from './interface'

// Getting initial state for Create match form
export const getInitialState = (): CreateMatchState => ({
  selectedTournament: undefined,
  selectedDate: undefined,
  selectedTime: undefined,
  selectedRound: undefined,
  matchType: TENNIS_SINGLE,
  homeTeam: undefined,
  awayTeam: undefined,
  homeSubTeam1: undefined,
  homeSubTeam2: undefined,
  awaySubTeam1: undefined,
  awaySubTeam2: undefined,
  matchEnded: false,
  homeTeamScore: undefined,
  awayTeamScore: undefined,
  isValid: false,
})

export const createEventDto = (
  selectedTournament: BasicTournament,
  seasonId: number,
  state: Omit<CreateMatchState, 'isValid'>,
): CreateEventData => {
  const { selectedDate, selectedTime, selectedRound, homeTeam, awayTeam, homeTeamScore, awayTeamScore, matchEnded } =
    state
  selectedDate!.setHours(selectedTime!.getHours(), selectedTime!.getMinutes(), 0, 0)

  const startDateTimestamp = Math.round(selectedDate!.getTime() / 1000)

  const dataToSend = {
    tournamentId: Number(selectedTournament.id),
    seasonId,
    startDateTimestamp: startDateTimestamp,
    roundInfo: selectedRound!,
    homeTeamId: Number(homeTeam?.value),
    awayTeamId: Number(awayTeam?.value),
  }

  if (Number(homeTeamScore) >= 0 && Number(awayTeamScore) >= 0 && matchEnded) {
    dataToSend['postMatchData'] = {
      statusCode: StatusCode.Ended,
      homeScore: { current: homeTeamScore },
      awayScore: { current: awayTeamScore },
    }
  }

  return dataToSend
}

export const createDoublesEventDto = (
  selectedTournament: BasicTournament,
  seasonId: number,
  state: Omit<CreateMatchState, 'isValid'>,
): CreateDoublesEventData => {
  const {
    selectedDate,
    selectedTime,
    selectedRound,
    homeSubTeam1,
    homeSubTeam2,
    awaySubTeam1,
    awaySubTeam2,
    homeTeamScore,
    awayTeamScore,
    matchEnded,
  } = state
  selectedDate!.setHours(selectedTime!.getHours(), selectedTime!.getMinutes(), 0, 0)

  const startDateTimestamp = Math.round(selectedDate!.getTime() / 1000)

  const dataToSend = {
    tournamentId: Number(selectedTournament.id),
    seasonId,
    startDateTimestamp: startDateTimestamp,
    roundInfo: selectedRound!,
    homeSubTeam1: Number(homeSubTeam1?.value),
    homeSubTeam2: Number(homeSubTeam2?.value),
    awaySubTeam1: Number(awaySubTeam1?.value),
    awaySubTeam2: Number(awaySubTeam2?.value),
  }

  if (Number(homeTeamScore) >= 0 && Number(awayTeamScore) >= 0 && matchEnded) {
    dataToSend['postMatchData'] = {
      statusCode: StatusCode.Ended,
      homeScore: { current: homeTeamScore },
      awayScore: { current: awayTeamScore },
    }
  }

  return dataToSend
}
