import { Flex } from '@sofascore/ui'
import { PropsWithChildren } from 'react'

interface IncidentHeadProps {
  isHome: boolean | undefined
}

export const IncidentHead = ({ children, isHome }: PropsWithChildren<IncidentHeadProps>) => {
  return (
    <Flex
      order={isHome ? '1' : '3'}
      borderRight={isHome ? '1px solid onSurface.nLv4' : undefined}
      borderLeft={!isHome ? '1px solid onSurface.nLv4' : undefined}
      direction="column"
      align="center"
      justify="center"
      pl={isHome ? 'sm' : 'md'}
      pr="md"
      mr={isHome ? 'sm' : 0}
      ml={isHome ? 0 : 'sm'}
    >
      {children}
    </Flex>
  )
}
