import { useIntl } from 'react-intl'
import { MissingPlayerReason } from 'entities/Player'
import { Option } from 'shared/model'

import { Select } from 'components/Select'
import { backgrounds } from 'styles/color'

export const MissingReasonSelector = ({
  reason,
  setReason,
  options,
}: {
  reason: MissingPlayerReason
  setReason: (value: MissingPlayerReason) => void
  options: Option[]
}) => {
  const intl = useIntl()
  const selectedReason = options.find(k => k.value === reason)!

  return (
    <Select
      value={selectedReason}
      onChange={option => setReason(option.value as MissingPlayerReason)}
      options={options}
      label={intl.formatMessage({ id: 'reason' })}
      selectBackgroundColor={backgrounds.chip}
      disableClear
    />
  )
}
