import { BasicEvent } from 'entities/Event'

export enum ActionType {
  REGISTER_REVALIDATED_EVENT = 'REGISTER_REVALIDATED_EVENT',
  UNREGISTER_REVALIDATED_EVENT = 'REMOVE_REVALIDATED_EVENT',
  REMOVE_ALL_EVENTS = 'REMOVE_ALL_EVENTS',
}

export interface ActionPayload {
  revalidatedEvent?: BasicEvent
  eventId?: number
}

export const registerRevalidatedEvent = (revalidatedEvent: BasicEvent) => {
  return {
    type: ActionType.REGISTER_REVALIDATED_EVENT,
    payload: {
      revalidatedEvent,
    },
  }
}

export const unregisterRevalitedEvent = (eventId: number) => {
  return {
    type: ActionType.UNREGISTER_REVALIDATED_EVENT,
    payload: {
      eventId,
    },
  }
}

export const removeAllEvents = () => {
  return {
    type: ActionType.REMOVE_ALL_EVENTS,
  }
}
