import { Gender, MainHand } from 'entities/Team'

export const genderStringMap = {
  [Gender.Male]: 'gender_male',
  [Gender.Female]: 'gender_female',
  [Gender.Mixed]: 'gender_mixed',
}

export const mainHandStringMap = {
  [MainHand.Right]: 'hand_plays_right',
  [MainHand.Left]: 'hand_plays_left',
  [MainHand.Ambidextrous]: 'hand_plays_ambidextrous',
}

/**
 * 1 - 999
 * 1K - 999.9K
 * 1M - inf
 *
 * Rounded down to 1 decimal.
 *
 * Format configuration can be modified.
 *
 * @param {string | value} value
 * @returns
 */

// This is default behaviour on Core and Editor
const numberFormatConfig = {
  limits: [1e3, 1e6], // limits for abbreviations sorted ascendingly
  suffixes: ['K', 'M'], // abbreviation suffixes
  precision: 1, // number of decimal places; zeroes after decimal are stripped
}

interface CustomConfig {
  limits?: number[]
  suffixes?: string[]
  precision?: number
}

export const formatNumber = (value: number, customConfig?: CustomConfig): string => {
  const config = {
    ...numberFormatConfig,
    ...customConfig,
  }

  let formattedValue = value
  let suffix = ''
  config.limits.forEach((limit, i) => {
    if (Math.abs(value / limit) >= 1) {
      formattedValue = (value - (value % (limit / 10))) / limit
      suffix = config.suffixes[i]
    }
  })

  // strip zeroes after decimal with parseFloat
  return parseFloat(formattedValue.toFixed(config.precision)) + suffix
}
