import React, { createContext, Dispatch, useContext, useMemo, useReducer } from 'react'
import { AnyAction } from 'redux'
import { CompetitionType } from 'entities/Tournament/model'

import { CreateMatchState } from '../interface'
import { getInitialState } from '../helper'
import { MAX_ROUND, MIN_ROUND, TENNIS_DOUBLE } from '../../const'

const CreateMatchStateContext = createContext<CreateMatchState | null>(null)
const CreateMatchDispatchContext = createContext<Dispatch<AnyAction> | null>(null)

export const SET_TOURNAMENT = 'SET_TOURNAMENT'

export const SET_HOME_TEAM = 'SET_HOME_TEAM'
export const SET_AWAY_TEAM = 'SET_AWAY_TEAM'

// Sub teams are used for tennis doubles events
export const SET_MATCH_TYPE = 'SET_MATCH_TYPE'
export const SET_HOME_SUB_TEAM1 = 'SET_HOME_SUB_TEAM1'
export const SET_HOME_SUB_TEAM2 = 'SET_HOME_SUB_TEAM2'
export const SET_AWAY_SUB_TEAM1 = 'SET_AWAY_SUB_TEAM1'
export const SET_AWAY_SUB_TEAM2 = 'SET_AWAY_SUB_TEAM2'
export const RESET_TEAMS = 'RESET_TEAMS'

export const SET_DATE = 'SET_DATE'
export const SET_TIME = 'SET_TIME'
export const SET_ROUND = 'SET_ROUND'
export const SET_IS_VALID = 'SET_IS_VALID'

export const SET_MATCH_ENDED = 'SET_MATCH_ENDED'
export const SET_HOME_TEAM_SCORE = 'SET_HOME_TEAM_SCORE'
export const SET_AWAY_TEAM_SCORE = 'SET_AWAY_TEAM_SCORE'

export const reducer = (state: CreateMatchState, action: AnyAction): CreateMatchState => {
  switch (action.type) {
    case SET_TOURNAMENT:
      return {
        ...state,
        selectedTournament: action.payload,
      }
    case SET_DATE:
      return {
        ...state,
        selectedDate: action.payload,
      }
    case SET_TIME:
      return {
        ...state,
        selectedTime: action.payload,
      }
    case SET_ROUND:
      return {
        ...state,
        selectedRound: action.payload,
      }
    case SET_MATCH_TYPE: {
      return {
        ...state,
        matchType: action.payload,
      }
    }
    case SET_HOME_TEAM:
      return {
        ...state,
        homeTeam: action.payload,
      }
    case SET_AWAY_TEAM:
      return {
        ...state,
        awayTeam: action.payload,
      }
    case SET_HOME_SUB_TEAM1:
      return {
        ...state,
        homeSubTeam1: action.payload,
      }
    case SET_HOME_SUB_TEAM2:
      return {
        ...state,
        homeSubTeam2: action.payload,
      }
    case SET_AWAY_SUB_TEAM1:
      return {
        ...state,
        awaySubTeam1: action.payload,
      }
    case SET_AWAY_SUB_TEAM2:
      return {
        ...state,
        awaySubTeam2: action.payload,
      }
    case SET_HOME_TEAM_SCORE:
      return {
        ...state,
        homeTeamScore: action.payload,
      }
    case SET_AWAY_TEAM_SCORE:
      return {
        ...state,
        awayTeamScore: action.payload,
      }
    case SET_MATCH_ENDED:
      return {
        ...state,
        matchEnded: action.payload,
      }
    case SET_IS_VALID:
    case RESET_TEAMS:
      return {
        ...state,
        homeTeam: undefined,
        awayTeam: undefined,
        homeSubTeam1: undefined,
        homeSubTeam2: undefined,
        awaySubTeam1: undefined,
        awaySubTeam2: undefined,
        matchEnded: false,
        homeTeamScore: undefined,
        awayTeamScore: undefined,
      }
    default:
      return state
  }
}

export const CreateMatchProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, getInitialState())

  const isValid = useMemo(() => {
    const {
      selectedRound,
      selectedDate,
      selectedTime,
      matchType,
      homeTeam,
      awayTeam,
      homeSubTeam1,
      homeSubTeam2,
      awaySubTeam1,
      awaySubTeam2,
      matchEnded,
      homeTeamScore,
      awayTeamScore,
    } = state

    const matchHasEnded = matchEnded ? Number(homeTeamScore) >= 0 && Number(awayTeamScore) >= 0 : true
    const isDoubles = matchType === TENNIS_DOUBLE
    const teams = isDoubles ? homeSubTeam1 && homeSubTeam2 && awaySubTeam1 && awaySubTeam2 : homeTeam && awayTeam
    const rounds =
      CompetitionType['Cup'] === state.selectedTournament?.competitionType
        ? selectedRound && typeof selectedRound.round === 'number'
        : selectedRound &&
          selectedRound.round >= MIN_ROUND &&
          selectedRound.round <= MAX_ROUND &&
          typeof selectedRound.round === 'number'
    return !!state.selectedTournament && !!teams && !!rounds && !!selectedDate && !!selectedTime && matchHasEnded
  }, [state])

  return (
    <CreateMatchStateContext.Provider value={{ ...state, isValid }}>
      <CreateMatchDispatchContext.Provider value={dispatch}>{children}</CreateMatchDispatchContext.Provider>
    </CreateMatchStateContext.Provider>
  )
}

export const useCreateMatchState = (): CreateMatchState => {
  const context = useContext(CreateMatchStateContext)

  if (!context) {
    throw new Error('useCreateMatchState must be used within a CreateMatchProvider')
  }

  return context
}

export const useCreateMatchDispatch = () => {
  const context = useContext(CreateMatchDispatchContext)

  if (!context) {
    throw new Error('useCreateMatchDispatch must be used within a CreateMatchProvider')
  }

  return context
}
