import { useState } from 'react'
import { Flex, Text, Button } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'
import { FirebaseEventName, FirebaseEventType } from 'shared/lib/firebase/model'

import { useMyCompetitionsState } from 'modules/MyCompetitions/providers/MyCompetitionsProvider'
import ResponsivePopUp from 'components/ResponsivePopUp'

import { CreateNewSeasonForm } from './CreateNewSeasonForm'

const NewSeason = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { uniqueTournament } = useMyCompetitionsState()
  const sportName = uniqueTournament?.category.sport.name

  const openPopup = () => {
    setIsOpen(true)
    logFirebaseEvent(FirebaseEventName.AddEntity, {
      type: FirebaseEventType.Season,
    })
  }

  const responsivePopupClose = (discarded?: boolean | undefined) => {
    setIsOpen(false)
    if (discarded) {
      logFirebaseEvent(FirebaseEventName.DiscardEntity, {
        type: FirebaseEventType.Season,
        sport: sportName?.toLocaleLowerCase(),
      })
    } else {
      logFirebaseEvent(FirebaseEventName.SubmitEntity, {
        type: FirebaseEventType.Season,
        sport: sportName?.toLocaleLowerCase(),
      })
    }
  }

  return (
    <>
      <Flex direction="column" mt="sm" w="100%" p="lg" br="sm" bg="surface.s1">
        <Button onClick={openPopup} style={{ textTransform: 'none' }}>
          <FormattedMessage id="createNewSeason" />
        </Button>
      </Flex>
      <ResponsivePopUp
        isOpen={isOpen}
        onClose={responsivePopupClose}
        heading={
          <Text font="display.large">
            <FormattedMessage id="createNewSeason" />
          </Text>
        }
        overflowY="auto"
      >
        <CreateNewSeasonForm onClose={responsivePopupClose} />
      </ResponsivePopUp>
    </>
  )
}

export default NewSeason
