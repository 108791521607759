import { takeEvery, select, put, call } from 'redux-saga/effects'
import { v4 as uuidv4 } from 'uuid'
import { logFirebaseEvent, saveUserIDToFirebase } from 'shared/lib/firebase/utils'
import { FirebaseEventName } from 'shared/lib/firebase/model'
import { parseJwt } from 'features/Auth'

import { fetchRefreshedToken, fetchToken, postUserLogin, postUserLogout } from 'api'
import { getAuthFromState } from 'store/selectors'
import { facebookInitParams, facebookOptions, facebookURL } from 'config/facebook'
import { credentialManagementParams } from 'config/credentialManagement'
import { getErrorCode } from 'utils'
import * as A from 'store/auth/actions'

import { socialLoginPromises, hasCredentials } from './helper'
import * as I from '../model'
import { OnLoginService } from './onLoginService'

/**
 * Returns user token.
 * Can be started via `GET_TOKEN` action, but main use case will be starting this saga from another saga.
 * If token is present in state then returns that token, else new token is fetched from API and stored.
 * This saga is responsible for token init.
 * Saga could throw error, which must be handled in parent saga.
 */
export function* getTokenSaga() {
  // get token and uuid from state
  const { token: reduxToken, uuid: reduxUuid } = yield select(getAuthFromState)

  // if token exists, init is finished, so return token
  if (reduxToken) {
    return reduxToken
  }

  // generate unique device id only if no device id is stored
  const deviceUuid = reduxUuid || uuidv4()

  // fetch token from API
  const { token: receivedToken } = yield call(fetchToken, deviceUuid)
  yield put(A.tokenFetchSuccessful(receivedToken, deviceUuid))

  return receivedToken
}

/**
 * Refreshes user token, and handles token preservation.
 */
export function* refreshTokenSaga() {
  try {
    const token: string = yield call(getTokenSaga)

    const { token: receivedToken } = yield call(fetchRefreshedToken, token)

    yield put(A.tokenRefreshSuccessful(receivedToken))
  } catch (e) {
    yield call(handleError, e as Error)
  }
}

/**
 * Handles login flow.
 * 1. Gets token from `getTokenSaga`
 * 2. Sends fetch request to an API
 * 3. On success dispatches success action and on error dispatches error action
 */
export function* loginSaga(action: I.LoginAction) {
  const payload = action.payload

  try {
    const token: string = yield getTokenSaga()

    const { shouldRefreshToken, userAccount } = yield call(postUserLogin, payload, token)

    if (shouldRefreshToken) {
      yield call(refreshTokenSaga)
    }

    // yield call(writeToStorage, USER_DATA_STORAGE_KEY, { userData: userAccount })

    saveUserIDToFirebase(userAccount.id)
    yield put(A.loginSuccessful(userAccount))

    // mark successful login for OnLoginService
    yield call(OnLoginService.loginSuccessful)
  } catch (e) {
    yield call(handleError, e as Error)
  }
}
/**
 * Handles logout flow.
 * 1. Gets token from `getTokenSaga`
 * 2. Sends fetch request to an API
 * 3. On success dispatches success action and on error dispatches error action
 */
export function* logoutSaga() {
  try {
    const token: string = yield getTokenSaga()

    const { shouldRefreshToken } = yield call(postUserLogout, token)

    if (shouldRefreshToken) {
      yield call(refreshTokenSaga)
    }

    // Turning off credentials management's auto sign-in for future visits
    try {
      yield call([navigator.credentials, navigator.credentials.preventSilentAccess])
    } catch (error) {
      console.error(error)
    }

    yield put(A.logoutSuccessful({}))
  } catch (e) {
    yield call(handleError, e as Error)
  }
}

function* handleError(e: Error) {
  const errorCode = getErrorCode(e)

  if (errorCode === 401) {
    yield put(A.unauthorizedError(e))
  } else {
    yield put(A.unprocessableError(e))
  }
}

/**
 * Saga for handling google login process
 * 1. Gets data from google API
 * 2. Calls login saga with provided token, email and login type
 * 3. Sets provider data
 * 4. Makes redirection to user's profile
 */
export function* googleLoginSaga(action: I.SocialLoginAction) {
  try {
    const jwt = action.payload.jwt!
    const data = parseJwt(jwt)
    const loginRequestPayload: I.LoginPayload = {
      type: 'google' as I.LoginProviders,
      accessToken: jwt,
      email: data.email,
    }
    yield call(loginSaga, { payload: loginRequestPayload, type: A.LOGIN_REQUEST })

    const { error } = yield select(getAuthFromState)

    if (!error) {
      const providerData: I.ProviderData = {
        id: data.email,
        email: data.email,
        image: data.picture || null,
        name: data.name,
        type: 'google' as I.LoginProviders,
        data: {
          accessToken: loginRequestPayload.accessToken,
        },
      }

      yield put(A.setProviderData(providerData))
      logFirebaseEvent(FirebaseEventName.SignIn, { method: 'google' })

      action.payload.navigate('/home')
    }
  } catch (e) {
    yield call(handleError, e as Error)
  }
}

/**
 * Saga for handling facebook login process
 * 1. Gets login data from facebook API
 * 2. Calls login saga with provided token, email and login type
 * 3. Sets provider data
 * 4. Save credentials for Credentials management
 * 5. Makes redirection to user's profile
 */
export function* facebookLoginSaga(action: I.SocialLoginAction) {
  try {
    if (typeof FB === 'undefined') {
      return
    }
    yield call(window.FB.init, facebookInitParams)
    // @ts-ignore
    const authRes = yield call(socialLoginPromises.fbLogin, { scope: facebookOptions.scope })
    // @ts-ignore
    const data = yield call(socialLoginPromises.fbGet, { fields: facebookOptions.fields }, '/me')

    const { navigate } = action.payload

    const loginRequestPayload: I.LoginPayload = {
      type: 'facebook' as I.LoginProviders,
      accessToken: authRes.accessToken,
      email: data.email,
    }
    yield call(loginSaga, { payload: loginRequestPayload, type: A.LOGIN_REQUEST })

    const { error } = yield select(getAuthFromState)

    if (!error) {
      const providerData: I.ProviderData = {
        email: data.email,
        id: data.id,
        image: `https://graph.facebook.com/${data.id}/picture?type=normal`,
        name: data.name,
        type: 'facebook',
        data: {
          accessToken: authRes.accessToken,
        },
      }

      yield put(A.setProviderData(providerData))
      yield put(A.saveCredentials(providerData))

      logFirebaseEvent(FirebaseEventName.SignIn, { method: 'facebook' })

      navigate('/home')
    }
  } catch (e) {
    yield call(handleError, e as Error)
  }
}

/**
 * Saga for handling credential management API when user visits login page
 * 1. Checks if credential management is supported from browser
 * 2. Gets credential information
 * 3. Checks credential type (`federated`)
 * 4. Calls saga according to credential type
 */
export function* credentialsManagementSaga(action: I.CredentialsManagementAction) {
  if (hasCredentials() && navigator && navigator.credentials) {
    try {
      // getting credential information
      // @ts-ignore
      const credentials = yield call([navigator.credentials, navigator.credentials.get], credentialManagementParams)

      if (credentials) {
        const { navigate } = action.payload
        switch (credentials.type) {
          // facebook and google login
          case 'federated' as I.CredentialsTypes:
            if (credentials.provider === facebookURL) {
              yield call(facebookLoginSaga, { type: A.FACEBOOK_LOGIN, payload: { navigate } })
            }
            break
          default:
            return
        }
      }
    } catch (e) {
      yield call(handleError, e as Error)
    }
  }
}

/**
 * Saga for saving credentials data
 */
export function* saveCredentialsSaga(action: I.SaveCredentialsAction) {
  const { providerData } = action.payload
  let cred = null
  if (hasCredentials() && navigator.credentials) {
    cred = new window.FederatedCredential({
      id: providerData.name,
      name: providerData.name,
      iconURL: providerData.image,
      provider: facebookURL,
    })

    yield call([navigator.credentials, navigator.credentials.store], cred)
  }
}

export const authSagas = [
  takeEvery(A.GET_TOKEN, getTokenSaga),
  takeEvery(A.LOGIN_REQUEST, loginSaga),
  takeEvery(A.LOGOUT_REQUEST, logoutSaga),
  takeEvery(A.GOOGLE_LOGIN, googleLoginSaga),
  takeEvery(A.FACEBOOK_LOGIN, facebookLoginSaga),
  takeEvery(A.START_CREDENTIALS_MANAGEMENT_PROCESS, credentialsManagementSaga),
  takeEvery(A.SAVE_CREDENTIALS, saveCredentialsSaga),
]
