import * as React from 'react'
import { connect, useDispatch } from 'react-redux'

import { getDate, isDateValid } from 'utils/time'
import { RootState } from 'store/store'
import StartTimeForm from 'modules/Matches/components/StartTimeForm/StartTimeForm'
import { SET_START_TIMESTAMP } from 'modules/Matches/matchesReducer'
import { backgrounds } from 'styles/color'

interface Props {
  startTimestamp: number | null
}

/**
 * Panel for displaying event's start date and time
 */
const StartTimePanel = ({ startTimestamp }: Props) => {
  const dispatch = useDispatch()

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(startTimestamp ? getDate(startTimestamp) : null)
  const [selectedTime, setSelectedTime] = React.useState<Date | null>(startTimestamp ? getDate(startTimestamp) : null)

  const handleDateChange = React.useCallback(
    (date: Date | null) => {
      if (date && isDateValid(date) && selectedTime && isDateValid(selectedTime)) {
        date.setHours(selectedTime.getHours(), selectedTime.getMinutes(), 0, 0)
        dispatch({
          type: SET_START_TIMESTAMP,
          payload: {
            startTimestamp: Math.round(date.getTime() / 1000),
          },
        })
      } else if (startTimestamp) {
        dispatch({
          type: SET_START_TIMESTAMP,
          payload: {
            startTimestamp: null,
          },
        })
      }

      setSelectedDate(date)
    },
    [dispatch, selectedTime, startTimestamp],
  )

  const handleTimeChange = React.useCallback(
    (time: Date | null) => {
      if (time && isDateValid(time) && selectedDate && isDateValid(selectedDate)) {
        const updatedDate = new Date(selectedDate)
        updatedDate.setHours(time.getHours(), time.getMinutes(), 0, 0)
        dispatch({
          type: SET_START_TIMESTAMP,
          payload: {
            startTimestamp: Math.round(updatedDate.getTime() / 1000),
          },
        })
      } else if (startTimestamp) {
        dispatch({
          type: SET_START_TIMESTAMP,
          payload: {
            startTimestamp: null,
          },
        })
      }

      setSelectedTime(time)
    },
    [dispatch, selectedDate, startTimestamp],
  )

  return (
    <StartTimeForm
      selectedDate={selectedDate}
      selectedTime={selectedTime}
      handleDateChange={handleDateChange}
      handleTimeChange={handleTimeChange}
      style={{ backgroundColor: backgrounds.content }}
    />
  )
}

const mapStateToProps = ({ event }: RootState): Props => ({
  startTimestamp: event ? event.startTimestamp : null,
})

export default connect<Props, null, unknown, RootState>(mapStateToProps)(StartTimePanel)
