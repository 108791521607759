import { noPeriodsSports } from 'entities/Sport'
import { StatusType, isSpecialStatus } from 'entities/Status'
import { BasicEvent } from 'entities/Event'
import { Flex, Text, useMediaContext } from '@sofascore/ui'

import { Period, PeriodScoresProps } from './interface'

const PeriodScores = ({ event }: PeriodScoresProps) => {
  const { isMobile } = useMediaContext()

  // Don't display on mobile resolutions
  if (isMobile) return null

  const { tournament } = event
  const sport = tournament.category.sport.slug

  // Don't display for certain sports
  if (noPeriodsSports.includes(sport)) return null

  const periods = getFullPeriods(event)

  const { lastPeriod } = event
  const isLocked = event.feedLocked

  if (!periods.length) return null

  return (
    <Flex mr="lg" style={{ opacity: isLocked ? '.5' : 1 }}>
      {periods.map(period => {
        const { periodKey, home, away, homeTieBreak, awayTieBreak } = period

        const hideTieBreaks = !homeTieBreak && !awayTieBreak
        const isHomeWin = home > away
        const isAwayWin = away > home

        return (
          <Flex key={periodKey} w={24} direction="column">
            <Flex h="50%">
              <Text
                w="100%"
                align="right"
                color={lastPeriod === periodKey ? 'sofaSingles.live' : isHomeWin ? 'onSurface.nLv1' : 'onSurface.nLv3'}
              >
                {home}
              </Text>
              {!hideTieBreaks && (
                <Text fontSize="small">
                  <sup>{homeTieBreak}</sup>
                </Text>
              )}
            </Flex>
            <Flex h="50%" align="flex-end">
              <Text
                w="100%"
                align="right"
                color={lastPeriod === periodKey ? 'sofaSingles.live' : isAwayWin ? 'onSurface.nLv1' : 'onSurface.nLv3'}
              >
                {away}
              </Text>
              {!hideTieBreaks && (
                <Text fontSize="small">
                  <sup>{awayTieBreak}</sup>
                </Text>
              )}
            </Flex>
          </Flex>
        )
      })}
    </Flex>
  )
}

const getFullPeriods = (event: BasicEvent): Period[] => {
  const notStarted = event.status.type === StatusType.NOT_STARTED
  const hasSpecialStatus = isSpecialStatus(event.status.code)

  if (notStarted || hasSpecialStatus) {
    return []
  }

  const periods: Period[] = []

  Object.keys(event.homeScore)
    .filter(key => key.includes('period') || key.includes('overtime'))
    .forEach(periodKey => {
      const home = event.homeScore[periodKey]
      const away = event.awayScore[periodKey]
      const tieBreakKey = `${periodKey}TieBreak`
      const homeTieBreak = event.homeScore[tieBreakKey]
      const awayTieBreak = event.awayScore[tieBreakKey]

      periods.push({ periodKey, home, away, homeTieBreak, awayTieBreak })
    })

  return periods
}

export default PeriodScores
