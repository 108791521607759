import { CupRound } from 'entities/Tournament'

const cupRounds = [
  { name: 'Qualifications', value: CupRound.QUALIFICATIONS },
  { name: 'Round of 256', value: CupRound.FINAL_256 },
  { name: 'Round of 128', value: CupRound.FINAL_128 },
  { name: 'Round of 64', value: CupRound.FINAL_SIXTY_FOUR },
  { name: 'Round of 32', value: CupRound.FINAL_THIRTY_TWO },
  { name: 'Round of 16', value: CupRound.FINAL_SIXTEEN },
  { name: 'Quarterfinals', value: CupRound.QUARTERFINAL },
  { name: 'Semifinals', value: CupRound.SEMIFINAL },
  // 3rd place cannot be the value of firstCupRound, so it only matters that its value is
  // between semifinal and final round values (1 & 2) in order to display it correctly in the
  // round selector
  { name: 'Match for 3rd place', value: 1.5 },
  { name: 'Final', value: CupRound.FINAL },
]

export const getTournamentCupRounds = (firstCupRound?: CupRound) => {
  const rounds = firstCupRound ? cupRounds.filter(round => round.value <= firstCupRound) : cupRounds
  return rounds.map(round => round.name)
}
