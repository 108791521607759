import { facebookURL } from './facebook'

type CredentialMediationRequirement = 'silent' | 'optional' | 'required'

const mediation: CredentialMediationRequirement = 'optional'

export const credentialManagementParams = {
  password: true,
  federated: {
    providers: [facebookURL],
  },
  mediation: mediation,
}
