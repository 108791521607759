import { Flex } from '@sofascore/ui'
import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Chip, PermissionGuard } from 'shared/ui'
import { PermissionAction, PermissionScope } from 'entities/Permission'
import { usePermissions } from 'shared/hooks'

import { MyCompetitionPendingCupTeams } from './MyCompetitionPendingCupTeams'
import { EntityFilter } from '../model'
import { mapFilterToTranslation } from '../mappings'
import { MyCompetitionApprovedCupTeams } from './MyCompetitionApprovedCupTeams'
import { MyCompetitionDeclinedCupTeams } from './MyCompetitionDeclinedCupTeams'

export const MyCompetitionCupTeams = () => {
  const [filter, setFilter] = useState(EntityFilter.APPROVED)
  const intl = useIntl()
  const { guardScope } = usePermissions()
  const canSuggestTeamPermissions = guardScope([PermissionScope.Team], [PermissionAction.Create])

  const filterChips = useMemo(
    () =>
      Object.keys(mapFilterToTranslation).filter((key: string) => {
        if (!canSuggestTeamPermissions) return key === EntityFilter.APPROVED
        return true
      }),
    [canSuggestTeamPermissions],
  )

  return (
    <>
      <Flex align="center" gapX="sm">
        {filterChips.map(f => (
          <Chip
            key={f}
            value={intl.formatMessage({ id: mapFilterToTranslation[f] })}
            isActive={f === filter}
            onClick={() => setFilter(f as EntityFilter)}
          />
        ))}
      </Flex>

      {filter === EntityFilter.APPROVED && <MyCompetitionApprovedCupTeams />}
      <PermissionGuard entityScopes={[PermissionScope.Team]} checkAllowedActions={[PermissionAction.Create]}>
        {filter === EntityFilter.PENDING && <MyCompetitionPendingCupTeams />}
        {filter === EntityFilter.DECLINED && <MyCompetitionDeclinedCupTeams />}
      </PermissionGuard>
    </>
  )
}
