import useSWR from 'swr'
import { MatchesTabTitle, useEventState } from 'entities/Event'
import { SportName } from 'entities/Sport'
import { EventIncidentsProvider, IncidentsResponse } from 'entities/Incident'
import { Flex, Spinner } from '@sofascore/ui'
import { EventPlayersProvider, useTeamPlayers } from 'entities/Player'
import { useIntl } from 'react-intl'

import { getIncidents } from 'api/routes'
import { newFormSports } from 'modules/Home/config'

import { FootballEditPostMatch } from './Football'
import { MiniFootballEditPostMatch } from './MiniFootball'
import { RugbyEditPostMatch } from './Rugby'
import { BasketballEditPostMatch } from './Basketball'
import NewSportsMatchDetailsForm from './NewSportsMatchDetailsForm'
import GeneralMatchDetailsForm from './GeneralMatchDetailsForm'

const mapSportToForm: Record<string, () => JSX.Element> = {
  [SportName.Football]: FootballEditPostMatch,
  [SportName.ESports]: FootballEditPostMatch,
  [SportName.MiniFootball]: MiniFootballEditPostMatch,
  [SportName.Futsal]: MiniFootballEditPostMatch,
  [SportName.Rugby]: RugbyEditPostMatch,
  [SportName.Basketball]: BasketballEditPostMatch,
}

export const EditPostMatch = () => {
  const { event } = useEventState()
  const { homeTeam, awayTeam, tournament } = event

  const intl = useIntl()

  const { data: incidentsData, isLoading: incidentsLoading } = useSWR<IncidentsResponse>(
    event ? getIncidents(+event.id) : null,
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    },
  )

  const { data: homeTeamData, isLoading: isHomeTeamPlayersLoading } = useTeamPlayers(homeTeam.id)
  const { data: awayTeamData, isLoading: isAwayTeamPlayersLoading } = useTeamPlayers(awayTeam.id)

  if (incidentsLoading || isHomeTeamPlayersLoading || isAwayTeamPlayersLoading) {
    return (
      <Flex w="100%" h="100%" display="flex" align="center" justify="center" direction="column">
        <Spinner color="#374df5" width="50px" />
      </Flex>
    )
  }

  const players = {
    [homeTeam.id]: homeTeamData ? homeTeamData : [],
    [awayTeam.id]: awayTeamData ? awayTeamData : [],
  }

  const sport = tournament.category.sport.slug
  const PostMatchForm = mapSportToForm[sport]

  if (PostMatchForm) {
    return (
      <EventPlayersProvider initialState={players}>
        <EventIncidentsProvider initialState={incidentsData?.incidents}>
          <MatchesTabTitle title={intl.formatMessage({ id: 'post_match_tab_title' })} />
          <PostMatchForm />
        </EventIncidentsProvider>
      </EventPlayersProvider>
    )
  }

  if (newFormSports.includes(sport)) {
    return <NewSportsMatchDetailsForm />
  }

  return <GeneralMatchDetailsForm />
}
