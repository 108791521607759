import { makeStyles, Theme, createStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    teamInfo: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      width: theme.spacing(27),
      [theme.breakpoints.down('sm')]: {
        padding: `0 ${theme.spacing(2)}px`,
        width: theme.spacing(15),
      },
      [theme.breakpoints.up('lg')]: {
        minWidth: theme.spacing(27),
        padding: `0 ${theme.spacing(1.5)}px`,
      },
    },

    teamName: {
      textAlign: 'center',
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 12,
      },
    },

    teamImage: {
      width: 64,
      height: 64,
    },

    teamResult: {
      fontSize: 28,
      fontWeight: 500,
      alignSelf: 'flex-end',
      marginBottom: theme.spacing(1),
    },
  }),
)
