import { Text } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'
import { ReactNode } from 'react'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'
import { useEventState } from 'entities/Event'
import { FirebaseEventName } from 'shared/lib/firebase/model'

import useConfirm from 'modules/ConfirmDialog/useConfirm'

import * as S from './styles'
import { CLEAR_ALL, useLineupsDispatch } from '../../providers'

interface ClearAllProps {
  isHomeTeam: boolean
  message?: ReactNode
}

const defaultClearMessage = <FormattedMessage id="general_clear_team_lineup" />

export const ClearAll = ({ isHomeTeam, message = defaultClearMessage }: ClearAllProps) => {
  const dispatch = useLineupsDispatch()
  const { confirm } = useConfirm()
  const { event } = useEventState()

  const handleClearAll = async () => {
    const ok = await confirm({
      titleTextElement: <FormattedMessage id="clear_team_lineup_title" />,
      messageTextElement: message,
      confirmTextElement: <FormattedMessage id="clear" />,
      closeTextElement: <FormattedMessage id="cancel" />,
    })

    if (ok) {
      logFirebaseEvent(FirebaseEventName.ClearLineup, {
        id: event?.id,
        sport: event?.tournament?.category?.sport?.slug,
        status: event?.status?.type,
      })
      dispatch({ type: CLEAR_ALL, payload: { isHome: isHomeTeam } })
    }
  }

  return (
    <S.ClearAllButton onClick={handleClearAll}>
      <Text font="assistive.default">
        <FormattedMessage id="clear_all" />
      </Text>
    </S.ClearAllButton>
  )
}
