import { Box } from '@sofascore/ui'
import styled from 'styled-components'

export const OptionsContainer = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  column-gap: 12px;
  row-gap: 16px;

  ${p => p.theme.breakpoints.query.smMin`
    grid-template-columns: 1fr 1fr;
  `}
`
