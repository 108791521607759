import { Sport } from 'entities/Sport/model'
import { BasicCategory } from 'entities/Category'
import { BasicTournament } from 'entities/Tournament'
import { Country } from 'entities/Country'
import { UniqueTournamentDetails } from 'entities/UniqueTournament'
import { EntityAllowedActions } from 'entities/Permission'
import { BasicSearchResult } from 'entities/Search'

export interface BasicTeam {
  id: number
  name: string
  slug: string
  shortName?: string
  subTeams?: BasicTeam[] // note: for undefined, array will probably exits but will be empty
  gender?: string
  disabled?: boolean
  national?: boolean
  userCount?: number
  allowedActions?: EntityAllowedActions
}

export interface ExtendedTeam extends BasicTeam {
  teamColors: TeamColors
  category: BasicCategory
}

interface TeamColors {
  primary: string
  secondary: string
  text: string
}

interface PlayerTeamInfo {
  id: string
  plays: MainHand
  birthDateTimestamp: number
}

export interface Team extends BasicTeam {
  sport: Sport
  category: BasicCategory
  tournament: BasicTournament
  country?: Country
  subTeams?: Array<BasicTeam>
  teamColors?: TeamColors
  playerTeamInfo?: PlayerTeamInfo
}

export interface TeamsUniqueTournamentsResponse {
  uniqueTournaments: UniqueTournamentDetails[]
}

export enum MainHand {
  Right = 'right-handed',
  Left = 'left-handed',
  Ambidextrous = 'ambidextrous',
}

export enum Gender {
  Male = 'M',
  Female = 'F',
  Mixed = 'X',
}

export type SearchTeamResponse = BasicSearchResult<BasicTeam>
