import * as React from 'react'
import { Typography, Button, Divider, Menu, MenuItem, useTheme } from '@material-ui/core'
import { useIntl } from 'react-intl'

import { IconArrowDown } from 'components/Icons/IconArrowDown'

import { useStyles } from './styles'
import { DropdownMenuProps, Option } from './interface'

const defaultPlaceholder = 'select'

const DropdownMenu = ({
  onChange,
  MenuIcon,
  options,
  placeholder,
  selectedOption,
  caption,
  style,
  disabled,
  renderSelectedOption,
  className,
}: DropdownMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const classes = useStyles()
  const theme = useTheme()

  const intl = useIntl()
  const translatedDefaultPlaceholder = intl.formatMessage({ id: defaultPlaceholder })

  const onMenuItemClick = React.useCallback(
    (option: Option) => {
      onChange(option)
      handleClose()
    },
    [onChange],
  )

  return (
    <div className={`${classes.menuContainer} ${className}`} style={style}>
      {caption && (
        <Typography variant="caption" color="primary" style={disabled ? { color: theme.palette.text.disabled } : {}}>
          {caption}
        </Typography>
      )}
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.menuButton}
        style={{ marginTop: caption ? theme.spacing(1) : 0 }}
        disabled={disabled}
      >
        <Typography
          className={classes.menuTitle}
          variant="subtitle2"
          style={selectedOption ? { color: theme.palette.text.primary } : {}}
        >
          {renderSelectedOption
            ? selectedOption && renderSelectedOption(selectedOption)
            : selectedOption
            ? selectedOption.label
            : placeholder
            ? placeholder
            : translatedDefaultPlaceholder}
        </Typography>
        <Divider orientation="vertical" className={classes.divider} />
        <div
          className={classes.menuIcon}
          style={{
            opacity: disabled ? 0.5 : 1,
          }}
        >
          {MenuIcon ? <MenuIcon /> : <IconArrowDown />}
        </div>
      </Button>
      <Menu
        data-testid="simple-menu"
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <MenuItem
            onClick={() => onMenuItemClick(option)}
            key={`${option.label}-${index}`}
            classes={{ root: classes.menuItem }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default DropdownMenu
