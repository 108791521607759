import { Flex, Text } from '@sofascore/ui'
import { useEventState } from 'entities/Event'

import { Timekeeper } from 'components/Timekeeping'

import { getText } from '../utils'

interface Props {
  disableEditLiveInactiveThreshold: number
  enableEditLiveThreshold: number
}

export const CountdownSection = ({ disableEditLiveInactiveThreshold, enableEditLiveThreshold }: Props) => {
  const { initialEvent } = useEventState()
  const { startTimestamp } = initialEvent

  return (
    <Timekeeper referenceTime={startTimestamp} modifier={-1}>
      {({ delta, timeParts }) => {
        const minutesRemaining = delta / 60
        /**
         * Time window inside of which editors have a chance to start the game live
         * begins: 15min before the match start time
         * closes: 1h 45min after the match start time
         *
         * When the game starts, this component is no longer rendered and
         * the match will stay live until the editor finishes it.
         */
        const minutesAfterStart = Math.abs(minutesRemaining)
        const isEditLiveEnabled =
          minutesRemaining <= enableEditLiveThreshold && minutesAfterStart < disableEditLiveInactiveThreshold

        const { primary, secondary } = getText(
          new Date(startTimestamp * 1000),
          timeParts,
          delta,
          disableEditLiveInactiveThreshold,
          enableEditLiveThreshold,
        )

        return (
          <Flex direction="column" align="center">
            <Text fontWeight="medium" color={isEditLiveEnabled ? 'primary.default' : undefined}>
              {primary}
            </Text>

            <Text>{secondary}</Text>
          </Flex>
        )
      }}
    </Timekeeper>
  )
}
