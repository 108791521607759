import { IconProps, IconBase } from './Base'

const IconRugbyPenalty = ({ fill = '#0BB32A' }: IconProps) => {
  return (
    <IconBase width={24} height={24} viewBox="0 0 24 24">
      <path
        d="M4 2v14h16V2h2v16h-2v4h-2v-4H6v4H4v-4H2V2h2zm7.8.5c2.1 0 3.7 1.1 3.7 3.1 0 1-.3 1.9-1.4 2.5 1.4.6 1.8 1.6 1.8 2.8 0 2.2-1.7 3.4-4.1 3.4-2.4 0-4-1.3-4.2-3.5 0-.1 0-.2.1-.2h1.7v-.1c.1 0 .2.1.2.2.2 1 .8 1.6 2.1 1.6 1.4 0 2-.7 2-1.6 0-.8-.5-1.5-2-1.5h-1.2c-.1 0-.2-.1-.2-.2V7.4c0-.1.1-.2.2-.2h1.2c1.1 0 1.6-.7 1.6-1.4 0-.8-.6-1.4-1.6-1.4-1.1 0-1.8.5-1.9 1.5 0 .1-.1.2-.2.2H7.9c-.1 0-.2-.1-.1-.2.3-2.1 1.7-3.4 4-3.4z"
        fill={fill}
        fillRule="evenodd"
      />
    </IconBase>
  )
}

export default IconRugbyPenalty
