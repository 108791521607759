import { Box, Flex, Text } from '@sofascore/ui'
import { useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useEventState } from 'entities/Event'
import { TeamPicker } from 'entities/Team'
import {
  useTeamPlayerOptions,
  PlayerOption,
  getDefaultOption,
  renderPlayerOption,
  renderSelectedPlayerOption,
} from 'entities/Player'
import { useMissedPenaltyReasonOptions } from 'entities/Incident'

import { Option } from 'components/DropdownMenu/interface'
import { Button } from 'components/Button'
import WheelPicker from 'components/WheelPicker'
import {
  formatMatchTimeToOptionValue,
  formatTimeOptionValueToMatchTime,
  generateCurrentFootballMatchTimeOptions,
} from 'utils/matchTime'
import { Select } from 'components/Select'

import { EditIncidentFormProps } from '../model'

export const EditMissedPenaltyForm = ({
  onSubmit,
  onClose,
  incident,
  additionalTimeDisplayLimit,
}: EditIncidentFormProps) => {
  const [isSaving, setIsSaving] = useState(false)
  const { event } = useEventState()
  const intl = useIntl()
  const { id: eventId, homeTeam, awayTeam } = event
  const {
    id,
    incidentType,
    incidentClass,
    homeScore,
    awayScore,
    player: initialPlayer,
    reason,
    time: initialTime,
    addedTime: initialAddedTime,
    isHome,
  } = incident

  const [matchTime, setMatchTime] = useState(
    initialTime
      ? {
          time: formatMatchTimeToOptionValue({ time: initialTime, addedTime: initialAddedTime }),
        }
      : { time: '1' },
  )
  const [player, setPlayer] = useState<PlayerOption | undefined>(getDefaultOption(initialPlayer))
  const [teamId, setTeamId] = useState<number>(isHome ? homeTeam.id : awayTeam.id)

  const { options: missedPenaltyReasonOptions } = useMissedPenaltyReasonOptions()

  const initialpenaltyMissedType = reason
    ? missedPenaltyReasonOptions.find(option => option.value === reason)
    : undefined
  const [penaltyMissedType, setPenaltyMissedType] = useState<Option | undefined>(initialpenaltyMissedType)

  const handleConfirm = async () => {
    const incidentTime = formatTimeOptionValueToMatchTime(matchTime.time)

    onSubmit({
      eventId: eventId,
      incidentId: id as number,
      incidentData: {
        incidentType,
        incidentClass,
        isHome: teamId === homeTeam.id,
        time: incidentTime.time,
        addedTime: incidentTime.addedTime,
        homeScore,
        awayScore,
        player: player?.value,
        reason: penaltyMissedType?.value as string,
        reasonDescription: penaltyMissedType?.label as string,
      },
    })
      .then(() => onClose())
      .finally(() => setIsSaving(false))
  }

  const [teamPlayersOptions] = useTeamPlayerOptions(teamId)
  const timeOptions = useMemo(
    () => generateCurrentFootballMatchTimeOptions(event, additionalTimeDisplayLimit),
    [event, additionalTimeDisplayLimit],
  )

  return (
    <Box px="sm" pb="xxl">
      <Box px="sm" pt="lg" pb="sm" mb="lg" br="sm" bg="surface.s2">
        <Flex justify="center" mb="lg">
          <Text font="display.small">
            <FormattedMessage id="confirm_time" />
          </Text>
        </Flex>
        <Box>
          <WheelPicker
            columnsOptions={{ time: timeOptions }}
            columnsValues={matchTime}
            onChange={(_name, option) => {
              setMatchTime({ time: option as string })
            }}
            height={100}
          />
        </Box>
      </Box>

      <Box px="sm" pt="lg" pb="xs" br="sm" bg="surface.s2">
        <Flex mb="xl" justify="center">
          <Text font="display.small">
            <FormattedMessage id="select_player" />
          </Text>
        </Flex>

        <TeamPicker
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          teamId={teamId}
          onChangeTeam={newTeamId => {
            setTeamId(newTeamId)
            setPlayer(undefined)
          }}
        />

        <Select
          label={intl.formatMessage({ id: 'player' })}
          options={teamPlayersOptions}
          helperText="Optional"
          onChange={option => {
            setPlayer(option)
          }}
          value={player}
          maxVisibleOptions={6}
          w="100%"
          selectBackgroundColor="surface.s1"
          renderOption={renderPlayerOption}
          renderSelectedOption={renderSelectedPlayerOption}
        />

        <Select
          label={intl.formatMessage({ id: 'outcome' })}
          options={missedPenaltyReasonOptions}
          helperText="Optional"
          value={penaltyMissedType}
          onChange={option => {
            setPenaltyMissedType(option)
          }}
          maxVisibleOptions={6}
          w="100%"
          selectBackgroundColor="surface.s1"
        />
      </Box>

      <Flex gapX="lg" px="lg" mt="xxl">
        <Button variant="outlined" fullWidth onClick={onClose}>
          <FormattedMessage id="cancel" />
        </Button>
        <Button variant="contained" fullWidth onClick={handleConfirm} isLoading={isSaving}>
          <FormattedMessage id="confirm" />
        </Button>
      </Flex>
    </Box>
  )
}
