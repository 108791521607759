import isEqual from 'lodash/isEqual'
import { BasicEvent } from 'entities/Event'

import { UpdateEventBody } from 'modules/Matches/interface'

// Method which returns request body when we are sending request for updating event. Currently, used when updating
// football events from the home screen. In this case, there is a difference in homeScore and awayScore
// objects (in relation to the function getUpdateEventBody used in "full" match details form).
// Unlike the previous function, only the current score (homeScore.current & awayScore.current) is sent in payload,
// because all other results (period1, period2...) are calculated on the backend - auto calculation of period scores.
// In the "full" match details form (which can be reached by selecting unique tournament -> tournament -> matches tab
// and the desired match) it is possible to edit the result for certain periods (result - total, halftime, full time,
// first extra time...), but on the form that uses this function, only result that the user can enter is result - total.
export const getEventBody = (event: BasicEvent, prevEvent?: BasicEvent) => {
  const { homeScore, awayScore, currentPeriodStartTimestamp, status, aggregatedWinnerCode } = event
  const hasStatusChanges = prevEvent && prevEvent.status.code !== event.status.code
  const hasScoreChanges =
    prevEvent && !isEqual(event.homeScore, prevEvent.homeScore) && !isEqual(event.awayScore, prevEvent.awayScore)

  if (homeScore && awayScore && status) {
    const updatedEventBody: UpdateEventBody = {
      status: { code: status.code },
      homeScore: {
        current: homeScore.current,
        period1: homeScore.period1,
        period2: homeScore.period2,
        period3: homeScore.period3,
        period4: homeScore.period4,
        period5: homeScore.period5,
      },
      awayScore: {
        current: awayScore.current,
        period1: awayScore.period1,
        period2: awayScore.period2,
        period3: awayScore.period3,
        period4: awayScore.period4,
        period5: awayScore.period5,
      },
      aggregatedWinnerCode: aggregatedWinnerCode ? aggregatedWinnerCode : 0,
      currentPeriodStartTimestamp: typeof currentPeriodStartTimestamp === 'number' ? currentPeriodStartTimestamp : null,
      ...((hasStatusChanges || hasScoreChanges) && { crowdsourcingDataDisplayEnabled: false }),
    }

    return updatedEventBody
  }

  return null
}
