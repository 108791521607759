import { Country } from 'entities/Country'
import { IntlShape } from 'react-intl'
import slugify from 'slugify'

export const getTeamCountryOption = (country: Country | undefined, intl: IntlShape) => {
  if (!country || !country.alpha2 || !country.name) return undefined

  return {
    value: country.alpha2,
    label: intl.formatMessage({ id: slugify(country.name).toLowerCase() }),
  }
}
