import { IncidentType, UpdateIncidentData } from 'entities/Incident'
import { mutate } from 'swr'
import { useSelector } from 'react-redux'
import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useToast } from 'shared/lib'
import { useErrorHandler } from 'shared/hooks'

import { createIncident, deleteIncident, updateIncident } from 'api'
import { getAuthFromState } from 'store/selectors'
import { eventDetails, getIncidents } from 'api/routes'

export const useLiveMatchEditIncidentHandler = () => {
  const { userAccount } = useSelector(getAuthFromState)
  const { enqueueToast } = useToast()
  const intl = useIntl()
  const handleError = useErrorHandler()

  const handleEdit = useCallback(
    async (data: UpdateIncidentData) => {
      if (!userAccount) return

      try {
        const { updateScore, incidentData, eventId, incidentId } = data
        const { incidentType } = incidentData

        // In penalty shootout edit penalty from scored to missed and opposite
        // is done by deleting incident and creating a new one
        if (incidentType === IncidentType.PenaltyShootout && updateScore) {
          await deleteIncident(
            {
              eventId,
              incidentType,
              incidentId,
            },
            true,
          )

          await createIncident(data, true)
        } else {
          await updateIncident(data, updateScore)
        }

        mutate(eventDetails(eventId))
        mutate(getIncidents(data.eventId))
        enqueueToast(intl.formatMessage({ id: 'alert_detail_edited' }), { variant: 'success' })
      } catch (e) {
        handleError(e)
      }
    },
    [userAccount, intl, handleError, enqueueToast],
  )

  return { handleEdit }
}
