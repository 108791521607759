import { Outlet } from 'react-router-dom'

import { MyCompetitionHeader } from '../components/MyCompetitionHeader'

export const MyCompetitionLayout = () => {
  return (
    <>
      <MyCompetitionHeader />
      <Outlet />
    </>
  )
}
