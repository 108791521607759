import { CSSProperties } from 'react'
import { SportName } from 'entities/Sport'

import { StandingsRow } from './interface'

interface Column {
  shortKey: string
  resolveValue: (row: StandingsRow) => string | number | undefined
  textAlign?: CSSProperties['textAlign']
}

const team = { shortKey: 'Team', textAlign: 'left' as const, resolveValue: (row: StandingsRow) => row.team.shortName }
const player = {
  shortKey: 'Player name',
  textAlign: 'left' as const,
  resolveValue: (row: StandingsRow) => row.team.shortName,
}
const position = { shortKey: '#', resolveValue: (row: StandingsRow) => row.position }
const matches = { shortKey: 'P', resolveValue: (row: StandingsRow) => row.matches }
const wins = { shortKey: 'W', resolveValue: (row: StandingsRow) => row.wins }
const draws = { shortKey: 'D', resolveValue: (row: StandingsRow) => row.draws }
const losses = { shortKey: 'L', resolveValue: (row: StandingsRow) => row.losses }
const goals = { shortKey: 'GOALS', resolveValue: (row: StandingsRow) => `${row.scoresFor}:${row.scoresAgainst}` }
const diff = {
  shortKey: 'DIFF',
  resolveValue: (row: StandingsRow) => {
    if (row.scoresFor !== undefined && row.scoresAgainst !== undefined) {
      const _diff = row.scoresFor - row.scoresAgainst

      return _diff > 0 ? `+${_diff}` : _diff
    }

    return 0
  },
}
const points = { shortKey: 'PTS', resolveValue: (row: StandingsRow) => row.points }
const winPercentage = {
  shortKey: 'Pct',
  resolveValue: (row: StandingsRow) => {
    let pct = 0

    if (row.matches && row.wins) {
      pct = row.wins / row.matches
    }

    return pct.toFixed(3)
  },
}

// Here we define columns that we want to display in standings for each sport
export const COLUMNS_BY_SPORT: Record<SportName, Column[]> = {
  [SportName.Football]: [position, team, matches, wins, draws, losses, goals, points],
  [SportName.Futsal]: [position, team, matches, wins, draws, losses, goals, points],
  [SportName.ESports]: [position, team, matches, wins, draws, losses, points],
  [SportName.Waterpolo]: [position, team, matches, wins, draws, losses, diff, points],
  [SportName.Tennis]: [position, player, matches, wins, losses, diff, points],
  [SportName.Basketball]: [position, team, matches, wins, losses, diff, winPercentage],
  [SportName.Volleyball]: [position, team, matches, wins, losses, diff, points],
  [SportName.MiniFootball]: [position, team, matches, wins, draws, losses, goals, points],
  [SportName.Rugby]: [position, team, matches, wins, draws, losses, diff, points],
}
