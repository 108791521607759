import { Theme, createStyles, makeStyles } from '@material-ui/core'

import { neutral, borders, textColorRgbaDark } from 'styles/color'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuContainer: {
      width: '100%',
    },
    menuButton: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: borders.lightRgba,
      padding: 0,
      textTransform: 'none',
      border: `2px solid ${neutral}`,
      marginTop: theme.spacing(1),
    },
    menuTitle: {
      width: `calc(100% - ${theme.spacing(5)}px)`,
      textAlign: 'left',
      paddingLeft: theme.spacing(2),
      color: textColorRgbaDark,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    menuIcon: {
      width: theme.spacing(5),
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: 'white',
      height: theme.spacing(5),
      '& > svg': {
        marginTop: theme.spacing(1),
      },
    },
    divider: {
      height: theme.spacing(5),
      width: 2,
      backgroundColor: neutral,
    },
    menuItem: {
      fontSize: 16,
    },
  }),
)
