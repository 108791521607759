import { Box, ZIndex } from '@sofascore/ui'
import styled from 'styled-components'

export const AddTeamMenuItem = styled.li`
  display: flex;
  flex-direction: column;
  padding: 16px;

  white-space: nowrap;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: ${p => p.theme.colors.surface.s2};
  }

  &:first-child {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  &:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`

export const AddTeamMenu = styled.ul`
  position: absolute;
  top: 100%;
  right: 0;
  z-index: ${ZIndex.Dropdown};

  display: none;
  flex-direction: column;
  margin: 0;
  padding: 0;

  border-radius: 8px;
  background-color: ${p => p.theme.colors.surface.s1};
  box-shadow: 0 1px 8px 2px ${p => p.theme.colors.effect.elevation};

  &[aria-expanded='true'] {
    display: flex;
  }
`

export const DeleteWrapper = styled(Box)`
  & svg {
    display: none;
  }

  &:hover svg {
    display: block;
  }
`
