import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Typography, useTheme, Accordion, AccordionSummary, AccordionDetails, Box } from '@material-ui/core'
import { useMediaContext } from '@sofascore/ui'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { FormattedMessage, useIntl } from 'react-intl'
import { RoundInfo } from 'entities/Event'

import { RootState } from 'store/store'
import { backgrounds } from 'styles/color'
import { useStyles } from 'modules/Matches/styles'
import { DropdownMenu } from 'components/DropdownMenu'
import { SET_AGGREGATED_WINNER_CODE } from 'modules/Matches/matchesReducer'
import { Option } from 'components/DropdownMenu/interface'

interface StateProps {
  homeTeamName: string | null
  awayTeamName: string | null
  aggregatedWinnerCode: number | null
  roundInfo: RoundInfo | null
}

interface DispatchProps {
  setAggregatedWinner: (aggregatedWinnerCode: number) => void
}

type Props = StateProps & DispatchProps

const getDropdownOptions = (homeTeamName: string, awayTeamName: string): Option[] => [
  {
    value: 0,
    label: 'None',
  },
  {
    value: 1,
    label: homeTeamName,
  },
  {
    value: 2,
    label: awayTeamName,
  },
  {
    value: 3,
    label: 'Draw',
  },
]

const AggregatedScoreSection = (props: Props) => {
  const intl = useIntl()

  const { homeTeamName, awayTeamName, aggregatedWinnerCode, setAggregatedWinner, roundInfo } = props
  const [aggregatedWinnerValue, setAggregatedWinnerValue] = React.useState<Option>({
    value: 0,
    label: intl.formatMessage({ id: 'none' }),
  })

  useEffect(() => {
    if (aggregatedWinnerCode && homeTeamName && awayTeamName) {
      const options = getDropdownOptions(homeTeamName, awayTeamName)
      const selectedOption = options.filter(option => option.value === aggregatedWinnerCode)
      if (selectedOption.length) {
        setAggregatedWinnerValue(selectedOption[0])
      }
    }
  }, [aggregatedWinnerCode, homeTeamName, awayTeamName])

  const handleClickMatchStatusMenuItem = (selectedOption: Option) => {
    setAggregatedWinnerValue(selectedOption)
    setAggregatedWinner(Number(selectedOption.value))
  }

  const classes = useStyles()
  const theme = useTheme()
  const { isMobile } = useMediaContext()

  // Don't display Aggregated Score Section for not staged rounds
  if (roundInfo && !roundInfo.name) {
    return null
  }

  if (!homeTeamName || !awayTeamName) {
    return null
  }

  const dropdownOptions = [
    {
      value: 0,
      label: intl.formatMessage({ id: 'none' }),
    },
    {
      value: 1,
      label: homeTeamName,
    },
    {
      value: 2,
      label: awayTeamName,
    },
    {
      value: 3,
      label: intl.formatMessage({ id: 'draw' }),
    },
  ]

  return (
    <Accordion className={classes.expansionPanel} style={{ backgroundColor: backgrounds.content }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: theme.palette.secondary.main }} />}
        className={classes.expansionPanelSummary}
      >
        <Typography className={classes.heading}>
          <FormattedMessage id="aggregatedScore" />
        </Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.expansionPanelDetailsRoot }}>
        <Box className={classes.container}>
          <Box width={isMobile ? undefined : '33%'}>
            <DropdownMenu
              onChange={handleClickMatchStatusMenuItem}
              options={dropdownOptions}
              selectedOption={aggregatedWinnerValue}
              caption={intl.formatMessage({ id: 'aggregated' })}
            />
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

const mapStateToProps = ({ event }: RootState): StateProps => ({
  homeTeamName: event ? event.homeTeam.name : null,
  awayTeamName: event ? event.awayTeam.name : null,
  aggregatedWinnerCode: event && typeof event.aggregatedWinnerCode === 'number' ? event.aggregatedWinnerCode : null,
  roundInfo: event && event.roundInfo ? event.roundInfo : null,
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setAggregatedWinner: (aggregatedWinnerCode: number) =>
    dispatch({ type: SET_AGGREGATED_WINNER_CODE, payload: { aggregatedWinnerCode } }),
})

export default connect<StateProps, DispatchProps, unknown, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(AggregatedScoreSection)
