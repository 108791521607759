import {
  IncidentGoalType,
  IncidentType,
  IncidentGoalRegular,
  IncidentOwnGoal,
  IncidentFootballGoalFromPenalty,
  IncidentCardType,
  IncidentYellowCard,
  IncidentRedCard,
  IncidentYellowRedCard,
  IncidentFootballMissedPenalty,
  IncidentShootoutPenaltyScored,
  IncidentShootoutPenaltyMissed,
  IncidentPenaltyShootoutType,
  IncidentSuspensionType,
  IncidentTwoMinuteSuspension,
  IncidentAccumulatedPenalty,
} from 'entities/Incident'
import { DeleteIncident } from 'features/DeleteIncident'
import {
  EditTwoMinuteSuspension,
  EditMiniFootballGoalRegular as EditGoalRegular,
  EditMiniFootballLastGoalRegular as EditLastGoalRegular,
  EditMiniFootballLastOwnGoal as EditLastOwnGoal,
  EditMiniFootballLastGoalFromPenalty as EditLastGoalFromPenalty,
  EditMiniFootballMissedPenalty as EditMissedPenalty,
  EditMiniFootballYellowCard as EditYellowCard,
  EditMiniFootballRedCard as EditRedCard,
  EditMiniFootballYellowRedCard as EditYellowRedCard,
  EditMiniFootballLastShootoutPenalty as EditLastShootoutPenalty,
  EditMiniFootballShootoutPenalty as EditShootoutPenalty,
  EditMiniFootballLastAccumulatedPenalty as EditLastAccumulatedPenalty,
  EditMiniFootballAccumulatedPenalty as EditAccumulatedPenalty,
} from 'features/EditIncident'

import { IncidentRendererProps } from '../../model'

export const MiniFootballIncidentRenderer = ({
  incident,
  isLastGoal = false,
  onEdit,
  onDelete,
}: IncidentRendererProps) => {
  const { incidentType, incidentClass } = incident

  const props = {
    incident,
    isLastGoal,
    onEdit,
    onDelete,
  }

  if (incidentType === IncidentType.Goal && incidentClass == IncidentGoalType.GoalRegular) {
    return (
      <IncidentGoalRegular incident={incident}>
        {isLastGoal ? <DeleteIncident {...props} /> : null}
        {isLastGoal ? <EditLastGoalRegular {...props} /> : <EditGoalRegular {...props} />}
      </IncidentGoalRegular>
    )
  }

  if (incidentType === IncidentType.Goal && incidentClass == IncidentGoalType.GoalFromPenalty) {
    return (
      <IncidentFootballGoalFromPenalty incident={incident}>
        {isLastGoal ? <DeleteIncident {...props} /> : null}
        {isLastGoal ? <EditLastGoalFromPenalty {...props} /> : <EditGoalRegular {...props} />}
      </IncidentFootballGoalFromPenalty>
    )
  }

  if (incidentType === IncidentType.Goal && incidentClass == IncidentGoalType.OwnGoal) {
    return (
      <IncidentOwnGoal incident={incident}>
        {isLastGoal ? <DeleteIncident {...props} /> : null}
        {isLastGoal ? <EditLastOwnGoal {...props} /> : <EditGoalRegular {...props} />}
      </IncidentOwnGoal>
    )
  }

  if (incidentType === IncidentType.Goal && incidentClass == IncidentGoalType.AccumulatedPenalty) {
    return (
      <IncidentAccumulatedPenalty incident={incident}>
        {isLastGoal ? <DeleteIncident {...props} /> : null}
        {isLastGoal ? <EditLastAccumulatedPenalty {...props} /> : <EditAccumulatedPenalty {...props} />}
      </IncidentAccumulatedPenalty>
    )
  }

  if (incidentType === IncidentType.PenaltyShootout && incidentClass === IncidentPenaltyShootoutType.Scored) {
    return (
      <IncidentShootoutPenaltyScored incident={incident}>
        {isLastGoal ? <DeleteIncident {...props} /> : null}
        {isLastGoal ? <EditLastShootoutPenalty {...props} /> : <EditShootoutPenalty {...props} />}
      </IncidentShootoutPenaltyScored>
    )
  }

  if (incidentType === IncidentType.PenaltyShootout && incidentClass === IncidentPenaltyShootoutType.Missed) {
    return (
      <IncidentShootoutPenaltyMissed incident={incident}>
        {isLastGoal ? <DeleteIncident {...props} /> : null}
        {isLastGoal ? <EditLastShootoutPenalty {...props} /> : <EditShootoutPenalty {...props} />}
      </IncidentShootoutPenaltyMissed>
    )
  }

  if (incidentType === IncidentType.Suspension && incidentClass === IncidentSuspensionType.TwoMinutes) {
    return (
      <IncidentTwoMinuteSuspension incident={incident}>
        <DeleteIncident {...props} />
        <EditTwoMinuteSuspension {...props} />
      </IncidentTwoMinuteSuspension>
    )
  }

  if (incidentType === IncidentType.Card && incidentClass == IncidentCardType.Yellow) {
    return (
      <IncidentYellowCard incident={incident}>
        <DeleteIncident {...props} />
        <EditYellowCard {...props} />
      </IncidentYellowCard>
    )
  }

  if (incidentType === IncidentType.Card && incidentClass == IncidentCardType.Red) {
    return (
      <IncidentRedCard incident={incident}>
        <DeleteIncident {...props} />
        <EditRedCard {...props} />
      </IncidentRedCard>
    )
  }

  if (incidentType === IncidentType.Card && incidentClass == IncidentCardType.YellowRed) {
    return (
      <IncidentYellowRedCard incident={incident}>
        <DeleteIncident {...props} />
        <EditYellowRedCard {...props} />
      </IncidentYellowRedCard>
    )
  }

  if (incidentType === IncidentType.InGamePenalty) {
    return (
      <IncidentFootballMissedPenalty incident={incident}>
        <DeleteIncident {...props} />
        <EditMissedPenalty {...props} />
      </IncidentFootballMissedPenalty>
    )
  }

  return null
}
