import { Flex } from '@sofascore/ui'
import { DynamicLineupsPlayer } from 'features/AddLineups'
import { useState } from 'react'

import * as S from './styles'
import { PLAYER_SLOT_HEIGHT, PLAYER_SLOT_WIDTH } from '../config'
import { MobileClickSlot } from './MobileClickSlot'
import { PitchBackground, PitchLines } from './PitchGraphics'
import { MobileAddPlayerDialog } from '../../MobileAddPlayerDialog'

export const MobileClickPitch = ({
  lineupPlayers,
  formation,
  kit,
  goalkeeperKit,
  onDrop,
  onRemove,
}: {
  lineupPlayers: DynamicLineupsPlayer[]
  formation: string
  kit: string
  goalkeeperKit: string
  onDrop: (lineupPlayer: DynamicLineupsPlayer, position: number) => void
  onRemove: (lineupPlayer: DynamicLineupsPlayer) => void
}) => {
  const [selectedPlayerSlot, setSelectedPlayerSlot] = useState<number | null>(null)

  const lines = formation.toString().split('-')
  const linesCount = lines.length

  let position = 1

  return (
    <>
      <S.MobilePitchBackground style={{ backgroundImage: 'url(/images/graphics/football-terrain.svg)' }}>
        <S.MobilePitchContainer>
          <PitchBackground />
          <PitchLines />

          <Flex position="relative" direction="column" gapY={linesCount === 3 ? 36 : 28} h="100%" w="100%" p="sm">
            {/** Goalkeeper */}
            <Flex gapX="xs" align="center" justify="center">
              <MobileClickSlot
                lineupPlayers={lineupPlayers}
                position={position}
                kitColor={goalkeeperKit}
                onClick={() => setSelectedPlayerSlot(1)}
                onRemove={player => onRemove(player)}
                style={{ width: PLAYER_SLOT_WIDTH, height: PLAYER_SLOT_HEIGHT }}
              />
            </Flex>

            {/** Other lines */}
            {lines.map((line, index) => {
              return (
                <Flex key={`${line}-${index}`} gapX="xs" align="flex-start" justify="center">
                  {Array(Number(line))
                    .fill(undefined)
                    .map((slot, index) => {
                      position++
                      return (
                        <MobileClickSlot
                          key={`${slot}-${index}`}
                          lineupPlayers={lineupPlayers}
                          position={position}
                          kitColor={kit}
                          onClick={position => setSelectedPlayerSlot(position)}
                          onRemove={player => onRemove(player)}
                          style={{ width: PLAYER_SLOT_WIDTH, height: PLAYER_SLOT_HEIGHT }}
                        />
                      )
                    })}
                </Flex>
              )
            })}
          </Flex>
        </S.MobilePitchContainer>
      </S.MobilePitchBackground>

      <MobileAddPlayerDialog
        open={!!selectedPlayerSlot}
        lineupPlayers={lineupPlayers}
        selectedPosition={selectedPlayerSlot!}
        onClose={() => setSelectedPlayerSlot(null)}
        onAddPlayer={lineupPlayer => onDrop(lineupPlayer, selectedPlayerSlot!)}
      />
    </>
  )
}
