import { RugbyPitchLineups } from 'widgets/PitchLineups'

import { LineupsLoader } from './RugbyLineupsLoader'
import { LineupsLayout } from '../../LineupsLayout'
import { RugbyLineupsActions } from './RugbyLineupsActions'
import { isPitchLineupValid, responseToInitialState } from '../utils'

export const RugbyLineups = () => {
  return (
    <LineupsLoader transformer={responseToInitialState}>
      <LineupsLayout>
        <RugbyLineupsActions isLineupValid={isPitchLineupValid}>
          <RugbyPitchLineups />
        </RugbyLineupsActions>
      </LineupsLayout>
    </LineupsLoader>
  )
}
