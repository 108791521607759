import * as React from 'react'

import { IconBase, IconProps } from './Base'

export const IconShirt = (props: IconProps) => (
  <IconBase width={21} height={24} viewBox="0 0 21 24" {...props}>
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="30rb5e79ua">
        <stop stopOpacity="0" offset="0%" />
        <stop stopOpacity=".1" offset="100%" />
      </linearGradient>
      <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="zq9xq85yfb">
        <stop stopOpacity=".1" offset="0%" />
        <stop stopOpacity="0" offset="100%" />
      </linearGradient>
      <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="d2h5nhfonc">
        <stop stopOpacity=".1" offset="0%" />
        <stop stopOpacity="0" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M0 0h21v24H0z" />
      <path
        d="M13.85.541 19.5 2.25 21 9.278l-2.25 1.5V23.25A.75.75 0 0 1 18 24H3a.75.75 0 0 1-.75-.75V10.778L0 9.278 1.5 2.25 7.15.541c.618.734 1.886 1.237 3.35 1.237 1.464 0 2.732-.503 3.35-1.237z"
        fill={props.fill || '#0162AF'}
      />
      <g fill="none" fillOpacity="0">
        <path d="M7.15.541c.618.734 1.886 1.237 3.35 1.237V24H3a.75.75 0 0 1-.75-.75V10.778L0 9.278 1.5 2.25 7.15.541z" />
        <path d="m18.749 21 .001 2.25A.75.75 0 0 1 18 24H3a.75.75 0 0 1-.75-.75L2.249 21h16.5zm0-6v3h-16.5l.001-3h16.499zm2.192-6 .059.278-2.25 1.5L18.749 12H2.25v-1.222L0 9.278.059 9h20.882zM19.66 3l.64 3H.7l.64-3h18.32z" />
        <path d="M6 .89V24H3V1.797L6 .89zm6.001.7L12 24H9V1.59c.46.121.967.188 1.5.188.534 0 1.042-.067 1.501-.188zM15 24V.89l3 .907V24h-3z" />
        <path d="m15 .89 3 .907V3h1.66l.64 3H18v3h2.941l.059.278-2.25 1.5L18.749 12H18v3h.749v3H18v3h.749l.001 2.25a.75.75 0 0 1-.648.743L18 24v-3h-3v3h-3v-3H9v3H6v-3H3v3a.75.75 0 0 1-.75-.75L2.249 21H3v-3h-.751l.001-3H3v-3h-.75v-1.222L0 9.278.059 9H3V6H.7l.64-3H3V1.797L6 .89V3h3V1.59c.46.121.967.188 1.5.188.534 0 1.042-.067 1.501-.188L12 3h3V.89zM9 18H6v3h3v-3zm6 0h-3v3h3v-3zm-9-3H3v3h3v-3zm6 0H9v3h3v-3zm6 0h-3v3h3v-3zm-9-3H6v3h3v-3zm6 0h-3v3h3v-3zM6 9H3v3h3V9zm6 0H9v3h3V9zm6 0h-3v3h3V9zM9 6H6v3h3V6zm6 0h-3v3h3V6zM6 3H3v3h3V3zm6 0H9v3h3V3zm6 0h-3v3h3V3z" />
        <path d="M2.999 1.796 3 11.262l-.75-.484L0 9.278 1.5 2.25l1.499-.454zm15.001 0 1.475.445.025.009L21 9.278l-2.25 1.5-.75.484V1.796zM13.85.541l1.501.455A3.961 3.961 0 0 1 11.908 3H9.092A3.961 3.961 0 0 1 5.65.996L7.15.541c.617.734 1.885 1.237 3.349 1.237 1.464 0 2.732-.503 3.35-1.237z" />
      </g>
      <path fill="none" d="M0 0h21v24H0z" />
      <path
        d="m13.964.966 5.215 1.578 1.401 6.562-2.205 1.47V23.25L3 23.624l-.375-13.047L.42 9.107l1.4-6.563L7.037.966c.716.7 1.991 1.186 3.464 1.186 1.473 0 2.748-.486 3.464-1.186z"
        stroke="#19191C"
        strokeWidth=".75"
        opacity=".4"
      />
      <path
        d="M2.25 16.2h16.5v5.25a.75.75 0 0 1-.75.75H3a.75.75 0 0 1-.75-.75V16.2z"
        fill="url(#30rb5e79ua)"
        transform="translate(0 1.8)"
      />
      <path fill="url(#zq9xq85yfb)" transform="matrix(-1 0 0 1 39 1.8)" d="m20.25 8.977.75.485V0l-1.5.45L18 7.477z" />
      <path fill="url(#d2h5nhfonc)" d="m2.25 8.978.75.484V0L1.5.45 0 7.478z" transform="translate(0 1.8)" />
    </g>
  </IconBase>
)
