import { Box, Flex, Text } from '@sofascore/ui'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  IncidentType,
  IncidentPenaltyShootoutType,
  useMissedPenaltyReasonOptions,
  CreateIncidentData,
} from 'entities/Incident'
import { useEventState } from 'entities/Event'
import { useTeamPlayerOptions, PlayerOption } from 'entities/Player'
import IconFootballPenalty from '@sofascore/ui/dist/modules/Icons/_IconFootballPenalty'
import IconPenaltyMissed from '@sofascore/ui/dist/modules/Icons/_IconPenaltyMissed'

import IncidentBox from 'components/IncidentBox'
import { Button } from 'components/Button'
import { Select } from 'components/Select'
import { GREEN, RED } from 'styles/color'

interface Props {
  teamId: number
  sequence: number | undefined
  onSubmit: (arg0: CreateIncidentData) => Promise<void>
  onClose: () => void
}

export const AddFootballShootoutPenaltyForm = ({ teamId, sequence, onClose, onSubmit }: Props) => {
  const { event } = useEventState()
  const intl = useIntl()

  const [isSaving, setIsSaving] = useState(false)
  const [scored, setScored] = useState<IncidentPenaltyShootoutType>(IncidentPenaltyShootoutType.Scored)
  const [scorer, setScorer] = useState<PlayerOption | undefined>()

  const { id: eventId, homeTeam, homeScore, awayScore } = event
  const isHomeTeam = homeTeam.id === teamId

  const [scorerOptions] = useTeamPlayerOptions(teamId, scorer)
  const { options, missedPenaltyReason, setMissedPenaltyReason } = useMissedPenaltyReasonOptions()

  const handleConfirm = () => {
    setIsSaving(true)

    let _homeScore = homeScore.penalties || 0
    if (scored === IncidentPenaltyShootoutType.Scored && isHomeTeam) {
      _homeScore = _homeScore + 1
    }

    let _awayScore = awayScore.penalties || 0
    if (scored === IncidentPenaltyShootoutType.Scored && !isHomeTeam) {
      _awayScore = _awayScore + 1
    }

    onSubmit({
      eventId,
      incidentData: {
        incidentType: IncidentType.PenaltyShootout,
        incidentClass: scored,
        isHome: isHomeTeam,
        time: null,
        player: scorer ? scorer.value : undefined,
        homeScore: _homeScore,
        awayScore: _awayScore,
        reason: scored === IncidentPenaltyShootoutType.Missed ? missedPenaltyReason?.value : undefined,
        description: scored === IncidentPenaltyShootoutType.Missed ? missedPenaltyReason?.label : undefined,
        sequence: sequence,
      },
    })
      .then(() => onClose())
      .finally(() => setIsSaving(false))
  }

  return (
    <Box px="sm" pb="xxl">
      <Box px="sm" pt="lg" pb="xs" br="sm" bg="surface.s2">
        <Flex mb="xl" justify="center">
          <Text font="display.small">
            <FormattedMessage id="select_outcome_penalty_taker" />
          </Text>
        </Flex>

        <Flex mb="lg" px="xxl" justify="center">
          <IncidentBox
            label={intl.formatMessage({ id: 'scored' })}
            isActive={scored === IncidentPenaltyShootoutType.Scored}
            onClick={() => {
              setScored(IncidentPenaltyShootoutType.Scored)
            }}
            icon={<IconFootballPenalty fill={GREEN.incident} />}
            mx="sm"
          />

          <IncidentBox
            label={intl.formatMessage({ id: 'missed' })}
            isActive={scored === IncidentPenaltyShootoutType.Missed}
            onClick={() => {
              setScored(IncidentPenaltyShootoutType.Missed)
            }}
            icon={<IconPenaltyMissed fill={RED.incident} />}
            mx="sm"
          />
        </Flex>

        <Select
          label={intl.formatMessage({ id: 'player' })}
          options={scorerOptions}
          helperText="Optional"
          value={scorer}
          onChange={option => setScorer(option)}
          maxVisibleOptions={6}
          w="100%"
          selectBackgroundColor="surface.s1"
        />

        {scored === IncidentPenaltyShootoutType.Missed && (
          <Select
            label={intl.formatMessage({ id: 'outcome' })}
            options={options}
            helperText="Optional"
            value={missedPenaltyReason}
            onChange={option => {
              setMissedPenaltyReason(option)
            }}
            maxVisibleOptions={6}
            w="100%"
            selectBackgroundColor="surface.s1"
          />
        )}
      </Box>

      <Flex gapX="lg" px="lg" mt="xxl">
        <Button variant="outlined" fullWidth onClick={onClose}>
          <FormattedMessage id="cancel" />
        </Button>
        <Button variant="contained" fullWidth onClick={handleConfirm} isLoading={isSaving}>
          <FormattedMessage id="confirm" />
        </Button>
      </Flex>
    </Box>
  )
}
