import { ScoreWithPeriods } from 'entities/Score'
import { Incident, IncidentType } from 'entities/Incident'

/**
 * Returns tuple with first element being the last goal (or undefined if it does not exist) and second element
 * being the second to last goal (or undefined if it does not exist) from the provided incident list.
 */
export const getLastGoalsFromIncidents = (incidents: Incident[]): [Incident | undefined, Incident | undefined] => {
  const goals = incidents ? incidents.filter(i => i.incidentType === IncidentType.Goal) : []
  const [lastGoal, secondToLastGoal] = goals

  return [lastGoal, secondToLastGoal]
}

/**
 * Utility function that returns the incremented score based on `isHomeTeam` flag. Used for
 * getting the new (incremented) score in edit match form.
 */
export const getIncrementedScore = (homeScore: ScoreWithPeriods, awayScore: ScoreWithPeriods, isHomeTeam: boolean) => {
  const _homeScore = homeScore.display || 0
  const _awayScore = awayScore.display || 0

  return {
    homeScore: isHomeTeam ? _homeScore + 1 : _homeScore,
    awayScore: isHomeTeam ? _awayScore : _awayScore + 1,
  }
}
