import * as React from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  FIRST_EXTRA_TIME_CODE,
  SECOND_EXTRA_TIME_CODE,
  PENALTIES_CODE,
  SECOND_HALF_CODE,
  FOURTH_QUARTER_CODE,
  OVERTIME as OVERTIME_CODE,
} from 'entities/Status'
import { ScoreWithPeriods } from 'entities/Score'
import { SportName, sportsWithTwoHalves } from 'entities/Sport'

import { RootState } from 'store/store'
import {
  CURRENT,
  PERIOD1,
  PENALTIES,
  PERIOD3,
  OVERTIME,
  EXTRA_TIME_1,
  PERIOD2,
  EXTRA_TIME_2,
  PERIOD4,
  PERIOD5,
} from 'modules/Matches/const'
import { REMOVE_SCORE_SECTION, SET_MATCH_STATUS } from 'modules/Matches/matchesReducer'
import { useMyCompetitionsState } from 'modules/MyCompetitions/providers/MyCompetitionsProvider'

import { extra1InitialValue, extra2InitialValue, penaltiesInitialValue, overtimeInitialValue } from './helper'
import ResultSectionItem from './ResultSectionItem'

interface ResultSectionRedux {
  homeScore: ScoreWithPeriods | null
  awayScore: ScoreWithPeriods | null
  matchStatusCode: number | null
}

type Props = ResultSectionRedux

const ResultSection = (props: Props) => {
  const { uniqueTournament } = useMyCompetitionsState()

  const sport = uniqueTournament?.category.sport.slug
  const { homeScore, awayScore, matchStatusCode } = props

  const dispatch = useDispatch()

  const isWaterpolo = sport === SportName.Waterpolo
  const hasTwoHalves = sport ? sportsWithTwoHalves.includes(sport) : false
  const hasPenalties = hasTwoHalves || isWaterpolo
  const has5thPeriod = sport === SportName.Tennis || sport === SportName.Volleyball

  const [showExtra1, setShowExtra1] = React.useState<boolean>(extra1InitialValue(matchStatusCode, homeScore, awayScore))
  const [showExtra2, setShowExtra2] = React.useState<boolean>(extra2InitialValue(matchStatusCode, homeScore, awayScore))
  const [showPenalties, setShowPenalties] = React.useState<boolean>(
    penaltiesInitialValue(matchStatusCode, homeScore, awayScore),
  )
  const [showOvertime, setShowOvertime] = React.useState<boolean>(
    overtimeInitialValue(matchStatusCode, homeScore, awayScore),
  )

  const deleteExtra1 = () => {
    setShowExtra1(false)
    dispatch({ type: REMOVE_SCORE_SECTION, payload: { periodToRemove: EXTRA_TIME_1 } })
    if (showExtra2) {
      setShowExtra2(false)
      dispatch({ type: REMOVE_SCORE_SECTION, payload: { periodToRemove: EXTRA_TIME_2 } })
    }
    if (showPenalties) {
      setShowPenalties(false)
      dispatch({ type: REMOVE_SCORE_SECTION, payload: { periodToRemove: PENALTIES } })
    }
    dispatch({ type: SET_MATCH_STATUS, payload: { status: { code: SECOND_HALF_CODE } } })
  }

  const deleteExtra2 = () => {
    setShowExtra2(false)
    dispatch({ type: REMOVE_SCORE_SECTION, payload: { periodToRemove: EXTRA_TIME_2 } })
    dispatch({ type: SET_MATCH_STATUS, payload: { status: { code: FIRST_EXTRA_TIME_CODE } } })
  }

  const deletePenalties = () => {
    setShowPenalties(false)
    dispatch({ type: REMOVE_SCORE_SECTION, payload: { periodToRemove: PENALTIES } })
    dispatch({
      type: SET_MATCH_STATUS,
      payload: {
        status: {
          code: isWaterpolo ? FOURTH_QUARTER_CODE : showExtra2 ? SECOND_EXTRA_TIME_CODE : SECOND_HALF_CODE,
        },
      },
    })
  }

  const deleteOvertime = () => {
    setShowOvertime(false)
    dispatch({ type: REMOVE_SCORE_SECTION, payload: { periodToRemove: OVERTIME } })
    dispatch({ type: SET_MATCH_STATUS, payload: { status: { code: FOURTH_QUARTER_CODE } } })
  }

  React.useEffect(() => {
    switch (matchStatusCode) {
      case FIRST_EXTRA_TIME_CODE:
        setShowExtra1(true)
        break
      case SECOND_EXTRA_TIME_CODE:
        !showExtra1 && setShowExtra1(true)
        setShowExtra2(true)
        break
      case PENALTIES_CODE:
        setShowPenalties(true)
        break
      case OVERTIME_CODE:
        setShowOvertime(true)
        break
      default:
        return
    }
  }, [showExtra1, matchStatusCode])

  return (
    <>
      <ResultSectionItem key={CURRENT} periodKey={CURRENT} expanded />
      <ResultSectionItem key={PERIOD1} periodKey={PERIOD1} />
      {showExtra1 && hasTwoHalves && (
        <>
          <ResultSectionItem key={PERIOD2} periodKey={PERIOD2} />
          <ResultSectionItem periodKey={EXTRA_TIME_1} closeable deleteSection={deleteExtra1} />
        </>
      )}
      {showExtra2 && hasTwoHalves && (
        <>
          <ResultSectionItem periodKey={EXTRA_TIME_2} closeable deleteSection={deleteExtra2} />
        </>
      )}
      {!hasTwoHalves && (
        <>
          <ResultSectionItem key={PERIOD2} periodKey={PERIOD2} />
          <ResultSectionItem key={PERIOD3} periodKey={PERIOD3} />
          <ResultSectionItem key={PERIOD4} periodKey={PERIOD4} />
          {showOvertime && (
            <ResultSectionItem key={OVERTIME} periodKey={OVERTIME} closeable deleteSection={deleteOvertime} />
          )}
        </>
      )}
      {has5thPeriod && <ResultSectionItem key={PERIOD5} periodKey={PERIOD5} />}
      {showPenalties && hasPenalties && (
        <>
          <ResultSectionItem periodKey={PENALTIES} closeable deleteSection={deletePenalties} />
        </>
      )}
    </>
  )
}

const areStatesEqual = (nextState: RootState, prevState: RootState) => {
  if (!nextState.event || !prevState.event) {
    return false
  }
  const nextHomeScore = nextState.event.homeScore
  const prevHomeScore = prevState.event.homeScore
  const nextAwayScore = nextState.event.awayScore
  const prevAwayScore = prevState.event.awayScore
  const prevStatusCode = prevState.event.status.code
  const nextStatusCode = nextState.event.status.code

  return (
    Object.keys(nextHomeScore).length === Object.keys(prevHomeScore).length &&
    Object.keys(nextAwayScore).length === Object.keys(prevAwayScore).length &&
    prevStatusCode === nextStatusCode
  )
}

const mapStateToProps = ({ event }: RootState): Props => ({
  homeScore: event ? event.homeScore : null,
  awayScore: event ? event.awayScore : null,
  matchStatusCode: event ? event.status.code : null,
})

export default connect<ResultSectionRedux, null, unknown, RootState>(mapStateToProps, null, null, {
  areStatesEqual,
})(ResultSection)
