import React, { useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import { AWAY_TEAM, HOME_TEAM, StatusCode } from 'entities/Status'
import { BasicEvent } from 'entities/Event'

import { CURRENT } from 'modules/Matches/const'
import { RootState } from 'store/store'
import { backgrounds, secondaryColor } from 'styles/color'
import { Direction } from 'components/Counter/interface'
import { ScoreCounter } from 'components/Counter/Counter'
import { SET_SCORE } from 'modules/Matches/matchesReducer'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: theme.spacing(32.5),
      backgroundColor: backgrounds.content,
    },

    resultSection: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      flexGrow: 3,
    },

    resultItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    helperText: {
      fontSize: 12,
      marginRight: 16,
      color: secondaryColor,
      maxHeight: 36,
      overflow: 'hidden',
    },
  }),
)

const ResultContainer = () => {
  const classes = useStyles()

  const event: BasicEvent = useSelector((state: RootState) => state.event)
  const { homeTeam, awayTeam, homeScore, awayScore, status } = event
  const dispatch = useDispatch()

  const [resultHome, setResultHome] = useState<number>(-1)
  const [resultAway, setResultAway] = useState<number>(-1)

  useEffect(() => {
    const resultHome = typeof homeScore.current === 'number' ? homeScore.current : -1
    const resultAway = typeof awayScore.current === 'number' ? awayScore.current : -1
    setResultHome(resultHome)
    setResultAway(resultAway)
  }, [awayScore, homeScore])

  const onScoreChanged = (newScore: number, team: string) => {
    const homeScoreUpdate = team === HOME_TEAM
    const invalidScore = newScore < 0
    if (invalidScore) {
      setResultHome(-1)
      setResultAway(-1)
    } else {
      setResultHome(homeScoreUpdate ? newScore : resultHome < 0 ? 0 : resultHome)
      setResultAway(homeScoreUpdate ? (resultAway < 0 ? 0 : resultAway) : newScore)
    }
  }

  const applyChanges = useCallback(() => {
    dispatch({
      type: SET_SCORE,
      payload: {
        periodKey: CURRENT,
        homeScore: resultHome < 0 ? null : resultHome,
        awayScore: resultAway < 0 ? null : resultAway,
      },
    })
  }, [dispatch, resultAway, resultHome])

  // Check if `current` property is defined in homeScore and awayScore object (resultHome and awayScore is then set to
  // -1 inside of useEffect)
  const isResultDefined = !(resultHome === -1 && !homeScore.current && resultAway === -1 && !awayScore.current)
  const hasResultChanges = resultHome !== homeScore.current || resultAway !== awayScore.current
  const isNotStarted = status.code === StatusCode['Not started']

  const teamAplayer1Name = homeTeam.subTeams?.length && homeTeam.subTeams[0].name
  const teamAplayer2Name = homeTeam.subTeams?.length && homeTeam.subTeams[1].name
  const teamBplayer1Name = awayTeam.subTeams?.length && awayTeam.subTeams[0].name
  const teamBplayer2Name = awayTeam.subTeams?.length && awayTeam.subTeams[1].name
  const isDoubles = teamAplayer1Name && teamAplayer2Name && teamBplayer1Name && teamBplayer2Name

  return (
    <Box className={classes.container}>
      <Box className={classes.resultSection}>
        <Box className={classes.resultItem}>
          {isDoubles ? (
            <Typography variant="caption" color="primary" align="center">
              {teamAplayer1Name}
              <br />
              {teamAplayer2Name}
            </Typography>
          ) : (
            <Typography variant="caption" color="primary">
              {homeTeam.name}
            </Typography>
          )}

          <Box width={100}>
            <ScoreCounter
              direction={Direction.COLUMN}
              value={resultHome}
              onChange={newScore => {
                onScoreChanged(newScore, HOME_TEAM)
              }}
            />
          </Box>
        </Box>
        <Box className={classes.resultItem}>
          {isDoubles ? (
            <Typography variant="caption" color="primary" align="center">
              {teamBplayer1Name}
              <br />
              {teamBplayer2Name}
            </Typography>
          ) : (
            <Typography variant="caption" color="primary">
              {awayTeam.name}
            </Typography>
          )}

          <Box width={100}>
            <ScoreCounter
              direction={Direction.COLUMN}
              value={resultAway}
              onChange={newScore => {
                onScoreChanged(newScore, AWAY_TEAM)
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box p={2} display="flex" alignItems="center" justifyContent="flex-end">
        {isNotStarted && hasResultChanges && isResultDefined && (
          <Typography className={classes.helperText}>
            <FormattedMessage id="applyResultHelperText" />
          </Typography>
        )}
        <Box>
          <Button
            disabled={!isResultDefined || !hasResultChanges || isNotStarted}
            variant="contained"
            onClick={applyChanges}
          >
            <FormattedMessage id="apply" />
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ResultContainer
