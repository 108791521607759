import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const Link = styled(NavLink)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  color: ${p => p.theme.colors.onSurface.nLv3};
  padding: 16px 16px 13px 16px;
  border-bottom: 3px solid transparent;
  transition: all 0.2s;

  span {
    font-weight: 500;
  }

  &:hover,
  &.active {
    color: ${p => p.theme.colors.primary.default};
    border-bottom: 3px solid ${p => p.theme.colors.primary.default};
  }

  ${p => p.theme.breakpoints.query.mdMax`
    width: 100%;
    height: 56px;
    `}
`
