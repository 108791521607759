import { Box, Flex, Text } from '@sofascore/ui'
import { FormattedMessage } from 'react-intl'

import { textLightColor } from 'styles/color'

export const AboutSofascoreEditor = () => {
  return (
    <Box px="lg">
      <Flex direction="column" gapY="lg">
        <Text font="headerTitle">
          <FormattedMessage id="editorWelcome" />
        </Text>

        <Text font="body.large" fontWeight="medium">
          <FormattedMessage id="welcomeText1" />
        </Text>

        <Text font="body.large" color={textLightColor}>
          <FormattedMessage id="welcomeText2" />
        </Text>
      </Flex>

      <Flex direction="column" gapY="lg" mt="xxl">
        <Text font="body.large" fontWeight="medium">
          <FormattedMessage id="howToJoin" />
        </Text>

        <Text font="body.large" color={textLightColor}>
          <FormattedMessage id="welcomeText3" />
        </Text>

        <Text font="body.large" color={textLightColor}>
          <FormattedMessage id="welcomeText4" />
        </Text>
      </Flex>
    </Box>
  )
}
