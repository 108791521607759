import React from 'react'
import { Typography, Theme, useTheme, AccordionSummary, AccordionDetails, Accordion } from '@material-ui/core'
import { useMediaContext } from '@sofascore/ui'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { FormattedMessage, useIntl } from 'react-intl'
import { Stylable } from 'shared/model'

import { DatePicker, TimePicker } from 'components/DateTimePickers/DateTimePickers'
import { useStyles } from 'modules/Matches/styles'

interface StartTimeFormProps extends Stylable {
  selectedDate: Date | null
  selectedTime: Date | null
  handleDateChange: (selectedDate: Date | null) => void
  handleTimeChange: (selectedDate: Date | null) => void
  expanded?: boolean
}

const StartTimeForm = ({
  selectedDate,
  selectedTime,
  handleDateChange,
  handleTimeChange,
  expanded,
  style,
}: StartTimeFormProps) => {
  const theme: Theme = useTheme()
  const { isMobile } = useMediaContext()
  const classes = useStyles()
  const intl = useIntl()

  return (
    <Accordion className={classes.expansionPanel} defaultExpanded={expanded} style={style}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: theme.palette.secondary.main }} />}
        className={classes.expansionPanelSummary}
      >
        <Typography className={classes.heading}>
          <FormattedMessage id="startTime" />
        </Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.expansionPanelDetailsRoot }}>
        <div className={classes.timeContainer}>
          <>
            <DatePicker
              value={selectedDate}
              onChange={handleDateChange}
              label={intl.formatMessage({ id: 'date' })}
              style={isMobile ? { width: '50%' } : { width: '33.33%' }}
              key="datePicker"
            />

            <TimePicker
              value={selectedTime}
              onChange={handleTimeChange}
              label={intl.formatMessage({ id: 'time' })}
              style={isMobile ? { width: '50%' } : { width: '33.33%' }}
              key="timePicker"
            />
          </>
          {!isMobile && <div style={{ width: '33.33%' }} />}
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default StartTimeForm
