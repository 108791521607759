import { Box, Text, TextProps } from '@sofascore/ui'
import styled from 'styled-components'

import { textPrimary, textSecondary } from 'styles/color'

import { TERRAIN_HEIGHT, TERRAIN_WIDTH } from '../config'

export const PitchOptionsGrid = styled(Box)`
  display: grid;
  column-gap: 8px;
  grid-template-columns: 360px 332px;
`

export const MobilePitchBackground = styled.div`
  display: flex;
  justify-content: center;
  background-repeat: repeat;

  @media (min-width: 720px) {
    background-repeat: space;
  }
`

export const MobilePitchContainer = styled.div`
  width: ${TERRAIN_WIDTH}px;
  height: ${TERRAIN_HEIGHT}px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
`

export const DragAndDropPitch = styled.div`
  width: ${TERRAIN_WIDTH}px;
  height: ${TERRAIN_HEIGHT}px;
  display: inline-flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
`

export const DragAndDropSlot = styled(Box)`
  position: relative;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const DragAndDropSlotText = styled(Text)`
  position: absolute;
  display: inline-block;
  top: 46px;
  left: 50%;
  transform: translateX(-50%);

  white-space: nowrap;

  &::after {
    content: '';
    position: absolute;
    top: -44px;
    left: 50%;
    transform: translateX(-50%);

    width: 72px;
    height: 72px;

    background: rgba(255, 255, 255, 0.2);
    border: 1px dashed #fff;
    border-radius: 4px;

    white-space: nowrap;
  }
`

export const DragAndDropShirtNumber = styled.span`
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  color: #fff;
`

export const JerseyNumber = styled(Text)<{ $isWhiteJersey: boolean } & TextProps>`
  position: absolute;
  left: 50%;
  top: calc(50% - 2px);
  transform: translate(-50%, -50%);
  font-weight: bold;
  color: ${p => (p.$isWhiteJersey ? textPrimary : textSecondary)};
`

export const PlayerName = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);

  width: 72px;
  max-height: 28px;

  text-overflow: ellipsis;
  word-wrap: nowrap;
  overflow: hidden;

  color: white;
  font-weight: 500;
  text-align: center;
  font-size: 12px;
`
