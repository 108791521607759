import { Flex, Box } from '@sofascore/ui'
import styled from 'styled-components'

export const TeamLogos = styled(Flex)`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -8px;
    height: 100%;
    width: 8px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%);
  }
`
export const Buttons = styled(Box)`
  display: flex;
  align-items: center;
  position: relative;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
`

export const Results = styled(Flex)`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: -8px;
    height: 100%;
    width: 8px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.05) 100%);
  }
`
