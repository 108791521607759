import { Box, Flex } from '@sofascore/ui'
import { useEventState } from 'entities/Event'
import { StatusType } from 'entities/Status'
import { MatchProperties } from 'features/MatchProperties'
import { MatchReportDownload } from 'features/MatchReportDownload'
import { CountdownSection } from 'features/CountdownSection'
import { ScoreSection } from 'features/ScoreSection'
import { MatchActionButtons } from 'features/MatchActionButtons'

import { EditMatchInfoHeader } from '../MatchInfoHeader'
import { DISABLE_EDIT_LIVE_INACTIVE_THRESHOLD, ENABLE_EDIT_LIVE_THRESHOLD } from '../../config'

export const MiniFootballMatchInfo = () => {
  const { event } = useEventState()

  // Allow match report generating only when match is finished
  const isNotStarted = event.status.type === StatusType.NOT_STARTED

  return (
    <Flex mt="sm" minH={604} direction="column" justify="space-between">
      <Box>
        <MatchReportDownload />
        <EditMatchInfoHeader>
          {isNotStarted ? (
            <CountdownSection
              enableEditLiveThreshold={ENABLE_EDIT_LIVE_THRESHOLD}
              disableEditLiveInactiveThreshold={DISABLE_EDIT_LIVE_INACTIVE_THRESHOLD}
            />
          ) : (
            <ScoreSection />
          )}
        </EditMatchInfoHeader>

        <MatchProperties />
      </Box>

      <MatchActionButtons
        enableEditLiveThreshold={ENABLE_EDIT_LIVE_THRESHOLD}
        disableEditLiveInactiveThreshold={DISABLE_EDIT_LIVE_INACTIVE_THRESHOLD}
      />
    </Flex>
  )
}
