import { FormattedMessage } from 'react-intl'
import { Box, Flex, Text } from '@sofascore/ui'
import { SportName } from 'entities/Sport'
import { useEventState } from 'entities/Event'
import { ProgressBar } from 'shared/ui'
import { MIN_PLAYERS, DynamicLineupsPlayer, LineupType, ClearAll } from 'features/AddLineups'

export const ListLineupsCounter = ({
  type,
  lineupPlayers,
  isHomeTeam,
}: {
  type: LineupType
  lineupPlayers: DynamicLineupsPlayer[]
  isHomeTeam: boolean
}) => {
  const { event } = useEventState()
  const starterCount = lineupPlayers.filter(s => s.position).length

  const sport = event.tournament.category.sport.slug
  const isFootball = sport === SportName.Football

  return (
    <Flex align="center" py="xs">
      <Text font="display.medium" fontWeight="bold">
        <FormattedMessage id="players" />
      </Text>

      {isFootball && (
        <>
          <Box display="block" h={6} w={6} bg="#ddd" borderRadius="50%" mx="sm"></Box>

          <Text font="display.medium" fontWeight="bold">
            {starterCount}/{MIN_PLAYERS.PITCH.TO_CREATE}
          </Text>

          <ProgressBar
            value={starterCount / MIN_PLAYERS.PITCH.TO_CREATE}
            fullfillThreshold={
              type === LineupType.PLAYER_LIST
                ? MIN_PLAYERS.PLAYER_LIST.TO_CREATE / MIN_PLAYERS.PITCH.TO_CREATE
                : undefined
            }
            ml="lg"
          />
        </>
      )}

      <Box ml="auto">
        <ClearAll
          isHomeTeam={isHomeTeam}
          message={
            <FormattedMessage id={isFootball ? 'football_clear_team_lineup_text' : 'general_clear_team_lineup'} />
          }
        />
      </Box>
    </Flex>
  )
}
