import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Flex, Input, Text, TextArea } from '@sofascore/ui'
import IconAddCircle from '@sofascore/ui/dist/modules/Icons/IconAddCircle'
import { AnimatePresence, motion } from 'framer-motion'
import { FormattedMessage, useIntl } from 'react-intl'
import { useEventState } from 'entities/Event'
import { useToast } from 'shared/lib'
import { FirebaseEventName } from 'shared/lib/firebase/model'
import { logFirebaseEvent } from 'shared/lib/firebase/utils'
import { StatusType } from 'entities/Status'

import { createMatchReport } from 'api'
import { Button } from 'components/Button'

import { MatchReportAction, MatchReportData, MatchReportStatus, RepeaterItem } from '../../model'
import { repeaterItemAnimationConfig } from '../../config'
import * as S from '../../styles'

export function UpdateConfigForm({
  setConfigModal,
  initialData,
  mutate,
  matchReportCreated,
}: {
  setConfigModal: Dispatch<SetStateAction<boolean>>
  initialData?: MatchReportData
  mutate: () => void
  matchReportCreated?: boolean
}) {
  const intl = useIntl()
  const { enqueueToast } = useToast()
  const { event } = useEventState()
  const { id: eventID } = event
  const hasFinished = event.status.type === StatusType.FINISHED
  const [isLoading, setIsLoading] = useState(false)
  const [homeCoaches, setHomeCoaches] = useState<RepeaterItem[]>(
    initialData?.homeTeamManagersNames?.map(name => ({ uid: uuidv4(), value: name })) || [{ uid: uuidv4(), value: '' }],
  )
  const [awayCoaches, setAwayCoaches] = useState<RepeaterItem[]>(
    initialData?.awayTeamManagersNames?.map(name => ({ uid: uuidv4(), value: name })) || [{ uid: uuidv4(), value: '' }],
  )
  const [referees, setReferees] = useState<RepeaterItem[]>(
    initialData?.officialsNames?.map(name => ({ uid: uuidv4(), value: name })) || [{ uid: uuidv4(), value: '' }],
  )
  const [delegate, setDelegate] = useState(initialData?.delegateName || '')
  const [additionalNotes, setAdditionalNotes] = useState(initialData?.notes || '')

  const validateForm = useMemo(() => {
    const homeCoachesErrors = homeCoaches.filter(item => !item?.value.replace(/\s+/g, '')).map(item => item.uid)
    const awayCoachesErrors = awayCoaches.filter(item => !item?.value.replace(/\s+/g, '')).map(item => item.uid)
    const refereesErrors = referees.filter(item => !item?.value.replace(/\s+/g, '')).map(item => item.uid)
    return {
      homeCoachesErrors,
      awayCoachesErrors,
      refereesErrors,
    }
  }, [homeCoaches, awayCoaches, referees])

  const isFormValid = useMemo(() => {
    return Object.values(validateForm).every(fields => Array.isArray(fields) && fields.length === 0)
  }, [validateForm])

  const onSubmit = async () => {
    if (!isFormValid) return

    setIsLoading(true)

    const updatedData = {
      eventId: event.id,
      homeTeamManagersNames: homeCoaches.map(item => item.value),
      awayTeamManagersNames: awayCoaches.map(item => item.value),
      officialsNames: referees.map(item => item.value),
      delegateName: delegate,
      notes: additionalNotes,
    }

    try {
      await createMatchReport(updatedData)
      enqueueToast(intl.formatMessage({ id: 'toastSuccess' }), { variant: 'success' })
      setConfigModal(false)
      logFirebaseEvent(FirebaseEventName.MatchReport, {
        id: eventID,
        action: matchReportCreated ? MatchReportAction.Edit : MatchReportAction.Create,
        sport: event?.tournament?.category?.sport?.slug,
        status: hasFinished ? MatchReportStatus.PostMatch : MatchReportStatus.PreMatch,
      })
    } catch (e) {
      console.error(e)
      enqueueToast(intl.formatMessage({ id: 'toastError' }), { variant: 'error' })
    } finally {
      setIsLoading(false)
      mutate()
    }
  }

  return (
    <Flex direction="column" p="xl">
      <Flex direction="column" w="100%">
        <Text mb="md">
          <FormattedMessage id="coaches" />
        </Text>
        <AnimatePresence>
          {homeCoaches.map(coach => (
            <motion.div key={coach.uid} {...repeaterItemAnimationConfig}>
              <Flex direction="column" gapY="md" w="100%" mb="md">
                <Input
                  clearable
                  type="text"
                  value={coach.value}
                  label={<FormattedMessage id="home_team_coach" />}
                  required
                  endAdornment={
                    homeCoaches?.length > 1 && (
                      <S.RepeaterDeleteButton
                        cursor="pointer"
                        onClick={() => {
                          const updatedCoaches = homeCoaches.filter(item => item.uid !== coach.uid)
                          setHomeCoaches([...updatedCoaches])
                        }}
                      />
                    )
                  }
                  onChange={value => {
                    const updatedCoaches = homeCoaches.map(item =>
                      item.uid === coach.uid ? { uid: item.uid, value } : item,
                    )
                    setHomeCoaches([...updatedCoaches])
                  }}
                  onClear={() => {
                    const updatedCoaches = homeCoaches.map(item =>
                      item.uid === coach.uid ? { uid: item.uid, value: '' } : item,
                    )
                    setHomeCoaches([...updatedCoaches])
                  }}
                  name="homeCoaches"
                />
              </Flex>
            </motion.div>
          ))}
        </AnimatePresence>

        <S.RepeaterButton onClick={() => setHomeCoaches([...homeCoaches, { uid: uuidv4(), value: '' }])}>
          <IconAddCircle fill="primary.default" mr="sm" />
          <Text>
            <FormattedMessage id="add_another_home_team_coach" />
          </Text>
        </S.RepeaterButton>
        <AnimatePresence>
          {awayCoaches.map(coach => (
            <motion.div key={coach.uid} {...repeaterItemAnimationConfig}>
              <Flex direction="column" gapY="md" w="100%" mb="md">
                <Input
                  clearable
                  type="text"
                  label={<FormattedMessage id="away_team_coach" />}
                  required
                  name="awayCoaches"
                  value={coach.value}
                  endAdornment={
                    awayCoaches?.length > 1 && (
                      <S.RepeaterDeleteButton
                        cursor="pointer"
                        onClick={() => {
                          const updatedCoaches = awayCoaches.filter(item => item.uid !== coach.uid)
                          setAwayCoaches([...updatedCoaches])
                        }}
                      />
                    )
                  }
                  onChange={value => {
                    const updatedCoaches = awayCoaches.map(item =>
                      item.uid === coach.uid ? { uid: item.uid, value } : item,
                    )
                    setAwayCoaches([...updatedCoaches])
                  }}
                  onClear={() => {
                    const updatedCoaches = awayCoaches.map(item =>
                      item.uid === coach.uid ? { uid: item.uid, value: '' } : item,
                    )
                    setAwayCoaches([...updatedCoaches])
                  }}
                />
              </Flex>
            </motion.div>
          ))}
        </AnimatePresence>
        <S.RepeaterButton onClick={() => setAwayCoaches([...awayCoaches, { uid: uuidv4(), value: '' }])}>
          <IconAddCircle fill="primary.default" mr="sm" />
          <Text>
            <FormattedMessage id="add_another_away_team_coach" />
          </Text>
        </S.RepeaterButton>
      </Flex>

      <Flex direction="column" w="100%" mt="lg">
        <Text mb="md">
          <FormattedMessage id="match_officials" />
        </Text>
        <AnimatePresence>
          {referees.map(referee => (
            <motion.div key={referee.uid} {...repeaterItemAnimationConfig}>
              <Flex direction="column" gapY="md" w="100%" mb="md">
                <Input
                  clearable
                  type="text"
                  label={<FormattedMessage id="referee" />}
                  required
                  value={referee.value}
                  endAdornment={
                    referees?.length > 1 && (
                      <S.RepeaterDeleteButton
                        cursor="pointer"
                        onClick={() => {
                          const updatedrReferees = referees.filter(item => item.uid !== referee.uid)
                          setReferees([...updatedrReferees])
                        }}
                      />
                    )
                  }
                  onChange={value => {
                    const updatedReferees = referees.map(item =>
                      item.uid === referee.uid ? { uid: item.uid, value } : item,
                    )
                    setReferees([...updatedReferees])
                  }}
                  onClear={() => {
                    const updatedCoaches = referees.map(item =>
                      item.uid === referee.uid ? { uid: item.uid, value: '' } : item,
                    )
                    setReferees([...updatedCoaches])
                  }}
                  name="homeCoaches"
                />
              </Flex>
            </motion.div>
          ))}
        </AnimatePresence>
        <S.RepeaterButton onClick={() => setReferees([...referees, { uid: uuidv4(), value: '' }])}>
          <IconAddCircle fill="primary.default" mr="sm" />
          <Text>
            <FormattedMessage id="add_another_referee" />
          </Text>
        </S.RepeaterButton>
      </Flex>

      <Flex direction="column" w="100%" mt="lg">
        <Text mb="md">
          <FormattedMessage id="delegate" />
        </Text>

        <Input
          type="text"
          label={<FormattedMessage id="delegate" />}
          name="delegate"
          value={delegate}
          onChange={setDelegate}
        />
      </Flex>

      <Flex direction="column" gapY="md" w="100%" mt="lg">
        <Text>
          <FormattedMessage id="additional_notes" />
        </Text>

        <TextArea
          bg="surface.s2"
          label={<FormattedMessage id="message" />}
          name="additionalNotes"
          value={additionalNotes}
          onChange={setAdditionalNotes}
          placeholder={intl.formatMessage({ id: 'additional_notes_placeholder' })}
          h="130px"
        />
      </Flex>

      <Flex gapX="lg" mt="xl">
        <Button variant="outlined" fullWidth onClick={() => setConfigModal(false)}>
          <FormattedMessage id="cancel" />
        </Button>
        <Button
          variant="contained"
          fullWidth
          onClick={onSubmit}
          disabled={!isFormValid || isLoading}
          isLoading={isLoading}
        >
          {initialData ? <FormattedMessage id="save" /> : <FormattedMessage id="create_report" />}
        </Button>
      </Flex>
    </Flex>
  )
}
